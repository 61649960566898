import {Component, Input, OnInit} from '@angular/core';
import {animate, sequence, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-collapsible-form',
  templateUrl: './collapsible-form.component.html',
  styleUrls: ['./collapsible-form.component.scss'],
  animations: [
    trigger('caretRotation', [
      state('open', style({transform: 'rotate(90deg)'})),
      state('close', style({transform: 'rotate(0deg)'})),
      transition('open <=> close', [
        animate(200)
      ])
    ]),
    trigger('filtersShow', [
      transition(':enter', [
        style({height: 0, opacity: 0}),
        sequence([
          animate(100, style({height: '*'})),
          animate(100, style({opacity: 1})),
        ]),
      ]),
      transition(':leave', [
        sequence([
          animate(100, style({opacity: 0})),
          animate(100, style({height: 0}))
        ])
      ])
    ])
  ]
})
export class CollapsibleFormComponent implements OnInit {

  showFilters = false;
  @Input() title: string;

  constructor() { }

  ngOnInit(): void {
  }

}
