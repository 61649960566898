import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {IncidencesService} from '../../../../incidences/incidences.service';
import {DetailWorkOrderService} from '../detail-work-order.service';
import {WorkOrder} from '../../../models/work-order.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, skipWhile} from 'rxjs/operators';

@Component({
  selector: 'app-detail-work-order-documents',
  templateUrl: './detail-work-order-documents.component.html',
  styleUrls: ['./detail-work-order-documents.component.scss']
})
export class DetailWorkOrderDocumentsComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid';

  workOrder$: BehaviorSubject<WorkOrder>;
  documents$: Observable<{url: string, file: string}[]>;

  @ViewChild('fileInput') fileInput;
  uploadingImage: boolean;

  constructor(
    public detailWorkOrderService: DetailWorkOrderService,
    private incidenceService: IncidencesService
  ) {
    this.workOrder$ = this.detailWorkOrderService.workOrder$;
  }

  ngOnInit(): void {
    this.documents$ = this.workOrder$.pipe(
      skipWhile(order => !order),
      map(order => {
        return order.photos.map(url => {
          const fileParts = url.split('/');
          const file = fileParts[fileParts.length - 1];
          return {
            url,
            file
          };
        });
      })
    );
  }

  addFiles() {
    this.uploadingImage = true;
    const file = this.fileInput.nativeElement.files[0];

    this.incidenceService.uploadImage(file).subscribe(result => {
      this.fileInput.nativeElement.value = null;
      this.workOrder$.value.photos.push(result.url);
      this.workOrder$.next(this.workOrder$.value);
      this.uploadingImage = false;
    });
  }

  deleteImage(image: string) {
    this.workOrder$.value.photos = this.workOrder$.value.photos.filter(photo => image !== photo);
  }

  deleteDocument(i: number) {
    this.workOrder$.value.photos.splice(i, 1);
    this.workOrder$.next(this.workOrder$.value);
  }
}
