
import { 
    ReportEvolutionIncidenceByCategoryDTO,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessEvolutionIncidencesByCategory extends ProcessDataReport{

    constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
        super(reports, reportGrossData);
    }
    
    getData(){
        this.reports.evolutionIncidencesByCategory = this.reportGrossData as ReportEvolutionIncidenceByCategoryDTO;
        this.reports.showIncidenceStatusFilter = true;
 
    }
   
}