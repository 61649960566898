<button tabindex="-1" (click)="onButtonClick($event)">
  <div *ngIf="leftLabeledIcon" class="label label--left">
    <i [puiIcon]="leftLabeledIcon"></i>
  </div>
  <div class="content">
    <i *ngIf="icon" [puiIcon]="icon"></i>
    <span *ngIf="label" class="text">{{label}}</span>
  </div>
  <div *ngIf="rightLabeledIcon" class="label label--right">
    <i [puiIcon]="rightLabeledIcon"></i>
  </div>
</button>
