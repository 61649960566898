<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'SETTINGS.TITLE' | translate }}</h1>
</div>

<configuration-menu [defaultSectionKey]="'criticalityLevels'"></configuration-menu>

<div class="sixteen wide mobile twelve wide computer column">
  <div class="ui grid">
    <div *ngIf="activeSection" class="sixteen wide column">
      <div class="ui">
        <h2 class="ui header">{{ 'SETTINGS.HEADER_CRITICALITY_LEVELS' | translate }}</h2>
        <div class="ui segment">
          <div class="ui very relaxed divided list">
            <div *ngFor="let level of criticalityLevels" class="item">
              <div class="right floated content"><i (click)="deleteCriticalityLevel(level.id)" class="ui delete icon"></i></div>
              <div class="content">{{level.name}} - {{level.time | minutesToHours}}</div>
            </div>
          </div>

          <h3 class="ui header">{{ 'SETTINGS.HEADER_ADD_NEW' | translate }}</h3>
          <form class="ui form" [formGroup]="levelForm" (ngSubmit)="addNewCriticalityLevel()">
            <div class="fields">
              <div class="eight wide field">
                <input formControlName="name" name="name" type="text" placeholder="{{ 'SETTINGS.PLACEHOLDER_URGENT' | translate }}" required>
              </div>
              <div class="three wide field">
                <div class="ui right labeled input">
                  <input formControlName="hours" appNumeric name="hours" type="number" placeholder="3">
                  <div class="ui basic label">
                    {{ 'SETTINGS.LABEL_HOURS' | translate }}
                  </div>
                </div>
              </div>
              <div class="three wide field">
                <div class="ui right labeled input">
                  <input formControlName="minutes" appNumeric max="59" name="minutes" type="number" placeholder="30">
                  <div class="ui basic label">
                    {{ 'SETTINGS.LABEL_MINUTES' | translate }}
                  </div>
                </div>
              </div>
              <div class="two wide field">
                <button class="ui icon button" type="submit" [class.disabled]="!levelForm.valid">
                  <i class="add icon"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
