import { AbstractControl } from "@angular/forms";

export class UserFormComponentValidator{

    static passwordStrength(control: AbstractControl): { [key: string]: any } | null {
        if (!control.value.toString().trim()) {
          return null;
        }
        const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d_\-!@#\$%\^&\*]{6,}$/;
        return PASSWORD_REGEX.test(control.value) ? null : { passwordStrengthLow: true};
      }
}

