<h5
  (click)="showFilters = !showFilters"
  class="ui left aligned header"
  style="cursor: pointer"
><i [@caretRotation]="showFilters ? 'open' : 'close'" class="caret right icon"></i>
  {{title}}
</h5>

<div [@filtersShow] *ngIf="showFilters">
  <ng-content></ng-content>
</div>
