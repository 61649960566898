import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CriticalityLevelInterface} from '../models/criticalityLevel.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CriticalityLevelsService {

  private readonly apiHost = environment.apiHost;

  constructor(private http: HttpClient) {
  }

  fetchAll(facilityId: number): Observable<CriticalityLevelInterface[]> {
    return this.http.get<CriticalityLevelInterface[]>(`${this.apiHost}/criticality-levels?facilityId=${facilityId}`);
  }

  create(facilityId: number, data: {name: string, time: number}): Observable<CriticalityLevelInterface> {
    return this.http.post<CriticalityLevelInterface>(`${this.apiHost}/criticality-levels?facilityId=${facilityId}`, data);
  }

  update(id: number, facilityId: number, data: {name?: string, time?: number}): Observable<CriticalityLevelInterface> {
    return this.http.patch<CriticalityLevelInterface>(`${this.apiHost}/criticality-levels/${id}?facilityId=${facilityId}`, data);
  }

  delete(id: number, facilityId: number): Observable<any> {
    return this.http.delete(`${this.apiHost}/criticality-levels/${id}?facilityId=${facilityId}`);
  }
}
