<div class="sixteen wide column">
  <div class="ui button"
      [class.disabled]="!detailWorkOrderService.canMessageAndAddFiles()"
       (click)="fileInput.click()">{{'ADD_FILE' | translate}}
  </div>
  <input #fileInput type="file" accept="application/pdf,.jpg,.jpeg,.png,.docx,.xlsx,.pptx" hidden (change)="addFiles()">

  <table *ngIf="(documents$ | async)?.length" class="ui small compact table">
    <thead>
    <tr>
      <th>{{'NAME' | translate}}</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let doc of documents$ | async; let i=index">
      <td><a href="{{doc.url}}" target="_blank">{{doc.file}}</a></td>
      <td class="actions-cell">
        <div class="ui mini icon button"
            [class.disabled]="!detailWorkOrderService.canMessageAndAddFiles()"
             (click)="deleteDocument(i)"
        ><i class="ui delete icon"></i></div>
      </td>
    </tr>
    </tbody>
  </table>

  <div *ngIf="!(documents$ | async)?.length" class="ui segment">
    <p style="text-align: center">{{'NO_FILES' | translate}}</p>
  </div>
</div>
