import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'pui-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputComponent implements OnInit {

  _loading = false;
  @Input() set loading(value) {
    this._loading = (value === '') || value;
  }

  _clickable = false;
  @Input() set clickable(value) {
    this._clickable = (value === '') || value;
  }

  @Input() size: 'mini' | 'small' | 'normal' | 'large' | 'big' | 'huge' | 'massive' = 'normal';

  get wrapperClass() {
    let classes = 'wrapper ';
    classes += this._loading ? 'loading ' : '';
    classes += this.size;

    return classes;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
