<h3 class="ui header">{{ 'SCHEDULED_TASK_DETAIL.SELECT_COMPONENTS' | translate }}</h3>
<div
  class="ui form sixteen wide column filterContainer"
  *ngIf="shouldShowComponentTable()"
>
  <div class="mb-0 two wide field">
    <div class="ui icon input">
      <input
        type="text"
        id="filterCode"
        placeholder="{{ 'SCHEDULED_TASK_DETAIL.CODE' | translate }}"
        (keyup)="filterComponent($event.target.value, 'assetIdentifier')"
      />
    </div>
  </div>

  <div class="six wide field mb-0">
    <div class="ui icon input">
      <input
        type="text"
        id="filterName"
        placeholder="{{ 'SCHEDULED_TASK_DETAIL.NAME' | translate }}"
        (keyup)="filterComponent($event.target.value, 'componentName')"
      />
    </div>
  </div>
  <div class="three wide field mb-0">
    <div class="ui icon input">
      <input
        type="text"
        id="filterType"
        placeholder="{{ 'SCHEDULED_TASK_DETAIL.TYPE' | translate }}"
        (keyup)="filterComponent($event.target.value, 'typeName')"
      />
    </div>
  </div>

  <div class="two wide field mb-0">
    <div class="ui icon input">
      <input
        type="text"
        id="filterSpace"
        placeholder="{{ 'SCHEDULED_TASK_DETAIL.SPACE' | translate }}"
        (keyup)="filterComponent($event.target.value, 'spaceName')"
      />
    </div>
  </div>
  <div class="two wide field mb-0">
    <div class="ui icon input">
      <input
        type="text"
        id="filterFloor"
        placeholder="{{ 'SCHEDULED_TASK_DETAIL.FLOOR' | translate }}"
        (keyup)="filterComponent($event.target.value, 'floorName')"
      />
    </div>
  </div>
  <div class="one wide field mb-0">
    <fui-dropdown-two
      selectorId="numElemSelector"
      [fluid]="true"
      [options]="numComponentsPerPageOptions"
      [formControl]="selectedNumPerPage"
    ></fui-dropdown-two>
  </div>
</div>

<table class="ui small sortable selectable compact table">
  <thead>
    <tr class="ui sixteen wide column">
      <th class="two wide field">{{ 'SCHEDULED_TASK_DETAIL.CODE' | translate }}</th>
      <th class="six wide field">{{ 'SCHEDULED_TASK_DETAIL.NAME' | translate }}</th>
      <th class="three wide field">{{ 'SCHEDULED_TASK_DETAIL.TYPE' | translate }}</th>
      <th class="two wide field">{{ 'SCHEDULED_TASK_DETAIL.SPACE' | translate }}</th>
      <th class="two wide field">{{ 'SCHEDULED_TASK_DETAIL.FLOOR' | translate }}</th>
      <th class="one wide field">
        <div class="checkContainer">
          <div class="ui checkbox">
            <input
              type="checkbox"
              (change)="checkAllComponents($event.target.checked)"
              [checked]="allComponentChecked"
            />
            <label></label>
          </div>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let scheduledTask of scheduledTasks$ | async">
      <td>{{ scheduledTask.component.assetIdentifier }}</td>
      <td>{{ scheduledTask.component.name }}</td>
      <td>{{ scheduledTask.component.type.name }}</td>
      <td>{{ scheduledTask.component.space.name }}</td>
      <td>{{ scheduledTask.component.space.floor.name }}</td>
      <td class="checkContainer">
        <div class="ui checkbox">
          <input
            type="checkbox"
            id="rowId_{{scheduledTask.component.id}}"
            (change)="
              onComponentChecked(
                $event.target.checked,
                scheduledTask.component.id
              )
            "
            [checked]="checkedComponent.has(scheduledTask.component.id)"
          />
          <label></label>
        </div>
      </td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th colspan="16">
        <div class="footerInfoContainer">
          {{ getFooterMessage() }}
          <p>{{ 'SCHEDULED_TASK_DETAIL.SELECTED' | translate }}: {{ checkedComponent.size }}</p>
        </div>
      </th>
    </tr>
    <tr>
      <th colspan="16">
        <fui-pagination-menu
          [page]="componentsPage$ | async"
          [numOfPages]="componentsNumOfPages"
          (pageSelected)="componentsPage$.next($event)"
          [maxVisibleOptions]="componentLimit"
        ></fui-pagination-menu>
      </th>
    </tr>
  </tfoot>
</table>

<div *ngIf="shouldShowSubtaskTable()">
  <h3 class="ui header">{{ 'SCHEDULED_TASK_DETAIL.SUBTASKS' | translate }}</h3>
  <table class="ui small sortable selectable compact table mt-20">
    <thead>
      <tr>
        <th>{{ 'SCHEDULED_TASK_DETAIL.CODE' | translate }}</th>
        <th>{{ 'SCHEDULED_TASK_DETAIL.DESCRIPTION' | translate }}</th>
        <th class="one wide field"></th>
      </tr>
    </thead>
    <tbody *ngIf="subTasksByComponent">
      <tr *ngFor="let subTask of paginatedSubTasks">
        <td [ngClass]="subTask.type === 'uncommon' ? 'color-gray text-italic' : ''">
          {{ subTask.code }}
        </td>
        <td [ngClass]="subTask.type === 'uncommon' ? 'color-gray text-italic' : ''">
          {{ subTask.description }}
        </td>
        <td>
          <div class="ui checkbox">
            <input
              type="checkbox"
              (change)="onSubtaskChecked($event.target.checked, subTask)"
              [checked]="checkedSubtasks.has(subTask)"
            />
            <label></label>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="16">
          <div class="footerInfoContainer" *ngIf="subTasksByComponent">
            <div *ngIf="subTasksByComponent.subtasks.length === 0">
              <p>{{ 'SCHEDULED_TASK_DETAIL.NO_SUBTASKS' | translate }}</p>
            </div>
            <div *ngIf="subTasksByComponent.subtasks.length > 0">
              <p>
                {{ 'SCHEDULED_TASK_DETAIL.SHOWING' | translate }}
                {{ subTasksPage <= 1 ? subTasksPage : (subTasksPage - 1) * 10 }}
                {{ 'SCHEDULED_TASK_DETAIL.TO' | translate }}
                {{
                  subTasksByComponent.subtasks.length > subTasksPage * 10
                    ? subTasksPage * 10
                    : subTasksByComponent.subtasks.length
                }}
                {{ 'SCHEDULED_TASK_DETAIL.OF' | translate }} {{ subTasksByComponent.subtasks.length }} {{ 'SCHEDULED_TASK_DETAIL.TOTAL_SUBTASKS' | translate }}
              </p>

              <p>
                {{ 'SCHEDULED_TASK_DETAIL.COMMON_SUBTASKS' | translate }} {{ subTasksByComponent.totalCommon }}
                {{ 'SCHEDULED_TASK_DETAIL.ARE_COMMON_AND' | translate }} {{ subTasksByComponent.totalUncommon }} {{ 'SCHEDULED_TASK_DETAIL.ARE_UNCOMMON' | translate }}
              </p>
            </div>
            <p *ngIf="subTasksByComponent.subtasks.length > 0">
              {{ 'SCHEDULED_TASK_DETAIL.SELECTED' | translate }}: {{ checkedSubtasks.size }}
            </p>
          </div>
        </th>
      </tr>
      <tr *ngIf="subTasksByComponent">
        <th colspan="16" *ngIf="subTasksByComponent.subtasks.length > 0">
          <fui-pagination-menu
            [page]="subTasksPage"
            [numOfPages]="subTasksNumOfPage"
            (pageSelected)="changeSubtasksPage($event)"
          ></fui-pagination-menu>
        </th>
      </tr>
    </tfoot>
  </table>
</div>

<div class="sixteen wide column btnContainer mt-20">
  <button
    class="ui primary button"
    (click)="clickOnSubmit()"
    [class.disabled]="isBtnSubmitDisabled()"
  >
    {{ 'SCHEDULED_TASK_DETAIL.ADD' | translate }}
  </button>

  <div
    class="ui button ml-10"
    [ngClass]="
      this.checkedComponent.size > 0 && this.checkedSubtasks.size > 0
        ? 'red'
        : ''
    "
    (click)="clickOnDelete()"
    [class.disabled]="isBtnDeleteDisabled()"
  >
    {{ 'SCHEDULED_TASK_DETAIL.DELETE' | translate }}
  </div>
</div>

<div class="addSubtasksContainer">
  <app-label-and-info
    [labelText]="'SCHEDULED_TASK_DETAIL.ADD_SUBTASKS' | translate"
    [helpingText]="'SCHEDULED_TASK_DETAIL.ADD_SUBTASKS_HELP' | translate"
    [customClass]="'header3'"
  ></app-label-and-info>
  <form (ngSubmit)="clickOnSubmit()">
    <div
      *ngFor="let subTask of subTasks().controls; let i = index"
      style="margin-bottom: 10px"
    >
      <div></div>
      <div class="ui form inputContainer" [formGroup]="subTask">
        <div class="four wide field mb-0">
          <div class="field">
            <input
              type="text"
              id="code"
              formControlName="code"
              placeholder="{{ 'SCHEDULED_TASK_DETAIL.CODE' | translate }}"
            />
          </div>
        </div>

        <div class="four wide field mb-0 ml-10">
          <div class="field">
            <input
              type="text"
              id="description"
              formControlName="description"
              placeholder="{{ 'SCHEDULED_TASK_DETAIL.DESCRIPTION' | translate }}"
            />
          </div>
        </div>

        <div *ngIf="i > 0" class="four wide field mb-0 ml-10">
          <button (click)="removeSubTask(i)" class="ui red button">
            {{ 'SCHEDULED_TASK_DETAIL.REMOVE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>

  <i
    (click)="addSubTask()"
    class="icon big plus square outline grey"
    style="cursor: pointer"
  ></i>
</div>

<!-- ------- MODALS ------- -->
<pui-modal *ngIf="showAddModal" title="{{ 'SCHEDULED_TASK_DETAIL.ADD_SUBTASKS' | translate }}">
  <div class="edit-modal">
    <i class="fas fa-exclamation-triangle"></i>
    <h3>{{ 'SCHEDULED_TASK_DETAIL.ADD_SUBTASKS_CONFIRM' | translate }}</h3>
  </div>

  <div footer class="edit-modal__footer">
    <div class="left-actions">
      <div class="ui button" (click)="closeModal()">{{ 'SCHEDULED_TASK_DETAIL.CANCEL' | translate }}</div>
    </div>
    <div class="right-actions">
      <div class="ui primary button" (click)="submit(false)">{{ 'SCHEDULED_TASK_DETAIL.THIS_TASK' | translate }}</div>
      <div class="ui primary button" (click)="submit(true)">{{ 'SCHEDULED_TASK_DETAIL.ALL_TASKS' | translate }}</div>
    </div>
  </div>
</pui-modal>

<pui-modal *ngIf="showDeleteModal" title="{{ 'SCHEDULED_TASK_DETAIL.DELETE_SUBTASKS' | translate }}">
  <div class="edit-modal">
    <i class="fas fa-exclamation-triangle"></i>
    <h3>{{ 'SCHEDULED_TASK_DETAIL.DELETE_SUBTASKS_CONFIRM' | translate }}</h3>
  </div>

  <div footer class="edit-modal__footer">
    <div class="left-actions">
      <div class="ui button" (click)="closeModal()">{{ 'SCHEDULED_TASK_DETAIL.CANCEL' | translate }}</div>
    </div>
    <div class="right-actions">
      <div class="ui red button" (click)="deleteSubtasks(false)">
        {{ 'SCHEDULED_TASK_DETAIL.THIS_TASK' | translate }}
      </div>
      <div class="ui red button" (click)="deleteSubtasks(true)">{{ 'SCHEDULED_TASK_DETAIL.ALL_TASKS' | translate }}</div>
    </div>
  </div>
</pui-modal>
