import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private readonly apiHost = environment.apiHost;

    constructor(private http: HttpClient) {}

    getData(facilityId: number, fromDate: string, toDate: string): Observable<any> {
        return this.http.get(`${this.apiHost}/dashboard?facilityId=${facilityId}&from=${fromDate}&to=${toDate}`);
    }
}
