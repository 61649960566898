import {Pipe, PipeTransform} from '@angular/core';


@Pipe({name: 'minutesToHours'})
export class MinutesToHoursPipe implements PipeTransform {
  transform(value: number): any {
    const hours = value / 60;
    const roundedHours = Math.floor(hours);
    const minutes = (hours - roundedHours) * 60;
    const roundedMinutes = Math.round(minutes);

    if (roundedHours && roundedMinutes) {
      return `${roundedHours} horas y ${roundedMinutes} minutos`;
    } else if (roundedHours) {
      return `${roundedHours} horas`;
    } else {
      return `${roundedMinutes} minutos`;
    }
  }
}
