<div class="ui secondary segment">
  <ng-container
    *ngFor="let inclusiveFilter of conditionsFiltersArray.controls; let i = index"
  >
    <h5 class="ui header" style="display: flex; justify-content: space-between">
      {{
      i === 0 ? firstGroupTitle : nextGroupsTitle }}
      <span
        *ngIf="i !== 0"
        class="ui mini icon button"
        style="width: 20px; height: 20px; display: flex; justify-content: center; align-items: center"
        (click)="deleteFilter(i)"
      ><i class="delete icon"></i>
            </span>
    </h5>
    <app-conditions-filter-form
      filterId="inclusive"
      [filterParams]="filterParams"
      [parentForm]="inclusiveFilter"
    ></app-conditions-filter-form>
  </ng-container>

  <div class="ui divider hidden"></div>

  <div class="ui basic icon button" [class.disabled]="!this.conditionsFiltersArray.valid"
       (click)="addFilter()">
    {{'SCHEDULED_TASK_DETAIL.ADD_MORE_CONDITIONS_OR' | translate}}
    <i class="add icon"></i>
  </div>
</div>
