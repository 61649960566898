<form class="ui form error" [formGroup]="form">
  <div class="two fields">
    <div class="field required">
      <label for="name">{{ 'USERS.NAME' | translate }}</label>
      <input id="name" formControlName="name" [attr.readonly]="disableEdit ? '' : null"  type="text" placeholder="{{ 'USERS.NAME_PLACEHOLDER' | translate }}" >
    </div>
    <div class="field">
      <label for="surname">
        {{ 'USERS.SURNAME' | translate }}
      </label>
      <input id="surname" formControlName="surname" [attr.readonly]="disableEdit ? '' : null" type="text" placeholder="{{ 'USERS.SURNAME_PLACEHOLDER' | translate }}"  >
    </div>
  </div>
  <div class="two fields">
    <div class="field required">
      <label for="email">Email</label>
      <input id="email" formControlName="email" [attr.readonly]="disableEdit ? '' : null"  type="email" placeholder="{{ 'USERS.EMAIL_PLACEHOLDER' | translate }}" >
    </div>
    <div class="field password-input-container"
     [class.required]="!isEditing"
     [class.error]="form.controls.password.errors?.passwordStrengthLow && form.controls.password.touched"
    >
      <label for="password">
        {{ 'USERS.PASSWORD' | translate }}
      </label>
      <div class="password-wrapper">
        <input id="password" 
        formControlName="password" 
        [attr.readonly]="disableEdit ? '' : null" 
        [type]="passwordVisible ? 'text' : 'password'">
        <i class="{{ iconClass }} icon" 
          (click)="togglePasswordVisibility()">
        </i>
      </div>
    </div>

  </div>
  <div class="two fields">
    <div class="field required">
      <label for="companySel">{{ 'USERS.COMPANY' | translate }}</label>
      <fui-dropdown-two
        selectorId="companySel"
        [search]="true"
        [options]="companyOptions"
        valueProperty="id"
        formControlName="company"
        [readonly]="disableEdit || isClientUser"
      >
      </fui-dropdown-two>
    </div>
    <div class="field required">
      <label>{{ 'USERS.ROLE' | translate }}</label>
      <fui-dropdown-two
        selectorId="roleSel"
        valueProperty="id"
        [options]="roleOptions"
        formControlName="role"
        [readonly]="disableEdit || isClientUser"
      ></fui-dropdown-two>
    </div>
  </div>
  <div class="two fields">
    <div class="field">
      <label for="mobile">
        {{ 'USERS.MOBILE' | translate }}
      </label>
      <input id="mobile" formControlName="mobile" [attr.readonly]="disableEdit ? '' : null" type="text" placeholder="{{ 'USERS.MOBILE_PLACEHOLDER' | translate }}"  >
    </div>
  </div>
  <div class="field">
    <div class="ui error message" *ngIf="form.controls.password.errors?.passwordStrengthLow && form.controls.password.touched">
      <div class="header">{{ 'USERS.PASSWORD_WEAK' | translate }}</div>
      <p>{{ 'USERS.PASSWORD_REQUIREMENTS' | translate }}</p>
    </div>
  </div>

  <div *ngIf="!disableEdit" class="field" style="display: flex; justify-content: flex-end">
      <div class="ui primary button" [class.disabled]="!canSubmitForm()" (click)="submitForm()">{{ buttonLabel }}</div>
  </div>
</form>
