import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CompanyInterface} from '../../../companies/models/company.interface';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {CriticalityLevelInterface} from '../../../configuration/models/criticalityLevel.interface';
import {WorkOrder} from '../../models/work-order.model';
import {ProblemTypeInterface} from '../../../configuration/models/problem-type.interface';
import {ROLES} from '../../../shared/models/role.interface';
import {UserInterface} from '../../../users/models/user.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-work-order-info-form',
  templateUrl: './work-order-info-form.component.html',
  styleUrls: ['./work-order-info-form.component.scss']
})
export class WorkOrderInfoFormComponent {

  @Input() parentForm: FormGroup;

  @Input() categories: CategoryInterface[];
  @Input() companies: CompanyInterface[];
  @Input() users: UserInterface[];
  @Input() problemTypes: ProblemTypeInterface[];
  @Input() criticalityLevels: CriticalityLevelInterface[];

  @Input() incidenceWorkOrders: WorkOrder[];

  @Input() userRole: ROLES;
  @Input() disabled = false;

  constructor(private translate: TranslateService,) {}

  canViewAssignedUser() {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN].includes(this.userRole);
  }
}
