import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {FacilityComponentInterface} from '../../models/facility-element.interface';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {map, pluck, skipWhile, switchMap, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {SessionService} from '../../../shared/services/session.service';
import {FacilitiesService} from '../../facilities.service';
import {Facility} from '../../models/facility.interface';
import {ToastService} from '../../../shared/services/toast.service';
import {ROLES} from '../../../shared/models/role.interface';
import {IncidencesService} from '../../../incidences/incidences.service';
import * as QRCode from 'qrcode';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-elements-detail-page',
  templateUrl: './elements-detail-page.component.html',
  styleUrls: ['./elements-detail-page.component.scss']
})
export class ElementsDetailPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui stackable grid container';
  activeFacility$: BehaviorSubject<Facility> = new BehaviorSubject<Facility>(null);
  activeFacility: Facility;
  facilitySubs: Subscription;
  component: FacilityComponentInterface;
  component$ = new BehaviorSubject<FacilityComponentInterface>(null);

  isPreselectedToIncidence = false;
  form: FormGroup;
  hasViewer: boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private toast: ToastService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private facilitySrv: FacilitiesService,
    private incidenceSrv: IncidencesService,
    private translate: TranslateService,
  ) {
    combineLatest([
      this.route.params.pipe(pluck('id')),
      this.activeFacility$.pipe(skipWhile(facility => !facility))
    ])
    .pipe(
      takeUntil(this.destroy$),
      switchMap(([elementId, facility]) => {
        return this.facilitySrv.fetchComponentById(elementId, facility.id);
      })
    ).subscribe(component => {
      this.component = component;
      this.component$.next(component);
      this.form.setValue({
        assetIdentifier: component.assetIdentifier,
        type: component.type?.name || null,
        floor: component.space?.floor?.name || null,
        space: component.space?.name || null,
        warrantyStartOn: component.warrantyStartOn ? new Date(component.warrantyStartOn) : null,
        installatedOn: component.installatedOn ? new Date(component.installatedOn) : null,
        replacedOn: component.replacedOn ? new Date(component.replacedOn) : null,
        guid: component.externalIdentifier || null,
        description: component.description,
      });
    });

    this.form = fb.group({
      assetIdentifier: null,
      type: null,
      floor: null,
      space: null,
      warrantyStartOn: null,
      installatedOn: null,
      replacedOn: null,
      guid: null,
      description: null,
    });
  }

  ngOnInit(): void {
    this.session.activeFacility$.pipe(
      skipWhile(f => !f),
      takeUntil(this.destroy$)
    ).subscribe(facility => {
      if (this.activeFacility$.value && this.activeFacility$.value.id !== facility.id) {
        this.router.navigateByUrl('componentes');
      } else {
        this.activeFacility$.next(facility);
      }
    });

    this.facilitySubs = this.session.activeFacility$.subscribe(active => {
      this.activeFacility = active;
      if (active) {
        console.info("IMBI.viewerId:" + active.externalFacilityObject);
        this.hasViewer = this.activeFacility.externalFacilityObject != null ? true : false;
      }
    });
    
    combineLatest([
      this.component$.pipe(skipWhile(component => !component)),
      this.incidenceSrv.preselectedComponentsToIncidence$
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([component, preselectedComponents]) => {
        this.isPreselectedToIncidence = !!preselectedComponents.find(elem => elem.id === component.id);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  updateComponent() {
    const data = this.form.value;
    delete data.type;
    delete data.floor;
    delete data.space;

    this.facilitySrv.updateComponent(this.component.id, data, {facilityId: this.activeFacility$.value.id})
      .subscribe(result => {
        this.form.markAsPristine();
        this.toast.showToast({
          type: 'success',
          message: this.translate.instant('COMPONENTS.UPDATED'),
        });
      });
  }

  goToViewer() {
    this.router.navigate(['visualizador'], {state: {component: this.component}});
  }

  downloadQR() {
    // Get the current domain (origin)
    const currentDomain = window.location.origin;
    // Construct the QR code content
    const qrContent = `${currentDomain}/scanqr?facilityId=${this.activeFacility.id}&componentGUID=${this.component.externalIdentifier}`;
    
    // Generate QR code
    QRCode.toCanvas(qrContent, { errorCorrectionLevel: 'H' }, (error, canvas) => {
      if (error) {
        console.error('COMPONENTS.ERROR_QR_DOWNLOAD', error);
        return;
      }

      // Convert canvas to data URL
      const dataUrl = canvas.toDataURL('image/png');

      const fileName = this.component.space.floor.name +'-'+ this.component.space.name + '-'+this.component.name + '.png';
      // Trigger download
      const a = document.createElement('a');
      a.href = dataUrl;
      a.download = fileName;
      a.click();
    });
  }

  canEdit(): Observable<boolean> {
    return this.session.userRole$.pipe(
      map(role => {
        return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role);
      })
    );
  }

  goToFilteredIncidences(typeIds: number) {
    const navigationExtras: NavigationExtras = {
      state: {
        relatedToComponentId: this.component.id,
        filters: {
          typeIds: typeIds
        }
      }
    };
  
    this.router.navigate(['solicitudes'], navigationExtras);
  }

  navigateToComponentType() {
    this.router.navigate(['tipos-componentes', this.component.typeId]);
  }

  addToNewIncidence() {
    this.incidenceSrv.addComponentToNextIncidence(this.component);
  }

  goToFilteredTasks() {
    this.router.navigate(['tareas-programadas', 'lista'], {queryParams: {relatedToComponent: this.component.id}});
  }

  goToFilteredWorkOrders() {
    this.router.navigate(['ordenes-de-trabajo'], {state:{filters:{componentIds: this.component.id}}});
  }
}
