import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, 
  RouterStateSnapshot, UrlSegment, UrlSegmentGroup
} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }

    this.authService.setRedirectUrl(this.getRoutePath(state.url));
    this.authService.setRedirectQueryParams(this.getQueryParams(state.url));
    
    this.router.navigate(['/login']);
    return false;
  }

  getRoutePath = (url: string) : string => {
    const urlTree = this.router.parseUrl(url);
    const urlSegmentGroup: UrlSegmentGroup = urlTree.root.children['primary'];
    const urlSegments: UrlSegment[] = urlSegmentGroup.segments;
    return urlSegments.map(segment => segment.path).join('/');
  };

  getQueryParams(url: string): { [key: string]: string } {
    const urlTree = this.router.parseUrl(url);
    return urlTree.queryParams;
  }
}


