import { Component, HostBinding, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-config-general-page",
  templateUrl: "./config-general-page.component.html",
  styleUrls: ["./config-general-page.component.scss"],
})
export class ConfigGeneralPageComponent implements OnInit {
  @HostBinding("class") hostClasses = "ui container grid";

  sections: { key: string; title: string }[];
  activeSection: { key: string; title: string };

  constructor(
    private translate: TranslateService,
  ){
  }

  ngOnInit(): void {
    this.configureSections();
    this.translate.onLangChange.subscribe(() => this.configureSections());
    this.activeSection = this.sections[4];
  }

  private configureSections() {
    this.sections = [
      { key: "notifications", title: this.translate.instant("SETTINGS.NOTIFICATIONS") },
      { key: "problemTypes", title: this.translate.instant("SETTINGS.PROBLEM_TYPES") },
      { key: "criticalityLevels", title: this.translate.instant("SETTINGS.CRITICALITY_LEVELS") },
      { key: "facilityInfo", title: this.translate.instant("SETTINGS.FACILITY_INFO") },
      { key: "general", title: this.translate.instant("SETTINGS.GENERAL") },
    ];
  }

}
