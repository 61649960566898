<div class = "custome-select-container" [class]="customClass">
  <input
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="searchTerm"
    [disabled]="disabled"
    (click)="toggleDropdown()"
    (input)="onInputChange($event.target.value)"
    [value]="displayValue"
    class="custom-select-input"
  />
  <div class="custom-select-options" *ngIf="isDropdownVisible">
    <div *ngFor="let option of filteredOptions" class="custom-select-option" (click)="onOptionClick(option)">
      {{ option.name }}
    </div>
  </div>
</div>
