import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FacilityComponentInterface} from '../../../facilities/models/facility-element.interface';
import {Router} from '@angular/router';
import {IncidencesService} from '../../../incidences/incidences.service';
import { Subscription } from 'rxjs';
import { SessionService } from '../../services/session.service';
import { Facility } from 'src/app/facilities/models/facility.interface';

@Component({
  selector: 'app-element-info-panel',
  templateUrl: './element-info-panel.component.html',
  styleUrls: ['./element-info-panel.component.scss']
})
export class ElementInfoPanelComponent implements OnInit {

  @Input() element: FacilityComponentInterface;
  @Input() addToNextIncidence = true;
  @Input() addComponentToCurrentEntity = false;
  @Input() text : string;
  @Input() addComponentCallback: (event: any) => void; // callback function type

  @Output() close = new EventEmitter<null>();
  @Output() clickAddComponent = new EventEmitter<null>();
  
  facilitySubs: Subscription;
  activeFacility: Facility;
  hasViewer:boolean;

  constructor(
    private router: Router,
    private incidencesSrv: IncidencesService,
    private session: SessionService,
  ) {}

  ngOnInit(): void {
    this.facilitySubs = this.session.activeFacility$
    .subscribe(activeFacility => {
      this.activeFacility = activeFacility;
      if (activeFacility) {
        this.hasViewer = this.activeFacility.externalFacilityObject != null ? true : false;
      }
    });
  }

  goToElement() {
    const url = this.router.createUrlTree(['componentes', this.element.id]);
    window.open(url.toString(), '_blank');
  }

  // TODO: Sacar la logica de incluir el componente en la incidencia fuera del componente visual,
  //       y eliminar el servicio que tiene como dependencia, solo para esto.
  addComponentToNextIncidence() {
    if (this.addToNextIncidence) {
      this.incidencesSrv.addComponentToNextIncidence(this.element);
      this.onClose();
    }
    this.clickAddComponent.next();
  }

  onClose() {
    this.close.emit(null);
  }
}
