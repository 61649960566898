<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'SCHEDULED_TASK_DETAIL.SCHEDULED_TASKS' | translate }}</h1>
</div>
<div class="buttons-line">
  <div class="ui right floated primary icon button"
       *ngIf="canCreate() | async"
       (click)="goToCreate()"
  >{{ 'SCHEDULED_TASK_DETAIL.NEW_TASK' | translate }}
  </div>
</div>

<div class="sixteen wide column">
  <h5
    (click)="showFilters = !showFilters"
    class="ui horizontal left aligned header"
    style="cursor: pointer"
  ><i [@caretRotation]="showFilters ? 'open' : 'close'" class="caret right icon"></i>
    {{ 'SCHEDULED_TASK_DETAIL.FILTER' | translate }}
  </h5>

  <form [@filtersShow] *ngIf="showFilters" [formGroup]="filtersForm" class="ui mini form">
    <div class="fields">

      <div class="four wide field">
        <div class="field">
          <app-label-and-info
            [labelText]="'SCHEDULED_TASK_DETAIL.TYPE' | translate"
            [helpingText]="'SCHEDULED_TASK_DETAIL.CLASSIFIES_TASK' |translate"
          ></app-label-and-info>
          <fui-dropdown-two 
            [multiple]="true"
            [options]="taskTypes" 
            formControlName="typeIds"
          ></fui-dropdown-two>
        </div>
      </div>

      <div class="four wide field">
        <div class="field">
          <label>{{ 'SCHEDULED_TASK_DETAIL.STATUS' | translate }}</label>
          <pui-dropdown 
            multiple
            [options]="taskStatus" 
            formControlName="statusIds"
          ></pui-dropdown>
        </div>
      </div>

      <div class="four wide field">
        <label>{{ 'SCHEDULED_TASK_DETAIL.CATEGORY' | translate }}</label>
        <fui-dropdown-two
          selectorId="categoriesFilterSelector"
          [multiple]="true"
          [search]="true"
          [options]="categories$ | async"
          valueProperty="id"
          formControlName="categoryIds"
          (keydown)="handleKeyPress($event)"
        ></fui-dropdown-two>
      </div>

      <div class="four wide field">
        <label>{{ 'SCHEDULED_TASK_DETAIL.FLOOR' | translate }}</label>
        <fui-dropdown-two
          selectorId="floorsFilterSelector"
          [multiple]="true"
          [search]="true"
          [options]="floors$ | async"
          valueProperty="id"
          formControlName="floorIds"
          (keydown)="handleKeyPress($event)"
        ></fui-dropdown-two>
      </div>
    </div>

    <div class="fields">
      <div class="four wide field">
        <label for="nameInput">{{ 'SCHEDULED_TASK_DETAIL.NAME' | translate }}</label>
        <input id="nameInput" formControlName="name" type="text" (keydown)="handleKeyPress($event)">
      </div>
      
      <div class="four wide field">
        <label>{{ 'SCHEDULED_TASK_DETAIL.COMPANY' | translate }}</label>
        <input type="text" formControlName="company" (keydown)="handleKeyPress($event)">
      </div>

      <div class="four wide field">
        <label>{{ 'SCHEDULED_TASK_DETAIL.ASSIGNED_USER' | translate }}</label>
        <input type="text" formControlName="assignedUser" (keydown)="handleKeyPress($event)">
      </div>
      
      <div class="four wide field">
        <label>{{ 'SCHEDULED_TASK_DETAIL.SPACE' | translate }}</label>
        <input type="text" formControlName="space" (keydown)="handleKeyPress($event)">
      </div>
    </div>


    <div class="fields">
      <div class="eight wide field">
        <fui-calendar-range
          #rangeSelector
          selectorId="dateFilterSelector"
          [fromLabel]="this.selectedView === 'list' ? 'SCHEDULED_TASK_DETAIL.NEXT_EXECUTION_FROM' : 'SCHEDULED_TASK_DETAIL.FROM' | translate"
          [toLabel]="this.selectedView === 'list' ? 'SCHEDULED_TASK_DETAIL.NEXT_EXECUTION_TO' : 'SCHEDULED_TASK_DETAIL.TO' | translate"
          size="mini"
          (startDateChange)="filterStartDateChange($event)"
          (endDateChange)="filterEndDateChange($event)"
        ></fui-calendar-range>
        <app-label-and-info
          [helpingText]="'SCHEDULED_TASK_DETAIL.CALENDAR_VIEW_DESC' | translate"
        ></app-label-and-info>
      </div>

      <div class="four wide field">
        <label>{{ 'SCHEDULED_TASK_DETAIL.FREQUENCY' | translate }}</label>
        <div class="fields">
          <div class="six wide field">
            <input appNumeric integer [min]="1" type="text" formControlName="frequencyQuantity" (keydown)="handleKeyPress($event)">
          </div>
          <div class="ten wide field">
            <pui-dropdown size="mini" [options]="frequencyUnits" formControlName="frequencyUnit"></pui-dropdown>
          </div>
        </div>
      </div>

      <div class="four wide field">
        <label for="idInput">{{ 'SCHEDULED_TASK_DETAIL.ID' | translate }}</label>
        <input id="idInput" formControlName="parent" type="text" (keydown)="handleKeyPress($event)">
      </div>
    </div>

    <div class="fields">
      <div class="sixteen wide field" style="display: flex; justify-content: flex-end; align-items: flex-end">
        <div class="ui small basic button" (click)="clearFilters()">{{ 'BUTTON.CLEAR' | translate }}</div>
        <div class="ui small button" (click)="applyFilters()">{{ 'SCHEDULED_TASK_DETAIL.FILTER' | translate }}</div>
      </div>
    </div>

    <div class="fields sixteen wide">
      <div class="thirteen wide field">
      </div>
      <div class="three wide field button">
        <div class="ui small button download" (click)="downloadPDF()" style="position: relative;">
          <span *ngIf="!isDownloadingPDF"><i class="download icon"></i>{{ 'BUTTON.DOWNLOAD_PDF' | translate }}</span>
          <span *ngIf="isDownloadingPDF">
            <i class="spinner loading icon"></i> {{ 'BUTTON.DOWNLOADING_PDF' | translate }}
          </span>
        </div>
      </div>
    </div>

  </form>
</div>

<div class="sixteen wide column">
  <div class="ui stackable grid">
    <div class="four wide column">
      <div class="ui fluid vertical menu">
        <a class="item" [class.active]="selectedView === 'calendar'" routerLinkActive="active" (click)="showSection('calendar')">{{ 'SCHEDULED_TASK_DETAIL.CALENDAR_VIEW' | translate }}</a>
        <a class="item" [class.active]="selectedView === 'scheduled'" routerLinkActive="active" (click)="showSection('scheduled')">{{ 'SCHEDULED_TASK_DETAIL.AGENDA_VIEW' | translate }}</a>
        <a class="item" [class.active]="selectedView === 'list'" routerLinkActive="active" (click)="showSection('list')">{{ 'SCHEDULED_TASK_DETAIL.LIST_VIEW' | translate }}</a>
      </div>
    </div>

    <div class="twelve wide column">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
