<span
  (click)="toggleContent()" 
  (contextmenu)="showContextMenu($event)"
  [ngClass]="class"
>
  <i [@caretRotation]="showContent ? 'open' : 'close'" 
  [class.caret]="!class.includes('leaf')"
  [class.right]="!class.includes('leaf')"
  [class.icon]="!class.includes('leaf')"
  ></i>
  {{title | translate}}
</span>
<app-context-menu
  *ngIf="contextMenuVisible"
  [options]="contextMenuOptions"
  [left]="contextMenuLeft"
  [top]="contextMenuTop"
  (optionClicked)="handleContextMenuOptionClick($event)"
></app-context-menu>