import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PuiStep} from '../../models/PuiStep';

@Component({
  selector: 'pui-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Input() steps: PuiStep[];
  @Input() mode: 'horizontal' | 'vertical' | 'headerOnly' = 'horizontal';
  @Input() allowDirectStepSelect = false;

  @Input() set activeStep(step: PuiStep) {
    this._activeStep = step;
    if (this._activeStep) {
      this.activeStepPosition = this.steps.indexOf(this._activeStep);
    }
  }
  _activeStep: PuiStep;
  activeStepPosition: number;
  @Output() activeStepChange = new EventEmitter<PuiStep>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickStep(step: PuiStep) {
    if (this.allowDirectStepSelect) {
      this._activeStep = step;
      this.activeStepChange.emit(this._activeStep);
    }
  }
}
