
import { 
    ReportCostByCompanyDTO,
    ReportCostByCompanyReg,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessCostWorkerHourByCompany extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
      this.setColumnsCostTime().then(columns => {
        this.reports.tableDataParams = columns;
      });
  }
    
    getData(){
      const {
        labels: companyWorker, 
        dataA : hourlyWorkerCost, 
        dataA2 : hourlyWorkerCost2,} = this.getCostsWorkerHourByCompanyDataSets(this.reportGrossData as ReportCostByCompanyDTO);
      const dataSetsCostsWorkerHourByCompany = [{data: hourlyWorkerCost, label: this.translate.instant("REPORTS.LABOR_HOUR"),}]
      if (hourlyWorkerCost2.length > 0) {
        dataSetsCostsWorkerHourByCompany.push({data: hourlyWorkerCost2, label: this.translate.instant("REPORTS.LABOR_HOUR_2"),})
      }
      this.reports.costsWorkerHourByCompany = {
        labels: companyWorker,
        dataSets: dataSetsCostsWorkerHourByCompany
      };

      //TABLE FOR REPORT_ID.COST_BY_COMPANY
      this.processTableData('companyName');
      this.reports.showIncidenceStatusFilter = false;
 
    }
    private getCostsWorkerHourByCompanyDataSets(costsWorderHourByCompany: ReportCostByCompanyDTO) {
    
      let labels = [];
      const dataA = [];
      const dataA2 = [];
      costsWorderHourByCompany.value.forEach((reg: ReportCostByCompanyReg) => {
        labels.push(reg.companyName);
        dataA.push(reg.hourlyWorkerCost);
      });
      if (costsWorderHourByCompany.value2) {
        costsWorderHourByCompany.value2.forEach((reg: ReportCostByCompanyReg) => {
          labels.push(reg.companyName);
          dataA2.push(reg.hourlyWorkerCost);
        });
      }    
  
      labels = [...new Set(labels)];
  
      return {labels, dataA, dataA2,};
    }
}