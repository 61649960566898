import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { UtilsService } from '../../shared/services/utils.service';
import {
  ProblemTypeCreateDtoInterface,
  ProblemTypeGetPaginatedDtoInterface,
  ProblemTypeInterface
} from '../models/problem-type.interface';
import { UrlParamsInterface } from 'src/app/shared/models/utils.interface';


@Injectable()
export class ProblemTypesService {

  private readonly apiHost = environment.apiHost;

  constructor(
    private http: HttpClient, 
    private utilsService: UtilsService) {
  }

  fetchAll(queryParams: {}): Observable<ProblemTypeInterface[]> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.get<ProblemTypeInterface[]>(`${this.apiHost}/problem-types?${params.join('&')}`);
  }

  fetchPaginated(
    page: number,
    limit: number,
    queryParams: {[key: string]: string|number}): Observable<ProblemTypeGetPaginatedDtoInterface> {

      const params = this.utilsService.parseParams(queryParams);

    return this.http.get<ProblemTypeGetPaginatedDtoInterface>(`${this.apiHost}/problem-types?page=${page}&limit=${limit}&${params.join('&')}`);
  }

  create(data: ProblemTypeCreateDtoInterface, queryParams: {}): Observable<ProblemTypeInterface> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.post<ProblemTypeInterface>(`${this.apiHost}/problem-types?${params.join('&')}`, data);
  }

  patch(
    problemTypeId: number,
    data: ProblemTypeCreateDtoInterface,
    queryParams: {}): Observable<ProblemTypeInterface> {

      const params = this.utilsService.parseParams(queryParams);

    return this.http.patch<ProblemTypeInterface>(`${this.apiHost}/problem-types/${problemTypeId}?${params.join('&')}`, data);
  }

  uploadCSV(file, urlParams: UrlParamsInterface) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const params = this.utilsService.parseParams(urlParams);

    const url = `${this.apiHost}/problem-types/csv?${params.join('&')}`;
    return this.http.patch(url, formData);
  }

  delete(problemTypeId: number, queryParams: {}): Observable<any> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.delete(`${this.apiHost}/problem-types/${problemTypeId}?${params.join('&')}`);
  }
}
