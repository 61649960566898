<div class="ui grid container">
    <div class="two column row">
      <div class="column">
        <div class="ui icon buttons">
          <div (click)="previousMonth()" class="ui labeled icon button">
            <i class="left arrow icon"></i>
            {{'PREVIOUS' | translate}}
          </div>
          <div class="ui button" (click)="goToday()">{{'TODAY' | translate}}</div>
          <div (click)="nextMonth()" class="ui right labeled icon button">
            <i class="right arrow icon"></i>
            {{'NEXT' | translate}}
          </div>
        </div>
      </div>

      <div class="right aligned column">
        <h2>{{monthYearText$ | async | titlecase}}</h2>
      </div>
    </div>


    <div class="sixteen wide column">
      <div *ngFor="let day of groupedTasks | keyvalue">
        <div class="ui divider"></div>
        <div class="day-container" [ngClass]="{'current-day': isCurrentDay(day.key)}">
            <div class="day">{{ getDay(day.key) }}</div>
            <div class="month">{{ getMonth(day.key) }}</div>
            <div class="tasks">
              <div *ngFor="let task of day.value">
                <div class="task-item" (click)="eventClicked(task.id)">
                    <div class="task-status" [ngStyle]="{ 'background-color': getTaskColor(task) }"></div>
                    <p class="task-name">{{ task.name }}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  