<div class="modal">
  <div class="header">
    <h2>
      {{title}}
    </h2>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
