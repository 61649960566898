import {Injectable, OnDestroy} from '@angular/core';
import {SessionService} from '../../../shared/services/session.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WorkOrderService} from '../../work-order.service';
import {BehaviorSubject, combineLatest, concat, forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {WorkOrder} from '../../models/work-order.model';
import {filter, map, skipWhile, switchMap, take, takeLast, takeUntil} from 'rxjs/operators';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ROLES } from '../../../shared/models/role.interface';
import {WorkOrderEditDtoInterface} from '../../models/work-order.interface';
import {ToastService} from '../../../shared/services/toast.service';
import {Facility} from '../../../facilities/models/facility.interface';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {CompaniesService} from '../../../companies/companies.service';
import {CompanyInterface} from '../../../companies/models/company.interface';
import {UserInterface} from '../../../users/models/user.interface';
import {ProblemTypesService} from '../../../configuration/services/problem-types.service';
import {ProblemTypeInterface} from '../../../configuration/models/problem-type.interface';
import {CriticalityLevelInterface} from '../../../configuration/models/criticalityLevel.interface';
import {CriticalityLevelsService} from '../../../configuration/services/criticalityLevels.service';
import { FacilityElementInterface } from 'src/app/facilities/models/facility-element.interface';
import { UserService } from 'src/app/users/services/user.service';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { MATERIAL_UNITS_LITERALS, WorkOrderMaterialInterface } from '../../models/work-order-material.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DetailWorkOrderService implements OnDestroy {

  subscription: Subscription;
  workOrder$: BehaviorSubject<WorkOrder> = new BehaviorSubject<WorkOrder>(null);

  infoForm: FormGroup;
  workingTimeForm: FormGroup;
  validationEmailForm: FormGroup;
  billingForm: FormGroup;

  facility$: Observable<Facility>;
  activeFacility: Facility;
  categories: CategoryInterface[];
  companies: CompanyInterface[];
  users: UserInterface[];
  validators: UserInterface[];
  problemTypes: ProblemTypeInterface[];
  criticalityLevels: CriticalityLevelInterface[];
  selectedElement: FacilityElementInterface;
  isElementSelected = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  workOrder: WorkOrder;
  role: ROLES;

  constructor(
    private toast: ToastService,
    private fb: FormBuilder,
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private companySrv: CompaniesService,
    private workOrderService: WorkOrderService,
    private problemTypesSrv: ProblemTypesService,
    private criticalityLevelSrv: CriticalityLevelsService,
    private userService: UserService,
    private translate: TranslateService,
  ) {
    this.facility$ = this.session.activeFacility$.pipe(skipWhile(facility => !facility));

    this.initializeForms();

    this.getCategories();
    this.getCompaniesAndProblemTypes();
    this.getAssignedUsers();
    this.getValidatorsUsers();

    this.session.activeFacility$.pipe(filter(facility => !!facility))
      .pipe(
        takeUntil(this.destroy$),
        switchMap(facility => this.criticalityLevelSrv.fetchAll(facility.id))
      )
      .subscribe(levels => {
        this.criticalityLevels = levels;
      });
  
    this.subscription = this.route.paramMap.pipe(
      map(params => parseInt(params.get('id'))),
      switchMap(id => this.workOrderService.fetchOne(id))
    ).subscribe(order => this.updateFormDataFormWorkOrder(order));
  
    this.session.activeFacility$.pipe(
      skipWhile(f => !f),
      takeUntil(this.destroy$)
    ).subscribe(facility => {
      if (this.activeFacility && this.activeFacility.id !== facility.id) {
        this.router.navigateByUrl('ordenes-de-trabajo');
      } else {
        this.activeFacility = facility;
      }
    });

    this.workOrder$.subscribe(order => this.workOrder = order),
    this.session.userRole$.subscribe(role => this.role = role)
  }
  
  private initializeForms(){
    this.infoForm = this.fb.group({
      category: [null, Validators.required],
      company: [null, Validators.required],
      problem: [null, Validators.required],
      criticalityLevel: [null, Validators.required],
      parentWorkOrder: [null],
      assignedUser: [{ value: null, disabled: false }],
      description: ['']
    });

    this.workingTimeForm = this.fb.group({
      workingTimeHours: this.fb.control(null),
      workingTimeMinutes: this.fb.control(null),
    });

    this.validationEmailForm = this.fb.group({
      validationEmail : this.fb.control([''], Validators.compose([Validators.email, Validators.required])),
    });

    this.billingForm = this.fb.group({
      workingTimeCost: this.fb.control(null, Validators.required),
      workOrderMaterialsCosts: this.fb.array([])
    });
  }

  // Method to add material to FormArray
  public addMaterialToFormArray(material: WorkOrderMaterialInterface): void {
    const materialCostFormGroup = this.fb.group({
      workOrderMaterialId: this.fb.control(material.id, Validators.required),
      unitCost: this.fb.control(material.unitCost ? parseFloat(material.unitCost).toFixed(2) : null, Validators.required)
    });

    const formArray = this.billingForm.get('workOrderMaterialsCosts') as FormArray;
    formArray.push(materialCostFormGroup);
  }

  // Method to remove material from FormArray
  public removeMaterialFromFormArray(materialId: number): void {
    const formArray = this.billingForm.get('workOrderMaterialsCosts') as FormArray;
    const index = formArray.controls.findIndex(control => control.value.workOrderMaterialId === materialId);
    if (index !== -1) {
      formArray.removeAt(index);
    }
  }

  // Method to clear FormArray when needed
  public clearMaterialsFormArray(): void {
    const formArray = this.billingForm.get('workOrderMaterialsCosts') as FormArray;
    formArray.clear();
  }

  getCategories() {
    this.companySrv.fetchCategories()
      .pipe(map(categories => categories.filter(category => !category.parentCategoryId)))
      .subscribe(response => this.categories = response);
  }

  getCompaniesAndProblemTypes() {
    const changeCategory$ = combineLatest([
      this.infoForm.controls['category'].valueChanges.pipe(skipWhile(category => !category)),
      this.session.activeFacility$.pipe(skipWhile(facility => !facility))
    ]).pipe(takeUntil(this.destroy$));

    changeCategory$.pipe(
      switchMap(([category, facility]) => {
        return this.companySrv.fetchAll({facilityId: facility.id, companyTypeId: 2})
          .pipe(
            map((companies: CompanyInterface[]) => {
              return companies.filter(company => {
                return !!company.categories.find(companyCategory => companyCategory.categoryId === category.id);
              });
            })
          );
      })
    ).subscribe(companiesSameCategory => {
      this.companies = companiesSameCategory;
    });
    
    changeCategory$.pipe(
      switchMap(([category, facility]) => {
        return this.problemTypesSrv.fetchAll({
          facilityId: facility.id,
          categoryId: category.id
        });
      })
    ).subscribe(problems => this.problemTypes = problems);
  }

  getAssignedUsers(): void{
    combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.infoForm.controls.company.valueChanges
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([facility, company]) => {
        if (company) {
          return this.companySrv.fetchCompanyUsers(
            company.id,
            {
              facilityId: facility.id,
              roleIds: [ROLES.MAINTENANCE_USER, ROLES.MAINTENANCE_ADMIN, ROLES.GLOBAL_SERVICE, ROLES.OWNER]
            });
        } else {
          return of([]);
        }
      })
    ).subscribe(maintenanceUsers => this.users = maintenanceUsers);
  }

  getValidatorsUsers(): void {
    combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.infoForm.controls.company.valueChanges,
      this.workOrder$
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([facility, company, workOrder]) => {
        if (company && workOrder && workOrder.creator) {
          return this.userService.fetchAll({
            facilityIds: [facility.id],
            roleIds: [ROLES.MAINTENANCE_USER, ROLES.MAINTENANCE_ADMIN, ROLES.GLOBAL_SERVICE, ROLES.OWNER],
            companyIds: [company.id, workOrder.creator.companyId]
          });
        } else {
          return of([]);
        }
      })
    ).subscribe(validatorUsers => this.validators = validatorUsers as UserInterface[]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private assignInfoFormData(workOrder: WorkOrder) {
    this.infoForm.setValue({
      category: workOrder.category,
      company: workOrder.assignedCompany,
      problem: workOrder.problemType,
      criticalityLevel: workOrder.criticalityLevel,
      parentWorkOrder: workOrder.parentWorkOrder,
      assignedUser: workOrder.assignedUser,
      description: workOrder.description
    });
  }

  private assignWorkingTimeFormInfo(workOrder: WorkOrder) {
    if (workOrder.workingTime) {
      const hours = Math.floor(workOrder.workingTime / 60);
      const mins = workOrder.workingTime % 60;
      this.workingTimeForm.controls.workingTimeHours.setValue(hours);
      this.workingTimeForm.controls.workingTimeMinutes.setValue(mins);
    }
  }

  private assignBillingFormInfo(workOrder: WorkOrder) {
    (this.billingForm.controls.workOrderMaterialsCosts as FormArray).clear();
    workOrder.workOrderMaterials.forEach((orderMaterial) => {
      (this.billingForm.controls.workOrderMaterialsCosts as FormArray).push(this.fb.group({
        workOrderMaterialId: this.fb.control( orderMaterial.id, Validators.required),
        unitCost: this.fb.control(orderMaterial.unitCost ? parseFloat(orderMaterial.unitCost).toFixed(2) : null, Validators.required)
      }));
    });
    if (workOrder.workingTimeCost) {
      this.billingForm.get('workingTimeCost').setValue(parseFloat(workOrder.workingTimeCost).toFixed(2));
    } else {
      this.billingForm.get('workingTimeCost').setValue(null);
    }
  }

  private assignValidatorEmailFormData(workOrder: WorkOrder) {
    if (workOrder.validator) {
      this.validationEmailForm.get('validationEmail').setValue(workOrder.validator);
    }
  }

  updateFormDataFormWorkOrder(workOrder: WorkOrder) {
    this.assignInfoFormData(workOrder);
    this.assignBillingFormInfo(workOrder);
    this.assignWorkingTimeFormInfo(workOrder);
    this.assignValidatorEmailFormData(workOrder);
    

    this.session.userRole$.pipe(
      filter(role => role !== undefined && role !== null),
      take(1)
    ).subscribe(role => {
      if (
        workOrder.wasClosed() 
      || workOrder.wasFinished()
      || !([ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN].includes(role))) {
        this.infoForm.controls.assignedUser.disable();
      }
    });

    this.workOrder$.next(workOrder);
  }

  private getFormattedWorkOrderData(): WorkOrderEditDtoInterface {
    const data: WorkOrderEditDtoInterface = {
      problemTypeId: this.infoForm.value.problem?.id,
      description: this.infoForm.value.description,
      assignedCompanyId: this.infoForm.value.company?.id,
      assignedUserId: this.infoForm.value.assignedUser?.id || null,
      categoryId: this.infoForm.value.category?.id,
      criticalityLevelId: this.infoForm.value.criticalityLevel?.id,
      parentWorkOrderId: this.infoForm.value.parentWorkOrder?.id || null,

      photos: this.workOrder$.value.photos,
      workOrderMaterialsCosts: this.billingForm.value.workOrderMaterialsCosts.map(cost => {
        return Object.assign(cost, {unitCost: parseFloat(cost.unitCost)});
      }),
      workingTimeCost: this.billingForm.value.workingTimeCost
    };

    Object.keys(data).forEach(key => {
      if (data[key] === undefined) {
        delete data[key];
      }
    });

    const minutes = this.getWorkingTimeInMinutes();
    data.workingTime = minutes > 0 ? minutes : null;

    return data;
  }

  private getWorkingTimeInMinutes() {
    let minutes = 0;
    if (this.workingTimeForm.value.workingTimeHours) {
      minutes = this.workingTimeForm.value.workingTimeHours * 60;
    }
    if (this.workingTimeForm.value.workingTimeMinutes) {
      minutes = minutes + this.workingTimeForm.value.workingTimeMinutes;
    }

    return minutes;
  }

  finishWorkOrder() {
    const email = this.validationEmailForm.get('validationEmail').value?.email;
    this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1),
      switchMap(facility => {
        return concat(
          this.workOrderService.edit(
            this.workOrder$.value.id, facility.id, this.getFormattedWorkOrderData()
          ),
          this.workOrderService.finish(this.workOrder$.value.id, facility.id, email)
        );
      }),
      takeLast(1)
    ).subscribe(resultOrder => {
      this.updateFormDataFormWorkOrder(resultOrder);
    });
  }

  closeWorkOrder() {
    forkJoin([
      this.session.userRole$.pipe(
        filter(role => role !== undefined && role !== null),
        take(1)
      ),
      this.session.activeFacility$.pipe(filter(facility => !!facility), take(1)),
      this.workOrder$.pipe(filter(order => !!order), take(1))
    ]).pipe(
      switchMap(([role, facility, order]) => {
        if ([ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role) && order.isInProgress()) {
          return this.workOrderService.close(order.id, facility.id);
        } else {
          return concat(
            this.workOrderService.edit(order.id, facility.id, this.getFormattedWorkOrderData()),
            this.workOrderService.close(order.id, facility.id)
          );
        }
      }),
      takeLast(1)
    ).subscribe(resultOrder => {
      this.updateFormDataFormWorkOrder(resultOrder);
    });
  }

  saveWorkOrderChanges() {
    this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1),
      switchMap(facility => {
        return this.workOrderService.edit(
          this.workOrder$.value.id,
          facility.id,
          this.getFormattedWorkOrderData()
        );
      })
    ).subscribe(order => {
      this.updateFormDataFormWorkOrder(order);
      this.toast.showToast({
        type: 'success',
        message: this.translate.instant('WORK_ORDER_DETAIL.CHANGES_SAVED')
      });
    });
  }

  canEditInfo() {
    if (this.role === ROLES.SUPER_ADMIN) {
      return true;
    }
    return ([ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.role) && !this.workOrder?.wasInProgress());
  }

  canMessageAndAddFiles(){
    if (this.role === ROLES.SUPER_ADMIN) {
      return true;
    }
    return ([ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN].includes(this.role) 
          && !this.workOrder?.wasClosed()) 
      || ([ROLES.MAINTENANCE_USER].includes(this.role) && !this.workOrder?.wasFinished());
  }

  public canEditComponents() {
    return !this.workOrder$.value?.wasFinished() && !this.workOrder$.value?.wasClosed();
  }

  addComponentToWorkOrder(externalId: string){
    if (externalId && this.canEditComponents()) {
      combineLatest([
        this.facility$,
        this.workOrder$.pipe(skipWhile(order => !order))
      ]).pipe(
        take(1),
        switchMap(([facility, order]) => {
          return this.workOrderService.addComponent(facility.id, order.id, externalId);
        })
      ).subscribe(orderComponent => {
        this.selectedElement = orderComponent.component;
        this.isElementSelected = true;
        this.workOrder$.value.workOrderComponents.push(orderComponent);
        
        this.toast.showToast({
          type: 'success',
          message: this.translate.instant('WORK_ORDER_DETAIL.COMPONENT_SAVED')
        });
      });
    }else if (this.workOrder$.value.wasFinished()){
      this.toast.showToast({
        type: 'information',
        message: this.translate.instant('WORK_ORDER_DETAIL.COMPONENT_SAVED')
      });
    }
  }

  materialUnitLiteral(id: number) {
    return this.translate.instant(`UNIT.${MATERIAL_UNITS_LITERALS[id]}`);
  }

  downloadPDF(){
    this.workOrderService.downloadPDF(this.activeFacility.id, this.workOrder.id).subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
        const fileName = `work-order-${this.workOrder.id}-${date}.pdf`;
        saveAs(blob, fileName);
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading the PDF', error);
        this.toast.showToast({
          type: 'error',
          message: this.translate.instant('DOWNLOAD_PDF_ERROR')
        });
      }
    );
  }

}
