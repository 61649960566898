<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'WORK_ORDERS' | translate }}</h1>
</div>

<div class="sixteen wide column">
  <h5
    (click)="showFilters = !showFilters"
    class="ui left aligned header"
    style="cursor: pointer"
  >
    <i [@caretRotation]="showFilters ? 'open' : 'close'" class="caret right icon"></i>
    {{ 'FILTER' | translate }}
  </h5>

  <div [@filtersShow] *ngIf="showFilters" class="ui mini form" [formGroup]="filtersForm">
    <div class="fields">
      <div class="three wide field">
        <label>{{ 'ID' | translate }}</label>
        <input formControlName="ids" type="number" (keydown)="handleKeyPress($event)">
      </div>
      <div class="four wide field">
        <label>{{ 'STATUS' | translate }}</label>
        <fui-dropdown-two
          selectorId="statusFilter"
          [multiple]="true"
          [search]="true"
          formControlName="workOrderStateIds"
          [options]="workOrdersStatuses"
        ></fui-dropdown-two>
      </div>
      <div class="three wide field">
        <label>{{ 'PROBLEM' | translate }}</label>
        <input formControlName="problemType" type="text" (keydown)="handleKeyPress($event)">
      </div>
      <div class="three wide field">
        <label>{{ 'DESCRIPTION' | translate }}</label>
        <input formControlName="description" type="text" (keydown)="handleKeyPress($event)">
      </div>
      <div class="three wide field">
        <label>{{ 'ACTIVE' | translate }}</label>
        <input formControlName="facilityName" type="text" (keydown)="handleKeyPress($event)">
      </div>
    </div>
    <div class="fields">
      <div class="three wide field">
        <label>{{ 'CRITICALITY' | translate }}</label>
        <fui-dropdown-two
          selectorId="criticalityLevelsFilter"
          [multiple]="true"
          [search]="true"
          formControlName="criticalityLevelIds"
          [options]="criticalityLevels$ | async"
          valueProperty="id"
        ></fui-dropdown-two>
      </div>
      <div class="four wide field">
        <label>{{ 'CATEGORY' | translate }}</label>
        <fui-dropdown-two
          selectorId="categoriesFilterSelector"
          [multiple]="true"
          [search]="true"
          [options]="categories$ | async"
          valueProperty="id"
          formControlName="categories"
          (keydown)="handleKeyPress($event)"
        ></fui-dropdown-two>
      </div>
      <div class="three wide field">
        <label>{{ 'COMPANY' | translate }}</label>
        <input formControlName="companyName" type="text" (keydown)="handleKeyPress($event)">
      </div>
      <div class="three wide field">
        <label>{{ 'ASSIGNED_USER' | translate }}</label>
        <input formControlName="assignedUser" type="text" (keydown)="handleKeyPress($event)">
      </div>
    </div>
    <div class="fields">
      <div class="six wide field">
        <fui-calendar-range
          #createRangeSel
          selectorId="createRangeFilter"
          fromLabel="{{ 'CREATED_FROM' | translate }}"
          toLabel="{{ 'CREATED_TO' | translate }}"
          size="mini"
          (startDateChange)="createdFromFilter = getInitOfDay($event)"
          (endDateChange)="createdToFilter = getEndOfDay($event)"
        ></fui-calendar-range>
      </div>
      <div class="one wide field"></div>
      <div class="six wide field">
        <fui-calendar-range
          #inProgressRangeSel
          selectorId="inprogressRangeFilter"
          fromLabel="{{ 'IN_PROGRESS_FROM' | translate }}"
          toLabel="{{ 'IN_PROGRESS_TO' | translate }}"
          size="mini"
          (startDateChange)="inProgressFromFilter = getInitOfDay($event)"
          (endDateChange)="inProgressToFilter = getEndOfDay($event)"
        ></fui-calendar-range>
      </div>
    </div>
    <div class="fields">
      <div class="six wide field">
        <fui-calendar-range
          #finishRangeSel
          selectorId="finishRangeFilter"
          fromLabel="{{ 'FINISHED_FROM' | translate }}"
          toLabel="{{ 'FINISHED_TO' | translate }}"
          size="mini"
          (startDateChange)="finishFromFilter = getInitOfDay($event)"
          (endDateChange)="finishToFilter = getEndOfDay($event)"
        ></fui-calendar-range>
      </div>
      <div class="one wide field"></div>
      <div class="six wide field">
        <fui-calendar-range
          #closeRangeSel
          selectorId="closeRangeFilter"
          fromLabel="{{ 'CLOSED_FROM' | translate }}"
          toLabel="{{ 'CLOSED_TO' | translate }}"
          size="mini"
          (startDateChange)="closedFromFilter = getInitOfDay($event)"
          (endDateChange)="closedToFilter = getEndOfDay($event)"
        ></fui-calendar-range>
      </div>
      <div class="three wide field" style="display: flex; align-items: flex-end; justify-content: flex-end">
        <div class="ui small basic button" (click)="clearFilters()">{{ 'BUTTON.CLEAR' | translate }}</div>
        <div class="ui small button" (click)="applyFilters()">{{ 'BUTTON.APPLY_FILTERS' | translate }}</div>
      </div>
    </div>
    <div class="fields sixteen wide">
      <div class="thirteen wide field">
        <app-label-and-info
          [helpingText]="'PDF_INFO' | translate "
        ></app-label-and-info>
      </div>
      <div class="three wide field" style="display: flex; align-items: flex-end; justify-content: flex-end">
        <div class="ui small button" (click)="downloadPDF()" style="position: relative;">
          <span *ngIf="!isDownloadingPDF"><i class="download icon"></i>{{ 'BUTTON.DOWNLOAD_PDF' | translate }}</span>
          <span *ngIf="isDownloadingPDF">
            <i class="spinner loading icon"></i>{{ 'BUTTON.DOWNLOAD_PDF' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sixteen wide column">
  <form class="ui small form">
    <div class="fields">
      <div class="fourteen wide field"></div>
      <div class="two wide field">
        <fui-dropdown-two
          selectorId="numItemsSel"
          [options]="numItemsPerPageOptions"
          [formControl]="elementsPerPage"
        ></fui-dropdown-two>
      </div>
    </div>
  </form>
</div>

<div *ngIf="(filters$ | async) && (!workOrders || !workOrders.length)" class="sixteen wide column">
  <div class="ui segment">
    <p>{{ 'NO_WORK_ORDERS_FOUND' | translate }}</p>
  </div>
</div>

<div *ngIf="workOrders && workOrders.length" class="sixteen wide column">
  <app-work-order-table
    [workOrders]="workOrders"
    (workOrderClicked)="goToWorkOrderDetail($event)"
    [userRole]="session.userRole$ | async"
    [sortBy]="sortBy$ | async"
    (sortByChange)="sortBy$.next($event)"
  >
    <fui-pagination-menu
      [page]="page$ | async"
      [numOfPages]="numOfPages"
      (pageSelected)="page$.next($event)"
    ></fui-pagination-menu>

    <app-pagination-num-items-info
      pagination-info
      itemsName="{{ 'ORDERS' | translate }}"
      [page]="page$ | async"
      [numItemsPerPage]="numItemsPerPage()"
      [totalItems]="totalItems"
    ></app-pagination-num-items-info>
  </app-work-order-table>
</div>
