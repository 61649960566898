import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, take} from 'rxjs/operators';
import {ROLES} from '../../../shared/models/role.interface';
import {SessionService} from '../../../shared/services/session.service';
import {Incidence} from '../../models/incidence';
import {DetailIncidenceService} from './detail-incidence.service';


@Component({
  selector: 'app-detail-incidence',
  templateUrl: './detail-incidence.component.html',
  styleUrls: ['./detail-incidence.component.scss'],
  providers: [DetailIncidenceService]
})
export class DetailIncidenceComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';

  incidence: Incidence;

  userRole: ROLES;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    public detailIncidence: DetailIncidenceService
  ) {
  }

  ngOnInit(): void {
    this.session.userRole$.pipe(filter(role => role !== null && role !== undefined), take(1))
      .subscribe(role => this.userRole = role);
  }

  ngOnDestroy(): void {
  }

  canViewWorkOrders(): boolean {
    if (this.userRole === ROLES.SUPER_ADMIN) {
      return true;
    }
    return this.detailIncidence.incidence$.value?.typeId && 
      [ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole) &&
      this.detailIncidence.incidence$.value?.wasOpened();
  }

  canViewChat() {
    if (
      this.userRole === ROLES.SUPER_ADMIN ||
      this.detailIncidence.incidence$.value?.isCreated() ||
      this.detailIncidence.incidence$.value?.isOpen() ||
      this.detailIncidence.incidence$.value?.isClosed()
    ) {
      return true;
    }
    return  [ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole);
  }

  canViewRating() {
    return this.detailIncidence.incidence$.value?.wasFinished() || this.detailIncidence.incidence$.value?.isClosed();
  }
}
