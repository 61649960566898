<div class="sixteen wide column">
    <h1 class="ui dividing header">{{'SETTINGS.TITLE' | translate}}</h1>
  </div>
  
  <configuration-menu [defaultSectionKey]="'general'"></configuration-menu>
  
  <div class="sixteen wide mobile twelve wide computer column">
    <div class="ui grid">
      <div *ngIf="activeSection" class="sixteen wide column">
  
        <app-general-tab *ngIf="activeSection.key === 'general'">
        </app-general-tab>
  
      </div>
  
    </div>
  </div>
  
  