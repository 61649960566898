import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {CompanyInterface} from '../../models/company.interface';
import {CompaniesService} from '../../companies.service';
import {IGooglePlaceAddress} from '../../models/google-place-address.interface';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {map} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Facility } from 'src/app/facilities/models/facility.interface';
import { SessionService } from 'src/app/shared/services/session.service';
import { CompanyLogoService } from 'src/app/shared/services/companyLogo.service';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit {

  isEditing: boolean;

  form: FormGroup;
  contacts: FormArray;

  formattedAddress: IGooglePlaceAddress;
  company: CompanyInterface;
  companyCategories: CategoryInterface[];
  categoryOptions: CategoryInterface[];
  private isFormInitialized: boolean = false;
  logoUrl: string | null = null;
  facilitySubs: Subscription;
  activeFacility: Facility;

  @Input() set value(company: CompanyInterface) {
    if (company) {
      this.company = company;
      this.isEditing = true;
      this.form.controls.name.setValue(company.name);
      this.form.controls.cif.setValue(company.cif);
      if (company.logo){
        this.logoUrl = company.logo;
      }
      

      company.types.forEach(type => {
        if (type.companyType.id === 1) {
          this.form.controls.clientType.setValue(true);
        }
        if (type.companyType.id === 2) {
          this.form.controls.maintenanceType.setValue(true);
        }
      });

      this.companyCategories = company.categories.map(category => category.category);

      if (company.address) {
        this.formattedAddress = company.address;
        this.form.controls.address.setValue(company.address.formattedAddress);
        this.form.controls.city.setValue(company.address.city);
        this.form.controls.postalCode.setValue(company.address.postalCode);
        this.form.controls.state.setValue(company.address.state);
        this.form.controls.country.setValue(company.address.country);
      }

      if (company.companyContacts && company.companyContacts.length > 0){
        this.contacts.clear();
        company.companyContacts.forEach(contact => {
          const contactGroup = this.fb.group({
            id: [contact.id],
            name: [contact.name, CompanyFormComponent.notEmptyValidator],
            email: [contact.email],
            phone: [contact.phone]
          });
          contactGroup.setValidators([CompanyFormComponent.contactValidator]);

          this.contacts.push(contactGroup);
        });
        this.form.updateValueAndValidity();
      }

    }
  }

  @Output() submittedValue = new EventEmitter();

  constructor(
    private fb: FormBuilder, 
    private companiesService: CompaniesService,
    private sessionService: SessionService,
    private logoService: CompanyLogoService,
    ) {
  }

  private static notEmptyValidator(control: AbstractControl): { [key: string]: any } | null {
    return !control.value.trim() ? {valueCanNotBeEmpty: true} : null;
  }

  private static contactValidator(control: AbstractControl): { [key: string]: any } | null {
    const emailOrPhone = control.value.email || control.value.phone;
    return emailOrPhone ? null : {emailOrPhoneRequired: {value: control.value}};
  }

  ngOnInit(): void {
    this.facilitySubs = this.sessionService.activeFacility$.subscribe(facility => this.activeFacility = facility);

    this.companiesService.fetchCategories()
      .pipe(map(categories => categories.filter(category => !category.parentCategoryId)))
      .subscribe(categories => this.categoryOptions = categories);

    this.form = this.fb.group({
      name: ['', CompanyFormComponent.notEmptyValidator],
      cif: ['', CompanyFormComponent.notEmptyValidator],
      clientType: [false],
      maintenanceType: [false],
      categoryIds: [''],
      address: [''],
      city: [{value: '', disabled: true}],
      postalCode: [{value: '', disabled: true}],
      state: [{value: '', disabled: true}],
      country: [{value: '', disabled: true}],
      contacts: this.fb.array([]),
      logo: [''],
    });

    this.contacts = this.form.get('contacts') as FormArray;
    this.companyCategories = [];
  }

  ngOnDestroy() {
    this.facilitySubs.unsubscribe();
  }

  onSelectedAddress(address) {
    this.formattedAddress = {
      id: (this.formattedAddress || {}).id || null,
      formattedAddress: address.formatted_address,
      postalCode: (address.address_components.find(component => component.types.includes('postal_code')) || {}).long_name || '',
      city: address.address_components.find(component => component.types.includes('locality')).long_name,
      state: address.address_components.find(component => component.types.includes('administrative_area_level_2')).long_name,
      country: address.address_components.find(component => component.types.includes('country')).long_name,
      latitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng()
    };

    this.form.controls.address.setValue(this.formattedAddress.formattedAddress);
    this.form.controls.city.setValue(this.formattedAddress.city);
    this.form.controls.postalCode.setValue(this.formattedAddress.postalCode);
    this.form.controls.state.setValue(this.formattedAddress.state);
    this.form.controls.country.setValue(this.formattedAddress.country);
  }

  onLogoChange(event: any): void {
    
    const file: File = event.target.files[0];
    if (file) {
      if (this.isEditing){
        this.companiesService.uploadLogoAndGetCompany(this.activeFacility.id, this.company.id, file)
          .subscribe((company: CompanyInterface) => {
            const url: string = company.logo;
            this.logoUrl = url; 
            this.logoService.updateLogoInfo(url,company);
          });
      }else{
        this.form.controls.logo.setValue(file);
      }
      
    }
  }

  addContact() {
    const newContact = this.fb.group({
      id: [null],
      name: ['', CompanyFormComponent.notEmptyValidator],
      email: [''],
      phone: ['']
    });
    newContact.setValidators([CompanyFormComponent.contactValidator]);
    newContact.updateValueAndValidity();
    this.contacts.push(newContact);
  }

  deleteContact(index) {
    this.contacts.removeAt(index);
    this.form.markAsDirty();
  }

  categoriesChange(value: Array<CategoryInterface>) {
    // Set as pristine only on load init categories. Then mark as dirty if its modified
    if (value.length === this.companyCategories.length && !this.isFormInitialized) {
      this.form.markAsPristine({onlySelf: false});
      this.isFormInitialized = true;
    } else {
      this.form.markAsDirty();
    }

    this.form.controls.categoryIds.setValue(value.map(category => category.id));
  }

  submit(): void {
    const data = Object.assign({}, this.form.value);

    data.typeIds = [];
    if (data.clientType) {
      data.typeIds.push(1);
    }
    if (data.maintenanceType) {
      data.typeIds.push(2);
    } else {
      data.categoryIds = [];
    }

    delete data.clientType;
    delete data.maintenanceType;

    data.address = this.formattedAddress;
    this.submittedValue.emit(data);
    this.form.markAsPristine();
  }
}
