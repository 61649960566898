<a class="item" routerLink="/solicitudes/nueva">
    <div class="ui primary button">
      {{'BUTTON.NEW_REQUEST' | translate}}
      <div
        *ngIf="(incidencesService.preselectedComponentsToIncidence$ | async).length"
        class="floating ui circular teal label"
      >
        {{(incidencesService.preselectedComponentsToIncidence$ | async).length}}
      </div>
    </div>
  </a>