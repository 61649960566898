import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, combineLatest, fromEvent, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, map, skipWhile, startWith, take, takeUntil, timeout} from 'rxjs/operators';
import {SimpleSearchContentComponent} from '../simple-search-content/simple-search-content.component';

type dropdownSize = 'mini' | 'small' | 'normal' | 'large' | 'big' | 'huge' | 'massive';

@Component({
  selector: 'pui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ]
})
export class DropdownComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {

  _readonly = false;
  @Input() set readonly(value) {
    this._readonly = (value === '') || value;
  }

  _multiple = false;
  @Input() set multiple(value) {
    this._multiple = (value === '') || value;
  }

  @HostBinding('class') get hostClasses() {
    let classes = '';
    if (this._multiple) {
      classes += ' multiple';
    }
    if (this._readonly) {
      classes += ' readonly';
    }
    if (this.size !== 'normal') {
      classes += ' ' + this.size;
    }

    return classes;
  }

  @Input() size: dropdownSize = 'normal';

  _allowAdditions = false;
  @Input() set allowAdditions(value) {
    this._allowAdditions = (value === '') || value;
  }

  @Input() placeholder: string;
  @Input() name: string;

  options$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);

  @Input() set options(options: any[]) {
    this.options$.next(options);
  }

  selectableOptions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedOptions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  @Input() labelParam = 'name';
  @Input() valueParam = 'value';

  @Input() set search(value) {
    this._search = (value === '') || value;
  }

  _search = false;
  searchForm: FormControl;
  @ViewChild('search') searchInput: ElementRef;
  @ViewChild('simpleSearchContent') simpleSearchContent: SimpleSearchContentComponent;

  _asyncSearch = false;
  @Input() set asyncSearch(value) {
    this._asyncSearch = (value === '') || value;
    if (this._asyncSearch) {
      this.search = true;
    }
  }
  keyUpOnSearch$: Observable<KeyboardEvent>;
  focusOnSearch$: Observable<any>;

  @Input() asyncSearchFunction: (searchString, maxResults?) => Observable<any[]>;
  actualAsyncSearchSubs: Subscription = null;
  loadingOptions = false;

  opened = false;
  @Input() disabled = false;

  onChange = (_: any) => {};
  onTouched = () => {};

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.opened = false;
      this.searchForm.setValue('');
      if (this._asyncSearch) {
        this.selectableOptions$.next([]);
      }
    }
  }

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private elRef: ElementRef) {
    this.searchForm = new FormControl('');
  }

  ngAfterViewInit() {
    if (this._asyncSearch) {
      this.keyUpOnSearch$ = fromEvent(this.searchInput.nativeElement, 'keyup');
      this.focusOnSearch$ = fromEvent(this.searchInput.nativeElement, 'focus');

      this.keyUpOnSearch$.pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.fetchAsyncSearch(this.searchForm.value, 20);
      });

      this.focusOnSearch$
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.fetchAsyncSearch(this.searchForm.value, 20);
        });
    }
  }

  ngOnInit(): void {
    this.options$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(options => {
        const selected = this.selectedOptions$.value;
        if (selected.length) {
          const selectedInNewOptions = selected.filter(selectedOpt => options.find(newOption => this.areEqualOptions(newOption, selectedOpt)));
          const selectedNotInNewOptions = selected.filter(selectedOpt => !options.find(newOption => this.areEqualOptions(newOption, selectedOpt)));

          const newSelection = this._allowAdditions ? [...selectedInNewOptions, ...selectedNotInNewOptions] : selectedInNewOptions;
          this.selectedOptions$.next(newSelection);

          if (newSelection.length !== selected.length) {
            this.onChange(this._multiple ? selectedInNewOptions : selectedInNewOptions[0]);
          }
        }
      });

    if (!this._asyncSearch) {
      combineLatest([
        this.options$.pipe(skipWhile(options => !options)),
        this.selectedOptions$.pipe(skipWhile(selected => !selected)),
        this.searchForm.valueChanges.pipe(startWith(''))
      ]).pipe(
        takeUntil(this.destroy$),
        map(([options, selected, filter]) => {
          let selectable;
          if (filter && filter.length) {
            selectable = this.filterOptionsByString(options, filter);
          } else {
            selectable = options;
          }
          selectable = this.excludeOptions(selectable, selected);
          return selectable;
        })
      ).subscribe(options => this.selectableOptions$.next(options));
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.selectedOptions$.next([]);
    } else {
      if (Array.isArray(obj)) {
        obj.forEach(sel => this.selectOption(sel));
      } else {
        this.selectOption(obj);
      }
    }
  }


  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  areEqualOptions(opt1, opt2): boolean {
    return (opt1 && opt2) ? this.optionLabel(opt1) === this.optionLabel(opt2) : false;
  }

  optionLabel(option: any): string {
    return typeof option === 'string' ? option : option[this.labelParam];
  }

  clickOnInput() {
    if (!this.disabled && !this._readonly) {
      this.opened = true;
      if (this._search && !this._multiple) {
        this.simpleSearchContent.focusOnInput();
      }
      if (this._search && this._multiple) {
        this.searchInput.nativeElement.focus();
      }
    }
  }

  private selectOption(option: any) {
    this.selectableOptions$.pipe(
      skipWhile(options => !options || !options.length),
      take(1),
      timeout(3000)
    ).subscribe(
      options => {
        const selected = options.find(opt => this.areEqualOptions(opt, option));

        if (selected) {
          if (this._multiple) {
            this.selectedOptions$.value.push(selected);
          } else {
            this.selectedOptions$.next([selected]);
          }
        } else {
          if (this._allowAdditions) {
            this.selectedOptions$.value.push(option);
          } else {
            console.error(`Tried to set formControl value in ProtoUI Dropdown that not has this option`);
          }
        }
      },
      () => {
        if (this._allowAdditions) {
          this.selectedOptions$.value.push(option);
        } else {
          console.error(`Tried to set formControl value in ProtoUI Dropdown without options value setted`);
        }
      }
    );

    if (this._search) {
      this.searchInput?.nativeElement?.focus();
    }
  }

  private filterOptionsByString(options: any [], filter: string) {
    const regex = new RegExp(`.*${filter}.*`, 'i');
    return options.filter(opt => regex.test(this.optionLabel(opt)));
  }

  private excludeOptions(options: any[], toExclude: any[]): any[] {
    return toExclude.length ? options.filter(opt => !toExclude.find(excluded => this.areEqualOptions(opt, excluded))) : options;
  }

  clickOnOption(option: any, event: MouseEvent) {
    event.stopPropagation();
    this.selectOption(option);

    if (this._asyncSearch) {
      this.selectableOptions$.next(this.excludeOptions(this.selectableOptions$.value, [option]));
    }
    if (!this._asyncSearch) {
      this.searchForm.setValue('');
    }

    this.updateOnChange();
    this.onTouched();

    if (!this._multiple || this.selectedOptions$.value.length === this.options$.value?.length) {
      this.opened = false;
    }
  }

  clickOnDeselectOption(option: any, event: Event) {
    event.preventDefault();
    const index = this.selectedOptions$.value.indexOf(option);
    const selected = this.selectedOptions$.value;
    selected.splice(index, 1);

    this.selectedOptions$.next(selected);
    this.updateOnChange();
    this.onTouched();
  }

  fetchAsyncSearch(query: string, maxResults: number = 0): void {
    this.loadingOptions = true;
    if (this.actualAsyncSearchSubs) {
      this.actualAsyncSearchSubs.unsubscribe();
    }

    this.actualAsyncSearchSubs = this.asyncSearchFunction(query, maxResults).subscribe(options => {
      const filtered = this.excludeOptions(options, this.selectedOptions$.value);
      this.selectableOptions$.next(filtered);
      this.loadingOptions = false;
    });
  }

  keyUpOnSearch(event: KeyboardEvent) {
    if ((this._allowAdditions) && (event.key === 'Enter' || event.key === ';' || event.key === ',')) {
      const newOption = event.key === 'Enter' ? this.searchForm.value : this.searchForm.value.slice(0, -1);
      if (newOption.length) {
        this.selectedOptions$.value.push(newOption);
        this.updateOnChange();
        this.onTouched();
      }
      this.searchForm.setValue('');
    }
  }

  keyDownOnSearch(event: KeyboardEvent) {
    if (event.key === 'Backspace' && !this.searchForm.value.length) {
      this.selectedOptions$.value.pop();
      this.updateOnChange();
      this.onTouched();
    }
  }

  private updateOnChange(value = this.selectedOptions$.value) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      this.onChange(null);
    } else {
      this.onChange(this._multiple ? value : value[0]);
    }
  }
}
