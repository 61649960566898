import {
  Component, 
  HostBinding, 
  OnInit,
  } from '@angular/core';
import {DetailIncidenceService} from '../detail-incidence.service';
import {ROLES} from '../../../../shared/models/role.interface';
import {IUserLogged} from '../../../../shared/models/i-user-logged';
import {SessionService} from '../../../../shared/services/session.service';
import {filter, map, take} from 'rxjs/operators';
import {Incidence} from '../../../models/incidence';
import {IncidencesService} from '../../../incidences.service';
import {Facility} from '../../../../facilities/models/facility.interface';
import {INCIDENCE_TYPES} from '../../../models/incidence.interface';
import {FormGroup} from '@angular/forms';
import {WORK_ORDER_STATES} from '../../../../workorders/models/work-order.model';
import {Observable} from 'rxjs';
import {ToastService} from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-incidence-info',
  templateUrl: './detail-incidence-info.component.html',
  styleUrls: ['./detail-incidence-info.component.scss']
})
export class DetailIncidenceInfoComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid';
  
  private userRole: ROLES;
  private loggedUser: IUserLogged;
  private activeFacility: Facility;

  incidenceTypes : any;

  form: FormGroup;

  constructor(
    private session: SessionService,
    private incidenceSrv: IncidencesService,
    public detailIncidence: DetailIncidenceService,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.form = this.detailIncidence.incidenceForm.get('info') as FormGroup;
  }

  private translateLabels() {
    this.translate.get('REQUESTS_TYPE').subscribe(translations => {
      this.incidenceTypes = [
        {value: INCIDENCE_TYPES.INCIDENCE, name: translations.INCIDENCE},
        {value: INCIDENCE_TYPES.SERVICE_REQUEST, name: translations.SERVICE_REQUEST}
      ];
    });
  }

  ngOnInit(): void {
    this.translateLabels();

    this.session.activeFacility$.pipe(filter(facility => !!facility), take(1))
      .subscribe(facility => this.activeFacility = facility);
    this.session.userRole$.pipe(filter(role => role !== null && role !== undefined), take(1))
      .subscribe(role => this.userRole = role);
    this.session.loggedUser$.pipe(
        filter(user => !!user),
        take(1)
      ).subscribe(user => this.loggedUser = user);
  }

  canUserOpenIncidence() {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN].includes(this.userRole);
  }

  openIncidence() {
    this.incidenceSrv.setOpenState(this.detailIncidence.incidence$.value.id, this.activeFacility.id).subscribe(result => {
      this.detailIncidence.incidence$.next(new Incidence(result));
    });
  }

  hasWorkOrdersInProgress(): Observable<boolean> {
    return this.detailIncidence.workOrders$.pipe(
      map(orders => orders?.some(order => order.workOrderStateId !== WORK_ORDER_STATES.CLOSED) || false)
    );
  }

  canBeOpened(): boolean {
    return (
      [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN].includes(this.userRole) &&
      this.detailIncidence.incidence$.value?.isCreated()
    );
  }

  isOpened(): boolean {
    return this.detailIncidence.incidence$.value?.isOpen();
  }

  canBeClosed(): boolean {
    return (
      (this.detailIncidence.incidence$.value?.isCreated() &&
      this.detailIncidence.incidence$.value.creatorId === this.loggedUser.id) ||
      [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole) &&
        (this.detailIncidence.incidence$.value?.isFinished() || 
        this.detailIncidence.incidence$.value?.isCreated() ||
        this.detailIncidence.incidence$.value?.isOpen()) ||
      [ROLES.CLIENT_ADMIN].includes(this.userRole) &&
        this.detailIncidence.incidence$.value?.isOpen()
        
    );
  }

  canBeFinished(): boolean {
    return (
      [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN].includes(this.userRole) &&
      this.detailIncidence.incidence$.value?.isAssigned()
    );
  }

  canRateIncidence(): Observable<boolean> {
    return this.detailIncidence.isUserCreator().pipe(map(isCreator => {
      return isCreator && !this.detailIncidence.incidence$.value?.reviewScore 
        && (this.detailIncidence.incidence$.value?.wasFinished() || this.detailIncidence.incidence$.value?.isClosed());
    }));
  }

  finalizeIncidence() {
    this.incidenceSrv.setFinishedState(this.detailIncidence.incidence$.value.id, this.activeFacility.id).subscribe(result => {
      this.detailIncidence.incidence$.next(new Incidence(result));
    });
  }

  closeIncidence() {
    this.incidenceSrv.setClosedState(this.detailIncidence.incidence$.value.id, this.activeFacility.id).subscribe(
      result => {
        this.detailIncidence.incidence$.next(new Incidence(result));
      },
      response => {
        const message = response.error.message;
        this.toast.showToast({
          message,
          type: 'error'
        });
      }
    );
  }

  canViewIncidenceCategories() {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole);
  }

}
