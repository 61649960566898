import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {IUploadResponse} from '../shared/models/upload-response.interface';
import {IIncidencePaginatedResponse, IncidenceInterface, IncidenceReviewDtoInterface} from './models/incidence.interface';
import {ChatMessageInterface} from '../shared/models/chat-message.interface';
import {WorkOrderCreateDtoInterface} from '../workorders/models/work-order.interface';
import {WorkOrder} from '../workorders/models/work-order.model';
import {Incidence} from './models/incidence';
import {map, pluck, skipWhile, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {SessionService} from '../shared/services/session.service';
import {FacilityComponentInterface} from '../facilities/models/facility-element.interface';
import {FacilitiesService} from '../facilities/facilities.service';
import {Facility} from '../facilities/models/facility.interface';
import { UtilsService } from '../shared/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class IncidencesService implements OnDestroy {

  preselectedComponentsConfig$: Observable<{[key: number]: number[]}>;
  preselectedComponentsToIncidence$ = new BehaviorSubject<FacilityComponentInterface[]>([]);

  private apiHost: string = environment.apiHost;
  facility$: Observable<Facility>;

  destroy$ = new Subject<boolean>();

  constructor(
    private http: HttpClient, 
    private session: SessionService, 
    private facilitySrv: FacilitiesService,
    private utilsService: UtilsService) {
    this.facility$ = this.session.activeFacility$.pipe(skipWhile(f => !f), takeUntil(this.destroy$));
    this.preselectedComponentsConfig$ = this.session.userConfig$.pipe(
      takeUntil(this.destroy$),
      pluck('preselectedComponentsToIncidence'),
      map(components => components || {}));

    combineLatest([
      this.preselectedComponentsConfig$,
      this.facility$
    ])
      .pipe(
        takeUntil(this.destroy$),
        skipWhile(([config, facility]) => !config || facility === undefined || facility === null),
        tap( ([config, facility]) => {
          const components = config[facility.id];
          if (!components) {
            const updatedConfig = Object.assign({}, config);
            updatedConfig[facility.id] = [];
            this.session.setUserConfig({preselectedComponentsToIncidence: updatedConfig});
          }
        }),
        switchMap(([config, facility]) => {
          const components = config[facility.id];
          if (components && components.length) {
            return this.facilitySrv.fetchComponentsByFilter({inclusiveFilters: [{id: components}], exclusiveFilters: {}}, {facilityId: facility.id});
          } else {
            return of([]);
          }
        })
      ).subscribe((components: FacilityComponentInterface[]) => {
        this.preselectedComponentsToIncidence$.next(components);
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  uploadImage(file: File): Observable<IUploadResponse> {
    const formData = new FormData();
    formData.append('photo', file, file.name);

    return this.http.post<IUploadResponse>(`${this.apiHost}/incidences/photo`, formData);
  }

  fetchOne(incidenceId: number): Observable<Incidence> {
    return this.session.activeFacility$.pipe(
      skipWhile(f => !f),
      take(1),
      pluck('id'),
      switchMap(facilityId => {
        return this.http.get<IncidenceInterface>(`${this.apiHost}/incidences/${incidenceId}?facilityId=${facilityId}`);
      }),
      map(response => new Incidence(response))
    );
  }

  fetchAll(facilityId: number): Observable<IncidenceInterface[]> {
    return this.http.get<IncidenceInterface[]>(`${this.apiHost}/incidences?facilityId=${facilityId}`);
  }

  fetchAllWithParams(queryParams: {[key: string]: string | number | number[]}, withComponentId: number = null): Observable<IncidenceInterface[] | IIncidencePaginatedResponse> {
    const params = this.utilsService.parseParams(queryParams);

    const url = withComponentId ? `${this.apiHost}/incidences/component/${withComponentId}?${params.join('&')}` : `${this.apiHost}/incidences?${params.join('&')}`;

    return this.http.get<IncidenceInterface[] | IIncidencePaginatedResponse>(url);
  }

  create(facilityId: number, data: object): Observable<IncidenceInterface> {
    return this.http.post<IncidenceInterface>(`${this.apiHost}/incidences?facilityId=${facilityId}`, data);
  }

  update(id: number, facilityId: number, data: object): Observable<IncidenceInterface> {
    return this.http.patch<IncidenceInterface>(`${this.apiHost}/incidences/${id}?facilityId=${facilityId}`, data);
  }

  setOpenState(id: number, facilityId: number) {
    return this.http.patch<IncidenceInterface>(`${this.apiHost}/incidences/${id}/open?facilityId=${facilityId}`, {});
  }

  setClosedState(id: number, facilityId: number): Observable<IncidenceInterface> {
    return this.http.patch<IncidenceInterface>(`${this.apiHost}/incidences/${id}/close?facilityId=${facilityId}`, {});
  }

  setFinishedState(id: number, facilityId: number): Observable<IncidenceInterface> {
    return this.http.patch<IncidenceInterface>(`${this.apiHost}/incidences/${id}/finish?facilityId=${facilityId}`, {});
  }

  fetchChatMessages(incidenceId: number, facilityId: number): Observable<ChatMessageInterface[]> {
    return this.http.get<ChatMessageInterface[]>(`${this.apiHost}/incidences/${incidenceId}/chat-messages?facilityId=${facilityId}`);
  }

  createChatMessage(incidenceId: number, facilityId: number, text: string, workOrderId?: number): Observable<ChatMessageInterface> {
    let url = `${this.apiHost}/incidences/${incidenceId}/chat-messages?facilityId=${facilityId}`;

    if (workOrderId !== undefined) {
        url += `&workOrderId=${workOrderId}`;
    }

    return this.http.post<ChatMessageInterface>(url, { text });
  }


  fetchWorkOrders(incidenceId: number, facilityId: number): Observable<WorkOrder[]> {
    return this.http.get<WorkOrder[]>(`${this.apiHost}/incidences/${incidenceId}/work-orders?facilityId=${facilityId}`)
      .pipe(
        map(orders => orders.map(order => new WorkOrder(order)))
      );
  }

  createWorkOrder(incidenceId: number, facilityId: number, data: WorkOrderCreateDtoInterface): Observable<WorkOrder> {
    return this.http.post<WorkOrder>(`${this.apiHost}/incidences/${incidenceId}/work-orders?facilityId=${facilityId}`, data)
      .pipe(
        map(order => new WorkOrder(order))
      );
  }

  reviewIncidence(incidenceId: number, facilityId: number, data: IncidenceReviewDtoInterface): Observable<Incidence> {
    return this.http.patch(`${this.apiHost}/incidences/${incidenceId}/review?facilityId=${facilityId}`, data).pipe(
      map(response => new Incidence(response))
    );
  }

  addComponentToNextIncidence(component: FacilityComponentInterface) {
    const addedYet = this.preselectedComponentsToIncidence$.value
      .find(elem => elem.id === component.id);

    if (!addedYet) {
      const actualComponents = [...this.preselectedComponentsToIncidence$.value];
      actualComponents.push(component);
      this.preselectedComponentsToIncidence$.next(actualComponents);

      const identifiers = actualComponents.map(element => element.id);
      this.updatePreselectedComponentsConfig(identifiers);
    }
  }

  addComponentToCreateIncidence(componentId: string, facilityId: number) {
    this.facilitySrv.fetchComponentById(componentId, facilityId).subscribe(component => {
      this.addComponentToNextIncidence(component);
    })
  }

  deleteComponentFromNextIncidence(component: FacilityComponentInterface) {
    const index = this.preselectedComponentsToIncidence$.value
      .findIndex(elem => elem.id === component.id);

    if (index !== -1) {
      const components = [...this.preselectedComponentsToIncidence$.value];
      components.splice(index, 1);
      this.preselectedComponentsToIncidence$.next(components);

      const identifiers = components.map(comp => comp.id);
      this.updatePreselectedComponentsConfig(identifiers);
    }
  }

  clearComponentsFromNextIncidence() {
    this.updatePreselectedComponentsConfig([]);
  }

  private updatePreselectedComponentsConfig(identifiers: number[]) {
    combineLatest([
      this.preselectedComponentsConfig$,
      this.facility$
    ]).pipe(
      take(1)
    ).subscribe(([config, facility]) => {
      config[facility.id] = identifiers;
      this.session.setUserConfig({preselectedComponentsToIncidence: config});
    });
  }
}
