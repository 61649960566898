import {Component, HostBinding, OnInit} from '@angular/core';
import {DetailIncidenceService} from '../detail-incidence.service';
import {IncidencesService} from '../../../incidences.service';
import {SessionService} from '../../../../shared/services/session.service';
import {Facility} from '../../../../facilities/models/facility.interface';
import {filter, take} from 'rxjs/operators';
import {ChatMessageInterface} from '../../../../shared/models/chat-message.interface';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-detail-incidence-chat',
  templateUrl: './detail-incidence-chat.component.html',
  styleUrls: ['./detail-incidence-chat.component.scss']
})
export class DetailIncidenceChatComponent implements OnInit {

  @HostBinding('class') hostClass: 'ui grid';

  activeFacility: Facility;
  messages: ChatMessageInterface[];

  constructor(
    public detailIncidence: DetailIncidenceService,
    private incidencesSrc: IncidencesService,
    private session: SessionService
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.detailIncidence.incidence$.pipe(filter(incidence => !!incidence))
    ]).pipe(take(1))
      .subscribe(([facility, incidence]) => {
        this.activeFacility = facility;
        this.incidencesSrc.fetchChatMessages(incidence.id, facility.id)
          .subscribe(messages => this.messages = messages);
      });
  }

  saveChatMessage(text: string) {
    this.incidencesSrc.createChatMessage(
      this.detailIncidence.incidence$.value.id,
      this.activeFacility.id, text)
      .subscribe(result => {
        this.messages = [...this.messages, result];
      });
  }
}
