import {Component, HostBinding, Input, OnInit, ViewChild} from '@angular/core';
import {FacilityComponentInterface} from '../../../../facilities/models/facility-element.interface';
import {Facility} from '../../../../facilities/models/facility.interface';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, map, skipWhile, startWith, switchMap,tap} from 'rxjs/operators';
import {FacilitiesService} from '../../../../facilities/facilities.service';
import {ScheduledTasksPageViewerService} from '../../services/scheduled-tasks-page-viewer.service';
import {
  IScheduledTaskComponentSubtask,
  ISubtasks,
  ScheduledTask
} from 'src/app/scheduled-tasks/models/scheduled-task.interface';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ToastService} from 'src/app/shared/services/toast.service';
import {ROLES} from 'src/app/shared/models/role.interface';
import {AutodeskViewerComponent} from '../../../../autodesk-forge/components/autodesk-viewer/autodesk-viewer.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-page-viewer-section',
  templateUrl: './detail-page-viewer-section.component.html',
  styleUrls: ['./detail-page-viewer-section.component.scss'],
  animations: [
    trigger('myAnimation', [
      transition(
        ':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 }))
      ]
      ),
      transition(
        ':leave', [
        style({ opacity: 1 }),
        animate('200ms', style({ opacity: 0 }))
      ]
      )]
    ),
    trigger('caretRotation', [
      state('open', style({ transform: 'rotate(90deg)' })),
      state('close', style({ transform: 'rotate(0deg)' })),
      transition('open <=> close', [
        animate(200)
      ]
      )]
    ),
  ],
})
export class DetailPageViewerSectionComponent implements OnInit {

  @HostBinding('class') hostClass = 'sixteen wide column';

  @ViewChild('viewer') viewer: AutodeskViewerComponent;
  @Input() task: ScheduledTask;
  @Input() facility: Facility;
  activeFacility: Facility;
  subtaskValue$: Subject<{ scheduledTaskIndex: number, indexSubtask: number, subtaskId: number; newValue: number | null; }>;
  @Input() set components(components: FacilityComponentInterface[]) {
    this.components$.next(components);
  }
  @Input() userRole: ROLES;

  components$: BehaviorSubject<FacilityComponentInterface[]> = new BehaviorSubject<FacilityComponentInterface[]>(null);
  loadedViewer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  selectedElement: FacilityComponentInterface;
  isElementSelected = false;
  hasViewer: boolean;
  selectedElementTable: any;
  listComponentsSubtasks$: Observable<IScheduledTaskComponentSubtask[]>;
  listSubtask: Array<ISubtasks[]>;
  componentReviewed = new Map<number, boolean>();
  hasUnReviewedSubtask = new Map<number, boolean>();
  totalComponents: number;

  componentsPage$ = new BehaviorSubject<number>(1);
  componentsNumOfPages = 1;

  showComponentsTable = new Array<boolean>(10);

  subTasksReviewed = new BehaviorSubject<any[]>(null);

  searchQuery$ = new Subject<string>();

  showReadQRModal = false;
  errorTitle = '';
  errorMessage = '';
  showErrorModal = false;

  constructor(
    private facilitySrv: FacilitiesService,
    private viwerSrv: ScheduledTasksPageViewerService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    this.showComponentsTable.fill(false);
  }


  ngOnInit(): void {
    this.hasViewer = this.facility.externalFacilityObject != null ? true : false;

    if (this.hasViewer){
      combineLatest([
        this.components$.pipe(skipWhile(c => !c)),
        this.loadedViewer$.pipe(skipWhile(l => !l))
      ]).subscribe(([components, loaded]) => {
        const isolate:boolean = false;
        this.viewer.focusElements(components, isolate);
      });
    }

    this.listComponentsSubtasks$ = combineLatest(
      [
        this.componentsPage$.pipe(debounceTime(500)),
        this.searchQuery$.pipe(debounceTime(500), startWith('')),
      ]
    ).pipe(
      switchMap(([page, search]) => {
        let params = {
          taskId: this.task?.id,
          facilityId: this.facility?.id,
          page,
          limit: 10,
        };
        if (search) {
          params = Object.assign(params, { search });
        }
        return this.viwerSrv.fetchComponentsSubtask(params);
      }),
      tap((response) => {
        this.showComponentsTable.fill(false);
        this.componentsNumOfPages = response.meta.totalPages;
        this.totalComponents = response.meta.totalItems;
        this.listSubtask = response.items.map((item: IScheduledTaskComponentSubtask) => {
          return [...item.scheduledTaskComponentsSubtasks];
        });
      }),
      map(response => {
        const data: IScheduledTaskComponentSubtask[] = response.items;
        data.forEach((scheduledTask, index) => {
          this.componentReviewed.set(scheduledTask.id, scheduledTask.checkedDate !== null);
          const listSubtasks = [...scheduledTask.scheduledTaskComponentsSubtasks];
          const hastUnFinishedSubtask = listSubtasks.some(subtask => subtask.checkedOption === false);
          this.hasUnReviewedSubtask.set(index, hastUnFinishedSubtask);
        });
        return data;
      })
    );

    this.subtaskValue$ = new Subject<{ scheduledTaskIndex: number, indexSubtask: number, subtaskId: number, newValue: number | null }>();

    // Subscribe to debounced values and update the value after 3 seconds
    this.subtaskValue$.pipe(
      debounceTime(1000) // Delay emission for 3 seconds
    ).subscribe(({ scheduledTaskIndex, indexSubtask, subtaskId, newValue }) => {
      // Call the updateValue method to update the subtask value
      this.updateValue(scheduledTaskIndex, indexSubtask, subtaskId, newValue);
    });
  }

  isTaskFinished(): boolean {
    return !!this.task.finishedAt;
  }

  onClickComponentTable(scheduledTask: any): void {
    const { component } = scheduledTask;
    const { externalIdentifier } = component;
    this.selectedElemOnViewer(externalIdentifier);
    this.viewer.focusElements(component);
    this.selectedElementTable = component;
  }

  selectedElemOnViewer(externalIdentifier: string) {
    if (externalIdentifier) {
      this.facilitySrv.fetchComponentByExternalIdentifier(externalIdentifier, this.facility.id)
        .subscribe(
          component => {
            this.selectedElement = component;
            this.isElementSelected = true;
          },
          error => {
            this.selectedElement = null;
            this.isElementSelected = true;
          }
        );
    } else {
      this.selectedElement = null;
      this.isElementSelected = false;
    }
    this.viewer.resize();
  }

  showComponents() {
    this.viewer.focusElements(this.components$.value);
    this.selectedElement = null;
  }

  toggleSubtaskTables(index: number) {
    const thereAreSubtasks = this.listSubtask?.[index]?.length;
    if (thereAreSubtasks) {
      this.showComponentsTable[index] = !this.showComponentsTable[index];
    } else {
      this.toastInformationMessage(this.translate.instant('SCHEDULED_TASK_DETAIL.NO_SUBTASKS'))
    }
  }

  changeSubtask(
    subtaskId: number,
    checkedOption: boolean | null,
    componentIndex: number,
    subtaskIndex: number,
  ) {

    this.changeAllCheckOpacity('', componentIndex, checkedOption);

    const initialChecked = this.listSubtask[componentIndex][subtaskIndex].checkedOption;
    this.viwerSrv.reviewSubtask(subtaskId, this.facility.id, checkedOption)
      .subscribe(
        (response) => {
          if (Object.keys(response).length) {
            this.listSubtask[componentIndex][subtaskIndex].checkedOption = response.checkedOption;

            const hastUnFinishedSubtask = this.listSubtask[componentIndex].some(subtask => subtask.checkedOption === false);
            this.hasUnReviewedSubtask.set(componentIndex, hastUnFinishedSubtask);
            this.componentReviewed.set(
              response?.scheduledTaskComponent?.id,
              response?.allSubtasksAreCompleted,
            );
            this.toastSucessMessage();
          }
        },
        (err: any) => {
          this.listSubtask[componentIndex][subtaskIndex].checkedOption = initialChecked;
          this.toastErrorMessage(err.error.message);
        }
      );
  }

  isReviewedDisabled(scheduledTask: IScheduledTaskComponentSubtask): boolean {
    return scheduledTask.scheduledTaskComponentsSubtasks.length > 0 || this.isTaskFinished();
  }

  isReviewed(componentId: number): boolean {
    return this.componentReviewed.get(componentId);
  }

  searchComponente(search: string) {
    this.componentsPage$.next(1);
    this.searchQuery$.next(search);
  }

  reviwedAllSubtasks(componentId: number, checkedOption: boolean | null, componentIndex: number) {

    this.changeAllCheckOpacity('reviewAll', componentIndex, checkedOption);

    this.viwerSrv.reviewedAllSubtask(componentId, this.facility.id, checkedOption)
      .subscribe(
        (response: IScheduledTaskComponentSubtask) => {
          if (Object.keys(response).length) {
            this.listSubtask[componentIndex] = [...response.scheduledTaskComponentsSubtasks];

            const hastUnFinishedSubtask = response.scheduledTaskComponentsSubtasks.some(subtask => subtask.checkedOption === false);
            this.hasUnReviewedSubtask.set(componentIndex, hastUnFinishedSubtask);

            this.componentReviewed.set(response.id, response.checkedDate !== null);
            this.toastSucessMessage();
          }
        },
        (err: any) => {
          this.toastErrorMessage(err.error.message);
        }
      );
  }

  reviewComponent(componentId: number, checked: boolean) {
    this.viwerSrv.reviewComponent(componentId, this.facility.id, checked)
      .subscribe(
        (response: any) => {
          if (Object.keys(response).length) {
            this.toastSucessMessage();
          }
        },
        (err: any) => {
          this.toastErrorMessage(err.error.message);
        }
      );
  }

  getFooterMessage(): string {

    let startElement = 0;
    let endElement = 0;
    const totalItems = this.totalComponents;


    if (this.totalComponents === 0) {
      return this.translate.instant('SCHEDULED_TASK_DETAIL.NO_COMPONENTS_TO_SHOW');

    } else if (this.totalComponents <= 10) {
      endElement = this.totalComponents;
      return this.translate.instant('SCHEDULED_TASK_DETAIL.LESSTHAN_TEN_COMPONENTES', {endElement, totalComponents: this.totalComponents});

    } else if (this.componentsPage$.value > 0 && this.totalComponents >= 10) {

      startElement = this.componentsPage$.value;
      this.componentsPage$.value > 1 && (startElement = (startElement - 1) * 10);

      endElement = this.componentsPage$.value * 10;
      endElement > totalItems && (endElement = totalItems);

      return this.translate.instant('SCHEDULED_TASK_DETAIL.GREATERTHAN_THEN_COMPONENTS', { startElement, endElement, totalComponents: this.totalComponents});
    }
    return '';
  }

  shouldShowTables() {
    return [
      ROLES.SUPER_ADMIN,
      ROLES.GLOBAL_SERVICE,
      ROLES.OWNER,
      ROLES.MAINTENANCE_ADMIN,
      ROLES.MAINTENANCE_USER].includes(this.userRole);
  }

  onQRRead(qrData: string) {
    this.componentsPage$.next(1);
    this.searchQuery$.next(qrData);
  }

  onErrorReadingQR(): void {
    this.showReadQRModal = false;

    this.errorTitle = 'ERROR_READING_QR_TITLE';
    this.errorMessage = 'ERROR_READING_QR_MESSAGE';

    this.showErrorModal = true;
  }
  toastInformationMessage(message: string) {
    this.toastService.showToast({
      type: 'information',
      message: message,
      duration: 3000,
    })
  }
  toastErrorMessage(message: string) {
    this.toastService.showToast({
      type: 'error',
      message: message || this.translate.instant('SCHEDULED_TASK_DETAIL.ERROR_HAPPEND'),
      duration: 3000,
    })
  }
  toastSucessMessage(message?: string) {
    this.toastService.showToast({
      type: 'success',
      message: message || this.translate.instant('SCHEDULED_TASK_DETAIL.CHANGES_SAVED'),
      duration: 3000,
    })
  }

  changeAllCheckOpacity(option: string, componentIndex: number, checked: boolean): void {

    let changeOpacity = {
      id1: `reviewedAll_${componentIndex}_check`,
      id2: `reviewedAll_${componentIndex}_cross`,
      id3: `reviewedAll_${componentIndex}_none`,
      opacity1: '0.2',
      opacity2: '0.2',
      opacity3: '0.2',
    };

    // checked in the header of the subtask table
    if (option === 'reviewAll') {

      switch (checked) {
        case null:
          changeOpacity = {
            ...changeOpacity,
            opacity3: '1',
          };
          break;
        case false:
          changeOpacity = {
            ...changeOpacity,
            opacity2: '1',
          };
          break;
        case true:
          changeOpacity = {
            ...changeOpacity,
            opacity1: '1',
          };
          break;
        default:
          break;
    }
  }
    this.changeOpacityForMultipleIds(changeOpacity);
  }

  changeOpacityForMultipleIds = ({id1, id2, id3, opacity1, opacity2, opacity3 }) => {
    this.changeOpacity(id1, opacity1);
    this.changeOpacity(id2, opacity2);
    this.changeOpacity(id3, opacity3);
  }

  changeOpacity(id: string, opacity: string) {
    const element = document.getElementById(id);
    element.style.opacity = opacity;
  }

  resetIsolation() {
    this.viewer.resetIsolation();
  }

  onSubtaskValueChange(scheduledTaskIndex: number, indexSubtask: number, subtaskId: number, newValue: string) {
    const parsedValue = newValue !== '' ? parseFloat(newValue) : null;
    this.debouncedUpdate(scheduledTaskIndex, indexSubtask, subtaskId, parsedValue);
  }

  private debouncedUpdate(scheduledTaskIndex: number, indexSubtask: number, subtaskId: number, newValue: number | null): void {
    this.subtaskValue$.next({ scheduledTaskIndex, indexSubtask, subtaskId, newValue });
  }

  updateValue(scheduledTaskIndex: number, indexSubtask:number, subtaskId: number, newValue: number|null){
    this.viwerSrv.updateValue(subtaskId, this.facility.id, newValue)
    .subscribe(
      (response: any) => {
        if (Object.keys(response).length) {
          this.toastSucessMessage();
          this.listSubtask[scheduledTaskIndex][indexSubtask].value = isNaN(newValue)? null : newValue;
        }
      },
      (err: any) => {
        this.toastErrorMessage(err.error.message);
      }
    );
  }

  getDisplayValue(value: number): string {
    if (value != null && !isNaN(value)) {
      const parts = value.toString().split('.'); // Split the number into integer and decimal parts
      if (parts.length > 1 && parseFloat(parts[1]) === 0) {
        return parts[0]; // If the decimal part is zero, return only the integer part
      }
    }
    
    if (value === null)
      return null;

    return value.toString();
  }
  
}
