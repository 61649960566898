import {CategoryInterface} from '../../shared/models/category.interface';

export enum MATERIAL_UNITS {
  UNITS = 1,
  LITERS,
  KILOGRAMS,
  METERS,
  SQUARE_METERS,
  CUBIC_METERS
}

export const MATERIAL_UNITS_LITERALS = {
  1: 'UNITS',
  2: 'LITERS',
  3: 'KILOGRAMS',
  4: 'METERS',
  5: 'SQUARE_METERS',
  6: 'CUBIC_METERS'
};

export interface WorkOrderMaterialCreateDtoInterface {
  name: string;
  quantity: number;
  categoryId: number;
  materialUnitId: number;
  workOrderComponentsIds: number[];
}

export interface WorkOrderMaterialDeletedDtoInterface {
  id: number;
}

export interface WorkOrderMaterialInterface {
  createdAt: string;
  creatorId: number;
  id: number;
  materialId: number;
  material: MaterialInterface;
  materialUnitId: number;
  quantity: number;
  unitCost?: string;
  updatedAt?: string;
  updatorId?: number;
  workOrderId: number;
}

export interface MaterialInterface {
  id: number;
  name: string;
  category: CategoryInterface;
  categoryId: number;

  createdAt: string;
  creatorId: number;
}
