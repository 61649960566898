<ng-container *ngIf="mode === 'horizontal'">
  <div class="horizontal-steps" [class.allowSelect]="allowDirectStepSelect">
    <div class="step"
         *ngFor="let step of steps; let i = index"
         [class.active]="_activeStep === step"
         (click)="onClickStep(step)"
    >
      <span class="number">{{i + 1}}</span>
      <div class="content">
        <h1>{{step.title}}</h1>
        <p>{{step.description}}</p>
      </div>
    </div>
  </div>
  <div class="step-template">
    <ng-container *ngFor="let step of steps">
      <div *ngIf="_activeStep === step">
        <ng-container [ngTemplateOutlet]="step.templateRef"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-container *ngIf="mode === 'vertical'">
  <div class="vertical-step" *ngFor="let step of steps; let i = index">
    <div class="step" (click)="onClickStep(step)" [class.allowSelect]="allowDirectStepSelect">
      <span class="number">{{i + 1}}</span>
      <div class="content">
        <h1>{{step.title}}</h1>
        <p>{{step.description}}</p>
      </div>
    </div>
    <div *ngIf="step === _activeStep" class="step-template">
      <ng-container [ngTemplateOutlet]="step.templateRef"></ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="mode === 'headerOnly'">
  <div class="vertical-step" *ngIf="_activeStep">
    <div class="step active">
      <span class="number">{{activeStepPosition + 1}} <span class="of-count">de</span> {{steps.length}}</span>
      <div class="content">
        <h1>{{_activeStep.title}}</h1>
        <p>{{_activeStep.description}}</p>
      </div>
    </div>
    <div class="step-template">
      <ng-container [ngTemplateOutlet]="_activeStep.templateRef"></ng-container>
    </div>
  </div>
</ng-container>

