import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FacilitiesService} from '../../facilities.service';
import {SessionService} from '../../../shared/services/session.service';
import { map, pluck, skipWhile, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {FacilityComponentInterface} from '../../models/facility-element.interface';
import {PaginatedResult} from '../../../shared/models/paginated-result.interface';
import {saveAs} from 'file-saver';
import {ActivatedRoute, Router} from '@angular/router';
import {SortByData} from '../../../fomantic-ui/sortable-th/sortByData.interface';
import {ROLES} from '../../../shared/models/role.interface';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-elements-page',
  templateUrl: './elements-page.component.html',
  styleUrls: ['./elements-page.component.scss']
})
export class ElementsPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui grid container';

  filtersForm: FormGroup;
  filters$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  elements: FacilityComponentInterface[];

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  elemsByPage: FormControl;
  elemsByPageOptions = [
    {value: 10, name: '10'},
    {value: 20, name: '20'},
    {value: 40, name: '40'}
  ];
  sortedBy$: BehaviorSubject<SortByData> = new BehaviorSubject<SortByData>(null);
  totalElements: number;
  totalPages: number;

  loadingFile = false;

  @ViewChild('fileInput') fileInput: ElementRef;

  destroy$: Subject<boolean> = new Subject<boolean>();

  showErrorsModal = false;
  uploadErrors: {row: number, message: string}[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private session: SessionService,
    private facitiesSrv: FacilitiesService,
    private translate: TranslateService,
  ) {
    this.filters$.next(router.getCurrentNavigation()?.extras?.state?.filters);
    this.filtersForm = fb.group({
      assetIdentifiers: null,
      floors: null,
      spaces: null,
      types: null,
      names: null,
      expired: null
    });

    this.elemsByPage = fb.control(null);
  }

  ngOnInit(): void {
    combineLatest([
      this.filters$,
      this.sortedBy$,
      this.elemsByPage.valueChanges,
      this.page$,
      this.session.activeFacility$.pipe(skipWhile(facility => !facility))
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([filterData, sortData, elemsByPage, page, facility]) => {
        let params = {facilityId: facility.id, page, limit: elemsByPage.value};
        if (filterData){
          params = Object.assign(params, filterData);
        }
        
        if (sortData) {
          params = Object.assign(params, {
            sortBy: sortData.column, 
            order: sortData.order});
        }
        return (this.facitiesSrv.fetchComponentsFiltered(filterData, params) as Observable<PaginatedResult<FacilityComponentInterface>>);
      }),
      tap(result => {
        if (this.page$.value > result.meta.totalPages && result.meta.totalPages) {
          this.page$.next(result.meta.totalPages);
        } else {
          this.totalElements = result.meta.totalItems;
          this.totalPages = result.meta.totalPages;
        }
      }),
      pluck('items'),
      takeUntil(this.destroy$)
    )
      .subscribe(elements => this.elements = elements);

    setTimeout(() => {
      this.elemsByPage.setValue(this.elemsByPageOptions[0]);
    }, 100);


    this.uploadErrors = [];
    for (let i = 0; i < 100; i++) {
      this.uploadErrors.push({row: i, message: this.translate.instant('COMPONENTS.ROW_ERROR',{i})});
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clearFilters() {
    this.filtersForm.reset();
    this.filters$.next(null);
    this.page$.next(1);
  }

  handleKeyPress(event){
    if (event.keyCode === 13)
      this.applyFilters();
  }
  
  applyFilters() {
    const formValue = this.filtersForm.value;
    this.filters$.next(this.parseFilterForm(formValue));
    this.page$.next(1);
  }

  private parseFilterForm(formValue: any) {
    Object.keys(formValue).forEach(key => {
      if (key === 'expired') {
        formValue[key] = formValue[key];
      }else if (formValue[key]) {
        formValue[key] = [formValue[key]];
      } else {
        delete formValue[key];
      }
    });

    return formValue;
  }

  export() {
    this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.facitiesSrv.downloadCSVComponentsFiltered(this.filters$.value, {facilityId: facility.id});
      })
    ).subscribe(data => {
      const blob = new Blob([data], {type: 'text/csv'});
      const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
      const fileName = `facility-components-${date}.csv`;
      saveAs(blob, fileName);
    });
  }

  selectedFile() {
    const file = this.fileInput.nativeElement.files[0];
    this.loadingFile = true;
    this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => { 
        return this.facitiesSrv.uploadComponentsCSV(file, {facilityId: facility.id});
      })
    ).subscribe(
      (result: any) => {
        if (result.errors) {
          this.uploadErrors = result.errors;
          this.showErrorsModal = true;
        }
      },
      error => {},
      () => {
        this.loadingFile = false;
        this.fileInput.nativeElement.value = null;
      }
    );
  }

  componentSelected(element: FacilityComponentInterface) {
    this.router.navigate([element.id], {relativeTo: this.route});
  }

  canImportExport(): Observable<boolean> {
    return this.session.userRole$.pipe(
      map(role => {
        return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role);
      })
    );
  }
}
