<form class="ui form" autocomplete="off" [formGroup]="form" (ngSubmit)="submit()">
  <div class="sixteen wide column">
      <button class="ui left primary button" type="submit" [class.disabled]="isEditing ? !form.valid || !form.dirty : !form.valid">
          {{ 'SETTINGS.BUTTON_SAVE' | translate }}
      </button>
  </div>
  <div class="ui divider hidden"></div>
  <div class="two fields">
      <div class="field">
          <label for="name">{{ 'SETTINGS.FIELD_NAME' | translate }}</label>
          <input formControlName="name" type="text">
      </div>
      <div class="field">
          <label for="facilityId">{{ 'SETTINGS.FIELD_ID' | translate }}</label>
          <input id="id" formControlName="id" type="number">
      </div>
  </div>

  <div class="two fields">
      <div class="field">
          <app-label-and-info
              [labelText]="'SETTINGS.FIELD_CURRENCY' | translate"
              [helpingText]="'SETTINGS.HELP_CURRENCY' | translate"
              [forAttribute]="'currencyUnit'"
          ></app-label-and-info>
          <pui-dropdown
              search
              [options]="currencyCodes"
              formControlName="currencyUnit"
          ></pui-dropdown>
      </div>
      <div class="field">
          <label for="siteName">{{ 'SETTINGS.FIELD_SITE' | translate }}</label>
          <input id="siteName" formControlName="siteName" type="text">
      </div>
  </div>

  <h4 class="ui header">{{ 'SETTINGS.HEADER_ADDRESS' | translate }}</h4>
  <div class="field">
      <input formControlName="address"
             ngx-google-places-autocomplete
             #placesRef="ngx-places"
             (onAddressChange)="onSelectedAddress($event)"
             placeholder="{{ 'SETTINGS.PLACEHOLDER_ADDRESS' | translate }}"
             type="text"
      >
  </div>

  <div class="two fields">
      <div class="field">
          <label for="city">{{ 'SETTINGS.FIELD_CITY' | translate }}</label>
          <input formControlName="city" type="text">
      </div>
      <div class="field">
          <label for="postalCode">{{ 'SETTINGS.FIELD_POSTAL_CODE' | translate }}</label>
          <input formControlName="postalCode" type="text">
      </div>
  </div>

  <div class="two fields">
      <div class="field">
          <label for="state">{{ 'SETTINGS.FIELD_STATE' | translate }}</label>
          <input formControlName="state" type="text">
      </div>
      <div class="field">
          <label for="country">{{ 'SETTINGS.FIELD_COUNTRY' | translate }}</label>
          <input formControlName="country" type="text">
      </div>
  </div>
</form>
