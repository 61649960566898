<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'NAV.REQUESTS' | translate }}</h1>
  <div class="buttons-line">
    <div (click)="goToAddIncidence()" class="ui left floated primary button">{{ 'BUTTON.NEW_REQUEST' | translate }}</div>
  </div>
</div>

<div class="sixteen wide column">
  <h5 (click)="showFilters = !showFilters" class="ui horizontal left aligned header" style="cursor: pointer">
    <i [@caretRotation]="showFilters ? 'open' : 'close'" class="caret right icon"></i>
    {{ 'FILTER' | translate }}
  </h5>
  <div *ngIf="showFilters" @filtersShow class="ui mini form" [formGroup]="filtersForm">
    <div class="fields">
      <div class="two wide field">
        <label>{{ 'REQUESTS_TABLE.ID' | translate }}</label>
        <input type="number" min="0" formControlName="id" (keydown)="handleKeyPress($event)">
      </div>
      <div class="two wide field">
        <label>{{ 'REQUESTS_TABLE.TYPE' | translate }}</label>
        <fui-dropdown-two selectorId="typeFilterSelector" [multiple]="true" [options]="incidenceTypeOptions" formControlName="types"></fui-dropdown-two>
      </div>
      <div class="three wide field">
        <label>{{ 'REQUESTS_TABLE.ISSUE' | translate }}</label>
        <input type="text" formControlName="topicSearch" (keydown)="handleKeyPress($event)">
      </div>
      <div class="three wide field">
        <label>{{ 'REQUESTS_TABLE.DESCRIPTION' | translate }}</label>
        <input type="text" formControlName="descriptionSearch" (keydown)="handleKeyPress($event)">
      </div>
      <div class="three wide field">
        <label>{{ 'REQUESTS_TABLE.CREATOR' | translate }}</label>
        <input type="text" formControlName="creatorSearch" (keydown)="handleKeyPress($event)">
      </div>
      <div class="three wide field">
        <label>{{ 'REQUESTS_TABLE.STATUS' | translate }}</label>
        <fui-dropdown-two selectorId="stateFilterSeletor" [multiple]="true" [search]="true" [options]="incidenceStatesOptions" formControlName="statuses"></fui-dropdown-two>
      </div>
    </div>

    <div class="fields">
      <div class="four wide field">
        <label>{{ 'REQUESTS_TABLE.CATEGORY' | translate }}</label>
        <fui-dropdown-two selectorId="categoriesFilterSelector" [multiple]="true" [options]="categories$ | async" [search]="true" valueProperty="id" formControlName="categories" (keydown)="handleKeyPress($event)"></fui-dropdown-two>
      </div>
      <div class="six wide field">
        <fui-calendar-range #createRangeSel selectorId="createRangeFilter" fromLabel="{{ 'REQUESTS_TABLE.CREATED_FROM' | translate }}" toLabel="{{ 'REQUESTS_TABLE.CREATED_TO' | translate }}" size="mini" (startDateChange)="filterCreateFromDateChange($event)" (endDateChange)="filterCreateToDateChange($event)"></fui-calendar-range>
      </div>
      <div class="six wide field">
        <fui-calendar-range #closeRangeSel selectorId="closeRangeFilter" fromLabel="{{ 'REQUESTS_TABLE.CLOSED_FROM' | translate }}" toLabel="{{ 'REQUESTS_TABLE.CLOSED_TO' | translate }}" size="mini" (startDateChange)="filterClosedFromDateChange($event)" (endDateChange)="filterClosedToDateChange($event)"></fui-calendar-range>
      </div>
    </div>
    <div class="fields">
      <div class="sixteen wide field" style="display: flex; align-items: flex-end; justify-content: flex-end">
        <div class="ui small basic button" (click)="clearFilters()">{{ 'BUTTON.CLEAR' | translate }}</div>
        <div class="ui small button" (click)="applyFilters()">{{ 'BUTTON.APPLY_FILTERS' | translate }}</div>
      </div>
    </div>
  </div>
</div>

<div class="thirteen wide column"></div>
<div class="three wide column">
  <fui-dropdown [id]="'numItemsSelector'" [fluid]="true" [options]="numPagesOptions" [defaultSelected]="numPagesOptions[0]" (selectedChange)="selectedNumElemPerPage($event)"></fui-dropdown>
</div>

<div class="sixteen wide column">
  <div class="table-container">
    <table class="ui small sortable celled table">
      <thead>
        <tr>
          <th *ngFor="let column of tableColumns" [ngClass]="{
            sorted: sortBy$.value?.column === column.id,
            ascending: sortBy$.value?.column === column.id && sortBy$.value?.order === 'ASC',
            descending: sortBy$.value?.column === column.id && sortBy$.value?.order === 'DES'
          }" (click)="orderByColumn(column.id)">
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let incidence of incidences" (click)="selectedIncidence(incidence)" style="cursor: pointer">
          <td *ngFor="let columnContent of tableColumns">
            {{ parseCell(incidence[columnContent.id], columnContent.type) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-foot">
    <tfoot>
      <tr>
        <th [attr.colspan]="tableColumns.length">
          <fui-pagination-menu [page]="actualPage | async" [numOfPages]="numOfPages" (pageSelected)="actualPageSelected($event)"></fui-pagination-menu>
        </th>
        <th [attr.colspan]="tableColumns.length">
          <app-pagination-num-items-info itemsName="{{ 'REQUESTS_TABLE_TITLE' | translate }}" [page]="actualPage | async" [numItemsPerPage]="numElemPerPage | async" [totalItems]="totalElems"></app-pagination-num-items-info>
        </th>
      </tr>
    </tfoot>
  </div>
</div>
