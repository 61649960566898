import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { BaseChartComponent } from '../../base-chart.component';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent extends BaseChartComponent implements OnInit, OnChanges{

  @ViewChild(BaseChartDirective, { static: true }) chartCanvas: BaseChartDirective;

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() title: string;

  @Input() chartDataSets: ChartDataSets[];
  @Input() labels: string[];

  chartOptions: any;

  constructor(private translate: TranslateService) {
    super();
  }

  public chartColors: Array<any> = [
    { backgroundColor: '#329648', borderColor: '#329648' },
    { backgroundColor: '#005ec9', borderColor: '#005ec9' },
  ];

  ngOnInit(): void {
      moment.locale(this.translate.currentLang);//translate x-axis
      this.updateChartOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startDate || changes.endDate) {
      this.updateChartOptions();
    }
  }

  updateChartOptions(): void {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      spanGaps: true,
      elements: {
        line: {
          tension: 0,
          fill: false,
          borderWidth: 2,
        }
      },
      legend: {
        labels: {
          fontFamily: '"Exo 2", serif',
          fontColor: '#000000'
        }
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            minUnit: 'day'
          },
          ticks: {
            min: this.startDate?.getTime(),
            max: this.endDate?.getTime(),
            fontFamily: '"Exo 2", serif',
            fontColor: '#000000'
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            fontFamily: '"Exo 2", serif',
            fontColor: '#000000'
          }
        }]
      }
    };
  }

  getChartCanvas(): HTMLCanvasElement {
    const chartCanvas: HTMLCanvasElement = this.chartCanvas.chart.ctx.canvas;
    if (chartCanvas) {
      return chartCanvas;
    } else {
      console.error('Chart canvas not found.');
      return null;
    }
  }

  getTitle(): string {
    return this.title;
  }
}
