import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { IUserLogged } from '../models/i-user-logged';
import { environment } from '../../../environments/environment';
import { FacilitiesService } from '../../facilities/facilities.service';
import { ROLES } from '../models/role.interface';
import { AuthService } from '../../auth/auth.service';
import { Facility } from '../../facilities/models/facility.interface';
import { HttpClient } from '@angular/common/http';
import { skipWhile, map, takeUntil } from 'rxjs/operators';
import { UserConfig } from '../models/user-config.interface';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {

  private readonly apiHost = environment.apiHost;

  private loggedUserSubs: Subscription;
  private loggedUserSubject$: BehaviorSubject<IUserLogged> = new BehaviorSubject<IUserLogged>(null);
  public loggedUser$: Observable<IUserLogged> = this.loggedUserSubject$.asObservable();

  private userRoleSubject$: BehaviorSubject<ROLES> = new BehaviorSubject<ROLES>(null);
  public userRole$: Observable<ROLES> = this.userRoleSubject$.asObservable();
  public userRoleDescription$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private facilitiesSubject$: BehaviorSubject<Facility[]> = new BehaviorSubject<Facility[]>([]);
  public facilities$: Observable<Facility[]> = this.facilitiesSubject$.asObservable();

  private activeFacilitySubject$: BehaviorSubject<Facility> = new BehaviorSubject<Facility>(null);
  public activeFacility$: Observable<Facility> = this.activeFacilitySubject$.asObservable();

  private userConfigSubject$: BehaviorSubject<UserConfig> = new BehaviorSubject<UserConfig>(null);
  userConfig$: Observable<UserConfig> = this.userConfigSubject$.asObservable().pipe(skipWhile(c => !c));

  dataDatesRange$: BehaviorSubject<{ start: Date, end: Date }> = new BehaviorSubject<{ start: Date; end: Date }>(null);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private authSrv: AuthService,
    private facilitiesSrv: FacilitiesService,
    private translate: TranslateService,
  ) {
    this.loggedUserSubs = this.authSrv.isUserLogged$.subscribe(isLogged => {
      if (isLogged) {
        this.http.get<IUserLogged>(`${this.apiHost}/auth/me`)
          .subscribe(user => {
            this.loggedUserSubject$.next(user);
          });
        this.http.get<UserConfig>(`${this.apiHost}/config`).subscribe(config => this.userConfigSubject$.next(config));
      } else {
        this.loggedUserSubject$.next(null);
      }
    });

    this.loggedUser$.subscribe(user => {
      if (user) {
        facilitiesSrv.fetchFacilities().subscribe(response => {
          this.facilitiesSubject$.next(response);
        });
      }
    });

    this.facilities$.subscribe(facilities => {
      const cachedActiveFacilityId = localStorage.getItem('activeFacilityId');
      if (cachedActiveFacilityId) {
        this.activeFacilitySubject$.next(facilities.find(facility => facility.id === parseInt(cachedActiveFacilityId)));
      } else {
        this.activeFacilitySubject$.next(facilities[0]);
      }
    });

    this.activeFacility$.subscribe(facility => {
      if (facility) {
        const role = (facility.userFacilityRoles[0] || {}).role;
        this.userRoleSubject$.next(role ? role.id : ROLES.SUPER_ADMIN);
        this.updateUserRoleDescription(role ? role.name : 'SUPER_ADMIN');
      } else {
        this.userRoleSubject$.next(null);
        this.userRoleDescription$.next(null);
      }
    });

    // Subscribe to language changes
    this.translate.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: LangChangeEvent) => {
        const role = this.userRoleSubject$.value;
        if (role) {
          this.updateUserRoleDescription(this.getUserRoleDescriptionKey(role));
        }
      });
  }

  ngOnDestroy() {
    this.loggedUserSubs.unsubscribe();
    this.loggedUserSubject$.complete();
    this.facilitiesSubject$.complete();
    this.activeFacilitySubject$.complete();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setUserConfig(config: UserConfig) {
    const actual = this.userConfigSubject$.value || {};
    const added = Object.assign(actual, config);
    this.http.post<UserConfig>(`${this.apiHost}/config`, added)
      .subscribe(configSaved => this.userConfigSubject$.next(configSaved));
  }

  setActiveFacility(facility: Facility) {
    if (facility) {
      localStorage.setItem('activeFacilityId', facility.id.toString());
      this.activeFacilitySubject$.next(facility);
    }
  }

  /**
   * Clear cache session
   */
  public clearSession() {
    localStorage.clear();
  }

  private updateUserRoleDescription(roleName: string) {
    this.userRoleDescription$.next(this.translate.instant(`ROLES.${roleName}`));
  }

  private getUserRoleDescriptionKey(role: ROLES): string {
    switch (role) {
      case ROLES.OWNER: return 'OWNER';
      case ROLES.GLOBAL_SERVICE: return 'GLOBAL_SERVICE';
      case ROLES.CLIENT_ADMIN: return 'CLIENT_ADMIN';
      case ROLES.CLIENT_USER: return 'CLIENT_USER';
      case ROLES.MAINTENANCE_ADMIN: return 'MAINTENANCE_ADMIN';
      case ROLES.MAINTENANCE_USER: return 'MAINTENANCE_USER';
      case ROLES.SUPER_ADMIN: return 'SUPER_ADMIN';
      default: return 'UNKNOWN';
    }
  }
}
