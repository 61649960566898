import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SessionService } from '../shared/services/session.service';
import { Observable } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { ScheduledTask } from './models/scheduled-task.interface';
import { PaginatedResult } from '../shared/models/paginated-result.interface';
import { UtilsService } from '../shared/services/utils.service';
import { UrlParamsInterface } from '../shared/models/utils.interface';

@Injectable()
export class ScheduledTaskService {

  private readonly apiHost = environment.apiHost;

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private utilsService: UtilsService,
  ) { }

  fetchOneScheduledTask(id: number): Observable<ScheduledTask> {
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.http.get<ScheduledTask>(`${this.apiHost}/scheduled-tasks/${id}?facilityId=${facility.id}`);
      })
    );
  }

  fetchScheduledTasks(urlParams: UrlParamsInterface, withComponentId: number = null):
    Observable<ScheduledTask[] | PaginatedResult<ScheduledTask>> {
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        urlParams = Object.assign(urlParams, { facilityId: facility.id });
        const params = this.utilsService.parseParams(urlParams);
        const url = withComponentId !== null ? `${this.apiHost}/scheduled-tasks/component/${withComponentId}?${params.join('&')}` :
          `${this.apiHost}/scheduled-tasks?${params.join('&')}`;

        return this.http.get<ScheduledTask[] | PaginatedResult<ScheduledTask>>(url);
      })
    );
  }

  downloadFilteredPDF(queryParams: { [key: string]: string | number | number[] }): Observable<Blob> {
    const params = this.utilsService.parseParams(queryParams);
    const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
    const responseType = 'blob';

    return this.http.get(`${this.apiHost}/scheduled-tasks/download?${params.join('&')}&format=pdf`, {
      headers,
      responseType,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<Blob>) => {
        return response.body;
      })
    );
  }

  addScheduledTask(data: object): Observable<any> {
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.http.post(`${this.apiHost}/scheduled-tasks?facilityId=${facility.id}`, data);
      })
    );
  }

  editScheduledTask(taskId: number, allTasks: boolean, data: object): Observable<ScheduledTask> {
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.http.patch<ScheduledTask>(`${this.apiHost}/scheduled-tasks/${taskId}?facilityId=${facility.id}&editNext=${allTasks}`, data);
      })
    );
  }


  finishScheduledTask(taskId: number, email: string, timeSpent: number, comment: string): Observable<ScheduledTask> {
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        const data = {
          validatorEmail: email,
          timeSpent: timeSpent,
          comment: comment,
        };
        return this.http.patch<ScheduledTask>(`${this.apiHost}/scheduled-tasks/${taskId}/finish?facilityId=${facility.id}`, data);
      })
    );
  }

  deleteScheduledTask(taskId: number, allTasks = false) {
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.http.delete<ScheduledTask>(`${this.apiHost}/scheduled-tasks/${taskId}?facilityId=${facility.id}&deleteNext=${allTasks}`);
      })
    );
  }

  downloadPDF(facilityId: number, taskId: number,){
    const params = {
      facilityId
    }
    const filteredParams = this.utilsService.filterNonNullValues(params);
    const formattedParams = this.utilsService.parseParams(filteredParams);

    return this.http.get(`${this.apiHost}/scheduled-tasks/${taskId}/pdf?${formattedParams.join('&')}`, { responseType: 'blob' });
  
  }
}
