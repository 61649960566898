import { 
  Component, Input, Output, EventEmitter, 
  HostListener,ChangeDetectorRef 
} from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent{
  @Input() options: any[];
  @Input() disabled: boolean = false;
  @Input() customClass: string;
  @Input() placeholder: string = 'Select an option';
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputReset: EventEmitter<void> = new EventEmitter<void>();


  searchTerm: string = '';
  selectedOption: any;
  isDropdownVisible: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  onOptionClick(option: any): void {
    this.selectedOption = option;
    this.optionSelected.emit(this.selectedOption);
    this.isDropdownVisible = false;
    this.searchTerm = option.name;
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  onInputChange(value: string) {
    this.searchTerm = value;
    this.cdr.detectChanges();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const isClickOnAnOption = (event.target as HTMLElement).classList.contains('custom-select-option');
    const isClickOnTheInput = (event.target as HTMLElement).classList.contains('custom-select-input');

    if (!isClickOnAnOption) {
      this.handleFreeTextSelection(isClickOnTheInput);
    }
  }

  // Function to filter options based on the search term
  get filteredOptions(): any[] {
    if (!this.searchTerm) {
      return this.options;
    }
    const searchTermLower = this.searchTerm.toLowerCase();
    return this.options.filter(option => option.name.toLowerCase().includes(searchTermLower));
  }

  handleFreeTextSelection(isClickOnTheInput:boolean): void {
    
    if (this.searchTerm) {
      this.selectedOption = { name: this.searchTerm };
      this.optionSelected.emit(this.selectedOption);
    }

    this.isDropdownVisible = isClickOnTheInput;
    this.cdr.detectChanges();
  }

  resetInput(): void {
    this.searchTerm = '';
    this.inputReset.emit();
    this.cdr.detectChanges();
  }

  get displayValue(): string {
    return this.selectedOption ? this.selectedOption.name : this.searchTerm;
  }

}
