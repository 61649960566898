<div class="forgot-password-container">

  <img class="logo" src="assets/images/logo.png" alt="IMBICLOUD">

  <form class="ui form" (ngSubmit)="resetPasswordSubmit()">
    <div class="field" [class.error]="resetPasswordForm.controls['email'].hasError('email') && resetPasswordForm.controls['email'].touched">
      <input [formControl]="resetPasswordForm.controls['email']" type="email" name="email" placeholder="{{ 'EMAIL' | translate }}">
    </div>

    <button class="ui button primary" [class.loading]="loading" type="submit">{{ 'RESET_PASSWORD_BUTTON' | translate }}</button>
    <a routerLink="/login">{{ 'LOGIN_LINK' | translate }}</a>

    <div *ngIf="successMessage" class="ui success-message">
      <div class="header">{{ 'FORGOT_PWD_SUCCESS_MESSAGE' | translate }}</div>
      <p>{{ successMessage }}</p>
    </div>

    <div *ngIf="errorMessage" class="ui error-message">
      <div class="header">{{ 'FORGOT_PWD_ERROR_MESSAGE' | translate }}</div>
      <p>{{ errorMessage }}</p>
    </div>
  </form>
</div>
