
import { 
  ReportHoursByUserAndMaintenanceDTO, 
  ReportHoursByUserAndMaintenanceReg, 
  ReportMaterialCostBreakdownDTO,
  ReportStatDTO,  
} from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { TranslateService } from "@ngx-translate/core";


export class ProcessDataReport {

    reportGrossData: ReportStatDTO;
    reports: ReportsComponent;
    translate: TranslateService;

    constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
        this.translate = reports.translate;
        this.reportGrossData =  reportGrossData;
        this.reports = reports;
    }
    // ----------------------------------------------------------------------------
    getData(){
    }
    // ----------------------------------------------------------------------------
   
    protected async setColumnsCostTime(): Promise<any[]> {
        return this.translate.get([
          "REPORTS.COMPANY",
          "REPORTS.WORK_ORDERS_COUNT",
          "REPORTS.LABOR",
          "REPORTS.HOURS",
          "REPORTS.MATERIAL_COST",
          "REPORTS.TOTAL_COST",
          "REPORTS.LABOR_HOUR"
        ]).toPromise().then(translations => {
        return [
            {
              name: translations["REPORTS.COMPANY"],
              key: 'companyName'
            },
            {
              name: translations['REPORTS.WORK_ORDERS_COUNT'],
              key: 'countWorkOrders',
              type: 'number',
            },
            {
              name: translations['REPORTS.HOURS'],
              key: 'hours',
              type: 'number'
            },
            {
              name: translations['REPORTS.MATERIAL_COST'],
              key: 'materialCost',
              type: 'currency'
            },
            {
              name: translations["REPORTS.LABOR"],
              key: 'timeCost',
              type: 'currency'
            },
            {
              name: translations["REPORTS.TOTAL_COST"],
              key: 'totalCost',
              type: 'currency'
            },
            {
              name: translations["REPORTS.LABOR_HOUR"],
              key: 'hourlyWorkerCost',
              type: 'currency'
            },
          ];
      }
    )};
// ----------------------------------------------------------------------------
    protected getGroupedItems(arr, arr2, mainKey) {
      const newCostsProblemType = {};
      arr.forEach(elem => {
        if (!newCostsProblemType[elem[mainKey]]) {
          const auxVal = elem;
          auxVal.valOrder = 1;
          newCostsProblemType[elem[mainKey]] = [auxVal];
        } else {
          const auxVal = elem;
          auxVal.valOrder = 1;
          newCostsProblemType[elem[mainKey]].push(auxVal);
        }
      });
      arr2.forEach(elem2 => {
        if (!newCostsProblemType[elem2[mainKey]]) {
          const auxVal = elem2;
          auxVal.valOrder = 2;
          newCostsProblemType[elem2[mainKey]] = [auxVal];
        } else {
          const auxVal = elem2;
          auxVal.valOrder = 2;
          newCostsProblemType[elem2[mainKey]].push(auxVal);
        }
      });
      const groupArray = [];
      Object.keys(newCostsProblemType).forEach(key => {
        groupArray.push(newCostsProblemType[key].sort((a, b) => a.valOrder - b.valOrder));
      });
      return groupArray;
    }
// ----------------------------------------------------------------------------
    protected getHoursByUserAndMaintenanceDataSets(hoursByUserAndMaintenance: ReportHoursByUserAndMaintenanceDTO) {
    
      let labels = [];
      const dataA = [];
      const dataB = [];
      const dataA2 = [];
      const dataB2 = [];
      hoursByUserAndMaintenance.value.forEach((reg: ReportHoursByUserAndMaintenanceReg) => {
        labels.push(reg.userFullName);
        dataA.push(reg.correctiveHours);
        dataB.push(reg.scheduledHours);
      });
      if (hoursByUserAndMaintenance.value2) {
        hoursByUserAndMaintenance.value2.forEach((reg: ReportHoursByUserAndMaintenanceReg) => {
          labels.push(reg.userFullName);
          dataA2.push(reg.correctiveHours);
          dataB2.push(reg.scheduledHours);
        });
      }    
  
      labels = [...new Set(labels)];
  
      return {labels, dataA, dataB, dataA2, dataB2};
    }
// ----------------------------------------------------------------------------
    protected processTableData(mainKey: string) {
      this.reports.tableDataMainKey = mainKey;
      const tableDataProcessedDate1 = (this.reportGrossData as ReportMaterialCostBreakdownDTO).value;
      const tableDataProcessedDate2 = this.reportGrossData.value2 ? (this.reportGrossData as ReportMaterialCostBreakdownDTO).value2 : [];
      this.reports.tableDataGroupedItems = this.getGroupedItems(
      tableDataProcessedDate1, 
      tableDataProcessedDate2, 
      mainKey);
      this.reports.tableDataTotalItems = this.reports.tableDataGroupedItems.length;
    }
    // ----------------------------------------------------------------------------
}