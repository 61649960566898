<div class="ui form">
  <div class="four fields">
    <div class="four wide field">
      <div class="ui input focus">
        <button
          type="button"
          (click)="showReadQRModal = true"
          class="ui right labeled icon button"
        >
          <i class="right qrcode icon"></i>
          {{ 'SCAN_QR' | translate }}
        </button>
      </div>
    </div>

    <div class="field">
      <label>{{ 'FLOOR' | translate }}</label>
      <fui-dropdown
        [id]="'viewerSelectorFloorsDropdown'"
        [options]="floors"
        [search]="true"
        valueProperty="id"
        [selected]="_selectedFloor"
        (selectedChange)="floorSelected($event)"
      >
      </fui-dropdown>
    </div>
    <div class="field">
      <label>{{ 'SPACE' | translate }}</label>
      <fui-dropdown
        [id]="'viewerSelectorSpacesDropdown'"
        [options]="spaces"
        [selected]="_selectedSpace"
        [search]="true"
        valueProperty="id"
        (selectedChange)="spaceSelected($event)"
      ></fui-dropdown>
    </div>
    <div class="field">
      <label>{{ 'COMPONENT' | translate }}</label>
      <fui-dropdown
        [id]="'viewerSelectorComponentsDropdown'"
        [options]="components"
        [selected]="_selectedComponent"
        [search]="true"
        valueProperty="id"
        (selectedChange)="componentSelected($event)"
      ></fui-dropdown>
    </div>
  </div>

  <app-qr-reader-modal
    *ngIf="showReadQRModal"
    (close)="this.showReadQRModal = false"
    (dataRead)="onQRRead($event)"
    (errorOnRead)="onErrorReadingQR()"
  ></app-qr-reader-modal>

  <pui-modal *ngIf="showErrorModal" [title]="errorTitle">
    {{errorMessage}}
    <div footer>
      <div class="ui primary button" (click)="this.showErrorModal = false">
        {{ 'ACCEPT' | translate }}
      </div>
    </div>
  </pui-modal>
</div>
