
import { 
    ReportReplacementCostByTypeDTO,
    ReportReplacementCostByTypeReg,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessReplacementCostByType extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
        this.setColumnsReplacementCosts().then(columns => {
          this.reports.tableDataParams = columns;
        });
    }
    
    getData(){
        const {
            labels: componentTypeName, 
            dataA : replacementCost, 
            dataA2 : replacementCost2,
        } = this.getReplacementCostByTypeDataSets(this.reportGrossData as ReportReplacementCostByTypeDTO);
        
        const dataSetsReplacementCostByType = [{data: replacementCost, label: this.translate.instant("REPORTS.COST_BY_TYPE_LABEL_1"),}];
        
        if (replacementCost2.length > 0) {
            dataSetsReplacementCostByType.push({data: replacementCost2, label: this.translate.instant("REPORTS.COST_BY_TYPE_LABEL_2"),});
        }

        this.reports.replacementCostByType = {
            labels: componentTypeName,
            dataSets: dataSetsReplacementCostByType
        };

       //TABLE FOR REPORT_ID.REPLACEMENT_COST_BY_TYPE
       this.processTableData('componentTypeName');
       this.reports.showIncidenceStatusFilter = false;
 
    }
  
    private getReplacementCostByTypeDataSets(replacementCostByType: ReportReplacementCostByTypeDTO) {
    
        let labels = [];
        const dataA = [];
        const dataA2 = [];
        replacementCostByType.value.forEach((reg: ReportReplacementCostByTypeReg) => {
          labels.push(reg.componentTypeName);
          dataA.push(reg.totalReplacementCost);
        });
        if (replacementCostByType.value2) {
          replacementCostByType.value2.forEach((reg: ReportReplacementCostByTypeReg) => {
            labels.push(reg.componentTypeName);
            dataA2.push(reg.totalReplacementCost);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA,  dataA2};
      }

        private async setColumnsReplacementCosts(): Promise<any[]> {
          return this.translate.get([
            "REPORTS.COMPONENT_TYPE",
            "REPORTS.COMPONENTS",
            "REPORTS.REPLACEMENT_COST",
          ]).toPromise().then(translations => {
        return [
          {
            name: translations["REPORTS.COMPONENT_TYPE"],
            key: 'componentTypeName'
          },
          {
            name: translations["REPORTS.COMPONENTS"],
            key: 'countComponents',
            type: 'number',
          },
          {
            name: translations["REPORTS.REPLACEMENT_COST"],
            key: 'totalReplacementCost',
            type: 'currency'
          },
        ];
      }
    )};
}