import { Router } from "@angular/router";
import { ContextMenuOption } from "./collapsible-tree.interface";
import { IncidencesService } from "src/app/incidences/incidences.service";
import { FacilitiesService } from "src/app/facilities/facilities.service";

export enum LABEL_CONTEXT_MENU_FACILITY {
  VIEWER = 'TREE_VIEW.OPEN_VIEWER',
  DASHBOARD = 'TREE_VIEW.DASHBOARD',
  FACILITY_INFO = 'TREE_VIEW.FACILITY_INFO',
}

export enum LABEL_CONTEXT_MENU_COMPONENT {
  COMPONENT_INFO = 'TREE_VIEW.VIEW_DETAILS',
  COMPONENT_IN_VIEWER = 'TREE_VIEW.VIEW_IN_VIEWER',
  ADD_TO_CREATE_NEW_INCIDENCE = 'TREE_VIEW.ADD_TO_NEW_REQUEST',
}

export enum TREE_LEVEL {
  FACILITY = 'facility',
  FLOOR = 'floor',
  SPACE = 'space',
  COMPONENT = 'component',
}

export interface ContextMenuConfig {
  level: TREE_LEVEL;
  router: Router;
  incidenceService: IncidencesService;
  facilitiesService: FacilitiesService;
  id: string;
  facilityId?: string;
  hasViewer: boolean;
}

export abstract class ContextMenuOptions {
  protected config: ContextMenuConfig;

  constructor(config: ContextMenuConfig) {
    this.config = config;
  }

  abstract getOptions(): ContextMenuOption[];
  abstract handleOption(option: ContextMenuOption): void;
}

export function isContextMenuFacility(label: LABEL_CONTEXT_MENU_FACILITY) {
  return [
    LABEL_CONTEXT_MENU_FACILITY.DASHBOARD,
    LABEL_CONTEXT_MENU_FACILITY.VIEWER,
    LABEL_CONTEXT_MENU_FACILITY.FACILITY_INFO
  ].includes(label);
}

export function isContextMenuComponent(label: LABEL_CONTEXT_MENU_COMPONENT) {
  return [
    LABEL_CONTEXT_MENU_COMPONENT.ADD_TO_CREATE_NEW_INCIDENCE,
    LABEL_CONTEXT_MENU_COMPONENT.COMPONENT_INFO,
    LABEL_CONTEXT_MENU_COMPONENT.COMPONENT_IN_VIEWER
  ].includes(label);
}

export class ContextMenuFacility extends ContextMenuOptions {
  constructor(config: ContextMenuConfig) {
    super(config);
  }

  getOptions(): ContextMenuOption[] {
    if (this.config.hasViewer)
      return [
        { label: LABEL_CONTEXT_MENU_FACILITY.DASHBOARD },
        { label: LABEL_CONTEXT_MENU_FACILITY.VIEWER },
        { label: LABEL_CONTEXT_MENU_FACILITY.FACILITY_INFO }
      ];
    else
      return [
        { label: LABEL_CONTEXT_MENU_FACILITY.DASHBOARD },
        { label: LABEL_CONTEXT_MENU_FACILITY.FACILITY_INFO }
      ];
  }

  handleOption(option: ContextMenuOption) {
    switch (option.label) {
      case LABEL_CONTEXT_MENU_FACILITY.DASHBOARD:
        this.config.router.navigate(['/dashboard']);
        break;
      case LABEL_CONTEXT_MENU_FACILITY.VIEWER:
        this.config.router.navigate(['/visualizador']);
        break;
      case LABEL_CONTEXT_MENU_FACILITY.FACILITY_INFO:
        this.config.router.navigate(['/configuracion/facility-info']);
        break;
    }
  }
}

export class ContextMenuComponent extends ContextMenuOptions {
  constructor(config: ContextMenuConfig) {
    super(config);
  }

  getOptions(): ContextMenuOption[] {
    if (this.config.hasViewer)
      return [
        { label: LABEL_CONTEXT_MENU_COMPONENT.COMPONENT_IN_VIEWER },
        { label: LABEL_CONTEXT_MENU_COMPONENT.COMPONENT_INFO },
        { label: LABEL_CONTEXT_MENU_COMPONENT.ADD_TO_CREATE_NEW_INCIDENCE }
      ];
    else
      return [
        { label: LABEL_CONTEXT_MENU_COMPONENT.COMPONENT_INFO },
        { label: LABEL_CONTEXT_MENU_COMPONENT.ADD_TO_CREATE_NEW_INCIDENCE }
      ];
  }

  handleOption(option: ContextMenuOption) {
    switch (option.label) {
      case LABEL_CONTEXT_MENU_COMPONENT.ADD_TO_CREATE_NEW_INCIDENCE:
        this.config.incidenceService.addComponentToCreateIncidence(this.config.id, parseInt(this.config.facilityId, 10));
        break;
      case LABEL_CONTEXT_MENU_COMPONENT.COMPONENT_INFO:
        this.config.facilitiesService.goToComponentInfo(this.config.id);
        break;
      case LABEL_CONTEXT_MENU_COMPONENT.COMPONENT_IN_VIEWER:
        this.config.facilitiesService.showComponentInViewer(this.config.id, parseInt(this.config.facilityId, 10));
        break;
    }
  }
}
