<div class="sixteen wide mobile four wide computer column">
  <fui-menu
    [options]="{
          vertical: true,
          pointing: true
        }"
    [items]="sections$ | async"
    [activeItem]="activeSection"
    (selectedItem)="selectedSection($event)"
  ></fui-menu>
</div>