import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CompaniesService} from '../../companies.service';
import {Facility} from '../../../facilities/models/facility.interface';
import {SessionService} from '../../../shared/services/session.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';

  facilitySubs: Subscription;
  activeFacility: Facility;

  duplicatedCIF: boolean;

  constructor(private router: Router, private session: SessionService, private companiesSrv: CompaniesService) {}

  submitForm(data) {

    this.companiesSrv.createCompany(this.activeFacility.id, data).subscribe( response => {
      this.router.navigate(['/empresas'], {
        state: {
          successfulCompanyCreate: true
        }
      });
    }, error => {
      this.duplicatedCIF = true;
    });
  }

  ngOnInit(): void {
    this.facilitySubs = this.session.activeFacility$.subscribe(facility => this.activeFacility = facility);
  }

  ngOnDestroy() {
    this.facilitySubs.unsubscribe();
  }
}
