import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from './shared/services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'imbicloud-front';

  constructor(private router: Router, private toast: ToastService) {
    this.checkChangeRoute();
  }

  checkChangeRoute() {
    this.router.events.subscribe(() => {
      this.toast.hideToast();
    });
  }
}
