<div class="sixteen wide column">
  <div *ngIf="hasViewer" 
    class="ui button" 
    (click)="showAllComponents()" >{{'SHOW_COMPONENTS' | translate}}</div>
</div>

<div class="sixteen wide column">
  <div class="ui horizontal selection list">
    <div *ngFor="let orderComponent of (workOrder$ | async)?.workOrderComponents" class="item">
                <span style="margin-right: 10px" (click)="showComponent(orderComponent.component)" >
                  {{orderComponent?.component?.name}}
                </span>
      <div *ngIf="detailWorkOrder && detailWorkOrder.canEditComponents()"
           class="ui mini icon button"
           (click)="deleteWorkOrderComponent(orderComponent)">
        <i class="delete icon"></i>
      </div>
    </div>
  </div>
</div>

<div *ngIf="hasViewer && detailWorkOrder && detailWorkOrder.canEditComponents()"
  class="sixteen wide column" style="display: flex;">
  <i class="circle info icon"></i>
  <span 
  >{{'DOUBLE_CLICK_ADD_COMPONENT_WO' | translate}}</span>
</div>

<div *ngIf="detailWorkOrder && detailWorkOrder.canEditComponents()"
class="sixteen wide column selector">
  <div class="ui segment navigation-form">
    <app-viewer-element-selector
      [facility]="activeFacility"
      (selectedSpace)="selectedSpaceOnSelector($event)"
      (selectedComponent)="selectedComponentOnSelector($event)"
    >
    </app-viewer-element-selector>
  </div>
</div>

<div *ngIf="hasViewer" [ngClass]="isElementSelected ? 'eleven wide column' : 'sixteen wide column'" style="height: 500px">
  <app-autodesk-viewer
    #viewer
    [document]="(this.facility$ | async).externalFacilityObject"
    (selectedElement)="showComponentInfo($event)"
    (loaded)="loadedViewer()"
    (doubleClick)="addComponentToWorkOrder($event)"
  ></app-autodesk-viewer>
</div>

<div class="five wide column"
     *ngIf="isElementSelected">
  <app-element-info-panel
    class="element-panel"
    [element]="selectedElement"
    [addToNextIncidence]="true"
    (close)="closeElementInfoPanel()"
    [addComponentToCurrentEntity]="detailWorkOrder && detailWorkOrder.canEditComponents()"
    [addToNextIncidence]="true"
    [addComponentCallback]="getAddComponentCallback()"
    [text]="'ADD_TO_THIS_WO' | translate"
  ></app-element-info-panel>
</div>



