import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fui-menu',
  templateUrl: './menu.component.html'
})
export class FuiMenuComponent implements OnInit {

  @HostBinding('attr.class') hostClasses = FuiMenuComponent.generateHostClasses({});

  @Input() items: { key: string, title: string }[];
  @Input('options') set setOptions(options: any) {
    this.hostClasses = FuiMenuComponent.generateHostClasses(options);
  }

  @Input() activeItem: { key: string, title: string };

  @Output() selectedItem: EventEmitter<{ key: string, title: string }> = new EventEmitter<{ key: string, title: string }>();

  private static generateHostClasses(options) {
    const classes = [];
    classes.push('ui');
    if (options.vertical) {
      classes.push('vertical');
    }
    if (options.secondary) {
      classes.push('secondary');
    }
    if (options.pointing) {
      classes.push('pointing');
    }
    classes.push('fluid');
    classes.push('menu');

    return classes.join(' ');
  }

  constructor() {
  }

  ngOnInit(): void {
  }

}
