
import { 
    ReportStatDTO,
    ReportWorkByComponentDTO,
    ReportWorkByComponentReg,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessWorkByComponent extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
        this.setColumnsWorkByComponent().then(columns => {
          this.reports.tableDataParams = columns;
        });
    }
    
    getData(){
        const {
          labels: componentName, 
          correctiveData : WOCount, 
          correctiveData2 : WOCount2, 
          scheduledData : jobsCount,
          scheduledData2 : jobsCount2, 
        } = this.getWorkByComponentDataSets(this.reportGrossData as ReportWorkByComponentDTO);

          const dataSetsWorkByComponent = [
            {data: WOCount, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_1"), stack: 'One date'},
            {data: jobsCount, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_1"), stack: 'One date'}
        ];

        if (jobsCount2.length > 0) {
          dataSetsWorkByComponent.push(
              {data: WOCount2, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_2"), stack: 'Other date'},
              {data: jobsCount2, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_2"), stack: 'Other date'},
              );
        }

          this.reports.workByComponent = {
            labels: componentName,
            dataSets: dataSetsWorkByComponent
          };

          //TABLE FOR REPORT_ID.WORK_BY_COMPONENT:
          this.processTableData('componentName');  
          this.reports.showIncidenceStatusFilter = false;
 
    }
    // ----------------------------------------------------------------------------
    private getWorkByComponentDataSets(workByComponent: ReportWorkByComponentDTO) {
        let labels = [];
        const correctiveData = [];
        const scheduledData = [];
        const correctiveData2 = [];
        const scheduledData2 = [];
        const TOP_RANK = 30;
      
        workByComponent.value.slice(0, TOP_RANK).forEach((reg: ReportWorkByComponentReg) => {
          labels.push(reg.componentName);
          correctiveData.push(reg.workOrdersCount);
          scheduledData.push(reg.scheduledTaskCount);
        });
      
        if (workByComponent.value2) {
          workByComponent.value2.slice(0, TOP_RANK).forEach((reg: ReportWorkByComponentReg) => {
            labels.push(reg.componentName);
            correctiveData2.push(reg.workOrdersCount);
            scheduledData2.push(reg.scheduledTaskCount);
          });
        }
        labels = [...new Set(labels)];
      
        return { labels, correctiveData, scheduledData, correctiveData2, scheduledData2 };
      }
// ----------------------------------------------------------------------------

  private async setColumnsWorkByComponent(): Promise<any[]> {
      return this.translate.get([
            "REPORTS.COMPONENT",
            "REPORTS.CORRECTIVE_COUNT",
            "REPORTS.SCHEDULED_COUNT",
            "REPORTS.TASKS_COUNT",
            "REPORTS.CORRECTIVE_TIME",
            "REPORTS.SCHEDULED_TIME",
            "REPORTS.TOTAL_TIME"
          ]).toPromise().then(translations => {
        return [
          {
            name: translations["REPORTS.COMPONENT"],
            key: 'componentName'
          },
          {
            name: translations["REPORTS.CORRECTIVE_COUNT"],
            key: 'workOrdersCount',
            type: 'number',
          },
          {
            name: translations["REPORTS.SCHEDULED_COUNT"],
            key: 'scheduledTaskCount',
            type: 'number'
          },
          {
              name: translations["REPORTS.TASKS_COUNT"],
              key: 'totalCount',
              type: 'number',
            },
            {
              name: translations["REPORTS.CORRECTIVE_TIME"],
              key: 'workingTimeCorrective',
              type: 'number',
            },
            {
              name: translations["REPORTS.SCHEDULED_TIME"],
              key: 'workingTimeTasks',
              type: 'number'
            },
            {
              name: translations["REPORTS.TOTAL_TIME"],
              key: 'totalWorkingTime',
              type: 'number'
            },
        ];
      }
    )};
      // ----------------------------------------------------------------------------
}