import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { LayoutComponent } from "./shared/layout/layout.component";
import { DashboardPageComponent } from "./dashboard/pages/dashboard/dashboard-page.component";
import { UsersPageComponent } from "./users/pages/users-page/users-page.component";
import { AddUserPageComponent } from "./users/pages/add-user-page/add-user-page.component";
import { UserDetailPageComponent } from "./users/pages/user-detail-page/user-detail-page.component";
import { ViewerPageComponent } from "./viewer/pages/viewer/viewer-page.component";
import { UserGroupsPageComponent } from "./user-groups/pages/user-groups-page/user-groups-page.component";
import { RolesService } from "./shared/services/roles.service";
import { ROLES } from "./shared/models/role.interface";
import { DetailUserGroupPageComponent } from "./user-groups/pages/detail-user-group/detail-user-group-page.component";
import { AddUserGroupPageComponent } from "./user-groups/pages/add-user-group-page/add-user-group-page.component";
import { ReportsComponent } from "./reports/pages/reports/reports.component";
import { MessagesPageComponent } from "./messages/pages/messages-page/messages-page.component";
import { ConfigProblemTypePageComponent } from "./configuration/pages/config-problemType-page/config-problemType-page.component";
import { ConfigNotificationPageComponent } from "./configuration/pages/config-notification-page/config-notification-page.component";
import { AuthGuardService } from "./auth/auth-guard.service";
import { ConfigCriticalityPageComponent } from "./configuration/pages/config-criticality-page/config-criticality-page.component";
import { ScanQrComponent } from "./shared/components/qr-scan/qr-scan.component";
import { ConfigFacilityPageComponent } from "./configuration/pages/config-facility-page/config-facility-page.component";
import { TreePageComponent } from "./treeview/pages/tree-page/tree-page.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { ConfigGeneralPageComponent } from "./configuration/pages/config-general-page/config-general-page.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "",
    component: LayoutComponent,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "configuracion",
        component: ConfigNotificationPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [
            ROLES.OWNER,
            ROLES.GLOBAL_SERVICE,
            ROLES.CLIENT_ADMIN,
            ROLES.CLIENT_USER,
            ROLES.MAINTENANCE_ADMIN,
            ROLES.MAINTENANCE_USER,
          ],
        },
      },
      {
        path: "configuracion/tipos-de-problemas",
        component: ConfigProblemTypePageComponent,
        canActivate: [RolesService],
        data: {
          roles: [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN],
        },
      },
      {
        path: "configuracion/niveles-de-criticidad",
        component: ConfigCriticalityPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [ROLES.OWNER, ROLES.GLOBAL_SERVICE],
        },
      },
      {
        path: "configuracion/notificaciones",
        component: ConfigNotificationPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [
            ROLES.OWNER,
            ROLES.GLOBAL_SERVICE,
            ROLES.CLIENT_ADMIN,
            ROLES.CLIENT_USER,
            ROLES.MAINTENANCE_ADMIN,
            ROLES.MAINTENANCE_USER,
          ],
        },
      },
      {
        path: "configuracion/facility-info",
        component: ConfigFacilityPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [
            ROLES.OWNER,
            ROLES.GLOBAL_SERVICE,
            ROLES.CLIENT_ADMIN,
            ROLES.CLIENT_USER,
            ROLES.MAINTENANCE_ADMIN,
            ROLES.MAINTENANCE_USER,
          ],
        },
      },
      {
        path: "configuracion/general",
        component: ConfigGeneralPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [
            ROLES.OWNER,
            ROLES.GLOBAL_SERVICE,
            ROLES.CLIENT_ADMIN,
            ROLES.CLIENT_USER,
            ROLES.MAINTENANCE_ADMIN,
            ROLES.MAINTENANCE_USER,
          ],
        },
      },
      {
        path: "dashboard",
        component: DashboardPageComponent,
      },
      {
        path: "visualizador",
        component: ViewerPageComponent,
      },
      {
        path: "tree-view",
        component: TreePageComponent,
      },
      {
        path: "usuarios",
        component: UsersPageComponent,
      },
      {
        path: "usuarios/nuevo",
        component: AddUserPageComponent,
      },
      {
        path: "usuarios/:userId",
        component: UserDetailPageComponent,
      },
      {
        path: "grupos-usuarios",
        component: UserGroupsPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN],
        },
      },
      {
        path: "grupos-usuarios/nuevo",
        component: AddUserGroupPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN],
        },
      },
      {
        path: "grupos-usuarios/:groupId",
        component: DetailUserGroupPageComponent,
        canActivate: [RolesService],
        data: {
          roles: [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN],
        },
      },
      {
        path: "mensajes",
        component: MessagesPageComponent,
      },
      {
        path: "reports",
        component: ReportsComponent,
      },
      {
        path: 'scanqr',
        component: ScanQrComponent,
      },
    ],
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
