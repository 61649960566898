import {NgModule} from '@angular/core';
import {MessagesPageComponent} from './pages/messages-page/messages-page.component';
import {SharedModule} from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    MessagesPageComponent
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild(),
  ]
})
export class MessagesModule { }
