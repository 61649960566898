import { FacilityComponentInterface } from 'src/app/facilities/models/facility-element.interface';

import { CompanyInterface } from '../../companies/models/company.interface';
import { UserInterface } from '../../users/models/user.interface';


export interface ScheduledTaskFilterConditions {
  assetIdentifier?: string[];
  floor?: string[];
  space?: string[];
  type?: string[];
  name?: string[];
}

export type ScheduledTaskFilterConditionsGroup = ScheduledTaskFilterConditions[];

export interface ScheduledTaskFilter {
  inclusiveFilters: ScheduledTaskFilterConditionsGroup;
  exclusiveFilters: ScheduledTaskFilterConditionsGroup;
}

export interface ScheduledTask {
  assignedAt?: string;
  assignedById?: number;
  assignedToId?: number;
  assignedTo?: UserInterface;
  categoryId: number;
  companyId: number;
  companyName: string;
  company: CompanyInterface;
  createdAt: string;
  creatorId: number;
  creator: UserInterface;
  facilityId: number;
  filter: ScheduledTaskFilter;
  finishedAt?: string;
  finisher: UserInterface;
  finisherId?: number;
  frequencyQuantity: number;
  frequencyUnit: string;
  id: number;
  lastScheduledTask: string;
  name: string;
  nextScheduledTask: string;
  parent: string;
  statusId: number;
  strictDates: boolean;
  timeSpent?: number,
  updatedAt?: string;
  updatorId?: number;
  validator?: UserInterface;
  validatorId?: number;
  typeId?: number;
  comment?: string;
}

export enum SCHEDULED_TASKS_STATUS {
  NOT_STARTED = 3,
  FINISHED_WITH_ISSUES = 2,
  FINISHED = 1,
}

export enum SCHEDULED_TASKS_TYPES{
  PREVENTIVE = 1,
  CONDUCTIVE = 2,
  MANDATORY = 3,
}

export enum FREQUENCY_UNITS{
  DAYS = 1,
  WEEKS = 2,
  MONTHS = 3,
  YEARS = 4,
}

export interface IScheduledTaskComponentSubtask {

  id: number;
  creatorId: number;
  checkedDate: string;
  scheduledTasksId: number;
  componentsId: number;
  scheduledTask: ScheduledTask;
  component: FacilityComponentInterface;
  scheduledTaskComponentsSubtasks: ISubtasks[];

}

export interface ISubtasks {
  id: number;
  code: string;
  description: string;
  value: number | null;
  updatorId: string;
  checkedOption: boolean;
  checkedDate: string;
  scheduledTaskComponentId: number;
}