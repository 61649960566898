import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ScheduledTask} from '../../../models/scheduled-task.interface';
import {FacilityComponentInterface} from '../../../../facilities/models/facility-element.interface';
import {ROLES} from "../../../../shared/models/role.interface";

@Component({
  selector: 'app-detail-page-filter-section',
  templateUrl: './detail-page-filter-section.component.html',
  styleUrls: ['./detail-page-filter-section.component.scss']
})
export class DetailPageFilterSectionComponent implements OnInit {

  @HostBinding('class') hostClass = 'sixteen wide column';

  @Input() task: ScheduledTask;
  @Input() showFilterForm: boolean;
  @Input() filtersForm: FormGroup;
  @Input() filterParams: { value: string, name: string }[];
  @Input() canEditTask: boolean;
  @Input() filteredComponents: FacilityComponentInterface[];
  @Input() filteredComponentsPage: number;
  @Input() numComponentsPerPage: number;
  @Input() totalComponentsFiltered: number;
  @Input() componentsTotalPages: number;
  @Input() userRole: ROLES;

  @Output() deleteTask = new EventEmitter<null>();
  @Output() editTask = new EventEmitter<null>();
  @Output() pageSelected = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  clickOnDeleteTask() {
    this.deleteTask.emit();
  }

  clickOnEditTask() {
    this.editTask.emit();
  }

  pageClick(page: number) {
    this.pageSelected.emit(page);
  }

  shouldShowDeleteButton() {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole);
  }
}
