import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {DetailWorkOrderService} from '../detail-work-order.service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {WorkOrder} from '../../../models/work-order.model';
import {filter, map, skipWhile, switchMap} from 'rxjs/operators';
import {IncidencesService} from '../../../../incidences/incidences.service';
import {SessionService} from '../../../../shared/services/session.service';
import {ROLES} from '../../../../shared/models/role.interface';

@Component({
  selector: 'app-detail-work-order-info',
  templateUrl: './detail-work-order-info.component.html',
  styleUrls: ['./detail-work-order-info.component.scss']
})
export class DetailWorkOrderInfoComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui grid';

  subscriptions: Subscription[];

  workOrder: WorkOrder;
  incidenceWorkOrders$: Observable<WorkOrder[]>;

  role: ROLES;

  constructor(
    public detailWorkOrder: DetailWorkOrderService,
    private incidenceService: IncidencesService,
    public session: SessionService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions = [
      this.detailWorkOrder.workOrder$.subscribe(order => this.workOrder = order),
      this.session.userRole$.subscribe(role => this.role = role)
    ];

    this.incidenceWorkOrders$ = combineLatest([
      this.detailWorkOrder.workOrder$.pipe(skipWhile(order => !order)),
      this.session.activeFacility$.pipe(filter(facility => !!facility))
    ]).pipe(
      switchMap(([order, facility]) => {
        return this.incidenceService.fetchWorkOrders(order?.incidenceId, facility.id)
          .pipe(map(orders => {
            return orders.filter(orderElm => orderElm.id !== order.id);
          }));
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

}
