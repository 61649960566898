import {Component, ElementRef, HostBinding, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'pui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @HostBinding('tabindex') tabindex = 0;
  @HostListener('keyup', ['$event']) keyUpOnHost(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.ref.nativeElement.click();
    }
  }

  @Input() size: 'mini' | 'small' | 'normal' | 'large' | 'big' | 'huge' | 'massive' = 'normal';

  @Input() disabled = false;

  isPrimary = false;
  @Input() set primary(value) {
    this.isPrimary = !(value === undefined || value === false);
  }

  isSecondary = false;
  @Input() set secondary(value) {
    this.isSecondary = !(value === undefined || value === false);
  }

  isBasic = false;
  @Input() set basic(value) {
    this.isBasic = !(value === undefined || value === false);
  }

  get isOnlyIconButton() {
    return this.icon && !this.label;
  }

  @HostBinding('class') get hostClasses() {
    let classes = '';
    classes += this.size !== 'normal' ? this.size : '';
    classes += this.disabled ? ' disabled' : '';
    classes += this.isPrimary ? ' primary' : '';
    classes += this.isSecondary ? ' secondary' : '';
    classes += this.isBasic ? ' basic' : '';
    classes += this.isOnlyIconButton ? ' onlyIcon' : '';
    classes += this.leftLabeledIcon ? ' left-labeled' : '';
    classes += this.rightLabeledIcon ? ' right-labeled' : '';

    return classes;
  }

  @Input() icon: string;
  @Input() label: string;

  @Input() leftLabeledIcon: string;
  @Input() rightLabeledIcon: string;

  constructor(private ref: ElementRef) { }

  ngOnInit(): void {
  }

  onButtonClick(event: MouseEvent) {
    if (this.disabled) {
      event.stopPropagation();
    }
  }
}
