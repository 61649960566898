
<div class="container">
    <div class="fields">
        <div class="field">
            <div>{{this.activeFacility.name}} > {{this.component.space.floor.name}} > {{this.component.space.name}}</div>
            <h3>{{this.component.name}}</h3>
        </div>
      <div class="field">
        <button class="ui button" (click)="redirectToComponentPage()">Ver Ficha</button>
      </div>
      <div class="field">
        <button class="ui button" (click)="redirectToNewIncidence()">Crear solicitud</button>
      </div>
    </div>
  </div>
  