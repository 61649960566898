<form class="ui form" [formGroup]="parentForm">

  <div class="two fields">
    <div class="field required">
      <label>{{ 'CATEGORY' | translate }}</label>
      <pui-dropdown
        [options]="categories"
        [search]="true"
        formControlName="category"
        [readonly]="disabled"
        [class.empty-input]="!parentForm.controls.category.value"
      ></pui-dropdown>
    </div>
    <div class="field required">
      <label>{{ 'COMPANY_TO_ASSIGN' | translate }}</label>
      <pui-dropdown
        [options]="companies"
        [search]="true"
        formControlName="company"
        [readonly]="disabled"
        [disabled]="!parentForm.value.category"
        [class.empty-input]="!parentForm.controls.company.value"
      ></pui-dropdown>
    </div>
  </div>

  <div *ngIf="canViewAssignedUser()" class="two fields">
    <div class="field"></div>
    
    <div class="field">
      <app-label-and-info
        [labelText]="'ASSIGNED_USER' | translate "
        [helpingText]="'ASSIGNED_USER_HELP' | translate "
      ></app-label-and-info>
      <!-- TODO: cambiar dropdown cuando se implemente la funcionalidad clearable en el nuevo -->
      <fui-dropdown-two
        selectorId="assignedUserSel"
        [options]="users"
        [search]="true"
        valueProperty="id"
        nameProperty="email"
        formControlName="assignedUser"
        [clearable]="true"
      ></fui-dropdown-two>
    </div>
  </div>

  <div class="field required">
    <app-label-and-info
        [labelText]="'PROBLEM_TYPE' | translate "
        [helpingText]="'PROBLEM_TYPE_HELP' | translate "
      ></app-label-and-info>
    <pui-dropdown
      search
      [options]="problemTypes"
      labelParam="text"
      formControlName="problem"
      [readonly]="disabled"
      [disabled]="!parentForm.value.category"
      [class.disabled]="disabled"
      [class.empty-input]="!parentForm.controls.problem.value"
    ></pui-dropdown>
  </div>

  <div class="two fields">
    <div class="field required">
      <label>{{ 'CRITICALITY' | translate }}</label>
      <pui-dropdown
        search
        [options]="criticalityLevels"
        formControlName="criticalityLevel"
        [readonly]="disabled"
        [class.empty-input]="!parentForm.controls.criticalityLevel.value"
      ></pui-dropdown>
    </div>
    <div class="field">
      <app-label-and-info
        [labelText]="'PREVIOUS_WORK_ORDER' | translate "
        [helpingText]="'PREVIOUS_WORK_ORDER_HELP' | translate "
      ></app-label-and-info>
      <!-- TODO: cambiar dropdown cuando se implemente la funcionalidad clearable en el nuevo -->
      <fui-dropdown-two
        selectorId="parentWorkOrderSel"
        [options]="incidenceWorkOrders"
        valueProperty="id"
        nameProperty="problemTypeName"
        formControlName="parentWorkOrder"
        [disabled]="disabled"
        [clearable]="true"
      ></fui-dropdown-two>
    </div>
  </div>

  <div class="field">
    <label for="description">{{ 'DESCRIPTION' | translate }}</label>
    <textarea
      name="description"
      id="description"
      formControlName="description"
      cols="30" rows="10"
      [readonly]="disabled"
    ></textarea>
  </div>

</form>
