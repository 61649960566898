import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {fromEvent, ReplaySubject} from 'rxjs';
import {skipWhile, take} from 'rxjs/operators';
import LineChartOptions = google.visualization.LineChartOptions;

@Component({
  selector: 'app-google-line-chart',
  templateUrl: './google-line-chart.component.html',
  styleUrls: ['./google-line-chart.component.scss']
})
export class GoogleLineChartComponent implements OnInit, AfterViewInit {

  @ViewChild('chart') chartElem: ElementRef;
  chart: google.visualization.LineChart;

  @Input() set dataView(view: google.visualization.DataView) {
    this._dataView = view;
    this.drawChart();
  }
  private _dataView: google.visualization.DataView;

  @Input() set options(options: LineChartOptions) {
    this._options = options;
    this.drawChart();
  }
  private _options: LineChartOptions;

  private initializedViewSubject$ = new ReplaySubject(1);
  private initializedView$ = this.initializedViewSubject$
    .pipe(
      skipWhile(init => !init),
      take(1)
    );

  constructor() { }

  ngOnInit(): void {
    fromEvent(window, 'resize').subscribe(event => {
      this.drawChart();
    });
  }

  drawChart() {
    this.initializedView$.subscribe((init) => {
      if (this._dataView) {
        this.chart = new google.visualization.LineChart(this.chartElem.nativeElement);
        this.chart.draw(this._dataView, this._options);
      }
    });
  }

  ngAfterViewInit() {
    google.charts.load('current', {packages: ['corechart']});
    google.charts.setOnLoadCallback(() => {
      this.initializedViewSubject$.next(true);
      this.drawChart();
    });
  }
}
