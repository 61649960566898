
import { 
    ReportCostByFacilityDTO,
    ReportCostByFacilityReg,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessCostByFacility extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }
    
    getData(){
        const costByFacilityParams = this.getCostByFacilityDateSets(this.reportGrossData as ReportCostByFacilityDTO);
              const dataSetsFacil = [{
                data: costByFacilityParams.materialsData, label: this.translate.instant("REPORTS.MATERIALS_LABEL_1"), stack: 'One date'},
                {data: costByFacilityParams.timeData, label: this.translate.instant("REPORTS.TIME_LABEL_1"), stack: 'One date'}];

        if (costByFacilityParams.materialsData2.length > 0) {
            dataSetsFacil.push({data: costByFacilityParams.materialsData2, label: this.translate.instant("REPORTS.MATERIALS_LABEL_2"), stack: 'Other date'})
            dataSetsFacil.push({data: costByFacilityParams.timeData2, label: this.translate.instant("REPORTS.TIME_LABEL_2"), stack: 'Other date'})
        }

        this.reports.costByFacility = {
            labels: costByFacilityParams.labels,
            dataSets: dataSetsFacil
        };
        
        this.reports.showIncidenceStatusFilter = false;
 
    }
    private getCostByFacilityDateSets(costByFacility: ReportCostByFacilityDTO) {
        let labels = [];
        const materialsData = [];
        const timeData = [];
        const materialsData2 = [];
        const timeData2 = [];
        costByFacility.value.forEach((reg: ReportCostByFacilityReg) => {
          labels.push(reg.facilityName);
          materialsData.push(reg.materialCost);
          timeData.push(reg.timeCost);
        });
        if (costByFacility.value2) {
          costByFacility.value2.forEach((reg: ReportCostByFacilityReg) => {
            labels.push(reg.facilityName);
            materialsData2.push(reg.materialCost);
            timeData2.push(reg.timeCost);
          });
        }
    
        labels = [...new Set(labels)];
    
        return {labels, materialsData, timeData, materialsData2, timeData2};
      }
}