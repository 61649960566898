import {ApplicationRef, ChangeDetectorRef, Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, from, of} from 'rxjs';
import {WorkOrder} from '../../../models/work-order.model';
import {DetailWorkOrderService} from '../detail-work-order.service';
import {
  WorkOrderMaterialCreateDtoInterface,
  WorkOrderMaterialInterface
} from '../../../models/work-order-material.interface';
import {SessionService} from '../../../../shared/services/session.service';
import {WorkOrderService} from '../../../work-order.service';
import {mergeMap, skipWhile, switchMap, take} from 'rxjs/operators';
import {AddMaterialFormComponent} from '../../../components/add-material-form/add-material-form.component';

@Component({
  selector: 'app-detail-work-order-materials',
  templateUrl: './detail-work-order-materials.component.html',
  styleUrls: ['./detail-work-order-materials.component.scss']
})
export class DetailWorkOrderMaterialsComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid';
  workOrder$: BehaviorSubject<WorkOrder>;
  selectedWorkOrderMaterials: WorkOrderMaterialInterface[] = [];

  addMaterialData: WorkOrderMaterialCreateDtoInterface;
  @ViewChild('addMaterialForm') addMaterialForm: AddMaterialFormComponent;

  constructor(
    public detailWorkOrder: DetailWorkOrderService,
    private session: SessionService,
    private workOrderSrv: WorkOrderService,
    private cdr: ChangeDetectorRef,  private appRef: ApplicationRef,
  ) {
    this.workOrder$ = detailWorkOrder.workOrder$;
  }

  ngOnInit(): void {
  }

  isMaterialSelected(material: WorkOrderMaterialInterface) {
    return this.selectedWorkOrderMaterials.includes(material);
  }

  deselectMaterial(materialId: number) {
    const index = this.selectedWorkOrderMaterials.findIndex(elem => elem.id === materialId);
    this.selectedWorkOrderMaterials.splice(index, 1);
  }

  onClickMaterial(material: WorkOrderMaterialInterface) {
    if (this.workOrder$.value.wasFinished()) {
      return;
    }
    if (!this.isMaterialSelected(material)) {
      this.selectedWorkOrderMaterials.push(material);
    } else {
      this.deselectMaterial(material.id);
    }
  }

  addMaterial() {
    if (this.addMaterialData) {
      combineLatest([
        this.workOrder$.pipe(skipWhile(order => !order)),
        this.session.activeFacility$.pipe(skipWhile(facility => !facility))
      ]).pipe(
        take(1),
        switchMap(([order, facility]) => {
          // Perform any necessary checks or updates here
          // TODO. avoid ExpressionChangedAfterItHasBeenCheckedError 
          // when click on submit button after enter freetext in the input descripion
    
          // Manually trigger change detection
        this.cdr.detectChanges();
        this.appRef.tick();

          // Use setTimeout to schedule the form submission after change detection
          setTimeout(() => {
            // Submit the form
            this.workOrderSrv.addMaterial(
              order.id,
              { facilityId: facility.id.toString() },
              this.addMaterialData
            ).subscribe(result => {
              this.addMaterialForm.reset();
              this.workOrder$.value.workOrderMaterials = [...this.workOrder$.value.workOrderMaterials, result];

              this.detailWorkOrder.addMaterialToFormArray(result);
            });
          });
          
          // Return an observable to complete the switchMap
          return of(null);
        })
      ).subscribe();
    }
  }
  

  deleteSelectedMaterials() {
    combineLatest([
      this.workOrder$.pipe(skipWhile(order => !order), take(1)),
      this.session.activeFacility$.pipe(skipWhile(facility => !facility), take(1)),
      from(this.selectedWorkOrderMaterials)
    ]).pipe(
      mergeMap(([order, facility, material]) => {
        return this.workOrderSrv
          .deleteMaterial(order.id, material.id, {facilityId: facility.id.toString()});
      })
    ).subscribe((response) => {
      const index = this.workOrder$.value.workOrderMaterials
        .findIndex(elem => elem.id === response.id);
      this.workOrder$.value.workOrderMaterials.splice(index, 1);
      this.deselectMaterial(response.id);

      this.detailWorkOrder.removeMaterialFromFormArray(response.id);
    });
  }

  
}
