import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrder } from '../../models/work-order.model';

@Component({
  selector: 'app-work-order-steps-indicator',
  templateUrl: './work-order-steps-indicator.component.html',
  styleUrls: ['./work-order-steps-indicator.component.scss']
})
export class WorkOrderStepsIndicatorComponent implements OnInit {

  @Input() workOrder: WorkOrder;
  currentLang: string;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;

    // Subscribe to language changes
    this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
    });
  }

  formatDate(date: string): string {
    return DateTime.fromISO(date).setLocale(this.currentLang).toLocaleString(DateTime.DATE_FULL);
  }

  formatHour(date: string): string {
    return DateTime.fromISO(date).setLocale(this.currentLang).toLocaleString(DateTime.TIME_24_SIMPLE);
  }
}
