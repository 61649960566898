import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import { BaseChartComponent } from '../../base-chart.component';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends BaseChartComponent  implements OnInit {

  @ViewChild(BaseChartDirective, { static: true }) chartCanvas: BaseChartDirective;

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() title: string;

  @Input() chartDataSets: ChartDataSets[];
  @Input() labels: Label[];

  @Input() xAxesStacked = false;
  @Input() yAxesStacked = false;

  @Input() chartType = 'pie';

  chartOptions: any;

  constructor() {
    super();
  }

  chartColors: Array<any> = [
    {backgroundColor: [
        '#329648',
        '#005ec9',
        '#c74f42',
        '#deac00',
        '#12b0ab',
        '#c74289',
        '#4c9efd',
        '#42c781',
        '#fc523f',
        '#7632fc',
        '#0e0091',
        '#91004e',
        '#de4d00',
        '#f08907',
        '#916a00'
    ]}
  ];
 

  ngOnInit(): void {   
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: this.title,
        fontFamily: '"Exo 2", serif',
        fontColor: '#000000',
        fontSize: 18,
      },
      legend: {
        labels: {
          fontFamily: '"Exo 2", serif',
          fontColor: '#000000',
        }
      },
      scales: {
      }
    };
  }

  getChartCanvas(): HTMLCanvasElement {
    const chartCanvas: HTMLCanvasElement = this.chartCanvas.chart.ctx.canvas;
    if (chartCanvas) {
      return chartCanvas;
    } else {
      console.error('Chart canvas not found.');
      return null;
    }
  }

  getTitle(){
    return this.title;
  }
}
