<div class="login-container">

  <img class="logo" src="assets/images/logo.png" alt="IMBICLOUD">


  <form  class="ui form" (ngSubmit)="loginSubmit(loginForm.value)">
    <div class="field" [class.error]="loginForm.controls['email'].hasError('email') && loginForm.controls['email'].touched" >
      <input [formControl]="loginForm.controls['email']" type="email" name="email" placeholder="{{'EMAIL' | translate}}">
    </div>
    <div class="field password-input-container">
      <input 
      [formControl]="loginForm.controls['password']" 
      [type]="passwordVisible ? 'text' : 'password'"
      name="password" 
      placeholder="{{'PASSWORD' | translate}} ">
      <i class="{{ iconClass }} icon" (click)="togglePasswordVisibility()"></i>
    </div>

    <button class="ui button primary" [class.loading]="loading" [class.disabled]="!loginForm.valid" type="submit">{{'LOGIN_LINK' | translate}}</button>
    <a routerLink="/forgot-password">{{'FORGOT_PASSWORD' | translate}}</a>
  </form>
  <div class ="ui divider"></div>
  <div class="separator">
    <span>{{'OTHER_OPTIONS' | translate}}</span>
  </div>
  <div class ="ui divider"></div>
  <button class="ui button social-btn" (click)="signIn()">
    <span class="social-logo-wrapper">
    <img class="social-logo" src="/assets/images/google-logo.svg" alt="Google logo">
  </span>{{'LOGIN_GOOGLE' | translate}}</button>
  <div *ngIf="loginError" class="ui error message">
    <div class="header">{{'WRONG_DATA' | translate}}</div>
    <p>{{ errorMessage }}</p>
  </div>
  <div *ngIf="loginForm.controls['email'].hasError('email') && loginForm.controls['email'].touched" class="ui error message">
    <p>{{'WRONG_EMAIL_FORMAT' | translate}}</p>
  </div>
</div>
