import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {CompanyInterface} from '../../../companies/models/company.interface';
import {
  getFrequencyUnitOptions, 
  getScheduledTypesOptions,
} from '../../shared/scheduled-tasks.utils';
import {UserInterface} from '../../../users/models/user.interface';
import {ROLES} from '../../../shared/models/role.interface';
import { IReadonlyFields } from '../../pages/scheduled-task-detail-page/detail-page-edit-section/models/edit-section-interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-scheduled-task-info-form',
  templateUrl: './scheduled-task-info-form.component.html',
  styleUrls: ['./scheduled-task-info-form.component.scss']
})
export class ScheduledTaskInfoFormComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui form';

  @Input() userRole: ROLES;
  @Input() creatingTask = false;

  @Input() form: FormGroup;
  @Input() categories: CategoryInterface[];
  @Input() companies: CompanyInterface[];
  @Input() users: UserInterface[];

  @Input() readonlyFields:IReadonlyFields;

  frequencyUnits : any;
  taskTypes : any;
  showInfoStrictDates = false;
  
  constructor(
    private translate: TranslateService,
  ) {
    
  }

  ngOnInit(): void {
    this.taskTypes= getScheduledTypesOptions(this.translate);
    this.frequencyUnits = getFrequencyUnitOptions(this.translate);
  }

  canEditUserRole() {
    return [ROLES.MAINTENANCE_USER, ROLES.MAINTENANCE_ADMIN].includes(this.userRole);
  }

}
