import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FacilityElementType, FacilityElementTypeDocument} from '../../models/facility-element-type.interface';
import {BehaviorSubject, combineLatest, EMPTY, Observable, Subject, forkJoin} from 'rxjs';
import {SessionService} from '../../../shared/services/session.service';
import {pluck, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {FacilitiesService} from '../../facilities.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FacilityComponentInterface} from '../../models/facility-element.interface';
import {Facility} from '../../models/facility.interface';
import {ToastService} from '../../../shared/services/toast.service';
import {Ng2ImgMaxService} from 'ng2-img-max';
import {ROLES} from '../../../shared/models/role.interface';
import {AutodeskViewerComponent} from '../../../autodesk-forge/components/autodesk-viewer/autodesk-viewer.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-element-type-detail-page',
  templateUrl: './element-type-detail-page.component.html',
  styleUrls: ['./element-type-detail-page.component.scss']
})
export class ElementTypeDetailPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui stackable grid container';

  userRole: ROLES;

  activeFacility: Facility;
  activeSection: 'info' | 'components' | 'documents' = 'info';

  @ViewChild('viewer') viewer: AutodeskViewerComponent;

  componentType$ = new BehaviorSubject<FacilityElementType>(null);
  componentTypeForm: FormGroup;

  components: FacilityComponentInterface[];
  selectedComponent: FacilityComponentInterface;
  isSelectedComponent = false;

  @ViewChild('fileInput') fileInput: ElementRef;
  uploadingDocument = false;
  deletingDocument = -1;

  destroy$: Subject<boolean> = new Subject<boolean>();
  hasViewer: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private facilitySrv: FacilitiesService,
    private fb: FormBuilder,
    private toast: ToastService,
    private imgResizeSrv: Ng2ImgMaxService,
    private translate: TranslateService,
  ) {
    this.componentTypeForm = fb.group({

      // TODO: remove name field when required rule in backend would be disabled
      name: null,

      modelNumber: null,
      company: null,
      description: null,
      warrantyDurationParts: null,
      warrantyGuarantorParts: null,
      warrantyDurationLabor: null,
      warrantyGuarantorLabor: null,
      replacementCost: null,
      expectedLife: null,
      components: null,
    });
  }

  ngOnInit(): void {
    this.session.userRole$.pipe(
      skipWhile(role => !role && role !== 0),
      take(1)
    ).subscribe(role => this.userRole = role);

    const activeFacilityRequest = this.session.activeFacility$.pipe(
      skipWhile(f => !f),
      takeUntil(this.destroy$)
    );

    combineLatest([
      this.route.params.pipe(pluck('id')),
      this.session.activeFacility$.pipe(skipWhile(f => !f)),
      activeFacilityRequest
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([typeId, facility]) => {
        this.activeFacility = facility;

        if (this.activeFacility && this.activeFacility.id !== facility.id) {
          this.router.navigateByUrl('/tipos-componentes');
          return EMPTY; // Skip further processing if navigation occurs
        } else {
          return this.facilitySrv.fetchOneComponentType(typeId, { facilityId: facility.id });
        }
      })
    ).subscribe(componentType => {
      this.componentType$.next(componentType);
      this.componentTypeForm.patchValue(componentType);
      this.hasViewer = this.activeFacility.externalFacilityObject != null;
    });

    combineLatest([
      this.componentType$.pipe(skipWhile(t => !t)),
      this.session.activeFacility$.pipe(skipWhile(f => !f)),
      activeFacilityRequest
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([type, facility]) => {
        return this.facilitySrv.fetchComponentsFiltered({types: [type.name]}, {facilityId: facility.id});
      })
    ).subscribe((components: FacilityComponentInterface[]) => this.components = components);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  loadedViewer() {
    this.focusComponents();
  }

  focusComponents() {
    this.viewer.focusElements(this.components);
  }

  selectComponent(externalId: string) {
    if (externalId) {
      this.facilitySrv.fetchComponentByExternalIdentifier(externalId, this.activeFacility.id)
        .subscribe(
          component => {
            this.selectedComponent = component;
            this.isSelectedComponent = true;
          },
          error => {
            this.selectedComponent = null;
            this.isSelectedComponent = true;
          }
        );
    } else {
      this.selectedComponent = null;
      this.isSelectedComponent = false;
    }

    this.viewer.resize();
  }

  saveChanges() {
    this.facilitySrv.editComponentType(
      this.componentType$.value.id,
      {facilityId: this.activeFacility.id},
      this.componentTypeForm.value)
      .subscribe(componentType => {
        this.componentTypeForm.patchValue(componentType);
        this.componentTypeForm.markAsPristine();
        this.toast.showToast({
          type: 'success',
          message: this.translate.instant('COMPONENT_TYPES.UPDATED'),
        });
      });
  }

  addDocument() {
    this.fileInput.nativeElement.click();
  }

  async getFileSize(file): Promise<{ height: number, width: number }> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const image = new Image();
        image.onload = () => {
          resolve({width: image.naturalWidth, height: image.naturalHeight});
        };
        image.src = event.target.result as string;
      };
      reader.readAsDataURL(file);
    });
  }

  async selectedFile() {
    const files = this.fileInput.nativeElement.files;

    // Check if the number of selected files exceeds the limit
    const maxFiles = 4;
    if (files.length > maxFiles) {
      this.toast.showToast({
        type: 'information',
        message: this.translate.instant('COMPONENT_TYPES.NUM_MAX_FILES'),
      });
      return;
    }

    if (files.length > 0) {
      this.uploadingDocument = true;

      const uploadRequests: Observable<FacilityElementTypeDocument>[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        uploadRequests.push(await this.handleFileUpload(file));
      }

      // Use forkJoin to wait for all uploads to complete
      forkJoin(uploadRequests)
        .subscribe(results => {
          this.uploadingDocument = false;
          // Handle results as needed
          results.forEach(result => {
            this.componentType$.value.documents.push(result);
          });
        });
    }
  }

  private async handleFileUpload(file): Promise<Observable<FacilityElementTypeDocument>> {
    // Logic to determine uploadRequest based on file type
    let uploadRequest: Observable<FacilityElementTypeDocument>;

    if ((file.type === 'application/pdf') ||
      ( file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
      ( file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
      ( file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') 
    ) {
      uploadRequest = this.generateUnResizedRequest(file);
    } else if ((file.type === 'application/jpg') ||
      (file.type === 'application/jpeg') ||
      (file.type === 'application/jpeg') || 
        (file.type === 'application/png') || 
        (file.type === 'image/jpg')|| 
        (file.type === 'image/jpeg')|| 
        (file.type === 'image/png')
    ) {
      uploadRequest = await this.uploadImageRequest(file);
    }

    return uploadRequest;
  }

  private async uploadImageRequest(image) {
    const size: { height: number, width: number } = await this.getFileSize(image);
    const min = this.getMinSize(size);

    return min.value > 1080 ? this.generateResizedRequest(min, image) : this.generateUnResizedRequest(image);
  }

  private generateUnResizedRequest(file) {
    return this.session.activeFacility$
      .pipe(
        skipWhile(facility => !facility),
        take(1),
        switchMap(facility => {
          return this.facilitySrv.uploadProblemTypeFile(this.componentType$.value.id, file, {facilityId: facility.id});
        })
      );
  }

  private generateResizedRequest(min: { property: 'height' | 'width'; value: number }, file) {
    const maxHeight = min.property === 'height' ? 1080 : 100000;
    const maxWidth = min.property === 'width' ? 1080 : 100000;

    return combineLatest([
      this.session.activeFacility$.pipe(skipWhile(facility => !facility)),
      this.imgResizeSrv.resizeImage(file, maxWidth, maxHeight)
    ]).pipe(
      take(1),
      switchMap(([facility, resizedFile]) => {
        return this.facilitySrv.uploadProblemTypeFile(this.componentType$.value.id, resizedFile, {facilityId: facility.id});
      })
    );
  }

  private getMinSize(size: { height: number; width: number }) {
    let min: { property: 'height' | 'width', value: number } = null;
    if (size.height < size.width) {
      min = {property: 'height', value: size.height};
    } else {
      min = {property: 'width', value: size.width};
    }
    return min;
  }

  deleteDocument(doc: FacilityElementTypeDocument) {
    this.deletingDocument = doc.id;
    this.session.activeFacility$
      .pipe(
        skipWhile(facility => !facility),
        take(1),
        switchMap(facility => {
          return this.facilitySrv.deleteProblemTypeFile(this.componentType$.value.id, doc, {facilityId: facility.id});
        })
      )
      .subscribe(() => {
        this.deletingDocument = -1;
        const index = this.componentType$.value.documents.findIndex(item => item.id === doc.id);
        this.componentType$.value.documents.splice(index, 1);
      });
  }

  canEditDocuments(): boolean {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole);
  }
}
