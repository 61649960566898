<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'ADD_INCIDENCE.TITLE' | translate }}</h1>
</div>

<div class="row">
  <pui-stepper
    class="steps"
    [steps]="steps"
    mode="{{stepperMode}}"
    [allowDirectStepSelect]="true"
    [(activeStep)]="activeStep"
  >
  </pui-stepper>
</div>

<ng-template #topicStep>
  <pui-field class="topic-field">
    <pui-input>
      <input [(ngModel)]="topic" type="text" placeholder="{{ 'ADD_INCIDENCE.ISSUE_PLACEHOLDER' | translate }}">
    </pui-input>
  </pui-field>
  <div class="actions actions-right">
    <pui-button label="{{ 'NEXT' | translate }}" primary (click)="nextStep()" [disabled]="!topic"></pui-button>
  </div>
</ng-template>

<ng-template #descriptionStep>
  <pui-field class="description-field">
    <pui-text-area>
      <textarea cols="30" rows="10" [(ngModel)]="description" placeholder="{{ 'ADD_INCIDENCE.DESCRIPTION_PLACEHOLDER' | translate }}"></textarea>
    </pui-text-area>
  </pui-field>
  <div class="actions">
    <pui-button label="{{ 'PREVIOUS' | translate }}" (click)="prevStep()" primary></pui-button>
    <pui-button label="{{ 'NEXT' | translate }}" (click)="nextStep()" primary></pui-button>
  </div>
</ng-template>

<ng-template #photosStep>
  <div class="ui segment" [class.loading]="uploadingImage">
    <div class="ui placeholder segment">
      <div class="ui icon header">
        <i class="images icon"></i>
        {{ 'ADD_INCIDENCE.ADD_IMAGE' | translate }}
      </div>
      <div class="ui button" (click)="fileInput.click()">{{ 'ADD_INCIDENCE.ADD_IMAGE_BUTTON' | translate }}</div>
      <input #fileInput type="file" hidden (change)="addFiles()">
    </div>

    <div class="ui small images">
      <div *ngFor="let image of uploadedImages" class="ui image">
        <a class="ui right corner label" (click)="deleteImage(image)"> <i class="delete icon"></i></a>
        <img src="{{image.url}}" alt="random picture">
      </div>
    </div>
  </div>
  <div class="actions">
    <pui-button label="{{ 'PREVIOUS' | translate }}" (click)="prevStep()" primary></pui-button>
    <pui-button label="{{ 'NEXT' | translate }}" (click)="nextStep()" primary></pui-button>
  </div>
</ng-template>

<ng-template #componentsStep>
  <div class="ui segment">
    <div *ngIf="this.selectedComponents.length === 0" class="field-description">
      <p>
        <i class="circle info icon"></i>
        {{ 'ADD_INCIDENCE.ADD_AT_LEAST_ONE_COMPONENT' | translate }}
      </p>
    </div>
    <app-viewer-element-selector
      [facility]="activeFacility"
      (selectedSpace)="selectedSpaceOnSelector($event)"
      (selectedComponent)="selectedComponentOnSelector($event)"
    >
    </app-viewer-element-selector>

    <div class="viewer-wrapper" *ngIf="!isMobile && hasViewer">
      <app-autodesk-viewer
        #viewer
        [document]="(activeFacility || {}).externalFacilityObject"
        (selectedElement)="selectedElementOnViewer($event)"
        (loaded)="viewerLoaded()"
      ></app-autodesk-viewer>

      <app-element-info-panel
        *ngIf="activeElement"
        class="element-panel"
        [element]="activeElement"
        (close)="closeElementInfo()"
      ></app-element-info-panel>
    </div>

    <div class="elements-actions" *ngIf="isMobile || !hasViewer">
      <pui-button
        label="{{ 'ADD_INCIDENCE.ADD_COMPONENT' | translate }}"
        [disabled]="!activeElement"
        (click)="addActiveElementToIncidence()"
        primary
      ></pui-button>
    </div>

    <div class="ui horizontal selection list">
      <div *ngFor="let component of selectedComponents" class="item">
        <div class="content" (click)="selectedComponentOnList(component)">
            <span style="margin-right: 10px">
              {{component.name}}
            </span>
          <div class="ui mini icon button" (click)="deselectComponent(component)">
            <i class="delete icon"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="ui form" *ngIf="!isMobile && hasViewer" style="margin-top: 20px">
      <div class="field" style="display: flex; justify-content: space-between">
        <div class="ui button" (click)="focusSelectedComponents()">{{ 'ADD_INCIDENCE.SHOW_ALL' | translate }}</div>
      </div>
    </div>

  </div>

  <div class="actions">
    <pui-button label="{{ 'PREVIOUS' | translate }}" (click)="prevStep()" primary></pui-button>
    <pui-button label="{{ 'ADD_INCIDENCE.CREATE' | translate }}" [disabled]="!isValidIncidence()" (click)="create()" primary></pui-button>
  </div>

</ng-template>
