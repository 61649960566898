import {Component, OnDestroy, OnInit} from '@angular/core';
import {DetailIncidenceService} from '../detail-incidence.service';
import {SessionService} from '../../../../shared/services/session.service';
import {filter, take, map} from 'rxjs/operators';
import {IUserLogged} from '../../../../shared/models/i-user-logged';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {IncidencesService} from '../../../incidences.service';
import {Facility} from '../../../../facilities/models/facility.interface';
import {ToastService} from '../../../../shared/services/toast.service';
import {Subscription, Observable} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-incidence-rating',
  templateUrl: './detail-incidence-rating.component.html',
  styleUrls: ['./detail-incidence-rating.component.scss']
})
export class DetailIncidenceRatingComponent implements OnInit, OnDestroy {

  user: IUserLogged;
  activeFacility: Facility;

  form: FormGroup;
  incidenceSubs: Subscription;

  constructor(
    private session: SessionService,
    private incidenceSrv: IncidencesService,
    public detailIncidence: DetailIncidenceService,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1)
    ).subscribe(facility => this.activeFacility = facility);

    this.form = new FormGroup({
      score: new FormControl(null, Validators.required),
      description: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.session.loggedUser$.pipe(
      filter(user => !!user),
      take(1)
    ).subscribe(user => this.user = user);

    this.incidenceSubs = this.detailIncidence.incidence$.pipe(
      filter(incidence => !!incidence)
    ).subscribe(incidence => {
      this.form.patchValue({
        score: incidence.reviewScore,
        description: incidence.reviewDescription
      });
    });
  }

  ngOnDestroy() {
    this.incidenceSubs.unsubscribe();
  }

  canRateIncidence(): Observable<boolean> {
    return this.detailIncidence.isUserCreator().pipe(map(isCreator => {
      return isCreator && !this.detailIncidence.incidence$.value?.reviewScore;
    }));
  }

  rateIncidence() {
    this.incidenceSrv.reviewIncidence(
      this.detailIncidence.incidence$.value.id,
      this.activeFacility.id, this.form.value
    ).subscribe(result => {
      this.detailIncidence.incidence$.next(result);
      this.toast.showToast({
        message: this.translate.instant('REVIEW_THANKS'),
        type: 'success'
      });
    });
  }
}
