<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'USER_GROUPS.EDIT_TITLE' | translate: { groupName: group?.name } }}</h1>
</div>

<div class="sixteen wide column">
  <app-user-group-form
    [group]="group"
    [users]="users"
    (groupSubmitted)="saveChanges($event)"
  >
  </app-user-group-form>
</div>
