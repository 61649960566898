

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-label-and-info',
    templateUrl: './label-and-info.component.html',
    styleUrls: ['./label-and-info.component.scss'],
})
export class LabelInfoComponent {
  @Input() labelText: string = '';
  @Input() helpingText: string = '';
  @Input() forAttribute: string = '';
  @Input() customClass: string = '';

  showInfo: boolean = false;

  toggleInfo() {
    this.showInfo = !this.showInfo;
  }
}
