
import { 
    ReportScheduledTaskFinishedByTypeCategoryDTO, 
    ReportScheduledTaskFinishedByTypeCategoryReg, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessScheduledTaskFinishedByTypeCategory extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }
    
    getData(){
        const {labels: categoryNameMANT, dataA : hoursMANT, 
            dataA2 : hoursMANT2, } = this.getScheduledTaskFinishedByTypeCategoryDataSets(this.reportGrossData as ReportScheduledTaskFinishedByTypeCategoryDTO);
          const dataSetsHoursMaintenanceCategory = [{data: hoursMANT, label: this.translate.instant("REPORTS.TASKS_1")}]
          if (hoursMANT2.length > 0) {
            dataSetsHoursMaintenanceCategory.push({data: hoursMANT, label: this.translate.instant("REPORTS.TASKS_2")})
          }
          this.reports.scheduledTaskFinishedByTypeCategory = {
            labels: categoryNameMANT,
            dataSets: dataSetsHoursMaintenanceCategory
          };
          this.reports.showIncidenceStatusFilter = false;
 
    }
    // ----------------------------------------------------------------------------
    private getScheduledTaskFinishedByTypeCategoryDataSets(ScheduledTaskFinishedByTypeCategory: ReportScheduledTaskFinishedByTypeCategoryDTO) {
    
        let labels = [];
        const dataA = [];
        const dataA2 = [];
        ScheduledTaskFinishedByTypeCategory.value.forEach((reg: ReportScheduledTaskFinishedByTypeCategoryReg) => {
           
          const categoryName = reg.categoryName
            .replace('PREVENTIVE',this.translate.instant('SCHEDULED_TASKS_TYPE.PREVENTIVE'))
            .replace('CONDUCTIVE',this.translate.instant('SCHEDULED_TASKS_TYPE.CONDUCTIVE'))
            .replace('MANDATORY',this.translate.instant('SCHEDULED_TASKS_TYPE.MANDATORY'))
            .toUpperCase();
          labels.push(categoryName);
          dataA.push(reg.count);
        });
        if (ScheduledTaskFinishedByTypeCategory.value2) {
          ScheduledTaskFinishedByTypeCategory.value2.forEach((reg: ReportScheduledTaskFinishedByTypeCategoryReg) => {
            
            const categoryName = reg.categoryName
              .replace('PREVENTIVE',this.translate.instant('SCHEDULED_TASKS_TYPE.PREVENTIVE'))
              .replace('CONDUCTIVE',this.translate.instant('SCHEDULED_TASKS_TYPE.CONDUCTIVE'))
              .replace('MANDATORY',this.translate.instant('SCHEDULED_TASKS_TYPE.MANDATORY'))
              .toUpperCase();
            labels.push(categoryName);
            dataA2.push(reg.count);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataA2};
      }
}