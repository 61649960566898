<div class="sixteen wide column header">
  <h1 class="ui dividing header">{{ 'NAV.VIEWER' | translate }}</h1>
</div>

<div class="sixteen wide column selector">
  <div class="ui segment navigation-form">
    <p class="ui header small">
      {{ 'SELECT_PROMPT' | translate }}
    </p>

    <app-viewer-element-selector
      [facility]="activeFacility"
      (selectedSpace)="selectedSpaceOnSelector($event)"
      (selectedComponent)="selectedComponentOnSelector($event)"
    >
    </app-viewer-element-selector>

  </div>
</div>

<div class="viewer-container">
  <div class="viewer" *ngIf="activeFacility">

    <app-autodesk-viewer
      #adViewer
      [document]="this.activeFacility.externalFacilityObject"
      (selectedElement)="onSelectItemOnViewer($event)"
      (loaded)="onLoadViewer()"
    ></app-autodesk-viewer>

    <div *ngIf="!activeFacility" class="ui placeholder segment">
      <div class="ui icon header">
        <i class="building icon"></i>
        {{ 'SELECT_BUILDING' | translate }}
      </div>
    </div>
  </div>
  <app-element-info-panel 
    class="element-panel" 
    *ngIf="isSelectedElement" 
    [element]="selectedElement" 
    (close)="closeElementInfoPanel()">
  </app-element-info-panel>
</div>
