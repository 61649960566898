import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {WorkOrder} from './models/work-order.model';
import {map, skipWhile, switchMap, take} from 'rxjs/operators';
import {WorkOrderComponentInterface, WorkOrderEditDtoInterface, WorkOrderFilterOptions, WorkOrderGetPaginatedDtoInterface} from './models/work-order.interface';
import {
  WorkOrderMaterialCreateDtoInterface,
  WorkOrderMaterialDeletedDtoInterface,
  WorkOrderMaterialInterface
} from './models/work-order-material.interface';
import {SessionService} from '../shared/services/session.service';
import { UtilsService } from '../shared/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderService {

  private readonly apiHost = environment.apiHost;

  constructor(
    private http: HttpClient,
    private session: SessionService,
    private utilsService: UtilsService
  ) { }

  fetchOne(id: number, queryParams: {[key: string]: string} = {}): Observable<WorkOrder> {
    const params = this.utilsService.parseParams(queryParams);

    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        params.push(`facilityId=${facility.id}`);
        return this.http.get(`${this.apiHost}/work-orders/${id}?${params.join('&')}`);
      }),
      map(response => new WorkOrder(response))
    );
  }

  fetchAll(queryParams: {[key: string]: string | number}): Observable<WorkOrder[]> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.get<WorkOrder[]>(`${this.apiHost}/work-orders?${params.join('&')}`)
      .pipe(map(orders => orders.map(data => new WorkOrder(data))));
  }

  fetchPaginated(page: number, limit: number, queryParams: {[key: string]: string|number|number[]}): Observable<WorkOrderGetPaginatedDtoInterface> {
    const params = this.utilsService.parseParams(queryParams);
    return this.http.get<WorkOrderGetPaginatedDtoInterface>(`${this.apiHost}/work-orders?page=${page}&limit=${limit}&${params.join('&')}`)
      .pipe(map((result: WorkOrderGetPaginatedDtoInterface) => {
        if (result.items) {
          result.items = result.items.map(workOrder => new WorkOrder(workOrder));
        } else {
          result.items = [];
        }
        return result;
      }));
  }

  downloadFilteredPDF(queryParams: { [key: string]: string | number | number[] }): Observable<Blob> {
    const params = this.utilsService.parseParams(queryParams);
    const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
    const responseType = 'blob';

    return this.http.get(`${this.apiHost}/work-orders/download?${params.join('&')}&format=pdf`, {
      headers,
      responseType,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<Blob>) => {
        return response.body;
      })
    );
  }

  edit(id: number, facilityId: number, data: WorkOrderEditDtoInterface): Observable<WorkOrder> {
    return this.http.patch<WorkOrder>(`${this.apiHost}/work-orders/${id}?facilityId=${facilityId}`, data).pipe(map(workOrder => new WorkOrder(workOrder)));
  }

  addComponent(facilityId: number, workOrderId: number, componentExternalId: string): Observable<WorkOrderComponentInterface> {
    return this.http
      .post<WorkOrderComponentInterface>(`${this.apiHost}/work-orders/${workOrderId}/components/${componentExternalId}?facilityId=${facilityId}`, {});
  }

  downloadPDF(facilityId: number, workOrderId: number,){
    const params = {
      facilityId
    }
    const filteredParams = this.utilsService.filterNonNullValues(params);
    const formattedParams = this.utilsService.parseParams(filteredParams);

    return this.http.get(`${this.apiHost}/work-orders/${workOrderId}/pdf?${formattedParams.join('&')}`, { responseType: 'blob' });
  
  }

  deleteComponent(workOrderId: number, componentId: number, queryParams: {[key: string]: string}): Observable<object> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http
      .delete<object>(`${this.apiHost}/work-orders/${workOrderId}/components/${componentId}?${params.join('&')}`);
  }

  addMaterial(workOrderId: number, queryParams: {[key: string]: string}, data: WorkOrderMaterialCreateDtoInterface): Observable<WorkOrderMaterialInterface> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.post<WorkOrderMaterialInterface>(`${this.apiHost}/work-orders/${workOrderId}/materials?${params.join('&')}`, data);
  }

  deleteMaterial(workOrderId: number, materialId: number, queryParams: {[key: string]: string}): Observable<WorkOrderMaterialDeletedDtoInterface> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.delete<WorkOrderMaterialDeletedDtoInterface>(`${this.apiHost}/work-orders/${workOrderId}/materials/${materialId}?${params.join('&')}`)
      .pipe(map(response => Object.assign(response, {id: materialId})));
  }

  start(id: number, facilityId: number): Observable<WorkOrder> {
    return this.http.patch<WorkOrder>(`${this.apiHost}/work-orders/${id}/start?facilityId=${facilityId}`, {})
      .pipe(map(workOrder => new WorkOrder(workOrder)));
  }

  finish(id: number, facilityId: number, authEmail: string): Observable<WorkOrder> {
    return this.http.patch<WorkOrder>(`${this.apiHost}/work-orders/${id}/finish/${authEmail}?facilityId=${facilityId}`, {})
      .pipe(map(data => new WorkOrder(data)));
  }

  close(id: number, facilityId: number): Observable<WorkOrder> {
    return this.http.patch<WorkOrder>(`${this.apiHost}/work-orders/${id}/close?facilityId=${facilityId}`, {})
      .pipe(map(data => new WorkOrder(data)));
  }

}
