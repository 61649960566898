import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'pui-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() type: 'audio' | 'ball-triangle' | 'bars' | 'circles' | 'grid' | 'hearts' | 'oval' | 'puff' | 'rings' | 'spinning-circles' | 'tail-spin' | 'three-dots' = 'rings';
  @Input() color = '#383333';

  @Input() heightPx = 30;

  constructor() {
  }

  ngOnInit(): void {
  }

}
