<div class="sixteen wide column">
  <h1 class="ui dividing header">{{'USERS.ADD_USER_TITLE' | translate}}</h1>
</div>

<div class="centered row">
  <div class="sixteen wide mobile ten wide computer column">
    <app-user-form
      [companyOptions]="companies"
      [roleOptions]="roleOptions"
      (selectedCompany)="onSelectCompany($event)"
      (submitted)="createUser($event)"
    ></app-user-form>
  </div>
</div>

