import {Injectable, OnDestroy} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, forkJoin, Observable, Subject, of} from 'rxjs';
import {filter, map, pluck, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import { Facility } from 'src/app/facilities/models/facility.interface';
import { ROLES } from 'src/app/shared/models/role.interface';
import { IncidencesService } from 'src/app/incidences/incidences.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { WorkOrder } from 'src/app/workorders/models/work-order.model';
import { Incidence } from '../../models/incidence';
import { FacilityComponentInterface } from 'src/app/facilities/models/facility-element.interface';
import { FacilitiesService } from 'src/app/facilities/facilities.service';
import { TranslateService } from '@ngx-translate/core';
import { getIncidenceTypesOptions } from '../../incidences.utils';

@Injectable()
export class DetailIncidenceService implements OnDestroy {

  activeFacility: Facility;

  incidence$: BehaviorSubject<Incidence> = new BehaviorSubject<Incidence>(null);
  workOrders$: BehaviorSubject<WorkOrder[]> = new BehaviorSubject<WorkOrder[]>(null);

  modifiedPhotos = false;
  modifiedComponents = false;

  incidenceForm: FormGroup;
  incidenceTypes = getIncidenceTypesOptions(this.translate);

  destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private facilitySrv: FacilitiesService,
    private incidenceService: IncidencesService,
    private notificationSrv: ToastService,
    private fb: FormBuilder,
    private toast: ToastService,
    private translate: TranslateService,

  ) {
    this.incidenceForm = fb.group({
      info: fb.group({
        topic: ['', Validators.required],
        description: [''],
        type: [null]
      })
    });

    this.session.activeFacility$.pipe(
      skipWhile(f => !f),
      takeUntil(this.destroy$),
    ).subscribe(facility => {
      if (this.activeFacility && this.activeFacility.id !== facility.id) {
        this.router.navigateByUrl('solicitudes');
      } else {
        this.activeFacility = facility;
      }
    });

    this.route.params.pipe(
      takeUntil(this.destroy$),
      pluck('incidenceId'),
      switchMap(id => this.incidenceService.fetchOne(id))
    ).subscribe(incidence => {
      this.setFormDataFromIncidence(incidence);
      this.updateWorkOrders();
    });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  updateIncidence(): void {
    this.incidenceService.fetchOne(this.incidence$.value.id)
      .subscribe(incidence => {
        this.setFormDataFromIncidence(incidence);
      });
  }

  updateWorkOrders() {
    forkJoin([
      this.incidence$.pipe(skipWhile(i => !i), take(1)),
      this.session.userRole$.pipe(
        filter(role => !!role || role === 0),
        take(1)
      ),
      this.session.activeFacility$.pipe(
        skipWhile(f => !f),
        take(1)
      )
    ]).subscribe(([incidence, role, facility]) => {
      if ([ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role)) {
        this.incidenceService.fetchWorkOrders(incidence.id, facility.id)
          .subscribe(orders => this.workOrders$.next(orders));
      }
    });
  }

  setFormDataFromIncidence(incidence: Incidence) {
    this.incidenceForm.patchValue({
      info: {
        topic: incidence.topic,
        description: incidence.description,
        type: this.incidenceTypes.find(option => option.value === incidence.typeId)
      }
    });
    this.incidence$.next(incidence);
    setTimeout(() => {
      this.incidenceForm.markAsPristine({ onlySelf: false });
    }, 50);
    this.modifiedPhotos = false;
    this.modifiedComponents = false;
  }

  isUserCreator(): Observable<boolean> {
    return combineLatest([
      this.session.loggedUser$.pipe(filter(user => !! user)),
      this.incidence$.pipe(filter(incidence => !!incidence))
    ]).pipe(map(([user, incidence]) => { 
      const isUserCreator = user.id === incidence.creatorId;
      return isUserCreator;
    }));
  }

  canRateIncidence(): Observable<boolean> {
    return this.isUserCreator().pipe(map(isCreator => {
      return isCreator && !this.incidence$.value?.reviewScore;
    }));
  }

  canUserEdit(): Observable<boolean> {
    return combineLatest([
      this.incidence$.pipe(filter(incidence => !!incidence)),
      this.isUserCreator()
    ]).pipe(
      switchMap(([incidence, isUserCreator]) => {
      const canUserEdit = 
        !incidence.wasClosed() 
        && !incidence.wasAssigned()
        && isUserCreator ;
      return of(canUserEdit);
    }));
  }

  canAdminSetType(): Observable<boolean> {
    return combineLatest([
      this.session.userRole$.pipe(filter(role => role !== undefined && role !== null)),
      this.incidence$.pipe(filter(incidence => !!incidence)),
    ]).pipe(
      switchMap(([role, incidence]) => {
      const canUserEdit = 
        !incidence.wasClosed() 
        && !incidence.wasAssigned()
        && [ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role) ;
      return of(canUserEdit);
    }));
  }

  saveChanges() {
    const requestData = {
      id: this.incidence$.value.id,
      typeId: this.incidenceForm.value.info.type?.value,
      topic: this.incidenceForm.value.info.topic,
      description: this.incidenceForm.value.info.description,
      photos: this.incidence$.value.photos,
      components: this.incidence$.value.components.map(component => component.component.id)
    };

    this.incidenceService.update(this.incidence$.value.id, this.activeFacility.id, requestData).subscribe((response) => {
      const incidence = new Incidence(response);
      this.incidence$.next(incidence);
      this.setFormDataFromIncidence(incidence);
      this.toast.showToast({
        type: 'success',
        message: this.translate.instant('REQUEST_UPDATED'),
      });
    });
  }

  isModified(): boolean {
    return !this.incidenceForm.pristine || this.modifiedPhotos || this.modifiedComponents;
  }

  private incidenceIncludesComponent(componentExternalId: string): boolean {
    return !!this.incidence$.value.components.find(component => {
      return component.component.externalIdentifier === componentExternalId;
    });
  }

  async addComponentToIncidence(externalId: string) {
    let component: FacilityComponentInterface = await this.facilitySrv.fetchComponentByExternalIdentifierPromise(externalId, this.activeFacility.id);
    const existComponentInIncidence = this.incidenceIncludesComponent(externalId);

    /*TODO: Agregar comprobación de que debe ser el creador */
   if ( !existComponentInIncidence
      && !this.incidence$.value.wasAssigned()) {
      this.incidence$.value.components.push({
          id: null,
          createdAt: null,
          creatorId: null,
          incidenceId: null,
          componentId: null,
          component
        });
        
      this.modifiedComponents = true;
      }else if (existComponentInIncidence){
        this.notificationSrv.showToast({
          type: 'information',
          message: this.translate.instant('COMPONENT_ALREADY_HERE'),
        });
      }else if (this.incidence$.value.wasAssigned()) {
        this.notificationSrv.showToast({
          type: 'information',
          message: this.translate.instant('UPDATE_FORBIDDEN_IN_ASSIGNED_STATE'),
        });
      }else{
        this.notificationSrv.showToast({
          type: 'information',
          message: this.translate.instant('COMPONENTS_UPDATE_FORBIDDEN'),
        });
      }
    }
}
