import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ScheduledTasksPageFilterService {

  private filterBehavior$ = new BehaviorSubject(null);
  filter$ = this.filterBehavior$.asObservable();

  private monthYearBehavior$ = new BehaviorSubject<DateTime>(DateTime.now());
  monthYear$ = this.monthYearBehavior$.asObservable();
  
  relatedToComponentId$ = new BehaviorSubject<number>(null);

  private selectedViewSubject = new BehaviorSubject<string>('calendar');
  selectedView$ = this.selectedViewSubject.asObservable();

  constructor() { }

  setFilter(filter: any) {
    this.filterBehavior$.next(filter);
  }

  setSelectedView(view: string) {
    this.selectedViewSubject.next(view);
  }

  setMonthYear(monthYear: DateTime) {
    this.monthYearBehavior$.next(monthYear);
  }

  getSelectedView(){
    return this.selectedViewSubject.value; //this.selectedView$.subscribe(view => {return view})
  }
}
