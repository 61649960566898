import { TranslateService } from "@ngx-translate/core";

export interface RoleInterface {
  id: number;
  name: string;
  description?: string;
  createdAt?: string;
  updatedAt?: string;
}


export enum ROLES {
  SUPER_ADMIN = 0,
  GLOBAL_SERVICE,
  CLIENT_ADMIN,
  CLIENT_USER,
  MAINTENANCE_ADMIN,
  MAINTENANCE_USER,
  OWNER
}

export enum ROLE_LITERALS {
  'SUPER_ADMIN',
  'GLOBAL_SERVICE',
  'CLIENT_ADMIN',
  'CLIENT_USER',
  'MAINTENANCE_ADMIN',
  'MAINTENANCE_USER',
  'OWNER'
}

export const ROLES_OPTIONS = [
  {id: ROLES.GLOBAL_SERVICE, name: 'GLOBAL_SERVICE'},
  {id: ROLES.CLIENT_ADMIN, name: 'CLIENT_ADMIN'},
  {id: ROLES.CLIENT_USER, name: 'CLIENT_USER'},
  {id: ROLES.MAINTENANCE_ADMIN, name: 'MAINTENANCE_ADMIN'},
  {id: ROLES.MAINTENANCE_USER, name: 'MAINTENANCE_USER'},
  {id: ROLES.OWNER, name: 'OWNER'},
]

export function getAllRolesOptions(translate: TranslateService){
  return ROLES_OPTIONS.map(option => ({ id: option.id, name: translate.instant(`ROLES.${option.name}`) }));
}
export function getRolesOptions(translate: TranslateService, roles: any[]){
  return roles.map(option => ({ id: option.id, name: translate.instant(`ROLES.${option.name}`) }));
}


export enum ActionTypes {
  ManageIncidences = 'manageIncidences',
  CreateUser = 'createUser',
  EditUser = 'editUser',
  ManageUserGroups = 'manageUserGroups',
  CreateCompany = 'createCompany',
  ManageProblemTypes = 'manageProblemTypes',
  ManageCriticalityLevels = 'manageCriticalityLevels',
  ManageMenu = 'manageMenu',
  ManageCosts = 'manageCosts',
  ManageTime = 'manageTime',
  ManageFacilityInfo = 'manageFacilityInfo',
}

export type ActionKey = keyof typeof ActionTypes;

