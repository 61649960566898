import { FacilityComponentInterface } from './../../facilities/models/facility-element.interface';

export enum INCIDENCE_STATES {
  CREATED = 1,
  OPENED,
  ASSIGNED,
  FINISHED,
  CLOSED
}

export const INCIDENCE_STATES_LITERALS = {
  1: 'CREATED',
  2: 'OPENED',
  3: 'ASSIGNED',
  4: 'FINISHED',
  5: 'CLOSED',
  6: 'VALUED'
};

export enum INCIDENCE_TYPES {
  INCIDENCE = 1,
  SERVICE_REQUEST
}

export const INCIDENCE_TYPES_LITERALS = {
  1: 'INCIDENCE',
  2: 'SERVICE_REQUEST'
};

export interface IncidencesFilterOptions {
  ids?: string;
  typeIds?: string;
  topic?: string;
  description?: string;
  statusIds?: string;
  creator?: string;
  categoryIds?: string;
  fromCreatedAt?: string;
  toCreatedAt?: string;
  fromClosedAt?: string;
  toClosedAt?: string;
  reviewed?: boolean;
}

export interface IncidenceReviewDtoInterface {
  score: number;
  description?: string;
}

export interface IncidenceInterface {
  id?: number;
  typeId?: INCIDENCE_TYPES;
  statusId: number;
  topic: string;
  description?: string;
  photos: string[];
  components: {
    id: number,
    createdAt: Date,
    creatorId: number,
    incidenceId: number,
    componentId: number,
    component: FacilityComponentInterface
  }[];

  openedAt?: string;
  openerId?: string;

  closedAt?: string;
  closerId?: number;

  createdAt: string;
  creatorId: number;

  updatedAt?: string;
  updatorId?: number;
}

export interface IIncidencePaginatedResponse {
  items: IncidenceInterface[];
  meta: {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
  };
  links: {
    first: string;
    previous: string;
    next: string;
    last: string;
  };
}

export type ColumnType = 'type' | 'state' | 'date';

export interface TableColumn {
  id: string;
  name: string;
  type?: ColumnType;
}
