import {CompanyInterface} from '../../companies/models/company.interface';
import {CriticalityLevelInterface} from '../../configuration/models/criticalityLevel.interface';
import {WorkOrderComponentInterface} from './work-order.interface';
import {WorkOrderMaterialInterface} from './work-order-material.interface';
import {CategoryInterface} from '../../shared/models/category.interface';
import {ProblemTypeInterface} from '../../configuration/models/problem-type.interface';
import {UserInterface} from '../../users/models/user.interface';
import {Incidence} from '../../incidences/models/incidence';
import { TranslateService } from '@ngx-translate/core';

export enum WORK_ORDER_STATES {
  WAITING = 1,
  BLOCKED,
  IN_PROGRESS,
  FINISHED,
  CLOSED
}

export const WORK_ORDER_STATES_LITERALS = {
  1: 'WORK_ORDER_STATES.WAITING',
  2: 'WORK_ORDER_STATES.BLOCKED',
  3: 'WORK_ORDER_STATES.IN_PROGRESS',
  4: 'WORK_ORDER_STATES.FINISHED',
  5: 'WORK_ORDER_STATES.CLOSED'
};

export function WORK_ORDER_STATES_OPTIONS(translate: TranslateService) {
  const options = [];
  Object.keys(WORK_ORDER_STATES_LITERALS).forEach(key => {
    options.push({
      value: key,
      name: translate.instant(WORK_ORDER_STATES_LITERALS[key])
    });
  });

  return options;
}

export class WorkOrder {

  id: number;
  description?: string;
  photos?: string[];
  comment?: string;

  workingTime?: number;
  workingTimeCost?: string;

  createdAt: string;
  updatedAt?: string;
  assignedAt?: string;
  blockedAt?: string;
  startedAt?: string;
  finishedAt?: string;
  closedAt?: string;

  creatorId: number;
  creator: UserInterface;

  updatorId?: number;
  updator?: UserInterface;

  starterId?: number;
  starter: UserInterface;

  assignerId?: number;
  assigner: UserInterface;

  finisherId?: number;
  finisher?: UserInterface;

  closerId?: number;
  closer?: UserInterface;

  validatorId?: number;
  validator: UserInterface;

  problemTypeId: number;
  problemTypeName: string;
  problemType: ProblemTypeInterface;

  criticalityLevelId: number;
  criticalityLevel: CriticalityLevelInterface;

  incidenceId: number;
  incidence: Incidence;

  assignedCompanyId?: number;
  assignedCompany?: CompanyInterface;

  assignedUserId?: number;
  assignedUser?: UserInterface;

  workOrderStateId: WORK_ORDER_STATES;

  categoryId: number;
  category: CategoryInterface;

  parentWorkOrderId?: number;
  parentWorkOrder?: WorkOrder;

  workOrderComponents: WorkOrderComponentInterface[];

  workOrderMaterials: WorkOrderMaterialInterface[];


  constructor(data: any) {
    Object.assign(this, data);
    this.problemTypeName = data.problemType.text;
    if (!this.photos) {
      this.photos = [];
    }
  }

  isWaiting(): boolean {
    return this.workOrderStateId === WORK_ORDER_STATES.WAITING;
  }

  isBlocked() {
    return this.workOrderStateId === WORK_ORDER_STATES.BLOCKED;
  }

  isInProgress() {
    return this.workOrderStateId === WORK_ORDER_STATES.IN_PROGRESS;
  }

  isFinished() {
    return this.workOrderStateId === WORK_ORDER_STATES.FINISHED;
  }

  isClosed(): boolean {
    return this.workOrderStateId === WORK_ORDER_STATES.CLOSED;
  }

  wasBlocked(): boolean {
    return !!this.blockedAt;
  }

  wasInProgress(): boolean {
    return !!this.startedAt;
  }

  wasFinished(): boolean {
    return !!this.finishedAt;
  }

  wasClosed(): boolean {
    return !!this.closedAt;
  }
}
