<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'COMPANIES.HEADER' | translate }}</h1>
  <button *ngIf="roles.can('createCompany')" class="ui left floated primary icon button" routerLink="/empresas/nueva">
    {{ 'COMPANIES.ADD_COMPANY' | translate }}
  </button>
</div>

<div class="eight wide mobile four wide computer column">
  <div class="ui small fluid icon input">
    <input type="text" placeholder="{{ 'COMPANIES.QUICK_SEARCH' | translate }}" (keyup)="onSearchQueryChange($event.target.value)">
    <i class="search icon"></i>
  </div>
</div>
<div class="computer only nine wide column"></div>
<div class="eight wide mobile three wide computer column">
  <fui-dropdown-two
    selectorId="numElemSelector"
    [fluid]="true"
    [options]="numCompaniesPerPageOptions"
    [formControl]="selectedNumPerPage"
  ></fui-dropdown-two>
</div>

<div class="sixteen wide column">
  <div class="table-container">
    <table class="ui selectable celled small compact table">
      <thead>
        <tr>
          <th>{{ 'COMPANIES.NAME' | translate }}</th>
          <th>{{ 'COMPANIES.CIF' | translate }}</th>
          <th>{{ 'COMPANIES.TYPE' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr [routerLink]="['/empresas', company.id]" *ngFor="let company of companies$ | async">
          <td>{{ company.name }}</td>
          <td>{{ company.cif }}</td>
          <td>
            <ng-container *ngIf="company.types && company.types.length > 0">
              {{ company.types[0].companyType.name }}
              <span *ngIf="company.types.length > 1">, {{ company.types[1].companyType.name }}</span>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-foot">
    <tfoot>
      <tr>
        <th colspan="9">
          <fui-pagination-menu
            [page]="page$ | async"
            [numOfPages]="numOfPages"
            (pageSelected)="page$.next($event)"
          ></fui-pagination-menu>
        </th>
        <th colspan="9" class="pagination-info">
          {{ 'COMPANIES.PAGINATION_INFO' | translate:{first: firstItemNumOfPage(), last: lastItemNumOfPage(), total: numTotalCompanies} }}
        </th>
      </tr>
    </tfoot>
  </div>
</div>

<div class="centered row">
  <div class="twelve wide column">
    <div class="ui success message transition" [class.hidden]="!showSuccessCreateNotification">
      <i class="close icon" (click)="hideSuccessCreateNotification()"></i>
      <div class="header">
        {{ 'COMPANIES.REGISTRATION_SUCCESS' | translate }}
      </div>
      <p>{{ 'COMPANIES.REGISTRATION_SUCCESS_MESSAGE' | translate }}</p>
    </div>
  </div>
</div>
