import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, pluck, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { ScheduledTask } from '../../models/scheduled-task.interface';
import { ScheduledTaskService } from '../../scheduled-task.service';
import { FormArray, FormBuilder,FormGroup, Validators } from '@angular/forms';
import { notEmptyValidator, someInclusiveConditionRequiredValidator } from '../../shared/scheduled-tasks-validators';
import { CategoryInterface } from '../../../shared/models/category.interface';
import { CompaniesService } from '../../../companies/companies.service';
import { 
  formatFilterFromForm, 
  formatInfoFromForm, 
  getFrequencyUnitOptions, 
  getScheduledTypesOptions } from '../../shared/scheduled-tasks.utils';
import { CompanyInterface } from '../../../companies/models/company.interface';
import { SessionService } from '../../../shared/services/session.service';
import { FacilitiesService } from '../../../facilities/facilities.service';
import { PaginatedResult } from '../../../shared/models/paginated-result.interface';
import { ToastService } from '../../../shared/services/toast.service';
import { FacilityComponentInterface } from '../../../facilities/models/facility-element.interface';
import { UserInterface } from '../../../users/models/user.interface';
import { ROLES } from '../../../shared/models/role.interface';
import { Facility } from '../../../facilities/models/facility.interface';
import { SpinnerVeilService } from '../../../shared/services/spinner-veil.service';
import { SCHEDULED_TASKS_STATUS } from '../../models/scheduled-task.interface';
import { UserService } from 'src/app/users/services/user.service';
import { DateTime } from 'luxon';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import saveAs from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-scheduled-task-detail-page',
  templateUrl: './scheduled-task-detail-page.component.html',
  styleUrls: ['./scheduled-task-detail-page.component.scss']
})
export class ScheduledTaskDetailPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui container stackable grid';
  public isMobile: boolean;
  userRole$: Observable<ROLES>;
  actualFacility$: BehaviorSubject<Facility> = new BehaviorSubject<Facility>(null);

  task$ = new BehaviorSubject<ScheduledTask>(null);
  activeSection = 'info';
  originPage = 'list';

  categories$ = new BehaviorSubject<CategoryInterface[]>(null);
  companies$ = new BehaviorSubject<CompanyInterface[]>(null);
  selectableCompanies$ = new BehaviorSubject<CompanyInterface[]>(null);
  selectableUsers = new BehaviorSubject<UserInterface[]>(null);

  infoForm: FormGroup;
  filtersForm: FormGroup;
  filterParams: any;

  finishTaskForm: FormGroup;
  validators: UserInterface[];
  allFilteredComponents$: Observable<FacilityComponentInterface[]>;
  filteredComponentsPage$: BehaviorSubject<number> = new BehaviorSubject(1);
  filteredComponentsInPage: FacilityComponentInterface[];
  numComponentsPerPage = 5;
  totalComponentsFiltered: number;
  componentsTotalPages: number;

  showEditingModal = false;
  showDeletingModal = false;

  subscriptions: Subscription[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  scheduledTask: ScheduledTask;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private session: SessionService,
    private toast: ToastService,
    private veilService: SpinnerVeilService,
    private companiesSrv: CompaniesService,
    private tasksSrv: ScheduledTaskService,
    private facilitySrv: FacilitiesService,
    private userService: UserService,
    private responsiveService: ResponsiveService,
    private translate: TranslateService,
  ) {
    this.originPage = this.router.getCurrentNavigation()?.extras?.state?.originPage || 'calendar';
    this.userRole$ = this.session.userRole$;

    this.infoForm = fb.group({
      name: [null, notEmptyValidator],
      category: [null, Validators.required],
      company: [null, Validators.required],
      assignedTo: null,
      frequencyQuantity: [null, Validators.required],
      frequencyUnit: [null, Validators.required],
      createdAt: [null, Validators.required],
      strictDates: false,
      type: [null, notEmptyValidator],
    });

    this.filtersForm = fb.group({
      exclusiveFilters: fb.array([]),
      inclusiveFilters: fb.array([])
    });
    this.filtersForm.setValidators(someInclusiveConditionRequiredValidator);

    this.finishTaskForm = fb.group({
      comment: null,
      email: [null, [Validators.required, Validators.email]],
      date: null,
      hour: [null, Validators.compose(
        [
          Validators.min(0),
          Validators.required
        ]

      )],
      mins: [null, Validators.compose(
        [
          Validators.min(0),
          Validators.required,
        ]
      )],
    });

    route.params.pipe(
      skipWhile(params => !params.id),
      pluck('id'),
      switchMap(id => {
        return this.tasksSrv.fetchOneScheduledTask(id);
      }),
      takeUntil(this.destroy$)
    ).subscribe(task => {
      this.scheduledTask = task;
      this.task$.next(task);
    });

  }

  getAssignedUsers(): void{
    combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.infoForm.get('company').valueChanges,
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([facility, company]) => {
        if (company) {
          return this.companiesSrv.fetchCompanyUsers(company.id, { 
            facilityId: facility.id, 
            roleIds: [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER] });
        }
      })
      ).subscribe(users => this.selectableUsers.next(users));
  }

  getValidatorsUsers(): void {
    combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.infoForm.controls.company.valueChanges,
      this.task$
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([facility, company, task]) => {
        if (company && task && task.creator) {
          return this.userService.fetchAll({
            facilityIds: [facility.id],
            roleIds: [ROLES.MAINTENANCE_USER, ROLES.MAINTENANCE_ADMIN, ROLES.GLOBAL_SERVICE, ROLES.OWNER],
            companyIds: [company.id, task.creator.companyId]
          });
        } else {
          return of([]);
        }
      })
    ).subscribe(validatorUsers => this.validators = validatorUsers as UserInterface[]);
  }
  private translateLabels() {
    this.translate.get('FILTER_PARAMS').subscribe(translations => {
      this.filterParams = [
        { value: 'assetIdentifier', name: translations.CODE },
        { value: 'floor', name: translations.FLOOR },
        { value: 'space', name: translations.SPACE },
        { value: 'type', name: translations.TYPE },
        { value: 'name', name: translations.COMPONENT }
      ];
    });
  }

  ngOnInit(): void {
    this.translateLabels();
    this.fetchCategories();
    this.fetchMaintenanceCompanies();
    this.getAssignedUsers()
    this.getValidatorsUsers();

    combineLatest([
      this.task$.pipe(skipWhile(task => !task)),
      this.companies$.pipe(skipWhile(companies => !companies))
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(([task, companies]) => {
        const companiesOfTaskCategory = companies.filter(company => !!company.categories.find(cmpCategory => cmpCategory.categoryId === this.task$.value.categoryId));
        this.selectableCompanies$.next(companiesOfTaskCategory);
      });

    combineLatest([
      this.task$.pipe(skipWhile(task => !task)),
      this.categories$.pipe(skipWhile(categories => !categories)),
      this.selectableCompanies$.pipe(skipWhile(companies => !companies))
    ]).pipe(take(1))
      .subscribe(([task, categories, selectCompanies]) => {
        setTimeout(() => {
          this.completeInfoFormFromTask(task);
          if (task.finishedAt) {
            this.completeFinishFormFromTask(task);
          }
        }, 200);
      });

    this.infoForm.get('category').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        const companiesOfTaskCategory = this.companies$.value.filter(company => !!company.categories.find(cmpCategory => cmpCategory.categoryId === value.id));
        this.selectableCompanies$.next(companiesOfTaskCategory);
      });

    this.redirectToTasksHomeWhenFacilityChange()

    combineLatest([
      this.actualFacility$.pipe(skipWhile(facility => !facility)),
      this.filteredComponentsPage$.pipe(skipWhile(pageNum => !pageNum))
    ]).pipe(
      debounceTime(100),
      switchMap(([facility, pageNum]) => {
        const filterRequest = formatFilterFromForm(this.filtersForm.value);
        return this.facilitySrv.fetchComponentsByFilter(filterRequest, {
          facilityId: facility.id,
          page: pageNum,
          limit: this.numComponentsPerPage
        });
      }),
      takeUntil(this.destroy$)
    ).subscribe((result: PaginatedResult<FacilityComponentInterface>) => {
      this.filteredComponentsInPage = result.items;
      this.totalComponentsFiltered = result.meta.totalItems;
      this.componentsTotalPages = result.meta.totalPages;
    });

    this.filtersForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.filteredComponentsPage$.next(1));

    this.allFilteredComponents$ = this.actualFacility$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(facility => {
          const filter = formatFilterFromForm(this.filtersForm.value);
          return this.facilitySrv.fetchComponentsByFilter(filter, { facilityId: facility.id }) as Observable<FacilityComponentInterface[]>;
        })
      );

    this.task$
      .pipe(
        skipWhile(task => !task),
        takeUntil(this.destroy$)
      )
      .subscribe(task => {
        this.completeFilterFormFromTask(task);
      });

    this.responsiveService.isMobile$
    .pipe(takeUntil(this.destroy$))
    .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  fetchMaintenanceCompanies() {
    this.companiesSrv.fetchAllCompanies({ companyTypeId: 2 })
      .pipe(takeUntil(this.destroy$))
      .subscribe((companies: CompanyInterface[]) => this.companies$.next(companies));
  }
  fetchCategories() {
    this.companiesSrv.fetchCategories().pipe(
      map(categories => categories.filter(category => !category.parentCategoryId)),
      takeUntil(this.destroy$)
    ).subscribe(categories => this.categories$.next(categories));
  }

  redirectToTasksHomeWhenFacilityChange() {
    this.session.activeFacility$.pipe(takeUntil(this.destroy$))
      .subscribe(active => {
        if (this.actualFacility$.value && this.actualFacility$.value.id !== active.id) {
          this.router.navigateByUrl('tareas-programadas');
        } else {
          this.actualFacility$.next(active);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private completeInfoFormFromTask(task: ScheduledTask) {
    const values = {
      name: task.name,
      category: this.categories$.value.find(value => value.id === task.categoryId),
      company: this.selectableCompanies$.value.find(company => company.id === task.companyId),
      assignedTo: null,
      frequencyQuantity: task.frequencyQuantity,
      frequencyUnit: getFrequencyUnitOptions(this.translate).find(option => option.value === task.frequencyUnit),
      createdAt: new Date(task.createdAt),
      strictDates: task.strictDates,
      type: getScheduledTypesOptions(this.translate).find(option => option.value === task.typeId)
    };

    this.infoForm.setValue(values, { emitEvent: true });

    this.selectableUsers.pipe(
      skipWhile(u => !u),
      take(1)
    ).subscribe(users => {
      const assignedUser = users.find(user => user.id === task.assignedToId);
      setTimeout(() => {
        this.infoForm.patchValue({ assignedTo: assignedUser });
      }, 300);
    });
  }

  private completeFinishFormFromTask(task: ScheduledTask) {
    let spentTime = task.timeSpent;
    let hour = Math.floor(spentTime / 60);
    let mins = spentTime % 60;
    if (task.validator){
      const formattedDate = DateTime.fromISO(task.finishedAt).toFormat('yyyy-MM-dd HH:mm');
      this.finishTaskForm.setValue({
        comment: task.comment,
        email: task.validator,
        date: task.finishedAt ? formattedDate : null,
        hour,
        mins,
      });
    }
    
  }

  private completeFilterFormFromTask(task: ScheduledTask) {
    this.filtersForm.setControl('inclusiveFilters', this.fb.array([]));
    this.filtersForm.setControl('exclusiveFilters', this.fb.array([]));
    task.filter.inclusiveFilters.forEach(conditionsData => {
      const conditionsFilterForm = this.createConditionsFilterFormFromData(conditionsData);
      (this.filtersForm.get('inclusiveFilters') as FormArray).push(conditionsFilterForm);
    });
    if (task.filter.exclusiveFilters.length) {
      task.filter.exclusiveFilters.forEach(conditionsData => {
        const conditionsFilterForm = this.createConditionsFilterFormFromData(conditionsData);
        (this.filtersForm.get('exclusiveFilters') as FormArray).push(conditionsFilterForm);
      });
    } else {
      (this.filtersForm.get('exclusiveFilters') as FormArray).push(
        this.fb.array([
          this.fb.group({
            filterParam: [null, Validators.required],
            value: [{ value: null, disabled: true }, Validators.required]
          })
        ])
      );
    }
  }

  createConditionsFilterFormFromData(conditionsData): FormArray {
    const conditionsFilterForm = new FormArray([]);
    Object.keys(conditionsData).forEach(conditionParam => {
      conditionsFilterForm.push(this.fb.group({
        filterParam: [this.filterParams.find(param => param.value === conditionParam), Validators.required],
        value: [conditionsData[conditionParam], Validators.required]
      }));
    });

    return conditionsFilterForm;
  }

  pageSelected(pageNum: number) {
    this.filteredComponentsPage$.next(pageNum);
  }

  clickOnEditTask() {
    this.showEditingModal = true;
  }

  clickOnDeleteTask() {
    this.showDeletingModal = true;
  }

  cancelOnModal() {
    this.showEditingModal = false;
    this.showDeletingModal = false;
  }

  editTasks(allTasks = false) {
    this.veilService.openVeil();
    const info = formatInfoFromForm(this.infoForm.value);
    const filter = formatFilterFromForm(this.filtersForm.value);
    const data = Object.assign(info, { filter });

    this.tasksSrv.editScheduledTask(this.task$.value.id, allTasks, data).subscribe(response => {
      this.showEditingModal = false;
      this.veilService.closeVeil();
      this.task$.next(response);
      this.toast.showToast({
        type: 'success',
        message: this.translate.instant('UPDATE_SUCCESSFUL'),
      });
    },
      error => {
        this.showEditingModal = false;
        this.veilService.closeVeil();
        if (error.error.code === 'SD006') {
          this.toast.showToast({
            type: 'error',
            message: this.translate.instant('UPDATE_ERROR'),
          });
        }
        this.completeInfoFormFromTask(this.task$.value);
      });
  }

  deleteTasks(allTasks = false) {
    this.veilService.openVeil();
    this.tasksSrv.deleteScheduledTask(this.task$.value.id, allTasks).subscribe(response => {
      this.veilService.closeVeil();
      this.showEditingModal = false;
      this.router.navigateByUrl('/tareas-programadas').then(() => {
        this.toast.showToast({
          type: 'success',
          message: this.translate.instant('DELETE_SUCCESSFUL'),
        });
      });
    },
    () => {
      this.veilService.closeVeil();
    }
    );
  }

  validateTask() {
    const comment = this.finishTaskForm.value.comment;
    let email = this.finishTaskForm.value.email.email;
    let hour = this.finishTaskForm.value.hour;
    let mins = this.finishTaskForm.value.mins;
    let spentTime = Number((hour * 60)) + Number(mins);

    this.veilService.openVeil();

    this.tasksSrv.finishScheduledTask(this.task$.value.id, email, spentTime, comment)
      .subscribe(
        result => {
          this.completeFinishFormFromTask(result);
          this.task$.next(result);
          this.veilService.closeVeil();
          this.toast.showToast({
            type: 'success',
            message: this.translate.instant('VALIDATE_SUCCESSFUL'),
          });
        },
        response => {
          this.veilService.closeVeil();
        }
      );
  }

  navigateBack() {
    const section = this.originPage === 'calendar' ? 'calendario' : this.originPage === 'scheduled' ? 'agenda': 'lista';
    this.router.navigate(['tareas-programadas', section]);
  }

  canViewComponentsFilter(): Observable<boolean> {
    return this.userRole$.pipe(
      map(role => ![ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(role))
    );
  }

  canEditTask() {
    return this.infoForm.valid && !this.filtersForm.errors?.someInclusiveConditionRequired && (this.infoForm.dirty || this.filtersForm.dirty);
  }

  canViewInfoANDReviewSection():boolean{
    const rolePermited = [
      ROLES.SUPER_ADMIN, 
      ROLES.GLOBAL_SERVICE, 
      ROLES.OWNER,
      ROLES.MAINTENANCE_ADMIN, 
      ROLES.MAINTENANCE_USER
    ];

    let userRole = this.getUserRole();
    return rolePermited.includes(userRole)
  }

  canViewFilterANDEditSection(): boolean {
    const rolePermited = [
      ROLES.SUPER_ADMIN, 
      ROLES.GLOBAL_SERVICE, 
      ROLES.OWNER,
    ]
    let userRole = this.getUserRole();
    

    return (
      (rolePermited.includes(userRole) && this.isTaskNotFinished())
    )
  }

  isTaskNotFinished(){
    const taskNotFinished = this.task$.value != null? 
    this.task$.value.finishedAt === null?
      true: !this.task$.value.finishedAt
    :true;

    return taskNotFinished
  }

  getUserRole(): ROLES {
    let userRol:ROLES;

    this.userRole$
    .pipe(
      take(1),
    ).subscribe(
      (role:ROLES) =>{
        userRol = role;
      }
    );
    
    return userRol;
  }

  canFinishTask(): boolean {
    if (!this.scheduledTask || !this.scheduledTask.createdAt) {
      return false;
    }
    const createdAtDate = new Date(this.scheduledTask.createdAt);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
  
    const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1);
    
    const lastDayOfCurrentMonth = new Date(currentYear, currentMonth + 1, 0);
    lastDayOfCurrentMonth.setHours(23, 59, 59, 999); // Set time to end of the day

    return (
      this.scheduledTask.statusId === SCHEDULED_TASKS_STATUS.NOT_STARTED &&
      createdAtDate >= firstDayOfPreviousMonth &&
      createdAtDate <= lastDayOfCurrentMonth
    );
  }

  downloadPDF(){
    this.tasksSrv.downloadPDF(this.actualFacility$.value.id, this.scheduledTask.id).subscribe(
      (response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
        const fileName = `scheduledTask-${this.scheduledTask.id}-${date}.pdf`;
        saveAs(blob, fileName);
        window.URL.revokeObjectURL(url);
      },
      (error) => {
        console.error('Error downloading the PDF', error);
        this.toast.showToast({
          type: 'error',
          message: this.translate.instant('DOWNLOAD_PDF_ERROR'),
        });
      }
    );
  }
}
