<form class="ui form" autocomplete="off" [formGroup]="form" (ngSubmit)="submit()">
  <button class="ui left floated primary button" type="submit" [class.disabled]="isEditing ? !form.valid || !form.dirty : !form.valid">
    {{ isEditing ? ('COMPANIES.BUTTON_SAVE' | translate) : ('COMPANIES.BUTTON_CREATE' | translate) }}
  </button>
  <div class="two fields">
    <div class="field">
      <label for="name">{{ 'COMPANIES.FIELD_NAME' | translate }}</label>
      <input formControlName="name" type="text">
    </div>
    <div class="field">
      <label for="cif">{{ 'COMPANIES.FIELD_CIF' | translate }}</label>
      <input id="cif" formControlName="cif" type="text">
    </div>
  </div>

  <div class="inline fields">
    <label for="type">{{ 'COMPANIES.FIELD_TYPE' | translate }}</label>
    <div class="field">
      <div class="ui checkbox">
        <input formControlName="clientType" type="checkbox" name="clientType">
        <label>{{ 'COMPANIES.TYPE_CLIENT' | translate }}</label>
      </div>
    </div>
    <div class="field">
      <div class="ui checkbox">
        <input formControlName="maintenanceType" type="checkbox" name="maintenanceType">
        <label>{{ 'COMPANIES.TYPE_MAINTENANCE' | translate }}</label>
      </div>
    </div>
  </div>

  <div class="field" *ngIf="form.controls['maintenanceType'].value">
    <label for="categoryIds">{{ 'COMPANIES.FIELD_CATEGORIES' | translate }}</label>
    <fui-dropdown
      [id]="'categoryIds'"
      [name]="'categories'"
      [multiple]="true"
      [valueProperty]="'id'"
      [options]="categoryOptions"
      [defaultSelected]="companyCategories"
      [placeholder]="'COMPANIES.PLACEHOLDER_CATEGORIES' | translate"
      (selectedChange)="categoriesChange($event)"
    ></fui-dropdown>
  </div>

  <h4 class="ui header">{{ 'COMPANIES.HEADER_LOGO' | translate }}</h4>
  <div class="field">
    <img *ngIf="logoUrl" [src]="logoUrl" alt="{{ 'COMPANIES.ALT_LOGO' | translate }}" class="logo">
    <input type="file" (change)="onLogoChange($event)" accept="image/*">
  </div>

  <h4 class="ui header">{{ 'COMPANIES.HEADER_ADDRESS' | translate }}</h4>
  <div class="field">
    <input formControlName="address"
           ngx-google-places-autocomplete
           #placesRef="ngx-places"
           (onAddressChange)="onSelectedAddress($event)"
           placeholder="{{ 'COMPANIES.PLACEHOLDER_ADDRESS' | translate }}"
           type="text"
    >
  </div>

  <div class="two fields">
    <div class="field">
      <label for="city">{{ 'COMPANIES.FIELD_CITY' | translate }}</label>
      <input formControlName="city" type="text">
    </div>
    <div class="field">
      <label for="postalCode">{{ 'COMPANIES.FIELD_POSTAL_CODE' | translate }}</label>
      <input formControlName="postalCode" type="text">
    </div>
  </div>

  <div class="two fields">
    <div class="field">
      <label for="state">{{ 'COMPANIES.FIELD_STATE' | translate }}</label>
      <input formControlName="state" type="text">
    </div>
    <div class="field">
      <label for="country">{{ 'COMPANIES.FIELD_COUNTRY' | translate }}</label>
      <input formControlName="country" type="text">
    </div>
  </div>

  <h4 class="ui header">{{ 'COMPANIES.HEADER_CONTACTS' | translate }}</h4>
  <div class="field" formArrayName="contacts">
    <div *ngFor="let contact of contacts.controls; let i = index">
      <div class="fields" [formGroupName]="i">
        <div class="five wide field">
          <label>{{ 'COMPANIES.CONTACT_NAME' | translate }}</label>
          <input formControlName="name" type="text">
        </div>
        <div class="five wide field">
          <label>{{ 'COMPANIES.CONTACT_EMAIL' | translate }}</label>
          <input formControlName="email" type="email">
        </div>
        <div class="five wide field">
          <label>{{ 'COMPANIES.CONTACT_PHONE' | translate }}</label>
          <input formControlName="phone" type="tel">
        </div>
        <div class="one wide field" style="display: flex; align-items: flex-end; justify-content: flex-end; padding-bottom: 5px">
          <label for=""></label>
          <div class="ui mini icon button" (click)="deleteContact(i)"><i class="delete icon"></i></div>
        </div>
      </div>
    </div>
    <div class="field">
      <div class="ui icon mini button" (click)="addContact()" [class.disabled]="!contacts.valid">
        <i class="add icon"></i>
      </div>
    </div>
  </div>
</form>
