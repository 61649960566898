import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService implements OnInit {

  public readonly ORIENTATIONS = {
    portrait: '(orientation: portrait)',
    landscape: '(orientation: landscape)',
  }

  public readonly BREAKPOINTS = {
    mobile: '(max-width: 899px)',
    desktop: '(min-width: 900px)'
  }

  orientation$: Observable<any>;
  resolution$: Observable<any>;

  isMobile$: Observable<boolean>;

  constructor(private bp: BreakpointObserver) { 
    this.orientation$ = this.bp.observe([
      this.ORIENTATIONS.portrait,
      this.ORIENTATIONS.landscape
    ]);

    this.resolution$ = this.bp.observe([
      this.BREAKPOINTS.mobile,
      this.BREAKPOINTS.desktop
    ]);

    this.isMobile$ = this.resolution$.pipe(
      map(result => {
        return result.breakpoints[this.BREAKPOINTS.mobile];
      })
    )
  }

  ngOnInit() {
  }
}
