import {Component, HostBinding, OnInit} from '@angular/core';
import {DetailIncidenceService} from '../detail-incidence.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WORK_ORDER_STATES_LITERALS} from '../../../../workorders/models/work-order.model';
import * as moment from 'moment/moment';
import {WorkOrderService} from '../../../../workorders/work-order.service';
import {SessionService} from '../../../../shared/services/session.service';
import {Facility} from '../../../../facilities/models/facility.interface';
import {filter, take} from 'rxjs/operators';

@Component({
  selector: 'app-detail-incidence-workorders',
  templateUrl: './detail-incidence-workorders.component.html',
  styleUrls: ['./detail-incidence-workorders.component.scss']
})
export class DetailIncidenceWorkordersComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid';

  activeFacility: Facility;

  constructor(
    private session: SessionService,
    public detailIncidence: DetailIncidenceService,
    private ordersSrv: WorkOrderService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1)
    ).subscribe(facility => this.activeFacility = facility);
  }

  statusTextFromId(id: number) {
    return WORK_ORDER_STATES_LITERALS[id];
  }

  formatDate(date) {
    if (date) {
      moment().locale('es');
      return moment(date).format('YYYY-MM-DD');
    }
    return '---';
  }

  goToCreateWorkOrder() {
    this.router.navigate(['nueva'], {relativeTo: this.route});
  }

  goToWorkOrderDetail(workOrder: any) {
    this.router.navigate(['ordenes-de-trabajo', workOrder.id]);
  }

  emitOrder(orderId, event) {
    event.stopPropagation();

    this.ordersSrv.start(orderId, this.activeFacility.id).subscribe(() => {
      this.detailIncidence.updateIncidence();
      this.detailIncidence.updateWorkOrders();
    });
  }

}
