import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../../shared/services/session.service';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {Facility} from '../../../../facilities/models/facility.interface';
import {DetailWorkOrderService} from '../detail-work-order.service';
import {WorkOrderService} from '../../../work-order.service';
import {skipWhile, switchMap, take} from 'rxjs/operators';
import {WorkOrder} from '../../../models/work-order.model';
import {WorkOrderComponentInterface} from '../../../models/work-order.interface';
import {
  FacilityComponentInterface, 
  FacilityElementInterface, 
  FacilitySpaceInterface} from '../../../../facilities/models/facility-element.interface';
import {FacilitiesService} from '../../../../facilities/facilities.service';
import {AutodeskViewerComponent} from '../../../../autodesk-forge/components/autodesk-viewer/autodesk-viewer.component';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-work-order-facility-elems',
  templateUrl: './detail-work-order-facility-elems.component.html',
  styleUrls: ['./detail-work-order-facility-elems.component.scss']
})
export class DetailWorkOrderFacilityElemsComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui stackable grid';

  @ViewChild('viewer') viewer: AutodeskViewerComponent;

  facility$: Observable<Facility>;
  activeFacility: Facility;
  facilitySubs: Subscription;
  workOrder$: BehaviorSubject<WorkOrder>;
  hasViewer:boolean;
  selectedElement: FacilityElementInterface;
  isElementSelected = false;

  constructor(
    public detailWorkOrder: DetailWorkOrderService,
    private session: SessionService,
    private workOrderSrv: WorkOrderService,
    private facilitySrv: FacilitiesService,
    private notificationSrv: ToastService,
    private translate: TranslateService,
  ) {
    this.facility$ = this.session.activeFacility$.pipe(skipWhile(facility => !facility));
    this.workOrder$ = this.detailWorkOrder.workOrder$;
  }

  ngOnInit(): void {
    this.facilitySubs = this.session.activeFacility$
    .subscribe(activeFacility => {
      this.activeFacility = activeFacility;
      if (activeFacility) {
        this.hasViewer = this.activeFacility.externalFacilityObject != null ? true : false;
      }
    });
    
  }

  showComponentInfo(externalId: string) {
    if (externalId) {
      this.facility$.pipe(
        switchMap(facility => {
          return this.facilitySrv.fetchComponentByExternalIdentifier(externalId, facility.id);
        })
      )
        .subscribe(component => {
            this.selectedElement = component;
            this.isElementSelected = true;
          },
          error => {
            this.selectedElement = null;
            this.isElementSelected = true;
          }
        );
    }
    if (this.hasViewer) this.viewer.resize();
  }

  deleteWorkOrderComponent(orderComponent: WorkOrderComponentInterface) {
    combineLatest([
      this.session.activeFacility$.pipe(skipWhile(facility => !facility)),
      this.workOrder$.pipe(skipWhile(order => !order))
    ]).pipe(
      take(1),
      switchMap(([facility, order]) => {
        return this.workOrderSrv.deleteComponent(
          order.id, orderComponent.component.id, {facilityId: facility.id.toString()});
      })
    ).subscribe(() => {
      this.workOrder$.value.workOrderComponents.splice(
        this.workOrder$.value.workOrderComponents.indexOf(orderComponent),
        1
      );
      this.notificationSrv.showToast({
        type: 'success',
        message: this.translate.instant('WORK_ORDER_DETAIL.COMPONENT_REMOVED'),
      });
      this.closeElementInfoPanel();
      this.showAllComponents();
    });
  }

  addComponentToWorkOrder(component: FacilityElementInterface){
    this.detailWorkOrder.addComponentToWorkOrder(component.externalIdentifier);
    if (this.hasViewer) this.viewer.resize();
    this.closeElementInfoPanel();
  }

  getAddComponentCallback() {
    return this.addComponentToWorkOrder.bind(this);
  }

  async showComponent(component: FacilityElementInterface) {
    const result = await this.facilitySrv.fetchComponentByExternalIdentifierPromise(component.externalIdentifier, component.facilityId);

    if (result) {
      component = result;
    }
    const isolate: boolean = false;
    this.isElementSelected = true;
    this.selectedElement = component;
    if (this.hasViewer) {
      this.viewer.focusElements(component, isolate);
      this.viewer.resize();
    }
  }

  loadedViewer(){
    this.showAllComponents();
  }

  showAllComponents() {
    const isolate: boolean = false;
    this.selectedElement = null;
    if (this.hasViewer) {
      this.viewer.focusElements(
        this.workOrder$.value.workOrderComponents
        .map(orderComponent => orderComponent.component), isolate);
      
      this.viewer.resize();
    }
    this.closeElementInfoPanel();
  }

  selectedSpaceOnSelector(space: FacilitySpaceInterface) { 
    this.facilitySrv.fetchCubeForSpace(space.externalIdentifier, this.activeFacility.id)
    .subscribe(
      component => {
        if (this.hasViewer) this.viewer.focusElements(component);
        this.selectedComponentOnSelector(component);
      }
    );  
  }

  selectedComponentOnSelector(component: FacilityComponentInterface) {
    this.selectedElement = component;
    this.isElementSelected = true;
    if (this.hasViewer) this.viewer.focusElements(component);
  }

  closeElementInfoPanel() {
    this.selectedElement = null;
    this.isElementSelected = false;
    if (this.hasViewer) this.viewer.resize();
  }

}
