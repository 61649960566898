import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import { DepartmentalTreeComponent } from './components/departmental/departmental-tree.component';
import { TreePageComponent } from './pages/tree-page/tree-page.component';
import { CollapsibleTreeComponent } from './components/collapsible-tree/collapsible-tree.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DepartmentalTreeComponent,
    TreePageComponent,
    CollapsibleTreeComponent,
    ContextMenuComponent,
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild(),
  ]
})
export class TreeViewModule { }
