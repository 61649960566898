
import { 
    ReportWorkBySpacesDTO, 
    ReportWorkBySpacesReg, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessWorkBySpaces extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
        this.setColumnsWorkBySpaces().then(columns => {
          this.reports.tableDataParams = columns;
        });
    }
    
    getData(){
        const {labels, 
            correctiveData : WOCount, 
            correctiveData2 : WOCount2, 
            scheduledData : jobsCount,
            scheduledData2 : jobsCount2,
        } = this.getWorkBySpacesDataSets(this.reportGrossData as ReportWorkBySpacesDTO);
          const dataSetsCount = [
            {data: WOCount, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_1"), stack: 'One date'},
            {data: jobsCount, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_1"), stack: 'One date'}
        ];
          if (jobsCount2.length > 0) {
            dataSetsCount.push(
              {data: WOCount2, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_2"), stack: 'Other date'},
              {data: jobsCount2, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_2"), stack: 'Other date'}
            );
          }
          this.reports.workBySpaces = {
            labels,
            dataSets: dataSetsCount
          };
          this.reports.showIncidenceStatusFilter = false;
          
          //TABLE FOR REPORT_ID.WORK_BY_SPACES:
          this.processTableData('spaceName');
          this.reports.showIncidenceStatusFilter = false;
    }
    
    private getWorkBySpacesDataSets(WorkBySpaces: ReportWorkBySpacesDTO) {
    
        let labels = [];
        const correctiveData = [];
        const scheduledData = [];
        const correctiveData2 = [];
        const scheduledData2 = [];
        const TOP_RANK = 30;

        WorkBySpaces.value.slice(0, TOP_RANK).forEach((reg: ReportWorkBySpacesReg) => {
          labels.push(reg.spaceName);
          correctiveData.push(reg.workOrdersCount);
          scheduledData.push(reg.scheduledTaskCount);
        });
        if (WorkBySpaces.value2) {
          WorkBySpaces.value2.slice(0, TOP_RANK).forEach((reg: ReportWorkBySpacesReg) => {
            labels.push(reg.spaceName);
            correctiveData2.push(reg.workOrdersCount);
            scheduledData2.push(reg.scheduledTaskCount);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, correctiveData, scheduledData, correctiveData2, scheduledData2};
      }

      private async setColumnsWorkBySpaces(): Promise<any[]> {
          return this.translate.get([
                "REPORTS.SPACE",
                "REPORTS.CORRECTIVE_COUNT",
                "REPORTS.SCHEDULED_COUNT",
                "REPORTS.TASKS_COUNT",
                "REPORTS.CORRECTIVE_TIME",
                "REPORTS.SCHEDULED_TIME",
                "REPORTS.TOTAL_TIME"
              ]).toPromise().then(translations => {
        return [
          {
            name: translations["REPORTS.SPACE"],
            key: 'spaceName'
          },
          {
            name: translations["REPORTS.CORRECTIVE_COUNT"],
            key: 'workOrdersCount',
            type: 'number',
          },
          {
            name: translations["REPORTS.SCHEDULED_COUNT"],
            key: 'scheduledTaskCount',
            type: 'number'
          },
          {
              name: translations["REPORTS.TASKS_COUNT"],
              key: 'totalCount',
              type: 'number',
            },
            {
              name: translations["REPORTS.CORRECTIVE_TIME"],
              key: 'workingTimeCorrective',
              type: 'number',
            },
            {
              name: translations["REPORTS.SCHEDULED_TIME"],
              key: 'workingTimeTasks',
              type: 'number'
            },
            {
              name: translations["REPORTS.TOTAL_TIME"],
              key: 'totalWorkingTime',
              type: 'number'
            },
        ];
      }
    )};
}