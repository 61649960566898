<div class="sixteen wide column" *ngIf="!showFilterForm"
       style="display: flex; justify-content: flex-start">
    <div></div>
    <button *ngIf="shouldShowDeleteButton()" 
    (click)="clickOnDeleteTask()" [class.disabled]="task?.finishedAt" class="ui labeled icon red button">
      <i class="trash icon"></i>
      {{ 'SCHEDULED_TASK_DETAIL.DELETE_TASK' | translate }}
    </button>

    <div class="ui primary button" style="margin-left: 10px;"
         [class.disabled]="!canEditTask"
         (click)="clickOnEditTask()"
    > {{ 'SCHEDULED_TASK_DETAIL.SAVE' | translate }}
    </div>
  </div>

  <div class="sixteen wide column" *ngIf="showFilterForm"
       style="display: flex; justify-content: flex-start">
    <div></div>
    <button (click)="clickOnDeleteTask()" [class.disabled]="task?.finishedAt" class="ui labeled icon red button">
      <i class="trash icon"></i>
      {{ 'SCHEDULED_TASK_DETAIL.DELETE_TASK' | translate }}
    </button>

    <div class="ui primary button" style="margin-left: 10px;"
         [class.disabled]="!canEditTask"
         (click)="clickOnEditTask()"
    > {{ 'SCHEDULED_TASK_DETAIL.SAVE' | translate }}
    </div>
  </div>

  <div class="ui hidden divider"></div>

<div class="ui grid">
  <div class="sixteen wide column" *ngIf="showFilterForm">
    <div class="ui grid">
      <div class="sixteen wide column">
        <app-conditions-filter-group-form
          [conditionsFiltersArray]="filtersForm.get('inclusiveFilters')"
          [filterParams]="filterParams"
        ></app-conditions-filter-group-form>
      </div>
      <div class="sixteen wide column">
        <app-conditions-filter-group-form
          [conditionsFiltersArray]="filtersForm.get('exclusiveFilters')"
          [filterParams]="filterParams"
          [firstGroupTitle]="'SCHEDULED_TASK_DETAIL.EXCLUDE_COMPONENTS_WITH_ALL' | translate"
          [nextGroupsTitle]="'SCHEDULED_TASK_DETAIL.OR_COMPONENTS_WITH_ALL' | translate"
        ></app-conditions-filter-group-form>
      </div>
    </div>
  </div>

  

  <div class="sixteen wide column" *ngIf="filteredComponents">
    <app-filtered-components-table
      [components]="filteredComponents">
      <app-pagination-num-items-info
        slot="pagination"
        itemsName="{{ 'SCHEDULED_TASK_DETAIL.COMPONENTS' | translate }}"
        [page]="filteredComponentsPage"
        [numItemsPerPage]="numComponentsPerPage"
        [totalItems]="totalComponentsFiltered"
      ></app-pagination-num-items-info>
      <fui-pagination-menu
        [page]="filteredComponentsPage"
        [numOfPages]="componentsTotalPages"
        (pageSelected)="pageClick($event)"
      ></fui-pagination-menu>
    </app-filtered-components-table>
  </div>
</div>
