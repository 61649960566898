import {NgModule} from '@angular/core';
import {DashboardPageComponent} from './pages/dashboard/dashboard-page.component';
import {SharedModule} from '../shared/shared.module';
import {ChartsModule} from '../charts/charts.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DashboardPageComponent
  ],
  imports: [
    SharedModule,
    ChartsModule,
    TranslateModule.forChild(),
  ]
})
export class DashboardModule { }
