<ng-container [formGroup]="form">

  <div class="three fields">
    <div class="field">
      <label>{{ 'SCHEDULED_TASK_DETAIL.NAME' | translate }}</label>
      <input formControlName="name" [readOnly]="readonlyFields?.name" type="text">
    </div>
    <div class="field">
      <label>{{ 'SCHEDULED_TASK_DETAIL.CATEGORY' | translate }}</label>
      <pui-dropdown 
      formControlName="category" 
      [search]="true"
      [readonly]="readonlyFields?.category"
      [options]="categories">
    </pui-dropdown>
    </div>
    <div class="field">
      <app-label-and-info
        [labelText]="'SCHEDULED_TASK_DETAIL.FREQUENCY' | translate"
        [helpingText]="'SCHEDULED_TASK_DETAIL.FREQUENCY_HELP' | translate"
      ></app-label-and-info>
      <div class="two fields">
        <div class="field">
          <input appNumeric integer min="1" [readOnly]="readonlyFields?.frequencyQuantity"
                 formControlName="frequencyQuantity" type="text">
        </div>
        <div class="field">
          <pui-dropdown [search]="true"
          formControlName="frequencyUnit" [readonly]="readonlyFields?.frequencyUnit"
                        [options]="frequencyUnits"></pui-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="three fields">
    <div class="field">
      <label>{{ 'SCHEDULED_TASK_DETAIL.COMPANY' | translate }}</label>
      <pui-dropdown 
      formControlName="company" 
      [search]="true"
      [readonly]="readonlyFields?.company" 
      [options]="companies">
    </pui-dropdown>
    </div>
    <div *ngIf="!creatingTask" class="field">
      <app-label-and-info
        [labelText]="'SCHEDULED_TASK_DETAIL.ASSIGNED_USER' | translate"
        [helpingText]="'SCHEDULED_TASK_DETAIL.ASSIGNED_USER_HELP' | translate"
      ></app-label-and-info>
      <pui-dropdown 
      [readonly]="readonlyFields?.assignedTo"
      [options]="users" 
      formControlName="assignedTo"        
      valueParam="email" 
      labelParam="email">
    </pui-dropdown>
    </div>
    <div class="field">
      <label>{{ 'SCHEDULED_TASK_DETAIL.SCHEDULED_DATE' | translate }}</label>
      <fui-calendar
        selectorId="initDateSel"
        [disabled]="readonlyFields?.createdAt"
        formControlName="createdAt"
      ></fui-calendar>
    </div>
    <div class="field">
      <app-label-and-info
        [labelText]="'SCHEDULED_TASK_DETAIL.TASK_TYPE' | translate"
        [helpingText]="'SCHEDULED_TASK_DETAIL.TASK_TYPE_HELP' | translate"
      ></app-label-and-info>
      <pui-dropdown 
      [readonly]="readonlyFields?.type"
      [options]="taskTypes" 
      formControlName="type"
      >
      </pui-dropdown>
    </div>
  </div>

  <div class="field">
    <div class="ui checkbox">
      <input type="checkbox" [readonly]="readonlyFields?.strictDates" formControlName="strictDates">
      <label>{{ 'SCHEDULED_TASK_DETAIL.STRICT_DATES' | translate }}
        <i class="circle info icon"
          (click)="showInfoStrictDates = !showInfoStrictDates"></i>
      </label>
      <div *ngIf="showInfoStrictDates" class="field-description">
        <p>
          {{ 'SCHEDULED_TASK_DETAIL.STRICT_DATES_EXAMPLE' | translate }}<br>
        </p>
        <p>
          {{ 'SCHEDULED_TASK_DETAIL.STRICT_DATES_EXPLANATION' | translate }}
        </p>
      </div>
    </div>
  </div>

</ng-container>
