<div [ngClass]="customClass">
    <label [attr.for]="forAttribute">
        {{ labelText }}
        <span class="icon-wrapper" (click)="toggleInfo()">
            <i class="circle info icon"></i>
        </span>
    </label>
    <div *ngIf="showInfo">
        <p>{{ helpingText }}</p>
    </div>
</div>