import {Component, EventEmitter, OnInit, Output} from '@angular/core';

declare var Html5Qrcode: any;

@Component({
  selector: 'app-qr-reader-modal',
  templateUrl: './qr-reader-modal.component.html',
  styleUrls: ['./qr-reader-modal.component.scss']
})
export class QrReaderModalComponent implements OnInit {

  private reader: any;

  @Output() close = new EventEmitter<null>();
  @Output() dataRead = new EventEmitter<string>();
  @Output() errorOnRead = new EventEmitter<null>();

  constructor() {
  }

  ngOnInit(): void {
    const config = {
      fps: 10,
      qrbox: 250
    };
    this.reader = new Html5Qrcode('reader');
    this.reader.start({facingMode: 'environment'}, config, dataRead => {
      this.dataRead.emit(dataRead);
      this.clickClose();
    })
      .catch(error => {
        this.errorOnRead.emit();
      });
  }

  clickClose() {
    this.reader.stop().then(ignore => {
      this.close.emit();
    })
      .catch(error => this.close.emit());
  }
}
