import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {CompanyGetPaginatedDtoInterface, CompanyInterface} from './models/company.interface';
import {CategoryInterface} from '../shared/models/category.interface';
import {UserInterface} from '../users/models/user.interface';
import {PaginatedResult} from '../shared/models/paginated-result.interface';
import {SessionService} from '../shared/services/session.service';
import {skipWhile, switchMap} from 'rxjs/operators';
import { UtilsService } from '../shared/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  private readonly apiHost: string;

  constructor(
    private http: HttpClient, 
    private session: SessionService,
    private utilsService: UtilsService) {
    this.apiHost = environment.apiHost;
  }

  fetchCategories(): Observable<CategoryInterface[]> {
    return this.http.get<CategoryInterface[]>(`${this.apiHost}/categories`);
  }

  fetchCompany(companyId: number, facilityId?: number) {
    const queryParams = facilityId !== undefined ? `?facilityId=${facilityId}` : '';
  return this.http.get<CompanyInterface>(`${this.apiHost}/companies/${companyId}${queryParams}`);
}

  fetchAllCompanies(queryParams = {}): Observable<PaginatedResult<CompanyInterface> | CompanyInterface[]> {
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      switchMap(facility => {
        let urlParams: any = Object.assign(queryParams, {facilityId: facility.id});
        urlParams = this.utilsService.parseParams(urlParams);
        return this.http.get<PaginatedResult<CompanyInterface> | CompanyInterface[]>(`${this.apiHost}/companies?${urlParams.join('&')}`);
      })
    );
  }

  uploadLogoAndGetCompany(facilityId: number, companyId: number, logo: File): Observable<CompanyInterface> {
    const formData = new FormData();
    formData.append('logo', logo);

    return this.http.post<CompanyInterface>(`${this.apiHost}/companies/${companyId}/logo?facilityId=${facilityId}`, formData);
  }

  // TODO: replace all uses of this funtions with automated inserted facility id version
  fetchAll(queryParams: {}): Observable<CompanyGetPaginatedDtoInterface | CompanyInterface[]> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.get<CompanyGetPaginatedDtoInterface | CompanyInterface[]>(`${this.apiHost}/companies?${params.join('&')}`);
  }

  fetchAllInFacility(queryParams: {}): Observable<CompanyGetPaginatedDtoInterface | CompanyInterface[]> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.get<CompanyGetPaginatedDtoInterface | CompanyInterface[]>(`${this.apiHost}/companies/in-facility?${params.join('&')}`);
  }

  createCompany(facilityId: number, data: any): Observable<any> {
    const formData = new FormData();
  
    // Append each field to the FormData
    Object.keys(data).forEach((key) => {
      if (key === 'logo' && data[key] instanceof File) {
        // Handle the 'logo' field as a file
        formData.append(key, data[key], data[key].name);
      } else if (data[key] !== undefined) {
        if (Array.isArray(data[key])) {
          // Handle arrays by appending each element individually
          data[key].forEach((element) => {
            formData.append(`${key}[]`, element);
          });
        } else {
          formData.append(key, data[key]);
        }
      }
    });
  
    // Send the request with FormData
    return this.http.post(`${this.apiHost}/companies?facilityId=${facilityId}`, formData);
  }
  

  updateCompany(companyId: number, data: any, queryParams: {}): Observable<CompanyInterface> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.patch<CompanyInterface>(`${this.apiHost}/companies/${companyId}?${params.join('&')}`, data);
  }

  fetchCompanyUsers(companyId: number, queryParams: {facilityId:number, roleIds:any}): Observable<UserInterface[]> {
    const params = this.utilsService.parseParams(queryParams);

    return this.http.get<UserInterface[]>(`${this.apiHost}/companies/${companyId}/users?${params.join('&')}`);
  }

}
