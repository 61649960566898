import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ToastService} from '../services/toast.service';
import {SessionService} from '../services/session.service';
import {animate, sequence, state, style, transition, trigger} from '@angular/animations';
import {Subject} from 'rxjs';
import {pluck, take, takeUntil} from 'rxjs/operators';
import {SpinnerVeilService} from "../services/spinner-veil.service";
import {AutodeskViewerService} from '../../autodesk-forge/autodesk-viewer.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  animations: [
    trigger('showMobileSideNav', [
      state('closed', style({
        left: '-100%'
      })),
      state('open', style({
        left: '0'
      })),
      transition('open <=> closed', [
        animate(200)
      ])
    ]),

    trigger('bounce', [
      transition(':increment', [
        sequence([
          animate('200ms ease-out', style({fontSize: '1.5rem'})),
          animate('200ms ease-out', style({fontSize: '*'}))
        ])
      ])
    ])
  ],
 /* host: {
    "(document:click)": "onClick($event)",
  },*/
})
export class LayoutComponent implements OnInit,AfterViewInit, OnDestroy {

  @HostBinding('class.collapsed-sidenav') sideNavCollapsed;
  toastOpened = false;
  toastMessage: string;
  toastHeader: string;
  toastType: string;

  mobileSidenavState = 'closed';

  destroy$ = new Subject<boolean>();

  loadingMessage = '';
  currentMessageIndex = 0;
  messages:Array<string> = [
    "LOADING_MESSAGE0",
    "LOADING_MESSAGE1",
    "LOADING_MESSAGE2",
    "LOADING_MESSAGE3",
  ];
  interval = null;

  constructor(
    public session: SessionService,
    public toastService: ToastService,
    public veilService: SpinnerVeilService,
    public autodeskViewerSrv: AutodeskViewerService,
  ) { }

  ngOnInit(): void {
    this.session.userConfig$.pipe(
      take(1),
      pluck('collapsedSideBar')
    ).subscribe(collapsed => this.sideNavCollapsed = collapsed);

    this.toastService.showToastRequests$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(toastRequest => {
      if (toastRequest) {
        this.toastMessage = toastRequest.message;
        this.toastHeader = toastRequest.header;
        this.toastType = toastRequest.type;

        // Default timer for success messages
        let timeout = 4000;
        if (this.toastType === 'error') {
          // Default timer for error messages
          timeout = 180000;
        }
        if (toastRequest.duration) {
          timeout = toastRequest.duration
        }

        this.toastOpened = true;
        setTimeout(() => {
          this.toastOpened = false;
        }, timeout);
      }
    });
    this.toastService.hideToastRequests$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(dismiss => {
      if (dismiss) {
        this.hideToast();
      }
    });

  }

  ngAfterViewInit(): void {

    this.veilService.isOpen$.subscribe(
      (isOpen:boolean)=>{
        if(isOpen) {
          this.loadingMessage = this.messages[0];
          this.interval = setInterval(() => {
            this.loadingMessage = this.messages[this.currentMessageIndex];
            this.currentMessageIndex++;

            if(this.currentMessageIndex > 2){
              this.currentMessageIndex = 0;
            }
          },3000);
        }else{
          clearInterval(this.interval);
        }
      }
    )

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  toggleSideBar() {
    this.session.setUserConfig({collapsedSideBar: !this.sideNavCollapsed});
    this.sideNavCollapsed = !this.sideNavCollapsed;
    this.autodeskViewerSrv.shouldResize$.next(true);
  }

  openSidenav() {
    this.sideNavCollapsed = false;
    this.mobileSidenavState = 'open';
    this.autodeskViewerSrv.shouldResize$.next(true);
  }

  closeSidenav() {
    this.mobileSidenavState = 'closed';
    this.autodeskViewerSrv.shouldResize$.next(true);
  }

  hideToast() {
    this.toastOpened = false;
  }

}
