import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../users/services/user.service';
import {SessionService} from '../../../shared/services/session.service';
import {combineLatest, Subject} from 'rxjs';
import {UserGroupInterface} from '../../models/user-group.interface';
import {UserInterface} from '../../../users/models/user.interface';
import {Facility} from '../../../facilities/models/facility.interface';
import {skipWhile, take, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-detail-user-group-page',
  templateUrl: './detail-user-group-page.component.html',
  styleUrls: ['./detail-user-group-page.component.scss']
})
export class DetailUserGroupPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';

  group: UserGroupInterface;
  users: UserInterface[];

  private activeFacility: Facility;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private router: Router, private session: SessionService, private usersSrv: UserService) {}

  ngOnInit(): void {

     this.session.activeFacility$.pipe(
       skipWhile(f => !f),
       takeUntil(this.destroy$)
     ).subscribe(facility => {
       if (this.activeFacility && this.activeFacility.id !== facility.id) {
         this.router.navigateByUrl('grupos-usuarios');
       } else {
         this.activeFacility = facility;
       }
     });

     combineLatest([
       this.route.paramMap,
       this.session.activeFacility$.pipe(skipWhile(f => !f))
     ]).pipe(
       take(1)
     ).subscribe(([params, facility]) => {
        this.usersSrv.fetchAll({facilityId: facility.id}).subscribe((users: UserInterface[]) => this.users = users);
        this.usersSrv.fetchGroup(facility.id, parseInt(params.get('groupId'))).subscribe(group => this.group = group);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  saveChanges(data) {
    this.usersSrv.editGroup(this.activeFacility.id, this.group.id, data).subscribe(result => {
      this.router.navigate(['/grupos-usuarios']);
    });
  }

}
