import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DateTime } from 'luxon'; 
import {REPORT_ID, ReportStatDTO} from './models/reports.inteface';
import {Observable} from 'rxjs';
import { UtilsService } from '../shared/services/utils.service';
import { UrlParamsInterface } from '../shared/models/utils.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  private readonly apiHost = environment.apiHost;

  constructor(
    private http: HttpClient, 
    private utilsService: UtilsService) {
  }

  fetchReports(from: Date, to: Date, facilityIds: number[], companyIds: number[], typeIds: number[]) {

    const queryParams = {
      from: DateTime.fromJSDate(from).toFormat('yyyy-MM-dd'),
      to: DateTime.fromJSDate(to).toFormat('yyyy-MM-dd'),
      facilityIds, companyIds, typeIds
    };
    const params = this.utilsService.parseParams(queryParams);

    return this.http.get(`${this.apiHost}/report?${params.join('&')}`);
  }

  fetchReport(
    report: REPORT_ID,
    from: Date, to: Date,
    urlParams: UrlParamsInterface = null,
    compareFrom: Date = null, compareTo: Date = null
  ): Observable<ReportStatDTO> {
    const params = {
      from: DateTime.fromJSDate(from).toFormat('yyyy-MM-dd'),
      to: DateTime.fromJSDate(to).toFormat('yyyy-MM-dd')
    };
  
    if (urlParams) {
      if (report !== REPORT_ID.COST_BY_FACILITY) {
        urlParams.facilityIds = [urlParams.activeFacilityId] as number[];
      }
  
      Object.assign(params, urlParams);
    }
  
    if (compareFrom && compareTo) {
      const compareParams = {
        secondFrom: DateTime.fromJSDate(compareFrom).toFormat('yyyy-MM-dd'),
        secondTo: DateTime.fromJSDate(compareTo).toFormat('yyyy-MM-dd')
      };
      Object.assign(params, compareParams);
    }
  
    const filteredParams = this.utilsService.filterNonNullValues(params);
    const formattedParams = this.utilsService.parseParams(filteredParams);
  
    const url = `${this.apiHost}/report/${report}?${formattedParams.join('&')}`;
    return this.http.get<ReportStatDTO>(url);
  }

  getReportDataCSV(
    reportName: REPORT_ID,
    from: Date,
    to: Date,
    facilityIds: number[] = null,
    companyIds: number[] = null,
    typeIds: number[] = null,
    secondFrom?: Date,
    secondTo?: Date,
  ) {

    let params: {
      from: string; 
      to: string;
      facilityIds: number[]; 
      companyIds?: number[]; 
      typeIds?: number[];
      secondFrom?: string; 
      secondTo?: string; 
    };

    if (secondFrom && secondTo) {
      params = {
        facilityIds, companyIds, typeIds,
        from: DateTime.fromJSDate(from).toFormat('yyyy-MM-dd'),
        to: DateTime.fromJSDate(to).toFormat('yyyy-MM-dd'),
        secondFrom: DateTime.fromJSDate(secondFrom).toFormat('yyyy-MM-dd'),
        secondTo: DateTime.fromJSDate(secondTo).toFormat('yyyy-MM-dd')
      };
    } else {
      params = {
        facilityIds, companyIds, typeIds,
        from: DateTime.fromJSDate(from).toFormat('yyyy-MM-dd'),
        to: DateTime.fromJSDate(to).toFormat('yyyy-MM-dd')
      };
    }
  
    const filteredParams = this.utilsService.filterNonNullValues(params);
    const formattedParams = this.utilsService.parseParams(filteredParams);

    return this.http.get(`${this.apiHost}/report/${reportName}/csv?${formattedParams.join('&')}`, { responseType: 'text' });
  }

}
