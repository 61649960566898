<div class="sixteen wide column">
  <table class="ui small table" [formGroup]="detailWorkOrderSrv.billingForm">
    <thead>
      <tr>
        <th class="center aligned" colspan="6" style="font-size: 13pt">{{ 'WORK_ORDER_DETAIL.COST_MATERIALS_TITLE' | translate }}</th>
      </tr>
      <tr>
        <th>{{ 'WORK_ORDER_DETAIL.SUBCATEGORY' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.DESCRIPTION' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.UNITS' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.MEASURE' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.UNIT_COST' | translate }}<span style="color:red">*</span></th>
        <th>{{ 'WORK_ORDER_DETAIL.TOTAL_COST' | translate }}</th>
      </tr>
    </thead>
    <tbody formArrayName="workOrderMaterialsCosts">
      <tr *ngFor="let orderMaterial of (detailWorkOrderSrv.workOrder$ | async)?.workOrderMaterials; let i = index;">
        <td>{{ orderMaterial.material.category.name }}</td>
        <td>{{ orderMaterial.material.name }}</td>
        <td>{{ orderMaterial.quantity }}</td>
        <td>{{ detailWorkOrderSrv.materialUnitLiteral(orderMaterial.materialUnitId) }}</td>
        <td *ngIf="detailWorkOrderSrv.billingForm.get('workOrderMaterialsCosts')?.get(i.toString())" [formGroupName]="i">
          <div class="ui right labeled input">
            <input 
              appNumeric [min]="0" min="0" 
              formControlName="unitCost" 
              [readOnly]="(detailWorkOrderSrv.workOrder$ | async)?.isClosed()" 
              type="text"
              pattern="^\d*\.?\d*$"
              [class.empty-input]="!detailWorkOrderSrv.billingForm.value['workOrderMaterialsCosts']?.length || !detailWorkOrderSrv.billingForm.value['workOrderMaterialsCosts'][i]?.unitCost">
            <div class="ui basic label">{{ currencySymbol }}</div>
          </div>
        </td>
        <td *ngIf="detailWorkOrderSrv.billingForm.get('workOrderMaterialsCosts')?.get(i.toString())">
          {{ (detailWorkOrderSrv.billingForm.value['workOrderMaterialsCosts'][i].unitCost * orderMaterial.quantity) | currency:currencySymbol }}
        </td>
      </tr>
    </tbody>
    <thead>
      <tr>
        <th class="center aligned" colspan="6" style="font-size: 13pt; font-weight: 700">{{ 'WORK_ORDER_DETAIL.LABOR_COST_TITLE' | translate }}</th>
      </tr>
      <tr>
        <th colspan="3"></th>
        <th>{{ 'WORK_ORDER_DETAIL.HOURS' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.COST_PER_HOUR' | translate }}<span style="color:red">*</span></th>
        <th>{{ 'WORK_ORDER_DETAIL.TOTAL_HOUR_COST' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="3"></td>
        <td>
          {{ (detailWorkOrderSrv.workOrder$ | async)?.workingTime / 60 | number:'1.0-1' }}
        </td>
        <td>
          <div class="ui right labeled input">
            <input 
              appNumeric formControlName="workingTimeCost" [min]="0" 
              type="text" 
              pattern="^\d*\.?\d*$"
              [readOnly]="(detailWorkOrderSrv.workOrder$ | async)?.isClosed()"
              [class.empty-input]="!detailWorkOrderSrv.billingForm.value['workingTimeCost']"
            >
            <div class="ui basic label">{{ currencySymbol }}</div>
          </div>
        </td>
        <td>
          {{ getTotalHoursCost() | async | currency:currencySymbol }}
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <th colspan="4"></th>
        <th style="font-weight: 700">{{ 'WORK_ORDER_DETAIL.TOTAL' | translate }}</th>
        <th style="font-weight: 700">{{ getTotalCost() | async | currency:currencySymbol }}</th>
      </tr>
    </tfoot>
  </table>
</div>
