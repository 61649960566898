<table class="ui table" [class.celled]="celled" [class.compact]="compact" [class.selectable]="selectable" [ngClass]="size ? size : ''">
  <thead>
  <tr>
    <th *ngFor="let field of fields">{{field.title || field.key | titlecase }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let element of formattedElements; let i = index" (click)="elementSelected.emit(elements[i])">
    <td *ngFor="let field of fields">{{element[field.resultKey || field.key]}}</td>
  </tr>
  </tbody>
  <tfoot>
  <tr *ngIf="pagination || rightButton">
    <th [colSpan]="fields.length">

      <fui-pagination-menu
        *ngIf="pagination"
        [page]="pagination.page"
        [numOfPages]="pagination.numOfPages"
        (pageSelected)="pageSelected.emit($event)"
      >
      </fui-pagination-menu>

      <div *ngIf="rightButton" (click)="rightButtonClick.emit()"
           class="ui small right floated primary labeled icon button"
           [class.disabled]="rightButton.disabled">

        <i class="{{rightButton.icon}} icon"></i> {{rightButton.text}}
      </div>
    </th>
  </tr>
  </tfoot>

</table>
