<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'COMPONENT_TYPES.TITLE' | translate }}</h1>
  
  <input hidden #fileInput type="file" (change)="uploadCSV()">
  <button class="ui left floated button" [class.loading]="exportingCSV" [class.disabled]="exportingCSV" (click)="exportCSV()">
    {{ 'COMPONENT_TYPES.EXPORT' | translate }}
  </button>
  <button class="ui left floated button" [class.loading]="uploadingCsv" [class.disabled]="uploadingCsv" (click)="fileInput.click()">
    {{ 'COMPONENT_TYPES.UPDATE_FROM_FILE' | translate }}
  </button>
</div>

<!-- --------- FILTERS FORM --------- -->
<div class="sixteen wide column">
  <app-collapsible-form [title]="'COMPONENT_TYPES.FILTER' | translate">
    <form [formGroup]="filtersForm" class="ui mini form">
      <div class="three fields">
        <div class="field">
          <label>{{ 'COMPONENT_TYPES.NAME' | translate }}</label>
          <input type="text" formControlName="name" (keydown)="handleKeyPress($event)">
        </div>
        <div class="field">
          <label>{{ 'COMPONENT_TYPES.MODEL' | translate }}</label>
          <input type="text" formControlName="modelNumber" (keydown)="handleKeyPress($event)">
        </div>
        <div class="field">
          <label>{{ 'COMPONENT_TYPES.BRAND' | translate }}</label>
          <input type="text" formControlName="company" (keydown)="handleKeyPress($event)">
        </div>
        <div class="field">
          <label>{{ 'COMPONENT_TYPES.REPLACEMENT_COST' | translate }}</label>
          <input type="text" formControlName="replacementCost" (keydown)="handleKeyPress($event)">
        </div>
        <div class="field">
          <label>{{ 'COMPONENT_TYPES.EXPECTED_LIFE' | translate }}</label>
          <input type="text" formControlName="expectedLife" (keydown)="handleKeyPress($event)">
        </div>
      </div>

      <div class="fields">
        <div class="eight wide field">
        </div>
        <div class="eight wide field" style="display: flex; justify-content: flex-end; align-items: flex-end">
          <div class="ui small basic button" (click)="clearFilters()">{{ 'COMPONENT_TYPES.CLEAR' | translate }}</div>
          <div class="ui small button" (click)="applyFilters()">{{ 'COMPONENT_TYPES.FILTER' | translate }}</div>
        </div>
      </div>
    </form>
  </app-collapsible-form>
</div>

<div class="sixteen wide column" style="display: flex; justify-content: flex-end;">
  <div style="width: 100px">
    <pui-dropdown size="small" [options]="elemsByPageOptions" [formControl]="elemsByPage"></pui-dropdown>
  </div>
</div>

<div class="sixteen wide column">
  <div class="table-container">
    <table class="ui sortable small selectable compact table">
      <thead>
        <tr>
          <th fuiSortableTh columnName="name" [sortedBy]="sortedBy$.value" (sortedByChange)="sortedBy$.next($event)">
            {{ 'COMPONENT_TYPES.NAME' | translate }}
          </th>
          <th fuiSortableTh columnName="modelNumber" [sortedBy]="sortedBy$.value" (sortedByChange)="sortedBy$.next($event)">
            {{ 'COMPONENT_TYPES.MODEL' | translate }}
          </th>
          <th fuiSortableTh columnName="company" [sortedBy]="sortedBy$.value" (sortedByChange)="sortedBy$.next($event)">
            {{ 'COMPONENT_TYPES.BRAND' | translate }}
          </th>
          <th fuiSortableTh columnName="replacementCost" [sortedBy]="sortedBy$.value" (sortedByChange)="sortedBy$.next($event)">
            {{ 'COMPONENT_TYPES.REPLACEMENT_COST' | translate }}
          </th>
          <th fuiSortableTh columnName="expectedLife" [sortedBy]="sortedBy$.value" (sortedByChange)="sortedBy$.next($event)">
            {{ 'COMPONENT_TYPES.EXPECTED_LIFE' | translate }}
          </th>
          <th fuiSortableTh columnName="components" [sortedBy]="sortedBy$.value" (sortedByChange)="sortedBy$.next($event)">
            {{ 'COMPONENT_TYPES.COMPONENTS' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let type of types" [routerLink]="[type.id]">
          <td>{{type.name}}</td>
          <td>{{type.modelNumber || '---'}}</td>
          <td>{{type.company || '---'}}</td>
          <td>{{type.replacementCost || '---'}}</td>
          <td>{{type.expectedLife || '---'}}</td>
          <td>{{type.components}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-foot">
    <tfoot>
      <tr>
        <th colspan="4">
          <fui-pagination-menu [page]="page$ | async" [numOfPages]="totalPages" (pageSelected)="page$.next($event)"></fui-pagination-menu>
        </th>
        <th colspan="4">
          <app-pagination-num-items-info
            [itemsName]="'COMPONENT_TYPES.TYPES' | translate"
            [page]="page$ | async"
            [numItemsPerPage]="elemsByPage.value.value"
            [totalItems]="totalTypes"
          ></app-pagination-num-items-info>
        </th>
      </tr>
    </tfoot>
  </div>
</div>

<pui-modal *ngIf="showErrorsModal" [title]="'COMPONENT_TYPES.ERRORS_MODAL_TITLE' | translate">
  <div class="upload-errors">
    <ul>
      <li *ngFor="let error of uploadErrors">
        {{ 'COMPONENT_TYPES.ROW' | translate }}: {{error.row}} - {{error.message}}
      </li>
    </ul>
  </div>
  <div class="upload-errors--footer" footer>
    <div class="ui primary button" (click)="this.showErrorsModal = false">{{ 'COMPONENT_TYPES.ACCEPT' | translate }}</div>
  </div>
</pui-modal>
