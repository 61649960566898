<div class="table-container">
  <table class="ui small sortable selectable table">
    <thead>
      <tr>
        <th fuiSortableTh columnName="id"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
        >{{ 'ID' | translate }}</th>
        <th fuiSortableTh columnName="problemType"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
        >{{ 'PROBLEM' | translate }}</th>
        <th fuiSortableTh columnName="workOrderStateId"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
        >{{ 'STATUS' | translate }}</th>
        <th fuiSortableTh columnName="criticalityLevelId"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
        >{{ 'CRITICALITY' | translate }}</th>
        <th fuiSortableTh columnName="company"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
            *ngIf="canViewMaintenanceCompany()"
        >{{ 'MAINTENANCE_COMPANY' | translate }}</th>
        <th fuiSortableTh columnName="creatorCompany"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
            *ngIf="canViewCreatorCompany()"
        >{{ 'CREATOR_COMPANY' | translate }}</th>
        <th fuiSortableTh columnName="createdAt"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
            *ngIf="canViewCreatedDate()">{{ 'CREATED' | translate }}</th>
        <th fuiSortableTh columnName="startedAt"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
        >{{ 'IN_PROGRESS' | translate }}</th>
        <th fuiSortableTh columnName="finishedAt"
            [sortedBy]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
        >{{ 'FINISHED' | translate }}</th>
        <th fuiSortableTh columnName="closedAt"
            [(sortedBy)]="sortBy"
            (sortedByChange)="sortByChange.emit($event)"
        >{{ 'CLOSED' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let workOrder of workOrders" (click)="workOrderClicked.emit(workOrder)">
        <td>{{workOrder.id}}</td>
        <td>{{workOrder.problemType.text}}</td>
        <td>{{stateLabel(workOrder.workOrderStateId)}}</td>
        <td>{{workOrder.criticalityLevel.name}}</td>
        <td *ngIf="canViewMaintenanceCompany()">{{workOrder.assignedCompany.name}}</td>
        <td *ngIf="canViewCreatorCompany()">{{workOrder.creator.company?.name}}</td>
        <td *ngIf="canViewCreatedDate()">{{formatDate(workOrder.createdAt)}}</td>
        <td>{{formatDate(workOrder.startedAt)}}</td>
        <td>{{formatDate(workOrder.finishedAt)}}</td>
        <td>{{formatDate(workOrder.closedAt)}}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="table-foot">
  <tfoot *ngIf="pagination">
    <tr>
      <th colspan="9">
        <ng-content></ng-content>
      </th>
      <th colspan="9">
        <ng-content select="[pagination-info]"></ng-content>
      </th>
    </tr>
  </tfoot>
</div>
