import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'pui-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TextAreaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
