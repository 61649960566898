<div class="ui segment chatMessagesWindow" #chatMessagesWindow>
  <div class="ui comments">
    <ng-container *ngFor="let message of messages">
      <div class="comment">
        <div class="avatar">
          <img [src]="getPhotoSource(message)" alt="Profile Picture">
        </div>
        <div class="content">
          <a *ngIf="message.type === 'SYSTEM'" class="author">{{ 'MESSAGES.SYSTEM' | translate }}</a>
          <a *ngIf="!(message.type === 'SYSTEM')" class="author">{{message.creator.name}} {{message.creator.surname}}</a>
          <div class="metadata">
            <div class="date">{{formatDate(message.createdAt)}} {{formatHour(message.createdAt)}}</div>
          </div>
          <div class="text">
            <ng-container *ngIf="message.action; else plainText">
              <span [innerHTML]="sanitizeMessage(processMessage(message.action, message.text))"></span>
              <span *ngIf="message.payload && message.payload.id"> #{{ message.payload.id }} </span>
              <span *ngIf="message.type === 'SYSTEM'">{{ 'MESSAGES.BY' | translate }} {{message.payload.creator}}</span>
            </ng-container>
            <ng-template #plainText>
              <span [innerHTML]="sanitizeMessage(processMessage(null, message.text))"></span>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<form *ngIf="!disabled" class="ui form" #chatForm=ngForm (ngSubmit)="sendMessage(chatForm.value)">
  <div class="field">
    <div class="ui action input">
      <textarea ngModel name="text" type="text" class="message-input" placeholder="{{'MESSAGES.PLACEHOLDER_MESSAGE' | translate }}"></textarea>
      <button type="submit" class="ui primary right icon button">
        {{ 'MESSAGES.COMMENT' | translate }}
      </button>
    </div>
  </div>
</form>
