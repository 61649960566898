import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {CompanyInterface} from '../../models/company.interface';
import {CompaniesService} from '../../companies.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../shared/services/session.service';
import {combineLatest, Subject} from 'rxjs';
import {filter, skipWhile, take, takeUntil} from 'rxjs/operators';
import {Facility} from '../../../facilities/models/facility.interface';
import { ToastService } from 'src/app/shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-company',
  templateUrl: './detail-company.component.html',
  styleUrls: ['./detail-company.component.scss']
})
export class DetailCompanyComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';

  activeFacility: Facility;
  company: CompanyInterface;

  successUpdate = false;
  errorUpdate = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companiesSrv: CompaniesService,
    private session: SessionService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.session.activeFacility$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(facility => {
      if (this.activeFacility && this.activeFacility.id !== facility.id) {
        this.router.navigateByUrl('empresas');
      }
    });

    combineLatest([
      this.session.activeFacility$.pipe(skipWhile(facility => !facility)),
      this.route.paramMap
    ]).pipe(
      take(1)
    ).subscribe(([facility, params]) => {
      // TODO: Asociar el servicio de companies al de sesión, para no tener
      //       que estar pasando el facility activo en todas las clases
      this.activeFacility = facility;
      this.companiesSrv.fetchCompany(parseInt(params.get('companyId')), facility.id)
        .subscribe(company => this.company = company);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  update(data) {
    this.companiesSrv.updateCompany(this.company.id, data, {facilityId: this.activeFacility.id}).subscribe(result => {

      this.company = result;
      this.toastService.showToast({
        type: "success",
        message: this.translate.instant('COMPANIES.UPDATE_SUCCESS_MESSAGE')
      });
    }, error => {
      this.toastService.showToast({
        type: "error",
        message: this.translate.instant('COMPANIES.UPDATE_ERROR_MESSAGE')
      });
    });
  }

}
