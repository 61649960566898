<div class="sixteen wide column">
    <h1 class="ui dividing header">{{'SETTINGS.TITLE' | translate}}</h1>
  </div>
  
  <configuration-menu [defaultSectionKey]="'facilityInfo'"></configuration-menu>
  
  <div class="sixteen wide mobile twelve wide computer column">
    <div class="ui grid">
      <div *ngIf="activeSection" class="sixteen wide column">
  
        <app-facilities-tab *ngIf="activeSection.key === 'facilityInfo'">
        </app-facilities-tab>
  
      </div>
  
    </div>
  </div>
  
  