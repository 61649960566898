
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AbstractControl, FormBuilder,FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { NEVER, Subject, Subscription, of } from "rxjs";
import { filter,switchMap, takeUntil } from "rxjs/operators";
import { IGooglePlaceAddress } from "src/app/companies/models/google-place-address.interface";
import { FacilitiesService } from "src/app/facilities/facilities.service";
import { CURRENCYUNIT, Facility } from "src/app/facilities/models/facility.interface";
import { ActionTypes } from "src/app/shared/models/role.interface";
import { RolesService } from "src/app/shared/services/roles.service";
import { SessionService } from "src/app/shared/services/session.service";
import { ToastService } from "src/app/shared/services/toast.service";

@Component({
selector: "app-facilities-tab",
styleUrls: ["./facilities-tab.component.scss"],
templateUrl: "./facilities-tab.component.html",
})
export class FacilitiesConfigurationTabComponent{

    isEditing: boolean;
    form: FormGroup;
    formattedAddress: IGooglePlaceAddress;
    facilitySubs: Subscription;
    activeFacility: Facility;
    destroy$: Subject<boolean> = new Subject<boolean>();
    currencyCodes: { value: string; name: string }[] = Object.keys(CURRENCYUNIT).map((value) => ({
        value,
        name: CURRENCYUNIT[value],
      }));

    @Output() submittedValue = new EventEmitter();
    @Input() set facility(data: Facility) {
        if (data){
            this.isEditing = true;
            this.form.controls.id.setValue(data.id);
            this.form.controls.name.setValue(data.name);
            this.form.controls.currencyUnit.setValue(data.currencyUnit);
            this.form.controls.siteName.setValue(data.siteName);

            if (data.address) {
                this.formattedAddress = data.address;
                this.form.controls.address.setValue(data.address.formattedAddress);
                this.form.controls.city.setValue(data.address.city);
                this.form.controls.postalCode.setValue(data.address.postalCode);
                this.form.controls.state.setValue(data.address.state);
                this.form.controls.country.setValue(data.address.country);
              }
        }

    }
    
    constructor(
        private fb: FormBuilder, 
        private sessionService: SessionService,
        private rolesService: RolesService,
        private router: Router,
        private facilitiesService: FacilitiesService,
        private toastService: ToastService,
        private translate: TranslateService,
    ){

    }
    ngOnInit(): void {
        this.form = this.fb.group({
            name: ['', FacilitiesConfigurationTabComponent.notEmptyValidator],
            id: [{value: '', disabled: true}],
            siteName: [''],
            currencyUnit: [''],
            address: [''],
            city: [{value: '', disabled: true}],
            postalCode: [{value: '', disabled: true}],
            state: [{value: '', disabled: true}],
            country: [{value: '', disabled: true}],
            
          });

        // Use switchMap to handle activeFacility change
        this.sessionService.activeFacility$
        .pipe(
            filter((facility) => !!facility),
            switchMap((facility) => {
                this.activeFacility = facility;

                this.checkUserRole();
                
                return of(facility);
                }),
            takeUntil(this.destroy$)
        )
        .subscribe(() => {
            this.getFacility();
        });
    }

    checkUserRole(){
        if (!this.rolesService.can(ActionTypes.ManageFacilityInfo)) {
            // Use navigateByUrl to ensure synchronous navigation
            this.router.navigateByUrl("/configuracion/notificaciones");
            return NEVER; // Stop further execution if role is not authorized
        }
    }

    getFacility(){
        this.facilitiesService.fetchOne(this.activeFacility.id)
        .subscribe((facility) => this.facility = facility)

    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
      }

    private static notEmptyValidator(control: AbstractControl): { [key: string]: any } | null {
        return !control.value.trim() ? {valueCanNotBeEmpty: true} : null;
      }
      
    onSelectedAddress(address) {
        this.formattedAddress = {
          id: (this.formattedAddress || {}).id || null,
          formattedAddress: address.formatted_address,
          postalCode: (address.address_components.find(component => component.types.includes('postal_code')) || {}).long_name || '',
          city: address.address_components.find(component => component.types.includes('locality')).long_name,
          state: address.address_components.find(component => component.types.includes('administrative_area_level_2')).long_name,
          country: address.address_components.find(component => component.types.includes('country')).long_name,
          latitude: address.geometry.location.lat(),
          longitude: address.geometry.location.lng()
        };
    
        this.form.controls.address.setValue(this.formattedAddress.formattedAddress);
        this.form.controls.city.setValue(this.formattedAddress.city);
        this.form.controls.postalCode.setValue(this.formattedAddress.postalCode);
        this.form.controls.state.setValue(this.formattedAddress.state);
        this.form.controls.country.setValue(this.formattedAddress.country);
      }

      getCurrencyUnit(): string{
        if (typeof this.form.value.currencyUnit === 'object'
          && 'value' in this.form.value.currencyUnit)
          return  this.form.value.currencyUnit.value;
        else
          return  this.form.value.currencyUnit;
      }

      submit(): void {
        const data = Object.assign({}, this.form.value);
        data.address = this.formattedAddress;
        data.currencyUnit = this.getCurrencyUnit();   
 
        if (this.isEditing) {
            this.update(data);
          } 
        
        this.form.markAsPristine();
      }

      update(data) {
        this.facilitiesService.updateFacility(this.activeFacility.id, data)
        .subscribe(
            result => {
    
          this.facility = result;
    
          this.toastService.showToast({
            message: this.translate.instant('SETTINGS.CHANGES_SAVED'),
            type: 'success',
          })
        });
      }
}