<div class="sixteen wide column">
  <h1 class="ui dividing header" translate>REPORTS.TITLE</h1>
</div>

<div class="sixteen wide column">
  <h4 class="ui header" translate>REPORTS.FILTERS</h4>
  <div class="ui stackable grid">
    <div class="eight wide column">
      <fui-calendar-range
        #mainRangeDateCalendar
        style="width: 100%"
        selectorId="rangeSelector"
        (startDateChange)="mainDateChange('start', $event)"
        (endDateChange)="mainDateChange('end', $event)"
      ></fui-calendar-range>
    </div>
    <div class="eight wide column ui grid">
      <div *ngIf="compareWithOtherRange.value" class="sixteen wide column">
        <fui-calendar-range
          #compareRangeDateCalendar
          selectorId="compareRangeSelector"
          (startDateChange)="compareDateChange('start', $event)"
          (endDateChange)="compareDateChange('end', $event)"
        ></fui-calendar-range>
      </div>
      <div class="sixteen wide column">
        <div class="ui checkbox">
          <input type="checkbox" [formControl]="compareWithOtherRange" [class.unchecked]="!compareWithOtherRange.value">
          <label translate>REPORTS.COMPARE_PERIOD</label>
        </div>
      </div>
    </div>
    <div class="sixteen wide column">
      <form class="ui form" [formGroup]="filters">
        <div class="two fields">
          <div class="four wide field" *ngIf="showCompanyFilter">
            <label translate>REPORTS.COMPANIES</label>
            <fui-dropdown-two
              selectorId="companyFilter"
              formControlName="companies"
              [multiple]="true"
              [search]="true"
              [options]="companies"
              valueProperty="id"
              placeholder="{{'REPORTS.COMPANIES' | translate}}"
            ></fui-dropdown-two>
          </div>
          <div class="four wide field">
            <label translate>REPORTS.REQUEST_TYPE</label>
            <fui-dropdown-two
              selectorId="typesFilter"
              formControlName="incidenceTypes"
              [multiple]="true"
              [options]="incidenceTypes"
              placeholder="{{'REPORTS.REQUEST_TYPE_PLACEHOLDER' | translate}}"
            ></fui-dropdown-two>
          </div>
          <div class="four wide field" *ngIf="showIncidenceStatusFilter">
            <label translate>REPORTS.STATUS</label>
            <fui-dropdown-two
              selectorId="statusFilter"
              formControlName="incidenceStatus"
              [multiple]="true"
              [search]="true"
              [options]="incidenceStatus"
              placeholder="{{'REPORTS.STATUS' | translate}}"
            ></fui-dropdown-two>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="one_line">
  <div #chooseTabs class="choose_tabs">
    <div class="left_side margin-bottom-3" *ngFor="let menu of menuLateral">
      <ng-container *ngIf="menu.can ? roles.can(menu.canName) : true">
        <div class="ui vertical menu">
          <a class="item" (click)="menu.opened = !menu.opened">{{ menu.name }} <span class="toogle_icon" *ngIf="menu.opened">-</span><span class="toogle_icon plus" *ngIf="!menu.opened">+</span></a>
        </div>
        <div *ngIf="menu.opened">
          <div class="ui vertical menu">
            <ng-container *ngFor="let subMenu of menu.subMenu">
              <div *ngIf="subMenu.whoCan ? roles.can(subMenu.whoCan) : true">
                <a class="item" [class.active]="activeStat$.value === reportStats[subMenu.key]" (click)="selectStat(reportStats[subMenu.key])">
                  <span>{{ subMenu.name }}</span>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div #rightSide class="right_side">
    <!--
      -------------------------------------------------------------------------
    -->
    <app-evolution-incidences-by-category-report
      class="report-stat twelve wide column ui grid"
      *ngIf="activeStat$.value === reportStats.INCIDENCE_EVO_BY_CATEGORY"
      [reportData]="evolutionIncidencesByCategory"
    >
    </app-evolution-incidences-by-category-report>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="totalIncidencesByCategory && activeStat$.value === reportStats.TOTAL_INCIDENCES_BY_CATEGORY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="totalIncidencesByCategory.dataSets"
      [labels]="totalIncidencesByCategory.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.TOTAL_INCIDENCES_BY_CATEGORY_TITLE' | translate"
    ></app-stack-chart>
    <button 
    *ngIf="totalIncidencesByCategory && activeStat$.value === reportStats.TOTAL_INCIDENCES_BY_CATEGORY"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="countOTsFinishedByCategory && activeStat$.value === reportStats.COUNT_OTS_FINISHED_BY_CATEGORY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="countOTsFinishedByCategory.dataSets"
      [labels]="countOTsFinishedByCategory.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.COUNT_OTS_FINISHED_BY_CATEGORY_TITLE' | translate"
    ></app-stack-chart>
    <button 
    *ngIf="countOTsFinishedByCategory && activeStat$.value === reportStats.COUNT_OTS_FINISHED_BY_CATEGORY"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="hoursByCategoryAndMaintenance && activeStat$.value === reportStats.HOURS_BY_CATEGORY_MAINTENANCE"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="hoursByCategoryAndMaintenance.dataSets"
      [labels]="hoursByCategoryAndMaintenance.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.HOURS_BY_CATEGORY_MAINTENANCE_TITLE' | translate"
    ></app-stack-chart>
    <button 
    *ngIf="hoursByCategoryAndMaintenance && activeStat$.value === reportStats.HOURS_BY_CATEGORY_MAINTENANCE"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-pie-chart
      *ngIf="hoursByMaintenanceCategory && activeStat$.value === reportStats.HOURS_BY_MAINTENANCECATEGORY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="hoursByMaintenanceCategory.dataSets"
      [labels]="hoursByMaintenanceCategory.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      chartType="pie"
      [title]="'REPORTS.HOURS_BY_MAINTENANCECATEGORY_TITLE' | translate"
    ></app-pie-chart>
    <button 
    *ngIf="hoursByMaintenanceCategory && activeStat$.value === reportStats.HOURS_BY_MAINTENANCECATEGORY"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-pie-chart
      *ngIf="countByMaintenanceCategoryCreated && activeStat$.value === reportStats.COUNT_BY_MAINTENANCECATEGORY_CREATED"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="countByMaintenanceCategoryCreated.dataSets"
      [labels]="countByMaintenanceCategoryCreated.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      chartType="pie"
      [title]="'REPORTS.COUNT_BY_MAINTENANCECATEGORY_CREATED_TITLE' | translate"
    ></app-pie-chart>
    <button 
    *ngIf="activeStat$.value === reportStats.COUNT_BY_MAINTENANCECATEGORY_CREATED"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-pie-chart
      *ngIf="countByMaintenanceCategoryFinished && activeStat$.value === reportStats.COUNT_BY_MAINTENANCECATEGORY_FINISHED"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="countByMaintenanceCategoryFinished.dataSets"
      [labels]="countByMaintenanceCategoryFinished.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      chartType="pie"
      [title]="'REPORTS.COUNT_BY_MAINTENANCECATEGORY_FINISHED_TITLE' | translate"
    ></app-pie-chart>
    <button 
    *ngIf="activeStat$.value === reportStats.COUNT_BY_MAINTENANCECATEGORY_FINISHED"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>


    <!--
      -------------------------------------------------------------------------
    -->
    <app-pie-chart
      *ngIf="scheduledTaskFinishedByTypeCategory && activeStat$.value === reportStats.SCHEDULEDTASK_FINISHED_BY_TYPE_CATEGORY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="scheduledTaskFinishedByTypeCategory.dataSets"
      [labels]="scheduledTaskFinishedByTypeCategory.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      chartType="pie"
      [title]="'REPORTS.SCHEDULEDTASK_FINISHED_BY_TYPE_CATEGORY_TITLE' | translate"
    ></app-pie-chart>
    <button 
    *ngIf="activeStat$.value === reportStats.SCHEDULEDTASK_FINISHED_BY_TYPE_CATEGORY"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="hoursByCompany && activeStat$.value === reportStats.HOURS_BY_COMPANY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="hoursByCompany.dataSets"
      [labels]="hoursByCompany.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.HOURS_BY_COMPANY_TITLE' | translate"
    ></app-stack-chart>
    <button 
    *ngIf="hoursByCompany && activeStat$.value === reportStats.HOURS_BY_COMPANY"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="hoursByUserAndMaintenance && activeStat$.value === reportStats.HOURS_BY_ASSIGNED_MAINTENANCE"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="hoursByUserAndMaintenance.dataSets"
      [labels]="hoursByUserAndMaintenance.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.HOURS_BY_ASSIGNED_MAINTENANCE_TITLE' | translate"
    ></app-stack-chart>
    <button 
    *ngIf="hoursByUserAndMaintenance && activeStat$.value === reportStats.HOURS_BY_ASSIGNED_MAINTENANCE"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="hoursByUserAndMaintenance && activeStat$.value === reportStats.HOURS_BY_FINISHED_MAINTENANCE"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="hoursByUserAndMaintenance.dataSets"
      [labels]="hoursByUserAndMaintenance.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      [title]="'REPORTS.HOURS_BY_FINISHED_MAINTENANCE_TITLE' | translate"
      chartType="horizontalBar"
    ></app-stack-chart>
    <button 
    *ngIf="hoursByUserAndMaintenance && activeStat$.value === reportStats.HOURS_BY_FINISHED_MAINTENANCE"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-column-chart
      *ngIf="avgScoreByCompany && activeStat$.value === reportStats.AVG_SCORE_BY_COMPANY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="avgScoreByCompany.dataSets"
      [labels]="avgScoreByCompany.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="false"
      [xAxesStacked]="false"
      [title]="'REPORTS.AVG_SCORE_BY_COMPANY_TITLE' | translate"
      chartType="horizontalBar"
    ></app-column-chart>
    <button *ngIf="avgScoreByCompany && activeStat$.value === reportStats.AVG_SCORE_BY_COMPANY"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="workByComponent && activeStat$.value === reportStats.WORK_BY_COMPONENT"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="workByComponent.dataSets"
      [labels]="workByComponent.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="false"
      [title]="'REPORTS.TASKS_BY_COMPONENT_TOP_TITLE' | translate"
      chartType="horizontalBar"
    ></app-stack-chart>

    <app-report-table
      *ngIf="activeStat$.value === reportStats.WORK_BY_COMPONENT" 
      class="wide column twelve"
      [tableParams]="tableDataParams"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [reportId]="reportStats.WORK_BY_COMPONENT"
      [title]="'REPORTS.TASKS_BY_COMPONENT_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      [totalItems]="tableDataTotalItems"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
    ></app-report-table>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="workBySpaces && activeStat$.value === reportStats.WORK_BY_SPACES"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="workBySpaces.dataSets"
      [labels]="workBySpaces.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.TASKS_BY_SPACES_TOP_TITLE' | translate"
    ></app-stack-chart>

  
    <app-report-table
      *ngIf="activeStat$.value === reportStats.WORK_BY_SPACES" 
      class="wide column twelve"
      [tableParams]="tableDataParams"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [reportId]="reportStats.WORK_BY_SPACES"
      [title]="'REPORTS.TASKS_BY_SPACES_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      [totalItems]="tableDataTotalItems"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
    ></app-report-table>

    <!--
      -------------------------------------------------------------------------
    -->

    <app-stack-chart
      *ngIf="costByCategory && activeStat$.value === reportStats.COST_BY_CATEGORY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="costByCategory.dataSets"
      [labels]="costByCategory.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.COST_BY_CATEGORY_TITLE' | translate"
    ></app-stack-chart>

    <app-report-table
      *ngIf="activeStat$.value === reportStats.COST_BY_CATEGORY" 
      class="wide column twelve"
      [tableParams]="tableDataParams"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [reportId]="reportStats.COST_BY_CATEGORY"
      [title]="'REPORTS.COST_BY_CATEGORY_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      [totalItems]="tableDataTotalItems"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
    ></app-report-table>
    

    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="costByCompany && activeStat$.value === reportStats.COST_BY_COMPANY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="costByCompany.dataSets"
      [labels]="costByCompany.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      [title]="'REPORTS.COST_BY_COMPANY_TITLE' | translate"
      chartType="horizontalBar"
    ></app-stack-chart>

    <app-report-table
      *ngIf="activeStat$.value === reportStats.COST_BY_COMPANY" 
      class="wide column twelve"
      [tableParams]="tableDataParams"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [reportId]="reportStats.COST_BY_COMPANY"
      [totalItems]="tableDataTotalItems"
      [title]="'REPORTS.COST_BY_COMPANY_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
    ></app-report-table>

    <!--
      -------------------------------------------------------------------------
    -->
    <app-column-chart
      *ngIf="costsWorkerHourByCompany && activeStat$.value === reportStats.COST_WORKER_HOUR_BY_COMPANY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="costsWorkerHourByCompany.dataSets"
      [labels]="costsWorkerHourByCompany.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="false"
      [xAxesStacked]="false"
      [title]="'REPORTS.COST_WORKER_HOUR_BY_COMPANY_TITLE' | translate"
      chartType="horizontalBar"
    ></app-column-chart>

    <app-report-table
      *ngIf="activeStat$.value === reportStats.COST_WORKER_HOUR_BY_COMPANY" class="wide column twelve"
      [tableParams]="tableDataParams"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [reportId]="reportStats.COST_WORKER_HOUR_BY_COMPANY"
      [title]="'REPORTS.COST_WORKER_HOUR_BY_COMPANY_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      [totalItems]="tableDataTotalItems"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
    ></app-report-table>
    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="costByProblemType && activeStat$.value === reportStats.COST_BY_PROBLEM_TYPE"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="costByProblemType.dataSets"
      [labels]="costByProblemType.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      chartType="horizontalBar"
      [title]="'REPORTS.COST_BY_PROBLEM_TYPE_TITLE' | translate"
    ></app-stack-chart>
  
    <app-report-table
      *ngIf="activeStat$.value === reportStats.COST_BY_PROBLEM_TYPE" class="wide column twelve"
      [tableParams]="tableDataParams"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [reportId]="reportStats.COST_BY_PROBLEM_TYPE"
      [totalItems]="tableDataTotalItems"
      [title]="'REPORTS.COST_BY_PROBLEM_TYPE_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
    ></app-report-table>

  
    <!--
      -------------------------------------------------------------------------
    -->
    <app-column-chart
      *ngIf="replacementCostByType && activeStat$.value === reportStats.REPLACEMENT_COST_BY_TYPE"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="replacementCostByType.dataSets"
      [labels]="replacementCostByType.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      [title]="'REPORTS.REPLACEMENT_COST_BY_TYPE_TITLE' | translate"
      chartType="horizontalBar"
    ></app-column-chart>

    <app-report-table
      *ngIf="activeStat$.value === reportStats.REPLACEMENT_COST_BY_TYPE" 
      class="wide column twelve"
      [tableParams]="tableDataParams"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [reportId]="reportStats.REPLACEMENT_COST_BY_TYPE"
      [title]="'REPORTS.REPLACEMENT_COST_BY_TYPE_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      [totalItems]="tableDataTotalItems"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
    ></app-report-table>
    
    <!--
      -------------------------------------------------------------------------
    -->
    <app-stack-chart
      *ngIf="costByFacility && activeStat$.value === reportStats.COST_BY_FACILITY"
      class="report-stat twelve wide column ui grid"
      [chartDataSets]="costByFacility.dataSets"
      [labels]="costByFacility.labels"
      [startDate]="(this.datesRange$ | async).start"
      [endDate]="(this.datesRange$ | async).end"
      [yAxesStacked]="true"
      [xAxesStacked]="true"
      [title]="'REPORTS.COST_BY_FACILITY_TITLE' | translate"
      chartType="horizontalBar"
    ></app-stack-chart>
    <button 
    *ngIf="costByFacility && activeStat$.value === reportStats.COST_BY_FACILITY"
    class="ui primary button margin-top-3 margin-bottom-3"
    (click)="downloadPDF()">{{'BUTTON.DOWNLOAD_PDF' | translate}}</button>

    <!--
      -------------------------------------------------------------------------
    -->
    
    <app-report-table
      *ngIf="activeStat$.value === reportStats.MATERIAL_COSTS_BREAKDOWN" class="wide column twelve"
      [tableDataProcessedDate1]="tableDataProcessedDate1"
      [tableDataProcessedDate2]="tableDataProcessedDate2"
      [tableDataGroupedItems]="tableDataGroupedItems"
      [mainKey]="tableDataMainKey"
      [totalItems]="tableDataTotalItems"
      (downloadCSVClicked)="downloadReportCSV()"
      (downloadPDFClicked)="downloadPDF()"
      [title]="'REPORTS.MATERIAL_COSTS_BREAKDOWN_TITLE' | translate"
      [activeComparative]="this.compareWithOtherRange.value"
      [reportId]="reportStats.MATERIAL_COSTS_BREAKDOWN"
    ></app-report-table>  

    <!--
      -------------------------------------------------------------------------
    -->

  </div>
</div>