import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import {
  UserGetPaginatedDtoInterface,
  UserInterface,
} from "../models/user.interface";
import { Observable } from "rxjs";
import { UserGroupInterface } from "../../user-groups/models/user-group.interface";
import { UtilsService } from "src/app/shared/services/utils.service";
import { Configuration } from "src/app/facilities/models/config.interface";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private readonly apiHost: string;

  constructor(private http: HttpClient, private utilsService: UtilsService) {
    this.apiHost = environment.apiHost;
  }

  fetchByFacilityPaginated(
    facilityId: number,
    page: number,
    elemsPerPage: number
  ): Observable<UserGetPaginatedDtoInterface> {
    return this.http.get<UserGetPaginatedDtoInterface>(
      `${this.apiHost}/users?facilityId=${facilityId}&page=${page}&limit=${elemsPerPage}`
    );
  }

  fetchByFacilityPaginatedSearch(
    facilityId: number,
    page: number,
    elemsPerPage: number,
    searchQuery: string
  ): Observable<UserGetPaginatedDtoInterface> {
    return this.http.get<UserGetPaginatedDtoInterface>(
      `${this.apiHost}/users?facilityId=${facilityId}&page=${page}&limit=${elemsPerPage}&search=${searchQuery}`
    );
  }

  fetchAll(queryParams?: {}): Observable<
    UserGetPaginatedDtoInterface | UserInterface[]
  > {
    let url = `${this.apiHost}/users`;
    if (queryParams) {
      const params = this.utilsService.parseParams(queryParams);
      url = url + `?${params.join("&")}`;
    }

    return this.http.get<UserGetPaginatedDtoInterface | UserInterface[]>(url);
  }

  fetchUser(userId: number, facilityId: number): Observable<UserInterface> {
    return this.http.get<UserInterface>(
      `${this.apiHost}/users/${userId}?facilityId=${facilityId}`
    );
  }

  fetchUserNotifications(): Observable<any> {
    return this.http.get<any>(`${this.apiHost}/config`);
  }

  updateUserNotifications(activeConfiguration: Configuration): Observable<any> {
    return this.http.post<any>(`${this.apiHost}/config`, activeConfiguration);
  }

  createUser(facilityId: number, userData: any): Observable<any> {
    return this.http.post(
      `${this.apiHost}/users?facilityId=${facilityId}`,
      userData
    );
  }

  updateUser(
    facilityId: number,
    userId: number,
    userData: any
  ): Observable<any> {
    return this.http.patch(
      `${this.apiHost}/users/${userId}?facilityId=${facilityId}`,
      userData
    );
  }

  fetchGroup(
    facilityId: number,
    groupId: number
  ): Observable<UserGroupInterface> {
    return this.http.get<UserGroupInterface>(
      `${this.apiHost}/facility-groups/${groupId}?facilityId=${facilityId}`
    );
  }

  fetchGroups(facilityId: number): Observable<UserGroupInterface[]> {
    return this.http.get<UserGroupInterface[]>(
      `${this.apiHost}/facility-groups?facilityId=${facilityId}`
    );
  }

  createGroup(
    facilityId: number,
    groupData: any
  ): Observable<UserGroupInterface> {
    return this.http.post<UserGroupInterface>(
      `${this.apiHost}/facility-groups?facilityId=${facilityId}`,
      groupData
    );
  }

  editGroup(
    facilityId: number,
    groupId: number,
    groupData: any
  ): Observable<UserGroupInterface> {
    return this.http.patch<UserGroupInterface>(
      `${this.apiHost}/facility-groups/${groupId}?facilityId=${facilityId}`,
      groupData
    );
  }
}
