import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Facility} from './models/facility.interface';
import {PaginatedResult} from '../shared/models/paginated-result.interface';
import {FacilityAddCompanyDto} from './models/dtos.interface';
import {FacilityComponentInterface, FacilityFloorInterface, FacilitySpaceInterface} from './models/facility-element.interface';
import {FacilityElementType, FacilityElementTypeDocument} from './models/facility-element-type.interface';
import { UtilsService } from '../shared/services/utils.service';
import { Router } from '@angular/router';
import { UrlParamsInterface } from '../shared/models/utils.interface';

@Injectable({
  providedIn: 'root'
})
export class FacilitiesService {

  private readonly apiHost;
  private readonly facilitiesUrl = '/facilities';
  private readonly floorsUrl = '/floors';
  private readonly spacesUrl = '/spaces';
  private readonly componentsUrl = '/components';


  constructor(
    private http: HttpClient, 
    private utilsService: UtilsService,
    private router: Router,
    ) {
    this.apiHost = environment.apiHost;
  }

  fetchFacilities(): Observable<Facility[]> {
    return this.http.get<Facility[]>(this.apiHost + this.facilitiesUrl);
  }

  fetchOne(facilityId: number): Observable<Facility> {
    return this.http.get<Facility>(`${this.apiHost}/facilities/${facilityId}`);
  }

  updateFacility(facilityId: number, data: any, queryParams?: {}): Observable<Facility> {
    const filteredParams = this.utilsService.filterNonNullValues(queryParams);
    const formattedParams = this.utilsService.parseParams(filteredParams);

    return this.http.patch<Facility>(`${this.apiHost}/facilities/${facilityId}?${formattedParams.join('&')}`, data);
  }
  
  getCurrencySymbol(facilityId: number): Observable<string> {
    return this.http.get<string>(`${this.apiHost}/facilities/${facilityId}/currency`);
  }

  fetchFacilityFloors(facilityId: number): Observable<FacilityFloorInterface[]> {
    return this.http.get<FacilityFloorInterface[]>(`${this.apiHost + this.facilitiesUrl}/${facilityId}/floors`);
  }

  fetchFloorSpaces(floorId: number): Observable<FacilitySpaceInterface[]> {
    return this.http.get<FacilitySpaceInterface[]>(`${this.apiHost + this.floorsUrl}/${floorId}/spaces`);
  }

  fetchSpaceComponents(spaceId: number): Observable<FacilityComponentInterface[]> {
    return this.http.get<FacilityComponentInterface[]>(`${this.apiHost + this.spacesUrl}/${spaceId}/components`);
  }

  fetchComponentByExternalIdentifier(identifier: string, facilityId: number): Observable<FacilityComponentInterface> {
    const url = `${this.apiHost + this.componentsUrl}?facilityId=${facilityId}&externalIdentifier=${identifier}`;
    return this.http.get<FacilityComponentInterface>(url);
  }

  fetchComponentByExternalIdentifierPromise(identifier: string, facilityId: number): Promise<FacilityComponentInterface> {
    const url = `${this.apiHost + this.componentsUrl}?facilityId=${facilityId}&externalIdentifier=${identifier}`;
    return new Promise(resolve => {
      this.http.get<FacilityComponentInterface>(url).subscribe(data => {
        resolve(data);
      });
    });
  }

  fetchComponentById(elementId: string, facilityId: number): Observable<FacilityComponentInterface> {
    const url = `${this.apiHost + this.componentsUrl}/${elementId}?facilityId=${facilityId}`;
    return this.http.get<FacilityComponentInterface>(url);
  }

  fetchCubeForSpace(identifier: string, facilityId: number): Observable<FacilityComponentInterface> {
    return this.http.get<FacilityComponentInterface>(`${this.apiHost + this.componentsUrl}/cubeForSpace/${identifier}?facilityId=${facilityId}`);
  } 

  // Complex filter with "inclusive" and "exclusive" filter groups
  fetchComponentsByFilter(
    filter: {},
    urlParams: UrlParamsInterface)
    : Observable<FacilityComponentInterface[] | PaginatedResult<FacilityComponentInterface>> {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost + this.componentsUrl}/filter?${params.join('&')}`;
    return this.http.post<FacilityComponentInterface[] | PaginatedResult<FacilityComponentInterface>>(url, filter);
  }

  // Simple filter for page list
  fetchComponentsFiltered(
    filter: {},
    urlParams: UrlParamsInterface)
    : Observable<FacilityComponentInterface[] | PaginatedResult<FacilityComponentInterface>> {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost + this.componentsUrl}/filtered?${params.join('&')}`;
    return this.http.post<FacilityComponentInterface[] | PaginatedResult<FacilityComponentInterface>>(url, filter);
  }

  /**
   * Use for quick searching of components' names
   * @param urlParams facilityIds
   * @returns id and name of the components
   */
  fetchComponentsNames(
    urlParams: UrlParamsInterface)
    : Observable<FacilityComponentInterface[] | PaginatedResult<FacilityComponentInterface>> {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost + this.componentsUrl}/names?${params.join('&')}`;
    return this.http.get<FacilityComponentInterface[] | PaginatedResult<FacilityComponentInterface>>(url, {});
  }

  downloadCSVComponentsFiltered(
    filter: {},
    urlParams: UrlParamsInterface) {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost + this.componentsUrl}/filtered/csv?${params.join('&')}`;
    return this.http.post(url, filter, {responseType: 'text'});
  }

  updateComponent(id: number, data: any, urlParams: UrlParamsInterface): Observable<FacilityComponentInterface> {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost + this.componentsUrl}/${id}?${params.join('&')}`;

    return this.http.patch<FacilityComponentInterface>(url, data);
  }

  uploadComponentsCSV(file, urlParams: UrlParamsInterface) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const params = this.utilsService.parseParams(urlParams);

    const url = `${this.apiHost + this.componentsUrl}/csv?${params.join('&')}`;
    return this.http.patch(url, formData);
  }

  addCompany(facilityId: number, data: FacilityAddCompanyDto): Observable<Facility> {
    return this.http.put<Facility>(`${this.apiHost + this.facilitiesUrl}/${facilityId}/company`, data);
  }

  searchPropertyValues(
    property: string,
    urlParams: UrlParamsInterface,
    filter: any)
  : Observable<string[]>{
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost + this.componentsUrl}/extended-search?returnProperty=${property}&${params.join('&')}`;
    return this.http.post<string[]>(url, filter);
  }

  fetchComponentTypes(urlParams: UrlParamsInterface): Observable<FacilityElementType[] | PaginatedResult<FacilityElementType>> {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost}/component-types?${params.join('&')}`;
    return this.http.get<FacilityElementType[] | PaginatedResult<FacilityElementType>>(url);
  }

  downloadCSVComponentTypes(urlParams: UrlParamsInterface) {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost}/component-types/csv?${params.join('&')}`;
    return this.http.get(url, {responseType: 'text'});
  }

  uploadComponentTypesCSV(file, urlParams: UrlParamsInterface) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    const params = this.utilsService.parseParams(urlParams);

    const url = `${this.apiHost}/component-types/csv?${params.join('&')}`;
    return this.http.patch(url, formData);
  }

  fetchOneComponentType(id: number, urlParams: UrlParamsInterface): Observable<FacilityElementType> {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost}/component-types/${id}?${params.join('&')}`;
    return this.http.get<FacilityElementType>(url);
  }

  editComponentType(id: number, urlParams: UrlParamsInterface, data: any): Observable<FacilityElementType> {
    const params = this.utilsService.parseParams(urlParams);
    const url = `${this.apiHost}/component-types/${id}?${params.join('&')}`;
    return this.http.patch<FacilityElementType>(url, data);
  }

  uploadProblemTypeFile(componentId, file, urlParams: UrlParamsInterface): Observable<FacilityElementTypeDocument> {
    const formData = new FormData();
    formData.append('document', file, file.name);
    const params = this.utilsService.parseParams(urlParams);

    const url = `${this.apiHost}/component-types/${componentId}/document?${params.join('&')}`;
    return this.http.post<FacilityElementTypeDocument>(url, formData);
  }

  deleteProblemTypeFile(componentId: number, document: FacilityElementTypeDocument, urlParams: UrlParamsInterface) {
    const params = this.utilsService.parseParams(urlParams);

    const url = `${this.apiHost}/component-types/${componentId}/document/${document.identifier}?${params.join('&')}`;
    return this.http.delete(url);
  }

  showComponentInViewer(componentId: string, facilityId: number){
    this.fetchComponentById(componentId, facilityId).subscribe(component => {
      this.router.navigate(['visualizador'], {state: {component}});
    })
  }

  goToComponentInfo(componentId: string) {
    const url = this.router.createUrlTree(['componentes', componentId]);
    window.open(url.toString(), '_blank');
  }
}
