import { UserInterface } from '../../users/models/user.interface';
import { FacilityComponentInterface } from './../../facilities/models/facility-element.interface';
import { IncidenceInterface, INCIDENCE_STATES, INCIDENCE_TYPES } from './incidence.interface';

export class Incidence implements IncidenceInterface {

  id?: number;
  typeId?: INCIDENCE_TYPES;
  statusId: INCIDENCE_STATES;
  topic: string;
  description?: string;
  photos: string[];
  components: {
    id: number,
    createdAt: Date,
    creatorId: number,
    incidenceId: number,
    componentId:  number,
    component: FacilityComponentInterface
  }[];

  reviewScore: number;
  reviewDescription: string;
  reviewedAt?: string;

  openedAt?: string;
  openerId?: string;

  assignedAt?: string;

  finishedAt?: string;
  finisherId?: string;

  closedAt?: string;
  closerId?: number;

  createdAt: string;
  creatorId: number;
  creator: UserInterface;

  updatedAt?: string;
  updatorId?: number;

  constructor(props) {
    this.id = props.id || null;
    this.typeId = props.typeId || null;
    this.statusId = props.statusId || INCIDENCE_STATES.CREATED;
    this.topic = props.topic;
    this.description = props.description || null;
    this.photos = props.photos || [];
    this.components = props.components || [];
    this.reviewScore = props.reviewScore || null;
    this.reviewDescription = props.reviewDescription || null;

    this.reviewedAt = props.reviewedAt || null;

    this.openedAt = props.openedAt || null;
    this.openerId = props.openerId || null;

    this.assignedAt = props.assignedAt || null;

    this.finishedAt = props.finishedAt || null;
    this.finisherId = props.finisherId || null;

    this.closedAt = props.closedAt || null;
    this.closerId = props.closerId || null;

    this.createdAt = props.createdAt;
    this.creatorId = props.creatorId;
    this.creator = props.creator;

    this.updatedAt = props.updatedAt || null;
    this.updatorId = props.updatorId || null;
  }

  isStateCompleted(state: INCIDENCE_STATES) {
    return this.statusId >= state;
  }

  isCreated(): boolean {
    return this.statusId === INCIDENCE_STATES.CREATED;
  }

  isOpen(): boolean {
    return this.statusId === INCIDENCE_STATES.OPENED;
  }

  isAssigned(): boolean {
    return this.statusId === INCIDENCE_STATES.ASSIGNED;
  }

  isFinished(): boolean {
    return this.statusId === INCIDENCE_STATES.FINISHED;
  }

  isClosed(): boolean {
    return this.statusId === INCIDENCE_STATES.CLOSED;
  }

  wasOpened() {
    return !!this.openedAt;
  }

  wasAssigned() {
    return !!this.assignedAt;
  }

  wasFinished() {
    return !!this.finishedAt;
  }

  wasClosed() {
    return this.statusId === INCIDENCE_STATES.CLOSED;
  }

  wasRated() {
    return !!this.reviewedAt;
  }
}

