<div class="ui mini five steps">

  <div class="completed step" [class.active]="workOrder?.isWaiting()">
    <i class="time icon"></i>
    <div class="content">
      <div class="title">{{'WORK_ORDER_STATES.WAITING' | translate}}</div>
      <div class="description">
        {{formatDate(workOrder?.createdAt)}} <br>
        {{formatHour(workOrder?.createdAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="workOrder?.wasBlocked()" [class.active]="workOrder?.isBlocked()">
    <i class="lock icon"></i>
    <div class="content">
      <div class="title">{{'WORK_ORDER_STATES.BLOCKED' | translate}}</div>
      <div *ngIf="workOrder?.wasBlocked()" class="description">
        {{formatDate(workOrder?.blockedAt)}} <br>
        {{formatHour(workOrder?.blockedAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="workOrder?.wasInProgress()" [class.active]="workOrder?.isInProgress()">
    <i class="running icon"></i>
    <div class="content">
      <div class="title">{{'WORK_ORDER_STATES.IN_PROGRESS' | translate}}</div>
      <div *ngIf="workOrder?.wasInProgress()" class="description">
        {{formatDate(workOrder.startedAt)}} <br>
        {{formatHour(workOrder.startedAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="workOrder?.wasFinished()" [class.active]="workOrder?.isFinished()">
    <i class="tools icon"></i>
    <div class="content">
      <div class="title">{{'WORK_ORDER_STATES.FINISHED' | translate}}</div>
      <div *ngIf="workOrder?.wasFinished()" class="description">
        {{formatDate(workOrder.finishedAt)}} <br>
        {{formatHour(workOrder.finishedAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="workOrder?.isClosed()" [class.active]="workOrder?.isClosed()">
    <i class="lock icon"></i>
    <div class="content">
      <div class="title">{{'WORK_ORDER_STATES.CLOSED' | translate}}</div>
      <div *ngIf="workOrder?.isClosed()" class="description">
        {{formatDate(workOrder.closedAt)}} <br>
        {{formatHour(workOrder.closedAt)}}
      </div>
    </div>
  </div>
</div>
