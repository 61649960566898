import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {skipWhile, take} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fui-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarComponent),
      multi: true
    }
  ]
})
export class CalendarComponent implements OnInit, ControlValueAccessor {

  constructor(private translate: TranslateService) { }

  @Input() selectorId: string;

  private LITERALS : any;

  initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() disabled = false;

  ngOnInit(): void {
    this.translate.get('CALENDAR').subscribe(translations => {
      this.LITERALS = {
        days: translations.DAYS,
        months: translations.MONTHS,
        monthsShort: translations.MONTHS_SHORT,
        today: translations.TODAY,
        now: translations.NOW
      };
      setTimeout(() => {
        this.initialize();
      }, 200);
    });
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.setDate(obj);
  }

  initialize() {
    const calendar = $('#'+this.selectorId) as any;

    calendar.calendar({
      type: 'date',
      text: this.LITERALS,
      monthFirst: false,
      firstDayOfWeek: 1,
      onSelect: this.onChange.bind(this)
    });

    this.initialized.next(true);
  }

  setDate(date: Date, updateInput = true, fireChange = true) {
    this.initialized.pipe(
      skipWhile(initialized => !initialized),
      take(1)
    ).subscribe(() => {
      const calendar = $('#' + this.selectorId) as any;
      calendar.calendar('set date', date, updateInput, fireChange);
    });
  }
}
