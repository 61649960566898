import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {AuthModule} from './auth/auth.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SessionService} from './shared/services/session.service';
import {UserService} from './users/services/user.service';
import {ProblemTypesService} from './configuration/services/problem-types.service';
import {FacilitiesModule} from './facilities/facilities.module';
import {WorkordersModule} from './workorders/workorders.module';
import {ScheduledTasksModule} from './scheduled-tasks/scheduled-tasks.module';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import {CompaniesModule} from './companies/companies.module';
import {IncidencesModule} from './incidences/incidences.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {UsersModule} from './users/users.module';
import {ViewerModule} from './viewer/viewer.module';
import {UserGroupsModule} from './user-groups/user-groups.module';
import {MessagesModule} from './messages/messages.module';
import {ConfigurationModule} from './configuration/configuration.module';
import {ReportsModule} from './reports/reports.module';
import { TreeViewModule } from './treeview/tree-view.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './shared/services/language.service';
registerLocaleData(localeEs);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Function to initialize language service before app starts
export function languageInitializerFactory(languageService: LanguageService) {
  return () => languageService.initializeLanguage();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AuthModule,

    DashboardModule,
    UsersModule,
    UserGroupsModule,
    ViewerModule,

    FacilitiesModule,
    IncidencesModule,
    WorkordersModule,
    ScheduledTasksModule,
    CompaniesModule,
    MessagesModule,
    ConfigurationModule,
    ReportsModule,
    TreeViewModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    SessionService,
    UserService,
    ProblemTypesService,
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: languageInitializerFactory,
      deps: [LanguageService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
