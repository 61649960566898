import {Component, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { SessionService } from 'src/app/shared/services/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-element-type-form',
  templateUrl: './element-type-form.component.html',
  styleUrls: ['./element-type-form.component.scss']
})
export class ElementTypeFormComponent implements OnInit, OnDestroy {

  @Input() form: FormGroup;

  @HostBinding('class') hostClass = 'ui form';

  currencySymbol: string = 'EUR';
  private activeFacilitySubscription: Subscription;

  constructor(
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.activeFacilitySubscription = this.sessionService.activeFacility$.subscribe((activeFacility) => {
      if (activeFacility) {
        const currencySymbol = activeFacility.currencyUnit || 'EUR';
        this.currencySymbol = currencySymbol;
      }
    });
  }

  ngOnDestroy(): void {
    this.activeFacilitySubscription.unsubscribe();
  }

}
