<span class="selected-option" [class.background]="focused" [class.hidden]="searchForm.value.length">{{selectedLabel}}</span>
<input
  #input
  class="search-input"
  [formControl]="searchForm"
  type="text"
  autocomplete="off"
  [size]="searchForm.value.length + 1"
  (focus)="inputFocused()"
  (blur)="inputBlured()"
/>
