
/**
 * Customise the 3D toolbar hidens some buttons
 * to show only necesary buttons to cleaner presentation. 
 * @param viewer is the references of the viewer object
 */
  export function hideToolsFromToolBar(viewer): void{
    //if (environment.production){
      viewer.toolbar.getControl('settingsTools').removeControl('toolbar-propertiesTool');
      viewer.toolbar.getControl('settingsTools').removeControl('toolbar-settingsTool');
      viewer.toolbar.getControl('navTools').removeControl('toolbar-cameraSubmenuTool');
      viewer.toolbar.getControl('modelTools').removeControl('toolbar-explodeTool');
    //}
  }
