import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ITableParams, ReportTableDataReg} from '../../models/reports.inteface';
import { ROLES } from 'src/app/shared/models/role.interface';
import { filter, take } from 'rxjs/operators';
import { SessionService } from 'src/app/shared/services/session.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.scss']
})
export class ReportTableComponent implements OnInit, OnChanges {

  @Input() tableDataProcessedDate1: ReportTableDataReg[] = [];
  @Input() tableDataProcessedDate2: ReportTableDataReg[] = [];
  @Input() tableDataGroupedItems: any[];
  @Input() mainKey: string;
  @Input() totalItems = 0;
  @Input() title: string;
  @Input() reportId: string;
  @Input() activeComparative: boolean;

  page = 1;
  itemsPerPage = 10;
  showInfoCosts: boolean= false;
  showedItems: ReportTableDataReg[];
  userRole: ROLES;
  @Output() downloadCSVClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() downloadPDFClicked: EventEmitter<null> = new EventEmitter<null>();

  @Input()
  tableParams : ITableParams[] ; 
  currencySymbol: string = 'EUR';
  private activeFacilitySubscription: Subscription;

  constructor(
    public sessionService: SessionService,
    private translate: TranslateService,
  ) {}

  translateLabels() {
    this.translate.get('REPORTS').subscribe(translations => {
    this.tableParams = [
      {
        name: translations.CATEGORY,
        key: 'categoryName'
      },
      {
        name: translations.FACILITY,
        key: 'facilityName'
      },
      {
        name: translations.CLOSED_AT,
        key: 'closedAt',
        type: 'date'
      },
      {
        name: translations.COMPANY,
        key: 'companyName'
      },
      {
        name: translations.SUBCATEGORY,
        key: 'subcategoryName'
      },
      {
        name: translations.MATERIAL,
        key: 'materialName'
      },
      {
        name: translations.ORDER_ID,
        key: 'orderId',
        type: 'number'
      },
      {
        name: translations.QUANTITY,
        key: 'quantity',
        type: 'number'
      },
      {
        name: translations.UNIT_COST,
        key: 'unitCost',
        type: 'currency'
      },
      {
        name: translations.TIME,
        key: 'workingTime',
        type: 'number'
      },
      {
        name: translations.HOUR_COST,
        key: 'timeCost',
        type: 'currency'
      },
    ];
    });
  }

  ngOnInit(): void {
    this.translateLabels();

    this.sessionService.userRole$
      .pipe(filter(role => role !== null && role !== undefined), take(1))
      .subscribe(role => this.userRole = role);

    if (this.userRole === ROLES.GLOBAL_SERVICE || this.userRole === ROLES.MAINTENANCE_USER){
      this.showInfoCosts = true;
    }
    this.activeFacilitySubscription = this.sessionService.activeFacility$.subscribe((activeFacility) => {
      if (activeFacility) {
        const currencySymbol = activeFacility.currencyUnit || 'EUR';
        this.currencySymbol = currencySymbol;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableDataGroupedItems) {
      this.page = 1;
      this.showedItems = this.itemsOfPage(this.page);
    }
  }

  numOfPages() {
    let sumPage = 1;
    if (this.tableDataGroupedItems && this.tableDataGroupedItems.length) {
      sumPage = (this.tableDataGroupedItems.length % this.itemsPerPage === 0) ? 0 : 1;
    }
    
    return this.tableDataGroupedItems?.length > 0 ?
      Math.floor(this.tableDataGroupedItems.length / this.itemsPerPage) + sumPage :
      0;
  }

  firstIndexOfPage(page: number) {
    return (page - 1) * this.itemsPerPage;
  }

  itemsOfPage(page: number) {
    if (page <= this.numOfPages()) {
      return this.tableDataGroupedItems.slice(this.firstIndexOfPage(page), this.firstIndexOfPage(page) + this.itemsPerPage);
    }
    return [];
  }

  selectPage(page: number) {
    this.page = page;
    this.showedItems = this.itemsOfPage(this.page);
  }

  getTitle():string{
    return this.title;
  }
}
