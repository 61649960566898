import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IShowToastRequest} from '../models/show-toast-request.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private showToastRequestsSubject: Subject<IShowToastRequest> = new Subject<IShowToastRequest>();
  public showToastRequests$: Observable<IShowToastRequest> = this.showToastRequestsSubject.asObservable();
  private hideToastRequestsSubject: Subject<boolean> = new Subject<boolean>();
  public hideToastRequests$: Observable<boolean> = this.hideToastRequestsSubject.asObservable();

  constructor(private translate: TranslateService) { }

  showToast(toastData: IShowToastRequest) {
    
    if(toastData.message.includes("No metadata for"))
      toastData.message = this.translate.instant("SERVER_STANDBY")
    
    if (!toastData.duration) 
      toastData.duration = 6000; //default value

    this.showToastRequestsSubject.next(toastData);
  }

  hideToast() {
    this.hideToastRequestsSubject.next(true);
  }
}
