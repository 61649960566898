
import { Component, Input, } from '@angular/core';
import { FacilitiesService } from 'src/app/facilities/facilities.service';
import { FacilityComponentInterface, FacilityFloorInterface, FacilitySpaceInterface } from 'src/app/facilities/models/facility-element.interface';
import { Facility } from 'src/app/facilities/models/facility.interface';

@Component({
    selector: 'app-departmental-tree',
    templateUrl: './departmental-tree.component.html',
    styleUrls: ['./departmental-tree.component.scss']
  })
export class DepartmentalTreeComponent {
  @Input() facilities: Facility[] = [];
  floors : FacilityFloorInterface[];

  facilityFloorsMap: { [facilityId: number]: FacilityFloorInterface[] } = {};
  facilityFloorsVisible: { [facilityId: number]: boolean } = {};

  floorSpacesMap: { [floorId: number]: FacilitySpaceInterface[] } = {};
  floorSpacesVisible: { [floorId: number]: boolean } = {};

  spaceComponentsMap: { [floorId: number]: FacilityComponentInterface[] } = {};
  spaceComponentsVisible: { [floorId: number]: boolean } = {};

  constructor(
    private facilitiesService: FacilitiesService,
    ) {}

  loadFloors(facilityId: number) {
    if (!this.areFloorsFeched(facilityId)) {
      this.fetchFloors(facilityId);
      this.clearAllFloorSpacesVisibility();
      this.facilityFloorsVisible[facilityId] = true;
    } else {
      this.toggleFacilityFloorsVisibility(facilityId);
    }
  }

  loadSpaces(floorId: number) {
    if (!this.areSpacesFeched(floorId)) {
      this.fetchSpaces(floorId);
      this.clearAllSpaceComponentsVisibility();
      this.floorSpacesVisible[floorId] = true;
    } else {
      this.toggleFloorSpacesVisibility(floorId);
    }
  }

  loadComponents(spaceId: number) {
    if (!this.areComponentsFeched(spaceId)) {
      this.fetchComponents(spaceId);
      this.spaceComponentsVisible[spaceId] = true;
    } else {
      this.toggleSpaceComponentsVisibility(spaceId);
    }
  }
  areFloorsFeched(facilityId: number){
    return this.facilityFloorsMap[facilityId];
  }

  areSpacesFeched(floorId: number){
    return this.floorSpacesMap[floorId];
  }

  areComponentsFeched(spaceId: number){
    return this.spaceComponentsMap[spaceId];
  }
  fetchFloors(facilityId: number){
    this.facilitiesService.fetchFacilityFloors(facilityId).subscribe(floors => {
      this.facilityFloorsMap[facilityId] = floors; 
    });
  }
  fetchSpaces(floorId: number){
    this.facilitiesService.fetchFloorSpaces(floorId).subscribe(spaces => {
      this.floorSpacesMap[floorId] = spaces;
    });
  }
  fetchComponents(spaceId: number){
    this.facilitiesService.fetchSpaceComponents(spaceId).subscribe(components => {
      this.spaceComponentsMap[spaceId] = components;
    });
  }

  private toggleSpaceComponentsVisibility(spaceId: number) {
    this.spaceComponentsVisible[spaceId] = !this.spaceComponentsVisible[spaceId];
  }

  private toggleFloorSpacesVisibility(floorId: number) {
    this.floorSpacesVisible[floorId] = !this.floorSpacesVisible[floorId];
  }

  private toggleFacilityFloorsVisibility(facilityId: number) {
    this.facilityFloorsVisible[facilityId] = !this.facilityFloorsVisible[facilityId];
  }

  private clearAllFloorSpacesVisibility() {
    for (const floorId in this.floorSpacesVisible) {
      if (this.floorSpacesVisible.hasOwnProperty(floorId)) {
        this.floorSpacesVisible[floorId] = false;
      }
    }
  }
  clearAllSpaceComponentsVisibility(){
    for (const spaceId in this.spaceComponentsVisible){
      if (this.spaceComponentsVisible.hasOwnProperty(spaceId)){
        this.spaceComponentsVisible[spaceId] = false;
      }
    }
  }
}


