<div class="two fields">
  <div class="field">
    <label>{{fromLabel || 'CALENDAR.FROM_LABEL' | translate}}</label>
    <div class="ui calendar" [attr.id]="rangeStartId()">
      <div class="ui input left icon">
        <i class="calendar icon"></i>
        <input type="text" placeholder="{{'CALENDAR.START_PLACEHOLDER' | translate}}">
      </div>
    </div>
  </div>

  <div class="field">
    <label>{{toLabel || 'CALENDAR.TO_LABEL' | translate}}</label>
    <div class="ui calendar" [attr.id]="rangeEndId()">
      <div class="ui input left icon">
        <i class="calendar icon"></i>
        <input type="text" placeholder="{{'CALENDAR.END_PLACEHOLDER' | translate}}">
      </div>
    </div>
  </div>
</div>
