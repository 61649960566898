<div
  class="sixteen wide column"
  style="display: flex; justify-content: flex-start"
>
  <button
    *ngIf="shouldShowDeleteButton()"
    (click)="clickOnDeleteTask()"
    [class.disabled]="task?.finishedAt"
    class="ui labeled icon red button"
  >
    <i class="trash icon"></i>
    {{ 'BUTTON.DELETE_TASK' | translate }}
  </button>

  <div
    *ngIf="shouldShowSaveButton()"
    class="ui primary button"
    style="margin-left: 10px"
    [class.disabled]="!canEditTask"
    (click)="clickOnEditTask()"
  >
    {{ 'BUTTON.SAVE' | translate }}
  </div>

  <div
    class="ui button"
    style="margin-left: 10px"
    (click)="taskDetail.downloadPDF()"
  >
  <i class="download icon"></i>
    {{ 'BUTTON.DOWNLOAD_PDF' | translate }}
  </div>
</div>

<div class="ui hidden divider"></div>

<app-scheduled-task-info-form
  [form]="infoForm"
  [categories]="categories"
  [companies]="companies"
  [users]="users"
  [userRole]="userRole"
  [readonlyFields]="readonlyFields"
></app-scheduled-task-info-form>

<ng-container *ngIf="shouldShowFinishTaskForm()">
  <h5 class="ui header">{{ 'SCHEDULED_TASK_DETAIL.TASK_EXECUTION' | translate }}</h5>

  <div class="ui form" [formGroup]="finishTaskForm">

    <div class="sixteen wide field">
      <label for="comment">{{ 'SCHEDULED_TASK_DETAIL.COMMENT' | translate }}</label>
      <div class="fields">
        <textarea
          name="comment"
          id="comment"
          type="text"
          formControlName="comment"
          cols="30" rows="6"
          [readonly]="task?.finishedAt || !canFinishTask()"
        ></textarea>
      </div>
    </div>

    <div class="sixteen wide field">
      <label>{{ 'SCHEDULED_TASK_DETAIL.VALIDATION_EMAIL' | translate }}</label>
      <div class="fields">
        <div class="eight wide field">
        <fui-dropdown-two
          [readonly]="task?.finishedAt || !canFinishTask()"
          selectorId="validationEmailSel"
          [options]="validators"
          [search]="true"
          valueProperty="id"
          nameProperty="email"
          placeholder="ejemplo@imbicloud.com"
          formControlName="email"
        ></fui-dropdown-two>
        </div>
      </div>
    </div>
    <div class="sixteen wide field">
      <label>{{ 'SCHEDULED_TASK_DETAIL.EXECUTION_TIME' | translate }}</label>
      <div class="fields">
        <div class="four wide field">
          <div class="ui right labeled input">
            <input
              formControlName="hour"
              type="text"
              [readOnly]="task?.finishedAt || !canFinishTask()"
              [class.empty-input]="!finishTaskForm.controls.hour.value"
            />
            <div class="ui label">{{ 'SCHEDULED_TASK_DETAIL.HOURS' | translate }}</div>
          </div>
        </div>

        <div class="four wide field">
          <div class="ui right labeled input">
            <input
              formControlName="mins"
              type="text"
              [readOnly]="task?.finishedAt || !canFinishTask()"
              [class.empty-input]="!finishTaskForm.controls.mins.value"
            />
            <div class="ui label">{{ 'SCHEDULED_TASK_DETAIL.MINUTES' | translate }}</div>
          </div>
        </div>
        <div class="six wide field">
          <div
            *ngIf="!task?.finishedAt && canFinishTask()"
            class="two wide field"
          >
            <button
              class="ui button"
              [class.disabled]="!canValidateTask()"
              (click)="validateTaskClicked()"
            >
              {{ 'BUTTON.VALIDATE' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="finishTaskForm.value.date" class="six wide field">
        <label>{{ 'SCHEDULED_TASK_DETAIL.REVIEW_DATE' | translate }}</label>
        <input formControlName="date" type="text" readonly />
      </div>
    </div>
  </div>
</ng-container>
