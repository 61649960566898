<h2 class="ui header">{{ 'SETTINGS.PROBLEM_TYPES' | translate }}</h2>

<app-problem-types-paginated-table
  [categories]="categories"
  [problemTypes]="problemTypes"
  [page]="page | async"
  [totalPages]="totalPages"
  [totalItems]="totalItems"
  (selectedPage)="page.next($event)"
  (selectedNumItems)="numItems.next($event)"
  (selectedCategory)="filterCategory.next($event)"
  (deleted)="deleteProblem($event)"
>
</app-problem-types-paginated-table>

<h3 class="ui header">{{ 'SETTINGS.CREATE_NEW_PROBLEM_TYPES' | translate }}</h3>

<form class="ui form" [formGroup]="newProblemForm">
  <div class="fields">
    <div class="six wide field">
      <label>{{ 'SETTINGS.CATEGORY' | translate }}</label>
      <fui-dropdown-two
        selectorId="categorySel"
        [options]="categories"
        [search]="true"
        valueProperty="id"
        formControlName="category"
      ></fui-dropdown-two>
    </div>
    <div class="ten wide field">
      <label>{{ 'SETTINGS.PROBLEM_TYPE' | translate }}</label>
      <input type="text" name="text" placeholder="{{ 'SETTINGS.PROBLEM_TYPE_PLACEHOLDER' | translate }}"
             formControlName="text"
      >
    </div>
  </div>
  <div class="field" style="display: flex; justify-content: space-between">
    <div class="ui button" [class.loading]="loadingFile" (click)="fileInput.click()">{{ 'SETTINGS.UPLOAD_FROM_FILE' | translate }}</div>
    <input hidden #fileInput type="file" (change)="selectedFile()">
    <div
      class="ui primary button"
      [class.disabled]="!newProblemForm.valid"
      (click)="createNewProblemType()"
    >{{ 'SETTINGS.CREATE' | translate }}
    </div>
  </div>
</form>

<pui-modal *ngIf="showErrorsModal" title="{{ 'SETTINGS.IMPORT_ERRORS_TITLE' | translate }}">
  <div class="upload-errors">
    <ul>
      <li *ngFor="let error of uploadErrors">
        {{ 'SETTINGS.IMPORT_ERRORS_ROW' | translate }}: {{error.row}} - {{error.message}}
      </li>
    </ul>
  </div>
  <div class="upload-errors--footer" footer>
    <div class="ui primary button" (click)="this.showErrorsModal = false">{{ 'SETTINGS.ACCEPT' | translate }}</div>
  </div>
</pui-modal>
