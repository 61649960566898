import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputComponent} from './components/input/input.component';
import {SpinnerModule} from '../spinner/spinner.module';
import { FieldComponent } from './components/field/field.component';
import { TextAreaComponent } from './components/text-area/text-area.component';


@NgModule({
  declarations: [
    FieldComponent,
    InputComponent,
    TextAreaComponent,
  ],
  imports: [
    CommonModule,
    SpinnerModule
  ],
  exports: [
    FieldComponent,
    InputComponent,
    TextAreaComponent
  ]
})
export class FormModule {
}
