  <div class="ui segment">
      <div *ngFor="let condition of parentForm.controls; let i = index" class="ui form">
        <app-condition-form
          [conditionId]="filterId + i"
          [parentForm]="condition | asFormGroup"
          [unusedParams]="unusedParams$ | async"
          [groupFilter]="filter"
          [showDeleteButton]="parentForm.controls.length > 1"
          (deleteClick)="deleteCondition(i)"
        ></app-condition-form>
      </div>
    <div class="field" *ngIf="(unusedParams$ | async).length">
      <div class="ui icon button" [class.disabled]="!parentForm.valid" (click)="addNewCondition()">
        <i class="icon add"></i>
      </div>
    </div>
  </div>
