  <div class="sixteen wide column">
    <h2 class="ui header">{{'NEW_WORK_ORDER' | translate}}</h2>
    <app-work-order-info-form
      [parentForm]="form"
      [categories]="categories"
      [companies]="companies"
      [users]="users"
      [problemTypes]="problemTypes"
      [criticalityLevels]="criticalityLevels"
      [incidenceWorkOrders]="detailIncidence.workOrders$ | async"
      [userRole]="session.userRole$ | async"
    ></app-work-order-info-form>
    <div class="ui hidden divider"></div>
    <div class="ui right floated primary button" [class.disabled]="!form.valid" (click)="createWorkOrder()">
      {{'CREATE' | translate}}
    </div>
  </div>

