import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LayoutComponent} from '../shared/layout/layout.component';
import {AuthGuardService} from '../auth/auth-guard.service';
import {CompaniesComponent} from './pages/companies/companies.component';
import {AddCompanyComponent} from './pages/add-company/add-company.component';
import {DetailCompanyComponent} from './pages/detail-company/detail-company.component';

const routes = [
  {
    path: 'empresas',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: CompaniesComponent
      },
      {
        path: 'nueva',
        component: AddCompanyComponent
      },
      {
        path: ':companyId',
        component: DetailCompanyComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompaniesRoutingModule {}
