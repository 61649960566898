import {Component, HostBinding, OnInit} from '@angular/core';
import {ChatMessageInterface} from '../../../../shared/models/chat-message.interface';
import {DetailWorkOrderService} from '../detail-work-order.service';
import {SessionService} from '../../../../shared/services/session.service';
import {IncidencesService} from '../../../../incidences/incidences.service';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {skipWhile, switchMap, take} from 'rxjs/operators';
import {WorkOrder} from '../../../models/work-order.model';

@Component({
  selector: 'app-detail-work-order-chat',
  templateUrl: './detail-work-order-chat.component.html',
  styleUrls: ['./detail-work-order-chat.component.scss']
})
export class DetailWorkOrderChatComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid';

  workOrder$: BehaviorSubject<WorkOrder>;
  chatMessages: ChatMessageInterface[];

  constructor(
    public detailWorkOrder: DetailWorkOrderService,
    private session: SessionService,
    private incidencesSrv: IncidencesService
  ) {
    this.workOrder$ = detailWorkOrder.workOrder$;
  }

  ngOnInit(): void {
    combineLatest([
      this.detailWorkOrder.workOrder$.pipe(skipWhile(order => !order)),
      this.session.activeFacility$.pipe(skipWhile(facility => !facility))
    ]).pipe(
      switchMap(([order, facility]) => {
        return this.incidencesSrv.fetchChatMessages(order.incidenceId, facility.id);
      }),
      take(1)
    ).subscribe(messages => this.chatMessages = messages);
  }

  saveChatMessage(message: string) {
    combineLatest([
      this.workOrder$.pipe(skipWhile(order => !order)),
      this.session.activeFacility$.pipe(skipWhile(facility => !facility))
    ]).pipe(
      take(1),
      switchMap(([order, facility]) => {
        return this.incidencesSrv.createChatMessage(order.incidenceId, facility.id, message, order.id);
      })
    ).subscribe(newMessage => {
      this.chatMessages = [...this.chatMessages, newMessage];
    });
  }

}
