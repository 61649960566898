import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {UserInterface} from '../../../users/models/user.interface';
import {UserService} from '../../../users/services/user.service';
import {SessionService} from '../../../shared/services/session.service';
import {Subscription} from 'rxjs';
import {IUserLogged} from '../../../shared/models/i-user-logged';
import {Router} from '@angular/router';
import {Facility} from '../../../facilities/models/facility.interface';

@Component({
  selector: 'app-add-user-group-page',
  templateUrl: './add-user-group-page.component.html',
  styleUrls: ['./add-user-group-page.component.scss']
})
export class AddUserGroupPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui grid';


  loggedUser: IUserLogged;
  loggerUserSubs: Subscription;
  users: UserInterface[];

  activeFacility: Facility;

  activeFacilitySubs: Subscription;

  constructor(private router: Router, private usersSrv: UserService, private session: SessionService) { }

  ngOnInit(): void {
    this.activeFacilitySubs =  this.session.activeFacility$.subscribe(activeFacility => {
      this.activeFacility = activeFacility;

      if (activeFacility) {
        this.usersSrv.fetchAll({facilityId: activeFacility.id}).subscribe((users: UserInterface[]) => {
          this.users = users;
        });
      }
    });

    this.loggerUserSubs = this.session.loggedUser$.subscribe(user => {
      this.loggedUser = user;
    });
  }

  ngOnDestroy(): void {
    this.activeFacilitySubs.unsubscribe();
    this.loggerUserSubs.unsubscribe();
  }

  createGroup(group){
    this.usersSrv.createGroup(this.activeFacility.id, group).subscribe( result => {
      this.router.navigate(['/grupos-usuarios']);
    });
  }

}
