import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AutodeskViewerService} from '../../autodesk-viewer.service';
import {FacilityElementInterface} from '../../../facilities/models/facility-element.interface';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map, skipWhile, take, takeUntil} from 'rxjs/operators';
import {hideToolsFromToolBar } from '../../utils/autodesk-customeToolbar';
import { TranslateService } from '@ngx-translate/core';

declare var Autodesk: any;
export let viewerDocument: any;
export let viewables: any;

@Component({
  selector: 'app-autodesk-viewer',
  templateUrl: './autodesk-viewer.component.html',
  styleUrls: ['./autodesk-viewer.component.scss']
})
export class AutodeskViewerComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('viewer') viewerElem: ElementRef;
  @ViewChild('viewablesTree') viewablesTree: ElementRef;
  private viewer: any;
  
  //private view = new Autodesk.Viewing.AggregatedView();
  @Input('document') set document(documentUrn: string) {
    this.initializedViewer$.pipe(
      skipWhile(initialized => !initialized),
      take(1)
    ).subscribe(() => {
      Autodesk.Viewing.Document.load(`urn:${documentUrn}`, this.onDocumentLoadSuccess, this.onDocumentLoadFailure);
    
    });
  }
  private initializedViewer$ = new BehaviorSubject<boolean>(false);

  @Output() selectedElement = new EventEmitter<string>();
  @Output() loaded = new EventEmitter<boolean>();
  @Output() doubleClick = new EventEmitter<boolean>();

  private onDocumentLoadSuccess = (Doc) => {
    viewerDocument = Doc;
    viewables = viewerDocument.getRoot().search({type:'geometry'});
    console.info("IMBI.viewables",viewables)
    this.viewer.loadDocumentNode(viewerDocument, viewerDocument.getRoot().getDefaultGeometry(true));
    //this.viewer.loadDocumentNode(viewerDocument, viewables[0]);
    this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (event) => {
      const id = this.viewer.getSelection()[0];
      this.viewer.getProperties(id, (props) => {
        console.info("IMBI.props.externalId", props.externalId)
        this.selectedElement.emit(props.externalId);
      });
    });

    this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, async (event) => {
      // Get linked documents data 
      let model = this.viewer.getAllModels()[0];
      const aecData = await Autodesk.Viewing.Document.getAecModelData(model.getDocumentNode());
      const rvtLinks = aecData.linkedDocuments;
      console.info("IMBI.rvtLinks==========",rvtLinks)

      hideToolsFromToolBar(this.viewer);
      this.viewer.getExtension("Autodesk.BimWalk").tool.navigator.enableGravity(false);
      this.loaded.next(true);
    });

    this.viewer.canvas.addEventListener('dblclick', e => {
      const id = this.viewer.getSelection()[0];
      this.viewer.getProperties(id, (props) => {
      this.doubleClick.emit(props.externalId);
      });
    });
  }

  private onDocumentLoadFailure = () => {
    console.error('Failed fetching Forge manifest');
  }

  private destroy$ = new Subject<boolean>();
  currentLang: string;
  constructor(
    private autodeskSrv: AutodeskViewerService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    // Subscribe to language changes
    this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
    });

    this.autodeskSrv.shouldResize$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.resize();
    });
  }

  ngAfterViewInit(): void {
    const config = {
      extensions: []
    }
    const options = {
      env: 'AutodeskProduction2',
      language: this.currentLang,
      api: 'streamingV2_EU',  // for models uploaded to EMEA change this option to 'streamingV2_EU'
      getAccessToken: (onTokenReady) => {
        this.autodeskSrv.getToken().subscribe(token => {
          onTokenReady(token.access_token, token.expires_in);
        });
      }
    };

    Autodesk.Viewing.Initializer(options, () => {
      
      this.viewer = new Autodesk.Viewing.GuiViewer3D(this.viewerElem.nativeElement,config);
      const startedCode = this.viewer.start();
      if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }
      this.viewer.loadExtension('Autodesk.DocumentBrowser')
      this.initializedViewer$.next(true);
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  

  public getExternalIds(): Observable<[string, number][]> {
    return new Observable<[string, number][]>(subscriber => {
      this.viewer.model.getExternalIdMapping((result: {[key: string]: number}) => {
        const asArray = Object.entries(result);
        subscriber.next(asArray);
        subscriber.complete();
      });
    });
  }

   public focusElements(
    elements: FacilityElementInterface | FacilityElementInterface[], 
    isolate = false
    ) {
  
      this.viewer.getExtension("Autodesk.ViewCubeUi").setViewCube('[front/right]');
      let elementsGUID = [];
      
      if (!Array.isArray(elements)) {
        elementsGUID.push(elements.externalIdentifier);
      } else {
        elementsGUID = elements.map(elm => elm.externalIdentifier);
      }

      this.getExternalIds().pipe(
        map(pairs => {
          return pairs.filter(pair => elementsGUID.includes(pair[0]));
        }),
        map(pairs => pairs.map(pair => pair[1]))
      ).subscribe(dbIds => {
      
        if (isolate) {
          this.viewer.isolate(dbIds); 
        }

      this.viewer.select(dbIds)
      this.viewer.fitToView(dbIds);
      this.viewer.getExtension("Autodesk.BimWalk").activate();
    });
  }

  public resetIsolation(): void {
    this.viewer.isolate();
  }

  public render(): void {
    this.viewer.run();
  }

  public resize(): void {
    setTimeout(() => {
      this.viewer.resize();
    }, 500);
  }

}