
<div class="two column row">
    <div class="column">
      <div class="ui icon buttons">
        <div (click)="previousMonth()" class="ui labeled icon button">
          <i class="left arrow icon"></i>
          {{'PREVIOUS' | translate}}
        </div>
        <div class="ui button" (click)="goToday()">{{'TODAY' | translate}}</div>
        <div (click)="nextMonth()" class="ui right labeled icon button">
          <i class="right arrow icon"></i>
          {{'NEXT' | translate}}
        </div>
      </div>
    </div>

    <div class="right aligned column">
      <h2>{{monthYearText$ | async | titlecase}}</h2>
    </div>

</div>

<div class="sixteen wide column">
  <mwl-calendar-month-view
    [viewDate]="calendarDate"
    [activeDay]="activeDay"
    [events]="events$ | async"
    [activeDayIsOpen]="activeDayOpen"
    (dayClicked)="dayClicked($event)"
    (eventClicked)="eventClicked($event)"
    [locale]="currentLang$ | async"
    [weekStartsOn]="weekStartsOn"
  ></mwl-calendar-month-view>
</div>


