import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MaterialInterface} from './models/work-order-material.interface';
import {CategoryInterface} from '../shared/models/category.interface';
import { UtilsService } from '../shared/services/utils.service';

@Injectable()
export class WorkOrderMaterialService {

  private readonly apiHost = environment.apiHost;

  constructor(
    private http: HttpClient,
    private utilsService : UtilsService) {}

  public fetchCategories(): Observable<CategoryInterface[]> {
    return this.http.get<CategoryInterface[]>(`${this.apiHost}/categories`);
  }

  public fetchMaterials(queryParams?: {[key: string]: string}): Observable<MaterialInterface[]> {
    let params = [];

    if (queryParams) 
      params = this.utilsService.parseParams(queryParams);

    return this.http.get<MaterialInterface[]>(`${this.apiHost}/materials?${params.join('&')}`);
  }
}
