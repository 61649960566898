import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyInterface } from 'src/app/companies/models/company.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyLogoService {
  private logoInfoSubject = new BehaviorSubject<{ url: string | null; company: CompanyInterface | null }>({ url: null, company: null });

  get logoInfo$() {
    return this.logoInfoSubject.asObservable();
  }

  updateLogoInfo(url: string | null, company: CompanyInterface | null) {
    this.logoInfoSubject.next({url, company});
  }
}
