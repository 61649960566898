import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DropdownComponent} from './dropdown/dropdown.component';
import {TableComponent} from './table/table.component';
import {PaginationMenuComponent} from './pagination-menu/pagination-menu.component';
import {FuiMenuComponent} from './menu/menu.component';
import {DropdownTwoComponent} from './dropdown-two/dropdown-two.component';
import {RatingDirective} from './rating/rating.directive';
import {CalendarRangeComponent} from './calendar-range/calendar-range.component';
import {SortableThDirective} from './sortable-th/sortable-th.directive';
import {CalendarComponent} from './calendar/calendar.component';
import {ReactiveFormsModule} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    DropdownComponent,
    DropdownTwoComponent,
    TableComponent,
    PaginationMenuComponent,
    FuiMenuComponent,
    DropdownTwoComponent,
    RatingDirective,
    CalendarRangeComponent,
    SortableThDirective,
    CalendarComponent
  ],
  exports: [
    ReactiveFormsModule,
    DropdownComponent,
    DropdownTwoComponent,
    TableComponent,
    PaginationMenuComponent,
    FuiMenuComponent,
    DropdownTwoComponent,
    RatingDirective,
    CalendarRangeComponent,
    SortableThDirective,
    CalendarComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    TranslateModule.forChild(),
  ]
})
export class FomanticUiModule {
}
