import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fui-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnChanges {

  @Input() id: string;
  @HostBinding('id') hostId: string;
  @HostBinding('className') hostClasses;
  @HostBinding('class.disabled') get isDisabled() { return this.disabled; }

  @Input() name: string;
  @Input() placeholder: string;
  @Input() showDropdownIcon = true;

  @Input() multiple: boolean;
  @Input() search: boolean;
  @Input() fluid = false;
  @Input() disabled: boolean;
  @Input() size: string;

  @Input() allowAdditions = false;

  @Input() valueProperty: string;
  @Input() nameProperty: string;

  // formattedOptions: { value: string, name: string }[];
  @Input() options: any[];

  @Input() defaultSelected: any;

  @Input() set selected(options: any) {
    if (Array.isArray(options)) {
      options.forEach(option => this.selectOption(option));
    } else {
      this.selectOption(options);
    }
  }

  @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit(): void {
    this.hostId = this.id;
    this.hostClasses = this.calculateHostClasses();

    setTimeout(() => {
      this.initializeDropdown();
      this.selectDefaults();
    }, 300);
  }

  private calculateHostClasses(): string {
    const classes = ['ui'];
    if (this.fluid) {
      classes.push('fluid');
    }
    if (this.size) {
      classes.push(this.size);
    }
    if (this.search) {
      classes.push('search');
    }
    if (this.multiple) {
      classes.push('multiple');
    }
    classes.push('selection');
    classes.push('dropdown');
    return  classes.join(' ');
  }


  // ------ Native dropdown methods --------

  private initializeDropdown() {
    const idSelector = '#' + this.id;
    ($(idSelector) as any).dropdown({
      allowAdditions: this.allowAdditions
    });
  }

  private setSelectedInDropdown(id: number | string) {
    ($('#' + this.id) as any).dropdown('set selected', id);
  }

  clearDropdown() {
    ($('#' + this.id) as any).dropdown('clear');
  }

  // ----------------------------------------

  private areEqualOptions(opt1: any, opt2: any) {
    return opt1[this.valueProperty || 'value'] === opt2[this.valueProperty || 'value'] &&
           opt1[this.nameProperty || 'name'] === opt2[this.nameProperty || 'name'];
  }

  private getOptionByValue(value: number | string) {
    if (!this.options) {
      return null;
    }
    const stringValue = typeof value === 'string' ? value : value.toString();

    return this.options.find(option => option[this.valueProperty || 'value'].toString() === stringValue);
  }

  private isInOptions(option: any): boolean {
    if (!this.options || !option) {
      return false;
    }
    const found = this.options.find(current => this.areEqualOptions(current, option));
    return !!found;
  }

  private convertOptions(options: any[]): { value: string, name: string }[] {
    return options ? options.map(option => this.convertObjectToOption(option)) : null;
  }

  private convertObjectToOption(obj: any): { value: string, name: string } | null {
    const value = obj[this.valueProperty || 'value'];
    const name = obj[this.nameProperty || 'name'];

    if ((value || value === 0) && name) {
      return {
        value: value.toString(),
        name
      };
    }

    return null;
  }

  addNewOption(name) {
    const newOption = {};
    newOption[this.valueProperty || 'value'] = name;
    newOption[this.nameProperty || 'name'] = name;

    if (!this.isInOptions(newOption)) {
      this.options.push(newOption);
    }
  }

  private selectOption(option: any) {
    if (!this.isInOptions(option)) {
      if (this.allowAdditions) {
        this.addNewOption(option);
        this.setSelectedInDropdown(option);
      } else {
        this.clearDropdown();
        return;
      }
    }
    this.setSelectedInDropdown(option[this.valueProperty || 'value']);
  }


  /**
   * Emit options selected
   */
  hiddenInputValueChanged(value) {
    if (value) {
      let selected;

      if (this.multiple) {

        // Multiple Selector
        selected = [];
        const optionsValues = value.split(',');
        optionsValues.forEach(optionValue => {
          selected.push(this.getOptionByValue(optionValue));
        });

        if (selected.length > 0) {
          this.selectedChange.emit(selected);
        } else if (this.allowAdditions) {
          this.selectedChange.emit(optionsValues);
        } else {
          this.selectedChange.emit(null);
        }
      } else {

        // Single Selector
        selected = this.getOptionByValue(value);

        if (selected) {
          this.selectedChange.emit(selected);
        } else if (this.allowAdditions) {
          this.selectedChange.emit(value);
        } else {
          this.selectedChange.emit(null);
        }
      }

    } else {
      this.selectedChange.emit(null);
    }
  }

  selectDefaults() {
    if (this.options && this.defaultSelected) {
      if (Array.isArray(this.defaultSelected)) {
        this.defaultSelected.forEach(toSelect => this.selectOption(toSelect));
      } else {
        this.selectOption(this.defaultSelected);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.valueProperty || changes.nameProperty) && this.options) {
      this.selectDefaults();
    }

    if (changes.options) {
      const options = changes.options.currentValue;
      if (options) {
        this.selectDefaults();
      } else {
        this.clearDropdown();
      }
    }
  }
}
