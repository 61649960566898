import {NgModule} from '@angular/core';
import {FacilitiesService} from './facilities.service';
import {FacilitiesRoutingModule} from './facilities-routing.module';

// pages
import {ElementsPageComponent} from './pages/elements-page/elements-page.component';
import {ElementsDetailPageComponent} from './pages/elements-detail-page/elements-detail-page.component';
import {ElementTypesPageComponent} from './pages/element-types-page/element-types-page.component';
import {ElementTypeDetailPageComponent} from './pages/element-type-detail-page/element-type-detail-page.component';
import {ElementTypeFormComponent} from './components/element-type-form/element-type-form.component';
import {Ng2ImgMaxModule} from 'ng2-img-max';
import {SharedModule} from '../shared/shared.module';
import {AutodeskForgeModule} from '../autodesk-forge/autodesk-forge.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ElementsPageComponent,
    ElementsDetailPageComponent,
    ElementTypesPageComponent,
    ElementTypeDetailPageComponent,
    ElementTypeFormComponent
  ],
  imports: [
    SharedModule,
    FacilitiesRoutingModule,
    Ng2ImgMaxModule,
    AutodeskForgeModule,
    TranslateModule.forChild(),
  ],
  providers: [
    FacilitiesService
  ]
})
export class FacilitiesModule {
}
