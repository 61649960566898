import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FacilitiesService} from '../../../../facilities/facilities.service';
import {SessionService} from '../../../../shared/services/session.service';
import {skipWhile, switchMap, take} from 'rxjs/operators';

@Component({
  selector: 'app-condition-form',
  templateUrl: './condition-form.component.html',
  styleUrls: ['./condition-form.component.scss']
})
export class ConditionFormComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'fields';

  @Input() conditionId: string;
  @Input() parentForm: FormGroup;
  @Input() set unusedParams(unused: {value: any, name: string}[]) {
    if (this.parentForm.get('filterParam').value) {
      this.availableParamsOptions = [this.parentForm.value.filterParam, ...unused];
    } else {
      this.availableParamsOptions = unused;
    }
  }
  availableParamsOptions: {value: any, name: string}[];
  @Input() groupFilter: object;

  @Input() showDeleteButton = true;
  @Output() deleteClick: EventEmitter<null> = new EventEmitter<null>();

  private subscriptions: Subscription[];

  constructor(private session: SessionService, private facilitiesService: FacilitiesService) { }

  ngOnInit(): void {
    this.subscriptions = [
      this.parentForm.controls.filterParam.valueChanges.subscribe((value) => {
        if (value) {
          this.parentForm.get('value').enable();
        }
        this.parentForm.controls.value.reset();
      }),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  getOptions(search: string, maxResults: number = null) {
    let searchInParam = this.parentForm.value.filterParam?.value;
    if (searchInParam) {
      searchInParam = searchInParam + 's';
      let searchFilter = {};
      const paramFilter = {};
      searchFilter = Object.assign(searchFilter, this.groupFilter || {});
      if (searchFilter[searchInParam]) {
        delete searchFilter[searchInParam];
      }
      if (search && search.trim().length) {
        paramFilter[searchInParam] = [search];
        searchFilter = Object.assign(searchFilter, paramFilter);
      }
      return this.session.activeFacility$.pipe(
        skipWhile(facility => !facility),
        take(1),
        switchMap(facility => {
          const params: {facilityId: number, limit?: number} = {facilityId: facility.id};
          if (maxResults) {
            params.limit = maxResults;
          }
          return this.facilitiesService.searchPropertyValues(searchInParam, params, searchFilter);
        })
      );
    }

  }
}
