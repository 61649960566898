
<div *ngIf="facilities && facilities.length > 0">
    <ul>
        <li *ngFor="let facility of facilities"> 
            <span *ngIf="facility"
            (click)="loadFloors(facility.id)"
            ><app-collapsible-tree 
            title="{{facility.name}}"
            class="clickable"
            level="facility"
            ></app-collapsible-tree>  
            </span>
            <!-- FLOORS -->
            <div *ngIf="facility && facilityFloorsMap.hasOwnProperty(facility.id)">
                <ul *ngIf="facilityFloorsVisible[facility.id] && facilityFloorsMap[facility.id]?.length > 0">
                    <li *ngFor="let floor of facilityFloorsMap[facility.id]"> 
                        <span *ngIf="floor" 
                        (click)="loadSpaces(floor.id)">
                            <app-collapsible-tree 
                            title="{{floor.name}}"
                            class="clickable tab1" 
                            level="floor"
                            ></app-collapsible-tree>
                        </span>
                        <!-- SPACES -->
                        <div *ngIf="floor && floor.id in floorSpacesMap && floorSpacesMap[floor.id]">
                            <ul *ngIf="floorSpacesVisible[floor.id] && floorSpacesMap[floor.id]?.length > 0">
                                <li *ngFor="let space of floorSpacesMap[floor.id]"> 
                                    <span *ngIf="space" 
                                        (click)="loadComponents(space.id)">
                                        <app-collapsible-tree 
                                        title="{{space.name}}"
                                        class="clickable tab2" 
                                        level="space"
                                    ></app-collapsible-tree>
                                    </span>
                                    <!-- COMPONENTS -->
                                    <div *ngIf="space && space.id in spaceComponentsMap && spaceComponentsMap[space.id]">
                                        <ul *ngIf="spaceComponentsVisible[space.id] && spaceComponentsMap[space.id]?.length > 0">
                                            <li *ngFor="let component of spaceComponentsMap[space.id]"> 
                                                <span *ngIf="component" >
                                                    <app-collapsible-tree 
                                                        title="{{component.name}}"
                                                        class="clickable tab3 leaf"
                                                        level="component"
                                                        id={{component.id}}
                                                        facilityId={{facility.id}}
                                                                    ></app-collapsible-tree>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>
 