import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui message';
  @HostBinding('class.success') get isSuccessType() { return this.type === 'success'; }
  @HostBinding('class.error') get isErrorType() { return this.type === 'error'; }

  @Input() type: string;
  @Input() header: string;
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
