<ng-container [formGroup]="parentForm">
  <div class="seven wide field">
    <pui-dropdown
      placeholder="parámetro"
      formControlName="filterParam"
      [options]="availableParamsOptions"
    ></pui-dropdown>
  </div>
  <div class="seven wide field">
    <pui-dropdown
      multiple
      asyncSearch
      [asyncSearchFunction]="getOptions.bind(this)"
      allowAdditions
      formControlName="value"
    ></pui-dropdown>
  </div>
  <div class="two wide field">
    <div *ngIf="showDeleteButton" class="ui icon button" (click)="deleteClick.emit(null)">
      <i class="icon delete"></i>
    </div>
  </div>
</ng-container>
