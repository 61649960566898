<div class="sixteen wide column">
  <h1 class="ui dividing header">{{'SETTINGS.TITLE' | translate}}</h1>
</div>

<configuration-menu [defaultSectionKey]="'problemTypes'"></configuration-menu>

<div class="sixteen wide mobile twelve wide computer column">
  <div class="ui grid">
    <div *ngIf="activeSection" class="sixteen wide column">

      <app-problem-types-tab *ngIf="activeSection.key === 'problemTypes'">
      </app-problem-types-tab>

    </div>

  </div>
</div>

