import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {DetailWorkOrderService} from '../detail-work-order.service';
import {map, skipWhile} from 'rxjs/operators';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {SessionService} from '../../../../shared/services/session.service';
import { FacilitiesService } from 'src/app/facilities/facilities.service';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-detail-work-order-costs',
  templateUrl: './detail-work-order-costs.component.html',
  styleUrls: ['./detail-work-order-costs.component.scss']
})
export class DetailWorkOrderCostsComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui grid';
  currencySymbol: string = 'EUR';
  private activeFacilitySubscription: Subscription;
  
  constructor(
    public sessionService: SessionService,
    public detailWorkOrderSrv: DetailWorkOrderService,
    private facilitiesService: FacilitiesService,
  ) { }

  ngOnInit(): void {
    this.initializeFormArray();

    this.activeFacilitySubscription = this.sessionService.activeFacility$.subscribe((activeFacility) => {
      if (activeFacility) {
        const currencySymbol = activeFacility.currencyUnit || 'EUR';
        this.currencySymbol = currencySymbol;
      }
    });
  }
  ngOnDestroy(): void {
    this.activeFacilitySubscription.unsubscribe();
  }

  getTotalMaterialCosts(): Observable<number> {
    const unitCosts =  this.detailWorkOrderSrv.billingForm.value.workOrderMaterialsCosts;
    return this.detailWorkOrderSrv.workOrder$.pipe(
      skipWhile(order => !order),
      map(order => {
        return unitCosts ? unitCosts.reduce((sum, unitCost) => {
          const material = order.workOrderMaterials?.find(material => material.id === unitCost.workOrderMaterialId);

        // Use optional chaining and provide a default value of 0 if material is not found or undefined
        const numUnits = material?.quantity || 0;

        return sum + (numUnits * (unitCost.unitCost || 0));
      }, 0) : null;
      })
    );
  }

  getTotalHoursCost(): Observable<number> {
    return this.detailWorkOrderSrv.workOrder$.pipe(
      skipWhile(order => !order),
      map(order => {
        return (order.workingTime / 60) * this.detailWorkOrderSrv.billingForm.value.workingTimeCost;
      })
    );
  }

  getTotalCost(): Observable<number> {
    return combineLatest([
      this.getTotalMaterialCosts(),
      this.getTotalHoursCost()
    ]).pipe(
      map(([tableDataProcessedDate1, hoursCost]) => tableDataProcessedDate1 + hoursCost)
    );
  }

  initializeFormArray(): void {
    const workOrderMaterialsCosts = this.detailWorkOrderSrv.billingForm.get('workOrderMaterialsCosts') as FormArray;
    if (!workOrderMaterialsCosts) {
      // Create the FormArray if it does not exist to avoid errors in console
      this.detailWorkOrderSrv.billingForm.addControl('workOrderMaterialsCosts', new FormArray([]));
    }
  }
}
