import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit{
  resetPasswordForm: FormGroup;
  loading: boolean;
  successMessage: string | null = null;
  errorMessage: string | null = null;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
  ) {
    this.resetPasswordForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async resetPasswordSubmit() {
    this.loading = true;

    this.resetPasswordForm.markAllAsTouched();

    const email = this.resetPasswordForm.get('email').value;

    this.authService.forgotPassword(email).subscribe(
      (result) => {
        this.errorMessage = null;
        this.successMessage = result.message;
      },
      (error) => {
        console.error('Error resetting password', error);
        this.successMessage = null;
        this.errorMessage = error.message;
      }
    ).add(() => {
      // This will run regardless of success or error
      this.loading = false;
    });
  }

  ngOnInit(): void {
  }
}