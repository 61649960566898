<div class="sixteen wide column">
  <h1 class="ui dividing header">{{'USER_GROUPS.ADD_GROUP' | translate}}</h1>
</div>
  <div class="sixteen wide column">
      <app-user-group-form
        [loggedUser]="loggedUser"
        [users]="users"
        [submitButtonText]="'USER_GROUPS.CREATE' | translate"
        (groupSubmitted)="createGroup($event)"
        >
      </app-user-group-form>
  </div>
