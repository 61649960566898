<div class="centered row">
  <div class="fourteen wide column" style="margin-top: 10px">
    <div class="ui stackable grid">

      <div class="computer only ten wide column"></div>
      <div class="sixteen wide column ten wide computer column">
        <fui-calendar-range
          selectorId="calendar"
          size="tiny"
          (startDateChange)="selectedStartDate($event)"
          (endDateChange)="selectedEndDate($event)"
        ></fui-calendar-range>
      </div>

      <div *ngIf="areAnyRatePctStats(stats)" class="sixteen wide column">
        <div class="stats-group">
          <h1 class="title">{{ 'MAINTENANCE_PERFORMED' | translate }}</h1>
          <div class="stats">
            <div class="stat">
              <h2 class="title">{{ 'SCHEDULED' | translate }}</h2>
              <span class="value">{{ stats?.pctScheduledTaskVsCorrective ? (stats?.pctScheduledTaskVsCorrective | number:'1.0-0') : '-' }} <span class="unit">%</span></span>
            </div>
            <div class="stat" [class.error]="stats?.pctCorrectiveVsScheduledTask > stats?.pctScheduledTaskVsCorrective">
              <h2 class="title">{{ 'CORRECTIVE' | translate }}</h2>
              <span class="value">{{ stats?.pctCorrectiveVsScheduledTask ? (stats?.pctCorrectiveVsScheduledTask | number:'1.0-0') : '-' }} <span class="unit">%</span></span>
            </div>
          </div>
          <div class="stats">
            <div class="stat" [class.error]="stats?.pctScheduledTaskFinishedVsScheduled < 80">
              <h2 class="title">{{ 'SCHEDULED_FINISHED_VS_PLANNED' | translate }}</h2>
              <span class="value">{{ stats?.pctScheduledTaskFinishedVsScheduled ? (stats?.pctScheduledTaskFinishedVsScheduled | number:'1.0-0') : '-' }} <span class="unit">%</span></span>
            </div>
            <div class="stat" [class.error]="stats?.pctCorrectiveClosedVsStarted < 80">
              <h2 class="title">{{ 'CORRECTIVE_CLOSED_VS_STARTED' | translate }}</h2>
              <span class="value">{{ stats?.pctCorrectiveClosedVsStarted ? (stats?.pctCorrectiveClosedVsStarted | number:'1.0-0') : '-' }} <span class="unit">%</span></span>
            </div>
            <div class="stat">
              <h2 class="title">{{ 'AVG_SCHEDULED_TIME' | translate }}</h2>
              <span class="value">{{ stats?.avgTimeScheduledTasks ? (stats?.avgTimeScheduledTasks | number:'1.0-0') : '-' }} <span class="unit">{{'HOUR_TASK' | translate}}</span></span>
            </div>
            <div class="stat">
              <h2 class="title">{{ 'AVG_CORRECTIVE_TIME' | translate }}</h2>
              <span class="value">{{ stats?.avgTimeCorrective ? (stats?.avgTimeCorrective | number:'1.0-0') : '-' }} <span class="unit">{{'HOUR_TASK' | translate}}</span></span>
            </div>
          </div>
        </div>
      </div>

      <div class="sixteen wide column">
        <div class="stats-group">
          <h1 class="title">{{ 'MY_REQUESTS' | translate }}</h1>
          <div class="stats">
            <div class="stat linked" (click)="goToIncidencesFilteredByState('myActive')">
              <h2 class="title">{{ 'ACTIVE' | translate }}</h2>
              <span class="value">{{stats?.myActiveIncidences}}</span>
            </div>
            <div class="stat linked" [class.error]="highlightStat('myUnreviewed')" (click)="goToIncidencesFilteredByState('myUnreviewed')">
              <h2 class="title">{{ 'UNRATED' | translate }}</h2>
              <span class="value">{{stats?.myUnreviewedIncidences}}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="areAnyIncidencesValueStats(stats)" class="sixteen wide column">
        <div class="stats-group">
          <h1 class="title">{{ 'ALL_REQUESTS' | translate }}</h1>
          <div class="stats">
            <div class="stat linked" (click)="goToIncidencesFilteredByState('unstarted')" [class.error]="highlightStat('unstartedIncidences')" *ngIf="stats?.unstartedIncidences">
              <h2 class="title">{{ 'UNSTARTED' | translate }}</h2>
              <span class="value">{{stats.unstartedIncidences}}</span>
            </div>
            <div class="stat linked" (click)="goToIncidencesFilteredByState('unassigned')" [class.error]="highlightStat('unassignedIncidences')" *ngIf="stats?.unassignedIncidences">
              <h2 class="title">{{ 'UNASSIGNED' | translate }}</h2>
              <span class="value">{{stats.unassignedIncidences}}</span>
            </div>
            <div class="stat linked" (click)="goToIncidencesFilteredByState('unfinished')" [class.error]="highlightStat('unfinishedIncidences')" *ngIf="stats?.unfinishedIncidences">
              <h2 class="title">{{ 'UNFINISHED' | translate }}</h2>
              <span class="value">{{stats.unfinishedIncidences}}</span>
            </div>
            <div class="stat linked" (click)="goToIncidencesFilteredByState('unclosed')" [class.error]="highlightStat('unclosedIncidences')" *ngIf="stats?.unclosedIncidences">
              <h2 class="title">{{ 'UNCLOSED' | translate }}</h2>
              <span class="value">{{stats.unclosedIncidences}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="sixteen wide column" *ngIf="incidenceChart || incidenceCostChart">
        <div class="charts-row">
          <app-line-chart class="chart" *ngIf="incidenceChart" [startDate]="(datesRange$ | async)?.start" [endDate]="(datesRange$ | async)?.end" [chartDataSets]="incidenceChart"></app-line-chart>
          <app-line-chart class="chart" *ngIf="incidenceCostChart" [startDate]="(datesRange$ | async).start" [endDate]="(datesRange$ | async).end" [chartDataSets]="incidenceCostChart"></app-line-chart>
        </div>
      </div>

      <div *ngIf="areAnyWorkOrderValueStats(stats)" class="sixteen wide column">
        <div class="stats-group">
          <h1 class="title">{{ 'WORK_ORDERS' | translate }}</h1>
          <div class="stats">
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('waiting')" [class.error]="highlightStat('waitingOrders')" *ngIf="stats?.waitingOrders || stats?.waitingOrders === 0">
              <h2 class="title">{{ 'WAITING' | translate }}</h2>
              <span class="value">{{stats.waitingOrders}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('inProgress')" [class.error]="highlightStat('inProgressOrders')" *ngIf="stats?.inProgressOrders || stats?.inProgressOrders === 0">
              <h2 class="title">{{ 'IN_PROGRESS' | translate }}</h2>
              <span class="value">{{stats.inProgressOrders}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('unassigned')" [class.error]="highlightStat('unassignedOrders')" *ngIf="stats?.unassignedOrders || stats?.unassignedOrders === 0">
              <h2 class="title">{{ 'UNASSIGNED' | translate }}</h2>
              <span class="value">{{stats.unassignedOrders}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('unfinished')" [class.error]="highlightStat('unfinishedOrders')" *ngIf="stats?.unfinishedOrders || stats?.unfinishedOrders === 0">
              <h2 class="title">{{ 'UNFINISHED' | translate }}</h2>
              <span class="value">{{stats.unfinishedOrders}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('needCloseOrders')" [class.error]="highlightStat('needCloseOrders')" *ngIf="stats?.needCloseOrders || stats?.needCloseOrders === 0">
              <h2 class="title">{{ 'NEED_CLOSE' | translate }}</h2>
              <span class="value">{{stats.needCloseOrders}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('expired')" [class.error]="highlightStat('ordersOverTime')" *ngIf="stats?.ordersOverTime || stats?.ordersOverTime === 0">
              <h2 class="title">{{ 'EXPIRED' | translate }}</h2>
              <span class="value">{{stats.ordersOverTime}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('created')" [class.error]="highlightStat('countCorrectiveCreated')" *ngIf="stats?.countCorrectiveCreated || stats?.countCorrectiveCreated === 0">
              <h2 class="title">{{ 'CREATED' | translate }}</h2>
              <span class="value">{{stats.countCorrectiveCreated}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('started')" [class.error]="highlightStat('countCorrectiveStarted')" *ngIf="stats?.countCorrectiveStarted || stats?.countCorrectiveStarted === 0">
              <h2 class="title">{{ 'STARTED' | translate }}</h2>
              <span class="value">{{stats.countCorrectiveStarted}}</span>
            </div>
            <div class="stat linked" (click)="goToWorkOrdersFilteredByState('closed')" [class.error]="highlightStat('countCorrectiveClosed')" *ngIf="stats?.countCorrectiveClosed || stats?.countCorrectiveClosed === 0">
              <h2 class="title">{{ 'CLOSED' | translate }}</h2>
              <span class="value">{{stats.countCorrectiveClosed}}</span>
            </div>
          </div>
          <div class="stats">
            <div class="stat" *ngIf="stats?.costCorrective || stats?.costCorrective === 0">
              <h2 class="title">{{ 'COST' | translate }}</h2>
              <span class="value" [innerHTML]="formatCost(stats.costCorrective, this.currencySymbol)"></span>
            </div>
            <div class="stat">
              <h2 class="title">{{ 'TIME' | translate }}</h2>
              <span class="value">{{stats.hoursCorrective ? (stats.hoursCorrective | number:'.2-2') : '-'}} <span class="unit">h</span></span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="areAnyWorkOrderValueStats(stats)" class="sixteen wide column">
        <div class="charts-row">
          <app-line-chart class="chart" *ngIf="ordersCountChart" [startDate]="(datesRange$ | async).start" [endDate]="(datesRange$ | async).end" [chartDataSets]="ordersCountChart"></app-line-chart>
          <app-line-chart class="chart" *ngIf="ordersTimeChart" [startDate]="(datesRange$ | async).start" [endDate]="(datesRange$ | async).end" [chartDataSets]="ordersTimeChart"></app-line-chart>
        </div>
      </div>

      <div *ngIf="areAnyScheduledValueStats(stats)" class="sixteen wide column">
        <div class="stats-group">
          <h1 class="title">{{ 'SCHEDULED_TASKS' | translate }}</h1>
          <div class="stats">
            <div class="stat linked" (click)="goToScheduledTasksFilteredByState('all')">
              <h2 class="title">{{ 'SCHEDULED' | translate }}</h2>
              <span class="value">{{stats.countScheduledTasks}}</span>
            </div>
            <div class="stat linked" (click)="goToScheduledTasksFilteredByState('toDo')" [class.error]="stats.countScheduledTasksToDo > 0">
              <h2 class="title">{{ 'TO_DO' | translate }}</h2>
              <span class="value">{{stats.countScheduledTasksToDo}}</span>
            </div>
            <div class="stat linked" (click)="goToScheduledTasksFilteredByState('finished')">
              <h2 class="title">{{ 'FINISHED' | translate }}</h2>
              <span class="value">{{stats.countScheduledTasksFinished}}</span>
            </div>
            <div class="stat">
              <h2 class="title">{{ 'TIME' | translate }}</h2>
              <span class="value">{{ stats?.hoursScheduledTasks ? (stats?.hoursScheduledTasks | number:'1.0-0') : '-' }} <span class="unit">h</span></span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="areAnyScheduledValueStats(stats)" class="sixteen wide column">
        <div class="charts-row">
          <app-line-chart class="chart" *ngIf="scheduledTasksCountChart" [startDate]="(datesRange$ | async).start" [endDate]="(datesRange$ | async)?.end" [chartDataSets]="scheduledTasksCountChart"></app-line-chart>
          <app-line-chart class="chart" *ngIf="scheduledTasksFinishedTimeChart" [startDate]="(datesRange$ | async).start" [endDate]="(datesRange$ | async).end" [chartDataSets]="scheduledTasksFinishedTimeChart"></app-line-chart>
        </div>
      </div>

      <div *ngIf="areAnyComponentsValueStats(stats)" class="sixteen wide column">
        <div class="stats-group">
          <h1 class="title">{{ 'COMPONENTS_TO_REPLACE' | translate }}</h1>
          <div class="stats">
            <div class="stat linked" [class.error]="highlightStat('componentsExpiredIn1Month')" (click)="goToComponentsExpiredNextMonths('1')">
              <h2 class="title">{{ 'ONE_MONTH' | translate }}</h2>
              <span class="value">{{stats.componentsExpiredIn1Month}}</span>
            </div>
            <div class="stat linked" [class.error]="highlightStat('componentsExpiredThisYear')" (click)="goToComponentsExpiredNextMonths('thisYear')">
              <h2 class="title">{{ 'THIS_YEAR' | translate }}</h2>
              <span class="value">{{stats.componentsExpiredThisYear}}</span>
            </div>
            <div class="stat linked" [class.error]="highlightStat('componentsExpiredIn12Months')" (click)="goToComponentsExpiredNextMonths('12')">
              <h2 class="title">{{ 'TWELVE_MONTHS' | translate }}</h2>
              <span class="value">{{stats.componentsExpiredIn12Months}}</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
