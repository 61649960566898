import { NgModule } from '@angular/core';
import {UserGroupsPageComponent} from './pages/user-groups-page/user-groups-page.component';
import {DetailUserGroupPageComponent} from './pages/detail-user-group/detail-user-group-page.component';
import {AddUserGroupPageComponent} from './pages/add-user-group-page/add-user-group-page.component';
import {UserGroupFormComponent} from './components/user-group-form/user-group-form.component';
import {SharedModule} from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    UserGroupFormComponent,

    UserGroupsPageComponent,
    DetailUserGroupPageComponent,
    AddUserGroupPageComponent
  ],
  imports: [
    SharedModule,
    TranslateModule.forChild(),
  ]
})
export class UserGroupsModule { }
