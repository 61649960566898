import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserInterface} from '../../models/user.interface';
import {CompanyInterface} from '../../../companies/models/company.interface';
import {RoleInterface} from '../../../shared/models/role.interface';
import {Subscription} from 'rxjs';
import { UserFormComponentValidator } from 'src/app/shared/validators/users.validators';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html'
})
export class UserFormComponent implements OnInit, OnChanges, OnDestroy {
  iconClass = 'eye slash';
  form: FormGroup;
  passwordVisible = false;

  @Input() set user(data: UserInterface) {
    if (data) {
      this.isEditing = true;
      this.userId = data.id;

      this.form.controls.name.setValue(data.name);
      this.form.controls.surname.setValue(data.surname);
      this.form.controls.email.setValue(data.email);
      this.form.controls.mobile.setValue(data.mobile);

      this.form.controls.password.clearValidators();
      this.form.controls.password.setValidators(UserFormComponentValidator.passwordStrength);
      this.form.controls.password.updateValueAndValidity();

      this.form.controls.company.setValue(data.company);
      this.form.controls.role.setValue(data.userFacilityRoles[0].role);

      this.form.updateValueAndValidity();
      this.form.markAsPristine();
    }
  }

  @Input() disableEdit: boolean;
  @Input() isClientUser: boolean;
  @Input() companyOptions: CompanyInterface[];
  @Input() roleOptions: RoleInterface[];
  @Input() buttonLabel = 'Crear';

  isEditing = false;
  userId: number;

  selCompanySubs: Subscription;
  @Output() selectedCompany: EventEmitter<CompanyInterface> = new EventEmitter<CompanyInterface>();
  @Output() submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() validChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder) {
    this.form = fb.group({
      name: ['', Validators.required],
      surname: [''],
      mobile: [''],
      email: ['', Validators.required],
      password: ['', [Validators.required, UserFormComponentValidator.passwordStrength]],
      company: [null, Validators.required],
      role: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.selCompanySubs = this.form.controls.company.valueChanges.subscribe(value => {
      this.selectedCompany.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.companyOptions && changes.companyOptions.currentValue?.length === 1) {
      this.form.controls.company.setValue(changes.companyOptions.currentValue[0]);
    }
  }

  ngOnDestroy() {
    this.selCompanySubs.unsubscribe();
  }

  canSubmitForm() {
    return this.isEditing ? this.form.valid && this.form.dirty && !this.disableEdit : this.form.valid;
  }

  submitForm() {
    const data = {
      name: this.form.value.name,
      surname: this.form.value.surname,
      email: this.form.value.email,
      companyId: this.form.value.company?.id,
      roleId: this.form.value.role?.id,
      mobile: this.form.value.mobile,
    };

    if (this.form.controls.password.valid) {
      Object.assign(data, {password: this.form.value.password});
    }

    if (this.isEditing) {
      Object.assign(data, {id: this.userId});
    }

    this.submitted.emit(data);
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    this.iconClass = this.passwordVisible ? 'eye' : 'eye slash';
  }
}
