import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, Validators} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-conditions-filter-group-form',
  templateUrl: './conditions-filter-group-form.component.html',
  styles: []
})
export class ConditionsFilterGroupFormComponent implements OnInit {

  @Input() conditionsFiltersArray: FormArray;
  @Input() filterParams: any;
  @Input() firstGroupTitle = this.translate.instant('SCHEDULED_TASK_DETAIL.AND_COMPONENTS_WITH_ALL');
  @Input() nextGroupsTitle = this.translate.instant('SCHEDULED_TASK_DETAIL.OR_COMPONENTS_WITH_ALL');

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  deleteFilter(index: number) {
    this.conditionsFiltersArray.removeAt(index);
  }

  addFilter() {
    this.conditionsFiltersArray.push(this.createConditionsFilterForm());
  }

  createConditionsFilterForm(): FormArray {
    return this.fb.array([
      this.fb.group({
        filterParam: [null, Validators.required],
        value: [{value: null, disabled: true}, Validators.required]
      })
    ]);
  }
}
