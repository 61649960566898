import {NgModule} from '@angular/core';

// Modules
import {RouterModule} from '@angular/router';
import {PhotoGalleryModule} from '@twogate/ngx-photo-gallery';
import {WorkordersRoutingModule} from './workorders-routing.module';
import {SharedModule} from '../shared/shared.module';
import {AutodeskForgeModule} from '../autodesk-forge/autodesk-forge.module';

// Components
import {WorkOrderTableComponent} from './components/work-order-table/work-order-table.component';
import {WorkOrderInfoFormComponent} from './components/work-order-info-form/work-order-info-form.component';
import {
  WorkOrderStepsIndicatorComponent
} from './components/work-order-steps-indicator/work-order-steps-indicator.component';
import {AddMaterialFormComponent} from './components/add-material-form/add-material-form.component';

// Pages
import {WorkOrdersListComponent} from './pages/work-orders-list/work-orders-list.component';
import {DetailWorkOrderComponent} from './pages/detail-work-order/detail-work-order.component';
import {
  DetailWorkOrderInfoComponent
} from './pages/detail-work-order/detail-work-order-info/detail-work-order-info.component';
import {
  DetailWorkOrderChatComponent
} from './pages/detail-work-order/detail-work-order-chat/detail-work-order-chat.component';
import {
  DetailWorkOrderFacilityElemsComponent
} from './pages/detail-work-order/detail-work-order-facility-elems/detail-work-order-facility-elems.component';
import {
  DetailWorkOrderMaterialsComponent
} from './pages/detail-work-order/detail-work-order-materials/detail-work-order-materials.component';
import {
  DetailWorkOrderCostsComponent
} from './pages/detail-work-order/detail-work-order-costs/detail-work-order-costs.component';

// Services
import {WorkOrderMaterialService} from './work-order-material.service';
import {
  DetailWorkOrderDocumentsComponent
} from './pages/detail-work-order/detail-work-order-documents/detail-work-order-documents.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    // Components
    WorkOrderTableComponent,
    WorkOrderInfoFormComponent,
    WorkOrderStepsIndicatorComponent,
    AddMaterialFormComponent,

    // Pages
    WorkOrdersListComponent,
    DetailWorkOrderComponent,
    DetailWorkOrderInfoComponent,
    DetailWorkOrderDocumentsComponent,
    DetailWorkOrderChatComponent,
    DetailWorkOrderFacilityElemsComponent,
    DetailWorkOrderMaterialsComponent,
    DetailWorkOrderCostsComponent
  ],
  exports: [
    WorkOrderInfoFormComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    PhotoGalleryModule,
    WorkordersRoutingModule,
    AutodeskForgeModule,
    TranslateModule.forChild(),
  ],
  providers: [WorkOrderMaterialService]
})
export class WorkordersModule {
}
