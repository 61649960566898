import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {Facility} from '../../../../facilities/models/facility.interface';
import {SessionService} from '../../../../shared/services/session.service';
import {DetailIncidenceService} from '../detail-incidence.service';
import {FacilitiesService} from '../../../../facilities/facilities.service';
import {filter,skipWhile,take} from 'rxjs/operators';
import {FacilityComponentInterface, FacilitySpaceInterface} from '../../../../facilities/models/facility-element.interface';
import {AutodeskViewerComponent} from '../../../../autodesk-forge/components/autodesk-viewer/autodesk-viewer.component';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-detail-incidence-facility-elems',
  templateUrl: './detail-incidence-facility-elems.component.html',
  styleUrls: ['./detail-incidence-facility-elems.component.scss']
})
export class DetailIncidenceFacilityElemsComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui stackable grid';
  @ViewChild('viewer') viewer: AutodeskViewerComponent;

  facility$: Observable<Facility>;
  activeFacility: Facility;
  facilitySubs: Subscription;
  selectedElem: FacilityComponentInterface;
  isElementSelected = false;
  hasViewer: boolean;

  constructor(
    private session: SessionService,
    private facilitySrv: FacilitiesService,
    public detailIncidence: DetailIncidenceService,
  ) {
    this.facility$ = this.session.activeFacility$.pipe(skipWhile(facility => !facility));
  }

  ngOnInit(): void {
    this.facilitySubs = this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1)
    )
      .subscribe(activeFacility => {
        this.activeFacility = activeFacility;
        if (activeFacility) {
          this.hasViewer = this.activeFacility.externalFacilityObject != null ? true : false;
        }
      });
  }

  loadedViewer() {
    this.showSelectedComponents();
  }

  showComponentInfo(component: FacilityComponentInterface | string): void {
    if (component){
      if (typeof component === 'string') {
        this.facilitySrv.fetchComponentByExternalIdentifier(
          component, 
          this.activeFacility.id)
        .subscribe(
          result => {
            if (result) {
              this.selectedElem = result;
            }
          }
        );
      } else {
        this.selectedElem = component;
      }
    }
    if (this.hasViewer) this.viewer.resize();
  }

  showIncidenceComponent(component: FacilityComponentInterface) {
    const isolate: boolean = false;
    this.selectedElem = component;
    if (this.hasViewer) {
      this.viewer.focusElements(component, isolate);
      this.viewer.resize();
    }
  }

  showSelectedComponents() {
    const isolate: boolean = false;
    this.selectedElem = null;
    if (this.hasViewer){
      this.viewer.focusElements(
        this.detailIncidence.incidence$.value.components
        .map(component => component.component), isolate);
    }
    this.closeElementInfoPanel();
  }

  removeComponent(component: FacilityComponentInterface) {
    this.detailIncidence.incidence$.value.components.splice(this.detailIncidence.incidence$.value.components.findIndex(relationObject => relationObject.component.id === component.id), 1);
    this.detailIncidence.modifiedComponents = true;
    this.showSelectedComponents();
  }

  selectedSpaceOnSelector(space: FacilitySpaceInterface) {
    this.facilitySrv.fetchCubeForSpace(space.externalIdentifier, this.activeFacility.id)
    .subscribe(
      component => {
        if (this.hasViewer) this.viewer.focusElements(component);
        this.selectedComponentOnSelector(component);
      }
    );
    
  }

  selectedComponentOnSelector(component: FacilityComponentInterface) {
    this.selectedElem = component;
    this.isElementSelected = true;
    if (this.hasViewer) this.viewer.focusElements(component);
  }

  closeElementInfoPanel() {
    this.selectedElem = null;
    this.isElementSelected = false;
    if (this.hasViewer) this.viewer.resize();
  }

  addComponentToCurrentIncidence(component: FacilityComponentInterface){
    this.detailIncidence.addComponentToIncidence(component.externalIdentifier);
    this.closeElementInfoPanel();
  }
  getAddComponentCallback() {
    return this.addComponentToCurrentIncidence.bind(this);
  }
  
}
