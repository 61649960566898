<div class="sixteen wide column">
  <h1 class="ui dividing header">
    {{ 'SCHEDULED_TASK_DETAIL.TASK' | translate }}: {{ (task$ | async)?.name }}
  </h1>
</div>

<div class="three wide column">
  <div class="ui left labeled icon button" (click)="navigateBack()">
    <i class="angle left icon"></i>
    {{ 'SCHEDULED_TASK_DETAIL.SCHEDULED_TASKS' | translate }}
  </div>
</div>
<div *ngIf="canFinishTask()" class="thirteen wide column">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'SCHEDULED_TASK_DETAIL.NEXT_STEP' | translate }}
  </p>
</div>

<div class="sixteen wide column">
  <div class="ui stackable grid container">

    <div class="three wide column">
      <div class="ui fluid vertical menu">
        <a
        *ngIf="canViewInfoANDReviewSection()"
          class="item"
          [class.active]="activeSection === 'info'"
          (click)="activeSection = 'info'"
          >{{ 'SCHEDULED_TASK_DETAIL.INFO' | translate }}</a
        >
        <a
        *ngIf="canViewInfoANDReviewSection()"
          class="item"
          [class.active]="activeSection === 'viewer'"
          (click)="activeSection = 'viewer'"
          >{{ 'SCHEDULED_TASK_DETAIL.REVIEW_COMPONENTS' | translate }}</a
        >
        <a
          *ngIf="canViewFilterANDEditSection() && !isMobile"
          class="item"
          [class.active]="activeSection === 'filter'"
          (click)="activeSection = 'filter'"
          >{{ 'SCHEDULED_TASK_DETAIL.EDIT_COMPONENTS' | translate }}</a
        >
        <a
          *ngIf="canViewFilterANDEditSection() && !isMobile"
          class="item"
          [class.active]="activeSection === 'edit'"
          (click)="activeSection = 'edit'"
          >{{ 'SCHEDULED_TASK_DETAIL.EDIT_SUBTASKS' | translate }}</a
        >
      </div>
    </div>

    <div class="thirteen wide column">
      <div class="ui grid">
        <!-- ------- INFO SECTION ------- -->

        <app-detail-page-info-section
          *ngIf="activeSection === 'info'"
          [task]="task$ | async"
          [infoForm]="infoForm"
          [categories]="categories$ | async"
          [companies]="selectableCompanies$ | async"
          [users]="selectableUsers | async"
          [validators]="validators"
          [userRole]="userRole$ | async"
          [finishTaskForm]="finishTaskForm"
          [canEditTask]="canEditTask()"
          (validateTask)="validateTask()"
          (editTask)="clickOnEditTask()"
          (deleteTask)="clickOnDeleteTask()"
        >
        </app-detail-page-info-section>

        <!-- ------- FILTER SECTION ------- -->

        <app-detail-page-filter-section
          *ngIf="activeSection === 'filter'"
          [task]="task$ | async"
          [showFilterForm]="canViewComponentsFilter() | async"
          [filtersForm]="filtersForm"
          [filterParams]="filterParams"
          [canEditTask]="canEditTask()"
          [userRole]="userRole$ | async"
          [filteredComponents]="filteredComponentsInPage"
          [filteredComponentsPage]="filteredComponentsPage$ | async"
          [numComponentsPerPage]="numComponentsPerPage"
          [totalComponentsFiltered]="totalComponentsFiltered"
          [componentsTotalPages]="componentsTotalPages"
          (deleteTask)="clickOnDeleteTask()"
          (editTask)="clickOnEditTask()"
          (pageSelected)="pageSelected($event)"
        >
        </app-detail-page-filter-section>

        <!-- ------- VIEWER SECTION ------- -->
        <app-detail-page-viewer-section
          *ngIf="activeSection === 'viewer'"
          [task]="task$ | async"
          [userRole]="userRole$ | async"
          [facility]="actualFacility$ | async"
          [components]="allFilteredComponents$ | async"
        ></app-detail-page-viewer-section>

        <!-- ------- EDIT SECTION ------- -->
        <app-detail-page-edit-section
          style="width: 100%; padding: 0"
          *ngIf="activeSection === 'edit' && 'task'"
          [task]="task$ | async"
          [facility]="actualFacility$ | async"
          [userRole]="userRole$ | async"
        >
        </app-detail-page-edit-section>
      </div>
    </div>
</div>

  <!-- ------- MODALS ------- -->

  <pui-modal *ngIf="showEditingModal" title="{{ 'SCHEDULED_TASK_DETAIL.EDIT_TASKS' | translate }}">
    <div class="edit-modal">
      <i class="fas fa-exclamation-triangle"></i>
      <h3>{{ 'SCHEDULED_TASK_DETAIL.EDIT_TASK_CONFIRM' | translate }}</h3>
    </div>

    <div footer class="edit-modal__footer">
      <div class="left-actions">
        <div class="ui button" (click)="cancelOnModal()">{{ 'SCHEDULED_TASK_DETAIL.CANCEL' | translate }}</div>
      </div>
      <div class="right-actions">
        <div class="ui primary button" (click)="editTasks(false)">
          {{ 'SCHEDULED_TASK_DETAIL.THIS_TASK' | translate }}
        </div>
        <div class="ui primary button" (click)="editTasks(true)">
          {{ 'SCHEDULED_TASK_DETAIL.THIS_AND_FOLLOWING' | translate }}
        </div>
      </div>
    </div>
  </pui-modal>

  <pui-modal *ngIf="showDeletingModal" title="{{ 'SCHEDULED_TASK_DETAIL.DELETE_TASKS' | translate }}">
    <div class="edit-modal">
      <i class="fas fa-exclamation-triangle"></i>
      <h3>
        {{ 'SCHEDULED_TASK_DETAIL.DELETE_TASK_CONFIRM' | translate }}
      </h3>
    </div>

    <div footer class="edit-modal__footer">
      <div class="left-actions">
        <div class="ui button" (click)="cancelOnModal()">{{ 'SCHEDULED_TASK_DETAIL.CANCEL' | translate }}</div>
      </div>
      <div class="right-actions">
        <div class="ui red button" (click)="deleteTasks(false)">
          {{ 'SCHEDULED_TASK_DETAIL.THIS_TASK' | translate }}
        </div>
        <div class="ui red button" (click)="deleteTasks(true)">
          {{ 'SCHEDULED_TASK_DETAIL.THIS_AND_FOLLOWING' | translate }}
        </div>
      </div>
    </div>
  </pui-modal>
</div>
