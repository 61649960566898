<div class="ui hidden divider"></div>
<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'COMPONENT_TYPES.TYPE' | translate }}: {{(componentType$ | async)?.name}}</h1>
</div>

<div class="sixteen wide column">
  <div class="ui stackable grid">
    <div class="three wide column">
      <div class="ui fluid vertical menu">
        <a class="item" [class.active]="activeSection === 'info'" (click)="activeSection = 'info'">{{ 'COMPONENT_TYPES.INFO' | translate }}</a>
        <a *ngIf="hasViewer" class="item" [class.active]="activeSection === 'components'" (click)="activeSection = 'components'">{{ 'COMPONENT_TYPES.COMPONENTS' | translate }}</a>
        <a class="item" [class.active]="activeSection === 'documents'" (click)="activeSection = 'documents'">{{ 'COMPONENT_TYPES.FILES' | translate }}</a>
      </div>
    </div>

    <div *ngIf="activeSection === 'info'" class="thirteen wide column">
      <div class="ui grid">
        <div class="sixteen wide column">
          <app-element-type-form [form]="componentTypeForm"></app-element-type-form>
        </div>
        <div class="sixteen wide column">
          <div class="ui right floated primary button"
               [class.disabled]="componentTypeForm.pristine"
               (click)="saveChanges()"
          >{{ 'COMPONENT_TYPES.SAVE' | translate }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="activeSection === 'components'" class="thirteen wide column">
      <div class="ui grid">
        <div [ngClass]="this.isSelectedComponent ? 'eleven wide column' : 'sixteen wide column'">
          <div class="viewer">
            <app-autodesk-viewer
              #viewer
              [document]="this.activeFacility.externalFacilityObject"
              (selectedElement)="selectComponent($event)"
              (loaded)="loadedViewer()"
            ></app-autodesk-viewer>
          </div>
        </div>
        <div class="five wide column" *ngIf="isSelectedComponent">
          <app-element-info-panel 
            class="element-panel"
            [element]="selectedComponent" 
            (close)="this.selectComponent(null)">
          </app-element-info-panel>
        </div>
        <div class="sixteen wide column">
          <div class="ui button" (click)="focusComponents()">{{ 'COMPONENT_TYPES.SHOW_COMPONENTS' | translate }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="activeSection === 'documents'" class="thirteen wide column">
      <div class="field-description-container thirteen wide column">
        <p class="field-description">
          <i class="circle info icon"></i>
          {{ 'COMPONENT_TYPES.NUM_MAX_FILES' | translate }}
        </p>
      </div>
      <div class="ui hidden divider"></div>
      <div class="sixteen wide column" *ngIf="canEditDocuments()" style="display: flex; justify-content: flex-start">
        <div class="ui primary button" (click)="addDocument()" [class.loading]="uploadingDocument">{{ 'COMPONENT_TYPES.ADD_FILE' | translate }}</div>
        <input #fileInput type="file" (change)="selectedFile()" multiple accept="application/pdf,.jpg,.jpeg,.png,.docx,.xlsx,.pptx" hidden>
      </div>
      <div class="ui grid">
         <div class="sixteen wide column">
           <table class="ui small compact table" *ngIf="(componentType$ | async)?.documents?.length">
             <thead>
             <tr>
               <th>{{ 'COMPONENT_TYPES.NAME' | translate }}</th>
               <th>{{ 'COMPONENT_TYPES.UPLOAD_DATE' | translate }}</th>
               <th *ngIf="canEditDocuments()"></th>
             </tr>
             </thead>
             <tbody>
             <tr *ngFor="let doc of (componentType$ | async).documents" [class.disabled]="deletingDocument === doc.id">
               <td><a target="_blank" [href]="doc.url">{{doc.identifier}}</a></td>
               <td>{{doc.createdAt | date: 'yyyy-MM-dd'}}</td>
               <td *ngIf="canEditDocuments()"><div class="ui mini icon button" (click)="deleteDocument(doc)"><i class="ui delete icon"></i></div></td>
             </tr>
             </tbody>
           </table>
         </div>
       </div>
    </div>
  </div>
</div>
