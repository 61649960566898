<div class="ui hidden divider"></div>
<div class="table-wrapper">
  <table class="ui very compact table">
    <thead>
      <tr>
        <th class="to_right" *ngFor="let param of tableParams">{{ param.name }}</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let items of showedItems">
        <tr *ngFor="let elem of items; let index = index;">
          <td valign="middle" *ngIf="index === 0" [attr.rowspan]="items.length">{{ elem[mainKey] }}</td>
          <ng-container *ngFor="let param of tableParams">
            <ng-container *ngIf="param.key !== mainKey">
              <td [class.even]="elem.valOrder === 2" class="to_right" *ngIf="!param.type">{{elem[param.key]}}</td>
              <td [class.even]="elem.valOrder === 2" class="to_right" *ngIf="param.type === 'number'">{{elem[param.key] | number }}</td>
              <td [class.even]="elem.valOrder === 2" class="to_right" *ngIf="param.type === 'currency'">{{elem[param.key] | currency:currencySymbol}}</td>
              <td [class.even]="elem.valOrder === 2" class="to_right" *ngIf="param.type === 'date'">{{elem[param.key] | date:'yyyy-MM-dd'}}</td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr>
        <th [attr.colspan]="tableParams.length">
          <app-pagination-num-items-info
            itemsName="{{'REPORTS.RECORDS' | translate}}"
            [page]="page"
            [numItemsPerPage]="itemsPerPage"
            [totalItems]="totalItems"
            class="to_left"
          ></app-pagination-num-items-info>
        </th>
      </tr>
      </tfoot>
  </table>
</div>

<fui-pagination-menu
  class="pagination-menu"
  [page]="page"
  [numOfPages]="numOfPages()"
  (pageSelected)="selectPage($event)"
></fui-pagination-menu>

<app-pagination-num-items-info
  itemsName="elementos"
  [page]="page"
  [numItemsPerPage]="itemsPerPage"
  [totalItems]="tableDataProcessedDate1?.length"
></app-pagination-num-items-info>

<div class="sixteen wide column">
  <div *ngIf="showInfoCosts">
    <p>{{'REPORTS.FORBIDDEN_SEE_COSTS' | translate}}</p>
  </div>
</div>
<div class="ui hidden divider"></div>
<div *ngIf="reportId !== 'evolutionIncidencesByCategory'" class="sixteen wide column">
  <div *ngIf="!activeComparative" class="ui left floated primary button margin-bottom-3" (click)="downloadCSVClicked.emit(null)">{{'REPORTS.DOWNLOAD_CSV' | translate}}</div>
  <div class="ui left floated primary button margin-bottom-3" (click)="downloadPDFClicked.emit(null)">{{'BUTTON.DOWNLOAD_PDF' | translate}}</div>
</div>
