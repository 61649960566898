import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LayoutComponent} from '../shared/layout/layout.component';
import {AuthGuardService} from '../auth/auth-guard.service';
import {ElementsPageComponent} from './pages/elements-page/elements-page.component';
import {ElementsDetailPageComponent} from './pages/elements-detail-page/elements-detail-page.component';
import {ElementTypesPageComponent} from './pages/element-types-page/element-types-page.component';
import {ElementTypeDetailPageComponent} from './pages/element-type-detail-page/element-type-detail-page.component';

const routes = [
  {
    path: 'componentes',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: ElementsPageComponent
      },
      {
        path: ':id',
        component: ElementsDetailPageComponent,
      }
    ]
  },
  {
    path: 'tipos-componentes',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: ElementTypesPageComponent
      },
      {
        path: ':id',
        component: ElementTypeDetailPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FacilitiesRoutingModule {}
