import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from '../shared/layout/layout.component';
import {AuthGuardService} from '../auth/auth-guard.service';
import {AddScheduledTaskComponent} from './pages/add-scheduled-task/add-scheduled-task.component';
import {ScheduledTasksListPageComponent} from './pages/scheduled-tasks-list-page/scheduled-tasks-list-page.component';
import {ScheduledTasksPageComponent} from './pages/scheduled-tasks-page/scheduled-tasks-page.component';
import {ScheduledTasksCalendarPageComponent} from './pages/scheduled-tasks-calendar-page/scheduled-tasks-calendar-page.component';
import {ScheduledTaskDetailPageComponent} from './pages/scheduled-task-detail-page/scheduled-task-detail-page.component';
import {RolesService} from '../shared/services/roles.service';
import {ROLES} from '../shared/models/role.interface';
import { ScheduledTasksScheduledPageComponent } from './pages/scheduled-tasks-scheduled-page/scheduled-tasks-scheduled-page.component';


const routes: Routes = [
  {
    path: 'tareas-programadas',
    component: LayoutComponent,
    canActivate: [AuthGuardService, RolesService],
    data: {
      roles: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER]
    },
    children: [
      {
        path: '',
        component: ScheduledTasksPageComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'calendario'
          },
          {
            path: 'lista',
            component: ScheduledTasksListPageComponent
          },
          {
            path: 'calendario',
            component: ScheduledTasksCalendarPageComponent
          },
          {
            path: 'agenda',
            component: ScheduledTasksScheduledPageComponent
          }
        ]
      },
      {
        path: 'nueva',
        component: AddScheduledTaskComponent
      },
      {
        path: ':id',
        component: ScheduledTaskDetailPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScheduledTasksRoutingModule { }
