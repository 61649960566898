import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../shared/services/session.service';
import {WorkOrderService} from '../../work-order.service';
import {map, skipWhile, switchMap} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {WORK_ORDER_STATES_OPTIONS, WorkOrder} from '../../models/work-order.model';
import {Router,ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SortByData} from '../../../fomantic-ui/sortable-th/sortByData.interface';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CriticalityLevelInterface} from '../../../configuration/models/criticalityLevel.interface';
import {CriticalityLevelsService} from '../../../configuration/services/criticalityLevels.service';
import {WorkOrderFilterOptions} from '../../models/work-order.interface';
import {CalendarRangeComponent} from '../../../fomantic-ui/calendar-range/calendar-range.component';
import * as moment from 'moment/moment';
import {PaginatedResult} from '../../../shared/models/paginated-result.interface';
import { CategoryInterface } from 'src/app/shared/models/category.interface';
import { CompaniesService } from 'src/app/companies/companies.service';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workorders-list',
  templateUrl: './work-orders-list.component.html',
  styleUrls: ['./work-orders-list.component.scss'],
  animations: [
    trigger('caretRotation', [
      state('open', style({transform: 'rotate(90deg)'})),
      state('close', style({transform: 'rotate(0deg)'})),
      transition('open <=> close', [
        animate(200)
      ])
    ]),
    trigger('filtersShow', [
      transition(':enter', [
        style({height: 0}),
        animate(200, style({height: '*'}))
      ]),
      transition(':leave', [
        animate(200, style({height: 0}))
      ])
    ])
  ]
})
export class WorkOrdersListComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui container grid';

  workOrders: WorkOrder[];
  paginationSubs: Subscription;

  numItemsPerPageOptions = [
    {value: '10', name: '10'},
    {value: '20', name: '20'},
    {value: '50', name: '50'}
  ];

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  elementsPerPage: FormControl = new FormControl();
  numOfPages: number;
  totalItems: number;
  activeFacilityId: number;
  isDownloadingPDF = false;
  
  categories$: Observable<CategoryInterface[]>;
  criticalityLevels$: Observable<CriticalityLevelInterface[]>;
  workOrdersStatuses: { value: number, name: string }[];

  sortBy$: BehaviorSubject<SortByData> = new BehaviorSubject<SortByData>(null);

  showFilters = false;

  filters$: BehaviorSubject<WorkOrderFilterOptions> = new BehaviorSubject<WorkOrderFilterOptions>(null);
  filtersForm: FormGroup;

  @ViewChild('createRangeSel') createRangeSel: CalendarRangeComponent;
  createdFromFilter: Date;
  createdToFilter: Date;
  @ViewChild('inProgressRangeSel') inProgressRangeSel: CalendarRangeComponent;
  inProgressFromFilter: Date;
  inProgressToFilter: Date;
  @ViewChild('finishRangeSel') finishRangeSel: CalendarRangeComponent;
  finishFromFilter: Date;
  finishToFilter: Date;
  @ViewChild('closeRangeSel') closeRangeSel: CalendarRangeComponent;
  closedFromFilter: Date;
  closedToFilter: Date;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public session: SessionService,
    private workOrderSrv: WorkOrderService,
    private criticalityLevelsService: CriticalityLevelsService,
    private companiesSrv: CompaniesService,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {
    this.filters$.next(router.getCurrentNavigation()?.extras?.state?.filters);
    this.filtersForm = fb.group({
      ids: null,
      problemType: null,
      description: null,
      facilityName: null,
      companyName: null,
      categories: null,
      criticalityLevelIds: null,
      workOrderStateIds: null,
      assignedUser: null
    });
    this.workOrdersStatuses = WORK_ORDER_STATES_OPTIONS(this.translate);
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.workOrdersStatuses = WORK_ORDER_STATES_OPTIONS(this.translate);
    });
    
    this.categories$ = this.companiesSrv.fetchCategories().pipe(
      map(categories => {
        return categories.filter(category => !category.parentCategoryId);
      })
    );

    this.criticalityLevels$ = this.session.activeFacility$.pipe(
      switchMap(facility => {
        this.activeFacilityId = facility.id;
        return this.criticalityLevelsService.fetchAll(facility.id);
      })
    );

    this.paginationSubs = combineLatest([
      this.session.activeFacility$.pipe(skipWhile(facility => !facility)),
      this.page$,
      this.elementsPerPage.valueChanges,
      this.sortBy$,
      this.filters$,
    ]).pipe(
      switchMap(([facility, page, numItems, sort, filters]) => {
        let params = {facilityIds: facility.id};

        if (filters) {
          params = Object.assign(params, filters);
        }
        if (sort) {
          params = Object.assign(params, {
            sortBy: sort.column,
            order: sort.order
          });
        }
        return this.workOrderSrv.fetchPaginated(page || 1, numItems.value, params);
      })
    )
      .subscribe((result: PaginatedResult<WorkOrder>) => {
        if (result.meta?.totalPages && result.meta?.totalPages < this.page$.value) {
          this.page$.next(result.meta.totalPages);
        } else {
          this.workOrders = result.items;
          this.numOfPages = result.meta?.totalPages || 1;
          this.totalItems = result.meta.totalItems;
        }
      });

    this.elementsPerPage.setValue(this.numItemsPerPageOptions[0]);
  }

  ngOnDestroy() {
    this.paginationSubs.unsubscribe();
  }

  numItemsPerPage(): number {
    return parseInt(this.elementsPerPage.value.value);
  }

  goToWorkOrderDetail(selected: WorkOrder) {
    this.router.navigate(['ordenes-de-trabajo', selected.id]);
  }

  getInitOfDay(date: Date): Date {
    return date ? moment(date).startOf('day').toDate() : null;
  }

  getEndOfDay(date: Date): Date {
    return date ? moment(date).endOf('day').toDate() : null;
  }

  handleKeyPress(event){
    if (event.keyCode === 13)
      this.applyFilters();
  }

  applyFilters() {
    const filters: WorkOrderFilterOptions = {
      ids: this.filtersForm.value.ids ? this.filtersForm.value.ids : null,
      problemType: this.filtersForm.value.problemType || null,
      description: this.filtersForm.value.description || null,
      facilityName: this.filtersForm.value.facilityName || null,
      companyName: this.filtersForm.value.companyName || null,
      categoryIds: this.filtersForm.value.categories 
        ? this.filtersForm.value.categories.map(cat => cat.id) 
        : null,
      criticalityLevelIds: this.filtersForm.value.criticalityLevelIds 
        ? this.filtersForm.value.criticalityLevelIds.map(level => level.id) 
        : null,
      workOrderStateIds: this.filtersForm.value.workOrderStateIds 
        ? this.filtersForm.value.workOrderStateIds.map(state => state.value) 
        : null,
      assignedUser: this.filtersForm.value.assignedUser || null,
      fromCreatedAt: this.createdFromFilter ? this.createdFromFilter.toISOString() : null,
      toCreatedAt: this.createdToFilter ? this.createdToFilter.toISOString() : null,
      fromStartedAt: this.inProgressFromFilter ? this.inProgressFromFilter.toISOString() : null,
      toStartedAt: this.inProgressToFilter ? this.inProgressToFilter.toISOString() : null,
      fromFinishedAt: this.finishFromFilter ? this.finishFromFilter.toISOString() : null,
      toFinishedAt: this.finishToFilter ? this.finishToFilter.toISOString() : null,
      fromClosedAt: this.closedFromFilter ? this.closedFromFilter.toISOString() : null,
      toClosedAt: this.closedToFilter ? this.closedToFilter.toISOString() : null
    };

    Object.keys(filters).forEach(key => {
      if (filters[key] === null) {
        delete filters[key];
      }
    });

    this.filters$.next(filters);
  }

  clearFilters() {
    this.filtersForm.reset();
    this.createRangeSel.setStartDate(null);
    this.createRangeSel.setEndDate(null);
    this.inProgressRangeSel.setStartDate(null);
    this.inProgressRangeSel.setEndDate(null);
    this.finishRangeSel.setStartDate(null);
    this.finishRangeSel.setEndDate(null);
    this.closeRangeSel.setStartDate(null);
    this.closeRangeSel.setEndDate(null);

    this.applyFilters();
  }

  downloadPDF(){
    const filters = this.getFilters();
    const queryParams = { ...filters };

    // Check if there are other query parameters besides 'page' and 'limit'
    const hasOtherQueryParams = Object.keys(queryParams).some(key => key !== 'page' && key !== 'limit' && key !== 'facilityIds' && queryParams[key] != null);

    if (!hasOtherQueryParams) {
      if (!queryParams['page']) {
        queryParams['page'] = this.page$.value || 1;
      }
      if (!queryParams['limit']) {
        queryParams['limit'] = this.elementsPerPage.value.value || 10;
      }
    }

    // Remove 'page' and 'limit' if other query parameters exist
    if (hasOtherQueryParams) {
      delete queryParams['page'];
      delete queryParams['limit'];
    }

    this.isDownloadingPDF = true;

    this.workOrderSrv.downloadFilteredPDF(queryParams).subscribe(
      (pdfBlob: Blob) => {
        const blob = new Blob([pdfBlob], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
        const fileName = `work-orders-${date}.pdf`;
        saveAs(blob, fileName);
        window.URL.revokeObjectURL(url);
        this.isDownloadingPDF = false;
      },
      (error) => {
        console.error('Error downloading PDF:', error);
        this.isDownloadingPDF = false;
        // Handle error if necessary
      }
    );
  }

  private getFilters(): { [key: string]: string | number | number[] } {
    const filters: WorkOrderFilterOptions = {
      ids: this.filtersForm.value.ids ? this.filtersForm.value.ids : null,
      problemType: this.filtersForm.value.problemType || null,
      description: this.filtersForm.value.description || null,
      facilityName: this.filtersForm.value.facilityName || null,
      companyName: this.filtersForm.value.companyName || null,
      categoryIds: this.filtersForm.value.categories 
        ? this.filtersForm.value.categories.map(cat => cat.id) 
        : null,
      criticalityLevelIds: this.filtersForm.value.criticalityLevelIds 
        ? this.filtersForm.value.criticalityLevelIds.map(level => level.id) 
        : null,
      workOrderStateIds: this.filtersForm.value.workOrderStateIds 
        ? this.filtersForm.value.workOrderStateIds.map(state => state.value) 
        : null,
      assignedUser: this.filtersForm.value.assignedUser || null,
      fromCreatedAt: this.createdFromFilter ? this.createdFromFilter.toISOString() : null,
      toCreatedAt: this.createdToFilter ? this.createdToFilter.toISOString() : null,
      fromStartedAt: this.inProgressFromFilter ? this.inProgressFromFilter.toISOString() : null,
      toStartedAt: this.inProgressToFilter ? this.inProgressToFilter.toISOString() : null,
      fromFinishedAt: this.finishFromFilter ? this.finishFromFilter.toISOString() : null,
      toFinishedAt: this.finishToFilter ? this.finishToFilter.toISOString() : null,
      fromClosedAt: this.closedFromFilter ? this.closedFromFilter.toISOString() : null,
      toClosedAt: this.closedToFilter ? this.closedToFilter.toISOString() : null,
      facilityIds: [this.activeFacilityId],
    };
  
    return Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== null && filters[key] !== undefined) {
        acc[key] = filters[key];
      }
      return acc;
    }, {});
  }
}
