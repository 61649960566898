import { Component, OnInit } from '@angular/core';
import { ScheduledTaskService } from '../../scheduled-task.service';
import { ScheduledTask } from '../../models/scheduled-task.interface';
import { DateTime } from 'luxon';
import { SessionService } from 'src/app/shared/services/session.service';
import { ScheduledTasksPageFilterService } from '../services/scheduled-tasks-page-filter.service';
import { Observable, combineLatest } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getTaskColor } from '../../shared/scheduled-tasks.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-scheduled-tasks-Scheduled-page',
  templateUrl: './scheduled-tasks-Scheduled-page.component.html',
  styleUrls: ['./scheduled-tasks-Scheduled-page.component.scss']
})
export class ScheduledTasksScheduledPageComponent implements OnInit {
  groupedTasks: { [key: string]: ScheduledTask[] } = {};
  monthYearText$: Observable<string>;
  calendarDate = new Date();
  currentLang: string;
  
  private eventColors = {
    completed: {primary: '#107A43', secondary: '#d7fadd'},
    finishedWithIssue:{ primary: '#c74f42', secondary:'#FEADBA'},
  };

  constructor(
    private session: SessionService,
    private router: Router,
    private tasksService: ScheduledTaskService,
    private filterService: ScheduledTasksPageFilterService,
    private translate: TranslateService,
    ) { 
      this.currentLang = this.translate.currentLang;
    }

  ngOnInit(): void {

    combineLatest([
        this.session.activeFacility$.pipe(skipWhile(f => !f)),
        this.filterService.monthYear$,
        this.filterService.filter$,
        this.filterService.relatedToComponentId$,
      ]).pipe(
        skipWhile(monthYear => !monthYear),
        switchMap(([facility, monthYear, filter, relatedComponentId]) => {

            const from = monthYear.startOf('month').toUTC().toISO({ includeOffset: false });
            const to = monthYear.endOf('month').toUTC().toISO({ includeOffset: false });
            this.filterService.setSelectedView('scheduled') 
            let params = {from, to};
            params = Object.assign(params, filter);
            return this.tasksService.fetchScheduledTasks(params, relatedComponentId);
        }),
        map((scheduledTasks: ScheduledTask[]) => {
            this.groupedTasks = {};
            this.groupTasksByDay(scheduledTasks);
            
            
        })
        ).subscribe();
        
        this.monthYearText$ = this.filterService.monthYear$.pipe(
          map(monthYear => {
            return monthYear.setLocale(this.currentLang).toFormat('MMMM yyyy');
          })
        );

    this.initialiseCalendarMonth();
  }

  initialiseCalendarMonth() {
    this.filterService.filter$.pipe(
      take(1)
    ).subscribe(filter => {
      const filterTo = filter?.to;
      if (filterTo) {
        const toDate = DateTime.fromISO(filterTo);
        this.filterService.setMonthYear(toDate.startOf('month'));
        this.calendarDate = toDate.toJSDate();
      } else {
        this.goToday();
      }
    });

  }

  nextMonth() {
    this.filterService.monthYear$.pipe(
      take(1) // Take the first emitted value and unsubscribe
    ).subscribe(monthYear => {
      const newMonth = monthYear.plus({ months: 1 }).startOf('month');
      this.calendarDate = newMonth.toJSDate();
      this.filterService.setMonthYear(newMonth);
    });
  }

  previousMonth() {
    this.filterService.monthYear$.pipe(
      take(1) // Take the first emitted value and unsubscribe
    ).subscribe(monthYear => {
      const newMonth = monthYear.minus({ months: 1 }).startOf('month');
      this.calendarDate = newMonth.toJSDate();
      this.filterService.setMonthYear(newMonth);
    });
  }

  goToday() {
    const newMonth = DateTime.local().startOf('month');
    this.calendarDate = newMonth.toJSDate();
    this.filterService.setMonthYear(newMonth);
  }

  eventClicked(taskId: number) {
    this.router.navigate(['tareas-programadas', taskId], {state: {originPage: 'scheduled'}});
  }

  groupTasksByDay(tasks: ScheduledTask[]) {
    tasks.forEach(task => {
      const taskDate = DateTime.fromISO(task.createdAt).toFormat('yyyy-MM-dd');
      if (!this.groupedTasks[taskDate]) {
        this.groupedTasks[taskDate] = [] ;
      }
      this.groupedTasks[taskDate].push(task);
    });
  }

  getDay(date: string): string {
    const [year, month, day] = date.split('-');
    return day;
  }
  
  getMonth(date: string): string {
    const [year, month, day] = date.split('-');
    const dateTime = DateTime.fromISO(`${year}-${month}-01`);
    
    // Set the locale based on the current language
    const monthName = dateTime.setLocale(this.currentLang).toFormat('MMMM');
    
    return monthName;
  }
  
  isCurrentDay(date: string): boolean {
    const currentDate = new Date().toISOString().slice(0, 10);
    const taskDate = date.slice(0, 10);
    return currentDate === taskDate;
  }

  getTaskColor(task: ScheduledTask): string {
    return getTaskColor(task).primary;
  }
  
  
}
