import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IncidencesListComponent} from './pages/incidences-list/incidences-list.component';
import {AddIncidenceComponent} from './pages/add-incidence/add-incidence.component';
import {DetailIncidenceComponent} from './pages/detail-incidence/detail-incidence.component';
import {AddWorkOrderComponent} from './pages/add-work-order/add-work-order.component';
import {LayoutComponent} from '../shared/layout/layout.component';
import {DetailIncidenceInfoComponent} from './pages/detail-incidence/detail-incidence-info/detail-incidence-info.component';
import {DetailIncidenceWorkordersComponent} from './pages/detail-incidence/detail-incidence-workorders/detail-incidence-workorders.component';
import {DetailIncidenceChatComponent} from './pages/detail-incidence/detail-incidence-chat/detail-incidence-chat.component';
import {DetailIncidenceFacilityElemsComponent} from './pages/detail-incidence/detail-incidence-facility-elems/detail-incidence-facility-elems.component';
import {DetailIncidenceRatingComponent} from './pages/detail-incidence/detail-incidence-rating/detail-incidence-rating.component';
import {DetailIncidenceDocumentsComponent} from './pages/detail-incidence/detail-incidence-documents/detail-incidence-documents.component';

const routes: Routes = [
  {
    path: 'solicitudes',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: IncidencesListComponent
      },
      {
        path: 'nueva',
        component: AddIncidenceComponent
      },
      {
        path: ':incidenceId',
        component: DetailIncidenceComponent,
        children: [
          {
            path: '',
            redirectTo: 'info',
            pathMatch: 'full'
          },
          {
            path: 'info',
            component: DetailIncidenceInfoComponent
          },
          {
            path: 'ordenes-de-trabajo',
            component: DetailIncidenceWorkordersComponent
          },
          {
            path: 'ordenes-de-trabajo/nueva',
            component: AddWorkOrderComponent
          },
          {
            path: 'chat',
            component: DetailIncidenceChatComponent
          },
          {
            path: 'documentos',
            component: DetailIncidenceDocumentsComponent
          },
          {
            path: 'componentes',
            component: DetailIncidenceFacilityElemsComponent
          },
          {
            path: 'valoracion',
            component: DetailIncidenceRatingComponent
          }
        ]
      },
      {
        path: ':incidenceId/ordenes-de-trabajo/nueva',
        component: AddWorkOrderComponent
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class IncidencesRoutingModule {
}
