import { Component } from "@angular/core";
import { IncidencesService } from "src/app/incidences/incidences.service";

@Component({
    selector: 'app-menu-new-incidence',
    templateUrl: './menu-new-incidence.component.html',
    styleUrls: ['./menu-new-incidence.component.scss'],
  })
  export class MenuNewIncidenceComponent  {

    constructor(
        public incidencesService: IncidencesService,
      ) { }
  }