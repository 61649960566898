import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {filter, map, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import { Facility } from '../../../facilities/models/facility.interface';
import { IncidencesService } from '../../incidences.service';
import { SessionService } from '../../../shared/services/session.service';
import { ToastService } from '../../../shared/services/toast.service';
import { WorkOrderCreateDtoInterface } from '../../../workorders/models/work-order.interface';
import { DetailIncidenceService } from '../detail-incidence/detail-incidence.service';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {CompaniesService} from '../../../companies/companies.service';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {CompanyInterface} from '../../../companies/models/company.interface';
import {ProblemTypesService} from '../../../configuration/services/problem-types.service';
import {ProblemTypeInterface} from '../../../configuration/models/problem-type.interface';
import {CriticalityLevelsService} from '../../../configuration/services/criticalityLevels.service';
import {CriticalityLevelInterface} from '../../../configuration/models/criticalityLevel.interface';
import { TranslateService } from '@ngx-translate/core';
import { ROLES } from 'src/app/shared/models/role.interface';
import { UserInterface } from 'src/app/users/models/user.interface';

@Component({
  selector: 'app-add-work-order',
  templateUrl: './add-work-order.component.html'
})
export class AddWorkOrderComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui grid';

  form: FormGroup;

  activeFacility: Facility;

  categories: CategoryInterface[];
  companies: CompanyInterface[];
  users: UserInterface[];
  problemTypes: ProblemTypeInterface[];
  criticalityLevels: CriticalityLevelInterface[];

  destroy$ = new Subject<boolean>();

  constructor(
    public detailIncidence: DetailIncidenceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public session: SessionService,
    private companySrv: CompaniesService,
    private problemTypeSrv: ProblemTypesService,
    private criticalityLevelsSrv: CriticalityLevelsService,
    private incidencesService: IncidencesService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    this.form = fb.group({
      category: [null, Validators.required],
      company: [null, Validators.required],
      problem: [null, Validators.required],
      criticalityLevel: [null, Validators.required],
      parentWorkOrder: [null],
      assignedUser: [null],
      description: [this.detailIncidence.incidence$.value?.description]
    });
  }

  ngOnInit() {
    this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1)
    ).subscribe(facility => this.activeFacility = facility);

    const changeCategory$ = this.getCategories();
    this.getCompanies(changeCategory$);
    this.getProblemTypes(changeCategory$);
    this.getCriticalityLevels();
    this.getAssignedUsers();
  }

  getCategories(){
    this.companySrv.fetchCategories()
      .pipe(map(categories => categories.filter(category => !category.parentCategoryId)))
      .subscribe(response => this.categories = response);

    return combineLatest([
      this.form.controls.category.valueChanges.pipe(skipWhile(category => !category)),
      this.session.activeFacility$.pipe(skipWhile(facility => !facility))
    ]).pipe(takeUntil(this.destroy$));
  }

  getAssignedUsers(): void{
    combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.form.controls.company.valueChanges
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([facility, company]) => {
        if (company) {
          return this.companySrv.fetchCompanyUsers(
            company.id,
            {
              facilityId: facility.id,
              roleIds: [ROLES.MAINTENANCE_USER, ROLES.MAINTENANCE_ADMIN, ROLES.GLOBAL_SERVICE, ROLES.OWNER]
            });
        } else {
          return of([]);
        }
      })
    ).subscribe(maintenanceUsers => this.users = maintenanceUsers);
  }

  getCompanies(changeCategory$: Observable<[any, Facility]>){
    changeCategory$.pipe(
      switchMap(([category, facility]) => {
        return this.companySrv.fetchAll({facilityId: facility.id, companyTypeId: 2})
          .pipe(
            map((companies: CompanyInterface[]) => {
              return companies.filter(company => {
                return !!company.categories.find(companyCategory => companyCategory.categoryId === category.id);
              });
            })
          );
      })
    ).subscribe(companiesSameCategory => {
      this.companies = companiesSameCategory;
    });
  }

  getProblemTypes(changeCategory$: Observable<[any, Facility]>){
    changeCategory$.pipe(
      switchMap(([category, facility]) => {
        return this.problemTypeSrv.fetchAll({
          facilityId: facility.id,
          categoryId: category.id
        });
      })
    ).subscribe(problems => this.problemTypes = problems);
  }

  getCriticalityLevels(){
    this.session.activeFacility$.pipe(filter(facility => !!facility))
    .pipe(
      takeUntil(this.destroy$),
      switchMap(facility => this.criticalityLevelsSrv.fetchAll(facility.id))
    )
    .subscribe(levels => {
      this.criticalityLevels = levels;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  createWorkOrder() {
    const data: WorkOrderCreateDtoInterface = {
      problemTypeId: this.form.value.problem.id,
      description: this.form.value.description,
      assignedCompanyId: this.form.value.company.id,
      assignedUserId: this.form.value.assignedUser ? this.form.value.assignedUser.id : null,
      categoryId: this.form.value.category.id,
      criticalityLevelId: this.form.value.criticalityLevel.id,
    };
    if (this.form.value.parentWorkOrder) {
      data.parentWorkOrderId = this.form.value.parentWorkOrder.id;
    }

    this.incidencesService.createWorkOrder(this.detailIncidence.incidence$.value.id, this.activeFacility.id, data).subscribe(
      (workorder) => {
        this.detailIncidence.workOrders$.next([...this.detailIncidence.workOrders$.value, workorder]);
        this.router.navigate(['..'], {
          relativeTo: this.route
        }).then(() => {
          this.toastService.showToast({
            type: 'success',
            message: this.translate.instant('WORK_ORDER_CREATED'),
          });
        });
      },
      (error) => {
        this.toastService.showToast({
          type: 'error',
          message: error.error.message || error.error.code,
        });
      }
    );
  }

}
