// menu-messages.component.ts
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessagesService } from 'src/app/messages/services/messages.service';
import { SessionService } from '../../services/session.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu-messages',
  templateUrl: './menu-messages.component.html',
  styleUrls: ['./menu-messages.component.scss'],
})
export class MenuMessagesComponent implements OnInit, OnDestroy {
  unreadMessagesCount : number;
  destroy$ = new Subject<boolean>();
  
  constructor(
    private sessionService: SessionService,
    private messagesService: MessagesService
  ) { }

  ngOnInit(): void {
    // Subscribe to changes in the active facility
    this.sessionService.activeFacility$
      .pipe(
        filter((facility) => !!facility),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.updateUnreadMessagesCount();
      });

    // Initial call when the component is initialized
    this.updateUnreadMessagesCount();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private updateUnreadMessagesCount(): void {
    // Query unread messages count
    this.messagesService.fetchUnreadMessagesCount().subscribe((count) => {
      this.unreadMessagesCount = count;
    });
  }
}
