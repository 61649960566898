import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ProblemTypeCreateDtoInterface, ProblemTypeInterface} from '../../models/problem-type.interface';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {filter, map, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {CompaniesService} from '../../../companies/companies.service';
import {ProblemTypesService} from '../../services/problem-types.service';
import {BehaviorSubject, combineLatest, NEVER, of, Subject, Subscription} from 'rxjs';
import {Facility} from '../../../facilities/models/facility.interface';
import {SessionService} from '../../../shared/services/session.service';
import {ToastService} from '../../../shared/services/toast.service';
import { ActionTypes } from 'src/app/shared/models/role.interface';
import { RolesService } from 'src/app/shared/services/roles.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-problem-types-tab',
  styleUrls: ['./problem-types-tab.component.scss'],
  templateUrl: './problem-types-tab.component.html'
})
export class ProblemTypesTabComponent implements OnInit, OnDestroy {

  activeFacility: Facility;
  destroy$: Subject<boolean> = new Subject<boolean>();
  problemsSubs: Subscription;
  problemTypes: ProblemTypeInterface[];
  categories: CategoryInterface[];

  filterCategory: BehaviorSubject<CategoryInterface> = new BehaviorSubject<CategoryInterface>(null);
  numItems: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  page: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  totalPages: number;
  totalItems: number;


  newProblemForm: FormGroup;

  @ViewChild('fileInput') fileInput: ElementRef;
  loadingFile = false;
  showErrorsModal = false;
  uploadErrors: {row: number, message: string}[];

  constructor(
    private sessionService: SessionService,
    private companySrv: CompaniesService,
    private problemsSrv: ProblemTypesService,
    private toastService: ToastService,
    private rolesService: RolesService,
    private router: Router,
    private translate : TranslateService,
  ) {

    this.newProblemForm = new FormGroup({
      category: new FormControl(null, Validators.required),
      text: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    // Use switchMap to handle activeFacility change
    this.sessionService.activeFacility$
    .pipe(
      filter((facility) => !!facility),
      switchMap((facility) => {
        this.activeFacility = facility;
        // Check userRole before fetching problem types
        if (!this.rolesService.can(ActionTypes.ManageProblemTypes)) {
          // Use navigateByUrl to ensure synchronous navigation
          this.router.navigateByUrl("/configuracion/notificaciones");
          return NEVER; // Stop further execution if role is not authorized
        }
        return of(facility);
      }),
      takeUntil(this.destroy$)
    )
    .subscribe(() => {
      this.getProblemTypes();
    });
    
  }

   getProblemTypes() {
    // Get only parent categories
    this.companySrv.fetchCategories().pipe(map(categories => {
      return categories.filter(category => category.parentCategoryId === null);
    })).subscribe((categories: CategoryInterface[]) => {
      this.categories = categories;
    });

    this.problemsSubs = combineLatest([
      this.filterCategory.pipe(filter(cat => !!cat)),
      this.numItems.pipe(filter(value => !!value)),
      this.page
    ]).subscribe(([category, numItems, page]) => {
      let queryParams = {facilityId: this.activeFacility.id};
      if (category.id !== -1) {
        queryParams = Object.assign(queryParams, {categoryId: category.id});
      }
      this.problemsSrv.fetchPaginated(page, numItems, queryParams).subscribe(result => {
        if (this.page.value > result.meta.totalPages && result.meta.totalPages !== 0) {
          this.page.next(result.meta.totalPages);
        }

        this.problemTypes = result.items;
        this.totalPages = result.meta.totalPages ? result.meta.totalPages : 1;
        this.totalItems = result.meta.totalItems;
      });
    });

    this.filterCategory.pipe(filter(cat => !!cat)).subscribe(value => {
      if (value.id !== -1) {
        this.newProblemForm.get('category').setValue(value);
      }
    });
  }

  ngOnDestroy() {
    this.problemsSubs.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  createNewProblemType() {
    const data: ProblemTypeCreateDtoInterface = {
      categoryId: this.newProblemForm.value.category.id,
      text: this.newProblemForm.value.text
    };

    this.problemsSrv.create(data, {facilityId: this.activeFacility.id}).subscribe(result => {
      this.problemTypes = [...this.problemTypes, result];
      this.page.next(this.totalPages);
      this.newProblemForm.get('text').reset();
    });
  }

  deleteProblem(problem: ProblemTypeInterface) {
    this.problemsSrv.delete(problem.id, {facilityId: this.activeFacility.id})
      .subscribe(
        () => {
          this.problemTypes = this.problemTypes.filter(p => p.id !== problem.id);
        },
        () => {
          this.toastService.showToast({
            type: 'error',
            message: this.translate.instant("SETTINGS.PROBLEM_TYPE_ERROR"),
          });
        }
      );
  }

  selectedFile() {
    const file = this.fileInput.nativeElement.files[0];
    this.loadingFile = true;
    this.sessionService.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.problemsSrv.uploadCSV(file, {facilityId: facility.id});
      })
    ).subscribe(
      (result: any) => {
        if (result.errors) {
          this.uploadErrors = result.errors;
          this.showErrorsModal = true;
        }
      },
      error => {},
      () => {
        this.loadingFile = false;
        this.fileInput.nativeElement.value = null;
      }
    );
  }
}
