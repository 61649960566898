import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fui-pagination-menu',
  templateUrl: './pagination-menu.component.html'
})
export class PaginationMenuComponent {

  @Input() page: number;
  @Input() numOfPages: number;
  @Input() maxVisibleOptions = 5;

  @Output() pageSelected: EventEmitter<number> = new EventEmitter<number>();

  @HostBinding('class') hostClasses = 'ui mini pagination menu';
}

