<form class="ui form">
  <div class="fields" style="display: flex; justify-content: space-between">
    <div class="four wide field">
      <label>{{ 'SETTINGS.CATEGORY' | translate }}</label>
      <fui-dropdown-two
        selectorId="categoryFilter"
        [options]="categories"
        [search]="true"
        [nullOption]="{id: -1, name: 'SETTINGS.ALL' | translate}"
        [formControl]="filterCategory"
        valueProperty="id"
      ></fui-dropdown-two>
    </div>
    <div class="three wide field">
      <label>{{ 'SETTINGS.ITEMS_PER_PAGE' | translate }}</label>
      <fui-dropdown-two
        selectorId="numItemsSel"
        [options]="numItemOptions"
        [formControl]="numItems"
      ></fui-dropdown-two>
    </div>
  </div>
</form>
<table class="ui small selectable table">
  <thead>
    <tr>
      <th>{{ 'SETTINGS.CATEGORY' | translate }}</th>
      <th>{{ 'SETTINGS.PROBLEM_TYPE' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <tr [class.active]="selectedProblem === problem" (click)="clickProblem(problem)" *ngFor="let problem of problemTypes">
      <td>{{problem.category.name}}</td>
      <td>{{problem.text}}</td>
    </tr>
  </tbody>
  <tfoot>
    <tr>
      <th colspan="2">
        <fui-pagination-menu
          [page]="page"
          [numOfPages]="totalPages"
          (pageSelected)="selectedPage.emit($event)"
        ></fui-pagination-menu>
        <div (click)="deleteSelected()" class="ui right floated small labeled icon button" [class.disabled]="!selectedProblem">
          <i class="trash icon"></i>
          {{ 'SETTINGS.DELETE' | translate }}
        </div>
      </th>
    </tr>
    <tr>
      <th colspan="2">
        <app-pagination-num-items-info
          [itemsName]="'SETTINGS.PROBLEM_TYPES' | translate"
          [page]="page"
          [numItemsPerPage]="numItems.value.value"
          [totalItems]="totalItems"
        ></app-pagination-num-items-info>
      </th>
    </tr>
  </tfoot>
</table>
