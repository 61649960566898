import {Component, HostBinding, OnInit} from '@angular/core';
import {MessagesService} from '../../services/messages.service';
import {Observable, Subject} from 'rxjs';
import {Chat} from '../../models/chat.interface';
import {SessionService} from '../../../shared/services/session.service';
import {filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import * as moment from 'moment/moment';
import {INCIDENCE_TYPES, INCIDENCE_TYPES_LITERALS} from '../../../incidences/models/incidence.interface';
import {IncidencesService} from '../../../incidences/incidences.service';
import {ChatMessageInterface} from '../../../shared/models/chat-message.interface';
import { ResponsiveService } from 'src/app/shared/services/responsive.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-messages-page',
  templateUrl: './messages-page.component.html',
  styleUrls: ['./messages-page.component.scss']
})
export class MessagesPageComponent implements OnInit {

  @HostBinding('class') hostClasses = 'ui container grid';
  public isMobile: boolean;
  loading = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private session: SessionService,
    private messagesSrv: MessagesService,
    private incidenceSrv: IncidencesService,
    private responsiveService: ResponsiveService,
    private translate: TranslateService,
  ) {}

  chats: Observable<Chat[]>;
  messages: ChatMessageInterface[];
  selected: number;
  messagesIncidences: Chat[];

  ngOnInit(): void {
    this.chats = this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      tap(() => { this.loading = true; }),
      switchMap(async facility => {
        this.messagesIncidences = await this.messagesSrv.fetchChatsPromise(facility.id);
        this.loading = false;
        return this.messagesIncidences;
      })
    );

    this.responsiveService.isMobile$
    .pipe(takeUntil(this.destroy$))
    .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  formatDate(isoString: string): string {
    moment.locale('es');
    return moment(isoString).format('LLL');
  }

  incidenceType(type: string) {
    return this.translate.instant(`REQUESTS_TYPE.${INCIDENCE_TYPES_LITERALS[INCIDENCE_TYPES[type]] || 'NO_TYPE'}`);
  }

  selectChat(chat: Chat) {
    this.selected = chat.id;
    chat.unreadChats = null;
    this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1),
      switchMap(facility => {
        return this.incidenceSrv.fetchChatMessages(chat.id, facility.id);
      })
    ).subscribe(messages => this.messages = messages);
  }

  sendMessage(message: string) {
    this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      take(1),
      switchMap(facility => {
        return this.incidenceSrv.createChatMessage(this.selected, facility.id, message);
      })
    ).subscribe(newMessage => {
      this.messages = [...this.messages, newMessage];
    });
  }

  goBackToChatSelector(){
    this.messages = null;
    this.selected = null;
  }
}
