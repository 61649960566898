import { Component, OnInit } from "@angular/core";
import { Facility } from "src/app/facilities/models/facility.interface";
import { SessionService } from "src/app/shared/services/session.service";

@Component({
    selector: 'tree-page',
    templateUrl: './tree-page.component.html',
    styleUrls: ['./tree-page.component.scss']
  })
export class TreePageComponent implements OnInit {
    facilities: Facility[];
  
    constructor(
      private sessionService: SessionService,
      ) {}
  
    ngOnInit() {
      this.getFacilities();
    }

    getFacilities(){
      this.sessionService.activeFacility$
      .subscribe(activeFacility => {
        this.facilities = [activeFacility];
      });
    }
  }