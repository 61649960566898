import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { filter, skipWhile, switchMap, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { Facility } from '../../../facilities/models/facility.interface';
import { DateTime } from 'luxon';
import { CalendarRangeComponent } from '../../../fomantic-ui/calendar-range/calendar-range.component';
import { STATISTIC_TYPE, StatisticInterface } from '../../models/statistic.interface';
import { ChartDataSets } from 'chart.js';
import { ROLES } from '../../../shared/models/role.interface';
import { Router } from '@angular/router';
import { INCIDENCE_STATES, IncidencesFilterOptions } from '../../../incidences/models/incidence.interface';
import { WORK_ORDER_STATES } from '../../../workorders/models/work-order.model';
import { WorkOrderFilterOptions } from '../../../workorders/models/work-order.interface';
import { IUserLogged } from '../../../shared/models/i-user-logged';
import { DashboardService } from '../../services/dashboard.service';
import { ComponentFilterOptions } from 'src/app/facilities/models/facility-element.interface';
import getSymbolFromCurrency from 'currency-symbol-map';
import { SCHEDULED_TASKS_STATUS } from '../../../scheduled-tasks/models/scheduled-task.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit, AfterViewInit, OnDestroy {

  static STATS_LABELS: any = {};

  @HostBinding('class') hostClass = 'ui grid';
  @ViewChild(CalendarRangeComponent) calendar;

  activeFacility: Facility;
  userRole: ROLES;
  loggedUser: IUserLogged;

  changesSubs: Subscription;

  stats: { [statId: string]: number | ChartDataSets };

  incidenceChart: ChartDataSets[];
  incidenceCostChart: ChartDataSets[];
  petitionCostChart: ChartDataSets[];
  ordersCountChart: ChartDataSets[];
  ordersTimeChart: ChartDataSets[];
  scheduledTasksCountChart: ChartDataSets[];
  scheduledTasksFinishedTimeChart: ChartDataSets[];

  datesRange$: BehaviorSubject<{ start: Date, end: Date }> = new BehaviorSubject<{ start: Date; end: Date }>(null);

  destroy$: Subject<boolean> = new Subject<boolean>();

  private lastVisit: DateTime;
  private currentDate: DateTime;
  public currencySymbol: string;

  constructor(
    private router: Router,
    public session: SessionService,
    private dashboardSrv: DashboardService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.translateLabels();

    this.session.userRole$.pipe(
      filter(role => role !== undefined && role !== null),
      takeUntil(this.destroy$)
    ).subscribe(role => {
      this.userRole = role;
    });

    this.session.loggedUser$.pipe(
      filter(user => !!user),
      take(1)
    ).subscribe(user => this.loggedUser = user);

    this.session.userConfig$
      .pipe(take(1))
      .subscribe(config => {
        
        //this.lastVisit = config?.datesRange?.start ? DateTime.fromJSDate(new Date(config.datesRange.start)) : DateTime.now().startOf('day');
        this.lastVisit = DateTime.fromJSDate(new Date(config.lastVisit));
        this.currentDate = DateTime.now();

        if (this.shouldUpdateRange()) {
          const firstDayOfSameMonthLastYear = this.currentDate.minus({ years: 1 }).startOf('month').toJSDate();
          const lastDayOfCurrentMonth = this.currentDate.endOf('month').toJSDate();
          const startMinusAYear = DateTime.fromJSDate(firstDayOfSameMonthLastYear).minus({years: 1}).toJSDate();
          const endMinusAYear = DateTime.fromJSDate(lastDayOfCurrentMonth).minus({years: 1}).toJSDate();
          const range = { start: firstDayOfSameMonthLastYear, end: lastDayOfCurrentMonth };
          const compareRange = { start: startMinusAYear, end: endMinusAYear};
          this.datesRange$.next(range);
          this.session.setUserConfig({ lastVisit: this.currentDate.toJSDate(), datesRange: range, reportsDateRanges: {dateRange:range , compareDateRange: compareRange} });
        } else {
          //this.datesRange$.next(config.datesRange);
          this.datesRange$.next({
            start: new Date(config.datesRange.start),
            end: new Date(config.datesRange.end)
          });
        }
      });


    this.changesSubs = combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.datesRange$.pipe(skipWhile(range => !range?.start || !range.end))
    ]).pipe(
      switchMap(([facility, datesRange]) => {
        this.activeFacility = facility;
        this.currencySymbol = facility.currencyUnit || 'EUR';
        return this.dashboardSrv.getData(
          facility.id,
          DateTime.fromJSDate(datesRange.start).toFormat('yyyy-MM-dd'),
          DateTime.fromJSDate(datesRange.end).toFormat('yyyy-MM-dd')
        );
      })
    ).subscribe(result => {
      this.stats = this.parseData(result);
      this.updateCharts();
    });
  }

  ngAfterViewInit() {
    this.datesRange$.pipe(
      skipWhile(r => !r),
      take(1)
    ).subscribe(range => {
      this.calendar.setStartDate(range.start, true, false);
      this.calendar.setEndDate(range.end, true, false);
    });
  }

  ngOnDestroy() {
    this.changesSubs.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private shouldUpdateRange(): boolean {
    return (
      !this.currentDate.hasSame(this.lastVisit, 'day') // If it's a new day
    );
  }

  selectedStartDate(date: Date) {
    const newRange = {
      start: date,
      end: this.datesRange$.value?.end
    };
    this.datesRange$.next(newRange);
    this.session.setUserConfig({datesRange: newRange});
  }

  selectedEndDate(date: Date) {
    const newRange = {
      start: this.datesRange$.value?.start,
      end: date
    };
    this.datesRange$.next(newRange);
    this.session.setUserConfig({datesRange: newRange});
  }

  getValueFromStat(stat: StatisticInterface) {
    return stat.value[0].value;
  }

  getChartDataSetFromStat(stat: StatisticInterface, title: string): ChartDataSets {
    const data = [];
    stat.value.forEach(statValue => {
      let date = null;
      if (statValue.day) {
        date = DateTime.fromFormat(statValue.day, 'yyyy-MM-dd').toJSDate();
      } else if (statValue.week) {
        const [year, week] = statValue.week.split('-');
        // Calculate the start of the week
        const isoWeekStart = DateTime.fromObject({ year: parseInt(year), month: 1, day: 1 })
            .plus({ weeks: parseInt(week) - 1 }) // Subtract 1 to account for Luxon's 1-based week numbering
            .startOf('week');

        date = isoWeekStart.toJSDate();

      } else if (statValue.month) {
        date = DateTime.fromFormat(statValue.month, 'yyyy-MM').toJSDate();
      } else if (statValue.year) {
        date = DateTime.fromFormat(statValue.year, 'yyyy').toJSDate();
      }
      
      if (date) {
        data.push({ x: date, y: statValue.value });
      }
    });
    return {data, label: this.translate.instant(title)};
  }

  parseData(data: any[]): { [key: string]: number | ChartDataSets } {
    const stats = {};

    data.forEach((rawStat: StatisticInterface) => {
      if (rawStat.type === STATISTIC_TYPE.SINGLE) {
        stats[rawStat.id] = this.getValueFromStat(rawStat);
      } else {
        stats[rawStat.id] = this.getChartDataSetFromStat(rawStat, DashboardPageComponent.STATS_LABELS[rawStat.id]);
      }
    });

    return stats;
  }

  updateCharts(): void {
    if (this.stats.countTypeIncidences && this.stats.countTypePetitions) {
      this.incidenceChart = [
        this.stats.countTypeIncidences as ChartDataSets, 
        this.stats.countTypePetitions as ChartDataSets,
      ];
    }
    if (this.stats.incidenceCost && this.stats.petitionCost) {
      this.incidenceCostChart = [
        this.stats.incidenceCost as ChartDataSets, 
        this.stats.petitionCost as ChartDataSets,
      ];
    }
    if (this.stats.ordersStartedCount && this.stats.ordersClosedCount) {
      this.ordersCountChart = [
        this.stats.ordersStartedCount as ChartDataSets, 
        this.stats.ordersClosedCount as ChartDataSets,
      ];
    }
    if (this.stats.ordersTime) {
      this.ordersTimeChart = [this.stats.ordersTime as ChartDataSets];
    }
    if (this.stats.scheduledTasksFinishedCount && this.stats.scheduledTasksCreatedCount) {
      this.scheduledTasksCountChart = [
        this.stats.scheduledTasksCreatedCount as ChartDataSets,
        this.stats.scheduledTasksFinishedCount as ChartDataSets, 
      ];
    }
    if (this.stats.scheduledTasksFinishedTime) {
      this.scheduledTasksFinishedTimeChart = [this.stats.scheduledTasksFinishedTime as ChartDataSets];
    }
  }

  areAnyRatePctStats(stats: { [statId: string]: number | ChartDataSets }) {
    if (stats) {
      const ids = ['pctScheduledTaskVsCorrective', 'pctCorrectiveVsScheduledTask'
      , 'pctScheduledTaskFinishedVsScheduled', 'pctCorrectiveClosedVsStarted'
    ,'avgTimeScheduledTasks','avgTimeCorrective'];
      return Object.keys(stats).some(key => ids.includes(key));
    }
    return false;
  }

  areAnyScheduledValueStats(stats: { [statId: string]: number | ChartDataSets }) {
    if (stats) {
      const ids = ['countScheduledTasksFinished', 'countScheduledTasks'
      , 'hoursScheduledTasks', 'pctCorrectiveClosedVsStarted'
      ];
      return Object.keys(stats).some(key => ids.includes(key));
    }
    return false;
  }

  areAnyIncidencesValueStats(stats: { [statId: string]: number | ChartDataSets }) {
    if (stats) {
      const ids = ['unstartedIncidences', 'unassignedIncidences', 'unclosedIncidences', 'unfinishedIncidences'];
      return Object.keys(stats).some(key => ids.includes(key));
    }
  }

  areAnyWorkOrderValueStats(stats: { [statId: string]: number | ChartDataSets }): boolean {
    if (stats) {
      const ids = ['waitingOrders', 'inProgressOrders', 'ordersOverTime'
      , 'unassignedOrders', 'unfinishedOrders', 'needCloseOrders'
    ,'countCorrectiveClosed','costCorrective'];
      return Object.keys(stats).some(key => ids.includes(key));
    }
    return false;
  }

  areAnyComponentsValueStats(stats: { [statId: string]: number | ChartDataSets }){
    if (stats) {
      const ids = ['componentsExpiredIn1Month', 'componentsExpiredThisYear', 'componentsExpiredIn12Months'];
      return Object.keys(stats).some(key => ids.includes(key));
    }
    return false;
  }

  goToIncidencesFilteredByState(state: string) {
    let filters: IncidencesFilterOptions = {
      fromCreatedAt: this.datesRange$.value.start.toISOString(),
      toCreatedAt: this.datesRange$.value.end.toISOString()
    };

    let incidenceStates: INCIDENCE_STATES[];
    switch (state) {
      case 'myActive':
        incidenceStates = [
          INCIDENCE_STATES.CREATED,
          INCIDENCE_STATES.OPENED,
          INCIDENCE_STATES.ASSIGNED,
          INCIDENCE_STATES.FINISHED
        ];
        filters.creator = this.loggedUser.email;
        break;
      case 'myUnreviewed':
        incidenceStates = [
          INCIDENCE_STATES.FINISHED,
          INCIDENCE_STATES.CLOSED
        ];
        filters.reviewed = false;
        filters.creator = this.loggedUser.email;
        break;
      case 'unstarted':
        incidenceStates = [INCIDENCE_STATES.CREATED];
        break;
      case 'unassigned':
        incidenceStates = [INCIDENCE_STATES.OPENED];
        break;
      case 'unfinished':
        incidenceStates = [INCIDENCE_STATES.ASSIGNED];
        break;
      case 'unclosed':
        incidenceStates = [INCIDENCE_STATES.FINISHED];
        break;
    }

    filters = Object.assign(filters, { statusIds: incidenceStates });

    this.router.navigate(['solicitudes'], {
      state: { filters }
    });

  }

  goToWorkOrdersFilteredByState(state: string) {
    const filters: WorkOrderFilterOptions = {};

    switch (state) {
      case 'waiting':
        filters.workOrderStateIds = [WORK_ORDER_STATES.WAITING];
        filters.fromCreatedAt = this.datesRange$.value.start.toISOString();
        filters.toCreatedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'inProgress':
        filters.workOrderStateIds = [WORK_ORDER_STATES.IN_PROGRESS];
        filters.fromCreatedAt = this.datesRange$.value.start.toISOString();
        filters.toCreatedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'unassigned':
        filters.assignedUser = null;
        filters.workOrderStateIds = [WORK_ORDER_STATES.IN_PROGRESS];
        filters.fromStartedAt = this.datesRange$.value.start.toISOString();
        filters.toStartedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'unfinished':
        filters.workOrderStateIds = [WORK_ORDER_STATES.IN_PROGRESS];
        filters.fromStartedAt = this.datesRange$.value.start.toISOString();
        filters.toStartedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'needCloseOrders':
        filters.workOrderStateIds = [WORK_ORDER_STATES.FINISHED];
        filters.fromStartedAt = this.datesRange$.value.start.toISOString();
        filters.toStartedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'expired':
        filters.expired = true;
        filters.fromStartedAt = this.datesRange$.value.start.toISOString();
        filters.toStartedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'created':
        filters.fromCreatedAt = this.datesRange$.value.start.toISOString();
        filters.toCreatedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'started':
        filters.fromStartedAt = this.datesRange$.value.start.toISOString();
        filters.toStartedAt = this.datesRange$.value.end.toISOString();
        break;
      case 'closed':
          filters.fromClosedAt = this.datesRange$.value.start.toISOString();
          filters.toClosedAt = this.datesRange$.value.end.toISOString();
          break;
    }
    
    this.router.navigate(['ordenes-de-trabajo'], {
      state: {
        filters
      }
    });
  }

  goToScheduledTasksFilteredByState(state: string) {
    let filters: any = {};
    filters.from = this.datesRange$.value.start.toISOString();
    filters.to = this.datesRange$.value.end.toISOString();
    filters.view = 'calendar'

    switch (state) {
      case 'finished':
        filters.statusIds = [SCHEDULED_TASKS_STATUS.FINISHED, SCHEDULED_TASKS_STATUS.FINISHED_WITH_ISSUES];
        break;
      case 'toDo':
        filters.statusIds = [SCHEDULED_TASKS_STATUS.NOT_STARTED];
        break;
    }

    this.router.navigate(['tareas-programadas'], {
      queryParams: filters
    });
  }

  goToComponentsExpiredNextMonths(state: string){
    const filters: ComponentFilterOptions = {
    };
    
    switch (state) {
      case '1':
        filters.expired = 1;
        break;
      case 'thisYear':
        filters.expired = 'thisYear';
        break;
      case '12':
        filters.expired = 12;
        break;
    }
    
    this.router.navigate(['componentes'], {
      state: {
        filters
      }
    });
  }

  highlightStat(stat) {
    switch (stat) {
      case 'unstartedIncidences':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN].includes(this.userRole) && (this.stats?.unstartedIncidences as number > 0);
      case 'unassignedIncidences':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole) && this.stats?.unassignedIncidences as number > 0;
      case 'unfinishedIncidences':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole) && this.stats?.unfinishedIncidences as number > 0;
      case 'unclosedIncidences':
        return [ROLES.OWNER].includes(this.userRole) && this.stats?.unclosedIncidences as number > 0;
      case 'myUnreviewed':
        return this.stats?.myUnreviewedIncidences as number > 0;
      case 'waitingOrders':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole) && this.stats?.waitingOrders as number > 0;
      case 'unassignedOrders':
        return [ROLES.MAINTENANCE_ADMIN].includes(this.userRole) && this.stats?.unassignedOrders as number > 0;
      case 'unfinishedOrders':
        return [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole) && this.stats?.unfinishedOrders as number > 0;
      case 'needCloseOrders':
        return [ROLES.OWNER, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole) && this.stats?.needCloseOrders as number > 0;
      case 'ordersOverTime':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole) && this.stats?.ordersOverTime as number > 0;
      case 'componentsExpiredIn12Months':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole) && this.stats?.componentsExpiredIn12Months as number > 0;
      case 'componentsExpiredThisYear':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole) && this.stats?.componentsExpiredThisYear as number > 0;
      case 'componentsExpiredIn1Month':
        return [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole) && this.stats?.componentsExpiredIn1Month as number > 0;
      default:
        return false;
    }
  }

  formatCost(value: number | ChartDataSets, currencySymbol: string): string {
    value = Number(value);
    if (typeof value === 'number') {
      const absValue = Math.abs(value);
      let formattedString: string;
      if (absValue >= 1000000) {
          formattedString = `${(absValue / 1000000).toFixed(2)} <span class="unit">mill</span> <span class="currency">${getSymbolFromCurrency(currencySymbol)}</span>`;
      } else if (absValue >= 1000) {
          formattedString = `${(absValue / 1000).toFixed(2)} <span class="unit">mil</span> <span class="currency">${getSymbolFromCurrency(currencySymbol)}</span>`;
      } else {
          formattedString = `${value.toFixed(2)} <span class="currency">${getSymbolFromCurrency(currencySymbol)}</span>`;
      }
      return formattedString;
    }
  }

  private translateLabels() {
    this.translate.get('STATS_LABELS').subscribe(translations => {
      DashboardPageComponent.STATS_LABELS = {
        countTypeIncidences: translations.COUNT_TYPE_INCIDENCES,
        countTypePetitions: translations.COUNT_TYPE_PETITIONS,
        ordersCount: translations.ORDERS_COUNT,
        ordersStartedCount: translations.ORDERS_STARTED_COUNT,
        ordersClosedCount: translations.ORDERS_CLOSED_COUNT,
        ordersTime: translations.ORDERS_TIME,
        incidenceCost: translations.INCIDENCE_COST,
        petitionCost: translations.PETITION_COST,
        scheduledTasksFinishedCount: translations.SCHEDULED_TASKS_FINISHED_COUNT,
        scheduledTasksFinishedTime: translations.SCHEDULED_TASKS_FINISHED_TIME,
        scheduledTasksCreatedCount: translations.SCHEDULED_TASKS_CREATED_COUNT,
      };
    });
  }
}
