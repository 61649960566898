<table class="ui small compact table">
  <thead>
  <tr>
    <th>{{ 'CODE' | translate }}</th>
    <th>{{ 'NAME' | translate }}</th>
    <th>{{ 'TYPE' | translate }}</th>
    <th>{{ 'SPACE' | translate }}</th>
    <th>{{ 'FLOOR' | translate }}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let component of components">
    <td>{{component.assetIdentifier}}</td>
    <td>{{component.name}}</td>
    <td>{{component.type.name}}</td>
    <td>{{component.space.name}}</td>
    <td>{{component.space.floor.name}}</td>
  </tr>
  </tbody>
  <tfoot>
  <tr>
    <th colspan="5">
      <ng-content select="fui-pagination-menu"></ng-content>
    </th>
  </tr>
  <tr>
    <th colspan="5">
      <ng-content select="[slot=pagination]"></ng-content>
    </th>
  </tr>
  </tfoot>
</table>
