
import { 
    ReportCostByProblemTypeDTO,
    ReportCostByProblemTypeReg,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessCostByProblemType extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
        this.setColumnsProblemType().then(columns => {
          this.reports.tableDataParams = columns;
        });
    }
    
    getData(){
        const {labels: problemType, dataA : materialDataPT, dataB : HourDataPT, 
            dataA2 : materialDataPT2, dataB2 : HourDataPT2
        } = this.getCostByProblemTypeDataSets(this.reportGrossData as ReportCostByProblemTypeDTO);
          const dataSetsCostProblemType = [{data: materialDataPT, label: this.translate.instant("REPORTS.MATERIALS_LABEL_1"), stack: 'One date', order: 1},
                            {data: HourDataPT, label: this.translate.instant("REPORTS.TIME_LABEL_1"), stack: 'One date', order: 1}]
          if (materialDataPT2.length > 0) {
            dataSetsCostProblemType.push({data: materialDataPT2, label: this.translate.instant("REPORTS.MATERIALS_LABEL_2"), stack: 'Other date', order: 1})
            dataSetsCostProblemType.push({data: HourDataPT2, label: this.translate.instant("REPORTS.TIME_LABEL_2"), stack: 'Other date', order: 2})
          }
          this.reports.costByProblemType = {
            labels: problemType,
            dataSets: dataSetsCostProblemType
          };

        //TABLE FOR REPORT_ID.COST_BY_PROBLEM_TYPE:
        this.processTableData('problemType');
          this.reports.showIncidenceStatusFilter = false;
 
    }
    private getCostByProblemTypeDataSets(costsByProblemType: ReportCostByProblemTypeDTO) {
    
        let labels = [];
        const dataA = [];
        const dataB = [];
        const dataA2 = [];
        const dataB2 = [];
        costsByProblemType.value.forEach((reg: ReportCostByProblemTypeReg) => {
          labels.push(reg.problemType);
          dataA.push(reg.materialCost);
          dataB.push(reg.timeCost);
        });
        if (costsByProblemType.value2) {
          costsByProblemType.value2.forEach((reg: ReportCostByProblemTypeReg) => {
            labels.push(reg.problemType);
            dataA2.push(reg.materialCost);
            dataB2.push(reg.timeCost);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataB, dataA2, dataB2};
      }
      private async setColumnsProblemType(): Promise<any[]> {
        return this.translate.get([
          "REPORTS.PROBLEM_TYPE",
          "REPORTS.WORK_ORDERS",
          "REPORTS.HOURS",
          "REPORTS.MATERIAL_COST",
          "REPORTS.LABOR",
          "REPORTS.TOTAL_COST"
        ]).toPromise().then(translations => {
      return [
          {
            name: translations["REPORTS.PROBLEM_TYPE"],
            key: 'problemType'
          },
          {
            name: translations["REPORTS.WORK_ORDERS"],
            key: 'countWorkOrders',
            type: 'number',
          },
          {
            name: translations["REPORTS.HOURS"],
            key: 'hours',
            type: 'number'
          },
          {
            name: translations["REPORTS.MATERIAL_COST"],
            key: 'materialCost',
            type: 'currency'
          },
          {
            name: translations["REPORTS.LABOR"],
            key: 'timeCost',
            type: 'currency'
          },
          {
            name: translations["REPORTS.TOTAL_COST"],
            key: 'totalCost',
            type: 'currency'
          },
        ];
    }
  )};
}