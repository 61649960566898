import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ButtonModule} from './modules/button/button.module';
import {DropdownModule} from './modules/dropdown/dropdown.module';
import {FormModule} from './modules/form/form.module';
import {IconModule} from './modules/icon/icon.module';
import {ModalModule} from './modules/modal/modal.module';
import {SpinnerModule} from './modules/spinner/spinner.module';
import {CheckboxModule} from './modules/checkbox/checkbox.module';
import {StepperModule} from './modules/stepper/stepper.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    ButtonModule,
    CheckboxModule,
    DropdownModule,
    FormModule,
    IconModule,
    ModalModule,
    SpinnerModule,
    StepperModule
  ],
  exports: [
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    FormModule,
    IconModule,
    ModalModule,
    SpinnerModule,
    StepperModule
  ]
})
export class NgProtoUiModule {
}
