<div *ngIf="(detailIncidence.incidence$ | async)?.isOpen()" 
class="field-description-container sixteen wide column">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'NEXT_STEP_OPEN' | translate }}
  </p>
</div>

<div *ngIf="(detailIncidence.incidence$ | async)?.isAssigned() && !canBeFinished()" 
  class="field-description-container sixteen wide column">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'NEXT_STEP_ASSIGNED_NOT_FINISHED' | translate }}
  </p>
</div>

<div *ngIf="(detailIncidence.incidence$ | async)?.isAssigned() && canBeFinished()" 
class="field-description-container sixteen wide column">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'NEXT_STEP_ASSIGNED_CAN_BE_FINISHED' | translate }}
  </p>
</div>

<div *ngIf="(detailIncidence.incidence$ | async)?.isFinished() && canBeClosed()" 
class="field-description-container sixteen wide column">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'NEXT_STEP_FINISHED_CAN_BE_CLOSED' | translate }}
  </p>
</div>

<div *ngIf="(canRateIncidence() | async)" 
class="field-description-container sixteen wide column">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'NEXT_STEP_CAN_RATE' | translate }}
  </p>
</div>

<div class="sixteen wide column">
  <app-incidence-steps-indicator
    [incidence]="detailIncidence.incidence$ | async"
  ></app-incidence-steps-indicator>
</div>

<div class="sixteen wide column">
  <div class="ui button primary" *ngIf="canBeOpened()"
       (click)="openIncidence()">{{ 'BUTTON_OPEN' | translate }}
  </div>
  <div class="ui button primary" *ngIf="canBeFinished()"
       (click)="finalizeIncidence()">{{ 'BUTTON_FINISH' | translate }}
  </div>
  <div class="ui button primary"
       *ngIf="canBeClosed()"
       (click)="closeIncidence()">{{ 'BUTTON_CLOSE' | translate }}
  </div>
  <div
      class="ui primary button"
      *ngIf="(detailIncidence.canUserEdit() | async) || !((detailIncidence.incidence$ | async)?.wasAssigned())"
      [class.disabled]="!form.valid || !detailIncidence.isModified()"
      (click)="detailIncidence.saveChanges()"
    >{{ 'BUTTON_SAVE' | translate }}</div>
</div>

<div class="sixteen wide column">
  <form class="ui form" [formGroup]="form">
    <div class="field required">
      <label for="topic">
        {{ 'LABEL_TOPIC' | translate }}
      </label>
      <input name="topic" formControlName="topic" type="text" 
      [readOnly]="!(detailIncidence.canUserEdit() | async)">
    </div>

    <div class="field">
      <label for="description">{{ 'LABEL_DESCRIPTION' | translate }}</label>
      <textarea name="description" formControlName="description" 
      [readOnly]="!(detailIncidence.canUserEdit() | async)"></textarea>
    </div>

    <div *ngIf="canViewIncidenceCategories()" class="two fields">
      <div class="field">
        <app-label-and-info
          [labelText]="'LABEL_CATEGORIES' | translate"
          [helpingText]="'HELP_CATEGORIES' | translate"
        ></app-label-and-info>
        <div class="ui segment categories">
          <div *ngIf="!((detailIncidence.workOrders$ | async)?.length)" class="ui label">{{ 'NO_CATEGORIES' | translate }}</div>
          <div *ngFor="let order of detailIncidence.workOrders$ | async" class="ui label">{{order.category.name}}</div>
        </div>
      </div>
      <div class="field">
        <app-label-and-info
          [labelText]="'LABEL_REQUEST_TYPE' | translate"
          [helpingText]="'HELP_REQUEST_TYPE' | translate"
          [forAttribute]="'priority'"
        ></app-label-and-info>
        <fui-dropdown-two
          [readonly]="!(detailIncidence.canAdminSetType() | async)"
          [options]="incidenceTypes"
          formControlName="type"
          [class.empty-input]="form.controls.type.value === undefined"
        ></fui-dropdown-two>
      </div>
    </div>
  </form>
</div>
