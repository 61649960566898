
import { 
    ReportCostByCompanyDTO,
    ReportCostByCompanyReg,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessCostByCompany extends ProcessDataReport{
  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
        this.setColumnsCostTime().then(columns => {
          this.reports.tableDataParams = columns;
        });
    }
    
    getData(){
        const {labels: company, dataA : materialDataCompany, dataB : HourDataCompany, 
            dataA2 : materialDataCompany2, dataB2 : HourDataCompany2
        } = this.getCostByCompanyDataSets(this.reportGrossData as ReportCostByCompanyDTO);

        const dataSetsCostCompany = [{data: materialDataCompany, label: this.translate.instant("REPORTS.MATERIALS_LABEL_1"), stack: 'One date', order: 1},
                            {data: HourDataCompany, label: this.translate.instant("REPORTS.TIME_LABEL_1"), stack: 'One date', order: 2}];
                            
        if (materialDataCompany2.length > 0) {
            dataSetsCostCompany.push({data: materialDataCompany2, label: this.translate.instant("REPORTS.MATERIALS_LABEL_2"), stack: 'Other date', order: 1})
            dataSetsCostCompany.push({data: HourDataCompany2, label: this.translate.instant("REPORTS.TIME_LABEL_2"), stack: 'Other date', order: 2})
        }

        this.reports.costByCompany = {
            labels: company,
            dataSets: dataSetsCostCompany
        };

        //TABLE FOR REPORT_ID.COST_BY_COMPANY
        this.processTableData('companyName');
        this.reports.showIncidenceStatusFilter = false;
 
    }
    private getCostByCompanyDataSets(costsByCompany: ReportCostByCompanyDTO) {
    
        let labels = [];
        const dataA = [];
        const dataB = [];
        const dataA2 = [];
        const dataB2 = [];
        costsByCompany.value.forEach((reg: ReportCostByCompanyReg) => {
          labels.push(reg.companyName);
          dataA.push(reg.materialCost);
          dataB.push(reg.timeCost);
        });
        if (costsByCompany.value2) {
          costsByCompany.value2.forEach((reg: ReportCostByCompanyReg) => {
            labels.push(reg.companyName);
            dataA2.push(reg.materialCost);
            dataB2.push(reg.timeCost);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataB, dataA2, dataB2};
      }
}