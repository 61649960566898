<ng-container *ngIf="numOfPages <= maxVisibleOptions">
  <a class="icon item" (click)="pageSelected.emit(page - 1)" [class.disabled]="page === 1">
    <i class="left chevron icon"></i>
  </a>

  <a *ngFor="let pageItem of [].constructor(numOfPages); let i = index" class="item"
     [class.active]="i+1 === page" (click)="pageSelected.emit(i+1)">{{i + 1}}</a>

  <a class="icon item" (click)="pageSelected.emit((page + 1))"
     [class.disabled]="page === numOfPages || !numOfPages">
    <i class="right chevron icon"></i>
  </a>
</ng-container>

<ng-container *ngIf="numOfPages > maxVisibleOptions">
  <a class="icon item" (click)="pageSelected.emit(page - 1)" [class.disabled]="page === 1">
    <i class="left chevron icon"></i>
  </a>
  <a class="item" [class.active]="page === 1" (click)="pageSelected.emit(1)">1</a>

  <a *ngIf="page > 3" class="disabled item">...</a>
  <a *ngIf="page > 2" class="item" (click)="pageSelected.emit(page-1)" >{{page-1}}</a>
  <a *ngIf="page !== 1 && page !== numOfPages" class="active item" >{{page}}</a>
  <a *ngIf="page < numOfPages-1" class="item" (click)="pageSelected.emit(page+1)" >{{page+1}}</a>
  <a *ngIf="page < numOfPages-2" class="disabled item" >...</a>

  <a class="item" [class.active]="page === numOfPages" (click)="pageSelected.emit(numOfPages)">{{numOfPages}}</a>
  <a class="icon item" (click)="pageSelected.emit((page + 1))"
     [class.disabled]="page === numOfPages || !numOfPages">
    <i class="right chevron icon"></i>
  </a>
</ng-container>

