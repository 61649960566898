import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import { BaseChartComponent } from '../../base-chart.component';

@Component({
  selector: 'app-stack-chart',
  templateUrl: './stack-chart.component.html',
  styleUrls: ['./stack-chart.component.scss']
})
export class StackChartComponent extends BaseChartComponent  implements OnChanges {

  @ViewChild(BaseChartDirective, { static: true }) chartCanvas: BaseChartDirective;

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() title: string;

  @Input() chartDataSets: ChartDataSets[];
  @Input() labels: Label[];

  @Input() xAxesStacked = false;
  @Input() yAxesStacked = false;

  @Input() chartType = 'bar';

  chartOptions: any;

  constructor(
    private elementRef: ElementRef,
  ) {
    super();
  }

  chartColors: Array<any> = [
    {backgroundColor: '#329648'},
    {backgroundColor: '#005ec9'},
    {backgroundColor: '#c74f42'},
    {backgroundColor: '#deac00'},
    {backgroundColor: '#12b0ab'},
    {backgroundColor: '#c74289'},
    {backgroundColor: '#4c9efd'},
    {backgroundColor: '#42c781'},
    {backgroundColor: '#fc523f'},
    {backgroundColor: '#7632fc'},
    {backgroundColor: '#0e0091'},
    {backgroundColor: '#91004e'},
    {backgroundColor: '#de4d00'},
    {backgroundColor: '#f08907'},
    {backgroundColor: '#916a00'},
  ]
 

  private setupChartOptions(): void {

    this.adjustChartHeight();  
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: this.title,
        fontFamily: '"Exo 2", serif',
        fontColor: '#000000',
        fontSize: 18,
      },
      legend: {
        labels: {
          fontFamily: '"Exo 2", serif',
          fontColor: '#000000',
        }
      },

      scales: {
        xAxes: [{
          offset:false,
          stacked: this.xAxesStacked,
          ticks: {
            fontFamily: '"Exo 2", serif',
            fontColor: '#000000',
          }
        }],
        yAxes: [{
          offset:true,
          stacked: this.yAxesStacked,
          ticks: {
            beginAtZero: true,
            fontFamily: '"Exo 2", serif',
            fontColor: '#000000',
          }
        }]
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartDataSets || changes.title) {
      this.setupChartOptions();
    }
  }
  

  private calculateChartHeight(): number {
    const numBars = this.chartDataSets[0]?.data?.length || 0;
    const minHeight = 400;
    const maxHeight = 900;

    return Math.max(minHeight, Math.min(maxHeight, numBars * 40)); // Adjust 40 based on your preference
  }

  private adjustChartHeight(): void {
    const canvas = this.elementRef.nativeElement.querySelector('canvas');
    const calculatedHeight = this.calculateChartHeight();
    canvas.height = calculatedHeight;
  }

  getChartCanvas(): HTMLCanvasElement {
    const chartCanvas: HTMLCanvasElement = this.chartCanvas.chart.ctx.canvas;
    if (chartCanvas) {
      return chartCanvas;
    } else {
      console.error('Chart canvas not found.');
      return null;
    }
  }
  getTitle(){
    return this.title;
  }
}
