import {
  Component, ContentChild, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import {
  WORK_ORDER_STATES, 
  WORK_ORDER_STATES_LITERALS, 
  WorkOrder
} from '../../models/work-order.model';
import * as moment from 'moment/moment';
import {ROLES} from '../../../shared/models/role.interface';
import {PaginationMenuComponent} from '../../../fomantic-ui/pagination-menu/pagination-menu.component';
import {SortByData} from '../../../fomantic-ui/sortable-th/sortByData.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-work-order-table',
  templateUrl: './work-order-table.component.html',
  styleUrls: ['./work-order-table.component.scss']
})
export class WorkOrderTableComponent implements OnInit {

  @ContentChild(PaginationMenuComponent) pagination;

  @Input() workOrders: WorkOrder[];
  @Input() userRole: ROLES;

  @Output() createWorkOrder: EventEmitter<null> = new EventEmitter<null>();
  @Output() workOrderClicked: EventEmitter<WorkOrder> = new EventEmitter<WorkOrder>();

  @Input() showCreateButton = false;

  @Input() sortBy: SortByData;
  @Output() sortByChange: EventEmitter<SortByData> = new EventEmitter<SortByData>();

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  stateLabel(stateId: WORK_ORDER_STATES) {
    return this.translate.instant(WORK_ORDER_STATES_LITERALS[stateId]);
  }

  formatDate(date) {
    if (date) {
      moment().locale('es');
      return moment(date).format('YYYY-MM-DD');
    }

    return '---';
  }

  canViewMaintenanceCompany() {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole);
  }

  canViewCreatorCompany() {
    return [ROLES.SUPER_ADMIN, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole);
  }

  canViewCreatedDate() {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole);
  }

}
