import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {DetailIncidenceService} from '../detail-incidence.service';
import {IncidencesService} from '../../../incidences.service';
import {Incidence} from '../../../models/incidence';
import {Observable} from "rxjs";
import {map, skipWhile} from "rxjs/operators";

@Component({
  selector: 'app-detail-incidence-documents',
  templateUrl: './detail-incidence-documents.component.html',
  styleUrls: ['./detail-incidence-documents.component.scss']
})
export class DetailIncidenceDocumentsComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid';
  @ViewChild('fileInput') fileInput;

  documents$: Observable<{url: string, file: string}[]>;
  public uploadingImage: boolean;

  constructor(
    public detailIncidence: DetailIncidenceService,
    private incidenceSrv: IncidencesService
  ) {}

  ngOnInit(): void {
    this.documents$ = this.detailIncidence.incidence$.pipe(
      skipWhile(incidence => !incidence),
      map(incidence => {
        return incidence.photos.map(url => {
          const fileParts = url.split('/');
          const file = fileParts[fileParts.length - 1];
          return {
            url,
            file
          };
        });
      })
    );
  }

  addFiles() {
    this.uploadingImage = true;
    const file = this.fileInput.nativeElement.files[0];

    this.incidenceSrv.uploadImage(file).subscribe(result => {
      this.fileInput.nativeElement.value = null;
      this.detailIncidence.modifiedPhotos = true;
      const incidence = new Incidence(this.detailIncidence.incidence$.getValue());
      incidence.photos.push(result.url);
      this.detailIncidence.incidence$.next(incidence);
      this.uploadingImage = false;
    });
  }

  deleteDocument(index: number) {
    this.detailIncidence.modifiedPhotos = true;
    this.detailIncidence.incidence$.value.photos
      .splice(index, 1);
    this.detailIncidence.incidence$.next(this.detailIncidence.incidence$.value);
  }
}
