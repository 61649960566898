import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { CompaniesService } from '../../../companies/companies.service';
import { IUserLogged } from '../../models/i-user-logged';
import { Subject, combineLatest } from 'rxjs';
import { CompanyLogoService } from '../../services/companyLogo.service';

@Component({
  selector: 'app-menu-profile',
  templateUrl: './menu-profile.component.html',
  styleUrls: ['./menu-profile.component.scss'],
})
export class MenuProfileComponent implements OnInit, OnDestroy {
  logoUrl: string;
  loggedUser: IUserLogged;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private companiesService: CompaniesService,
    private logoService: CompanyLogoService,
  ) {}

  ngOnInit(): void {
    this.logoService.logoInfo$.pipe(takeUntil(this.destroy$)).subscribe(({url, company}) => {
      if (this.loggedUser && company && company.id === this.loggedUser.companyId) {
        this.logoUrl = url;
      }
    });

    combineLatest([
      this.sessionService.activeFacility$.pipe(filter(facility => !!facility)),
      this.sessionService.loggedUser$.pipe(filter(user => !!user)),
    ])
      .pipe(
        switchMap(([facility, user]) => this.companiesService.fetchCompany(user.companyId)),
        takeUntil(this.destroy$)
      )
      .subscribe((company) => {
        this.logoUrl = company.logo;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
