import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FacilitiesService} from '../../../facilities/facilities.service';
import {Facility} from '../../../facilities/models/facility.interface';
import {
  FacilityComponentInterface,
  FacilityFloorInterface,
  FacilitySpaceInterface
} from '../../../facilities/models/facility-element.interface';
import {delay, switchMap, tap} from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-viewer-element-selector',
  templateUrl: './viewer-element-selector.component.html',
  styleUrls: ['./viewer-element-selector.component.scss']
})

export class ViewerElementSelectorComponent implements OnInit, OnChanges {

  @Input() facility: Facility;

  _selectedFloor: FacilityFloorInterface;
  floors: FacilityFloorInterface[];

  _selectedSpace: FacilitySpaceInterface;
  spaces: FacilitySpaceInterface[];

  _selectedComponent: FacilityComponentInterface;
  components: FacilityComponentInterface[];

  @Output() selectedFloor: EventEmitter<FacilityFloorInterface> = new EventEmitter<FacilityFloorInterface>();
  @Output() selectedSpace: EventEmitter<FacilitySpaceInterface> = new EventEmitter<FacilitySpaceInterface>();
  @Output() selectedComponent: EventEmitter<FacilityComponentInterface> = new EventEmitter<FacilityComponentInterface>();

  showReadQRModal = false;

  private emitValuesOnUpdate = true;

  showErrorModal = false;
  errorTitle: string;
  errorMessage: string;

  constructor(
    private facilitySrv: FacilitiesService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.facility && this.facility) {
      this.facilitySrv.fetchFacilityFloors(this.facility.id).subscribe(floors => this.floors = floors);
      this.spaces = null;
      this.components = null;
    }
  }

  floorSelected(floor: FacilityFloorInterface) {
    if (floor && this.emitValuesOnUpdate) {
      this.selectedFloor.emit(floor);
      this.spaces = null;
      this.components = null;
      this.facilitySrv.fetchFloorSpaces(floor.id).subscribe(spaces => this.spaces = spaces);
    }
  }

  spaceSelected(space: FacilitySpaceInterface) {
    if (space && this.emitValuesOnUpdate) {
      this.selectedSpace.emit(space);
      this.components = null;
      this.facilitySrv.fetchSpaceComponents(space.id).subscribe(components => this.components = components);
    }
  }

  componentSelected(component: FacilityComponentInterface) {
    if (component && this.emitValuesOnUpdate) {
      this.selectedComponent.emit(component);
    }
  }

  onQRRead(qrData: string) {
    // Extracting the componentGUID from the URL
    const url = new URL(qrData);
    const searchParams = new URLSearchParams(url.search);
    const componentGUID = searchParams.get('componentGUID');

    this.facilitySrv.fetchComponentByExternalIdentifier(componentGUID, this.facility.id).subscribe(component => {
      if (component) {
        this.emitValuesOnUpdate = false;
        if (component.space != null){
          this._selectedFloor = component.space.floor;

          this.facilitySrv.fetchFloorSpaces(component.space.floor.id).pipe(
            tap(spaces => this.spaces = spaces),
            delay(200),
            tap(spaces => this._selectedSpace = component.space),
            switchMap(spaces => this.facilitySrv.fetchSpaceComponents(this._selectedSpace.id)),
            tap(components => this.components = components),
            delay(200),
            tap(components => this._selectedComponent = component),
          ).subscribe(components => {
            this.emitValuesOnUpdate = true;
          });
        }
        this.selectedComponent.emit(component);
      }},
      error => {
        this.showComponentNotFoundError(qrData);
      }
    );
  }

  onErrorReadingQR(): void {
    this.showReadQRModal = false;

    this.errorTitle = 'ERROR_READING_QR_TITLE';
    this.errorMessage = 'ERROR_READING_QR_MESSAGE';

    this.showErrorModal = true;
  }

  showComponentNotFoundError(externalId: string): void {
    this.errorTitle = 'ERROR_COMPONENT_NOT_FOUND_TITLE';
    this.errorMessage = this.translate.instant('ERROR_COMPONENT_NOT_FOUND_MESSAGE', { externalId });

    this.showErrorModal = true;
  }
}
