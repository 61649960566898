
import { 
    ReportCostByCategoryDTO,
    ReportCostByCategoryReg,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessCostByCategory extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
        this.setColumnsProblemType().then(columns => {
          this.reports.tableDataParams = columns;
        });
        
    }
    
    getData(){
        let {labels: costByCategoryLabels, 
            dataA : materialsData, dataB : timeData, 
            dataA2 : materialsData2, dataB2 : timeData2
        } = this.getCostByCategoryDataSets(this.reportGrossData as ReportCostByCategoryDTO);
          let dataSetsCost = [{data: materialsData, label: this.translate.instant("REPORTS.MATERIALS_LABEL_1"), stack: 'One date'},
                            {data: timeData, label: this.translate.instant("REPORTS.TIME_LABEL_1"), stack: 'One date'}]
          if (materialsData2.length > 0) {
            dataSetsCost.push({data: materialsData2, label: this.translate.instant("REPORTS.MATERIALS_LABEL_2"), stack: 'Other date'})
            dataSetsCost.push({data: timeData2, label: this.translate.instant("REPORTS.TIME_LABEL_2"), stack: 'Other date'})
          }
          this.reports.costByCategory = {
            labels: costByCategoryLabels,
            dataSets: dataSetsCost
          };
          this.reports.showIncidenceStatusFilter = false;

          this.processTableData('categoryName');
       this.reports.showIncidenceStatusFilter = false;
 
 
    }
    private getCostByCategoryDataSets(tableDataProcessedDate1: ReportCostByCategoryDTO) {
    
        let labels = [];
        const dataA = [];
        const dataB = [];
        const dataA2 = [];
        const dataB2 = [];
        tableDataProcessedDate1.value.forEach((reg: ReportCostByCategoryReg) => {
          labels.push(reg.categoryName);
          dataA.push(reg.materialCost);
          dataB.push(reg.timeCost);
        });
        if (tableDataProcessedDate1.value2) {
          tableDataProcessedDate1.value2.forEach((reg: ReportCostByCategoryReg) => {
            labels.push(reg.categoryName);
            dataA2.push(reg.materialCost);
            dataB2.push(reg.timeCost);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataB, dataA2, dataB2};
      }

      private setColumnsProblemType(): Promise<any[]> {
        return this.translate.get([
          "REPORTS.CATEGORY",
          "REPORTS.MATERIAL",
          "REPORTS.LABOR",
          "REPORTS.TOTAL_COST"
        ]).toPromise().then(translations => {
          return [
            {
              name: translations["REPORTS.CATEGORY"],
              key: 'categoryName'
            },
            {
              name: translations["REPORTS.MATERIAL"],
              key: 'materialCost',
              type: 'currency',
            },
            {
              name: translations["REPORTS.LABOR"],
              key: 'timeCost',
              type: 'currency'
            },
            {
              name: translations["REPORTS.TOTAL_COST"],
              key: 'totalCost',
              type: 'currency'
            },
          ];
        });
      }
}