import {NgModule} from '@angular/core';
import {ReportTableComponent} from './components/report-table/report-table.component';
import {ReportsComponent} from './pages/reports/reports.component';
import {ChartsModule} from '../charts/charts.module';
import {GoogleLineChartComponent} from './components/google-line-chart/google-line-chart.component';
import {EvolutionIncidencesByCategoryReportComponent} from './components/evolution-incidences-by-category-report/evolution-incidences-by-category-report.component';
import {SharedModule} from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    ReportTableComponent,
    ReportsComponent,
    GoogleLineChartComponent,
    EvolutionIncidencesByCategoryReportComponent
  ],
  imports: [
    SharedModule,
    ChartsModule,
    TranslateModule.forChild(),
  ]
})
export class ReportsModule { }
