<div class="ui stackable grid">
  <div *ngIf="hasViewer"
    [ngClass]="isElementSelected ? 'eleven wide column' : 'sixteen wide column'"
    style="height: 500px"
  >
      <app-autodesk-viewer
        class="viewer"
        #viewer
        [document]="facility.externalFacilityObject"
        (selectedElement)="selectedElemOnViewer($event)"
        (loaded)="loadedViewer$.next(true)"
      ></app-autodesk-viewer>
  </div>
  <div class="five wide column" *ngIf="isElementSelected">
    <app-element-info-panel
      class="element-panel"
      [element]="selectedElement"
      (close)="selectedElemOnViewer(null)"
    ></app-element-info-panel>
  </div>

  <!-- COMPONENT TABLE -->

  <div *ngIf="hasViewer" class="viewer-actions">
    <button class="ui button" (click)="showComponents()">
      {{ 'SCHEDULED_TASK_DETAIL.SHOW_COMPONENTS' | translate }}
    </button>
  </div>

  <div class="sixteen wide column nested-table" *ngIf="shouldShowTables()">
    <app-collapsible-form [title]="'SCHEDULED_TASK_DETAIL.COMPONENTS' | translate">
      <form class="ui mini form">
        <div class="fields">
          <div class="four wide field">
            <div class="ui input focus">
              <button
                type="button"
                (click)="showReadQRModal = true"
                class="ui right labeled icon button"
              >
                <i class="right qrcode icon"></i>
                {{ 'SCHEDULED_TASK_DETAIL.SCAN_QR' | translate }}
              </button>
            </div>
          </div>

          <div class="eight wide field">
            <div class="ui icon input">
              <input
                type="text"
                [placeholder]="'SCHEDULED_TASK_DETAIL.SEARCH_BY_CODE_OR_NAME' | translate"
                (keyup)="searchComponente($event.target.value)"
              />
              <i class="search icon"></i>
            </div>
          </div>
        </div>
      </form>

      <table class="ui celled table">
        <thead>
          <tr>
            <th>{{ 'SCHEDULED_TASK_DETAIL.COMPONENT' | translate }}</th>
            <th>{{ 'SCHEDULED_TASK_DETAIL.SPACE' | translate }}</th>
            <th>{{ 'SCHEDULED_TASK_DETAIL.FLOOR' | translate }}</th>
            <th>{{ 'SCHEDULED_TASK_DETAIL.REVIEWED' | translate }}</th>
          </tr>
        </thead>
        <tbody
          *ngFor="
            let scheduledTask of listComponentsSubtasks$ | async;
            let scheduledTaskIndex = index
          "
        >
          <tr class="componentTable">
            <td>
              <span
                class="toggleSubTable"
                (click)="toggleSubtaskTables(scheduledTaskIndex)"
              >
                <i
                  [@caretRotation]="
                    showComponentsTable[scheduledTaskIndex] ? 'open' : 'close'
                  "
                  class="caret right icon"
                ></i>
              </span>
              <span
                class="cursor-pointer"
                (click)="onClickComponentTable(scheduledTask)"
              >
                {{ scheduledTask.component.name }}
              </span>
              <span *ngIf="hasUnReviewedSubtask.get(scheduledTaskIndex)">
                <span
                  class="ui"
                  data-tooltip="'SCHEDULED_TASK_DETAIL.CONTAINS_UNREVIEWED_SUBTASKS' | translate"
                >
                  <i class="info circle icon red"></i>
                </span>
              </span>
            </td>
            <td
              class="cursor-pointer"
              (click)="onClickComponentTable(scheduledTask)"
            >
              {{ scheduledTask.component.space.name }}
            </td>
            <td
              class="cursor-pointer"
              (click)="onClickComponentTable(scheduledTask)"
            >
              {{ scheduledTask.component.space.floor.name }}
            </td>
            <td>
              <div class="ui fitted checkbox cursor-notAllowed">
                <input
                  type="checkbox"
                  name="allSubtaskReviewed-{{ scheduledTaskIndex }}"
                  [checked]="isReviewed(scheduledTask.id)"
                  [disabled]="isReviewedDisabled(scheduledTask)"
                  (click)="
                    reviewComponent(scheduledTask.id, $event.target.checked)
                  "
                />
                <label></label>
              </div>
            </td>
          </tr>
          <tr [@myAnimation] *ngIf="showComponentsTable[scheduledTaskIndex]">
            <td colspan="16" class="">
              <table class="ui celled table subtaskTable">
                <thead>
                  <tr>
                    <th>{{ 'SCHEDULED_TASK_DETAIL.CODE' | translate }}</th>
                    <th>{{ 'SCHEDULED_TASK_DETAIL.DESCRIPTION' | translate }}</th>
                    <th>{{ 'SCHEDULED_TASK_DETAIL.VALUE' | translate }}</th>
                    <th>
                      <div class="radioContainerSubtask">
                        <label class="cursor-notAllowed">
                          <input
                            type="radio"
                            name="reviewedAll_{{ scheduledTaskIndex }}"
                            id="reviewedAll_{{ scheduledTaskIndex }}"
                            value="true"
                            (click)="
                              reviwedAllSubtasks(
                                scheduledTask.id,
                                true,
                                scheduledTaskIndex
                              )
                            "
                            [disabled]="isTaskFinished()"
                          />
                          <img
                            id="reviewedAll_{{ scheduledTaskIndex }}_check"
                            class="subtaskImg"
                            width="20px"
                            src="/assets/images/tick-icon.png"
                          />
                        </label>
                        <label class="radioCross cursor-notAllowed">
                          <input
                            type="radio"
                            name="reviewedAll_{{ scheduledTaskIndex }}"
                            value="false"
                            (click)="
                              reviwedAllSubtasks(
                                scheduledTask.id,
                                false,
                                scheduledTaskIndex
                              )
                            "
                            [disabled]="isTaskFinished()"
                          />
                          <img
                            id="reviewedAll_{{ scheduledTaskIndex }}_cross"
                            class="subtaskImg"
                            width="20px"
                            src="/assets/images/cross-icon.png"
                          />
                        </label>
                        <label class="cursor-notAllowed">
                          <input
                            type="radio"
                            name="reviewedAll_{{ scheduledTaskIndex }}"
                            id="reviewedAll_{{ scheduledTaskIndex }}"
                            value="null"
                            (click)="
                              reviwedAllSubtasks(
                                scheduledTask.id,
                                null,
                                scheduledTaskIndex
                              )
                            "
                            [disabled]="isTaskFinished()"
                          />
                          <img
                            id="reviewedAll_{{ scheduledTaskIndex }}_none"
                            class="subtaskImg"
                            width="20px"
                            src="/assets/images/forbidden-icon.png"
                          />
                        </label>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  
                  <tr
                    *ngFor="
                      let subtask of listSubtask[scheduledTaskIndex];
                      let indexSubtask = index
                    "
                  >
                    <td>
                      {{ subtask.code }}
                    </td>
                    <td>
                      {{ subtask.description }}
                    </td>
                    <td class="td-value">
                      <div class="ui right">
                        <input 
                        class="input-value" 
                          [readOnly]="isTaskFinished()" 
                          type="text"
                          [value]="getDisplayValue(subtask.value)"
                          maxlength="10" 
                          (input)="onSubtaskValueChange(scheduledTaskIndex, indexSubtask, subtask.id, $event.target.value)"
                          >
                        </div>
                    </td>
                    <td class="radioContainerSubtask">
                      <label class="cursor-notAllowed">
                        <input
                          type="radio"
                          name="reviewed_{{ subtask.id }}"
                          value="true"
                          [checked]="subtask.checkedOption === true"
                          (change)="
                            changeSubtask(
                              subtask.id,
                              true,
                              scheduledTaskIndex,
                              indexSubtask
                            )
                          "
                          [disabled]="isTaskFinished()"
                        />
                        <img width="20px"  class="subtaskImg" src="/assets/images/tick-icon.png" />
                      </label>

                      <label class="radioCross" class="cursor-notAllowed">
                        <input
                          type="radio"
                          name="reviewed_{{ subtask.id }}"
                          value="false"
                          [checked]="subtask.checkedOption === false"
                          (change)="
                            changeSubtask(
                              subtask.id,
                              false,
                              scheduledTaskIndex,
                              indexSubtask
                            )
                          "
                          [disabled]="isTaskFinished()"
                        />
                        <img width="20px"  class="subtaskImg" src="/assets/images/cross-icon.png" />
                      </label>

                      <label class="cursor-notAllowed">
                        <input
                          type="radio"
                          name="reviewed_{{ subtask.id }}"
                          value="false"
                          [checked]="subtask.checkedOption === null"
                          (change)="
                            changeSubtask(
                              subtask.id,
                              null,
                              scheduledTaskIndex,
                              indexSubtask
                            )
                          "
                          [disabled]="isTaskFinished()"
                        />
                        <img
                        class="subtaskImg"
                          width="20px"
                          src="/assets/images/forbidden-icon.png"
                        />
                      </label>
                    </td>
                  </tr>
                
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
        <tfoot class="full-width">
          <tr>
            <th colspan="16">
              <fui-pagination-menu
                [page]="componentsPage$ | async"
                [numOfPages]="componentsNumOfPages"
                (pageSelected)="componentsPage$.next($event)"
              ></fui-pagination-menu>
            </th>
          </tr>
          <tr>
            <th colspan="16">{{ getFooterMessage() }}</th>
          </tr>
        </tfoot>
      </table>
    </app-collapsible-form>
  </div>

  <app-qr-reader-modal
    *ngIf="showReadQRModal"
    (close)="this.showReadQRModal = false"
    (dataRead)="onQRRead($event)"
    (errorOnRead)="onErrorReadingQR()"
  ></app-qr-reader-modal>

  <pui-modal *ngIf="showErrorModal" [title]="errorTitle">
    {{ errorMessage }}
    <div footer>
      <div class="ui primary button" (click)="this.showErrorModal = false">
        {{ 'SCHEDULED_TASK_DETAIL.ACCEPT' | translate }}
      </div>
    </div>
  </pui-modal>
</div>
