<div class="sixteen wide column">
  <h1 class="ui dividing header">{{'SETTINGS.TITLE' | translate}}</h1>
</div>

<configuration-menu [defaultSectionKey]="'notifications'"></configuration-menu>

<div class="sixteen wide mobile twelve wide computer column">
  <div class="ui grid">
    <div *ngIf="activeSection" class="sixteen wide column">

      <app-notifications-configuration-tab *ngIf="activeSection.key === 'notifications'">
      </app-notifications-configuration-tab>

    </div>

  </div>
</div>

