<span class="close-button material-icons" (click)="onClose()">close</span>
<ng-container *ngIf="element">
  <h1 class="header">
    {{element.name}}
  </h1>
  <div class="divider"></div>
  <div class="info-item">
    <h2>{{ 'FLOOR' | translate }}</h2>
    <p>{{element.space?.floor?.name || '-'}}</p>
  </div>
  <div class="info-item">
    <h2>{{ 'SPACE' | translate }}</h2>
    <p>{{element.space?.name || '-'}}</p>
  </div>
  <div class="info-item">
    <h2>{{ 'SERIAL_NUMBER' | translate }}</h2>
    <p>{{element.serialNumber || '-'}}</p>
  </div>
  <div class="info-item">
    <h2>{{ 'CODE' | translate }}</h2>
    <p>{{element.assetIdentifier || '-'}}</p>
  </div>
  <div class="info-item">
    <h2>{{ 'WARRANTY_DATE' | translate }}</h2>
    <p>{{(element.warrantyStartOn | date:'mediumDate':'':'es') || '-'}}</p>
  </div>
  <div class="info-item">
    <h2>{{ 'INSTALLATION_DATE' | translate }}</h2>
    <p>{{(element.installatedOn | date:'mediumDate':'':'es') || '-'}}</p>
  </div>
  <div class="actions">
    <pui-button (click)="goToElement()" [label]="'VIEW_DETAILS' | translate"></pui-button>
    <pui-button class="primary button" (click)="addComponentToNextIncidence()" [label]="'ADD_NEW_REQUEST' | translate"></pui-button>
  </div>
  <div class="ui divider hidden"></div>
  <div class="actions">
    <pui-button *ngIf="addComponentToCurrentEntity" class="primary button" (click)="addComponentCallback(element)" [label]="text | translate"></pui-button>
  </div>
</ng-container>
<ng-container *ngIf="!element">
  <h1 class="header">{{ 'NO_DATA_FOUND' | translate }}</h1>
</ng-container>
