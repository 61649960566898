import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CompaniesRoutingModule} from './companies-routing.module';
import {FomanticUiModule} from '../fomantic-ui/fomantic-ui.module';

import {CompanyFormComponent} from './components/company-form/company-form.component';
import {AddCompanyComponent} from './pages/add-company/add-company.component';
import {DetailCompanyComponent} from './pages/detail-company/detail-company.component';
import {CompaniesComponent} from './pages/companies/companies.component';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CompanyFormComponent,
    AddCompanyComponent,
    DetailCompanyComponent,
    CompaniesComponent
  ],
  imports: [
    CommonModule,
    FomanticUiModule,
    CompaniesRoutingModule,
    GooglePlaceModule,
    TranslateModule.forChild(),
  ]
})
export class CompaniesModule { }
