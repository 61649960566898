import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {

  @Input() elements: object[];
  formattedElements: {[key: string]: string}[];
  @Input() fields: {key: string, resultKey?: string, title?: string, path?: string, function?: (element: any) => string  }[];
  @Output() elementSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input() celled = false;
  @Input() compact = false;
  @Input() selectable = false;
  @Input() size: string;

  @Input() pagination: {page: number, numOfPages: number};
  @Output() pageSelected: EventEmitter<number> = new EventEmitter<number>();

  @Input() rightButton: {disabled?: boolean, text: string, icon: string};
  @Output() rightButtonClick: EventEmitter<null> = new EventEmitter<null>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.elements || changes.fields) && this.elements && this.fields) {

      const formattedElements = [];

      this.elements.forEach(elem => {
        const formattedElement = {};

        this.fields.forEach(field => {
          if (field.function) {
            formattedElement[field.resultKey || field.key] = field.function(elem);
          } else if (field.path) {
            formattedElement[field.resultKey || field.key] = this.getParamByPath(elem[field.key], field.path, null);
          } else {
            formattedElement[field.resultKey || field.key] = elem[field.key];
          }
        });

        formattedElements.push(formattedElement);
      });

      this.formattedElements = formattedElements;
    }
  }

  private getParamByPath(obj, path, defaultValue) {
    if (obj && path) {
      path = Array.isArray(path) ? path : path.split('.');
      return path.reduce( (acc, key, index) => {
        const value = (acc || [])[ key ];
        const isLast = index + 1 === path.length;
        const isObject = value === Object(value);
        if (!isObject && !isLast) {
          return defaultValue || {};
        }
        return (value === 0 || !!value) ? value : defaultValue;
      }, obj);
    }

    return defaultValue;
  }
}
