import { TranslateService } from "@ngx-translate/core";
import { Facility } from "src/app/facilities/models/facility.interface";
import { ROLES } from "src/app/shared/models/role.interface";

export enum NotificationsActions {
  IncidenceCreatedHtml = "INCIDENCE_CREATED_HTML",
  IncidenceReadyToReviewHtml = "INCIDENCE_READY_TO_REVIEW_HTML",
  IncidenceReviewedHtml = "INCIDENCE_REVIEWED_HTML",

  WorkOrderEmitedHtml = "WORK_ORDER_EMITED_HTML",
  WorkOrderFinishedHtml = "WORK_ORDER_FINISHED_HTML",

  ScheduledTaskReminderHtml = "SCHEDULED_TASK_REMINDER_HTML",
  ScheduledTaskFinishedHtml = "SCHEDULED_TASK_FINISHED_HTML",

  ComponentsToReplaceNext6MonthsHtml = "COMPONENTS_TO_REPLACE_IN_6_MONTHS_HTML",

  MessageReceivedHtml = "MESSAGE_RECEIVED_HTML",

  UserLogInHtml = 'USER_LOGIN_HTML',
  UserPasswordChangedHtml = "USER_PASSWORD_CHANGED_HTML",
  WeekSummaryHtml = "WEEK_SUMMARY_HTML",
  UpdatesHtml = 'UPDATES_HTML',
  MonthlyInsightsHtml = 'MONTHLY_INSIGHTS_HTML',
}

const ROLE_OWNER_NOTIFICATIONS = [
  NotificationsActions.IncidenceCreatedHtml,
  NotificationsActions.IncidenceReadyToReviewHtml,
  NotificationsActions.IncidenceReviewedHtml,

  NotificationsActions.WorkOrderEmitedHtml,
  NotificationsActions.WorkOrderFinishedHtml,

  NotificationsActions.ScheduledTaskFinishedHtml,
  NotificationsActions.ScheduledTaskReminderHtml,

  NotificationsActions.MessageReceivedHtml,

  NotificationsActions.WeekSummaryHtml,
  NotificationsActions.UserPasswordChangedHtml,
  NotificationsActions.UserLogInHtml,
  NotificationsActions.UpdatesHtml,
  NotificationsActions.MonthlyInsightsHtml,
  
];

const ROLE_GLOBAL_SERVICE_NOTIFICATIONS = [
  NotificationsActions.IncidenceCreatedHtml,
  NotificationsActions.IncidenceReadyToReviewHtml,
  NotificationsActions.IncidenceReviewedHtml,

  NotificationsActions.WorkOrderEmitedHtml,
  NotificationsActions.WorkOrderFinishedHtml,

  NotificationsActions.ScheduledTaskFinishedHtml,
  NotificationsActions.ScheduledTaskReminderHtml,

  NotificationsActions.MessageReceivedHtml,

  NotificationsActions.WeekSummaryHtml,
  NotificationsActions.UserPasswordChangedHtml,
  NotificationsActions.UserLogInHtml,
  NotificationsActions.UpdatesHtml,
  NotificationsActions.MonthlyInsightsHtml,
];

const ROLE_MAINTENANCE_ADMIN_NOTIFICATIONS = [
  NotificationsActions.IncidenceCreatedHtml,
  NotificationsActions.IncidenceReadyToReviewHtml,

  NotificationsActions.WorkOrderFinishedHtml,

  NotificationsActions.ScheduledTaskFinishedHtml,
  NotificationsActions.ScheduledTaskReminderHtml,

  NotificationsActions.MessageReceivedHtml,

  NotificationsActions.WeekSummaryHtml,
  NotificationsActions.UserPasswordChangedHtml,
  NotificationsActions.UserLogInHtml,
  NotificationsActions.UpdatesHtml,
  NotificationsActions.MonthlyInsightsHtml,
];

const ROLE_MAINTENANCE_USER_NOTIFICATIONS = [
  NotificationsActions.IncidenceCreatedHtml,
  NotificationsActions.IncidenceReadyToReviewHtml,

  NotificationsActions.WorkOrderFinishedHtml,

  NotificationsActions.ScheduledTaskFinishedHtml,
  NotificationsActions.ScheduledTaskReminderHtml,

  NotificationsActions.MessageReceivedHtml,

  NotificationsActions.WeekSummaryHtml,
  NotificationsActions.UserPasswordChangedHtml,
  NotificationsActions.UserLogInHtml,
  NotificationsActions.UpdatesHtml,
  NotificationsActions.MonthlyInsightsHtml,
];

const ROLE_CLIENT_ADMIN_NOTIFICATIONS = [
  NotificationsActions.IncidenceCreatedHtml,
  NotificationsActions.IncidenceReadyToReviewHtml,

  NotificationsActions.MessageReceivedHtml,

  NotificationsActions.WeekSummaryHtml,
  NotificationsActions.UserPasswordChangedHtml,
  NotificationsActions.UserLogInHtml,
  NotificationsActions.UpdatesHtml,
];

const ROLE_CLIENT_USER_NOTIFICATIONS = [
  NotificationsActions.IncidenceCreatedHtml,
  NotificationsActions.IncidenceReadyToReviewHtml,

  NotificationsActions.MessageReceivedHtml,

  NotificationsActions.WeekSummaryHtml,
  NotificationsActions.UserPasswordChangedHtml,
  NotificationsActions.UserLogInHtml,
  NotificationsActions.UpdatesHtml,
];

export function getNotificationTranslation(notification: NotificationsActions, translate: TranslateService){
  const notifications = {
    [NotificationsActions.IncidenceCreatedHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.IncidenceCreatedHtml}`),
    [NotificationsActions.IncidenceReadyToReviewHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.IncidenceReadyToReviewHtml}`),
    [NotificationsActions.IncidenceReviewedHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.IncidenceReviewedHtml}`),

  [NotificationsActions.WorkOrderEmitedHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.WorkOrderEmitedHtml}`),
  [NotificationsActions.WorkOrderFinishedHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.WorkOrderFinishedHtml}`),

  [NotificationsActions.ScheduledTaskReminderHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.ScheduledTaskReminderHtml}`),
  [NotificationsActions.ScheduledTaskFinishedHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.ScheduledTaskFinishedHtml}`),

  [NotificationsActions.UserPasswordChangedHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.UserPasswordChangedHtml}`),
  [NotificationsActions.UserLogInHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.UserLogInHtml}`),

  [NotificationsActions.MessageReceivedHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.MessageReceivedHtml}`),
  [NotificationsActions.WeekSummaryHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.WeekSummaryHtml}`),
  [NotificationsActions.UpdatesHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.UpdatesHtml}`),
  [NotificationsActions.MonthlyInsightsHtml]: translate.instant(`NOTIFICATIONS.${NotificationsActions.MonthlyInsightsHtml}`),
  }

  return notifications[notification];
}

export enum NOTIFICATION_TYPES {
  INCIDENCES = "INCIDENCES",
  WORK_ORDERS = "WORK_ORDERS",
  SCHEDULED_TASKS = "SCHEDULED_TASKS",
  COMPONENTS = "COMPONENTS",
  MESSAGES = "MESSAGES",
  GLOBAL = "GLOBAL",
}

export const NOTIFICATION_TYPE = {
  // Incidencias
  [NotificationsActions.IncidenceCreatedHtml]: NOTIFICATION_TYPES.INCIDENCES,
  [NotificationsActions.IncidenceReadyToReviewHtml]: NOTIFICATION_TYPES.INCIDENCES,
  [NotificationsActions.IncidenceReviewedHtml]: NOTIFICATION_TYPES.INCIDENCES,

  // Órdenes de trabajo
  [NotificationsActions.WorkOrderEmitedHtml]: NOTIFICATION_TYPES.WORK_ORDERS,
  [NotificationsActions.WorkOrderFinishedHtml]: NOTIFICATION_TYPES.WORK_ORDERS,

  // Tareas programadas
  [NotificationsActions.ScheduledTaskReminderHtml]: NOTIFICATION_TYPES.SCHEDULED_TASKS,
  [NotificationsActions.ScheduledTaskFinishedHtml]: NOTIFICATION_TYPES.SCHEDULED_TASKS,

  // Componentes y mantenimiento
  [NotificationsActions.ComponentsToReplaceNext6MonthsHtml]: NOTIFICATION_TYPES.COMPONENTS,

  // Mensajes y comunicaciones
  [NotificationsActions.MessageReceivedHtml]: NOTIFICATION_TYPES.MESSAGES,

  // Global
  [NotificationsActions.WeekSummaryHtml]: NOTIFICATION_TYPES.GLOBAL,
  [NotificationsActions.UserPasswordChangedHtml]: NOTIFICATION_TYPES.GLOBAL,
  [NotificationsActions.UserLogInHtml]: NOTIFICATION_TYPES.GLOBAL,
  [NotificationsActions.UpdatesHtml]: NOTIFICATION_TYPES.GLOBAL,
  [NotificationsActions.MonthlyInsightsHtml]: NOTIFICATION_TYPES.GLOBAL,
};

const ROLE_NOTIFICATIONS_MAP = {
  [ROLES.OWNER]: ROLE_OWNER_NOTIFICATIONS,
  [ROLES.GLOBAL_SERVICE]: ROLE_GLOBAL_SERVICE_NOTIFICATIONS,
  [ROLES.MAINTENANCE_ADMIN]: ROLE_MAINTENANCE_ADMIN_NOTIFICATIONS,
  [ROLES.MAINTENANCE_USER]: ROLE_MAINTENANCE_USER_NOTIFICATIONS,
  [ROLES.CLIENT_ADMIN]: ROLE_CLIENT_ADMIN_NOTIFICATIONS,
  [ROLES.CLIENT_USER]: ROLE_CLIENT_USER_NOTIFICATIONS,
};

export const getNotificationsByRole = (role: ROLES = ROLES.OWNER, translate: TranslateService) =>
  ROLE_NOTIFICATIONS_MAP[role].map((notification: NotificationsActions) => ({
    key: notification,
    name: getNotificationTranslation(notification, translate),
    type: NOTIFICATION_TYPE[notification],
  }));

export const getNotificationIndexes = (
  activeFacility: Facility
): { global: number; activeFacility?: number } => ({
  global: 0,
  ...(activeFacility ? { activeFacility: activeFacility.id } : {}),
});

export const isGlobalNotification = (key: string): boolean => {
  return NOTIFICATION_TYPE[key] === NOTIFICATION_TYPES.GLOBAL;
};