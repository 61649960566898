<div class="pui-dropdown" [class.disabled]="disabled" (click)="clickOnInput()">
  <!--  <p *ngIf="!selectedOption" class="placeholder">{{placeholder || 'Seleccionar'}}</p>-->
  <pui-simple-search-content
    #simpleSearchContent
    *ngIf="_search && !_multiple"
    [selectedLabel]="(selectedOptions$.value[0] && optionLabel(selectedOptions$.value[0])) || ''"
    [searchForm]="searchForm"
  ></pui-simple-search-content>
  <div *ngIf="!_search || _multiple" class="content">
<!--    <p *ngIf="(selectedOptions$ | async).length === 0 && placeholder" class="placeholder"> {{placeholder}} </p>-->
    <p *ngFor="let option of selectedOptions$ | async" class="selectedOption">
      <ng-container *ngIf="_multiple">
        {{optionLabel(option) | slice:0:30}}
      </ng-container>
      <ng-container *ngIf="!_multiple">
        {{optionLabel(option)}}
      </ng-container>
      <span *ngIf="_multiple" class="close" (click)="clickOnDeselectOption(option, $event)"><i class="delete icon"></i></span>
    </p>
    <input (keyup)="keyUpOnSearch($event)" (keydown)="keyDownOnSearch($event)" #search *ngIf="(_search && !selectedOptions$.value.length) || (_search && _multiple)" class="textInput" [formControl]="searchForm" type="text"
           [size]="this.searchForm.value.length + 1"
           autocomplete="off"
           >
  </div>
  <div class="dropdown-icon">
    <i class="fa fa-caret-down"></i>
  </div>


  <ul *ngIf="opened" class="options">
    <li *ngIf="loadingOptions" class="loading">
      <pui-spinner heightPx="20" type="puff"></pui-spinner>
    </li>
    <ng-container *ngIf="!loadingOptions">
      <li *ngFor="let option of selectableOptions$ | async" (click)="clickOnOption(option, $event)">
        {{optionLabel(option)}}
      </li>
    </ng-container>
  </ul>
</div>

