import { Component, Input, OnDestroy, OnInit,
  } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subject } from 'rxjs';
import { SessionService } from "../../../shared/services/session.service";
import { takeUntil } from "rxjs/operators";
import { ActionTypes } from "src/app/shared/models/role.interface";
import { RolesService } from "src/app/shared/services/roles.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "configuration-menu",
  templateUrl: "./configuration-menu.component.html",
  styleUrls: ["./configuration-menu.component.scss"],
})
export class ConfigurationMenuComponent implements OnInit, OnDestroy {
  @Input() defaultSectionKey: string;

  sections: { key: string; title: string }[];
  sections$: BehaviorSubject<{ key: string; title: string }[]> = new BehaviorSubject([]);
  activeSection: { key: string; title: string };
  activeUserRole: number;
  private destroy$ = new Subject<void>();

  constructor(
    private session: SessionService, 
    private router: Router,
    private rolesService: RolesService,
    private translate : TranslateService,
    ) {}

  ngOnInit(): void {
    this.session.userRole$.pipe(takeUntil(this.destroy$)).subscribe(userRole => {
      this.activeUserRole = userRole;
      this.configureSections();
      this.setActiveSection();
      this.sections$.next(this.sections);
    });

    // Reconfigure sections on language change
    this.translate.onLangChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.configureSections();
      this.setActiveSection();
      this.sections$.next(this.sections); // Emitir nueva lista de secciones
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private configureSections() {
    this.sections = [];

    this.sections.push({ key: "notifications", title: this.translate.instant("SETTINGS.NOTIFICATIONS")});

    if (this.rolesService.can(ActionTypes.ManageProblemTypes)){
      this.sections.push({ key: "problemTypes", title: this.translate.instant("SETTINGS.PROBLEM_TYPES")});
    }

    if (this.rolesService.can(ActionTypes.ManageCriticalityLevels)){
      this.sections.push({key: "criticalityLevels", title: this.translate.instant("SETTINGS.CRITICALITY_LEVELS")});
    }

    if (this.rolesService.can(ActionTypes.ManageFacilityInfo)){
      this.sections.push({key: "facilityInfo", title: this.translate.instant("SETTINGS.FACILITY_INFO")});
    }

    this.sections.push({ key: "general", title: this.translate.instant("SETTINGS.GENERAL")});
    
  }

  private setActiveSection() {
    if (this.defaultSectionKey) {
      const section = this.sections.find((s) => s.key === this.defaultSectionKey);
      this.activeSection = section ? section : this.sections[0];
    } else {
      this.activeSection = this.sections[0];
    }
  }

  selectedSection(section) {
    this.activeSection = section;
    const routeMap = {
      notifications: "/configuracion/notificaciones",
      criticalityLevels: "/configuracion/niveles-de-criticidad",
      problemTypes: "/configuracion/tipos-de-problemas",
      facilityInfo: "/configuracion/facility-info",
      general: "/configuracion/general"
    };
    this.router.navigate([routeMap[section.key]]);
  }
}
