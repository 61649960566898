import { ResponsiveService } from "../../services/responsive.service";
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AuthService } from "../../../auth/auth.service";
import { Facility } from "../../../facilities/models/facility.interface";
import { IUserLogged } from "../../models/i-user-logged";
import { ROLES } from "../../models/role.interface";
import { SessionService } from "../../services/session.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.scss"],
})
export class SideNavComponent implements OnInit, OnDestroy {
  // TODO: Eliminar las propiedades que estén en servicios y usar directamene el valor del servicio con async pipe

  loggedUser: IUserLogged;
  userRole: ROLES;
  userRoleDescription: string;

  facilities: Facility[];

  activeFacility: FormControl;

  sections = [
    {
      id: "dashboard",
      title: "NAV.DASHBOARD",
      icon: "dashboard",
      link: "/dashboard",
    },
    { 
      id: "viewer", 
      title: "NAV.VIEWER", 
      icon: "eye", 
      link: "/visualizador",
     },
     {
      id: "incidences",
      title: "NAV.REQUESTS",
      icon: "exclamation triangle",
      link: "/solicitudes",
    },
    {
      id: "workOrders",
      title: "NAV.WORK_ORDERS",
      icon: "hammer",
      link: "/ordenes-de-trabajo",
    },
    {
      id: "scheduledTasks",
      title: "NAV.SCHEDULED_TASKS",
      icon: "calendar alternate",
      link: "/tareas-programadas",
    },
    {
      id: "facilityElementsTypes",
      title: "NAV.COMPONENT_TYPES",
      icon: "boxes",
      link: "/tipos-componentes",
    },
    {
      id: "facilityElements",
      title: "NAV.COMPONENTS",
      icon: "layer dice d6",
      link: "/componentes",
    },
    {
      id: "treeView",
      title: "NAV.TREE_VIEW",
      icon: "sitemap",
      link: "/tree-view",
    },
    { id: "users", title: "NAV.USERS", icon: "user", link: "/usuarios" },
    {
      id: "userGroups",
      title: "NAV.USER_GROUPS",
      icon: "users",
      link: "/grupos-usuarios",
    },
    {
      id: "companies",
      title: "NAV.COMPANIES",
      icon: "briefcase",
      link: "/empresas",
    },
    { id: "reports", title: "NAV.REPORTS", icon: "chart area", link: "/reports" },
    { id: "messages", title: "NAV.MESSAGES", icon: "comments", link: "/mensajes" },
    {
      id: "config",
      title: "NAV.CONFIG",
      icon: "setting",
      link: "/configuracion",
    },
    {
      id: "logout",
      title: "NAV.LOGOUT",
      icon: "sign out alternate",
      action: this.logout.bind(this),
    },
  ];

  @Output() toggleButtonClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() closeButtonClicked: EventEmitter<null> = new EventEmitter<null>();

  private isMobile: boolean;
  private hasViewer: boolean;
  private destroy$ = new Subject<boolean>();

  constructor(
    private auth: AuthService,
    public sessionService: SessionService,
    private responsiveService: ResponsiveService,
    private translate: TranslateService,
  ) {
    this.activeFacility = new FormControl(null);
  }

  selectFacility(selected: Facility) {
    this.sessionService.setActiveFacility(selected);
  }

  logout() {
    this.auth.logout();
  }

  ngOnInit(): void {
    this.sessionService.userRole$
      .pipe(
        takeUntil(this.destroy$),
        filter((role) => role !== undefined && role !== null)
      )
      .subscribe((role) => {
        this.userRole = role;
      });

    this.sessionService.loggedUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.loggedUser = user;
      });

    this.sessionService.facilities$
      .pipe(takeUntil(this.destroy$))
      .subscribe((facilities) => {
        this.facilities = facilities;
      });

    this.sessionService.activeFacility$
    .pipe(takeUntil(this.destroy$))
    .subscribe(active => {
      if (active) {
        this.activeFacility.setValue(active, {emitEvent: false});
        this.hasViewer = active.externalFacilityObject != null ? true : false; 
      }
    })

    this.activeFacility.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.sessionService.setActiveFacility(value);
      });

    this.responsiveService.isMobile$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => (this.isMobile = isMobile));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  canView(id: string, role: ROLES) {
    if (id === "dashboard") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.CLIENT_USER,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === 'viewer') {
      return !this.isMobile && this.hasViewer;
    }
    if (role === ROLES.SUPER_ADMIN) {
      return true;
    }
    if (id === "dashboard") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.CLIENT_USER,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "incidences") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.CLIENT_USER,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "workOrders") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "scheduledTasks") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "facilityElements") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "treeView") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "facilityElementsTypes") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "userGroups") {
      return [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN].includes(
        role
      );
    }
    if (id === "companies") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "reports") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }
    if (id === "config") {
      return [
        ROLES.OWNER,
        ROLES.GLOBAL_SERVICE,
        ROLES.CLIENT_ADMIN,
        ROLES.CLIENT_USER,
        ROLES.MAINTENANCE_ADMIN,
        ROLES.MAINTENANCE_USER,
      ].includes(role);
    }

    return true;
  }

  clickedSection(section: any) {
    this.closeButtonClicked.emit();
    if (section.action) {
      section.action();
    }
  }
}
