
import { 
    ReportHoursByUserAndMaintenanceDTO, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessHoursByFinishedMaintenance extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }
    
    getData(){
        const {labels: userFullNameFinisher, dataA : correctiveHoursFinisher, dataB : scheduledHoursFinisher, 
            dataA2 : correctiveHoursFinisher2, dataB2 : scheduledHoursFinisher2
        } = this.getHoursByUserAndMaintenanceDataSets(this.reportGrossData as ReportHoursByUserAndMaintenanceDTO);
        
          const dataSetsHoursFinishedAndMaintenance = [{
            data: correctiveHoursFinisher, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_1"), stack: 'One date', order: 1},
            {data: scheduledHoursFinisher, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_1"), stack: 'One date', order: 2}];

          if (correctiveHoursFinisher2.length > 0) {
            dataSetsHoursFinishedAndMaintenance.push({data: correctiveHoursFinisher2, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_2"), stack: 'Other date', order: 1})
            dataSetsHoursFinishedAndMaintenance.push({data: scheduledHoursFinisher2, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_2"), stack: 'Other date', order: 2})
          }
          this.reports.hoursByUserAndMaintenance = {
            labels: userFullNameFinisher,
            dataSets: dataSetsHoursFinishedAndMaintenance
          };
          this.reports.showIncidenceStatusFilter = false;
 
    }
    
}