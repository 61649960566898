import {ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, Pipe, PipeTransform} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, of, Subject, Subscription} from 'rxjs';
import {FacilitiesService} from '../../../facilities/facilities.service';
import {SessionService} from '../../../shared/services/session.service';
import {map, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {CompaniesService} from '../../../companies/companies.service';
import {CompanyInterface} from '../../../companies/models/company.interface';
import {notEmptyValidator} from '../../shared/scheduled-tasks-validators';
import {ScheduledTaskService} from '../../scheduled-task.service';
import {PaginatedResult} from '../../../shared/models/paginated-result.interface';
import {Router} from '@angular/router';
import {ToastService} from '../../../shared/services/toast.service';
import {ScheduledTaskFilter} from '../../models/scheduled-task.interface';
import {formatFilterFromForm, formatInfoFromForm} from '../../shared/scheduled-tasks.utils';
import {FacilityComponentInterface} from '../../../facilities/models/facility-element.interface';
import {UserInterface} from '../../../users/models/user.interface';
import {ROLES} from '../../../shared/models/role.interface';
import { SpinnerVeilService } from 'src/app/shared/services/spinner-veil.service';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'asFormArray'
})
export class AsFormArrayPipe implements PipeTransform {
  transform(value: any, ...args: any[]): FormArray {
    return value;
  }
}

@Component({
  selector: 'app-add-scheduled-task',
  templateUrl: './add-scheduled-task.component.html',
  styleUrls: ['./add-scheduled-task.component.scss']
})
export class AddScheduledTaskComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui container stackable grid';

  activeSection = 'info';

  infoForm: FormGroup;
  filtersForm: FormGroup;
  filterParams : any;

  categories: CategoryInterface[];
  companies: CompanyInterface[];
  users: UserInterface[];

  createRequestData: object;
  infoData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  filterData$: BehaviorSubject<ScheduledTaskFilter>
    = new BehaviorSubject<{ inclusiveFilters: any[], exclusiveFilters: any[] }>(null);

  filteredComponents: FacilityComponentInterface[];
  numComponentsPerPage = 5;
  totalComponentsFiltered: number;
  componentsPage$ = new BehaviorSubject<number>(1);
  componentsTotalPages: number;

  destroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private toast: ToastService,
    private session: SessionService,
    private facilitySrv: FacilitiesService,
    private companiesSrv: CompaniesService,
    private scheduledTaskSrv: ScheduledTaskService,
    private veilService: SpinnerVeilService,
    private translate: TranslateService,
  ) {
    this.infoForm = fb.group({
      name: [null, notEmptyValidator],
      category: [null, Validators.required],
      company: [{value: null, disabled: true}, Validators.required],
      assignedTo: null,
      frequencyQuantity: [null, Validators.required],
      frequencyUnit: [null, Validators.required],
      createdAt: [null, Validators.required],
      strictDates: false,
      type: [null, notEmptyValidator],
    });

    this.filtersForm = fb.group({
      inclusiveFilters: fb.array([
        this.createConditionsFilterForm()
      ]),
      exclusiveFilters: fb.array([
        this.createConditionsFilterForm()
      ])
    });
  }

  createConditionsFilterForm(): FormArray {
    return this.fb.array([
      this.fb.group({
        filterParam: [null, Validators.required],
        value: [{value: null, disabled: true}, Validators.required]
      })
    ]);
  }
  private translateLabels() {
    this.translate.get('FILTER_PARAMS').subscribe(translations => {
      this.filterParams = [
        { value: 'assetIdentifier', name: translations.CODE },
        { value: 'floor', name: translations.FLOOR },
        { value: 'space', name: translations.SPACE },
        { value: 'type', name: translations.TYPE },
        { value: 'name', name: translations.COMPONENT }
      ];
    });
  }

  ngOnInit(): void {
    this.translateLabels();
    this.fetchCategories();
    this.enableCompanyFieldOnCategorySelectSubs();
    this.fetchCompaniesOnSelectCategorySubs();
    this.fetchUsersOnSelectCompanySubs();
    this.updateInfoDataOnChangeFormSubs();
    this.updateFiltersDataOnChangeFormSubs();
    this.updateCreateRequestDataOnInfoOrFilterChangeSubs();
    this.fetchTasksOnSelectResultsPageSubs();
    this.fetchFirstPageComponentResultOnChangeFilterSubs();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private fetchFirstPageComponentResultOnChangeFilterSubs(): Subscription {
    return this.filterData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.componentsPage$.next(1));
  }

  private fetchTasksOnSelectResultsPageSubs(): Subscription {
    return this.componentsPage$.pipe(
      takeUntil(this.destroy$),
      switchMap(
        page => {
          return combineLatest([
            this.filterData$.pipe(skipWhile(data => !data)),
            this.session.activeFacility$.pipe(skipWhile(facility => !facility), take(1))
          ]).pipe(
            switchMap(([filterData, facility]) => {
              return this.facilitySrv.fetchComponentsByFilter(filterData, {
                facilityId: facility.id,
                page,
                limit: this.numComponentsPerPage
              });
            })
          );
        }
      )
    ).subscribe((result: PaginatedResult<FacilityComponentInterface>) => {
      this.filteredComponents = result.items;
      this.componentsTotalPages = result.meta.totalPages;
      this.totalComponentsFiltered = result.meta.totalItems;
    });
  }

  private fetchCompaniesOnSelectCategorySubs() {
    return combineLatest([
      this.session.activeFacility$.pipe(skipWhile(facility => !facility)),
      this.infoForm.controls.category.valueChanges
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([facility, category]) => {
        if (!category) {
          return of([]);
        } else {
          return this.companiesSrv.fetchAll({
            facilityId: facility.id,
            companyTypeId: 2
          }).pipe(
            map((companies: CompanyInterface[]) => {
              return companies.filter(company => !!company.categories.find(cat => cat.categoryId === category.id));
            })
          );
        }
      })
    ).subscribe(companies => this.companies = companies);
  }

  private updateCreateRequestDataOnInfoOrFilterChangeSubs() {
    return combineLatest([
      this.infoData$,
      this.filterData$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([info, filtersData]) => {
        if (info && filtersData) {
          return Object.assign(info, {filter: filtersData});
        } else {
          return null;
        }
      }),
    ).subscribe(data => {
      return this.createRequestData = data
    });
  }

  private updateFiltersDataOnChangeFormSubs(): Subscription {
    return this.filtersForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.cd.detectChanges();
        this.filterData$.next(formatFilterFromForm(value));
      });
  }

  private updateInfoDataOnChangeFormSubs(): Subscription {
    return this.infoForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (this.infoForm.valid) {
          this.infoData$.next(formatInfoFromForm(value));
        } else {
          this.infoData$.next(null);
        }
      });
  }

  private fetchUsersOnSelectCompanySubs() {
    return combineLatest([
      this.infoForm.get('company').valueChanges.pipe(skipWhile(c => !c)),
      this.session.activeFacility$.pipe(skipWhile(f => !f))
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([company, facility]) => {
        return this.companiesSrv.fetchCompanyUsers(company.id, {facilityId: facility.id, roleIds: ROLES.MAINTENANCE_USER});
      })
    ).subscribe(users => this.users = users);
  }

  private enableCompanyFieldOnCategorySelectSubs(): Subscription {
    return this.infoForm.get('category').valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        if (value) {
          this.infoForm.get('company').enable();
        } else {
          this.infoForm.get('company').disable();
        }
      });
  }

  private fetchCategories() {
    this.companiesSrv.fetchCategories().pipe(
      map(categories => categories.filter(category => !category.parentCategoryId))
    ).subscribe(categories => this.categories = categories);
  }

  createScheduledTask() {
    this.veilService.openVeil();
    this.scheduledTaskSrv.addScheduledTask(this.createRequestData)
      .subscribe(
        () => {
        this.veilService.closeVeil();
        this.router.navigateByUrl('tareas-programadas').then(() => {
          this.toast.showToast({
            type: 'success', 
            message: this.translate.instant('SCHEDULED_TASK_DETAIL.CREATED_OK')
          });
        });
      },
      (error) => {
        this.veilService.closeVeil();
      }
      );
  }

  pageSelected(page: number) {
    this.componentsPage$.next(page);
  }
}
