import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {SpinnerModule} from '../spinner/spinner.module';
import {DropdownComponent} from './components/dropdown/dropdown.component';
import {SimpleSearchContentComponent} from './components/simple-search-content/simple-search-content.component';


@NgModule({
  declarations: [
    DropdownComponent,
    SimpleSearchContentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    SpinnerModule
  ],
  exports: [
    DropdownComponent
  ]
})
export class DropdownModule { }
