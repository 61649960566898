export enum REPORT_ID {
  COST_BY_CATEGORY = 'costsByCategory',
  HOURS_BY_COMPANY = 'hoursByCompany',
  COST_BY_FACILITY = 'costsByFacility',
  MATERIAL_COSTS_BREAKDOWN = 'materialCostsBrakeDown',
  INCIDENCE_EVO_BY_CATEGORY = 'evolutionIncidencesByCategory',
  TOTAL_INCIDENCES_BY_CATEGORY = 'totalIncidencesByCategory',
  COST_BY_PROBLEM_TYPE = 'costsByProblemType',
  COST_BY_COMPANY = 'costsByCompany',
  HOURS_BY_CATEGORY_MAINTENANCE = 'hoursByCategoryAndMaintenance',
  HOURS_BY_ASSIGNED_MAINTENANCE = 'hoursByAssignedUserAndMaintenance',
  HOURS_BY_FINISHED_MAINTENANCE = 'hoursByFinishedUserAndMaintenance',
  HOURS_BY_MAINTENANCECATEGORY = 'hoursByMaintenanceCategory',
  COUNT_BY_MAINTENANCECATEGORY_CREATED = 'countByMaintenanceCategoryCreated',
  COUNT_BY_MAINTENANCECATEGORY_FINISHED = 'countByMaintenanceCategoryFinished',
  SCHEDULEDTASK_FINISHED_BY_TYPE_CATEGORY = 'scheduledTaskFinishedByTypeCategory',
  AVG_SCORE_BY_COMPANY = 'avgScoreByCompany',
  COST_WORKER_HOUR_BY_COMPANY = 'costsWorkerHourByCompany',
  WORK_BY_COMPONENT = 'workByComponent',
  REPLACEMENT_COST_BY_TYPE = 'replacementCostByType',
  WORK_BY_SPACES = 'workBySpaces',
  COUNT_OTS_FINISHED_BY_CATEGORY = 'countOTsFinishedByCategory',
}

export interface ITableParams {
  name: string,
  key: string,
  type?: string,
}

export interface DateRange {start: Date; end: Date};

export interface ReportStatDTO {
  id: REPORT_ID;
  type: 'series';
  value:
    ReportEvolutionIncidencesByCategoryReg[] |
    ReportTableDataReg[] |
    ReportCostByCategoryReg[] |
    ReportCostByCompanyReg[] |
    ReportHoursByCategoryAndMaintenanceReg[] |
    ReportHoursByUserAndMaintenanceReg[] |
    ReportHoursByMaintenanceCategoryReg[] |
    ReportScheduledTaskFinishedByTypeCategoryReg[] |
    ReportCountByMaintenanceCategoryReg[] |
    ReportCostByProblemTypeReg[] |
    ReportHoursByCompanyReg[] |
    ReportAVGScoreByCompanyReg[] |
    ReportReplacementCostByTypeReg[] |
    ReportWorkByComponentReg[] |
    ReportWorkBySpacesReg[] |
    ReportCostByFacilityReg[] |
    ReportTotalIncidencesByCategoryReg[] |
    ReportCountOTsFinishedByCategoryReg[];
  value2?:
    ReportEvolutionIncidencesByCategoryReg[] |
    ReportTableDataReg[] |
    ReportCostByCompanyReg[] |
    ReportCostByCategoryReg[] |
    ReportHoursByMaintenanceCategoryReg[] |
    ReportScheduledTaskFinishedByTypeCategoryReg[] |
    ReportCountByMaintenanceCategoryReg[] |
    ReportHoursByCategoryAndMaintenanceReg[] |
    ReportHoursByUserAndMaintenanceReg[] |
    ReportCostByProblemTypeReg[] |
    ReportHoursByCompanyReg[] |
    ReportWorkByComponentReg[] |
    ReportWorkBySpacesReg[] |
    ReportReplacementCostByTypeReg[] |
    ReportAVGScoreByCompanyReg[] |
    ReportCostByFacilityReg[] |
    ReportTotalIncidencesByCategoryReg[] |
    ReportCountOTsFinishedByCategoryReg[];
}
export interface ReportEvolutionIncidenceByCategoryDTO extends ReportStatDTO {
  value: ReportEvolutionIncidencesByCategoryReg[];
  value2?: ReportEvolutionIncidencesByCategoryReg[];
}
export interface ReportMaterialCostBreakdownDTO extends ReportStatDTO {
  value: ReportTableDataReg[];
  value2: ReportTableDataReg[];
}
export interface ReportCostByCategoryDTO extends ReportStatDTO {
  value: ReportCostByCategoryReg[];
  value2: ReportCostByCategoryReg[];
}
export interface ReportHoursByCategoryAndMaintenanceDTO extends ReportStatDTO {
  value: ReportHoursByCategoryAndMaintenanceReg[];
  value2: ReportHoursByCategoryAndMaintenanceReg[];
}
export interface ReportHoursByUserAndMaintenanceDTO extends ReportStatDTO {
  value: ReportHoursByUserAndMaintenanceReg[];
  value2: ReportHoursByUserAndMaintenanceReg[];
}
export interface ReportHoursByMaintenanceCategoryDTO extends ReportStatDTO {
  value: ReportHoursByMaintenanceCategoryReg[];
  value2: ReportHoursByMaintenanceCategoryReg[];
}
export interface ReportScheduledTaskFinishedByTypeCategoryDTO extends ReportStatDTO {
  value: ReportScheduledTaskFinishedByTypeCategoryReg[];
  value2: ReportScheduledTaskFinishedByTypeCategoryReg[];
}
export interface ReportCountByMaintenanceCategoryDTO extends ReportStatDTO {
  value: ReportCountByMaintenanceCategoryReg[];
  value2: ReportCountByMaintenanceCategoryReg[];
}
export interface ReportCostByProblemTypeDTO extends ReportStatDTO {
  value: ReportCostByProblemTypeReg[];
  value2: ReportCostByProblemTypeReg[];
}
export interface ReportCostByCompanyDTO extends ReportStatDTO {
  value: ReportCostByCompanyReg[];
  value2: ReportCostByCompanyReg[];
}
export interface ReportHoursByCompanyDTO extends ReportStatDTO {
  value: ReportHoursByCompanyReg[];
  value2: ReportHoursByCompanyReg[];
}
export interface ReportAVGScoreByCompanyDTO extends ReportStatDTO {
  value: ReportAVGScoreByCompanyReg[];
  value2: ReportAVGScoreByCompanyReg[];
}
export interface ReportReplacementCostByTypeDTO extends ReportStatDTO {
  value: ReportReplacementCostByTypeReg[];
  value2: ReportReplacementCostByTypeReg[];
}
export interface ReportWorkByComponentDTO extends ReportStatDTO {
  value: ReportWorkByComponentReg[];
  value2: ReportWorkByComponentReg[];
}
export interface ReportCostByFacilityDTO extends ReportStatDTO {
  value: ReportCostByFacilityReg[];
  value2: ReportCostByFacilityReg[];
}
export interface ReportTotalIncidencesByCategoryDTO extends ReportStatDTO {
  value: ReportTotalIncidencesByCategoryReg[];
  value2: ReportTotalIncidencesByCategoryReg[];
}
export interface ReportCountOTsFinishedByCategoryDTO extends ReportStatDTO {
  value: ReportCountOTsFinishedByCategoryReg[];
  value2: ReportCountOTsFinishedByCategoryReg[];
}
export interface ReportWorkBySpacesDTO extends ReportStatDTO {
  value: ReportWorkBySpacesReg[];
  value2: ReportWorkBySpacesReg[];
}

export interface ReportEvolutionIncidencesByCategoryReg {
  categoryId: number;
  categoryName: string;
  day?: string;
  week?: string;
  month?: string;
  year?: string;
  value: string;
}

export interface ReportTableDataReg {
  categoryId: number;
  categoryName: string;
  closedAt: string;
  companyId: number;
  companyName: string;
  facilityId: number;
  facilityName: string;
  incidenceId: number;
  materialId: number;
  materialName: string;
  orderId: number;
  quantity: number;
  subcategoryId: number;
  subcategoryName: string;
  timeCost: string;
  unitCost: string ;
  workingTime: string;
}

export interface ReportCostByCategoryReg {
  categoryId: number;
  categoryName: string;
  materialCost: string;
  timeCost: string;
  totalCost: string;
}

export interface ReportCostByCompanyReg {
  companyId: number;
  companyName: string;
  materialCost: string;
  timeCost: string;
  totalCost: string;
  hourlyWorkerCost: string;
}

export interface ReportAVGScoreByCompanyReg {
  companyId: number;
  companyName: string;
  avgScore: string;
}

export interface ReportReplacementCostByTypeReg {
  componentTypeId: number;
  componentTypeName: string;
  countComponents: string;
  totalReplacementCost: string;
}

export interface ReportWorkByComponentReg {
  componentId: number;
  componentName: string;
  workOrdersCount: string;
  scheduledTaskCount: string;
  totalCount: string;
  workingTimeCorrective: string;
  workingTimeTasks: string;
  totalWorkingTime: string;
}
export interface ReportWorkBySpacesReg {
  spaceId: number;
  spaceName: string;
  workOrdersCount: string;
  scheduledTaskCount: string;
  totalCount: string;
  workingTimeCorrective: string;
  workingTimeTasks: string;
  totalWorkingTime: string;
}

export interface ReportHoursByCategoryAndMaintenanceReg {
  categoryId: number;
  categoryName: string;
  correctiveHours: string;
  scheduledHours: string;
  totalHours: string;
}

export interface ReportHoursByUserAndMaintenanceReg {
  userId: number;
  userFullName: string;
  correctiveHours: string;
  scheduledHours: string;
  totalCost: string;
}

export interface ReportHoursByMaintenanceCategoryReg {
  categoryId: number;
  categoryName: string;
  hours: string;
}

export interface ReportScheduledTaskFinishedByTypeCategoryReg {
  categoryId: number;
  categoryName: string;
  hours: string;
  count: string;
}

export interface ReportCountByMaintenanceCategoryReg {
  categoryId: number;
  categoryName: string;
  count: string;
}

export interface ReportCostByProblemTypeReg {
  problemType: string;
  countWorkOrders: number;
  hours: string;
  materialCost: string;
  timeCost: string;
  totalCost: string;
}

export interface ReportHoursByCompanyReg {
  companyId: number;
  companyName: string;
  correctiveHours: string;
  scheduledHours: string;
}

export interface ReportCostByFacilityReg {
  facilityId: number;
  facilityName: string;
  materialCost: string;
  timeCost: string;
}

export interface ReportTotalIncidencesByCategoryReg {
  categoryId: number;
  categoryName: string;
  countIncidences: string;
}
export interface ReportCountOTsFinishedByCategoryReg {
  categoryId: number;
  categoryName: string;
  count: string;
}
