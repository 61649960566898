import { NgModule } from '@angular/core';

import {UsersPageComponent} from './pages/users-page/users-page.component';
import {UserDetailPageComponent} from './pages/user-detail-page/user-detail-page.component';
import {AddUserPageComponent} from './pages/add-user-page/add-user-page.component';
import {UserFormComponent} from './components/user-form/user-form.component';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    UsersPageComponent,
    UserDetailPageComponent,
    AddUserPageComponent,

    UserFormComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    TranslateModule.forChild(),
  ]
})
export class UsersModule { }
