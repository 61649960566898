<div class="sixteen wide column">
  <h1 class="ui dividing header">
    {{ 'WORK_ORDER_DETAIL.TITLE' | translate }} ({{workOrder?.id}}): {{workOrder?.problemTypeName}}
  </h1>
</div>

<div *ngIf="canGoToIncidence()" class="three wide column">
  <div class="ui left labeled icon button" [routerLink]="['/solicitudes', workOrder?.incidenceId]">
    <i class="angle left icon"></i>
    {{ 'WORK_ORDER_DETAIL.GO_TO_REQUEST' | translate }}
  </div>
</div>

<div *ngIf="workOrder?.isWaiting()" class="thirteen wide column next-step">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'WORK_ORDER_DETAIL.NEXT_STEP_WAITING' | translate }}
  </p>
</div>

<div *ngIf="workOrder?.isInProgress()" class="thirteen wide column next-step">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'WORK_ORDER_DETAIL.NEXT_STEP_IN_PROGRESS' | translate }}
  </p>
</div>

<div *ngIf="workOrder?.isFinished()" class="thirteen wide column next-step">
  <p class="field-description">
    <i class="circle info icon"></i>
    {{ 'WORK_ORDER_DETAIL.NEXT_STEP_FINISHED' | translate }}
  </p>
</div>

<div class="sixteen wide column">
  <div class="ui stackable grid container">
    <div class="three wide column">
      <div class="ui fluid vertical menu">
        <a class="item" routerLinkActive="active" routerLink="./info">{{ 'MENU.INFO' | translate }}</a>
        <a class="item" routerLinkActive="active" routerLink="./chat">{{ 'MENU.MESSAGES' | translate }}</a>
        <a class="item" routerLinkActive="active" routerLink="./documentos">{{ 'MENU.FILES' | translate }}</a>
        <a class="item" routerLinkActive="active" routerLink="./componentes">{{ 'MENU.COMPONENTS' | translate }}</a>
        <a *ngIf="!workOrder?.isWaiting() && !workOrder?.isBlocked()" class="item" routerLinkActive="active" routerLink="./materiales">{{ 'MENU.DELIVERY_NOTE' | translate }}</a>
        <a *ngIf="roles.can('manageCosts') && !workOrder?.isWaiting() && !workOrder?.isBlocked()" class="item" routerLinkActive="active" routerLink="./costes">{{ 'MENU.COSTS' | translate }}</a>
      </div>
    </div>

    <div class="thirteen wide column">
      <div class="ui grid one column">
        <div class="column">
          <div *ngIf="workOrder?.isWaiting()"
               [class.disabled]="!canStartWorkOrder()"
               class="ui button options" 
               (click)="startWorkOrder()">
            {{ 'WORK_ORDER_DETAIL.START_ORDER' | translate }}
          </div>
          <div *ngIf="workOrder?.isInProgress()" 
            class="ui primary button options"
                [class.disabled]="!canFinishWorkOrder()"
                (click)="detailWorkOrder.finishWorkOrder()">{{ 'WORK_ORDER_DETAIL.FINISH_ORDER' | translate }}
          </div>
          <div *ngIf="canCloseWaitingOrder()"
               class="ui button options"
               (click)="detailWorkOrder.closeWorkOrder()">
            {{ 'WORK_ORDER_DETAIL.CLOSE_ORDER' | translate }}
          </div>
          <div class="ui primary button options" 
              *ngIf="canCloseOrder() | async"
              [class.disabled]="!detailWorkOrder.billingForm.valid"
              (click)="detailWorkOrder.closeWorkOrder()">
              {{ 'WORK_ORDER_DETAIL.CLOSE_ORDER' | translate }}
          </div>
          <div *ngIf="!workOrder?.isClosed()"
               class="ui right floated primary button options"
               (click)="detailWorkOrder.saveWorkOrderChanges()">
            {{ 'SAVE' | translate }}
          </div>
          <div *ngIf="true"
               class="ui button options"
               (click)="detailWorkOrder.downloadPDF()">
               <i class="download icon"></i>
            {{ 'BUTTON.DOWNLOAD_PDF' | translate }}
          </div>
        </div>

        <div class="column">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
