import { Component, HostBinding, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Component({
  selector: "app-config-problemType-page",
  templateUrl: "./config-problemType-page.component.html",
  styleUrls: ["./config-problemType-page.component.scss"],
})
export class ConfigProblemTypePageComponent implements OnInit {
  @HostBinding("class") hostClasses = "ui container grid";

  sections: { key: string; title: string }[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  activeSection: { key: string; title: string };

  constructor(
    private translate : TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.configureSections();
    this.activeSection = this.sections[1];
  }

  private configureSections() {
    this.sections = [
      { key: "notifications", title: this.translate.instant("SETTINGS.NOTIFICATIONS") },
      { key: "problemTypes", title: this.translate.instant("SETTINGS.PROBLEM_TYPES") },
      { key: "criticalityLevels", title: this.translate.instant("SETTINGS.CRITICALITY_LEVELS") },
      { key: "facilityInfo", title: this.translate.instant("SETTINGS.FACILITY_INFO") },
      { key: "general", title: this.translate.instant("SETTINGS.GENERAL") },
    ];
  }

}
