import {Component} from '@angular/core';

@Component({
    selector: 'app-menu-help',
    templateUrl: './menu-help.component.html',
    styleUrls: ['./menu-help.component.scss'],
})

export class HelpMenuComponent {
    isHelpMenuOpened: boolean = false;

    toggleMenu(): void {
        this.isHelpMenuOpened = !this.isHelpMenuOpened;
    }

    clickedOutside(): void {
        this.isHelpMenuOpened = false;
    }


}
