import {AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ChartDataSets} from 'chart.js';
import {Label} from 'ng2-charts';
import {BehaviorSubject, combineLatest, Observable, Subject, ReplaySubject} from 'rxjs';
import {delay, filter, map, skipWhile, startWith, switchMap, switchMapTo, take, takeUntil} from 'rxjs/operators';
import {CompaniesService} from '../../../companies/companies.service';
import {CompanyInterface} from '../../../companies/models/company.interface';
import { Facility } from '../../../facilities/models/facility.interface';
import {ReportsService} from '../../reports.service';
import {SessionService} from '../../../shared/services/session.service';
import {
  REPORT_ID,
  ReportEvolutionIncidenceByCategoryDTO,
  ReportTableDataReg,
} from '../../models/reports.inteface';
import {ReportsDateRangesService} from '../../reports-date-ranges.service';
import { RolesService } from 'src/app/shared/services/roles.service';
import { ROLES } from 'src/app/shared/models/role.interface';
import { INCIDENCE_STATES_LITERALS } from 'src/app/incidences/models/incidence.interface';
import { ProcessTotalIncidencesByCategory } from './utils/processTotalIncidencesByCategory';
import { ProcessHoursByCategoryMaintenance } from './utils/processHoursByCategoryMaintenance';
import { ProcessHoursByMaintenanceCategory } from './utils/processHoursByMaintenanceCategory';
import { ProcessHoursByCompany } from './utils/processHoursByCompany';
import { ProcessHoursByAssignedMaintenance } from './utils/processHoursByAssignedMaintenance';
import { ProcessHoursByFinishedMaintenance } from './utils/processHoursByFinishedMaintenance';
import { ProcessAvgScoreByCompany } from './utils/processAvgScoreByCompany';
import { ProcessWorkByComponent } from './utils/processWorkByComponent';
import { ProcessCostByCategory } from './utils/processCostByCategory';
import { ProcessCostByCompany } from './utils/processCostByCompany';
import { ProcessCostWorkerHourByCompany } from './utils/processCostWorkerHourByCompany';
import { ProcessCostByProblemType } from './utils/processCostByProblemType';
import { ProcessReplacementCostByType } from './utils/processReplacementCostByType';
import { ProcessCostByFacility } from './utils/processCostByFacility';
import { ProcessMaterialCostBreakDown } from './utils/processMaterialCostBreakdown';
import { ReportsHelperService } from './utils/processDataReport.utils';
import { ProcessEvolutionIncidencesByCategory } from './utils/processEvolutionIncidencesByCategory';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ColumnChartComponent } from 'src/app/charts/components/column-chart/column-chart.component';
import { LineChartComponent } from 'src/app/charts/components/line-chart/line-chart.component';
import { PieChartComponent } from 'src/app/charts/components/pie-chart/pie-chart.component';
import { StackChartComponent } from 'src/app/charts/components/stack-chart/stack-chart.component';
import { ReportTableComponent } from '../../components/report-table/report-table.component';
import { IColumnWidthMultiplier, IOptionsPDFImbi } from 'src/app/shared/services/utils/PDF.interface';
import { ProcessWorkBySpaces } from './utils/processWorkBySpaces';
import { ProcessCountByMaintenanceCategoryCreated } from './utils/processCountByMaintenanceCategoryCreated';
import { ProcessScheduledTaskFinishedByTypeCategory } from './utils/processScheduledTaskFinishedByTypeCategory';
import { ProcessCountByMaintenanceCategoryFinished } from './utils/processCountByMaintenanceCategoryFinished';
import { ProcessCountOTsFinishedByCategory } from './utils/processCountOTsFinishedByCategory';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [ReportsHelperService],
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui container stackable grid';

  @ViewChild('mainRangeDateCalendar') mainRangeDateCalendar;
  @ViewChild('compareRangeDateCalendar') compareRangeDateCalendar;
  @ViewChild(ColumnChartComponent) columnChartComponent: ColumnChartComponent;
  @ViewChild(LineChartComponent) lineChartComponent: LineChartComponent;
  @ViewChild(PieChartComponent) pieChartComponent: PieChartComponent;
  @ViewChild(StackChartComponent) stackChartComponent: StackChartComponent;
  @ViewChild(ReportTableComponent) tableComponent: ReportTableComponent;


  reportStats = REPORT_ID;
  showCompanyFilter: boolean = false;
  showIncidenceStatusFilter: boolean = false;
  activeStat$: BehaviorSubject<REPORT_ID> = new BehaviorSubject<REPORT_ID>(REPORT_ID.INCIDENCE_EVO_BY_CATEGORY);

  compareWithOtherRange: FormControl = new FormControl(false);
  datesRange$: Observable<{ start: Date, end: Date }>;
  compareDatesRange$: Observable<{ start: Date, end: Date }>;
  
  facilities: Facility[];
  activeFacility: FormControl;
  private _activeFacility: Facility;

  companies: CompanyInterface[];
  incidenceTypes : any;
  incidenceStatus : {value: number, name: string}[];
  userRole: ROLES;
  filters: FormGroup;
  evolutionIncidencesByCategory: ReportEvolutionIncidenceByCategoryDTO;
  userRoleSubject: ReplaySubject<any> = new ReplaySubject<any>(1);

  totalIncidencesByCategory: { labels: Label[]; dataSets: ChartDataSets[] };
  countOTsFinishedByCategory: { labels: Label[]; dataSets: ChartDataSets[] };
  costByCategory: { labels: Label[]; dataSets: ChartDataSets[] };
  costByCompanyAndMaintenance: { labels: Label[]; dataSets: ChartDataSets[] };
  hoursByCategoryAndMaintenance: { labels: Label[]; dataSets: ChartDataSets[] };
  hoursByUserAndMaintenance: { labels: Label[]; dataSets: ChartDataSets[] };
  hoursByMaintenanceCategory: { labels: Label[]; dataSets: ChartDataSets[] };
  scheduledTaskFinishedByTypeCategory: { labels: Label[]; dataSets: ChartDataSets[] };
  countByMaintenanceCategoryCreated: { labels: Label[]; dataSets: ChartDataSets[] };
  countByMaintenanceCategoryFinished: { labels: Label[]; dataSets: ChartDataSets[] };
  costByProblemType: { labels: Label[]; dataSets: ChartDataSets[] };
  costByCompany: { labels: Label[]; dataSets: ChartDataSets[] };
  costsWorkerHourByCompany: { labels: Label[]; dataSets: ChartDataSets[] };
  hoursByCompany: { labels: Label[]; dataSets: ChartDataSets[] };
  avgScoreByCompany: { labels: Label[]; dataSets: ChartDataSets[] };
  replacementCostByType: { labels: Label[]; dataSets: ChartDataSets[] };
  workByComponent: { labels: Label[]; dataSets: ChartDataSets[] };
  workBySpaces: { labels: Label[]; dataSets: ChartDataSets[] };
  costByFacility: { labels: Label[]; dataSets: ChartDataSets[] };

  tableDataProcessedDate1: ReportTableDataReg[];
  tableDataProcessedDate2: ReportTableDataReg[];
  tableDataTotalItems: number;
  tableDataGroupedItems: any[];
  tableDataParams = [];
  tableDataMainKey: string;

  destroy$: Subject<boolean> = new Subject<boolean>();

  menuLateral: any;

  constructor(
    public session: SessionService,
    private companiesService: CompaniesService,
    private reports: ReportsService,
    public dateRangesSrv: ReportsDateRangesService,
    public fb: FormBuilder,
    public roles: RolesService,
    public reportsHelperService: ReportsHelperService,
    public utilsService: UtilsService,
    public translate: TranslateService,
  ) {
    this.filters = fb.group({
      companies: [null],
      incidenceTypes: [null],
      incidenceStatus: [null],
      facilities: [null]
    });
    this.activeFacility = new FormControl(null);


    this.incidenceStatus = Object.keys(INCIDENCE_STATES_LITERALS)
      .map(key => {
        return {
          value: Number(key),
          name: INCIDENCE_STATES_LITERALS[key] as string
        };
      });
  }

  selectFacility(selected: Facility) {
    this.session.setActiveFacility(selected);
  }

  private translateOptions() {
    this.translate.get('REQUESTS_TYPE').subscribe(translations => {
      this.incidenceTypes = [
        {value: 0, name: translations.NO_TYPE},
        {value: 1, name: translations.INCIDENCE},
        {value: 2, name: translations.SERVICE_REQUEST}
      ];
    });

    this.translate.get('INCIDENCE_STATES').subscribe(translations => {
      this.incidenceStatus = [
        {value: 1, name: translations.CREATED},
        {value: 2, name: translations.OPENED},
        {value: 3, name: translations.ASSIGNED},
        {value: 4, name: translations.FINISHED},
        {value: 5, name: translations.CLOSED},
        {value: 6, name: translations.VALUED},
      ];
    });
  }

  private tranlateMenuLateral(){
      this.translate.get('REPORTS').subscribe(translations => {
        this.menuLateral = [
          {
            name: translations.PERFORMANCE,
            opened: true,
            subMenu: [
              {
                name: translations.INCIDENCE_EVO_BY_CATEGORY_TITLE,
                key: 'INCIDENCE_EVO_BY_CATEGORY'
              },
              {
                name: translations.TOTAL_INCIDENCES_BY_CATEGORY_TITLE,
                key: 'TOTAL_INCIDENCES_BY_CATEGORY'
              },
              {
                name: translations.COUNT_OTS_FINISHED_BY_CATEGORY_TITLE,
                key: 'COUNT_OTS_FINISHED_BY_CATEGORY',
                whoCan: 'manageTime',
              },
              {
                name: translations.HOURS_BY_CATEGORY_MAINTENANCE_TITLE,
                key: 'HOURS_BY_CATEGORY_MAINTENANCE',
                whoCan: 'manageTime',
              },
              {
                name: translations.HOURS_BY_MAINTENANCECATEGORY_TITLE,
                key: 'HOURS_BY_MAINTENANCECATEGORY',
                whoCan: 'manageTime',
              },
              {
                name: translations.COUNT_BY_MAINTENANCECATEGORY_CREATED_TITLE,
                key: 'COUNT_BY_MAINTENANCECATEGORY_CREATED',
                whoCan: 'manageTime',
              },
              {
                name: translations.COUNT_BY_MAINTENANCECATEGORY_FINISHED_TITLE,
                key: 'COUNT_BY_MAINTENANCECATEGORY_FINISHED',
                whoCan: 'manageTime',
              },
              {
                name: translations.SCHEDULEDTASK_FINISHED_BY_TYPE_CATEGORY_TITLE,
                key: 'SCHEDULEDTASK_FINISHED_BY_TYPE_CATEGORY',
                whoCan: 'manageTime',
              },
              {
                name: translations.HOURS_BY_COMPANY_TITLE,
                key: 'HOURS_BY_COMPANY',
                whoCan: 'manageTime',
              },
              {
                name: translations.HOURS_BY_ASSIGNED_MAINTENANCE_TITLE,
                key: 'HOURS_BY_ASSIGNED_MAINTENANCE',
                whoCan: 'manageTime',
              },
              {
                name: translations.HOURS_BY_FINISHED_MAINTENANCE_TITLE,
                key: 'HOURS_BY_FINISHED_MAINTENANCE',
                whoCan: 'manageTime',
              },
              {
                name: translations.AVG_SCORE_BY_COMPANY_TITLE,
                key: 'AVG_SCORE_BY_COMPANY',
                whoCan: 'manageTime',
              },
              {
                name: translations.TASKS_BY_COMPONENT_TITLE,
                key: 'WORK_BY_COMPONENT',
                whoCan: 'manageTime',
              },
              {
                name: translations.TASKS_BY_SPACES_TITLE,
                key: 'WORK_BY_SPACES',
                whoCan: 'manageTime',
              },
            ]
          },
          {
            name: translations.ECONOMICS,
            opened: true,
            can: true,
            canName: 'manageCosts',
            subMenu: [
              {
                name: translations.COST_BY_CATEGORY_TITLE,
                key: 'COST_BY_CATEGORY',
                whoCan: 'manageCosts',
              },
              {
                name: translations.COST_BY_COMPANY_TITLE,
                key: 'COST_BY_COMPANY',
                whoCan: 'manageCosts',
              },
              {
                name: translations.COST_WORKER_HOUR_BY_COMPANY_TITLE,
                key: 'COST_WORKER_HOUR_BY_COMPANY',
                whoCan: 'manageCosts',
              },
              {
                name: translations.COST_BY_PROBLEM_TYPE_TITLE,
                key: 'COST_BY_PROBLEM_TYPE',
                whoCan: 'manageCosts',
              },
              {
                name: translations.REPLACEMENT_COST_BY_TYPE_TITLE,
                key: 'REPLACEMENT_COST_BY_TYPE',
                whoCan: 'manageCostsFacility',
              },
              {
                name: translations.COST_BY_FACILITY_TITLE,
                key: 'COST_BY_FACILITY',
                whoCan: 'manageCosts',
              },
              {
                name: translations.MATERIAL_COSTS_BREAKDOWN_TITLE,
                key: 'MATERIAL_COSTS_BREAKDOWN',
                whoCan: 'manageCosts',
              },
            ]
          }
        ];
      });
    }
  

  ngOnInit(): void {

    this.translateOptions();
    this.tranlateMenuLateral();
    
    this.session.facilities$
    .pipe(takeUntil(this.destroy$))
    .subscribe((facilities) => {
      this.facilities = facilities;
    })

    this.dateRangesSrv.getDateRange('main')
      .subscribe(range => {
        if (!range) {
          this.dateRangesSrv.setLastMonthAsRange('main');
        }
      });

    this.datesRange$ = this.dateRangesSrv.mainRange$;
    this.compareDatesRange$ = combineLatest([
      this.dateRangesSrv.compareRange$,
      this.compareWithOtherRange.valueChanges
    ]).pipe(
      map(([range, compareRanges]) => {
        return range && compareRanges ? range : null;
      })
    );
    
    const facilityChange$ = this.session.activeFacility$.pipe(
      filter(facility => !!facility),
      takeUntil(this.destroy$)
    );
    
    // Retrieve the user role from the session and emit it to the subject
    this.session.userRole$
    .pipe(
      filter(role => role !== null && role !== undefined),
      take(1),
      takeUntil(this.destroy$)
    )
    .subscribe(role => this.userRoleSubject.next(role));

    // Subscribe to the subject to get the userRole value
    this.userRoleSubject
      .pipe(take(1))
      .subscribe(role => {
        this.userRole = role;

        // Check userRole
        if ([ROLES.OWNER,ROLES.GLOBAL_SERVICE].includes(this.userRole) ){
          this.showCompanyFilter = true;
        }
      });

    facilityChange$.pipe(
      switchMap(facility => this.companiesService.fetchAllCompanies({facilityId: facility.id, companyTypeId: 2}))
    ).subscribe((companies: CompanyInterface[]) => {
      this.companies = companies;
    });
      
     
   combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.activeStat$.pipe(skipWhile(stat => !stat)),
      this.datesRange$.pipe(skipWhile(r => !r)),
      this.compareDatesRange$.pipe(startWith(null)),
      this.filters.valueChanges
    ])
      .pipe(
        takeUntil(this.destroy$),
        switchMap(([facility, stat, dateRange, compareRange, {facilities, companies, incidenceTypes, incidenceStatus}]) => {
          const facilityIds = this.facilities.length==0 ?[facility.id]: this.facilities.map(facility => facility.id);
          const companyIds = companies? (companies as CompanyInterface[]).map(company => company.id):null;
          const typeIds = incidenceTypes ? incidenceTypes.map(type => type.value) : null;
          const statusIds = incidenceStatus ? incidenceStatus.map(status => status.value) : null;
          const myCompareRange = compareRange;
          this._activeFacility = facility;
          const activeFacilityId = facility.id;
          return this.reports.fetchReport(
            stat,
            dateRange.start,
            dateRange.end,
            {activeFacilityId, companyIds, typeIds, statusIds, facilityIds},
            myCompareRange?.start,
            myCompareRange?.end);
        })
      )
      .subscribe((report) => {
        
        if (report) {
          switch (report.id) {
            // PERFORMANCE REPORTS=============================================
            case REPORT_ID.INCIDENCE_EVO_BY_CATEGORY:
              new ProcessEvolutionIncidencesByCategory(this, report).getData();
              break;

            case REPORT_ID.TOTAL_INCIDENCES_BY_CATEGORY:
              new ProcessTotalIncidencesByCategory(this, report).getData();
              break;
            
            case REPORT_ID.COUNT_OTS_FINISHED_BY_CATEGORY:
              new ProcessCountOTsFinishedByCategory(this, report).getData();
              break;

            case REPORT_ID.HOURS_BY_CATEGORY_MAINTENANCE:
              new ProcessHoursByCategoryMaintenance(this, report).getData();
              break;

            case REPORT_ID.HOURS_BY_MAINTENANCECATEGORY:
              new ProcessHoursByMaintenanceCategory(this, report).getData();
              break;

            case REPORT_ID.COUNT_BY_MAINTENANCECATEGORY_CREATED:
              new ProcessCountByMaintenanceCategoryCreated(this, report).getData();
              break;

            case REPORT_ID.COUNT_BY_MAINTENANCECATEGORY_FINISHED:
              new ProcessCountByMaintenanceCategoryFinished(this, report).getData();
              break;

            case REPORT_ID.SCHEDULEDTASK_FINISHED_BY_TYPE_CATEGORY:
              new ProcessScheduledTaskFinishedByTypeCategory(this, report).getData();
              break;

            case REPORT_ID.HOURS_BY_COMPANY:
              new ProcessHoursByCompany(this, report).getData();
              break;

            case REPORT_ID.HOURS_BY_ASSIGNED_MAINTENANCE:
              new ProcessHoursByAssignedMaintenance(this, report).getData();
              break;

            case REPORT_ID.HOURS_BY_FINISHED_MAINTENANCE:
              new ProcessHoursByFinishedMaintenance(this, report).getData();
              break;

            case REPORT_ID.AVG_SCORE_BY_COMPANY:
              new ProcessAvgScoreByCompany(this, report).getData();
              break;

            case REPORT_ID.WORK_BY_COMPONENT:
              new ProcessWorkByComponent(this, report).getData();
              break;
            
            case REPORT_ID.WORK_BY_SPACES:
              new ProcessWorkBySpaces(this, report).getData();
              break;

            // ECONOMICS REPORTS=============================================

            case REPORT_ID.COST_BY_CATEGORY:
              new ProcessCostByCategory(this, report).getData();
              break;
            

            case REPORT_ID.COST_BY_COMPANY:
              new ProcessCostByCompany(this, report).getData();
              break;

            case REPORT_ID.COST_WORKER_HOUR_BY_COMPANY:
              new ProcessCostWorkerHourByCompany(this, report).getData();
               break;

            case REPORT_ID.COST_BY_PROBLEM_TYPE:
              new ProcessCostByProblemType(this, report).getData();
              break;
        
            case REPORT_ID.REPLACEMENT_COST_BY_TYPE:
              new ProcessReplacementCostByType(this, report).getData();
             break;

            case REPORT_ID.COST_BY_FACILITY:
              new ProcessCostByFacility(this, report).getData();
              break;

            case REPORT_ID.MATERIAL_COSTS_BREAKDOWN:
              new ProcessMaterialCostBreakDown(this, report).getData();
              break;
          }
        }

      });
 
    this.filters.setValue({
      companies: null,
      incidenceTypes: null,
      incidenceStatus: null,
      facilities: null
    });

  }

  ngAfterViewInit() {
    this.datesRange$.pipe(
      skipWhile(r => !r),
      take(1)
    ).subscribe(range => {
      this.mainRangeDateCalendar.setStartDate(range.start, true, false);
      this.mainRangeDateCalendar.setEndDate(range.end, true, false);
    });

    this.compareWithOtherRange.valueChanges
      .pipe(
        filter(value => !!value),
        takeUntil(this.destroy$),
        delay(500),
        switchMapTo(this.dateRangesSrv.getDateRange('compare'))
      )
      .subscribe(range => {
        this.compareRangeDateCalendar.setStartDate(range.start, true, false);
        this.compareRangeDateCalendar.setEndDate(range.end, true, false);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  mainDateChange(date: 'start' | 'end', value: Date) {
    this.dateRangesSrv.setMainDate(date, value);
  }

  compareDateChange(date: 'start' | 'end', value: Date) {
    this.dateRangesSrv.setCompareDate(date, value);
  }

  selectStat(stat: REPORT_ID) {
    this.activeStat$.next(stat);
  }

  getCurrentActiveStat(): REPORT_ID {
    return this.activeStat$.getValue();
  }

  downloadReportCSV() {

    const companies = this.filters.value.companies 
      ? (this.filters.value.companies as CompanyInterface[]).map(company => company.id) 
      : null;
    
    const incidenceTypes = this.filters.value.incidenceTypes 
      ? this.filters.value.incidenceTypes.map(type => type.value) 
      : null;

    this.reportsHelperService.downloadReportCSV(
      this.getCurrentActiveStat(),
      this.dateRangesSrv.mainRange$.value,
      this.dateRangesSrv.compareRange$.value,
      this.compareWithOtherRange.value,
      companies,
      incidenceTypes
    );
    
  }

  async downloadPDF() {
    let options : IOptionsPDFImbi;
    
    if (this.tableComponent
      ){ //there is a table

      switch(this.tableComponent.reportId){
        case REPORT_ID.REPLACEMENT_COST_BY_TYPE:
          this.getTableData(this.getCurrentActiveStat()).subscribe(blob => {
            const chartCanvas = this.columnChartComponent.getChartCanvas();
            //define column width by column keys and width multiplier
            const columnWidthMultiplier : IColumnWidthMultiplier[] = [
              
              {key:"componentTypeName", factor: 16 },
              {key:"countComponents", factor: 6 },
              {key:"totalReplacementCost", factor: 6 },
            ];
            options  = {
              fontSize : 9,
              facilityName: this._activeFacility.name,
              title: this.tableComponent.title,
              firstDateRange: this.dateRangesSrv.mainRange$.value,
              currencySymbol: this.tableComponent.currencySymbol,
              chart : {
                chartCanvas,
                format: 'a4',
                orientation: 'portrait',
              },
              table : {
                tableData : blob,
                tableParams : this.tableComponent.tableParams,
                columnWidthMultiplier,
                format: 'a4',
                orientation: "landscape",
                rowsPerPage: 15,
              },
            }

            this.reportsHelperService.downloadReportPDF(options);
          });
        break;

        case REPORT_ID.COST_BY_PROBLEM_TYPE:
          this.getTableData(this.getCurrentActiveStat()).subscribe(blob => {
            const chartCanvas = this.stackChartComponent.getChartCanvas();
            //define column width by column keys and width multiplier
            const columnWidthMultiplier : IColumnWidthMultiplier[] = [
              
              {key:"problemType", factor: 6 },
              {key:"countWorkOrders", factor: 4 },
              {key:"hours", factor: 4 },
              {key:"materialCost", factor: 4 },
              {key:"timeCost", factor: 4 },
              {key:"totalCost", factor: 4 },
            ];
            options  = {
              fontSize : 9,
              facilityName: this._activeFacility.name,
              title: this.tableComponent.title,
              firstDateRange: this.dateRangesSrv.mainRange$.value,
              currencySymbol: this.tableComponent.currencySymbol,
              chart : {
                chartCanvas,
                format: 'a4',
                orientation: 'portrait',
              },
              table : {
                tableData : blob,
                tableParams : this.tableComponent.tableParams,
                columnWidthMultiplier,
                format: 'a4',
                orientation: "landscape",
                rowsPerPage: 15,
              },
            }

            this.reportsHelperService.downloadReportPDF(options);
          });
        break;
        
        case REPORT_ID.COST_BY_COMPANY:
        case REPORT_ID.COST_WORKER_HOUR_BY_COMPANY:
        case REPORT_ID.COST_BY_CATEGORY:
          this.getTableData(this.getCurrentActiveStat()).subscribe(blob => {
            let chartCanvas : HTMLCanvasElement;
            if (this.getCurrentActiveStat() === REPORT_ID.COST_BY_COMPANY){
              chartCanvas = this.stackChartComponent.getChartCanvas();
            }
            if (this.getCurrentActiveStat() === REPORT_ID.COST_WORKER_HOUR_BY_COMPANY){
              chartCanvas = this.columnChartComponent.getChartCanvas();
            }
            if (this.getCurrentActiveStat() === REPORT_ID.COST_BY_CATEGORY){
              chartCanvas = this.stackChartComponent.getChartCanvas();
            }
            
            //define column width by column keys and width multiplier
            const columnWidthMultiplier : IColumnWidthMultiplier[] = [
              {key:"categoryName", factor: 6 },
              {key:"companyName", factor: 6 },
              {key:"countWorkOrders", factor: 3 },
              {key:"hours", factor: 4 },
              {key:"materialCost", factor: 4 },
              {key:"timeCost", factor: 3 },
              {key:"totalCost", factor: 4 },
              {key:"hourlyWorkerCost", factor: 4 },
            ];
            options  = {
              fontSize : 9,
              facilityName: this._activeFacility.name,
              title: this.tableComponent.title,
              firstDateRange: this.dateRangesSrv.mainRange$.value,
              currencySymbol: this.tableComponent.currencySymbol,
              chart : {
                chartCanvas,
                format: 'a4',
                orientation: 'portrait',
              },
              table : {
                tableData : blob,
                tableParams : this.tableComponent.tableParams,
                columnWidthMultiplier,
                format: 'a4',
                orientation: "landscape",
                rowsPerPage: 15,
              },
            }

            this.reportsHelperService.downloadReportPDF(options);
          });
        break;

        case REPORT_ID.WORK_BY_SPACES:
        case REPORT_ID.WORK_BY_COMPONENT:
          this.getTableData(this.getCurrentActiveStat()).subscribe(blob => {
            const chartCanvas = this.stackChartComponent.getChartCanvas();
            //define column width by column keys and width multiplier
            const columnWidthMultiplier : IColumnWidthMultiplier[] = [
              {key:"componentName", factor: 6 },
              {key:"spaceName", factor: 6 },
              {key:"workOrdersCount", factor: 3 },
              {key:"scheduledTaskCount", factor: 3 },
              {key:"totalCount", factor: 4 },
              {key:"workingTimeCorrective", factor: 4 },
              {key:"workingTimeTasks", factor: 4 },
              {key:"totalWorkingTime", factor: 4 },
            ];
            options  = {
              fontSize : 9,
              facilityName: this._activeFacility.name,
              title: this.tableComponent.title,
              firstDateRange: this.dateRangesSrv.mainRange$.value,
              currencySymbol: this.tableComponent.currencySymbol,
              chart : {
                chartCanvas,
                format: 'a4',
                orientation: 'portrait',
              },
              table : {
                tableData : blob,
                tableParams : this.tableComponent.tableParams,
                columnWidthMultiplier,
                format: 'a4',
                orientation: "landscape",
                rowsPerPage: 15,
              },
            }

            this.reportsHelperService.downloadReportPDF(options);
          });
        break;

        case REPORT_ID.MATERIAL_COSTS_BREAKDOWN:
          this.getTableData(this.getCurrentActiveStat()).subscribe(blob => {
            //define column width by column keys and width multiplier
            const columnWidthMultiplier : IColumnWidthMultiplier[] = [
              {key:"facilityName", factor: 2 },
              {key:"companyName", factor: 3 },
              {key:"categoryName", factor: 4 },
              {key:"subcategoryName", factor: 4 },
              {key:"materialName", factor: 4 },
              {key:"closedAt", factor: 2 },
              {key:"workingTime", factor: 3 },
              {key:"timeCost", factor: 2 },
              {key:"quantity", factor: 2 },
              {key:"unitCost", factor: 2 },
            ];
            options = {
              fontSize : 9,
              facilityName: this._activeFacility.name,
              title: this.tableComponent.title,
              firstDateRange: this.dateRangesSrv.mainRange$.value,
              currencySymbol: this.tableComponent.currencySymbol,
              table : {
                tableData : blob,
                tableParams : this.tableComponent.tableParams,
                columnWidthMultiplier,
                format: 'a4',
                orientation: "landscape",
                rowsPerPage: 15,
              }
            };

            this.reportsHelperService.downloadReportPDF(options);
          });
        break;
      }//end switch
      
    }else{ //there is only a chart
      let chartCanvas: HTMLCanvasElement;
      let title : string;
      if (this.columnChartComponent) {
        chartCanvas = this.columnChartComponent.getChartCanvas();
        title = this.columnChartComponent.title;
      }else if(this.lineChartComponent){
        chartCanvas = this.lineChartComponent.getChartCanvas();
        title = this.lineChartComponent.title;
      }else if(this.pieChartComponent){
        chartCanvas = this.pieChartComponent.getChartCanvas();
        title = this.pieChartComponent.title;
      }else if(this.stackChartComponent){
        chartCanvas = this.stackChartComponent.getChartCanvas();
        title = this.stackChartComponent.title;
      }else {
        console.error(`Chart component not found.`);
      }

      options  = {
        fontSize : 9,
        facilityName: this._activeFacility.name,
        title,
        firstDateRange: this.dateRangesSrv.mainRange$.value,
        chart : {
          chartCanvas,
          format: 'a4',
          orientation: 'portrait',
        },
      }

      this.reportsHelperService.downloadReportPDF(options);
    } 
    
  }

  private getTableData(reportName: REPORT_ID): Observable<Blob>{
    const companies = this.filters.value.companies 
      ? (this.filters.value.companies as CompanyInterface[]).map(company => company.id) 
      : null;
    
    const incidenceTypes = this.filters.value.incidenceTypes 
      ? this.filters.value.incidenceTypes.map(type => type.value) 
      : null;

    return this.reportsHelperService.dataReport(
      reportName,
      this.dateRangesSrv.mainRange$.value,
      this.dateRangesSrv.compareRange$.value,
      this.compareWithOtherRange.value,
      companies,
      incidenceTypes,
    ).pipe(blob => blob);
  }
}
