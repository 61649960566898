
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as saveAs from "file-saver";
import { DateTime } from "luxon";
import { Observable } from "rxjs";
import { map, skipWhile, switchMap, take } from "rxjs/operators";
import { Facility } from "src/app/facilities/models/facility.interface";
import { DateRange, REPORT_ID,
 } from "src/app/reports/models/reports.inteface";
import { ReportsService } from "src/app/reports/reports.service";
import { SessionService } from "src/app/shared/services/session.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { IOptionsPDFImbi } from "src/app/shared/services/utils/PDF.interface";
import { PDFImbi } from "src/app/shared/services/utils/PDFImbi.utils";

@Injectable({
    providedIn: 'root',
  })
  export class ReportsHelperService {
    private activeFacility: Facility;
    constructor(
      private session: SessionService,
      private reports: ReportsService,
      private toastService: ToastService,
      private translate : TranslateService,
    ) {}
// ----------------------------------------------------------------------------
    downloadReportCSV(
        reportName: REPORT_ID,
        firstDateRange: DateRange,
        secondRangeDate: DateRange,
        isCompareActive: boolean,
        companies: number[],
        incidenceTypes: number[],
    ): void{
      this.session.activeFacility$.pipe(
        skipWhile(facility => !facility),
        take(1),
        switchMap(facility => {
            this.activeFacility = facility;
            if (isCompareActive){
              this.toastService.showToast({
                  type: 'information',
                  message: this.translate.instant("REPORTS.DATA_LIMITED"),
                  duration: 6000
              });
            }
            return this.reports.getReportDataCSV(
              reportName,
              firstDateRange.start, 
              firstDateRange.end, 
              [facility.id], 
              companies, 
              incidenceTypes, 
              secondRangeDate.start, 
              secondRangeDate.end,
              );
       })
      ).subscribe(data => {
          const blob =  new Blob([data], {type: 'text/csv;charset=utf-8'});
          const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
          const fileName = `${reportName}-${date}.csv`;
          saveAs(blob, fileName);
      });  
        
    }
// ----------------------------------------------------------------------------
  async downloadReportPDF(options : IOptionsPDFImbi){
    const pdfImbi = new PDFImbi(this.translate, options);
    await pdfImbi.build();
    const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
    const fileName = `${options.title}-${date}`;
    pdfImbi.savePDF(fileName);
  }
// ----------------------------------------------------------------------------
  dataReport(
      reportName: REPORT_ID,
      firstDateRange: DateRange,
      secondRangeDate: DateRange,
      isCompareActive: boolean,
      companies: number[],
      incidenceTypes: number[],
  ): Observable<Blob>{
    return this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        this.activeFacility = facility;
        if (isCompareActive) {
          this.toastService.showToast({
            type: 'information',
            message: this.translate.instant("REPORTS.DATA_LIMITED"),
            duration: 6000
          });
        }
        return this.reports.getReportDataCSV(
          reportName,
          firstDateRange.start, 
          firstDateRange.end, 
          [facility.id], 
          companies, 
          incidenceTypes, 
          secondRangeDate?.start, 
          secondRangeDate?.end,
          );
      }),
      map(data => new Blob([data], { type: 'text/csv;charset=utf-8' }))
    );   
  }
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
}