import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FomanticUiModule} from '../fomantic-ui/fomantic-ui.module';
import {NgProtoUiModule} from '../../../projects/ng-proto-ui/src/lib/ng-proto-ui.module';
import {CollapsibleFormComponent} from './components/collapsible-form/collapsible-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {ToastComponent} from './components/toast/toast.component';
import {ChatComponent} from './components/chat/chat.component';
import {PaginationNumItemsInfoComponent} from './components/pagination-num-items-info/pagination-num-items-info.component';
import {NumericDirective} from './directives/numeric/numeric.directive';
import {ElementInfoPanelComponent} from './components/element-info-panel/element-info-panel.component';
import {QrReaderModalComponent} from './components/qr-reader-modal/qr-reader-modal.component';
import {ViewerElementSelectorComponent} from './components/viewer-element-selector/viewer-element-selector.component';
import {HelpMenuComponent} from './components/menu-help/menu-help.component'
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { MenuProfileComponent } from './components/menu-profile/menu-profile.component';
import { MenuMessagesComponent } from './components/menu-messages/menu-messages.component';
import { MenuNewIncidenceComponent } from './components/menu-new-incidence/menu-new-incidence.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { LabelInfoComponent } from './components/label-and-info/label-and-info.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    LayoutComponent,
    SideNavComponent,
    ToastComponent,

    ChatComponent,
    CollapsibleFormComponent,
    PaginationNumItemsInfoComponent,
    NumericDirective,

    ElementInfoPanelComponent,
    QrReaderModalComponent,
    ViewerElementSelectorComponent,
    HelpMenuComponent,
    ClickOutsideDirective,
    MenuProfileComponent,
    MenuMessagesComponent,
    MenuNewIncidenceComponent,
    CustomSelectComponent,
    LabelInfoComponent,
  ],
  imports: [
    CommonModule,
    FomanticUiModule,
    NgProtoUiModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  exports: [
    CommonModule,
    FomanticUiModule,
    NgProtoUiModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    LayoutComponent,
    CollapsibleFormComponent,
    ChatComponent,
    PaginationNumItemsInfoComponent,
    NumericDirective,

    ElementInfoPanelComponent,
    QrReaderModalComponent,
    ViewerElementSelectorComponent,
    HelpMenuComponent,
    MenuProfileComponent,
    MenuMessagesComponent,
    MenuNewIncidenceComponent,
    CustomSelectComponent,
    LabelInfoComponent,
  ]
})
export class SharedModule { }
