import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'pui-simple-search-content',
  templateUrl: './simple-search-content.component.html',
  styleUrls: ['./simple-search-content.component.scss']
})
export class SimpleSearchContentComponent implements OnInit {

  @Input() selectedLabel: string;

  @Input() searchForm: FormControl;
  @ViewChild('input') input: ElementRef;

  focused = false;

  constructor() { }

  ngOnInit(): void {
  }

  focusOnInput() {
    this.input.nativeElement.focus();
  }

  inputFocused() {
    this.focused = true;
  }

  inputBlured() {
    this.focused = false
  }
}
