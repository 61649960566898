import {Injectable, OnDestroy} from '@angular/core';
import {SessionService} from '../shared/services/session.service';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { DateTime } from 'luxon';
import { DateRange } from './models/reports.inteface';


@Injectable({
  providedIn: 'root'
})
export class ReportsDateRangesService implements OnDestroy {

  mainRange$ = new BehaviorSubject<DateRange>(null);
  compareRange$ = new BehaviorSubject<DateRange>(null);

  private cachedMainRangeStartDate: Date;
  private cachedMainRangeEndDate: Date;
  private cachedCompareRangeStartDate: Date;
  private cachedCompareRangeEndDate: Date;

  destroy$: Subject<boolean> = new Subject<boolean>();

  private areEqualRanges(range1: DateRange, range2: DateRange): boolean {
    if (!range1 && !range2) {
      return true;
    }
    const equalStartDates = 
    (!(range1?.start) && !(range2?.start)) || 
    (range1?.start?.getTime() === range2?.start?.getTime());
    const equalEndDates = 
    (!(range1?.start) && !(range2?.start)) || 
    (range1?.end?.getTime() === range2?.end?.getTime());

    return equalStartDates && equalEndDates;
  }

  private parseConfigDataToRange(configRange): DateRange {
    if (configRange) {
      return {
        start: DateTime.fromISO(configRange.start).toJSDate(),
        end: DateTime.fromISO(configRange.end).toJSDate(),
      };
    } else {
      return null;
    }
  }

  constructor(
    private session: SessionService
  ) {
    this.session.userConfig$.pipe(
      takeUntil(this.destroy$),
      map(config => {

        if (config.reportsDateRanges == undefined) 
          this.setLastYearAsRange('main');
          
        const  range = config.reportsDateRanges.dateRange;
        return this.parseConfigDataToRange(range);
      }),
      filter(configRange => {
        const cachedRange = {
          start: this.cachedMainRangeStartDate,
          end: this.cachedMainRangeEndDate
        };

        return !this.areEqualRanges(cachedRange, configRange);
      })
    ).subscribe(range => this.mainRange$.next(range));

    this.session.userConfig$.pipe(
      takeUntil(this.destroy$),
      map(config => {
        const range = config.reportsDateRanges.compareDateRange;
        return this.parseConfigDataToRange(range);
      }),
      filter(configRange => {
        const cachedRange = {
          start: this.cachedCompareRangeStartDate,
          end: this.cachedCompareRangeEndDate,
        };
        return !this.areEqualRanges(cachedRange, configRange);
      })
    ).subscribe(range => this.compareRange$.next(range));

    this.mainRange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(range => {
      if (range && (!this.cachedMainRangeStartDate || !this.cachedMainRangeEndDate)) {
        this.cachedMainRangeStartDate = range.start;
        this.cachedMainRangeEndDate = range.end;
      }
    });

    this.compareRange$
      .pipe(takeUntil(this.destroy$))
      .subscribe(range => {
        if (range && (!this.cachedCompareRangeStartDate || !this.cachedCompareRangeEndDate)) {
          this.cachedCompareRangeStartDate = range.start;
          this.cachedCompareRangeEndDate = range.end;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  setMainDate(date: 'start' | 'end', dateValue: Date) {
    if (date === 'start') {
      this.cachedMainRangeStartDate = dateValue;
    } else {
      this.cachedMainRangeEndDate = dateValue;
    }

    if (this.cachedMainRangeStartDate && this.cachedMainRangeEndDate) {
      const range = {
        start: this.cachedMainRangeStartDate,
        end: this.cachedMainRangeEndDate
      };

      this.cachedMainRangeStartDate = null;
      this.cachedMainRangeEndDate = null;

      this.setDateRange('main', range);
    }
  }

  setCompareDate(date: 'start' | 'end', dateValue: Date) {
    if (date === 'start') {
      this.cachedCompareRangeStartDate = dateValue;
    } else {
      this.cachedCompareRangeEndDate = dateValue;
    }
    if (this.cachedCompareRangeStartDate && this.cachedCompareRangeEndDate) {
      const range = {
          start: this.cachedCompareRangeStartDate,
          end: this.cachedCompareRangeEndDate
      };
      this.cachedCompareRangeStartDate = null;
      this.cachedCompareRangeEndDate = null;

      this.setDateRange('compare', range);
    }
  }

  getDateRange(range: 'main' | 'compare' = 'main'): Observable<{ start: Date, end: Date }> {
    return this.session.userConfig$.pipe(
      take(1),
      map(config => {
        if (!config.reportsDateRanges) {
          return null;
        }
        return range === 'main' ?
          this.parseConfigDataToRange(config.reportsDateRanges.dateRange) :
          this.parseConfigDataToRange(config.reportsDateRanges.compareDateRange);
      })
    );
  }

  setDateRange(range: 'main' | 'compare' = 'main', rangeValue: {start: Date, end: Date}): void {
    if (range === 'main') {
      this.getDateRange('compare').subscribe(compareRange => {
        this.session.setUserConfig({
          reportsDateRanges: {
            dateRange: rangeValue,
            compareDateRange: compareRange
          }
        });
      });
    } else {
     this.getDateRange('main').subscribe(dateRange => {
       this.session.setUserConfig({
         reportsDateRanges: {
           dateRange,
           compareDateRange: rangeValue
         }
       });
     });
    }
  }

  setLastMonthAsRange(range: 'main' | 'compare') {
    const aMonthAgo = DateTime.now().minus({ days: 30 }).startOf('day').toJSDate();
    const today = DateTime.now().endOf('day').toJSDate();
    const rangeValue = { start: aMonthAgo, end: today };

    this.setDateRange(range, rangeValue);
  }

  setLastYearAsRange(range: 'main' | 'compare') {
    const aYearAgo = DateTime.now().minus({ years: 1 }).startOf('day').toJSDate();
    const today = DateTime.now().endOf('day').toJSDate();
    const rangeValue = { start: aYearAgo, end: today };

    this.setDateRange(range, rangeValue);
  }

}
