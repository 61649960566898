import {AbstractControl, FormArray} from '@angular/forms';

export function notEmptyValidator(control: AbstractControl): { [key: string]: any } | null {
  const isEmptyArray = (Array.isArray(control.value) && control.value.length === 0);
  const isEmptyString = (typeof control.value === 'string' && !control.value.trim());
  const empty = !control.value || isEmptyArray || isEmptyString;

  return empty ? {valueCanNotBeEmpty: true} : null;
}

export function someInclusiveConditionRequiredValidator(control: AbstractControl): {[key: string]: any} | null {
  const someCondition = (control.get('inclusiveFilters') as FormArray).controls.some((conditionsForm: FormArray) => someConditionParam(conditionsForm));
  return !someCondition ? {someInclusiveConditionRequired: true} : null;
}

function someConditionParam(conditionsForm: FormArray): boolean {
  return conditionsForm.controls.some(condition => condition.valid);
}



