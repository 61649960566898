import {AfterContentInit, AfterViewInit, Component, ContentChild, HostBinding, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {InputComponent} from '../input/input.component';
import {CheckboxComponent} from '../../../checkbox/components/checkbox/checkbox.component';
import {TextAreaComponent} from "../text-area/text-area.component";
import {DropdownComponent} from '../../../dropdown/components/dropdown/dropdown.component';

@Component({
  selector: 'pui-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FieldComponent implements OnInit, AfterContentInit {

  @HostBinding('class.checkbox-field') get isCheckboxField() { return this._isCheckboxField; }
  @HostBinding('class.input-field') get isInputField() { return this._isInputField; }
  @HostBinding('class.textarea-field') get isTextAreaField() { return this._isTextAreaField; }
  @HostBinding('class.dropdown-field') get isDropdownField() { return this._isDropdownField; }

  @ContentChild(CheckboxComponent) checkbox;
  _isCheckboxField = false;

  @ContentChild(InputComponent) input;
  _isInputField = false;

  @ContentChild(TextAreaComponent) textarea;
  _isTextAreaField = false;

  @ContentChild(DropdownComponent) dropdown;
  _isDropdownField = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this._isCheckboxField = !!this.checkbox;
    this._isInputField = !!this.input;
    this._isTextAreaField = !!this.textarea;
    this._isDropdownField = !!this.dropdown;
  }

}
