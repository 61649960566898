import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MATERIAL_UNITS_LITERALS, MaterialInterface, WorkOrderMaterialCreateDtoInterface} from '../../models/work-order-material.interface';
import {WorkOrderMaterialService} from '../../work-order-material.service';
import {WorkOrderComponentInterface} from '../../models/work-order.interface';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {FacilityElementInterface} from '../../../facilities/models/facility-element.interface';
import { WorkOrder } from '../../models/work-order.model';
import { CustomSelectComponent } from 'src/app/shared/components/custom-select/custom-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-material-form',
  templateUrl: './add-material-form.component.html',
  styleUrls: ['./add-material-form.component.scss']
})
export class AddMaterialFormComponent implements OnInit {

  categories: CategoryInterface[];
  workOrderCategory: CategoryInterface[];
  parentCategories: CategoryInterface[];
  childCategories: CategoryInterface[];

  selectedCategory: CategoryInterface;
  selectedSubcategory: CategoryInterface;

  materials: MaterialInterface[];
  materialOptions: MaterialInterface[];
  selectedMaterial: MaterialInterface | string;

  @Input('workOrderComponents') set setComponents(orderComponents: WorkOrderComponentInterface[]) {
    this.workOrderComponents = orderComponents;
    this.components = this.workOrderComponents.map(orderComponent => orderComponent.component);
  }

  @Input()
  workOrder: WorkOrder;

  workOrderComponents: WorkOrderComponentInterface[];
  components: FacilityElementInterface[];
  selectedFacilityElements: FacilityElementInterface[];

  units: number;
  materialUnitTypesOptions: { name: any; value: number }[];
  selectedMaterialUnits: { name: any; value: number };
  @ViewChild(CustomSelectComponent) customSelectComponent: CustomSelectComponent;

  @Output() valueChange: EventEmitter<WorkOrderMaterialCreateDtoInterface> = new EventEmitter<WorkOrderMaterialCreateDtoInterface>();

  constructor(
    private materialsService: WorkOrderMaterialService,
    private translate: TranslateService,
   ) {
  }

  ngOnInit(): void {

    this.materialsService.fetchCategories().subscribe(categories => {
      this.categories = categories;
      this.parentCategories = categories.filter(category => category.parentCategoryId === null);
      if (this.workOrder && this.workOrder.category) {
        this.onSelectCategory(this.workOrder.category);
      }
    });

    this.workOrderCategory = [this.workOrder.category];
    this.materialsService.fetchMaterials().subscribe(materials => this.materials = materials);
    this.materialUnitTypesOptions = Object.keys(MATERIAL_UNITS_LITERALS).map(key => {
      return {value: parseInt(key), name: this.translate.instant(`UNIT.${MATERIAL_UNITS_LITERALS[key]}`)};
    });
    this.selectedMaterialUnits = this.materialUnitTypesOptions[0];
  }

  onSelectCategory(category: CategoryInterface) {    
    this.selectedCategory = category;
    this.selectedSubcategory = null;
    if (this.selectedCategory) {
      this.childCategories = this.categories.filter(childCategory => childCategory.parentCategoryId === this.selectedCategory.id);
    }
    this.emitValue();
  }

  onSelectSubcategory(subcategory: CategoryInterface) {
    this.selectedSubcategory = subcategory;
    this.materialOptions = this.selectedSubcategory ?
      this.materials.filter(material => material.categoryId === this.selectedSubcategory.id) : null;
    
    this.customSelectComponent.resetInput();
    this.emitValue();
  }

  onSelectMaterial(material: any) {
    this.selectedMaterial = material;
    this.emitValue();
  }

  onInputReset() {
    // Needed to compile
  }

  onFacilityElementsChange(facilityElements: FacilityElementInterface[]) {
    this.selectedFacilityElements = facilityElements;
    this.emitValue();
  }

  onUnitsChange(units: number) {
    this.units = units;
    this.emitValue();
  }

  onMaterialUnitsChange(materialUnits: {name: any, value: number}) {
    this.selectedMaterialUnits = materialUnits;
    this.emitValue();
  }

  isValid() {
    return this.selectedCategory && this.selectedSubcategory && this.selectedMaterial &&
      this.selectedFacilityElements && this.units && this.selectedMaterialUnits;
  }

  emitValue() {
    let data: WorkOrderMaterialCreateDtoInterface;
    if (this.isValid()) {
      data = {
        name: typeof this.selectedMaterial === 'object' ? this.selectedMaterial.name : this.selectedMaterial,
        quantity: this.units,
        categoryId: this.selectedSubcategory.id,
        materialUnitId: this.selectedMaterialUnits.value,
        workOrderComponentsIds: this.selectedFacilityElements.map(component => {
          return this.workOrderComponents.find(orderComponent => orderComponent.component.id === component.id).id;
        })
      };
    } else {
      data = null;
    }

    this.valueChange.emit(data);
  }

  reset() {
    this.selectedMaterial = null;
    this.selectedSubcategory = null;
    this.selectedFacilityElements = null;
    this.units = null;
    this.selectedMaterialUnits = this.materialUnitTypesOptions[0];
  }

}
