<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'COMPONENTS.HEADER' | translate }}</h1>
  <div *ngIf="canImportExport() | async">
    <button class="ui button left floated" [class.disabled]="!totalElements" (click)="export()">{{ 'COMPONENTS.EXPORT' | translate }}</button>
    <button class="ui button left floated" [class.loading]="loadingFile" (click)="fileInput.click()">{{ 'COMPONENTS.UPDATE_FROM_FILE' | translate }}</button>
    <input hidden #fileInput type="file" (change)="selectedFile()">
  </div>
</div>

<div class="sixteen wide column">
  <app-collapsible-form title="{{ 'COMPONENTS.FILTERS' | translate }}">
    <form [formGroup]="filtersForm" class="ui mini form">
      <div class="fields">
        <div class="two wide field">
          <label>{{ 'COMPONENTS.CODE_GUID' | translate }}</label>
          <input type="text" formControlName="assetIdentifiers" (keydown)="handleKeyPress($event)">
        </div>
        <div class="four wide field">
          <label>{{ 'COMPONENTS.NAME' | translate }}</label>
          <input type="text" formControlName="names" (keydown)="handleKeyPress($event)">
        </div>
        <div class="four wide field">
          <label>{{ 'COMPONENTS.TYPE' | translate }}</label>
          <input type="text" formControlName="types" (keydown)="handleKeyPress($event)">
        </div>
        <div class="three wide field">
          <label>{{ 'COMPONENTS.SPACE' | translate }}</label>
          <input formControlName="spaces" type="text" (keydown)="handleKeyPress($event)">
        </div>
        <div class="three wide field">
          <label>{{ 'COMPONENTS.FLOOR' | translate }}</label>
          <input formControlName="floors" type="text" (keydown)="handleKeyPress($event)">
        </div>
      </div>
      <div class="fields">
        <div class="eight wide field">
          <div class="five wide field">
            <label>{{ 'COMPONENTS.REPLACE_IN' | translate }}</label>
            <div class="input-with-addon">
              <input formControlName="expired" type="number" (keydown)="handleKeyPress($event)">
              <span>{{ 'COMPONENTS.MONTHS' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="eight wide field" style="display: flex; justify-content: flex-end; align-items: flex-end">
          <div class="ui small basic button" (click)="clearFilters()">{{ 'COMPONENTS.CLEAR' | translate }}</div>
          <div class="ui small button" (click)="applyFilters()">{{ 'COMPONENTS.FILTER' | translate }}</div>
        </div>
      </div>
    </form>
  </app-collapsible-form>
</div>

<ng-container *ngIf="totalElements">
  <div class="sixteen wide column" style="display: flex; justify-content: flex-end;">
    <div style="width: 100px">
      <pui-dropdown size="small" [options]="elemsByPageOptions" [formControl]="elemsByPage"></pui-dropdown>
    </div>
  </div>

  <div class="sixteen wide column">
    <div class="table-container">
      <table class="ui small sortable selectable compact table">
        <thead>
          <tr>
            <th fuiSortableTh columnName="assetIdentifier"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.CODE' | translate }}
            </th>
            <th fuiSortableTh columnName="name"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.NAME' | translate }}
            </th>
            <th fuiSortableTh columnName="componentTypeName"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.TYPE' | translate }}
            </th>
            <th fuiSortableTh columnName="spaceName"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.SPACE' | translate }}
            </th>
            <th fuiSortableTh columnName="floorName"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.FLOOR' | translate }}
            </th>
            <th fuiSortableTh columnName="warrantyStartOn"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.WARRANTY_START' | translate }}
            </th>
            <th fuiSortableTh columnName="replacedOn"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.REPLACEMENT' | translate }}
            </th>
            <th fuiSortableTh columnName="installatedOn"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.INSTALLATION' | translate }}
            </th>
            <th fuiSortableTh columnName="expectedLife"
                [sortedBy]="sortedBy$.value"
                (sortedByChange)="this.sortedBy$.next($event)">
              {{ 'COMPONENTS.EXPECTED_LIFE_YEARS' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let element of elements" (click)="componentSelected(element)">
            <td>{{element?.assetIdentifier}}</td>
            <td>{{element?.name}}</td>
            <td>{{element?.type?.name}}</td>
            <td>{{element?.space?.name}}</td>
            <td>{{element?.space?.floor.name}}</td>
            <td>{{element?.warrantyStartOn | date: 'yyyy-MM-dd'}}</td>
            <td>{{element?.replacedOn | date: 'yyyy-MM-dd'}}</td>
            <td>{{element?.installatedOn | date: 'yyyy-MM-dd'}}</td>
            <td>{{element?.type?.expectedLife}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
      <tfoot>
        <tr>
          <th colspan="5">
            <fui-pagination-menu
              [numOfPages]="totalPages"
              [page]="page$.value"
              (pageSelected)="page$.next($event)">
            </fui-pagination-menu>
          </th>
          <th colspan="5">
            <app-pagination-num-items-info
              itemsName="{{ 'COMPONENTS.COMPONENTS' | translate }}"
              [totalItems]="totalElements"
              [page]="page$.value"
              [numItemsPerPage]="elemsByPage.value?.value">
            </app-pagination-num-items-info>
          </th>
        </tr>
      </tfoot>
    </div>
  </div>
</ng-container>

<div *ngIf="!totalElements && elements" class="sixteen wide column">
  <div class="ui placeholder segment">
    <div class="ui icon header">
      <i class="search icon"></i>
      {{ 'COMPONENTS.NO_COMPONENTS_FOUND' | translate }}
    </div>
  </div>
</div>

<div class="buttons-line">
  <pui-modal *ngIf="showErrorsModal" title="{{ 'COMPONENTS.IMPORT_ERRORS' | translate }}">
    <div class="upload-errors">
      <ul>
        <li *ngFor="let error of uploadErrors">
          {{ 'COMPONENTS.ROW' | translate }}: {{error.row}} - {{error.message}}
        </li>
      </ul>
    </div>
    <div class="upload-errors--footer" footer>
      <div class="ui primary button" (click)="this.showErrorsModal = false">{{ 'COMPONENTS.ACCEPT' | translate }}</div>
    </div>
  </pui-modal>
</div>
