<div class="sixteen wide column">
  <div class="ui button"
    [class.disabled]="!(detailIncidence.canUserEdit() | async)"
    (click)="fileInput.click()">{{ 'ADD_FILE' | translate }}
  </div>
  <input #fileInput type="file" accept="application/pdf,.jpg,.jpeg,.png,.docx,.xlsx,.pptx" hidden (change)="addFiles()">

  <table *ngIf="(documents$ | async)?.length" class="ui small compact table">
    <thead>
      <tr>
        <th>{{ 'NAME' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let doc of documents$ | async; let i=index">
        <td><a href="{{doc.url}}" target="_blank">{{doc.file}}</a></td>
        <td class="actions-cell">
          <div class="ui mini icon button"
               (click)="deleteDocument(i)"
               [class.disabled]="!(detailIncidence.canUserEdit() | async)"
          ><i class="ui delete icon"></i></div>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="!(documents$ | async)?.length" class="ui segment">
    <p style="text-align: center">{{ 'NO_FILES' | translate }}</p>
  </div>
</div>

<div class="sixteen wide column" style="display: flex; justify-content: flex-end">
  <div
    class="ui primary button"
    *ngIf="(detailIncidence.canUserEdit() | async)"
    [class.disabled]="!detailIncidence.isModified()"
    (click)="detailIncidence.saveChanges()"
  >{{ 'SAVE' | translate }}
  </div>
</div>
