import {ChangeDetectorRef, Component, HostBinding, OnInit} from '@angular/core';
import {CompanyInterface} from '../../../companies/models/company.interface';
import {CompaniesService} from '../../../companies/companies.service';
import {SessionService} from '../../../shared/services/session.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {getRolesOptions, RoleInterface, ROLES, ROLES_OPTIONS} from '../../../shared/models/role.interface';
import {Facility} from '../../../facilities/models/facility.interface';
import {combineLatest} from 'rxjs';
import {filter, take} from 'rxjs/operators';
import {IUserLogged} from '../../../shared/models/i-user-logged';
import {ToastService} from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-user-page',
  templateUrl: './add-user-page.component.html',
  styleUrls: ['./add-user-page.component.scss']
})
export class AddUserPageComponent implements OnInit {

  @HostBinding('class') hostClasses = 'ui container grid';

  activeFacility: Facility;
  userRole: ROLES;
  loggedUser: IUserLogged;

  companies: CompanyInterface[];
  roleOptions: RoleInterface[];

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private sessionSrv: SessionService,
    private usersSrv: UserService,
    private companiesSrv: CompaniesService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {

    combineLatest([
      this.sessionSrv.activeFacility$.pipe(filter(facility => !!facility)),
      this.sessionSrv.userRole$.pipe(filter(role => role !== null && role !== undefined)),
      this.sessionSrv.loggedUser$.pipe(filter(user => !!user))
    ]).pipe(take(1)).subscribe(([facility, role, loggedUser]) => {
      this.activeFacility = facility;
      this.userRole = role;
      this.loggedUser = loggedUser;

      this.companiesSrv.fetchAllCompanies({facilityId: this.activeFacility.id , companyTypeId: [1,2]})
        .subscribe((companies: CompanyInterface[]) => {
          if ([ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role)) {
            this.companies = companies;
          }

          if (role === ROLES.CLIENT_ADMIN || role === ROLES.MAINTENANCE_ADMIN) {
            this.companies = [companies.find(company => company.id === loggedUser.companyId)];
          }
        });
    });
  }

  createUser(userData: any) {
    this.usersSrv.createUser(this.activeFacility.id, userData).subscribe(response => {
        this.router.navigate(['/usuarios'], {
          state: {
            successfulUserCreate: true
          }
        });
      },
      error => {
        this.toastService.showToast({
          type: 'error',
          message: error.error.code || error.error.message,
        });
      });
  }

  onSelectCompany(companySelected: CompanyInterface) {
    const allRoles = ROLES_OPTIONS;
    let roles = [];

    if (this.userRole === ROLES.SUPER_ADMIN) {
      roles = allRoles;
    }
    if (this.userRole === ROLES.CLIENT_ADMIN) {
      roles = allRoles.filter(role => [ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER].includes(role.id));
    }
    if (this.userRole === ROLES.MAINTENANCE_ADMIN) {
      roles = allRoles.filter(role => [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(role.id));
    }
    if ([ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole)) {
      const isUserCompany = companySelected.id === this.loggedUser.companyId;
      const isClientType = companySelected.types.some(type => type.companyTypeId === 1);
      const isMaintenanceType = companySelected.types.some(type => type.companyTypeId === 2);

      if (isUserCompany) {
        if (isClientType) {
          roles = [...roles, ...allRoles.filter(role => [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER].includes(role.id))];
        }
        if (isMaintenanceType) {
          roles = [...roles, ...allRoles.filter(role => [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(role.id))];
        }
      } else {
        if (isClientType) {
          roles = [...roles, ...allRoles.filter(role => [ROLES.CLIENT_ADMIN].includes(role.id))];
        }
        if (isMaintenanceType) {
          roles = [...roles, ...allRoles.filter(role => [ROLES.MAINTENANCE_ADMIN].includes(role.id))];
        }
      }
    }
    this.roleOptions = getRolesOptions(this.translate, roles);
    this.cd.detectChanges();
  }
}
