import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AutodeskViewerComponent} from './components/autodesk-viewer/autodesk-viewer.component';



@NgModule({
  declarations: [
    AutodeskViewerComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AutodeskViewerComponent
  ]
})
export class AutodeskForgeModule { }
