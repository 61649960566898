
import { 
    ReportMaterialCostBreakdownDTO,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessMaterialCostBreakDown extends ProcessDataReport{

    constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
        super(reports, reportGrossData);
    }
    
    getData(){
        const tableDataProcessedDate1 = this.reportGrossData.value 
            ? (this.reportGrossData as ReportMaterialCostBreakdownDTO).value 
            : [];

        const tableDataProcessedDate2 = this.reportGrossData.value2 
            ? (this.reportGrossData as ReportMaterialCostBreakdownDTO).value2 
            : [];

        this.reports.tableDataMainKey = 'categoryName';
        this.reports.tableDataGroupedItems = this.mixGroupCosts(tableDataProcessedDate1, tableDataProcessedDate2);
        this.reports.tableDataTotalItems = this.reports.tableDataGroupedItems.length;
        this.reports.showIncidenceStatusFilter = false;

    }
// ----------------------------------------------------------------------------
    private mixGroupCosts(arr1, arr2 = []) {
        arr1 = arr1.map(a => ({ ...a, valOrder: 1 }));
        arr2 = arr2.map(a => ({ ...a, valOrder: 2 }));
        let newArr = arr1.concat(arr2 ? arr2 : []);
        
        newArr = newArr.sort((a, b) => a.orderId - b.orderId);
        newArr = newArr.map(a => {return [a]});
        return newArr.sort((a, b) => a.orderId - b.orderId);
      }
}