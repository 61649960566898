<div class="sixteen wide column">
  <form class="ui form">
    <div class="field">
      <div class="ui big input">
        <input type="text" name="groupName" placeholder="{{ 'USER_GROUPS.GROUP_NAME' | translate }}" [(ngModel)]="groupName">
      </div>
    </div>
  </form>
</div>

<div class="eight wide column">
  <h2 class="ui header">
    {{ 'USER_GROUPS.ADMINISTRATORS' | translate }}
  </h2>
  <table class="ui small compact table">
    <thead>
    <tr>
      <th>{{ 'USER_GROUPS.NAME' | translate }}</th>
      <th>{{ 'USER_GROUPS.SURNAME' | translate }}</th>
      <th>Email</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let admin of adminUsers">
      <td>{{admin.name}}</td>
      <td>{{admin.surname}}</td>
      <td>{{admin.email}}</td>
      <td>
        <div class="ui checkbox">
          <input type="checkbox" [checked]="selectedIds.includes(admin.id)" (change)="toggleSelectUser($event, admin.id)">
          <label for=""></label>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="eight wide column">
  <h2 class="ui header">
    {{ 'USER_GROUPS.USERS' | translate }}
  </h2>
  <table class="ui small compact table">
    <thead>
    <tr>
      <th>{{ 'USER_GROUPS.NAME' | translate }}</th>
      <th>{{ 'USER_GROUPS.SURNAME' | translate }}</th>
      <th>Email</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of clientUsers">
      <td>{{user.name}}</td>
      <td>{{user.surname}}</td>
      <td>{{user.email}}</td>
      <td>
        <div class="ui checkbox">
          <input type="checkbox" [checked]="selectedIds.includes((user.id))" (change)="toggleSelectUser($event, user.id)">
          <label for=""></label>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="sixteen wide column">
  <div (click)="submitGroup()" class="ui right floated primary button" [class.disabled]="!validGroup()">{{submitButtonText || ('USER_GROUPS.SAVE' | translate)}}</div>
</div>
