<div class="sixteen wide column">
  <h1 class="ui dividing header">{{user?.name}} {{user?.surname}}</h1>
</div>

<div class="centered row">
  <div class="sixteen wide mobile ten wide computer column">
      <app-user-form
        [user]="user$ | async"
        [companyOptions]="companies$ | async"
        [roleOptions]="roleOptions"
        [isClientUser]="isClientUser"
        (selectedCompany)="onSelectCompany($event)"
        (submitted)="saveChanges($event)"
        [disableEdit]="disableEdit$ | async"
        buttonLabel="{{'USERS.SAVE_BUTTON' | translate}}"
      ></app-user-form>
  </div>
</div>