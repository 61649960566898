<div class="sixteen wide column">
  <div class="ui form">
    <div class="two fields">
      <div class="field">
        <label>{{'REPORTS.CATEGORIES' | translate}}</label>
      </div>
    </div>
  </div>
  <pui-dropdown
    multiple
    [search]="true"
    [formControl]="selectedCategories"
    [options]="categories"
  ></pui-dropdown>
</div>
<div class="sixteen wide column" style="height: 500px">
  <app-google-line-chart
    style="height: 100%"
    [dataView]="dataView"
    [options]="chartOptions"
  ></app-google-line-chart>
</div>
