import {OnInit, Component, HostBinding, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {animate, style, transition, trigger, sequence, state} from '@angular/animations';
import {CalendarRangeComponent} from '../../../fomantic-ui/calendar-range/calendar-range.component';
import {ScheduledTasksPageFilterService} from '../services/scheduled-tasks-page-filter.service';
import { getFrequencyUnitOptions, 
  getScheduledTasksStatusOptions, 
  getScheduledTypesOptions,
} from '../../shared/scheduled-tasks.utils';
import * as moment from 'moment/moment';
import {ActivatedRoute, Router} from '@angular/router';
import { filter, map, pluck, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { ROLES } from 'src/app/shared/models/role.interface';
import { SessionService } from 'src/app/shared/services/session.service';
import { CategoryInterface } from 'src/app/shared/models/category.interface';
import { CompaniesService } from 'src/app/companies/companies.service';
import { FacilitiesService } from '../../../facilities/facilities.service';
import { Facility } from 'src/app/facilities/models/facility.interface';
import { FacilityFloorInterface } from 'src/app/facilities/models/facility-element.interface';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { ScheduledTaskService } from '../../scheduled-task.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-scheduled-tasks-page',
  templateUrl: './scheduled-tasks-page.component.html',
  styleUrls: ['./scheduled-tasks-page.component.scss'],
  providers: [ScheduledTasksPageFilterService],
  animations: [
    trigger('caretRotation', [
      state('open', style({transform: 'rotate(90deg)'})),
      state('close', style({transform: 'rotate(0deg)'})),
      transition('open <=> close', [
        animate(200)
      ])
    ]),
    trigger('filtersShow', [
      transition(':enter', [
        style({height: 0, opacity: 0}),
        sequence([
          animate(100, style({height: '*'})),
          animate(100, style({opacity: 1})),
        ]),
      ]),
      transition(':leave', [
        sequence([
          animate(100, style({opacity: 0})),
          animate(100, style({height: 0}))
        ])
      ])
    ])
  ]
})
export class ScheduledTasksPageComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid container';
  @ViewChild('rangeSelector') rangeSelector: CalendarRangeComponent;
  categories$: Observable<CategoryInterface[]>;
  floors$: Observable<FacilityFloorInterface[]>
  filtersForm: FormGroup;
  showFilters = false;
  selectedView: string;
  isDownloadingPDF = false;
  frequencyUnits : any;
  taskTypes : any;
  taskStatus : any;
  activeFacility: Facility;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private fb: FormBuilder,
    private filterService: ScheduledTasksPageFilterService,
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private companiesService: CompaniesService,
    private facilitiesService: FacilitiesService,
    private scheduledTaskService : ScheduledTaskService,
    private translate: TranslateService,
  ) {
    this.filtersForm = fb.group({
      parent: null,
      name: null,
      frequencyQuantity: null,
      frequencyUnit: null,
      company: null,
      space: null,
      assignedUser: null,
      category: null,
      categoryIds: [],
      from: null,
      to: null,
      typeIds: [],
      statusIds: [],
      floorIds: []
    });

    this.route.queryParams.pipe(pluck('relatedToComponent')).subscribe(id => {
      this.filterService.relatedToComponentId$.next(id);
    });
    this.route.queryParams.subscribe(params => {
      if (params){
        this.filterService.setFilter(params);
      }
    });
  }

  ngOnInit(): void {
   this.taskTypes= getScheduledTypesOptions(this.translate);
   this.frequencyUnits = getFrequencyUnitOptions(this.translate);
   this.taskStatus = getScheduledTasksStatusOptions(this.translate);

    this.filterService.filter$.subscribe(filter => {
      this.applyFilters();
    });

    this.session.activeFacility$
        .pipe(
            filter((facility) => !!facility),
            switchMap((facility) => {
                this.activeFacility = facility;
                return of(facility);
                }),
            takeUntil(this.destroy$)
        )
        .subscribe(() => {
            this.getFloors();
        });

    this.categories$ = this.companiesService.fetchCategories().pipe(
      map(categories => {
        return categories.filter(category => !category.parentCategoryId);
      })
    );

    
    this.filterService.selectedView$.subscribe((view: string) => {
      this.selectedView = view;
    });
  }

  getFloors(){
    this.floors$ = this.facilitiesService.fetchFacilityFloors(this.activeFacility.id).pipe(
      map(floors => {
        return floors.filter(floor => floor);
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clearFilters() {
    this.filtersForm.reset();
    this.rangeSelector.setStartDate(null, true, false);
    this.rangeSelector.setEndDate(null, true, false);

    this.filterService.setFilter({});
  }

  handleKeyPress(event){
    if (event.keyCode === 13)
      this.applyFilters();
  }
  
  applyFilters() {
    const filters = this.getFilters();
    this.filterService.setFilter(filters);
  }

  filterStartDateChange(startDate: Date) {
    const initOfDay = moment(startDate).startOf('day').toDate();
    this.filtersForm.patchValue({from: initOfDay});
  }

  filterEndDateChange(endDate: Date) {
    const endOfDay = moment(endDate).endOf('day').toDate();
    this.filtersForm.patchValue({to: endOfDay});
  }

  showSection(section: 'calendar' | 'list' | 'scheduled') {
    const componentId = this.filterService.relatedToComponentId$.value;
    const route = ['tareas-programadas'];
    route.push(section === 'calendar' ? 'calendario' : (section === 'list'? 'lista': 'agenda'));
    if (componentId || componentId === 0 ) {
      this.router.navigate(route, {queryParams: {relatedToComponent: componentId}});
    } else {
      this.router.navigate(route);
    }
  }

  canCreate(): Observable<boolean> {
    return this.session.userRole$.pipe(
      filter(role => role !== null && role !== undefined),
      map(role => {
        return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role);
      })
    );
  }

  goToCreate() {
    this.router.navigate(['tareas-programadas', 'nueva']);
  }

  getFilters(){
    const filters = this.filtersForm.getRawValue();
    Object.keys(filters).forEach(key => {
      if (filters[key] === null || filters[key] === undefined) {
        delete filters[key];
      }
    });
    if (filters.from) {
      filters.from = filters.from.toISOString();
    }
    if (filters.to) {
      filters.to = filters.to.toISOString();
    }
    if (filters.frequencyUnit) {
      filters.frequencyUnit = filters.frequencyUnit.value;
    }
    if (filters.typeIds && filters.typeIds.length){
      filters.typeIds = filters.typeIds.map(typeObj => typeObj.value);
    }
    if (filters.statusIds && filters.statusIds.length){
      filters.statusIds = filters.statusIds.map(typeObj => typeObj.value);
    } 
    if (filters.categoryIds && filters.categoryIds.length){
      filters.categoryIds = filters.categoryIds.map(({id}) => id);
    }
    if (filters.floorIds && filters.floorIds.length){
      filters.floorIds = filters.floorIds.map(({id}) => id);
    }
    return filters;
  }

  downloadPDF() {
      combineLatest([
        this.session.activeFacility$.pipe(skipWhile(f => !f) && take(1)),
        this.filterService.monthYear$.pipe(take(1)),
        this.filterService.filter$.pipe(take(1)),
      ]).pipe(
        skipWhile(([facility, monthYear, filter]) => !facility || !monthYear || !filter),
        switchMap(([facility, monthYear, filter]) => {
          const from = monthYear.startOf('month').toUTC().toISO({ includeOffset: false });
          const to = monthYear.endOf('month').toUTC().toISO({ includeOffset: false });
          this.isDownloadingPDF = true;
          const view = this.filterService.getSelectedView();
          let params = view !== 'list'? { from , to , view} : {view};
          params = { ...params, ...filter, facilityId:this.activeFacility.id };
          return this.scheduledTaskService.downloadFilteredPDF(params);
        })
      ).subscribe(
        (pdfBlob: Blob) => {
          const blob = new Blob([pdfBlob], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
          const fileName = `scheduledTasks-${date}.pdf`;
          saveAs(blob, fileName);
          window.URL.revokeObjectURL(url);
          this.isDownloadingPDF = false;
        },
        (error) => {
          console.error('Error downloading PDF:', error);
          this.isDownloadingPDF = false;
        }
      );
  }
  
}
