import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ScheduledTaskService} from '../../scheduled-task.service';
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs';
import {ScheduledTask} from '../../models/scheduled-task.interface';
import {PaginatedResult} from '../../../shared/models/paginated-result.interface';
import { map, skipWhile, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {ScheduledTasksPageFilterService} from '../services/scheduled-tasks-page-filter.service';
import * as moment from 'moment/moment';
import {SessionService} from '../../../shared/services/session.service';

@Component({
  selector: 'app-scheduled-tasks-list-page',
  templateUrl: './scheduled-tasks-list-page.component.html',
  styleUrls: ['./scheduled-tasks-list-page.component.scss']
})
export class ScheduledTasksListPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui grid';

  loadingTasks = true;
  tasks: ScheduledTask[];
  page$ = new BehaviorSubject<number>(1);

  subs: Subscription;
  elemsPerPageInput: FormControl;
  elemsPerPageOptions = [
    {value: 10, name: '10'},
    {value: 20, name: '20'},
    {value: 50, name: '50'},
  ];
  elemsPerPage$ = new BehaviorSubject<number>(this.elemsPerPageOptions[0].value);

  numOfPages: number;

  tableFields = [
    {title: 'Código', resultKey: 'parent', function: (ele: ScheduledTask) => {
      return ele.parent.slice(0, 8);
      }},
    {title: 'Nombre', key: 'name'},
    {title: 'Frecuencia', resultKey: 'frequency', function: (ele: ScheduledTask) => {
      if (ele) {
        return ele.frequencyQuantity + ' ' + ele.frequencyUnit;
      }},
    },
    {title: 'Empresa', key: 'company', path: 'name'},
    {title: 'Última Ejecución', resultKey: 'lastTask', function: (ele: ScheduledTask) =>  moment(ele.lastScheduledTask).format('YYYY-MM-DD') || '---'},
    {title: 'Próxima Ejecución', resultKey: 'nextTask', function: (ele: ScheduledTask) => moment(ele.nextScheduledTask).format('YYYY-MM-DD') || '---'},
  ];

  destroy$ = new Subject<boolean>();

  constructor(
    private session: SessionService,
    private router: Router,
    private tasksSrv: ScheduledTaskService,
    private filterSrv: ScheduledTasksPageFilterService
  ) {
    this.elemsPerPageInput = new FormControl(this.elemsPerPageOptions[0]);
  }

  ngOnInit(): void {
    combineLatest([
      this.session.activeFacility$.pipe(skipWhile(f => !f)),
      this.page$.pipe(skipWhile(page => !page)),
      this.elemsPerPage$.pipe(skipWhile(elems => ! elems)),
      this.filterSrv.filter$,
      this.filterSrv.relatedToComponentId$
    ]).pipe(
      takeUntil(this.destroy$),
      tap(() => this.loadingTasks = true ),
      switchMap(([facility, page, elems, filter, relatedComponentId]) => {
        let params = {page, limit: elems, view: 'list'};
        params = Object.assign(params, filter);
        return this.tasksSrv.fetchScheduledTasks(params, relatedComponentId);
      }),
      map((result: PaginatedResult<ScheduledTask>) => {
        this.numOfPages = result.meta.totalPages;
        if (this.numOfPages && this.page$.value > this.numOfPages) {
          this.page$.next(result.meta.totalPages);
        }
        return result.items;
      }),
      tap(() => this.loadingTasks = false)
    ).subscribe(tasks => {
      this.tasks = tasks;
      this.filterSrv.setSelectedView('list');
    });

    this.subs =  this.elemsPerPageInput.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(elem => this.elemsPerPage$.next(elem.value));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  selectPage(page: number) {
    this.page$.next(page);
  }

  selectedTask(task: ScheduledTask) {
    this.router.navigate(['tareas-programadas', task.id], {state: {originPage: 'list'}});
  }

  selectedExecution($event: MouseEvent, task: ScheduledTask) {
    $event.stopPropagation();
    this.selectedTask(task);
  }
}
