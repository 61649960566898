<div class="close-icon">
  <i class="ui close icon" (click)="closeButtonClicked.emit()"></i>
</div>

<div class="toggle-icon">
  <i class="arrows alternate horizontal icon" (click)="toggleButtonClicked.emit()"></i>
</div>

<div class="logo" routerLink="/"> </div>

<ul class="session-info">
  <li class="user-info">
    <i class="fas fa-user"></i>
    <span>{{ loggedUser && loggedUser.email}} <br> ({{sessionService.userRoleDescription$ | async}})</span>
  </li>
  <li>
    <i class="fas fa-building"></i>
    <fui-dropdown-two
      selectorId="facilitySel"
      size="small"
      [search]="true"
      [formControl]="activeFacility"
      [placeholder]="'SELECT_FACILITY' | translate"
      [options]="facilities"
      valueProperty="id"
    >
    </fui-dropdown-two>
  </li>
</ul>

<div class="divider"></div>

<nav>
  <ul>
    <ng-container *ngFor="let section of sections">
      <li class="section" *ngIf="canView(section.id, userRole)" (click)="clickedSection(section)">
        <a [routerLink]="section.link" routerLinkActive="active" [class]="'id_' + section.id">
          <i [className]="'ui icon '+section.icon"></i>
          <span>{{section.title | translate}}</span>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>
