<div class="sixteen wide column">
  <h1 class="ui dividing header">
    {{ 'COMPONENTS.COMPONENT' | translate }} ({{component?.id}}): {{component?.name}}
  </h1>
</div>

<div class="thirteen wide column">
  <form class="ui form" [formGroup]="form">
    <div class="two fields">
      <div class="field">
        <label>{{ 'COMPONENTS.CODE' | translate }}</label>
        <input formControlName="assetIdentifier" type="text">
      </div>
      <pui-field class="field">
        <label>{{ 'COMPONENTS.TYPE' | translate }}</label>
        <pui-input clickable (click)="navigateToComponentType()">
          <input readonly type="text" formControlName="type">
        </pui-input>
      </pui-field>
    </div>

    <div class="two fields">
      <div class="field">
        <label>{{ 'COMPONENTS.FLOOR' | translate }}</label>
        <input readonly formControlName="floor" type="text">
      </div>
      <div class="field">
        <label>{{ 'COMPONENTS.SPACE' | translate }}</label>
        <input readonly formControlName="space" type="text">
      </div>
    </div>

    <div class="two fields">
      <div class="field">
        <label>{{ 'COMPONENTS.WARRANTY_START_DATE' | translate }}</label>
        <fui-calendar
          selectorId="warrantyDate"
          formControlName="warrantyStartOn"
        ></fui-calendar>
      </div>
      <div class="field">
        <label>{{ 'COMPONENTS.INSTALLATION_DATE' | translate }}</label>
        <fui-calendar
          selectorId="installationDate"
          formControlName="installatedOn"
        ></fui-calendar>
      </div>
    </div>

    <div class="two fields">
      <div class="field">
        <label>{{ 'COMPONENTS.REPOSITION_DATE' | translate }}</label>
        <fui-calendar
          selectorId="repositionDate"
          formControlName="replacedOn"
        ></fui-calendar>
      </div>
      <div *ngIf="hasViewer" class="field" style="display: flex; flex-direction: column; justify-content: flex-end">
        <button class="ui button" (click)="goToViewer()">{{ 'COMPONENTS.VIEW_IN_VIEWER' | translate }}</button>
      </div>
    </div>

    <div class="two fields">
      <div class="field">
        <label>{{ 'COMPONENTS.GUID' | translate }}</label>
        <input readonly formControlName="guid" type="text">
      </div>
      <div class="field" style="display: flex; flex-direction: column; justify-content: flex-end">
        <button class="ui button" (click)="downloadQR()">{{ 'COMPONENTS.DOWNLOAD_QR' | translate }}</button>
      </div>
    </div>

    <div class="field">
      <label>{{ 'COMPONENTS.DESCRIPTION' | translate }}</label>
      <textarea formControlName="description"></textarea>
    </div>

    <div class="field" style="display: flex; justify-content: flex-end">
      <button class="ui primary button" *ngIf="canEdit() | async" [class.disabled]="form.pristine" (click)="updateComponent()">{{ 'COMPONENTS.SAVE' | translate }}</button>
    </div>
  </form>
</div>

<div class="three wide column" style="display: flex; flex-direction: column; align-items: center">
  <div class="ui small statistic" [class.statistic-disabled]="component?.incidencesCount === '0'" (click)="goToFilteredIncidences(1)">
    <div class="value">{{component?.incidencesCount}}</div>
    <div class="label">{{ 'COMPONENTS.INCIDENCES' | translate }}</div>
  </div>
  <div class="ui small statistic" [class.statistic-disabled]="component?.serviceRequestsCount === '0'" (click)="goToFilteredIncidences(2)">
    <div class="value">{{component?.serviceRequestsCount}}</div>
    <div class="label">{{ 'COMPONENTS.SERVICE_REQUESTS' | translate }}</div>
  </div>
  <div class="ui small statistic" [class.statistic-disabled]="component?.withoutTypeCount === '0'" (click)="goToFilteredIncidences(0)">
    <div class="value">{{component?.withoutTypeCount}}</div>
    <div class="label">{{ 'COMPONENTS.UNCLASSIFIED' | translate }}</div>
  </div>
  <div class="ui small statistic" [class.statistic-disabled]="component?.workOrdersCount === '0'" (click)="goToFilteredWorkOrders()">
    <div class="value">{{component?.workOrdersCount}}</div>
    <div class="label">{{ 'COMPONENTS.WORK_ORDERS' | translate }}</div>
  </div>
  <div class="ui small statistic" [class.statistic-disabled]="component?.tasksCount === '0'" (click)="goToFilteredTasks()">
    <div class="value">{{component?.tasksCount}}</div>
    <div class="label">{{ 'COMPONENTS.SCHEDULED_TASKS' | translate }}</div>
  </div>
  <div class="ui button" [class.disabled]="isPreselectedToIncidence" (click)="addToNewIncidence()">{{ 'COMPONENTS.ADD_TO_NEW_REQUEST' | translate }}</div>
</div>
