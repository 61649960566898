<div class="sixteen wide column">
  <app-work-order-steps-indicator
    [workOrder]="workOrder"
  ></app-work-order-steps-indicator>
</div>

<div class="sixteen wide column">
  <app-work-order-info-form
    [disabled]="!detailWorkOrder.canEditInfo()"
    [parentForm]="detailWorkOrder.infoForm"
    [categories]="detailWorkOrder.categories"
    [companies]="detailWorkOrder.companies"
    [users]="detailWorkOrder.users"
    [problemTypes]="detailWorkOrder.problemTypes"
    [criticalityLevels]="detailWorkOrder.criticalityLevels"
    [incidenceWorkOrders]="incidenceWorkOrders$ | async"
    [userRole]="session.userRole$ | async"
  ></app-work-order-info-form>
</div>
