import jsPDF from "jspdf";
import { IColumnWidthMultiplier, IOptionsPDFImbi } from './PDF.interface';
import { DateRange, ITableParams } from "src/app/reports/models/reports.inteface";
import { DateTime } from "luxon";
import html2canvas from "html2canvas";
import getSymbolFromCurrency from 'currency-symbol-map'
import { TranslateService } from "@ngx-translate/core";

const DEFAULT_FONT_SIZE: number = 9;
const TITLE_POS_Y : number = 22;
const FOOTER_PORTRAIT_POS_Y = 293;
const FOOTER_LANDSCAPE_POS_Y = 206;
const FOOTER_LASDSCAPE_PAGENUMBER_POS_X = 280;
const FOOTER_PORTRAIT_PAGENUMBER_POS_X = 180;
const COLUMNS_TYPE_TO_ALIGN_RIGHT: string[] = ["number","currency"];
const MIN_WIDTH : number = 3;
const ROWNUM_TABLE_HEADER = -1;

/**
 * Add a table to the PDF.
 * @argument {IColumnWidthMultiplier[]} options.columnWidthMultiplier - 
 * An array of objects specifying the width multiplier for each column.
 * Each object contains a `key` property representing the column key that you want to show
 * and a `factor` property indicating the width multiplier for that column.
 * @argument {number} options.fontSize - The font size to be used for the text in the table.
 * @argument {number} options.table.rowsPerPage - The number of rows to display per page.
 * @argument {ITableParams[]} options.table.tableParams - An array containing information about the table columns names.
 * @argument {string} options.facilityName - The name of the facility or organization.
 * @argument {string} options.title - The title of the table.
 * @argument {jsPDF} options.pdf - jsPDF if there is one to continue adding pages.
 * @example
 * const options: IOptionsPDFImbi = {
 *     
 *     fontSize : 9,
 *     title : "Title to use in every page and as file name",
 *     firstDateRange : { start: Date, end : Date},
 *     facilityName: "Example Facility",
 *     chart : {
 *          chartCanvas,
 *          format,
 *          orientation,
 *      },
 *     table : {
 *      columnWidthMultiplier: [
 *         { key: "facilityName", factor: 2 }, // Example: Facility name column is twice as wide as default
 *         { key: "companyName", factor: 3 }, // Example: Company name column is three times as wide as default
 *         // Add more column width multiplier objects as needed
 *          ],
 *      tableData,
 *      tableParams: [{ name: "Category", key: "categoryName" }, ...],
 *      rowsPerPage: 15,
 *      format,
 *      orientation,
 *      },
 * };
 * 
 *     
 * const pdfImbi = new PDFImbi(options);
 * pdfImbi.build()
 */
export class PDFImbi{
    private _pdf: jsPDF;
    private _currentPage: number;
    private _marginTop: number = 0;
    private _marginLeft: number = 10;
    private _rowsPerPage: number = 15;
    private _header: string[];
    private _numPages: number = 0;
    private _padding: number = 3;
    private _facilityName: string;
    private _title: string;
    private _firsDateRange: DateRange;
    private _orientation: "portrait" | "landscape";
    private _chartCanvas: HTMLCanvasElement;
    private _table : string | Blob;
    private _tableParams: ITableParams[];
    private _columnWidthMultiplier: IColumnWidthMultiplier[];
    private _tableData: string[];
    private _tableMetadata: TableMetadata;
    private _format : string = 'a4';
    private _currencySymbol : string;
    
    constructor(
        private translate: TranslateService,
        options?: IOptionsPDFImbi,
    ){

        if (options?.pdf){ // Add to given PDF
            this._pdf = options.pdf;
            this._currentPage = this._pdf.getNumberOfPages();
            if (options.chart?.chartCanvas){
                this._chartCanvas = options.chart.chartCanvas;
                this._addNewPage(
                    options?.chart.format,
                    options?.chart.orientation
                );
            }else if (options.table){
                this._columnWidthMultiplier = options.table.columnWidthMultiplier;
                this._tableParams = options.table.tableParams;
                this._table  = options.table.tableData;
                this._rowsPerPage = options.table.rowsPerPage;
                this._addNewPage(
                    options?.table.format,
                    options?.table.orientation
                );
            }
            
        }else{ // Create PDF
            
            if (options.chart?.chartCanvas){
                this._chartCanvas = options.chart.chartCanvas;
                this._format = options.chart.format ;
                this._orientation = options.chart.orientation;
                this._numPages++;
                this._currentPage = 1;
            }
            
            if (options.table){
                this._setTableData(options);
                if (!options.chart?.chartCanvas){
                    this._orientation = options.table.orientation;
                    this._format = options.table.format;
                }
               
            }
            this._pdf = new jsPDF({
                format : this._format, 
                orientation : this._orientation,
            });
            
            
        }
        this._pdf.setFontSize(options.fontSize);
        this._marginTop = 40;
        
        this._facilityName = options.facilityName ? options.facilityName : "";
        this._title = options.title ? options.title :  this.translate.instant("REPORTS.TITLE");
        this._firsDateRange = options.firstDateRange;
        this._currencySymbol = getSymbolFromCurrency(options.currencySymbol);
    }
    // ------------------------------------------------------------------------
    get marginTop(){
        return this._marginTop;
    }
    get pdf(){
        return this._pdf;
    }
    get numPages(){
        return this._numPages;
    }
    get fontSize(){
        return this.pdf.getFontSize();
    }
    get title(){
        return this._title;
    }
    // ------------------------------------------------------------------------
    private async _setTableData(options){
        this._columnWidthMultiplier = options.table.columnWidthMultiplier;
        this._tableParams = options.table.tableParams;
        this._table  = options.table.tableData;
        this._rowsPerPage = options.table.rowsPerPage;
        this._tableData = typeof this._table  === 'string' 
            ? this._table.split('\n') 
            : await this._getTableDataFromBlob(this._table);
        this._currentPage = this._currentPage ? this._currentPage++ : 1;
        this._setTableHeader();
        this._setMetaData();
        this._setNumPages(Math.ceil(this._tableData.length / this._rowsPerPage) + this._currentPage);
    }
    // ------------------------------------------------------------------------
    
    public async build(
    ){
        if (this._chartCanvas){
            await this._addHeaderToPage();

            // Capture chart as image
            await html2canvas(this._chartCanvas).then((chartCanvasImage) => {
                const fixedWith: number = 190;
                const fixedHeight: number = this.calculateHeigth(this._chartCanvas, fixedWith);

            // Add chart image to PDF
            this._pdf.addImage(chartCanvasImage.toDataURL('image/png'), 'PNG', 10, 30, fixedWith, fixedHeight);
            });

            this._addFooterToPage();
        }

        if (this._table ){
            if (this._chartCanvas){
                this._addNewPage('a4', 'landscape');
            }
            
            await this._processTableData();
        }
    }
    
    // ------------------------------------------------------------------------
     
    private calculateHeigth(chartCanvas: HTMLCanvasElement, fixedWith: number){
        const inversAspectRatio: number = parseInt(chartCanvas.style.height,10) / parseInt(chartCanvas.style.width,10);
        return  fixedWith * inversAspectRatio ;
      }

    // ------------------------------------------------------------------------
    protected _getOrientation() {
        return this._orientation;
    }
    // ------------------------------------------------------------------------
    protected _setNumPages(numPages: number) {
        this._numPages = numPages;
    }
    // ------------------------------------------------------------------------
    protected _addNewPage(
        format?: string | number[], 
        orientation?: "portrait" | "landscape",
    ){  
        this._orientation = orientation;
        this._pdf.addPage(format, orientation);
        this._currentPage++;
    }
    // ------------------------------------------------------------------------
    protected async _addHeaderToPage() {
        await this._addLogo();
        this._addFacilityInfo();
        this._addTitle();
        this._addDateRange();
        this._revertStyleToDefault();
    }
    // ------------------------------------------------------------------------
    private _addDateRange(){
        this._pdf.setFontSize(10);
        this._pdf.setTextColor(128,128,128);
        this._pdf.setFont("helvetica", 'normal');
        const currentLang = this.translate.currentLang;

        const startDate = DateTime.fromJSDate(this._firsDateRange.start)
        .setLocale(currentLang)
        .toFormat('yyyy-LLLL-dd');

        const endDate = DateTime.fromJSDate(this._firsDateRange.end)
        .setLocale(currentLang)
        .toFormat('yyyy-LLLL-dd');
        
        const text = `${startDate} - ${endDate}`;
        
        const posX = this._title 
            ? this._pdf.getStringUnitWidth(this._title + this._marginLeft) * 6 - (this.fontSize * 0.35)
            : this._marginLeft;
        
        this._pdf.text(text, posX, TITLE_POS_Y);
    }
    // ------------------------------------------------------------------------
    private _addFacilityInfo(){
        this._pdf.setTextColor(128,128,128);
        this._pdf.setFontSize(10);
        this._pdf.setFont("helvetica", 'bold');
        const active = `${this._facilityName}`
        this._pdf.text(active, 90, 12);
    }
    // ------------------------------------------------------------------------
    private _addTitle(){
        this._pdf.setFontSize(12);
        this._pdf.setTextColor(128,128,128);
        this._pdf.setFont("helvetica", 'bold');
        const title = `${this._title}`
        this._pdf.text(title, this._marginLeft, TITLE_POS_Y);
    }
    // ------------------------------------------------------------------------
    private async _addLogo(){
        const imgLogoDataUrl = await this._getImageDataUrl('assets/images/logo.png');
        const width = 50;
        const height = 15;
        this._pdf.addImage(imgLogoDataUrl, 'PNG', 6, 3, width, height);
    }
    // ------------------------------------------------------------------------
    private _getImageDataUrl(imagePath: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            };
            img.onerror = reject;
            img.src = imagePath;
        });
    }
    // ------------------------------------------------------------------------
    protected _addFooterToPage() {
        this._pdf.setTextColor(202,202,202);
        const currentLang = this.translate.currentLang;
        const currentDateTime = DateTime.local().setLocale(currentLang).toLocaleString({
        weekday: 'long', // Day in words
        month: 'long', // Display month in words
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
        });
        const posY = this._getOrientation() === "portrait" ? FOOTER_PORTRAIT_POS_Y : FOOTER_LANDSCAPE_POS_Y;
        const pageNumberPosX = this._getOrientation() === "portrait" ? FOOTER_PORTRAIT_PAGENUMBER_POS_X : FOOTER_LASDSCAPE_PAGENUMBER_POS_X;
        this._pdf.text(
            this.translate.instant("REPORTS.PRINTED") +`: ${currentDateTime}`,
            this._marginLeft,
            posY,
        );
        
        this._pdf.text(
            `${this._currentPage} / ${this.numPages}`,
            pageNumberPosX,
            posY,
        ); 
        this._revertStyleToDefault();
    }
    // ------------------------------------------------------------------------
    protected _revertStyleToDefault(){
        this._pdf.setTextColor(0,0,0);
        this._pdf.setFont("helvetica", 'normal');
        this._pdf.setFillColor(255, 255, 255); 
        this._pdf.setFontSize(DEFAULT_FONT_SIZE);
    }
    // ------------------------------------------------------------------------
    private async _processTableData(){
        
        this._tableData = typeof this._table  === 'string' 
            ? this._table.split('\n') 
            : await this._getTableDataFromBlob(this._table);
        this._setTableHeader();
        this._setMetaData();
        this._setNumPages(Math.ceil(this._tableData.length / this._rowsPerPage) + this._currentPage - 1);

        while (this._tableData.length > 0) {
            const pageData = this._tableData.splice(0, this._rowsPerPage);
            await this._addHeaderToPage();
            this._addDataRowsToPage({pageData});
            this._addFooterToPage();
            if (this._tableData.length > 0) {
                this._addNewPage('a4', 'landscape');
            }
        }
    }

    // ------------------------------------------------------------------------
    private _setMetaData() {
        this._tableMetadata = new TableMetadata(this._header, this._columnWidthMultiplier, this._tableParams);   
    }
    // ------------------------------------------------------------------------
    get tableMetadata(){
        return this._tableMetadata;
    }
    // ------------------------------------------------------------------------
    public savePDF(pdfName?: string){
        const name = pdfName 
        ? pdfName
        : this._title;
        this._pdf.save(`${name}.pdf`);
    }
    
    
    // ------------------------------------------------------------------------
     private _isSkipingColumn(columnNum: number): boolean{
        return this._tableMetadata.isSkipingColumn(columnNum);
    }
    
    // ------------------------------------------------------------------------
    private _setTableHeader(){ 
        if (this._tableData.length > 0){
            this._header = this._tableData.splice(0, 1)[0].split(';');
        }
    }
    // ------------------------------------------------------------------------
    private async _getTableDataFromBlob(tableBlob: Blob): Promise<string[]> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsText(tableBlob);
          reader.onload = () => {
            // Check if result is a string (successful read)
            if (typeof reader.result === 'string') {
                resolve(reader.result.split('\n'));
            } else {
                // Handle error case (non-string result)
                reject(new Error('Error reading table data: Unexpected result type'));
            }
          };
          reader.onerror = error => {
            reject(error);
          };
        });
      }
    // ------------------------------------------------------------------------
      private _addDataRowsToPage({pageData}){
        let posX = 10;

        //add header table
        this._pdf.setDrawColor(202,0,0);
        this._pdf.setFillColor(202, 20, 23);
        this._pdf.setFont("helvetica", 'bold');
        const headerNames = this._tableMetadata.getHeaderNames();
        this._addDataColumn({rowData: headerNames, posX, rowNum: ROWNUM_TABLE_HEADER});
        this._drawLineAfterRow(ROWNUM_TABLE_HEADER);

        this._revertStyleToDefault();

        let rowNum: number;
        for (rowNum = 0; rowNum < pageData.length; rowNum++) {
          const rowData = pageData[rowNum].split(';'); // Split row
            this._addDataColumn({rowData, posX, rowNum});
            this._drawLineAfterRow(rowNum);
        }
        this._drawLineMarginLeft(rowNum);
      }
    
    // ------------------------------------------------------------------------
      private _addDataColumn(
        {rowData, posX, rowNum}
      ){
        for (let columnNum = 0; columnNum < rowData.length; columnNum++) {
            if (this._isSkipingColumn(columnNum)) {
              continue;
            }

            const columnWidth : number = this._calculateColumnWith(columnNum);
            const columnTextLines : string[] = this._wrapColumnText(rowData[columnNum], columnWidth);

            for (let k = 0; k < columnTextLines.length; k++) {
                const posY = this._marginTop + (rowNum * 10) + (k * 5);

                if (this._isColumnAlignToRigh(columnNum)){ 
                    const rightAlignedPosX = this._calculateRightAlignedPosX(columnWidth, columnTextLines[k], posX);
                    const columnName = this._header[columnNum];
                    if (rowNum !== ROWNUM_TABLE_HEADER
                        && this._tableMetadata.getColumnType(columnName) === 'currency' 
                        ){
                        this._addText(columnTextLines[k] + ` ${this._currencySymbol}`, rightAlignedPosX, posY);
                    }else{
                        this._addText(columnTextLines[k], rightAlignedPosX, posY);
                    } 
                }else{
                    this._addText(columnTextLines[k], posX, posY);
                }
            }
            const minWidthMultiplier = this._tableMetadata.getMultiplierOfColumn(columnNum);
            posX = posX + this._marginLeft *  minWidthMultiplier; 
            
        }
      }
      // ------------------------------------------------------------------------
      private _wrapColumnText(columnText: string, width: number): string[]{
   
        const words = columnText.split(' ');
        let line = '';
        const columnTextLines = [];
        
        for (let word of words) {
            
            if (this._pdf.getStringUnitWidth(line + word) < width) {
                line += (line ? ' ' : '') + word;
            } else {
                columnTextLines.push(line);
                line = word;
            }
        }
        columnTextLines.push(line);
        return columnTextLines;  
      }
      // ------------------------------------------------------------------------
      private _calculateColumnWith(columnNum: number){
        const minWidthMultiplier = this._tableMetadata.getMultiplierOfColumn(columnNum);
        return MIN_WIDTH *  minWidthMultiplier;
      }

      // ------------------------------------------------------------------------
      private _calculateRightAlignedPosX(columnWidth: number, text: string, posX: number){
        return posX + columnWidth * 2.5 - (this._pdf.getStringUnitWidth(text) * (this.fontSize * 0.35));
      }
      // ------------------------------------------------------------------------
      private _addText(text: string, posX: number, posY: number){
        this._pdf.text(text, posX, posY);
      }
      // ------------------------------------------------------------------------
      private _isColumnAlignToRigh(columnNum: number): boolean{
        const columnName = this._header[columnNum];
        const columnType = this._tableMetadata.getColumnType(columnName);
        return columnType && COLUMNS_TYPE_TO_ALIGN_RIGHT.includes(columnType)
      }
      // ------------------------------------------------------------------------
      private  _drawLineAfterRow(rowNum: number){
        this._pdf.setDrawColor(202,202,202);
        this._pdf.line(
            this._marginLeft - this._padding, 
            this._marginTop + (rowNum + 0.65) * 10, 
            290, 
            this._marginTop + (rowNum + 0.65) * 10
            ); 
      }
    // ------------------------------------------------------------------------
      private _drawLineMarginLeft(numRows: number){
        this._pdf.setDrawColor(202,202,202);
        this._pdf.line(
            this._marginLeft - this._padding, 
            this._marginTop + (-2 + 0.65) * 10, 
            this._marginLeft - this._padding, 
            this._marginTop + (numRows - 1 + 0.65) * 10
            ); 
      }
}

// ------------------------------------------------------------------------
// ------------------------------------------------------------------------
// ------------------------------------------------------------------------
/**
 * @example 
 * tableParams = 
    [
        {name: "Category", key:"categoryName"},
        {name: "Company", key:"companyName"},
        {name: "OrderID", key:"companyId", type:"number"},
        {name: "Cost", key:"cost", type:"currency"},
    ]
 */

    class TableMetadata{
        private _columnFactor: IColumnWidthMultiplier[];
        private _header: string[];
        private _tableParams: ITableParams[];
    
        constructor(
            header: string[], 
            columnFactor: IColumnWidthMultiplier[],
            tableParams: ITableParams[],
            ){
            this._columnFactor = columnFactor;
            this._header = header;
            this._tableParams = tableParams;
        }
    
        get length(): number{
            return this._header.length;
        }
    
        getMultiplierOfColumn(index: number): number{
            const key = this._header[index];
            const columnFactor = this._columnFactor.find(param => param.key === key);
            return columnFactor.factor;
        }
        getKeyOfIndex(index: number): string {
            return this._header[index];
        }
        isSkipingColumn(index: number):boolean{
            return undefined === this.findColumnMultiplierByKey(this._header[index]);
        }
        findColumnMultiplierByKey(key: string):IColumnWidthMultiplier{
            return this._columnFactor.find(param => param.key === key);
        }
        getHeaderNames() {
            const headerNames : string[] = [...this._header];
            for (let i = 0; i < headerNames.length; i++) {
                const headerKey = headerNames[i];
                const matchingColumn = this._tableParams.find(column => column.key === headerKey);
                if (matchingColumn) {
                    headerNames[i] = matchingColumn.name;
                }
            }
    
            return headerNames;
        }
        getColumnType(key: string): string {
            const param = this._tableParams.find(param => param.key === key);
            return param ? param.type : undefined;
        }
    
    }