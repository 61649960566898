<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'USERS.TITLE' | translate }}</h1>
  <button
    class="ui left floated primary icon button"
    routerLink="/usuarios/nuevo"
    *ngIf="roleSrv.can('createUser')"
  >{{ 'USERS.ADD_USER' | translate }}
  </button>
</div>

<div class="sixteen wide column">
  <app-collapsible-form  title="{{ 'USERS.FILTERS' | translate }}">
    <form  [formGroup]="filtersForm">
      <div class="form-row">
        <pui-field>
          <label>{{ 'USERS.NAME' | translate }}</label>
          <pui-input>
            <input type="text" formControlName="name">
          </pui-input>
        </pui-field>
        <pui-field>
          <label>{{ 'USERS.SURNAME' | translate }}</label>
          <pui-input>
            <input type="text" formControlName="surname">
          </pui-input>
        </pui-field>
        <pui-field>
          <label>{{ 'USERS.COMPANY' | translate }}</label>
          <pui-input>
            <input type="text" formControlName="company">
          </pui-input>
        </pui-field>
        <pui-field>
          <label>Email</label>
          <pui-input>
            <input type="text" formControlName="email">
          </pui-input>
        </pui-field>
      </div>
      <div class="form-row">
        <pui-field>
          <label>{{ 'USERS.MOBILE' | translate }}</label>
          <pui-input>
            <input type="text" formControlName="mobile">
          </pui-input>
        </pui-field>
        <pui-field>
          <label>{{ 'USERS.ROLE' | translate }}</label>
          <pui-dropdown
            multiple
            formControlName="roleIds"
            [options]="roleOptions"
            [search]="true"
            valueParam="id"
            labelParam="name"
          ></pui-dropdown>
        </pui-field>
      </div>
      <div class="filter-form-actions">
        <pui-button basic label="{{ 'USERS.CLEAR' | translate }}" [disabled]="filtersForm.pristine" (click)="clearFilters()"></pui-button>
        <pui-button small label="{{ 'USERS.FILTER' | translate }}" [disabled]="filtersForm.pristine || filterFormSubmit" (click)="applyFilters()"></pui-button>
      </div>
    </form>
  </app-collapsible-form>
</div>

<div class="eight wide mobile four wide computer column">
  <div class="ui small fluid icon input">
    <input type="text" placeholder="{{ 'USERS.QUICK_SEARCH' | translate }}" (keyup)="searchChange($event.target.value)">
    <i class="search icon"></i>
  </div>
</div>
<div class="computer only nine wide computer column"></div>
<div class="eight wide mobile three wide computer column">
  <fui-dropdown
    [id]="'numElemSelector'"
    [name]="'numElemSelector'"
    [fluid]="true"
    [options]="numUsersPagOptions"
    [defaultSelected]="numUserPerPage$.value"
    (selectedChange)="numElemPerPageSelected($event)">
  </fui-dropdown>
</div>

<div class="sixteen wide column">
  <div class="table-container">
  <table class="ui selectable sortable celled small compact table">
    <thead>
    <tr>
      <th
        [ngClass]="{
        sorted: sortBy$.value?.column === 'name',
        ascending: sortBy$.value?.column === 'name' && sortBy$.value?.order === 'ASC',
        descending: sortBy$.value?.column === 'name' && sortBy$.value?.order === 'DES'
        }"
        (click)="orderByColumn('name')"
      >{{ 'USERS.NAME' | translate }}</th>
      <th
        [ngClass]="{
        sorted: sortBy$.value?.column === 'surname',
        ascending: sortBy$.value?.column === 'surname' && sortBy$.value?.order === 'ASC',
        descending: sortBy$.value?.column === 'surname' && sortBy$.value?.order === 'DES'
        }"
        (click)="orderByColumn('surname')"
      >{{ 'USERS.SURNAME' | translate }}</th>
      <th
        [ngClass]="{
        sorted: sortBy$.value?.column === 'company',
        ascending: sortBy$.value?.column === 'company' && sortBy$.value?.order === 'ASC',
        descending: sortBy$.value?.column === 'company' && sortBy$.value?.order === 'DES'
        }"
        (click)="orderByColumn('company')"
      >{{ 'USERS.COMPANY' | translate }}</th>
      <th
        [ngClass]="{
        sorted: sortBy$.value?.column === 'email',
        ascending: sortBy$.value?.column === 'email' && sortBy$.value?.order === 'ASC',
        descending: sortBy$.value?.column === 'email' && sortBy$.value?.order === 'DES'
        }"
        (click)="orderByColumn('email')"
      >{{ 'USERS.EMAIL' | translate }}</th>
      <th
        [ngClass]="{
        sorted: sortBy$.value?.column === 'mobile',
        ascending: sortBy$.value?.column === 'mobile' && sortBy$.value?.order === 'ASC',
        descending: sortBy$.value?.column === 'mobile' && sortBy$.value?.order === 'DES'
        }"
        (click)="orderByColumn('mobile')"
      >{{ 'USERS.MOBILE' | translate }}</th>
      <th
        [ngClass]="{
        sorted: sortBy$.value?.column === 'role',
        ascending: sortBy$.value?.column === 'role' && sortBy$.value?.order === 'ASC',
        descending: sortBy$.value?.column === 'role' && sortBy$.value?.order === 'DES'
        }"
        (click)="orderByColumn('role')"
      >{{ 'USERS.ROLE' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users$ | async" [routerLink]="['/usuarios', user.id]">
      <td>{{user.name}}</td>
      <td>{{user.surname || '-'}}</td>
      <td>{{user.company?.name}}</td>
      <td>{{user.email}}</td>
      <td>{{user.mobile || '-'}}</td>
      <td>{{user.userFacilityRoles[0]?.role.name || "-"}}</td>
    </tr>
    </tbody>
  </table>
</div>
<div class="table-foot">
<tfoot>
  <tr>
    <th colspan="6">
      <fui-pagination-menu
        [page]="page$ | async"
        [numOfPages]="numOfPages"
        (pageSelected)="goToPageNum($event)"
      ></fui-pagination-menu>
    </th>
    <th colspan="6" class="pagination-info">{{ 'USERS.SHOWING_USERS' | translate }} {{firstItemNumOfPage()}} {{ 'USERS.TO' | translate }} {{lastItemNumOfPage()}} {{ 'USERS.OF_TOTAL' | translate }} {{numTotalUsers}}</th>
  </tr>
  </tfoot>
</div>
</div>


<!--
 TODO: Change to use toastService
-->
<div class="centered row">
  <div class="twelve wide column">
    <div class="ui success message transition" [class.hidden]="!showSuccessCreateNotification">
      <i class="close icon" (click)="hideSuccessCreateNotification()"></i>
      <div class="header">
        {{ 'USERS.SUCCESS_TITLE' | translate }}
      </div>
      <p>{{ 'USERS.SUCCESS_MESSAGE' | translate }}</p>
    </div>
  </div>
</div>
