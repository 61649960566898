import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {animate, sequence, state, style, transition, trigger} from '@angular/animations';
import { Router } from '@angular/router';
import { IncidencesService } from 'src/app/incidences/incidences.service';
import { FacilitiesService } from 'src/app/facilities/facilities.service';
import { ContextMenuComponent, 
  ContextMenuConfig, 
  ContextMenuFacility, 
  LABEL_CONTEXT_MENU_COMPONENT, 
  LABEL_CONTEXT_MENU_FACILITY,
   TREE_LEVEL, isContextMenuComponent, 
   isContextMenuFacility } from '../../models/collapsible-tree.model';
import { ContextMenuOption } from '../../models/collapsible-tree.interface';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-collapsible-tree',
  templateUrl: './collapsible-tree.component.html',
  styleUrls: ['./collapsible-tree.component.scss'],
  animations: [
    trigger('caretRotation', [
      state('open', style({transform: 'rotate(90deg)'})),
      state('close', style({transform: 'rotate(0deg)'})),
      transition('open <=> close', [
        animate(200)
      ])
    ]),
    trigger('filtersShow', [
      transition(':enter', [
        style({height: 0, opacity: 0}),
        sequence([
          animate(100, style({height: '*'})),
          animate(100, style({opacity: 1})),
        ]),
      ]),
      transition(':leave', [
        sequence([
          animate(100, style({opacity: 0})),
          animate(100, style({height: 0}))
        ])
      ])
    ])
  ]
})
export class CollapsibleTreeComponent implements OnInit {

  showContent = false;
  @Input() title: string;
  @Input() class: string;
  @Input() level: TREE_LEVEL;
  @Input() id: string;
  @Input() facilityId : string;

  contextMenuVisible = false;
  contextMenuOptions: ContextMenuOption[];
  contextMenuLeft: number;
  contextMenuTop: number;
  private config: ContextMenuConfig;
  hasViewer: boolean;
  
  constructor(
    private router: Router,
    private incidenceService: IncidencesService,
    private facilitiesService: FacilitiesService,
    private sessionService: SessionService,
    private elementRef: ElementRef,
  ) {  
  }

  ngOnInit(): void {
    this.sessionService.activeFacility$
    .subscribe(activeFacility => {
      if (activeFacility) {
        this.hasViewer = activeFacility.externalFacilityObject != null ? true : false;
      }
    });

    this.config = {
      level: this.level,
      router: this.router,
      incidenceService: this.incidenceService,
      facilitiesService: this.facilitiesService,
      id: this.id,
      facilityId: this.facilityId,
      hasViewer: this.hasViewer,
    };
    
  }

  toggleContent() {
    this.showContent = !this.showContent;
  }

  showContextMenu(event: MouseEvent): void {
    event.preventDefault();
    this.contextMenuLeft = event.clientX;
    this.adjustContextMenuPosition();
    this.contextMenuVisible = true;
    this.contextMenuOptions = this.getContextMenuOptions(this.level);

    // Attach a click event listener to close the menu when clicking outside
    const closeMenuOnClick = (clickEvent: MouseEvent) => {
      const isClickInsideComponent = this.isDescendant(this.elementRef.nativeElement, clickEvent.target as Node);
      if (!isClickInsideComponent) {
        this.contextMenuVisible = false;
        document.removeEventListener('click', closeMenuOnClick);
      }
    };

    // Attach the event listener after a short delay to ensure it's not immediately triggered by the same click event
    setTimeout(() => {
      document.addEventListener('click', closeMenuOnClick);
    }, 0);
  }

  handleContextMenuOptionClick(option: ContextMenuOption): void {
    if (isContextMenuFacility(option.label as LABEL_CONTEXT_MENU_FACILITY)){
        new ContextMenuFacility(this.config).handleOption(option);
      }
    
    if (isContextMenuComponent(option.label as LABEL_CONTEXT_MENU_COMPONENT)){
      new ContextMenuComponent(this.config).handleOption(option);
    }
    
    this.contextMenuVisible = false;
  }

  getContextMenuOptions(level: TREE_LEVEL): ContextMenuOption[]{
    if (level === TREE_LEVEL.FACILITY){
      return new ContextMenuFacility(this.config).getOptions();
    }
    if (level === TREE_LEVEL.COMPONENT){
      return new ContextMenuComponent(this.config).getOptions();
    }
    console.assert(false,'Level unexpected')
  }

  private adjustContextMenuPosition() {

    if (navBar()) {
      const navBarWidth = navBar().offsetWidth;
      const adjustedLeft = this.contextMenuLeft - navBarWidth;
      this.contextMenuLeft = adjustedLeft;
    }

    function navBar(){
      return document.getElementById('sidenav');
    }
  }

  private isDescendant(parent: Node, child: Node): boolean {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }
}

