import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pagination-num-items-info',
  templateUrl: './pagination-num-items-info.component.html',
  styleUrls: ['./pagination-num-items-info.component.scss']
})
export class PaginationNumItemsInfoComponent implements OnInit {

  constructor() { }

  @Input() itemsName: string;
  @Input() page: number;
  @Input() numItemsPerPage: number;
  @Input() totalItems: number;

  ngOnInit(): void {
  }

  numOfPages() {
    return Math.ceil(this.totalItems / this.numItemsPerPage);
  }

  firstItem(): number {
    return ((this.page - 1) * this.numItemsPerPage) + 1;
  }

  lastItem(): number {
    if (this.page === this.numOfPages()) {
      return this.firstItem() + (this.totalItems % this.numItemsPerPage || this.numItemsPerPage) - 1;
    } else {
      return this.firstItem() + this.numItemsPerPage - 1;
    }
  }
}
