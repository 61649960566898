
import { 
    ReportHoursByCategoryAndMaintenanceDTO, 
    ReportHoursByCategoryAndMaintenanceReg, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessHoursByCategoryMaintenance extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }
    
    getData(){
        const {labels: categoryName, dataA : correctiveHours, dataB : preventiveHours, 
            dataA2 : correctiveHours2, dataB2 : scheduledHours2} = this.getHoursByCategoryAndMaintenanceDataSets(this.reportGrossData as ReportHoursByCategoryAndMaintenanceDTO);
            const dataSetsHoursCategoryAndMaintenance = [{data: correctiveHours, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_1"), stack: 'One date', order: 1},
                            {data: preventiveHours, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_1"), stack: 'One date', order: 2}]
            if (correctiveHours2.length > 0) {
            dataSetsHoursCategoryAndMaintenance.push({data: correctiveHours2, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_2"), stack: 'Other date', order: 1})
            dataSetsHoursCategoryAndMaintenance.push({data: scheduledHours2, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_2"), stack: 'Other date', order: 2})
            }
            this.reports.hoursByCategoryAndMaintenance = {
            labels: categoryName,
            dataSets: dataSetsHoursCategoryAndMaintenance
            };
            this.reports.showIncidenceStatusFilter = false;
    }
    
    private getHoursByCategoryAndMaintenanceDataSets(hoursByCategoryAndMaintenance: ReportHoursByCategoryAndMaintenanceDTO) {
    
        let labels = [];
        const dataA = [];
        const dataB = [];
        const dataA2 = [];
        const dataB2 = [];
        hoursByCategoryAndMaintenance.value.forEach((reg: ReportHoursByCategoryAndMaintenanceReg) => {
          labels.push(reg.categoryName);
          dataA.push(reg.correctiveHours);
          dataB.push(reg.scheduledHours);
        });
        if (hoursByCategoryAndMaintenance.value2) {
          hoursByCategoryAndMaintenance.value2.forEach((reg: ReportHoursByCategoryAndMaintenanceReg) => {
            labels.push(reg.categoryName);
            dataA2.push(reg.correctiveHours);
            dataB2.push(reg.scheduledHours);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataB, dataA2, dataB2};
      }
}