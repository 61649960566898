import {Directive, HostBinding, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[puiIcon]'
})
export class IconDirective {

  @HostBinding('class') get hostClass() {
    return  'fas fa-' + this.puiIcon;
  }

  @HostBinding('style.fontStyle') fontStyle = 'normal';
  @HostBinding('style.fontFamily') fontFamily = 'FontAwesome';

  @Input() puiIcon: string;

  constructor() { }
}
