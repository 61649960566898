import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private redirectUrl: string | null = null;
  private redirectQueryParams: any = {};

  private readonly apiHost = environment.apiHost;
  isUserLogged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private router: Router) {
    this.isUserLogged$.next(this.isAuthenticated());
  }

  login(email: string, password: string): Observable<any> {
    const language = (navigator.language || 'en').split('-')[0];
    return this.http.post(this.apiHost + `/auth/login?language=${language}`, {
      email,
      password
    }).pipe(map((response: any) => {
      localStorage.token = response.token;
      localStorage.refreshToken = response.refreshToken;
      this.isUserLogged$.next(true);

      return response;
    }));
  }

  googleLogin(idToken: string): Observable<any> {
    return this.http.post(this.apiHost + '/auth/google', {
      idToken,
    }).pipe(map((response: any) => {
      localStorage.token = response.token;
      localStorage.refreshToken = response.refreshToken;
      this.isUserLogged$.next(true);
      return response;
    }));
  }

  refreshToken(refreshToken: string): Observable<{ token: string }> {
    return this.http.post<{token: string}>(this.apiHost + '/auth/refresh-token', {
      refreshToken
    }).pipe(
      tap(response => localStorage.token = response.token)
    );
  }

  forgotPassword(email: string): Observable<any> {
    const language = (navigator.language || 'en').split('-')[0];
    return this.http.post<{success: string, message: string}>(this.apiHost + `/forgot-password?language=${language}`, {
      email
    }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  resetPassword(resetToken: string, newPassword:string ):Observable<any>{
    return this.http.post<{success: string, message: string}>(this.apiHost + '/reset-password', {
      resetToken,
      newPassword
    }).pipe(
      map((response: any) => response),
      catchError(this.handleError)
    );
  }

  isAuthenticated(): boolean {
    return !!localStorage.token;
  }

  getToken() {
    return localStorage.token;
  }

  getRefreshToken() {
    return localStorage.refreshToken;
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    this.isUserLogged$.next(false);
    this.router.navigate(['login']);
  }

  setRedirectUrl(url: string): void {
    this.redirectUrl = url;
  }
  getRedirectUrl(): string | null {
    return this.redirectUrl;
  }
  setRedirectQueryParams(params: any): void {
    this.redirectQueryParams = params;
  }
  getRedirectQueryParams(): any {
    return this.redirectQueryParams;
  }
  clearRedirectUrl(): void {
    this.redirectUrl = null;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    return throwError(error.error);
  }
}
