<ng-container [formGroup]="form">
  <div class="fields">
    <div class="four wide field">
      <label>{{ 'COMPONENT_TYPES.MODEL' | translate }}</label>
      <input formControlName="modelNumber" type="text">
    </div>
    <div class="four wide field">
      <label>{{ 'COMPONENT_TYPES.BRAND' | translate }}</label>
      <input formControlName="company" type="text">
    </div>
    <div class="eight wide field">
      <label>{{ 'COMPONENT_TYPES.DESCRIPTION' | translate }}</label>
      <input formControlName="description" type="text">
    </div>
  </div>

  <div class="four fields">
    <div class="field">
      <label>{{ 'COMPONENT_TYPES.WARRANTY_PARTS' | translate }}</label>
      <div class="ui right labeled input">
        <input appNumeric [integer]="true" formControlName="warrantyDurationParts" type="text">
        <div class="ui label">{{ 'COMPONENT_TYPES.YEARS' | translate }}</div>
      </div>
    </div>
    <div class="field">
      <label>{{ 'COMPONENT_TYPES.WARRANTY_GUARANTOR_PARTS' | translate }}</label>
      <input formControlName="warrantyGuarantorParts" type="text">
    </div>
    <div class="field">
      <label>{{ 'COMPONENT_TYPES.WARRANTY_LABOR' | translate }}</label>
      <div class="ui right labeled input">
        <input appNumeric [integer]="true" formControlName="warrantyDurationLabor" type="text">
        <div class="ui label">{{ 'COMPONENT_TYPES.YEARS' | translate }}</div>
      </div>
    </div>
    <div class="field">
      <label>{{ 'COMPONENT_TYPES.WARRANTY_GUARANTOR_LABOR' | translate }}</label>
      <input formControlName="warrantyGuarantorLabor" type="text">
    </div>
  </div>

  <div class="three fields">
    <div class="field">
      <label>{{ 'COMPONENT_TYPES.REPLACEMENT_COST' | translate }}</label>
      <div class="ui right labeled input">
        <input appNumeric formControlName="replacementCost" type="text">
        <div class="ui label">{{ currencySymbol }}</div>
      </div>
    </div>
    <div class="field">
      <label>{{ 'COMPONENT_TYPES.EXPECTED_LIFE' | translate }}</label>
      <div class="ui right labeled input">
        <input appNumeric [integer]="true" formControlName="expectedLife" type="text">
        <div class="ui label">{{ 'COMPONENT_TYPES.YEARS' | translate }}</div>
      </div>
    </div>
    <div class="field">
      <label>{{ 'COMPONENT_TYPES.NUM_COMPONENTS' | translate }}</label>
      <input readonly formControlName="components" type="text">
    </div>
  </div>
</ng-container>
