import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {ChartDataSets} from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import { BaseChartComponent } from '../../base-chart.component';

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.scss']
})
export class ColumnChartComponent extends BaseChartComponent implements  OnChanges {

  @ViewChild(BaseChartDirective, { static: true }) chartCanvas: BaseChartDirective;

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() title: string;

  @Input() chartDataSets: ChartDataSets[];
  @Input() labels: Label[];

  @Input() xAxesStacked = false;
  @Input() yAxesStacked = false;

  @Input() chartType = 'bar';

  chartOptions: any;
  chartId: string;

  constructor(

    private elementRef: ElementRef,
    ) {
    super();
  }

  chartColors: Array<any> = [
    {backgroundColor: 
    ['#329648',
    '#005ec9',
    '#c74f42',
    '#deac00',
    '#12b0ab',
    '#c74289',
    '#4c9efd',
    '#42c781',
    '#fc523f',
    '#7632fc',
    '#0e0091',
    '#91004e',
    '#de4d00',
    '#f08907',
    '#916a00',
    '#ff5252', '#689f38', '#0277bd', '#fbc02d', '#7b1fa2', '#00897b', 
    '#ff6f00', '#009688', '#d32f2f', '#4caf50', '#2196f3', '#ef6c00', 
    '#9e9d24', '#3f51b5', '#e91e63'
    ]}
  ];
 
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartDataSets || changes.title) {
      this.setupChartOptions();
    }
  }
  

  private calculateChartHeight(): number {
    const numBars = this.chartDataSets[0]?.data?.length || 0;
    const minHeight = 400;
    const maxHeight = 900;

    return Math.max(minHeight, Math.min(maxHeight, numBars * 40)); // Adjust 40 based on your preference
  }

  private adjustChartHeight(): void {
    const canvas = this.elementRef.nativeElement.querySelector('canvas');
    const calculatedHeight = this.calculateChartHeight();
    canvas.height = calculatedHeight;
  }

  private setupChartOptions(): void {

    this.adjustChartHeight();
    
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,

      title: {
        display: true,
        text: this.title,
        fontFamily: '"Exo 2", serif',
        fontColor: '#000000',
        fontSize: 18,
      },
      legend: {
        display: false,
      },

      scales: {
        xAxes: [{
          offset: false,
          stacked: this.xAxesStacked,
          ticks: {
            min: 0,
            fontFamily: '"Exo 2", serif',
            fontColor: '#000000',
          }
        }],
        yAxes: [{
          stacked: this.yAxesStacked,
          ticks: {
            beginAtZero: true,
            fontFamily: '"Exo 2", serif',
            fontColor: '#000000',
          }
        }]
      }
    };
  }

  getChartCanvas(): HTMLCanvasElement {
    const chartCanvas: HTMLCanvasElement = this.chartCanvas.chart.ctx.canvas;
    if (chartCanvas) {
      return chartCanvas;
    } else {
      return null;
    }
  }

  getTitle(){
    return this.title;
  }
}
