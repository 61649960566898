import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'fui-calendar-range',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.scss']
})
export class CalendarRangeComponent implements OnInit, OnDestroy {

  @HostBinding('class') get hostClasses() {
    const classes = ['ui'];
    if (this.size) {
      classes.push(this.size);
    }
    classes.push('form');
    return classes.join(' ');
  }

  @Input() selectorId: string;
  @Input() size: string;
  @Input() fromLabel: string;
  @Input() toLabel: string;

  private LITERALS: any;
  private translationsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private translationSubscription: Subscription;

  @Output() startDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() endDateChange: EventEmitter<Date> = new EventEmitter<Date>();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.translationSubscription = this.translate.onLangChange.subscribe(() => {
      this.loadTranslations();
    });
    this.loadTranslations();
  }

  ngOnDestroy(): void {
    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }
  }

  private loadTranslations() {
    this.translate.get('CALENDAR').subscribe(translations => {
      this.LITERALS = {
        days: translations.DAYS,
        months: translations.MONTHS,
        monthsShort: translations.MONTHS_SHORT,
        today: translations.TODAY,
        now: translations.NOW
      };
      this.translationsLoaded.next(true);
      setTimeout(() => {
        this.initialize();
      }, 200);
    });
  }

  private initialize() {
    this.translationsLoaded.pipe(
      skipWhile(loaded => !loaded),
      take(1)
    ).subscribe(() => {
      const startCalendar = $('#' + this.rangeStartId()) as any;
      const endCalendar = $('#' + this.rangeEndId()) as any;

      startCalendar.calendar({
        type: 'date',
        endCalendar,
        text: this.LITERALS,
        monthFirst: false,
        firstDayOfWeek: 1,
        onSelect: this.selectedStartDate.bind(this)
      });
      endCalendar.calendar({
        type: 'date',
        startCalendar,
        text: this.LITERALS,
        monthFirst: false,
        firstDayOfWeek: 1,
        onSelect: this.selectedEndDate.bind(this)
      });

      this.initialized.next(true);
    });
  }

  public rangeStartId(): string {
    return `${this.selectorId}-range-start`;
  }

  public rangeEndId(): string {
    return `${this.selectorId}-range-end`;
  }

  setStartDate(date: Date, updateInput = true, fireChange = true) {
    this.initialized.pipe(
      skipWhile(initialized => !initialized),
      take(1)
    ).subscribe(() => {
      const startCalendar = $('#' + this.rangeStartId()) as any;
      startCalendar.calendar('set date', date, updateInput, fireChange);
      if (fireChange) {
        this.startDateChange.emit(date);
      }
    });
  }

  setEndDate(date: Date, updateInput = true, fireChange = true) {
    this.initialized.pipe(
      skipWhile(initialized => !initialized),
      take(1)
    ).subscribe(() => {
      const endCalendar = $('#' + this.rangeEndId()) as any;
      endCalendar.calendar('set date', date, updateInput, fireChange);
      if (fireChange) {
        this.endDateChange.emit(date);
      }
    });
  }

  selectedStartDate(date, mode) {
    this.startDateChange.emit(date);
  }

  selectedEndDate(date, mode) {
    this.endDateChange.emit(date);
  }
}
