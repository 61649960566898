<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'REQUEST_TITLE' | translate }} ({{(detailIncidence.incidence$ | async)?.id}}): {{(detailIncidence.incidence$ | async)?.topic}}</h1>
</div>

<div class="sixteen wide column">
  <div class="ui stackable grid container">
    <div class="three wide column">
      <div class="ui fluid vertical menu">
        <a class="item"
           routerLink="info"
           routerLinkActive="active">
          {{ 'MENU.INFO' | translate }}
        </a>
        <a *ngIf="canViewWorkOrders()"
           routerLink="ordenes-de-trabajo"
           routerLinkActive="active"
           class="item">
          {{ 'MENU.WORK_ORDERS' | translate }}
        </a>
        <a class="item" *ngIf="canViewChat()"
           routerLink="chat"
           routerLinkActive="active">
          {{ 'MENU.MESSAGES' | translate }}
        </a>
        <a class="item" routerLink="documentos" routerLinkActive="active">
          {{ 'MENU.FILES' | translate }}
        </a>
        <a class="item" routerLink="componentes" routerLinkActive="active">
          {{ 'MENU.COMPONENTS' | translate }}
        </a>
        <a class="item" *ngIf="canViewRating()" routerLink="valoracion" routerLinkActive="active">
          {{ 'MENU.RATING' | translate }}
        </a>
      </div>
    </div>

    <div class="thirteen wide column">
          <router-outlet></router-outlet>
    </div>
  </div>
</div>
