// reset-password.component.ts
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserFormComponentValidator } from '../shared/validators/users.validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  iconClass = 'eye slash';
  passwordVisible = false;
  resetPasswordForm: FormGroup;
  loading: boolean;
  successMessage: string | null = null;
  errorMessage: string | null = null;
  
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.resetPasswordForm = fb.group({
      resetToken: [this.route.snapshot.queryParams.resetToken, Validators.required],
      newPassword: ['', [Validators.required, UserFormComponentValidator.passwordStrength]],
    });
  }

  async resetPasswordSubmit() {
    this.loading = true;
    this.resetPasswordForm.markAllAsTouched();

    try {
      const { resetToken, newPassword } = this.resetPasswordForm.value;
        this.authService.resetPassword( resetToken, newPassword ).subscribe(
            (result) => {

              this.errorMessage = null;
              this.successMessage = result.message;
              setTimeout(() => {
                this.router.navigate(['/login']);
              }, 3000);
            },
            (error) => {
              console.error('Error resetting password', error);
              this.successMessage = null;
              this.errorMessage = error.message;
            }
          ).add(() => {
            // This will run regardless of success or error
            this.loading = false;
          });
      
    } catch (error) {
      console.error('Error resetting password', error);
    } finally {
      this.loading = false;
    }
  }

  ngOnInit(): void {}

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    this.iconClass = this.passwordVisible ? 'eye' : 'eye slash';
  }
}
