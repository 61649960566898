<form class="ui form" autocomplete="off" [formGroup]="form" (ngSubmit)="submit()">
    <div class="sixteen wide column">
        <button class="ui left primary button" type="submit" [class.disabled]="isEditing ? !form.valid || !form.dirty : !form.valid">
            {{ 'SETTINGS.BUTTON_SAVE' | translate }}
        </button>
    </div>
    <div class="ui divider hidden"></div>
    
    <div class="two fields">
        <div class="field">
            <app-label-and-info
                [labelText]="'SETTINGS.FIELD_LANGUAGE' | translate"
                [helpingText]="'SETTINGS.HELP_LANGUAGE' | translate"
                [forAttribute]="'languageUnit'"
            ></app-label-and-info>
            <pui-dropdown
                search
                [options]="languageCodes"
                formControlName="languageUnit"
            ></pui-dropdown>
        </div>
      </div>
  </form>
  