import { Component, HostBinding, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Facility } from "../../../facilities/models/facility.interface";
import { CategoryInterface } from "../../../shared/models/category.interface";
import { CriticalityLevelInterface } from "../../models/criticalityLevel.interface";
import { SessionService } from "../../../shared/services/session.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-config-notification-page",
  templateUrl: "./config-notification-page.component.html",
  styleUrls: ["./config-notification-page.component.scss"],
})
export class ConfigNotificationPageComponent implements OnInit {
  @HostBinding("class") hostClasses = "ui container grid";

  sections: { key: string; title: string }[];

  activeSection: { key: string; title: string };

  activeFacility: Facility;
  categories: CategoryInterface[];

  criticalityLevels: CriticalityLevelInterface[];
  levelForm: FormGroup;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.levelForm = new FormGroup(
      {
        name: new FormControl("", Validators.required),
        hours: new FormControl(null),
        minutes: new FormControl(null),
      },
    );
  }

  ngOnInit(): void {
    this.session.activeFacility$
      .pipe(filter((facility) => !!facility))
      .subscribe((facility) => {
        this.activeFacility = facility;
        this.updateRoute();
      });

    this.configureSections();
    this.activeSection = this.sections[0];
    this.checkFacilityAsQueryParam();
  }

  checkFacilityAsQueryParam() {
    this.route.queryParams.pipe(
      take(1),
      switchMap((queryParams) => {
        if (queryParams.facilityId) {
          return this.session.facilities$.pipe(
            takeUntil(this.destroy$),
            map((facilities) => facilities.find(facility => facility.id === parseInt(queryParams.facilityId)))
          );
        } else {
          return of(null);
        }
      })
    ).subscribe((facility) => {
      if (facility) {
        this.session.setActiveFacility(facility);
      }else {
        this.updateRoute();
      }
    });
  }

  updateRoute(){
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {facilityId: this.activeFacility.id},
      queryParamsHandling: 'merge',
    });
  }

  private configureSections() {
    this.sections = [
      { key: "notifications", title: this.translate.instant("SETTINGS.NOTIFICATIONS") },
      { key: "problemTypes", title: this.translate.instant("SETTINGS.PROBLEM_TYPES") },
      { key: "criticalityLevels", title: this.translate.instant("SETTINGS.CRITICALITY_LEVELS") },
      { key: "facilityInfo", title: this.translate.instant("SETTINGS.FACILITY_INFO") },
      { key: "general", title: this.translate.instant("SETTINGS.GENERAL") },
    ];
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
