<div class="sixteen wide column">
  <h3 class="ui header">{{ 'WORK_ORDER_DETAIL.MATERIALS_TITLE' | translate }}</h3>
  <table class="ui small table" [class.selectable]="!(workOrder$ | async)?.wasFinished()">
    <thead>
      <tr>
        <th>{{ 'WORK_ORDER_DETAIL.SUBCATEGORY' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.DESCRIPTION' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.UNITS' | translate }}</th>
        <th>{{ 'WORK_ORDER_DETAIL.MEASURE' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let material of (workOrder$ | async)?.workOrderMaterials"
          [class.active]="isMaterialSelected(material)"
          (click)="onClickMaterial(material)">
        <td>{{ material.material.category.name }}</td>
        <td>{{ material.material.name }}</td>
        <td>{{ material.quantity }}</td>
        <td>{{ detailWorkOrder.materialUnitLiteral(material.materialUnitId) }}</td>
      </tr>
    </tbody>
    <tfoot *ngIf="!(workOrder$ | async)?.wasFinished()">
      <tr>
        <th colspan="4">
          <div *ngIf="(workOrder$ | async)?.isInProgress()"
               class="ui right floated small labeled icon button"
               [class.disabled]="selectedWorkOrderMaterials.length === 0"
               (click)="deleteSelectedMaterials()">
            <i class="trash icon"></i>
            {{ 'WORK_ORDER_DETAIL.DELETE_MATERIAL' | translate }}
            {{ selectedWorkOrderMaterials.length ? "(" + selectedWorkOrderMaterials.length + ")" : '' }}
          </div>
        </th>
      </tr>
    </tfoot>
  </table>
</div>

<div class="sixteen wide column" *ngIf="(workOrder$ | async)?.isInProgress()">
  <h4 class="ui header">{{ 'WORK_ORDER_DETAIL.ADD_MATERIAL_TITLE' | translate }}</h4>
  <app-add-material-form
    #addMaterialForm
    [workOrderComponents]="(workOrder$ | async)?.workOrderComponents"
    [workOrder]="(workOrder$ | async)"
    (valueChange)="addMaterialData = $event"
  ></app-add-material-form>
  <div class="field" style="display: flex; justify-content: flex-end">
    <div class="ui button" [class.disabled]="!addMaterialData" (click)="addMaterial()">
      {{ 'WORK_ORDER_DETAIL.ADD_MATERIAL_BUTTON' | translate }}
    </div>
  </div>
</div>

<div class="sixteen wide column ui form">
  <div class="two fields">
    <div class="field">
      <div class="ui form" [formGroup]="detailWorkOrder.workingTimeForm">
        <h3 class="ui header">{{ 'WORK_ORDER_DETAIL.WORKING_TIME_TITLE' | translate }}</h3>
        <div class="field required">
          <label>{{ 'WORK_ORDER_DETAIL.EXECUTION_TIME' | translate }}</label>
          <div class="two fields">
            <div class="field">
              <div class="ui right labeled input">
                <input appNumeric [readOnly]="(workOrder$ | async)?.wasFinished()"
                      [min]="0"
                      integer
                      formControlName="workingTimeHours"
                      type="text"
                      [class.empty-input]="!detailWorkOrder.workingTimeForm.controls.workingTimeHours.value && detailWorkOrder.workingTimeForm.controls.workingTimeHours.value !== 0">
                <div class="ui basic label">{{ 'WORK_ORDER_DETAIL.HOURS' | translate }}</div>
              </div>
            </div>
            <div class="field">
              <div class="ui right labeled input">
                <input appNumeric [readOnly]="(workOrder$ | async)?.wasFinished()"
                      [min]="0"
                      integer
                      formControlName="workingTimeMinutes"
                      type="text"
                      [class.empty-input]="!detailWorkOrder.workingTimeForm.controls.workingTimeMinutes.value && detailWorkOrder.workingTimeForm.controls.workingTimeMinutes.value !== 0">
                <div class="ui basic label">{{ 'WORK_ORDER_DETAIL.MINUTES' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field">
      <form class="ui form" [formGroup]="detailWorkOrder.validationEmailForm">
        <h3 class="ui header">{{ 'WORK_ORDER_DETAIL.RESPONSIBLE_TITLE' | translate }}</h3>
        <div class="field required">
          <app-label-and-info
            [labelText]="'WORK_ORDER_DETAIL.SIGNATURE_EMAIL_LABEL' | translate"
            [helpingText]="'WORK_ORDER_DETAIL.EMAIL_VALIDATION_HELP' | translate"
          ></app-label-and-info>
          <fui-dropdown-two
            [readonly]="(workOrder$ | async)?.wasFinished()"
            selectorId="validationEmailSel"
            [options]="detailWorkOrder.validators"
            [search]="true"
            valueProperty="id"
            nameProperty="email"
            placeholder="{{ 'WORK_ORDER_DETAIL.EMAIL_PLACEHOLDER' | translate }}"
            formControlName="validationEmail"
          ></fui-dropdown-two>
        </div>
      </form>
    </div>
  </div>
  <div class="ui divider hidden"></div>
</div>
