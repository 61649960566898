import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {UserGetPaginatedDtoInterface, UserInterface} from '../../models/user.interface';
import {UserService} from '../../services/user.service';
import {SessionService} from '../../../shared/services/session.service';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {debounceTime, map, startWith, switchMap, tap} from 'rxjs/operators';
import {RolesService} from '../../../shared/services/roles.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getAllRolesOptions } from 'src/app/shared/models/role.interface';


@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';

  users$: Observable<UserInterface[]>;
  numTotalUsers: number;

  page$ = new BehaviorSubject<number>(1);
  numOfPages: number;
  numUsersPagOptions: { value: string, name: string }[] = [
    {
      value: '10',
      name: '10',
    },
    {
      value: '20',
      name: '20',
    },
    {
      value: '50',
      name: '50',
    }
  ];
  numUserPerPage$ = new BehaviorSubject<{ value: string, name: string }>(this.numUsersPagOptions[0]);

  showSuccessCreateNotification: boolean;

  searchQuery = new Subject<string>();
  filtersForm: FormGroup;
  filterFormSubmit: boolean = true;
  roleOptions :any;
  sortBy$ = new BehaviorSubject<{ column: string, order: string }>(null);
  filterParams$ = new BehaviorSubject<{name?: string, surname?: string, company?: string, email?: string, mobile?: string, role?: number[]}>({});

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userSrv: UserService,
    private sessionSrv: SessionService,
    public roleSrv: RolesService,
    private translate: TranslateService,
  ) {
    this.showSuccessCreateNotification = (router.getCurrentNavigation().extras.state || {}).successfullUserCreate;

    this.filtersForm = this.fb.group({
      name: '',
      surname: '',
      company: '',
      email: '',
      mobile: '',
      roleIds: []
    });
  }

  ngOnInit(): void {
    this.roleOptions = getAllRolesOptions(this.translate);

    this.users$ = combineLatest(
      [
        this.sessionSrv.activeFacility$,
        this.page$,
        this.numUserPerPage$,
        this.searchQuery.pipe(
          startWith(''),
          debounceTime(500)
        ),
        this.sortBy$,
        this.filterParams$
      ]
    ).pipe(
      switchMap(([facility, page, usersPerPage, search, sort, filters]) => {
        let params = {
          facilityId: facility.id,
          page,
          limit: usersPerPage.value
        };
        if (search && search.trim() !== '') {
          params = Object.assign(params, {search});
        }
        if (sort) {
          params = Object.assign(params, {sortBy: sort.column, order: sort.order});
        }
        params = Object.assign(params, filters);

        return this.userSrv.fetchAll(params);
      }),
      tap((response: UserGetPaginatedDtoInterface) => {
        this.numOfPages = response.meta.totalPages;
        this.numTotalUsers = response.meta.totalItems;
      }),
      map(response => response.items)
    );

    this.filtersForm.valueChanges.subscribe(value => this.filterFormSubmit = false);
  }

  ngOnDestroy() {
  }

  firstItemNumOfPage(): number {
    return (this.page$.value - 1) * parseInt(this.numUserPerPage$.value.value) + 1;
  }

  lastItemNumOfPage(): number {
    if (this.page$.value === this.numOfPages) {
      return this.numTotalUsers;
    }

    return this.page$.value * parseInt(this.numUserPerPage$.value.value);
  }

  goToPageNum(num: number) {
    this.page$.next(num);
  }

  numElemPerPageSelected(selected) {
    this.numUserPerPage$.next(selected);
  }

  searchChange(value) {
    this.searchQuery.next(value);
  }

  hideSuccessCreateNotification() {
    this.showSuccessCreateNotification = false;
  }

  orderByColumn(column: string) {
    if (!this.sortBy$.value || this.sortBy$.value.column !== column) {
      this.sortBy$.next({column, order: 'ASC'});
    } else if (this.sortBy$.value?.column === column) {
      if (this.sortBy$.value.order === 'ASC') {
        this.sortBy$.next({column, order: 'DES'});
      } else {
        this.sortBy$.next(null);
      }
    }
  }

  applyFilters() {
    this.filterFormSubmit = true;
    const formValues = this.filtersForm.value;
    Object.keys(formValues).forEach(param => {
      if (!formValues[param]) {
        delete formValues[param];
      }
    });
    if (formValues.roleIds && formValues.roleIds.length) {
      formValues.roleIds = formValues.roleIds.map(roleObj => roleObj.id);
    }

    this.filterParams$.next(formValues);
  }

  clearFilters() {
    this.filtersForm.reset();
    this.filterParams$.next({});
    this.page$.next(1);
  }

}
