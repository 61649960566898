import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RolesService} from '../shared/services/roles.service';
import {AuthGuardService} from '../auth/auth-guard.service';
import {ROLES} from '../shared/models/role.interface';

import {WorkOrdersListComponent} from './pages/work-orders-list/work-orders-list.component';
import {DetailWorkOrderComponent} from './pages/detail-work-order/detail-work-order.component';
import {LayoutComponent} from '../shared/layout/layout.component';
import {DetailWorkOrderInfoComponent} from './pages/detail-work-order/detail-work-order-info/detail-work-order-info.component';
import {DetailWorkOrderChatComponent} from './pages/detail-work-order/detail-work-order-chat/detail-work-order-chat.component';
import {DetailWorkOrderFacilityElemsComponent} from './pages/detail-work-order/detail-work-order-facility-elems/detail-work-order-facility-elems.component';
import {DetailWorkOrderMaterialsComponent} from './pages/detail-work-order/detail-work-order-materials/detail-work-order-materials.component';
import {DetailWorkOrderCostsComponent} from './pages/detail-work-order/detail-work-order-costs/detail-work-order-costs.component';
import {DetailWorkOrderDocumentsComponent} from './pages/detail-work-order/detail-work-order-documents/detail-work-order-documents.component';

const routes: Routes = [
  {
    path: 'ordenes-de-trabajo',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        component: WorkOrdersListComponent,
        canActivate: [RolesService],
        data: {
          roles: [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER]
        }
      },
      {
        path: ':id',
        component: DetailWorkOrderComponent,
        canActivate: [RolesService],
        data: {
          roles: [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER]
        },
        children: [
          {
            path: '',
            redirectTo: 'info',
            pathMatch: 'full'
          },
          {
            path: 'info',
            component: DetailWorkOrderInfoComponent
          },
          {
            path: 'documentos',
            component: DetailWorkOrderDocumentsComponent
          },
          {
            path: 'chat',
            component: DetailWorkOrderChatComponent
          },
          {
            path: 'componentes',
            component: DetailWorkOrderFacilityElemsComponent
          },
          {
            path: 'materiales',
            component: DetailWorkOrderMaterialsComponent
          },
          {
            path: 'costes',
            component: DetailWorkOrderCostsComponent,
            canActivate: [RolesService],
            data: {
              roles: [ROLES.OWNER, ROLES.MAINTENANCE_ADMIN]
            }
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class WorkordersRoutingModule {}
