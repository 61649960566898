import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {UserGroupInterface} from '../../models/user-group.interface';
import {UserService} from '../../../users/services/user.service';
import {SessionService} from '../../../shared/services/session.service';
import {Subject} from 'rxjs';
import {Facility} from '../../../facilities/models/facility.interface';
import {skipWhile, switchMap, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-user-groups-page',
  templateUrl: './user-groups-page.component.html',
  styleUrls: ['./user-groups-page.component.scss']
})
export class UserGroupsPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';

  actualFacility: Facility;
  groups: UserGroupInterface[];

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private session: SessionService,
    private userSrv: UserService
  ) {}

  ngOnInit(): void {
    this.session.activeFacility$.pipe(
      skipWhile(f => !f),
      takeUntil(this.destroy$),
      switchMap(facility => this.userSrv.fetchGroups(facility.id))
    ).subscribe(groups => {
      this.groups = groups;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
