// language.service.ts

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './session.service';
import { filter, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private initialized: boolean = false;
  private fallbackLanguage: string = 'en';

  constructor(
    private translate: TranslateService,
    private sessionService: SessionService,
  ) {
    this.initializeLanguage();
  }

   initializeLanguage() {
    if (!this.initialized) {
      // Use language from localStorage or browser's language or default to fallbackLanguage
      const browserLang = localStorage.getItem('language') || this.translate.getBrowserLang() || this.fallbackLanguage;
      const preferredLang = browserLang.match(/en|es/) ? browserLang : this.fallbackLanguage;
      this.translate.setDefaultLang('en'); // Set default language
      this.translate.use(preferredLang); // Use preferred language

      // Subscribe to userConfig$ to get the user's language preference
      this.sessionService.userConfig$
        .pipe(
          filter(config => !!config && !!config.language), // Filter out falsy values
          take(1), // Take the first emitted value and unsubscribe
          tap(config => {
            this.translate.use(config.language); // Use user-configured language
            localStorage.setItem('language', config.language); // Store the language in localStorage
            this.translate.reloadLang(config.language).subscribe(() => {
              this.translate.use(config.language); // Reload and use the user-configured language
            });
          })
        )
        .subscribe(() => {
          this.initialized = true; // Mark initialization as complete
        });
    }
  }
}
