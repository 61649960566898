import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent{
  @Input() options: any[];
  @Input() left: number;
  @Input() top: number;
  @Output() optionClicked = new EventEmitter<string>();


  constructor(
    ) {}

  
  handleOptionClick(option: any): void {
    this.optionClicked.emit(option);
  }
}
