import {NgModule} from '@angular/core';

import {ScheduledTasksRoutingModule} from './scheduled-tasks-routing.module';
import {ScheduledTaskService} from './scheduled-task.service';

// Components
import {
  AsFormGroupPipe,
  ConditionsFilterFormComponent
} from './components/conditions-filter-form/conditions-filter-form.component';
import {ConditionFormComponent} from './components/conditions-filter-form/condition-form/condition-form.component';
import {
  FilteredComponentsTableComponent
} from './components/filtered-components-table/filtered-components-table.component';

// Pages
import {AddScheduledTaskComponent, AsFormArrayPipe} from './pages/add-scheduled-task/add-scheduled-task.component';
import {ScheduledTasksPageComponent} from './pages/scheduled-tasks-page/scheduled-tasks-page.component';
import {ScheduledTasksListPageComponent} from './pages/scheduled-tasks-list-page/scheduled-tasks-list-page.component';
import {
  ScheduledTasksCalendarPageComponent
} from './pages/scheduled-tasks-calendar-page/scheduled-tasks-calendar-page.component';

import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import {
  ScheduledTaskDetailPageComponent
} from './pages/scheduled-task-detail-page/scheduled-task-detail-page.component';
import {
  ConditionsFilterGroupFormComponent
} from './components/conditions-filter-group-form/conditions-filter-group-form.component';
import {ScheduledTaskInfoFormComponent} from './components/scheduled-task-info-form/scheduled-task-info-form.component';
import {
  DetailPageInfoSectionComponent
} from './pages/scheduled-task-detail-page/detail-page-info-section/detail-page-info-section.component';
import {
  DetailPageFilterSectionComponent
} from './pages/scheduled-task-detail-page/detail-page-filter-section/detail-page-filter-section.component';
import {
  DetailPageViewerSectionComponent
} from './pages/scheduled-task-detail-page/detail-page-viewer-section/detail-page-viewer-section.component';
import {SharedModule} from '../shared/shared.module';
import {
  DetailPageEditSectionComponent
} from './pages/scheduled-task-detail-page/detail-page-edit-section/detail-page-edit-section.component';
import {AutodeskForgeModule} from '../autodesk-forge/autodesk-forge.module';
import { ScheduledTasksScheduledPageComponent } from './pages/scheduled-tasks-scheduled-page/scheduled-tasks-scheduled-page.component';
import { TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localePt from '@angular/common/locales/pt';
import localeIt from '@angular/common/locales/it';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeFr);
registerLocaleData(localePt);
registerLocaleData(localeIt);
registerLocaleData(localeDe);

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  declarations: [
    AsFormArrayPipe,
    AsFormGroupPipe,

    // Pages
    AddScheduledTaskComponent,
    ScheduledTasksListPageComponent,

    // Components
    ConditionsFilterFormComponent,
    ConditionFormComponent,
    ScheduledTasksPageComponent,
    ScheduledTasksCalendarPageComponent,
    ScheduledTasksScheduledPageComponent,
    ScheduledTaskDetailPageComponent,
    ConditionsFilterGroupFormComponent,
    ScheduledTaskInfoFormComponent,
    FilteredComponentsTableComponent,
    DetailPageInfoSectionComponent,
    DetailPageFilterSectionComponent,
    DetailPageViewerSectionComponent,
    DetailPageEditSectionComponent,
  ],
  imports: [
    SharedModule,
    ScheduledTasksRoutingModule,

    CalendarModule.forRoot({provide: DateAdapter, useFactory: momentAdapterFactory}),
    AutodeskForgeModule,
    TranslateModule.forChild(),
  ],
  providers: [ScheduledTaskService]
})
export class ScheduledTasksModule {
}
