import {Component, Input, OnChanges, OnInit, Pipe, PipeTransform, SimpleChanges} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {notEmptyValidator} from '../../shared/scheduled-tasks-validators';


@Pipe({
  name: 'asFormGroup'
})
export class AsFormGroupPipe implements PipeTransform {
  transform(value: any, ...args: any[]): FormGroup {
    return value;
  }
}

@Component({
  selector: 'app-conditions-filter-form',
  templateUrl: './conditions-filter-form.component.html',
  styleUrls: ['./conditions-filter-form.component.scss']
})
export class ConditionsFilterFormComponent implements OnInit, OnChanges {

  @Input() filterId: string;
  @Input() parentForm: FormArray;
  @Input() filterParams: {value: any, name: string}[];

  filter: object;

  unusedParams$: BehaviorSubject<{value: any, name: string}[]> = new BehaviorSubject<{value: any, name: string}[]>([]);

  constructor() { }

  ngOnInit(): void {}

  updateUnusedParams(conditions) {
    const used = [];
    conditions.forEach(condition => {
      if (condition.filterParam) {
        used.push(condition.filterParam);
      }
    });

    const available = this.filterParams.filter(param => !used.includes(param));
    this.unusedParams$.next(available);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.parentForm && this.parentForm) {
      this.updateUnusedParams(this.parentForm.value);
      this.parentForm.valueChanges.subscribe((value: any[]) => {
        const filter = {};
        value.forEach(condition => {
          if (condition.value) {
            const filterKey = condition.filterParam.value + 's';
            filter[filterKey] = condition.value;
          }
        });
        this.filter = filter;
        this.updateUnusedParams(value);
      });
    }
  }

  addNewCondition() {
    this.parentForm.push(new FormGroup({
      filterParam: new FormControl(null, Validators.required),
      value: new FormControl({value: null, disabled: true}, notEmptyValidator)
    }));
  }

  deleteCondition(index: number) {
    this.parentForm.removeAt(index);
  }
}
