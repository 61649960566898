<h2 class="ui header">{{"NOTIFICATIONS.TITLE" | translate}}</h2>

<div class="ui segment" *ngFor="let section of notificationSections">
  <h2 class="ui header">{{ section.title }}</h2>

  <div class="ui very relaxed divided list">
    <div *ngFor="let notificationConfig of getNotificationsByType(section.type)" class="item">
      <div class="content">
        {{notificationConfig.name}}
        <div class="ui checkbox notification-checkbox" *ngIf="!!enabledNotifications">
          <input
            type="checkbox"
            (ngModelChange)="onNotificationChange($event, notificationConfig.key)"
            [ngModel]="enabledNotifications[notificationConfig.key]"
          />
          <label></label>
        </div>
      </div>
    </div>
  </div>
</div>