import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  IncidenceInterface,
  IIncidencePaginatedResponse,
  INCIDENCE_STATES_LITERALS,
  INCIDENCE_TYPES, IncidencesFilterOptions, TableColumn, ColumnType,
} from '../../models/incidence.interface';
import {IncidencesService} from '../../incidences.service';
import {SessionService} from '../../../shared/services/session.service';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import * as moment from 'moment/moment';
import {ToastService} from '../../../shared/services/toast.service';
import {CompaniesService} from '../../../companies/companies.service';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CalendarRangeComponent} from '../../../fomantic-ui/calendar-range/calendar-range.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incidences-list',
  templateUrl: './incidences-list.component.html',
  styleUrls: ['./incidences-list.component.scss'],
  animations: [
    trigger('caretRotation', [
      state('open', style({transform: 'rotate(90deg)'})),
      state('close', style({transform: 'rotate(0deg)'})),
      transition('open <=> close', [
        animate(200)
      ])
    ]),
    trigger('filtersShow', [
      transition(':enter', [
         style({height: 0}),
         animate(200, style({height: '*'}))
      ]),
      transition(':leave', [
        animate(200, style({height: 0}))
      ])
    ])
  ]
})
export class IncidencesListComponent implements OnInit, OnDestroy {
  @HostBinding('class') hostClasses = 'ui grid container';

  @ViewChild('createRangeSel') createRangeSel: CalendarRangeComponent;
  @ViewChild('closeRangeSel') closeRangeSel: CalendarRangeComponent;

  incidencesSubs: Subscription;
  incidences: IncidenceInterface[];

  showFilters = false;

  sortBy$: BehaviorSubject<{column: string, order: string}> = new BehaviorSubject<{column: string, order: string}>(null);

  numElemPerPage: BehaviorSubject<number>;
  actualPage: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  numOfPages: number;
  totalElems: number;

  incidenceTypeOptions = [];
  categories$: Observable<CategoryInterface[]>;
  incidenceStatesOptions: {value: number, name: string}[];

  filtersForm: FormGroup;
  createFromDateFilter: Date;
  createToDateFilter: Date;
  closedFromDateFilter: Date;
  closedToDateFilter: Date;


  pagination: {page: number, numOfPages: number};
  filters: BehaviorSubject<IncidencesFilterOptions> = new BehaviorSubject<IncidencesFilterOptions>(null);
  relatedToComponentId: number;

  numPagesOptions = [
    { value: '10', name: '10'},
    { value: '20', name: '20'},
    { value: '50', name: '50'}
  ];

  tableColumns: TableColumn[] = [];


  constructor(
    private fb: FormBuilder,
    private session: SessionService,
    private incidencesSrv: IncidencesService,
    private companiesSrv: CompaniesService,
    private router: Router,
    private toastService: ToastService,
    private translate: TranslateService,
  ) {
    const navigationState = router.getCurrentNavigation()?.extras?.state;

    this.filters.next(navigationState?.filters);
    this.relatedToComponentId = navigationState?.relatedToComponentId;

    if (navigationState?.successfulIncidenceCreated) {
      this.toastService.showToast({
        type: 'success',
        message: this.translate.instant('NEW_REQUEST_CREATED')
      });
    }

    // @ts-ignore
    this.numElemPerPage = new BehaviorSubject<number>(null).pipe(filter(num => !!num));

    this.filtersForm = fb.group({
      id: null,
      types: null,
      categories: null,
      creatorSearch: '',
      statuses: null,
      topicSearch: '',
      descriptionSearch: ''
    });
  }

  private translateLabels() {
    this.translate.get('REQUESTS_TYPE').subscribe(translations => {
      this.incidenceTypeOptions = [
        {value: 0, name: translations.NO_TYPE},
        {value: 1, name: translations.INCIDENCE},
        {value: 2, name: translations.SERVICE_REQUEST}
      ];
    });

    this.translate.get('INCIDENCE_STATES').subscribe(translations => {
      this.incidenceStatesOptions = [
        {value: 1, name: translations.CREATED},
        {value: 2, name: translations.OPENED},
        {value: 3, name: translations.ASSIGNED},
        {value: 4, name: translations.FINISHED},
        {value: 5, name: translations.CLOSED},
        {value: 6, name: translations.VALUED},
      ];
    });

    this.translate.get('REQUESTS_TABLE').subscribe(translations => {
      this.tableColumns = [
        { id: 'id', name: translations.ID },
        { id: 'typeId', name: translations.TYPE, type: 'type' },
        { id: 'topic', name: translations.ISSUE },
        { id: 'statusId', name: translations.STATUS, type: 'state' },
        { id: 'createdAt', name: translations.CREATED, type: 'date' },
        { id: 'openedAt', name: translations.OPENED, type: 'date' },
        { id: 'assignedAt', name: translations.ASSIGNED, type: 'date' },
        { id: 'finishedAt', name: translations.FINISHED, type: 'date' },
        { id: 'closedAt', name: translations.CLOSED, type: 'date' }
      ];
    });

  }

  ngOnInit(): void {

    this.translateLabels();

    this.categories$ = this.companiesSrv.fetchCategories().pipe(
      map(categories => {
        return categories.filter(category => !category.parentCategoryId);
      })
    );

    this.incidencesSubs =  combineLatest(
      [
        this.session.activeFacility$.pipe(filter(facility => !!facility)),
        this.numElemPerPage,
        this.actualPage,
        this.filters,
        this.sortBy$
      ])
      .subscribe(([facility, numElem, page, filters, sort]) => {
        let params = {
          facilityId: facility.id,
          page,
          limit: numElem
        };

        if (filters) {
          params = Object.assign(params, filters);
        }
        // Retrieve additional parameters from state
        const navigationState = this.router.getCurrentNavigation()?.extras?.state;
        if (navigationState?.filters) {
          params = { ...params, ...navigationState.filters };
        }

        if (sort) {
          params = Object.assign(params, {
            sortBy: sort.column,
            order: sort.order
          });
        }

        this.incidencesSrv.fetchAllWithParams(params, this.relatedToComponentId)
          .subscribe((response: IIncidencePaginatedResponse) => {
          if (response.meta?.totalPages && response.meta.totalPages > 0 && page > response.meta.totalPages) {
            this.actualPage.next(response.meta.totalPages);
          } else {
            this.incidences = response.items;
            this.numOfPages = response.meta.totalPages;
            this.totalElems = response.meta.totalItems;
            this.pagination = {
              page,
              numOfPages: response.meta.totalPages
            };
          }
        });
    });
  }

  ngOnDestroy(): void {
    this.incidencesSubs.unsubscribe();
  }

  parseCell(elem, type: ColumnType) {
    switch (type) {
      case 'type':
        return this.incidenceTypeLiteral(elem);
      case 'state':
        return this.incidenceStateLiteral(elem);
      case 'date':
        return elem ? this.formatDate(elem) : '---';
      default:
        return elem;
    }
  }

  incidenceTypeLiteral(type: INCIDENCE_TYPES): string {
    switch (type) {
      case INCIDENCE_TYPES.INCIDENCE:
        return this.translate.instant('REQUESTS_TYPE.INCIDENCE');
      case INCIDENCE_TYPES.SERVICE_REQUEST:
        return this.translate.instant('REQUESTS_TYPE.SERVICE_REQUEST');
      default:
        return '---';
    }
  }

  formatDate(date: string) {
    moment().locale('es');
    return moment(date).format('YYYY-MM-DD');
  }

  incidenceStateLiteral(status: any) {
    return status ? this.translate.instant(`INCIDENCE_STATES.${INCIDENCE_STATES_LITERALS[status]}`) : '---';
  }

  selectedNumElemPerPage(option) {
    this.numElemPerPage.next(parseInt(option.value));
  }

  selectedIncidence(incidence: IncidenceInterface) {
    this.router.navigate(['solicitudes', incidence.id]);
  }

  goToAddIncidence() {
    this.router.navigate(['solicitudes/nueva']);
  }

  actualPageSelected(page: number) {
    this.actualPage.next(page);
  }

  getFilters(): IncidencesFilterOptions {
    const options: IncidencesFilterOptions = {
      ids: this.filtersForm.value.id ? this.filtersForm.value.id : null,
      typeIds: this.filtersForm.value.types ? this.filtersForm.value.types.map(type => type.value) : null,
      topic: this.filtersForm.value.topicSearch || null,
      description: this.filtersForm.value.descriptionSearch || null,
      statusIds: this.filtersForm.value.statuses ? this.filtersForm.value.statuses.map(status => status.value) : null,
      creator: this.filtersForm.value.creatorSearch || null,
      categoryIds: this.filtersForm.value.categories ? this.filtersForm.value.categories.map(cat => cat.id) : null,
      fromCreatedAt: this.createFromDateFilter ? this.createFromDateFilter.toISOString() : null,
      toCreatedAt: this.createToDateFilter ? this.createToDateFilter.toISOString() : null,
      fromClosedAt: this.closedFromDateFilter ? this.closedFromDateFilter.toISOString() : null,
      toClosedAt: this.closedToDateFilter ? this.closedToDateFilter.toISOString() : null
    };

    Object.keys(options).forEach(key => {
      if (options[key] === null) {
        delete options[key];
      }
    });

    return options;
  }

  applyFilters() {
    this.filters.next(this.getFilters());
  }

  filterCreateFromDateChange(date: Date) {
     this.createFromDateFilter = date ? moment(date).startOf('day').toDate() : null;
  }

  filterCreateToDateChange(date: Date) {
    this.createToDateFilter = date ? moment(date).endOf('day').toDate() : null;
  }

  filterClosedFromDateChange(date: Date) {
    this.closedFromDateFilter = date ? moment(date).startOf('day').toDate() : null;
  }

  filterClosedToDateChange(date: Date) {
    this.closedToDateFilter = date ? moment(date).endOf('day').toDate() : null;
  }

  clearFilters() {
    this.filtersForm.reset();
    this.createRangeSel.setStartDate(null);
    this.createRangeSel.setEndDate(null);
    this.closeRangeSel.setStartDate(null);
    this.closeRangeSel.setEndDate(null);

    this.filters.next(null);
  }

  orderByColumn(column: string) {
    if (!this.sortBy$.value || this.sortBy$.value.column !== column) {
      this.sortBy$.next({column, order: 'ASC'});
    } else if (this.sortBy$.value?.column === column) {
      if (this.sortBy$.value.order === 'ASC') {
        this.sortBy$.next({column, order: 'DES'});
      } else {
        this.sortBy$.next(null);
      }
    }
  }

  handleKeyPress(event){
    if (event.keyCode === 13)
      this.applyFilters();
  }
}
