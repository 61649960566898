import {ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../shared/services/session.service';
import {FacilitiesService} from '../../../facilities/facilities.service';
import {Facility} from '../../../facilities/models/facility.interface';
import { Subscription } from 'rxjs';
import {
  FacilityComponentInterface,
  FacilitySpaceInterface
} from '../../../facilities/models/facility-element.interface';
import {Router} from '@angular/router';
import {AutodeskViewerComponent} from '../../../autodesk-forge/components/autodesk-viewer/autodesk-viewer.component';

@Component({
  selector: 'app-viewer-page',
  templateUrl: './viewer-page.component.html',
  styleUrls: ['./viewer-page.component.scss']
})
export class ViewerPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';
  @ViewChild('adViewer') viewer: AutodeskViewerComponent;

  initialFocusComponent: FacilityComponentInterface;
  activeFacility: Facility;
  facilitySubs: Subscription;

  selectedElement: FacilityComponentInterface;
  isSelectedElement = false;

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private session: SessionService,
    private facilitiesService: FacilitiesService
  ) {
    this.initialFocusComponent = this.router.getCurrentNavigation()?.extras?.state?.component;
  }

  ngOnInit(): void {
    this.facilitySubs = this.session.activeFacility$.subscribe(active => {
      this.activeFacility = active;
      if (active) {
        console.info("IMBI." + active.externalFacilityObject);
      }
    });
  }

  ngOnDestroy(): void {
    this.facilitySubs.unsubscribe();
  }

  onSelectItemOnViewer(guid: string) {
    if (guid) {
      this.facilitiesService.fetchComponentByExternalIdentifier(guid, this.activeFacility.id)
        .subscribe(
          (component: FacilityComponentInterface) => {
            this.selectedElement = component;
            this.isSelectedElement = true;
            this.viewer.resize();
          },
          error => {
            this.selectedElement = null;
            this.isSelectedElement = true;
          }
        );
    } else {
      this.selectedElement = null;
      this.isSelectedElement = false;
    }
  }

  selectedSpaceOnSelector(space: FacilitySpaceInterface) {
    this.facilitiesService.fetchCubeForSpace(space.externalIdentifier, this.activeFacility.id)
      .subscribe(
        component => {
          this.viewer.focusElements(component);
        }
      );
  }

  selectedComponentOnSelector(component: FacilityComponentInterface) {
    this.selectedElement = component;
    this.viewer.focusElements(component);
  }

  closeElementInfoPanel() {
    this.selectedElement = null;
    this.isSelectedElement = false;
    this.viewer.resize();
  }

  onLoadViewer() {
    const isolate: boolean = false;
    if (this.initialFocusComponent) {
      this.viewer.focusElements(this.initialFocusComponent, isolate);
      this.selectedElement = this.initialFocusComponent;
      this.cd.detectChanges();
    }
  }
}
