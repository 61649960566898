import { TranslateService } from "@ngx-translate/core";
import { INCIDENCE_TYPES } from './models/incidence.interface';

export const INCIDENCE_TYPES_OPTIONS = [
    {value: INCIDENCE_TYPES.INCIDENCE, name: 'INCIDENCE'},
    {value: INCIDENCE_TYPES.SERVICE_REQUEST, name: 'SERVICE_REQUEST'},
  ]
  
  export function getIncidenceTypesOptions(translate: TranslateService){
    return INCIDENCE_TYPES_OPTIONS.map(option => ({ value: option.value, name: translate.instant(`REQUESTS_TYPE.${option.name}`) }));
  }