<div class="sixteen wide column" style="display: flex; justify-content: flex-start">
  <div 
    *ngIf="hasViewer" 
    class="ui button" 
    (click)="showSelectedComponents()">{{ 'SHOW_COMPONENTS' | translate }}
  </div>
  <div
    class="ui primary button"
    *ngIf="(detailIncidence.canUserEdit() | async)"
    [class.disabled]="!detailIncidence.isModified()"
    (click)="detailIncidence.saveChanges()"
  >{{ 'SAVE' | translate }}</div>
</div>
<div class="sixteen wide column">
  <div class="ui horizontal selection list">
    <div *ngFor="let component of (detailIncidence.incidence$ | async)?.components" 
    class="item" (click)="showIncidenceComponent(component.component)">
      <div class="content">
            <span style="margin-right: 10px">
              {{component.component.name}}
            </span>
        <div *ngIf="(detailIncidence.canUserEdit() | async)" class="ui mini icon button" (click)="removeComponent(component.component)">
          <i class="delete icon"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="hasViewer && (detailIncidence.canUserEdit() | async)"
      class="sixteen wide column" style="display: flex;">
      <i class="circle info icon"></i>
      <span>{{ 'DOUBLE_CLICK_ADD_COMPONENT' | translate }}</span>
</div>
<div *ngIf="(detailIncidence.canUserEdit() | async)"
class="sixteen wide column selector">
  <div 
    *ngIf="(detailIncidence.canUserEdit() | async)"
    class="ui segment navigation-form">
    <app-viewer-element-selector
      [facility]="activeFacility"
      (selectedSpace)="selectedSpaceOnSelector($event)"
      (selectedComponent)="selectedComponentOnSelector($event)"
    >
    </app-viewer-element-selector>
  </div>
</div>

<div [ngClass]="{
          eleven: selectedElem,
          sixteen: !selectedElem,
          wide: true,
          column: true
        }">
  <div *ngIf="activeFacility && hasViewer" style="height: 500px">
    <app-autodesk-viewer
      #viewer
      [document]="activeFacility.externalFacilityObject"
      (selectedElement)="showComponentInfo($event)"
      (doubleClick)="detailIncidence.addComponentToIncidence($event)"
      (loaded)="loadedViewer()"
    ></app-autodesk-viewer>
  </div>
</div>

<div *ngIf="selectedElem" class="five wide column">
  <app-element-info-panel
    class="element-panel"
    [element]="selectedElem"
    [addToNextIncidence]="true"
    (close)="closeElementInfoPanel()"
    [addComponentToCurrentEntity]="(detailIncidence.canUserEdit() | async)"
    [addToNextIncidence]="true"
    [addComponentCallback]="getAddComponentCallback()"
    [text]="'ADD_TO_THIS_REQUEST' | translate"
  ></app-element-info-panel>
</div>
