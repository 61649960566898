import {NgModule} from '@angular/core';
import {IncidencesRoutingModule} from './incidences-routing.module';
import {WorkordersModule} from '../workorders/workorders.module';

// Components
import {
  IncidenceStepsIndicatorComponent
} from './components/incidence-steps-indicator/incidence-steps-indicator.component';

// Pages
import {AddIncidenceComponent} from './pages/add-incidence/add-incidence.component';
import {AddWorkOrderComponent} from './pages/add-work-order/add-work-order.component';
import {DetailIncidenceComponent} from './pages/detail-incidence/detail-incidence.component';
import {
  DetailIncidenceChatComponent
} from './pages/detail-incidence/detail-incidence-chat/detail-incidence-chat.component';
import {
  DetailIncidenceFacilityElemsComponent
} from './pages/detail-incidence/detail-incidence-facility-elems/detail-incidence-facility-elems.component';
import {
  DetailIncidenceInfoComponent
} from './pages/detail-incidence/detail-incidence-info/detail-incidence-info.component';
import {
  DetailIncidenceRatingComponent
} from './pages/detail-incidence/detail-incidence-rating/detail-incidence-rating.component';
import {
  DetailIncidenceWorkordersComponent
} from './pages/detail-incidence/detail-incidence-workorders/detail-incidence-workorders.component';
import {IncidencesListComponent} from './pages/incidences-list/incidences-list.component';
import {PhotoGalleryModule} from '@twogate/ngx-photo-gallery';
import {
  DetailIncidenceDocumentsComponent
} from './pages/detail-incidence/detail-incidence-documents/detail-incidence-documents.component';
import {SharedModule} from '../shared/shared.module';
import {AutodeskForgeModule} from '../autodesk-forge/autodesk-forge.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    // Components
    IncidenceStepsIndicatorComponent,

    // Pages
    IncidencesListComponent,

    DetailIncidenceComponent,
    DetailIncidenceChatComponent,
    DetailIncidenceFacilityElemsComponent,
    DetailIncidenceInfoComponent,
    DetailIncidenceDocumentsComponent,
    DetailIncidenceRatingComponent,
    DetailIncidenceWorkordersComponent,

    AddIncidenceComponent,
    AddWorkOrderComponent
  ],
  imports: [
    SharedModule,
    IncidencesRoutingModule,
    WorkordersModule,

    PhotoGalleryModule,
    AutodeskForgeModule,
    TranslateModule.forChild(),
  ]
})
export class IncidencesModule {
}
