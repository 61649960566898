import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {AuthService} from './auth.service';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {ToastService} from '../shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private isRefreshing = false;
  private refreshedToken: Subject<string> = new Subject<string>();

  constructor(private auth: AuthService, private toastService: ToastService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const request = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`
      }
    });

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          if (err.url.includes('refresh-token')) {
            this.isRefreshing = false;
            this.auth.logout();
            return throwError(err);
          }
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshedToken.next(null);
            const refreshToken = this.auth.getRefreshToken();
            if (refreshToken) {
              return this.auth.refreshToken(refreshToken)
                .pipe(
                  switchMap(response => {
                    this.isRefreshing = false;
                    this.refreshedToken.next(response.token);

                    const refreshedReq = request.clone({
                      setHeaders: {
                        Authorization: `Bearer ${response.token}`
                      }
                    });
                    return next.handle(refreshedReq);
                  }),
                  catchError(errOnRefresh => {
                    this.isRefreshing = false;
                    this.auth.logout();
                    return throwError(errOnRefresh);
                  })
                );
            } else {
              this.isRefreshing = false;
            }
          } else {
            return this.refreshedToken.pipe(
              filter(token => token !== null),
              take(1),
              switchMap(token => {
                const refreshedReq = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token}`
                  }
                });
                return next.handle(refreshedReq);
              })
            );
          }
        }

        if (err.error && err.error.message) {
          this.toastService.showToast({
            type: 'error',
            message: err.error.message
          });
        }
        
        return throwError(err);
      })
    );

  }
}
