
import { 
    ReportCountByMaintenanceCategoryDTO, 
    ReportCountByMaintenanceCategoryReg, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessCountByMaintenanceCategoryCreated extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }
    
    getData(){
        const {labels: categoryNameMANT, dataA : countMANT, 
            dataA2 : countMANT2, } = this.getCountByMaintenanceCategoryDataSets(this.reportGrossData as ReportCountByMaintenanceCategoryDTO);
          const dataSetsCountMaintenanceCategory = [{data: countMANT, label: this.translate.instant("REPORTS.TASKS_LABEL_1")}]
          if (countMANT2.length > 0) {
            dataSetsCountMaintenanceCategory.push({data: countMANT, label: this.translate.instant("REPORTS.TASKS_LABEL_2")})
          }
          this.reports.countByMaintenanceCategoryCreated = {
            labels: categoryNameMANT,
            dataSets: dataSetsCountMaintenanceCategory
          };
          this.reports.showIncidenceStatusFilter = false;
 
    }
    
    private getCountByMaintenanceCategoryDataSets(CountByMaintenanceCategory: ReportCountByMaintenanceCategoryDTO) {
    
        let labels = [];
        const dataA = [];
        const dataB = [];
        const dataA2 = [];
        const dataB2 = [];
        CountByMaintenanceCategory.value.forEach((reg: ReportCountByMaintenanceCategoryReg) => {
          const categoryName = reg.categoryName;
          labels.push(categoryName);
          dataA.push(reg.count);
        });
        if (CountByMaintenanceCategory.value2) {
          CountByMaintenanceCategory.value2.forEach((reg: ReportCountByMaintenanceCategoryReg) => {
            const categoryName = reg.categoryName;
            labels.push(categoryName);
            dataA2.push(reg.count);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataB, dataA2, dataB2};
      }
}