import { NgModule } from "@angular/core";
import { ProblemTypesTabComponent } from "./components/problem-types-tab/problem-types-tab.component";
import { NotificationsConfigurationTabComponent } from "./components/notifications-configuration-tab/notifications-configuration-tab.component";
import { SharedModule } from "../shared/shared.module";
import { MinutesToHoursPipe } from "./pipes/minutes-to-hours.pipe";
import { ConfigProblemTypePageComponent } from "./pages/config-problemType-page/config-problemType-page.component";
import { ConfigNotificationPageComponent } from "./pages/config-notification-page/config-notification-page.component";
import { ProblemTypesPaginatedTableComponent } from "./components/problem-types-paginated-table/problem-types-paginated-table.component";
import { ConfigurationMenuComponent } from "./components/configuration-menu/configuration-menu.component";
import { ConfigCriticalityPageComponent } from "./pages/config-criticality-page/config-criticality-page.component";
import { ConfigFacilityPageComponent } from "./pages/config-facility-page/config-facility-page.component";
import { FacilitiesConfigurationTabComponent } from "./components/facilities-configuration-tab/facilities-tab.component";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { TranslateModule } from "@ngx-translate/core";
import { ConfigGeneralPageComponent } from "./pages/config-general-page/config-general-page.component";
import { GeneralConfigurationTabComponent } from "./components/general-configuration-tab/general-tab.component";

@NgModule({
  declarations: [
    ProblemTypesPaginatedTableComponent,
    ProblemTypesTabComponent,
    NotificationsConfigurationTabComponent,
    ConfigNotificationPageComponent,
    MinutesToHoursPipe,
    ConfigurationMenuComponent,
    ConfigProblemTypePageComponent,
    ConfigCriticalityPageComponent,
    FacilitiesConfigurationTabComponent,
    ConfigFacilityPageComponent,
    ConfigGeneralPageComponent,
    GeneralConfigurationTabComponent,
  ],
  imports: [
    SharedModule,
    GooglePlaceModule,
    TranslateModule.forChild(),
  ],
})
export class ConfigurationModule {}
