import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LineChartComponent} from './components/line-chart/line-chart.component';
import {ColumnChartComponent} from './components/column-chart/column-chart.component';
import { ChartsModule as Ng2ChartsModule } from 'ng2-charts';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { StackChartComponent } from './components/stack-chart/stack-chart.component';



@NgModule({
  declarations: [
    LineChartComponent,
    ColumnChartComponent,
    PieChartComponent,
    StackChartComponent,
  ],
  imports: [
    CommonModule,
    Ng2ChartsModule
  ],
  exports: [
    LineChartComponent,
    ColumnChartComponent,
    PieChartComponent,
    StackChartComponent
  ]
})
export class ChartsModule { }
