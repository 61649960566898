import { DateTime } from "luxon";
import { ReportStatDTO, ReportCountOTsFinishedByCategoryDTO } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessCountOTsFinishedByCategory extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }

    getData(){
        const main = this.reports.dateRangesSrv.mainRange$.value;
        const compare = this.reports.dateRangesSrv.compareRange$.value;
        const fecha1Start = DateTime.fromJSDate(main.start).toFormat('yyyy-MM-dd');
        const fecha1End = DateTime.fromJSDate(main.end).toFormat('yyyy-MM-dd');
        const labels = [this.translate.instant("REPORTS.DATE_RANGE_LABEL",{from: fecha1Start, to: fecha1End})];
        const dataSets = this.getCountOTsFinishedByCatDataSets(this.reportGrossData as ReportCountOTsFinishedByCategoryDTO);

        if (compare) {
        const fecha2Start = DateTime.fromJSDate(compare.start).toFormat('yyyy-MM-dd');
        const fecha2End = DateTime.fromJSDate(compare.end).toFormat('yyyy-MM-dd');

        if (this.reportGrossData.value2) {
            labels.push(this.translate.instant("REPORTS.DATE_RANGE_LABEL",{from: fecha2Start, to: fecha2End}))
        }
        }
        
        this.reports.showIncidenceStatusFilter = true;
    
        this.reports.countOTsFinishedByCategory =  {
        labels,
        dataSets
        };
        
    }
// ----------------------------------------------------------------------------
    private getCountOTsFinishedByCatDataSets(totalIncidencesStat: ReportCountOTsFinishedByCategoryDTO) {
        const dataSets: { data: number[]; label: string }[] = [];
        
        totalIncidencesStat.value.forEach((reg, index) => {
          let data = [parseInt(reg.count)];
          if (totalIncidencesStat.value2) {
            const equalElem = totalIncidencesStat.value2.find(a => a.categoryId === reg.categoryId);
            data.push(equalElem ? parseInt(equalElem.count) : 0)
          }
          dataSets.push({data, label: reg.categoryName });
        });
        if (totalIncidencesStat.value2){
          totalIncidencesStat.value2.forEach((reg2, index) => {
            const equalElem = totalIncidencesStat.value.find(a => a.categoryId === reg2.categoryId);
            if (!equalElem) {
              const data = [0, parseInt(reg2.count)];
              dataSets.push({data, label: reg2.categoryName });
            }
          });
        }
    
        return dataSets;
      }
}