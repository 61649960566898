import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UserInterface} from '../../../users/models/user.interface';
import {ROLES} from '../../../shared/models/role.interface';
import {IUserLogged} from '../../../shared/models/i-user-logged';
import {UserGroupInterface} from '../../models/user-group.interface';

@Component({
  selector: 'app-user-group-form',
  templateUrl: './user-group-form.component.html',
  styleUrls: ['./user-group-form.component.scss']
})
export class UserGroupFormComponent implements OnInit, OnChanges {

  @HostBinding('class') hostClasses = 'ui stackable grid';

  groupName: string;

  @Input() set loggedUser(user: IUserLogged) {
    if (user) {
      this.selectedIds.push(user.id);
    }
  }

  @Input() set group(group: UserGroupInterface) {
    if (group) {
      this.groupName = group.name;

      this.selectedIds = [];
      group.users.forEach( user => {
        this.selectedIds.push(user.id);
      });
    }
  }
  @Input() users: UserInterface[];

  adminUsers: UserInterface[];
  clientUsers: UserInterface[];

  selectedIds: number[];

  @Input() submitButtonText: string;
  @Output() groupSubmitted = new EventEmitter<{name: string, users: number[]}>();

  constructor() {
    this.selectedIds = [];
    this.adminUsers = [];
    this.clientUsers = [];
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.users && changes.users.currentValue) {
      this.divideUsers();
    }
  }

  private divideUsers() {
    this.adminUsers = [];
    this.clientUsers = [];

    if (this.users) {
      this.users.forEach(user => {
        const userRole = user.userFacilityRoles[0]?.roleId;
        if (userRole || userRole === 0) {
          userRole === ROLES.CLIENT_ADMIN ? this.adminUsers.push(user) : this.clientUsers.push(user);
        }
      });
    }
  }

  selectUser(id) {
    if (!this.selectedIds.includes(id)) {
      this.selectedIds.push(id);
    }
  }

  toggleSelectUser(event, id) {
    if (event.target.checked) {
      this.selectUser(id);
    } else {
      const index = this.selectedIds.findIndex(selectedId => selectedId === id);
      this.selectedIds.splice(index, 1);
    }
  }

  private oneAdminSelected() {
    return !!this.adminUsers.find(admin => this.selectedIds.includes(admin.id));
  }

  validGroup() {
    if (!this.groupName || this.groupName.trim() === '') {
      return false;
    }
    if (!this.oneAdminSelected()) {
      return false;
    }

    return true;
  }

  submitGroup() {
    this.groupSubmitted.emit({
      name: this.groupName,
      users: this.selectedIds
    });
  }
}
