import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {AutodeskToken} from './models/autodesk-token';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AutodeskViewerService {

  public shouldResize$ = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  getToken(): Observable<AutodeskToken> {
    return this.http.get<AutodeskToken>(`${environment.apiHost}/autodesk/token`);
  }
}
