<div class="reset-password-container">
  <img class="logo" src="assets/images/logo.png" alt="IMBICLOUD">

  <form class="ui form" (ngSubmit)="resetPasswordSubmit()">
    <h3>{{ 'RESET_PASSWORD_TITLE' | translate }}</h3>
    <div 
      [class.error]="resetPasswordForm.controls.newPassword.errors?.passwordStrengthLow && resetPasswordForm.controls.newPassword.touched" 
      class="field password-input-container">
      <input 
        [formControl]="resetPasswordForm.controls['newPassword']" 
        [type]="passwordVisible ? 'text' : 'password'"
        name="newPassword" 
        placeholder="{{ 'NEW_PASSWORD' | translate }}">
      <i class="{{ iconClass }} icon" (click)="togglePasswordVisibility()"></i>
    </div>

    <button 
      class="ui button primary" 
      [class.loading]="loading" 
      [class.disabled]="!resetPasswordForm.valid" 
      type="submit">{{ 'SAVE_PASSWORD_BUTTON' | translate }}
    </button>
    
    <a *ngIf="errorMessage" routerLink="/forgot-password">{{ 'RESET_PASSWORD_LINK' | translate }}</a>
  </form>

  <div *ngIf="successMessage" class="ui success-message">
    <div class="header">{{ 'RESET_PWD_SUCCESS_MESSAGE' | translate }}</div>
    <p>{{ successMessage }}</p>
  </div>

  <div *ngIf="errorMessage" class="ui error-message">
    <div class="header">{{ 'RESET_PWD_ERROR_MESSAGE' | translate }}</div>
    <p>{{ errorMessage }}</p>
  </div>
</div>