import {Component, forwardRef, HostBinding, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {delay, filter} from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'fui-dropdown-two',
  templateUrl: './dropdown-two.component.html',
  styleUrls: ['./dropdown-two.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownTwoComponent),
      multi: true
    }
  ]
})
export class DropdownTwoComponent implements OnInit, ControlValueAccessor {

  @Input() selectorId: string;

  @HostBinding('id')
  get getSelectorId() {
    return this.selectorId;
  }

  @HostBinding('class.readonly') get getReadOnly() { return this.readonly; }

  @HostBinding('attr.class')
  get hostClasses() {
    const classes = ['ui'];
    if (this.fluid) {
      classes.push('fluid');
    }
    if (this.size) {
      classes.push(this.size);
    }
    if (this.search) {
      classes.push('search');
    }
    if (this.multiple) {
      classes.push('multiple');
    }
    classes.push('selection');
    classes.push('dropdown');
    if (this.disabled) {
      classes.push('disabled');
    }
    return classes.join(' ');
  }

  @HostBinding('class.disabled') get isDisabled() {
    return this.disabled;
  }

  @Input() placeholder: string;
  @Input() showDropdownIcon = true;

  @Input() nullOption: object;
  @Input() multiple: boolean;
  @Input() search: boolean;
  @Input() fluid = false;
  @Input() disabled: boolean;
  @Input() readonly: boolean;
  @Input() size: string;

  @Input() allowAdditions = false;
  @Input() clearable = false;

  @Input() valueProperty: string;
  @Input() nameProperty: string;

  @Input('options') set setOptions(options: any[]) {
    if (options) {
      this.options = this.nullOption ? [this.nullOption, ...options] : options;
    }
    this.options$.next(this.options);
  }
  options$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  options: any[];

  selectedOptions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  initializedDropdown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private selectedByUser = true;

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor() {}

  ngOnInit(): void {
    this.options$.pipe(filter(options => !!options)).subscribe(options => {
      this.initializeDropdown();
      this.initializedDropdown$.next(true);
    });

    combineLatest([
      this.options$.pipe(filter(options => !!options)),
      this.selectedOptions$,
      this.initializedDropdown$.pipe(filter(init => init === true), delay(200))
    ]).subscribe(([options, selectedOptions, initialized]) => {
      this.selectOptions(selectedOptions);
    });
  }


  writeValue(values: any) {
    this.selectedOptions$.next(values);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  // ------ Native dropdown methods --------

  private initializeDropdown() {
    const idSelector = '#' + this.selectorId;
    ($(idSelector) as any).dropdown({
      allowAdditions: this.allowAdditions,
      useLabels: this.allowAdditions,
      clearable: this.clearable
    });
  }

  private setSelectedInDropdown(value: number | string) {
    this.selectedByUser = false;
    ($('#' + this.selectorId) as any).dropdown('set selected', value);
  }

  clearDropdown() {
    ($('#' + this.selectorId) as any).dropdown('clear');
  }

  // ----------------------------------------

  private areEqualOptions(opt1: object, opt2: object) {
    return opt1[this.valueProperty || 'value'] === opt2[this.valueProperty || 'value'];
      //opt1[this.nameProperty || 'name'] === opt2[this.nameProperty || 'name'];
  }

  private getOptionByValue(value: number | string) {
    if (!this.options) {
      return null;
    }
    const valueInString = typeof value === 'string' ? value : value.toString();

    return this.options.find(option => {
      let optionValue = option[this.valueProperty || 'value'];
      optionValue = typeof optionValue === 'string' ? optionValue : optionValue.toString();
      return optionValue === valueInString;
    });
  }

  private isInOptions(option: object): boolean {
    if (!this.options) {
      return false;
    }
    const found = this.options.find(current => this.areEqualOptions(current, option));
    return !!found;
  }

  private selectOption(option: object | string) {
    if (option) {
      if (typeof option === "object" && this.isInOptions(option)) {
        this.setSelectedInDropdown(option[this.valueProperty || 'value']);
      }
      if (typeof option === "string" && this.allowAdditions) {
        this.setSelectedInDropdown(option);
      }
    } else {
      this.clearDropdown();
    }
  }

  private selectOptions(options: string | object | Array<string | object>) {
    if (Array.isArray(options)) {
      options.forEach(option => this.selectOption(option));
    } else {
      this.selectOption(options);
    }
  }

  hiddenInputValueChanged(value) {
    if (value) {
      let selected;

      if (this.multiple) {
        // Multiple Selector
        selected = [];
        const optionsValues = value.split(',');
        optionsValues.forEach(optionValue => {
          let optionObj = this.getOptionByValue(optionValue);
          if (optionObj) {
            selected.push(optionObj);
          }
          else {
            if (this.allowAdditions) {
              selected.push(optionValue);
            }
          }
        });
      } else {
        // Single Selector
        let optionObj = this.getOptionByValue(value);
        if (optionObj) {
          selected = optionObj;
        }
        else if (this.allowAdditions) {
          selected = value;
        }
      }
      this.onChange(selected);
    } else {
      this.onChange(null);
    }
    if (this.selectedByUser) {
      this.onTouch();
    } else {
      this.selectedByUser = true;
    }
  }
}
