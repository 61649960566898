import { Injectable } from '@angular/core';
import { UrlParamsInterface } from '../models/utils.interface';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  parseParams(params: UrlParamsInterface): string[] {
    const parsed = [];
    Object.keys(params).forEach(key => {
      if (Array.isArray(params[key])) {
        const values = params[key] as number[];
        values.forEach(value => {
          parsed.push(`${key}=${value}`);
        });
      } else {
        parsed.push(`${key}=${params[key]}`);
      }
    });
    return parsed;
  }

  filterNonNullValues(params: { [key: string]: any }): { [key: string]: any } {
    const filteredParams: { [key: string]: any } = {};
    for (const key in params) {
      if (params[key] !== null) {
        filteredParams[key] = params[key];
      }
    }
    return filteredParams;
  }
  
}

