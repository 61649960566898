import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReportEvolutionIncidenceByCategoryDTO, ReportEvolutionIncidencesByCategoryReg } from '../../models/reports.inteface';
import { DateTime, Duration } from 'luxon';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import DataTable = google.visualization.DataTable;
import DataView = google.visualization.DataView;
import LineChartOptions = google.visualization.LineChartOptions;
import DataTableColumnDescription = google.visualization.DataTableColumnDescription;

interface Category { id: number; name: string; }
interface ColumnRef { id: number; name: string; compared: boolean; label?: string; role?: string; }

@Component({
  selector: 'app-evolution-incidences-by-category-report',
  templateUrl: './evolution-incidences-by-category-report.component.html'
})
export class EvolutionIncidencesByCategoryReportComponent implements OnInit, OnChanges {

  @Input() reportData: ReportEvolutionIncidenceByCategoryDTO;

  categories: Category[];
  selectedCategories: FormControl;

  columnsRef: ColumnRef[];

  dataTable: DataTable;
  dataView: DataView;
  translate: TranslateService;

  chartOptions: LineChartOptions = {
    lineWidth: 2,
    legend: {
      position: 'top',
      maxLines: 3
    },
    chartArea: {
      width: '90%'
    },
    interpolateNulls: true,
    series: {
      0: { color: '#329648' },
      1: { color: '#005ec9' },
      2: { color: '#c74f42' },
      3: { color: '#deac00' },
      4: { color: '#12b0ab' },
      5: { color: '#c74289' },
      6: { color: '#4c9efd' },
      7: { color: '#42c781' },
      8: { color: '#fc523f' },
      9: { color: '#7632fc' },
      10: { color: '#0e0091' },
      11: { color: '#91004e' },
      12: { color: '#de4d00' },
      13: { color: '#f08907' },
      14: { color: '#916a00' },
    }
  };

  constructor(translate: TranslateService) {
    this.translate = translate;
    this.selectedCategories = new FormControl(null);
  }

  ngOnInit() {
    this.selectedCategories.valueChanges.subscribe(categories => {
      this.showCategories(categories);
    });
  }

  private showCategories(categories) {
    const view = new google.visualization.DataView(this.dataTable);
    if (categories?.length) {
      const columns = [0, ...this.getColumnIndexes(categories)];
      view.setColumns(columns);
    }

    this.dataView = view;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reportData) {
      const data = this.reportData?.value;
      const compareData = this.reportData?.value2;

      if (!data) {
        return;
      }

      const dataCategories = this.uniqueCategoriesFromData(data);
      const compareCategories = this.uniqueCategoriesFromData(compareData);
      this.categories = this.mergeUniqueCategories(dataCategories, compareCategories);

      this.columnsRef = this.columnsRefFromCategories(dataCategories, compareCategories);

      const dataValues = [];
      data.forEach(reg => {
        const row = this.dataRowFromReportReg(reg);
        dataValues.push(row);
      });

      if (compareData) {
        const diff = EvolutionIncidencesByCategoryReportComponent.dataFirstRegDateDiff(data, compareData);
        compareData.forEach(reg => {
          const row = this.dataRowFromReportReg(reg, diff);
          dataValues.push(row);
        });
      }

      const columns = this.parseTableColumns(this.columnsRef);
      google.charts.load('current', { packages: ['corechart'], language: this.translate.currentLang });
      google.charts.setOnLoadCallback(() => {
        this.dataTable = google.visualization.arrayToDataTable([
          columns,
          ...dataValues
        ]);
        this.showCategories(this.selectedCategories.value);
      });
    }
  }

  private mergeUniqueCategories(categories1: Category[], categories2: Category[]) {
    const uniques = [];
    categories1.forEach(cat => {
      if (!uniques.find(uniqCat => uniqCat.id === cat.id)) {
        uniques.push(cat);
      }
    });
    categories2.forEach(cat => {
      if (!uniques.find(uniqCat => uniqCat.id === cat.id)) {
        uniques.push(cat);
      }
    });

    return uniques;
  }

  private dataRowFromReportReg(reg: ReportEvolutionIncidencesByCategoryReg, dateOffset: Duration = null) {
    const row = new Array(this.columnsRef.length);
    row.fill(null);
    const regDate = EvolutionIncidencesByCategoryReportComponent.getDateFromReg(reg);

    if (dateOffset) {
      row[0] = DateTime.fromJSDate(regDate).plus(dateOffset).toJSDate();

      const categoryColIndex = this.columnsRef.findIndex(column => column.id === reg.categoryId && column.compared === true);
      row[categoryColIndex] = parseInt(reg.value);

      const tooltipColIndex = categoryColIndex + 1;
      row[tooltipColIndex] = `${DateTime.fromJSDate(regDate).setLocale(this.translate.currentLang).toFormat('MMM d, yyyy')}
      ${reg.categoryName}: ${parseInt(reg.value)}`;

    } else {
      row[0] = regDate;
      const categoryColumnIndex = this.columnsRef.findIndex(column => column.id === reg.categoryId && column.compared === false);
      row[categoryColumnIndex] = parseInt(reg.value);
    }

    return row;
  }

  private uniqueCategoriesFromData(data: ReportEvolutionIncidencesByCategoryReg[]): Category[] {
    const categories: Category[] = [];
    if (data) {
      data.forEach(dataReg => {
        if (!categories.find(cat => cat.id === dataReg.categoryId)) {
          categories.push({ id: dataReg.categoryId, name: dataReg.categoryName });
        }
      });
    }
    return categories;
  }

  private columnsRefFromCategories(categories: Category[], comparedCategories: Category[]): ColumnRef[] {
    const columns = [];
    columns.push({ label: this.translate.instant('Fecha'), type: 'date' });

    categories.forEach(category => {
      columns.push(Object.assign({ compared: false }, category));
      const sameInCompareCategories = comparedCategories.find(otherCat => otherCat.id === category.id);
      if (sameInCompareCategories) {
        sameInCompareCategories.name = sameInCompareCategories.name + ' (2)';
        const comparedCat = Object.assign({ compared: true }, sameInCompareCategories);
        columns.push(comparedCat);
        columns.push({ id: category.id, role: 'tooltip', label: null });
      }
    });

    comparedCategories.forEach(compareCat => {
      if (!columns.find(columnCat => columnCat.id === compareCat.id)) {
        const newColumn = Object.assign({ compared: true }, compareCat);
        columns.push(newColumn);
        columns.push({ role: 'tooltip', label: null });
      }
    });

    return columns;
  }

  private getCategoryColumnIndex(category: Category): number[] {
    const indexes = [];
    this.columnsRef.forEach((column, index) => {
      if (column.id === category.id) {
        indexes.push(index);
      }
    });
    return indexes;
  }

  private getColumnIndexes(categories: Category[]): number[] {
    const indexes = [];
    categories.forEach(category => indexes.push(...this.getCategoryColumnIndex(category)));
    return indexes;
  }

  private parseTableColumns(columnsRef: ColumnRef[]): DataTableColumnDescription[] {
    const columns: DataTableColumnDescription[] = [];

    columnsRef.forEach(ref => {
      const column: DataTableColumnDescription = {};
      column.label = ref.label || ref.name || null;
      if (ref.role) {
        column.role = ref.role;
      }

      columns.push(column);
    });

    return columns;
  }

  private static dataFirstRegDateDiff(
    primaryData: ReportEvolutionIncidencesByCategoryReg[],
    compareData: ReportEvolutionIncidencesByCategoryReg[]): Duration {
    if (primaryData.length && compareData.length) {
      const primaryFirstDate = EvolutionIncidencesByCategoryReportComponent.getDateFromReg(primaryData[0]);
      const compareFirstDate = EvolutionIncidencesByCategoryReportComponent.getDateFromReg(compareData[0]);
      return DateTime.fromJSDate(primaryFirstDate).diff(DateTime.fromJSDate(compareFirstDate));
    }
    return null;
  }

  private static getDateFromReg(value: ReportEvolutionIncidencesByCategoryReg) {
    return value.day ? DateTime.fromFormat(value.day, 'yyyy-MM-dd').toJSDate() :
           value.week ? DateTime.fromFormat(value.week, 'yyyy-WW').toJSDate() :
           value.month ? DateTime.fromFormat(value.month, 'yyyy-MM').toJSDate() :
           value.year ? DateTime.fromFormat(value.year, 'yyyy').toJSDate() : null;
  }

  selectedCategoriesToShowChange(categories: any) {

  }
}
