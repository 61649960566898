import {Directive, EventEmitter, forwardRef, Host, HostBinding, HostListener, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {skipWhile, take} from 'rxjs/operators';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[fuiRating]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RatingDirective),
    }
  ]
})
export class RatingDirective implements OnInit, ControlValueAccessor {


  @HostBinding('class') get hostClass() {
    return 'ui rating ' + this.color;
  }

  @Input() id: string;
  @Input() icon: string;
  @Input() color: string;
  @Input() maxRating: number;

  initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Output() rateChange: EventEmitter<number> = new EventEmitter<number>();

  onChange = (_: any) => {};
  onTouch = () => {};

  constructor() { }

  ngOnInit() {
    const selector = `#${this.id}`;
    ($(selector) as any).rating({
      icon: this.icon,
      maxRating: this.maxRating,
      onRate: (value) => {
        this.onChange(value);
        this.onTouch();
      }
    });

    this.initialized.next(true);
  }

  writeValue(values: any) {
    this.initialized.pipe(
      skipWhile(val => !val),
      take(1)
    ).subscribe(() => {
      this.setRate(values);
    });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
  }

  setRate(value) {
    ($('#' + this.id) as any).rating('set rating', value);
  }
}
