import {Component, HostBinding, OnInit} from '@angular/core';
import {CalendarEvent, CalendarMonthViewDay} from 'angular-calendar';
import {combineLatest, Observable} from 'rxjs';
import {map, skipWhile, startWith, switchMap, take} from 'rxjs/operators';
import {ScheduledTaskService} from '../../scheduled-task.service';
import {ScheduledTasksPageFilterService} from '../services/scheduled-tasks-page-filter.service';
import {ScheduledTask} from '../../models/scheduled-task.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from '../../../shared/services/session.service';
import {DateTime} from 'luxon';
import { getTaskColor } from '../../shared/scheduled-tasks.utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-scheduled-tasks-calendar-page',
  templateUrl: './scheduled-tasks-calendar-page.component.html',
  styleUrls: ['./scheduled-tasks-calendar-page.component.scss']
})
export class ScheduledTasksCalendarPageComponent implements OnInit {

  @HostBinding('class') hostClass = 'ui grid';

  weekStartsOn : number = 1;
  monthYearText$: Observable<string>;
  calendarDate = new Date();
  events$: Observable<CalendarEvent[]>;
  activeDay = new Date();
  activeDayOpen = false;
  currentLang: string = 'en';
  currentLang$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private session: SessionService,
    private tasksService: ScheduledTaskService,
    private filterService: ScheduledTasksPageFilterService,
    private translate: TranslateService,
    ) { 
      this.currentLang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.currentLang$ = this.translate.onLangChange.pipe(
      map(event => event.lang),
      startWith(this.translate.currentLang)
    );
    
    this.events$ = combineLatest([
      this.session.activeFacility$.pipe(skipWhile(f => !f)),
      this.filterService.monthYear$,
      this.filterService.filter$,
      this.filterService.relatedToComponentId$
    ]).pipe(
      skipWhile(monthYear => !monthYear),
      switchMap(([facility, monthYear, filter, relatedComponentId]) => {
        const from = monthYear.startOf('month').toUTC().toISO({ includeOffset: false });
        const to = monthYear.endOf('month').toUTC().toISO({ includeOffset: false });
        this.filterService.setSelectedView('calendar');
        let params = { from, to, view: 'calendar' };
        params = Object.assign(params, filter);
        return this.tasksService.fetchScheduledTasks(params, relatedComponentId);
      }),
      map((scheduledTasks: ScheduledTask[]) => {
        
        return scheduledTasks.map((task: ScheduledTask) => {
          let color = getTaskColor(task);
          return {
            id: task.id,
            title: task.name,
            color,
            start: DateTime.fromISO(task.createdAt).toJSDate(),
          };
        });
      })
    );

    this.monthYearText$ = this.filterService.monthYear$.pipe(
      map(monthYear => {
        return monthYear.setLocale(this.currentLang).toFormat('MMMM yyyy');
      })
    );

    this.initialiseCalendarMonth();
  }

  initialiseCalendarMonth() {
    this.filterService.filter$.pipe(
   //   take(1)
    ).subscribe(filter => {
      const filterTo = filter?.to;
      if (filterTo) {
        const toDate = DateTime.fromISO(filterTo);
        this.filterService.setMonthYear(toDate.startOf('month'));
        this.calendarDate = toDate.toJSDate();
      } else {
        this.goToday();
      }
    });
  }

  dayClicked({ day, sourceEvent }: { day: CalendarMonthViewDay; sourceEvent: any }) {
    this.filterService.monthYear$.pipe(take(1)).subscribe(monthYear => {
      const clickedDate = DateTime.fromJSDate(day.date);

      // Check if the clicked date belongs to the current month and has events
      if (clickedDate.hasSame(monthYear, 'month') && day.events.length) {
        // Toggle the visibility of events for the clicked day
        this.activeDayOpen = this.activeDay === day.date ? !this.activeDayOpen : true;
        // Update the active day to the clicked day
        this.activeDay = day.date;
      } else {
        // If the clicked date is not in the current month, navigate to that month
        this.calendarDate = day.date;
        this.filterService.setMonthYear(DateTime.fromJSDate(day.date).startOf('month'));
      }
    });
  }
  

  nextMonth() {
    this.filterService.monthYear$.pipe(
      take(1) // Take the first emitted value and unsubscribe
    ).subscribe(monthYear => {
      const newMonth = monthYear.plus({ months: 1 }).startOf('month');
      this.calendarDate = newMonth.toJSDate();
      this.filterService.setMonthYear(newMonth);
    });
  }

  previousMonth() {
    this.filterService.monthYear$.pipe(
      take(1) // Take the first emitted value and unsubscribe
    ).subscribe(monthYear => {
      const newMonth = monthYear.minus({ months: 1 }).startOf('month');
      this.calendarDate = newMonth.toJSDate();
      this.filterService.setMonthYear(newMonth);
    });
  }

  goToday() {
    const newMonth = DateTime.local().startOf('month');
    this.calendarDate = newMonth.toJSDate();
    this.filterService.setMonthYear(newMonth);
  }

  eventClicked(event: { event: CalendarEvent<any>; sourceEvent: any }) {
    this.router.navigate(['tareas-programadas', event.event.id], {state: {originPage: 'calendar'}});
  }

}
