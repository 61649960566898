import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { filter, map, takeUntil, } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Facility } from "../../../facilities/models/facility.interface";
import { SessionService } from "../../../shared/services/session.service";
import { ToastService } from "../../../shared/services/toast.service";
import {
  getNotificationIndexes,
  getNotificationsByRole,
  NotificationsActions,
  NOTIFICATION_TYPES,
  isGlobalNotification,
} from "./notifications-configuration-tab.config";
import { UserService } from "src/app/users/services/user.service";
import { Configuration } from "src/app/facilities/models/config.interface";
import { EnabledNotifications, NotificationSectionsInterface } from "../../models/notification.interface";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-notifications-configuration-tab",
  styleUrls: ["./notifications-configuration-tab.component.scss"],
  templateUrl: "./notifications-configuration-tab.component.html",
})
export class NotificationsConfigurationTabComponent
  implements OnInit
{
  activeFacility: Facility;
  activeConfiguration: Configuration;

  config$: Observable<any>;
  notificationConfigs: any[];
  notificationSections: NotificationSectionsInterface[];
  notificationSectionsReference: NotificationSectionsInterface[] = [];
  enabledNotifications: EnabledNotifications;

  @ViewChild("fileInput") fileInput: ElementRef;
  loadingFile = false;
  showErrorsModal = false;
  uploadErrors: { row: number; message: string }[];
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private session: SessionService,
    private toastService: ToastService,
    private usersSrv: UserService,
    private translate : TranslateService,
  ) {
  }

  getNotificationsByType(type: string) {
    return this.notificationConfigs.filter(
      (notification) => notification.type === type
    );
  }

  onNotificationChange(value: boolean, key: string): void {
    this.rebuildNotificationsData(value, key)
    this.updateUserNotifications();
    this.calculateEnabledNotifications();
  }

  rebuildNotificationsData(value: boolean, key: string): void {
    const { global, activeFacility } = getNotificationIndexes(this.activeFacility);
    const selectedIndex = isGlobalNotification(key) ? global : activeFacility;

    if (!this.activeConfiguration.notifications[selectedIndex]) {
      this.activeConfiguration.notifications[selectedIndex] = [];
    }

    if (value) {
      if (!this.activeConfiguration.notifications[selectedIndex].includes(key)){
        this.activeConfiguration.notifications[selectedIndex].push(key);
      }
    } else {
      this.activeConfiguration.notifications[selectedIndex] =
        this.activeConfiguration.notifications[selectedIndex].filter(
          (notificationKey) => notificationKey !== key
        );
    }
  }

  updateUserNotifications() {
    this.usersSrv.updateUserNotifications(this.activeConfiguration).subscribe(
      (response) => {
        this.toastService.showToast({
          type: "success",
          message: this.translate.instant("NOTIFICATIONS.UPDATE_SUCCESSFUL"),
        });
      },
      (error) => {
        this.toastService.showToast({
          type: "error",
          message: this.translate.instant("NOTIFICATIONS.UPDATE_ERROR"),
        });
      }
    );
  }

  ngOnInit() {
    this.translateSectionTitles();

    this.session.activeFacility$
      .pipe(filter((facility) => !!facility))
      .subscribe((facility) => {
        this.activeFacility = facility;
 
        this.setNotificationsSections();
        this.setNotificationsData();
      });
  }
  private translateSectionTitles() {
    this.translate.get('NOTIFICATIONS').subscribe(translations => {
      this.notificationSectionsReference = [
        { title: translations.INCIDENCES, type: NOTIFICATION_TYPES.INCIDENCES },
        { title: translations.WORK_ORDERS, type: NOTIFICATION_TYPES.WORK_ORDERS },
        { title: translations.SCHEDULED_TASKS, type: NOTIFICATION_TYPES.SCHEDULED_TASKS },
        { title: translations.COMPONENTS, type: NOTIFICATION_TYPES.COMPONENTS },
        { title: translations.MESSAGES, type: NOTIFICATION_TYPES.MESSAGES },
        { title: translations.GLOBAL, type: NOTIFICATION_TYPES.GLOBAL },
      ];
    });
  }

  setNotificationsData() {
    this.usersSrv
          .fetchUserNotifications()
          .pipe(
            map((config) => {
              this.activeConfiguration = config;
              return config.notifications;
            })
          )
          .subscribe(() => {
            this.calculateEnabledNotifications();
          });
  }

  setNotificationsSections() {
    this.session.userRole$.pipe(
      filter(role => role !== undefined && role !== null),
      takeUntil(this.destroy$)
    ).subscribe(role => {
      this.notificationConfigs = getNotificationsByRole(role, this.translate);
      this.notificationSections = this.getNotificationSections();
    });
  }

  getNotificationSections(){
    return this.notificationSectionsReference.filter(
      (section) => this.getNotificationsByType(section.type).length !== 0
    );
  }

  private calculateEnabledNotifications() {
    const notifications = this.activeConfiguration.notifications;
    const enabledNotifications = {};

    const notificationIndexes = getNotificationIndexes(this.activeFacility);

    // Iterate through enum keys
    Object.keys(NotificationsActions).forEach((key) => {
      const notificationKey = NotificationsActions[key];
      const isKeyIncludedInGlobal =
        notifications[notificationIndexes.global].includes(notificationKey);

      let isKeyIncludedInActiveFacility = false;
      if (this.activeFacility?.id) {
        isKeyIncludedInActiveFacility =
          notifications[notificationIndexes.activeFacility]?.includes(
            notificationKey
          ) || false;
      }

      enabledNotifications[notificationKey] =
        isKeyIncludedInGlobal || isKeyIncludedInActiveFacility;
    });

    this.enabledNotifications = enabledNotifications;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
