import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ChatMessageInterface} from '../../models/chat-message.interface';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnChanges {

  @ViewChild('chatForm') form;
  @ViewChild('chatMessagesWindow') chatMessagesWindow;

  @Input() messages: ChatMessageInterface[];
  @Input() disabled = false;
  currentLang: string;
  @Output() newMessage: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;

    // Subscribe to language changes
    this.translate.onLangChange.subscribe(event => {
      this.currentLang = event.lang;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messages) {
      this._scrollChatWindowToBottom();
    }
  }

  private _scrollChatWindowToBottom() {
    setTimeout(() => {
      this.chatMessagesWindow.nativeElement.scrollTop = this.chatMessagesWindow.nativeElement.scrollHeight + 200;
    }, 100);
  }

  sendMessage(value: { text: string }) {
    this.newMessage.emit(value.text);
    this.form.reset();
  }

  getPhotoSource(message: ChatMessageInterface): string {
    
    if (message) {
      const creatorPhoto = message.creator?.photo;
  
      if (message.type === 'SYSTEM') {
        return 'assets/images/logo-blue-icon.png';
      } else if (creatorPhoto) {
        return creatorPhoto;
      } else {
        return 'assets/images/icon-user-profile.png';
      }
    }
  
    // Default fallback
    return 'assets/images/icon-user-profile.png';
  }

  formatDate(date: string): string {
    return DateTime.fromISO(date).setLocale(this.currentLang).toLocaleString(DateTime.DATE_FULL);
  }

  formatHour(date: string): string {
    return DateTime.fromISO(date).setLocale(this.currentLang).toLocaleString(DateTime.TIME_24_SIMPLE);
  }

  processMessage(action: string, text: string): string {
    if (action) {
      return this.translate.instant(`MESSAGES.${action}`);
    }
    return this.replaceNewlinesWithBr(text);
  }

  replaceNewlinesWithBr(text: string | undefined): string {
    if (text === undefined || text === null) {
      return '';
    }
    return text.replace(/\n/g, '<br>');
  }

  sanitizeMessage(message: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

}
