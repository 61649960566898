import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { UserInterface } from '../../models/user.interface';
import { SessionService } from '../../../shared/services/session.service';
import { CompanyInterface } from '../../../companies/models/company.interface';
import { CompaniesService } from '../../../companies/companies.service';
import { getRolesOptions, RoleInterface, ROLES, ROLES_OPTIONS } from '../../../shared/models/role.interface';
import { Facility } from '../../../facilities/models/facility.interface';
import { combineLatest, Subject, Observable } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { filter, take, takeUntil, switchMap, map, skipWhile } from 'rxjs/operators';
import { IUserLogged } from '../../../shared/models/i-user-logged';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-detail-page',
  templateUrl: './user-detail-page.component.html',
  styleUrls: ['./user-detail-page.component.scss']
})
export class UserDetailPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClasses = 'ui container grid';

  activeFacility: Facility;
  isClientUser: boolean;
  loggedUser: IUserLogged;

  user: UserInterface;
  user$: Observable<UserInterface>;
  companies: CompanyInterface[];
  companies$: Observable<CompanyInterface[]>;
  roleOptions: RoleInterface[];
  disableEdit$: Observable<boolean>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usersSrv: UserService,
    private companiesSrv: CompaniesService,
    private session: SessionService,
    private toastService: ToastService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.exitOnChangeFacilitySubscription();

    this.session.activeFacility$.pipe(filter(facility => !!facility), take(1))
      .subscribe(facility => this.activeFacility = facility);

    this.session.userRole$.pipe(
      skipWhile(role => role === undefined || role === null),
      takeUntil(this.destroy$)
    ).subscribe(role => this.isClientUser = role === ROLES.CLIENT_USER);

    this.user$ = combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility)),
      this.route.params
    ]).pipe(
      switchMap(([facility, params]) => {
        return this.usersSrv.fetchUser(parseInt(params.userId), facility.id);
      })
    );

    this.disableEdit$ = combineLatest([
      this.user$,
      this.session.userRole$,
      this.session.loggedUser$
    ]).pipe(
      takeUntil(this.destroy$),
      map(([user, loggedUserRole, loggedUser]) => {
        this.loggedUser = loggedUser;
        return loggedUserRole === ROLES.CLIENT_USER && user.id !== loggedUser.id;
      })
    );

    this.companies$ = combineLatest([
      this.session.activeFacility$.pipe(filter(facility => !!facility), take(1)),
      this.companiesSrv.fetchAllCompanies({ facilityId: this.activeFacility?.id, companyTypeId: [1, 2] }),
      this.session.userRole$.pipe(filter(role => role !== null && role !== undefined)),
      this.session.loggedUser$.pipe(filter(user => !!user))
    ]).pipe(
      map(([activeFacility, companies, role, loggedUser]) => {
        this.activeFacility = activeFacility;
        this.loggedUser = loggedUser;
        const allCompanies = companies as CompanyInterface[];
        if ([ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(role)) {
          return allCompanies;
        } else {
          return allCompanies.filter(company => company.id === loggedUser.companyId);
        }
      })
    );
    
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private exitOnChangeFacilitySubscription() {
    this.session.activeFacility$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(facility => {
      if (this.activeFacility && this.activeFacility.id !== facility.id) {
        this.router.navigateByUrl('usuarios');
      }
    });
  }

  onSelectCompany(companySelected: CompanyInterface) {
    const allRoles = ROLES_OPTIONS;

    this.session.userRole$
      .pipe(
        skipWhile(role => role === undefined || role === null),
        take(1)
      )
      .subscribe( role => {
        let roles = [];
        if ([ROLES.OWNER, ROLES.SUPER_ADMIN].includes(role)) {
          roles = allRoles;
        }
        else if (role === ROLES.CLIENT_ADMIN) {
          roles = allRoles.filter(role1 => [ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER].includes(role1.id));
        }
        else if (role === ROLES.CLIENT_USER) {
          roles = allRoles.filter(role2 => [ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER].includes(role2.id));
        }
        else if ([ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(role)) {
          roles = allRoles.filter(role3 => [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(role3.id));
        }
        else if ([ROLES.GLOBAL_SERVICE].includes(role)) {
          
          const isUserCompany = companySelected.id === this.loggedUser?.companyId;
          const isClientType = companySelected.types?.some(type => type.companyTypeId === 1);
          const isMaintenanceType = companySelected.types?.some(type => type.companyTypeId === 2);

          
          if (isUserCompany) {
            if (isClientType) {
              roles = [...roles, ...allRoles.filter(role4 => [ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER].includes(role4.id))];
            }
            else if (isMaintenanceType) {
              roles = [...roles, ...allRoles.filter(role5 => [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(role5.id))];
            }
          } else {
            if (isClientType) {
              roles = [...roles, ...allRoles.filter(role6 => [ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER].includes(role6.id))];
            }
            else if (isMaintenanceType) {
              roles = [...roles, ...allRoles.filter(role7 => [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(role7.id))];
            }
          }
        }

        this.roleOptions = getRolesOptions(this.translate, roles);

      });
  }

  saveChanges(value) {
    this.usersSrv.updateUser(this.activeFacility.id, value.id, value).subscribe(result => {
      this.user = result;

      this.toastService.showToast({
        type: 'success',
        message: this.translate.instant('USERS.UPDATED'),
      });
    });
  }
}
