import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SpinnerVeilService {

  private isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isOpen$: Observable<boolean> = this.isOpen.asObservable();

  constructor() { }

  public openVeil() {
    this.isOpen.next(true);
  }
  public closeVeil() {
    this.isOpen.next(false);
  }
}
