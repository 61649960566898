import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {Chat} from '../models/chat.interface';
import {HttpClient} from '@angular/common/http';
import {SessionService} from '../../shared/services/session.service';
import {pluck, skipWhile, switchMap, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  private readonly apiHost = environment.apiHost;

  constructor(
    private http: HttpClient,
    private session: SessionService
  ) {
  }

  fetchChats(facilityId: number): Observable<Chat[]> {
    return this.http.get<Chat[]>(`${this.apiHost}/facilities/${facilityId}/chats`);
  }

  fetchChatsPromise(facilityId: number): Promise<Chat[]> {
    return new Promise(resolve => {
      this.fetchChats(facilityId).subscribe(chats => {
        resolve(chats);
      });
    });
  }

  fetchUnreadMessagesCount(): Observable<number> {
    return this.session.activeFacility$.pipe(
      skipWhile(f => !f),
      take(1),
      switchMap(facility => {
        return this.http.get<object>(`${this.apiHost}/chat-messages/unread?facilityId=${facility.id}`);
      }),
      pluck('count')
    );
  }
}
