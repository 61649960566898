<div class="sixteen wide column">
  <div class="table-container">
    <div class="table-foot">
      <tfoot *ngIf="!(detailIncidence.incidence$ | async)?.wasFinished()">
        <tr>
          <th colspan="11">
            <div
              class="ui right floated primary icon button"
              (click)="goToCreateWorkOrder()"
            >{{ 'CREATE_WORK_ORDER' | translate }}
            </div>
          </th>
        </tr>
      </tfoot>
    </div>
    <table class="ui small selectable table">
      <thead>
        <tr>
          <th></th>
          <th>{{ 'ID' | translate }}</th>
          <th>{{ 'PROBLEM' | translate }}</th>
          <th>{{ 'STATUS' | translate }}</th>
          <th>{{ 'CRITICALITY' | translate }}</th>
          <th>{{ 'PARENT_ORDER' | translate }}</th>
          <th>{{ 'CATEGORY' | translate }}</th>
          <th>{{ 'ASSIGNED_COMPANY' | translate }}</th>
          <th>{{ 'IN_PROGRESS' | translate }}</th>
          <th>{{ 'FINISHED' | translate }}</th>
          <th>{{ 'CLOSED' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let order of detailIncidence.workOrders$ | async"
          (click)="goToWorkOrderDetail(order)"
        >
          <td>
            <div *ngIf="order.isWaiting()" class="ui mini button" (click)="emitOrder(order.id, $event)">{{ 'EMIT' | translate }}</div>
          </td>
          <td>{{order.id}}</td>
          <td>{{order.problemType.text}}</td>
          <td>{{statusTextFromId(order.workOrderStateId) | translate}}</td>
          <td>{{order.criticalityLevel.name}}</td>
          <td>{{order.parentWorkOrder?.id || '---'}}</td>
          <td>{{order.category.name}}</td>
          <td>{{order.assignedCompany.name}}</td>
          <td>{{formatDate(order.startedAt)}}</td>
          <td>{{formatDate(order.finishedAt)}}</td>
          <td>{{formatDate(order.closedAt)}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
