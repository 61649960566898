import {NgModule} from '@angular/core';
import {ViewerPageComponent} from './pages/viewer/viewer-page.component';
import {SharedModule} from '../shared/shared.module';
import {AutodeskForgeModule} from '../autodesk-forge/autodesk-forge.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    ViewerPageComponent,
  ],
  imports: [
    SharedModule,
    AutodeskForgeModule,
    TranslateModule.forChild()
  ]
})
export class ViewerModule { }
