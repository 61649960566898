<form class="ui form">
  <div class="fields">
    <div class="four wide field">
      <label>{{ 'ADD_MATERIAL.CATEGORY' | translate }}</label>
      <fui-dropdown
        [id]="'selMatCategory'"
        valueProperty="id"
        [options]="workOrderCategory"
        [selected]="selectedCategory"
        [defaultSelected]="selectedCategory"
        (selectedChange)="onSelectCategory($event)"
        [disabled]="true"
      ></fui-dropdown>
    </div>
    <div class="four wide field required">
      <label>{{ 'ADD_MATERIAL.SUBCATEGORY' | translate }}</label>
      <fui-dropdown
        [id]="'selMatSubCategory'"
        [disabled]="!selectedCategory"
        valueProperty="id"
        [options]="childCategories"
        [selected]="selectedSubcategory"
        (selectedChange)="onSelectSubcategory($event)"
      ></fui-dropdown>
    </div>
    <div class="eight wide field required">
      <app-label-and-info
        [labelText]="'ADD_MATERIAL.DESCRIPTION_LABEL' | translate"
        [helpingText]="'ADD_MATERIAL.DESCRIPTION_HELP' | translate"
      ></app-label-and-info>
      <app-custom-select
        #customSelectComponent
        [options]="materialOptions"
        [disabled]="!selectedSubcategory"
        placeholder="{{ 'ADD_MATERIAL.SEARCH_OR_ADD' | translate }}"
        (optionSelected)="onSelectMaterial($event)"
        (inputReset)="onInputReset()"
      ></app-custom-select>
    </div>
  </div>
  <div class="fields">
    <div class="twelve wide field required">
      <label>{{ 'ADD_MATERIAL.COMPONENTS_APPLY' | translate }}</label>
      <fui-dropdown
        [id]="'materialCompSel'"
        [multiple]="true"
        valueProperty="id"
        [options]="components"
        [selected]="selectedFacilityElements"
        [defaultSelected]="selectedFacilityElements"
        (selectedChange)="onFacilityElementsChange($event)"
      ></fui-dropdown>
    </div>
    <div class="four wide field required">
      <label>{{ 'ADD_MATERIAL.UNITS' | translate }}</label>
      <div class="two fields">
        <div class="field">
          <input
            name="materialUnits"
            appNumeric
            [min]="0"
            [ngModel]="units"
            (ngModelChange)="onUnitsChange($event)"
            type="text" 
            pattern="^\d*\.?\d*$"
          />
        </div>
        <div class="field">
          <fui-dropdown
            [id]="'materialUnitsSel'"
            [options]="materialUnitTypesOptions"
            [defaultSelected]="selectedMaterialUnits"
            [selected]="selectedMaterialUnits"
            (selectedChange)="onMaterialUnitsChange($event)"
          ></fui-dropdown>
        </div>
      </div>
    </div>
  </div>
</form>
