
import { 
    ReportHoursByCompanyDTO,
    ReportHoursByCompanyReg,
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessHoursByCompany extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }
    
    getData(){
        const {
            hoursByCompanyLabels, 
            correctiveData, 
            scheduledData, 
            correctiveData2, 
            scheduledData2
        } = this.getHoursByCompanyDataSets(this.reportGrossData as ReportHoursByCompanyDTO);
        const dataSetsHours = [{data: correctiveData, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_1"), stack: 'One date'},
                            {data: scheduledData, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_1"), stack: 'One date'}]
        if (correctiveData2.length > 0) {
            dataSetsHours.push({data: correctiveData2, label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_2"), stack: 'Other date'})
            dataSetsHours.push({data: scheduledData2, label: this.translate.instant("REPORTS.SCHEDULED_LABEL_2"), stack: 'Other date'})
        }
        this.reports.hoursByCompany = {
            labels: hoursByCompanyLabels,
            dataSets: dataSetsHours
        };
        this.reports.showIncidenceStatusFilter = false;
 
    }
    
    private getHoursByCompanyDataSets(hoursByCompany: ReportHoursByCompanyDTO) {
    
        let labels = [];
        const correctiveData = [];
        const scheduledData = [];
        const correctiveData2 = [];
        const scheduledData2 = [];
        hoursByCompany.value.forEach((reg: ReportHoursByCompanyReg) => {
          labels.push(reg.companyName);
          correctiveData.push(reg.correctiveHours);
          scheduledData.push(reg.scheduledHours);
        });
        if (hoursByCompany.value2) {
          hoursByCompany.value2.forEach((reg: ReportHoursByCompanyReg) => {
            labels.push(reg.companyName);
            correctiveData2.push(reg.correctiveHours);
            scheduledData2.push(reg.scheduledHours);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {hoursByCompanyLabels: labels, correctiveData, scheduledData, correctiveData2, scheduledData2};
      }
    
}