import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CategoryInterface} from '../../../shared/models/category.interface';
import {FormControl} from '@angular/forms';
import {ProblemTypeInterface} from '../../models/problem-type.interface';

@Component({
  selector: 'app-problem-types-paginated-table',
  templateUrl: './problem-types-paginated-table.component.html',
  styleUrls: ['./problem-types-paginated-table.component.scss']
})
export class ProblemTypesPaginatedTableComponent implements OnInit {

  @Input() categories: CategoryInterface[];
  @Input() problemTypes: ProblemTypeInterface[];

  selectedProblem: ProblemTypeInterface;

  filterCategory: FormControl;

  numItemOptions = [
    {value: 10, name: '10'},
    {value: 20, name: '20'}
  ];
  numItems: FormControl;

  @Input() page: number;
  @Input() totalPages: number;
  @Input() totalItems: number;

  @Output() selectedCategory: EventEmitter<CategoryInterface> = new EventEmitter<CategoryInterface>();
  @Output() selectedNumItems: EventEmitter<number> = new EventEmitter<number>();
  @Output() selectedPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleted: EventEmitter<ProblemTypeInterface> = new EventEmitter<ProblemTypeInterface>();

  constructor() {
    this.filterCategory = new FormControl({id: -1, name: 'Todas'});
    this.numItems = new FormControl(this.numItemOptions[0]);
  }

  ngOnInit(): void {
    this.filterCategory.valueChanges.subscribe(category => this.selectedCategory.emit(category));
    this.numItems.valueChanges.subscribe(num => this.selectedNumItems.emit(num.value));
  }

  clickProblem(problem: ProblemTypeInterface) {
    this.selectedProblem = this.selectedProblem === problem ? null : problem;
  }

  deleteSelected() {
    this.deleted.emit(this.selectedProblem);
    this.selectedProblem = null;
  }
}
