<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'USER_GROUPS.TITLE' | translate }}</h1>
  <div class="ui left floated primary icon button" routerLink="/grupos-usuarios/nuevo">
    {{ 'USER_GROUPS.ADD_GROUP' | translate }}
  </div>
</div>

<div class="sixteen wide column">
  <table class="ui selectable celled small compact table">
    <thead>
    <tr>
      <th>{{ 'USER_GROUPS.ID' | translate }}</th>
      <th>{{ 'USER_GROUPS.NAME' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let group of groups" [routerLink]="['/grupos-usuarios', group.id]">
      <td>{{ group.id }}</td>
      <td>{{ group.name }}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <th colspan="2">
        
      </th>
    </tr>
    </tfoot>
  </table>
</div>
