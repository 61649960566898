import {Component, HostBinding, OnInit} from '@angular/core';
import {SessionService} from '../../../shared/services/session.service';
import {CompaniesService} from '../../companies.service';
import {CompanyInterface, CompanyGetPaginatedDtoInterface} from '../../models/company.interface';
import {Router} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, filter, map, startWith, switchMap} from 'rxjs/operators';
import {Facility} from '../../../facilities/models/facility.interface';
import {RolesService} from '../../../shared/services/roles.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  @HostBinding('class') hostClasses = 'ui container grid';

  activeFacility: Facility;

  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  numOfPages: number;
  numTotalCompanies: number;

  numCompaniesPerPageOptions: { value: string, name: string }[];
  selectedNumPerPage: FormControl = new FormControl(null);

  companies$: Observable<CompanyInterface[]>;

  showSuccessCreateNotification: boolean;

  searchQuery$ = new Subject<string>();

  constructor(
    private router: Router,
    private sessionSrv: SessionService,
    private companiesSrv: CompaniesService,
    public roles: RolesService
  ) {
    this.numCompaniesPerPageOptions = [
      {value: '10', name: '10'},
      {value: '20', name: '20'},
      {value: '50', name: '50'}
    ];
    this.selectedNumPerPage.setValue(this.numCompaniesPerPageOptions[0]);

    this.showSuccessCreateNotification = (router.getCurrentNavigation().extras.state || {}).successfulCompanyCreate;
  }

  ngOnInit(): void {
    this.companies$ = combineLatest([
      this.sessionSrv.activeFacility$.pipe(filter(facility => !!facility)),
      this.page$,
      this.selectedNumPerPage.valueChanges.pipe(filter(selected => !!selected), map(selected => selected.value)),
      this.searchQuery$.pipe(debounceTime(400), startWith(''))
    ]).pipe(
      switchMap(([facility, page, limit, search]) => {
        let params = {
          facilityId: facility.id,
          page,
          limit
        };
        if (search) {
          params = Object.assign(params, {search});
        }
        return this.companiesSrv.fetchAllInFacility(params);
      }),
      map((paginatedResponse: CompanyGetPaginatedDtoInterface) => {
        if (this.page$.value > paginatedResponse.meta?.totalPages) {
          this.page$.next(paginatedResponse.meta.totalPages);
        }
        this.numOfPages = paginatedResponse.meta?.totalPages;
        this.numTotalCompanies = paginatedResponse.meta?.totalItems;
        return paginatedResponse.items;
      }));
  }

  firstItemNumOfPage(): number {
    return (this.page$.value - 1) * this.selectedNumPerPage?.value?.value + 1;
  }

  lastItemNumOfPage(): number {
    if (this.page$.value === this.numOfPages) {
      return 0;
    }

    return this.page$.value * this.selectedNumPerPage?.value?.value;
  }

  onSearchQueryChange(search: string) {
    this.searchQuery$.next(search);
  }

  hideSuccessCreateNotification() {
    this.showSuccessCreateNotification = false;
  }

}
