import {Component, forwardRef, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'pui-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {

  @HostBinding('attr.tabindex') get hostTabIndex() {
    return !this.isDisabled && !this.isReadOnly ? 0 : -1;
  }

  @HostBinding('class.disabled') get hostDisabledClass() {
    return this.isDisabled;
  }
  @HostBinding('class.readonly') get hostReadOnlyClass() {
    return this.isReadOnly;
  }

  @HostListener('click') clicked() {
    this.toggleValue();
  }

  @HostListener('keydown', ['$event']) keyUpOnHost(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      event.stopPropagation();
      this.toggleValue();
    }
  }

  @Input() set disabled(value: boolean | string) {
    this.isDisabled = (value === '') || !!value;
  }
  isDisabled: boolean;

  @Input() set readonly(value: boolean | string) {
    this.isReadOnly = (value === '') || !!value;
  }
  isReadOnly: boolean;

  checked: boolean;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor() { }

  ngOnInit(): void {
  }

  writeValue(obj: any): void {
    this.checked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  toggleValue() {
    if (!this.isDisabled && !this.isReadOnly) {
      this.checked = !this.checked;
      this.onChange(this.checked);
      this.onTouched();
    }
  }
}
