
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { FacilitiesService } from 'src/app/facilities/facilities.service';
import { Facility } from 'src/app/facilities/models/facility.interface';
import { ToastService } from '../../services/toast.service';
import { IShowToastRequest } from '../../models/show-toast-request.interface';
import { FacilityComponentInterface } from 'src/app/facilities/models/facility-element.interface';
import { ROLES } from '../../models/role.interface';

@Component({
  selector: 'app-scan-qr',
  templateUrl: "./qr-scan.component.html",
  styleUrls: ["./qr-scan.component.scss"],
})
export class ScanQrComponent implements OnInit {

  activeFacility: Facility;
  componentGUID: string;
  activeUserRole: number;
  component: FacilityComponentInterface;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private sessionService: SessionService,
    private facilitiesService: FacilitiesService,
    private toastService: ToastService,
    ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const facilityId = params['facilityId'];
      this.componentGUID = params['componentGUID'];
      
      // Fetch facilities to find the active facility
      this.facilitiesService.fetchFacilities().subscribe((facilities: Facility[]) => {
        const facility = facilities.find(f => f.id === +facilityId);

        if (facility) {
          // Set the active facility
          this.sessionService.setActiveFacility(facility);
          this.activeFacility = facility;
          
          // Redirect to the desired URL
          this.sessionService.userRole$
            .subscribe(async (userRole) => {
              this.activeUserRole = userRole;
              if ([
                ROLES.CLIENT_ADMIN, 
                ROLES.CLIENT_USER,
              ].includes(userRole)){
                this.redirectToNewIncidence();
              }
              await this.getDataComponent();
            });
          

        } else {
            const toastData: IShowToastRequest = {
                "type":"error",
                "message":"Facility not found",
            };
            this.toastService.showToast(toastData);
        }
      });
    });
  }

  redirectToNewIncidence(){
    this.router.navigate(['solicitudes', 'nueva'], {
      queryParams: { 
        facilityId: this.activeFacility.id, 
        componentGUID: this.componentGUID,
       },
    });
  }

  redirectToComponentPage(){
      this.router.navigate(['componentes',this.component.id ], {
        queryParams: { 
          facilityId: this.activeFacility.id, 
          componentGUID: this.componentGUID,
         },
      }); 
  }

  async getDataComponent(){
    await this.facilitiesService.fetchComponentByExternalIdentifierPromise(this.componentGUID, this.activeFacility.id)
    .then((component: FacilityComponentInterface) => {
      this.component = component;
    }
    );
  }
}
