
import { 
    ReportHoursByUserAndMaintenanceDTO, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessHoursByAssignedMaintenance extends ProcessDataReport{

    constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
        super(reports, reportGrossData);
    }
    
    getData(){
        const {
            labels: userFullNameAssigner, 
            dataA : correctiveHoursAssigner, 
            dataB : preventiveHoursAssigner, 
            dataA2 : correctiveHoursAssigner2, 
            dataB2 : preventiveHoursAssigner2
        } = this.getHoursByUserAndMaintenanceDataSets(this.reportGrossData as ReportHoursByUserAndMaintenanceDTO);

        const dataSetsHoursAssignerAndMaintenance = [{
            data: correctiveHoursAssigner, 
            label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_1"), 
            stack: 'One date', 
            order: 1
        },{
            data: preventiveHoursAssigner, 
            label: this.translate.instant("REPORTS.SCHEDULED_LABEL_1"), 
            stack: 'One date', 
            order: 2
        }];

        if (correctiveHoursAssigner2.length > 0) {
            dataSetsHoursAssignerAndMaintenance.push({
                data: correctiveHoursAssigner2, 
                label: this.translate.instant("REPORTS.CORRECTIVE_LABEL_2"), 
                stack: 'Other date', 
                order: 1
            });
            dataSetsHoursAssignerAndMaintenance.push({
                data: preventiveHoursAssigner2, 
                label: this.translate.instant("REPORTS.SCHEDULED_LABEL_2"), 
                stack: 'Other date', 
                order: 2
            });
        }

        this.reports.hoursByUserAndMaintenance = {
            labels: userFullNameAssigner,
            dataSets: dataSetsHoursAssignerAndMaintenance
        };

        this.reports.showIncidenceStatusFilter = false;
 
    }
    
}