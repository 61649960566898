import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { LANGUAGES } from 'src/app/shared/models/languages';
import { UserConfig } from 'src/app/shared/models/user-config.interface';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-general-tab',
  styleUrls: ['./general-tab.component.scss'],
  templateUrl: './general-tab.component.html'
})
export class GeneralConfigurationTabComponent implements OnInit {
  form: FormGroup;
  destroy$: Subject<boolean> = new Subject<boolean>();
  languageCodes: { value: string; name: string }[] = Object.keys(LANGUAGES).map((value) => ({
    value: LANGUAGES[value],
    name: value,
  }));
  isEditing: boolean;

  @Output() submittedValue = new EventEmitter();
  @Input() set config(data: UserConfig) {
    if (data){
        this.isEditing = true;
        const displayName = Object.keys(LANGUAGES).find(key => LANGUAGES[key] === data.language);
      if (displayName) {
        this.form.controls.languageUnit.setValue(displayName);
      }
    }
  }

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private sessionService: SessionService,
  ) {
    
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      languageUnit: ['']
    });

    // Subscribe to userConfig$ to set initial value
    this.sessionService.userConfig$.subscribe(config => {
      if (config) {
        this.config = config;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit(): void {
    const selectedLanguage = this.form.value.languageUnit.value;
    if (selectedLanguage) {
      // Set language in ngx-translate
      this.translate.use(selectedLanguage);

      // Update user's configuration with the selected language
      this.sessionService.setUserConfig({ language: selectedLanguage });
    }
  }
}
