export interface StatisticDataRegInterface {
  label: string;
  value: number;
  day?: string;
  week?: string;
  month?: string;
  year?: string;
}

export enum STATISTIC_TYPE {
  SINGLE = 'single',
  SERIE = 'series'
}

export interface StatisticInterface {
  id: string;
  type: STATISTIC_TYPE;
  value: StatisticDataRegInterface[];
}
