import { AfterViewInit, Component, Input, OnInit, } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { Facility } from 'src/app/facilities/models/facility.interface';
import { ScheduledTask } from 'src/app/scheduled-tasks/models/scheduled-task.interface';
import { ROLES } from 'src/app/shared/models/role.interface';
import { SpinnerVeilService } from 'src/app/shared/services/spinner-veil.service';
import { ICreateSubTask, IDeleteSubTask, ISchduledTask, ISubTask, IResponseSubtasks, ITotalSubtasks } from './models/edit-section-interface';
import { DetailPageEditSectionService } from './service/detail-page-edit-section.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-detail-page-edit-section',
  templateUrl: './detail-page-edit-section.component.html',
  styleUrls: ['./detail-page-edit-section.component.scss']
})
export class DetailPageEditSectionComponent implements OnInit, AfterViewInit {

  @Input() task: ScheduledTask;
  @Input() facility: Facility;
  @Input() userRole: ROLES;

  public scheduledTasks$: Observable<ISchduledTask[]>;
  public numTotalComponent: number;

  public checkedComponent = new Set<number>()
  public checkedSubtasks = new Set<ISubTask>()
  public subTaskForm: FormGroup;

  componentsPage$ = new BehaviorSubject<number>(1);
  componentsNumOfPages: number;
  subTasksPage: number = 1;
  subTasksNumOfPage: number = 1;
  subTasksPageSelected: number;

  public subTasksByComponent: ITotalSubtasks;
  public paginatedSubTasks: ISubTask[];

  checkedComponentSubject = new Subject<Set<number>>();
  updateSubtaskTable$ = new BehaviorSubject<boolean>(false);

  numComponentsPerPageOptions: { value: string, name: string }[];
  selectedNumPerPage: FormControl = new FormControl(null);
  componentLimit: number;

  showAddModal = false;
  showDeleteModal = false;

  filterParams$ = new BehaviorSubject<{}>({});
  allComponentChecked: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private componentSrv: DetailPageEditSectionService,
    private veilService: SpinnerVeilService,
    private translate: TranslateService,
  ) {
    this.numComponentsPerPageOptions = [
      { value: '5', name: '5' },
      { value: '10', name: '10' },
      { value: '30', name: '30' },
      { value: '100', name: '100' },
      { value: '300', name: '300' },

    ];
    this.selectedNumPerPage.setValue(this.numComponentsPerPageOptions[0]);
    this.initForm();
    this.addSubTask();
  }

  ngOnInit(): void {
    this.scheduledTasks$ = combineLatest(
      [
        this.componentsPage$,
        this.filterParams$.pipe(
          debounceTime(200),
        ),
        this.selectedNumPerPage.valueChanges.pipe(
          filter(selected => !!selected),
          tap(() => this.componentsPage$
            .next(1),
            debounceTime(200),
          ),
          map(selected => selected.value),
        ),
      ]
    ).pipe(
      switchMap(([page, filterParams, limit]) => {
        this.componentLimit = limit;
        return this.componentSrv.fetchTaskComponents(this.task?.id, this.facility?.id, page, limit, filterParams);
      }),
      tap((response) => {
        this.componentsNumOfPages = response.meta.totalPages;
        this.numTotalComponent = response.meta.totalItems;
      }),
      map(response => {
        return response.items;
      })
    );
  }

  ngAfterViewInit(): void {

    combineLatest(
      [
        this.componentsPage$,
        this.updateSubtaskTable$,
        this.checkedComponentSubject
      ]
      )
      .pipe(
        debounceTime(200),
      )
      .subscribe(
        ([page, update, componentIds]) => {
          this.isAllComponentChecked()
          if (componentIds.size) {
            this.componentSrv.fetchSubTasks(this.task?.id, [...componentIds], this.facility?.id).subscribe(
              ((response: IResponseSubtasks) => {
                this.checkedSubtasks.clear();
                this.subTasksByComponent = {
                  ...this.formatSubtask(response)
                };
                this.paginatedSubTasks = [
                  ...this.paginateSubtasks(1),
                ];
                const totalNumPage = Math.round(this.subTasksByComponent.subtasks.length / 10 + 1);
                this.subTasksPage = 1;
                this.subTasksNumOfPage = totalNumPage;
              }),
            )
          } else {
            this.subTasksByComponent = undefined;
          }
      }
    );

  }

  public shouldShowComponentTable(): boolean {
    return [
      ROLES.SUPER_ADMIN,
      ROLES.GLOBAL_SERVICE,
      ROLES.MAINTENANCE_ADMIN,
      ROLES.MAINTENANCE_USER,
      ROLES.OWNER
    ].includes(this.userRole);
  }

  public shouldShowSubtaskTable(): boolean {
    return [
      ROLES.SUPER_ADMIN,
      ROLES.GLOBAL_SERVICE,
      ROLES.OWNER
    ].includes(this.userRole)
  }

  public filterComponent(search: string, field: string): void {
    let previous = this.filterParams$.value;
    let next = {
      ...previous,
      [field]: search,
    }
    this.componentsPage$.next(1);
    this.filterParams$.next({ ...next })
  }


  public formatSubtask(response: IResponseSubtasks): ITotalSubtasks {

    const commonSubtasks = response.common.reduce((acc, subTask) => {
      return [
        ...acc,
        {
          ...subTask,
          type: "common",
        }
      ]
    }, []);

    const unCommonSubtask = response.unCommon.reduce((acc, subtask) => {
      return [
        ...acc,
        {
          ...subtask,
          type: "uncommon",
        }
      ]

    }, [])

    return {
      totalCommon: commonSubtasks.length,
      totalUncommon: unCommonSubtask.length,
      subtasks: [...commonSubtasks, ...unCommonSubtask],
    }
  }

  isAllComponentChecked():void {
    let inputs: NodeListOf<Element> = document.querySelectorAll(`[id*="rowId_"]`);
    let inputsArr = Array.from(inputs);

    if(!this.checkedComponent.size|| !inputs.length){
      this.allComponentChecked = false;
    }else{
      let aux = inputsArr.every(input=>{
      let id = +input.id.split('_')[1];
        return this.checkedComponent.has(id);
      })
      this.allComponentChecked = aux;
    }
  }


  checkAllComponents(checked: boolean): void {

    let inputs: NodeListOf<Element> = document.querySelectorAll(`[id*="rowId_"]`);
    inputs.forEach(input => {
      let id = +input.id.split('_')[1];
      this.onComponentChecked(checked, id);
    })
  }


  public onComponentChecked(isComponentChecked: boolean, componentId: number): void {

    isComponentChecked ?
      this.checkedComponent.add(componentId) :
      this.checkedComponent.delete(componentId);

    this.checkedComponentSubject.next(this.checkedComponent);
  }

  public onSubtaskChecked(isSubtaskChecked: boolean, subTask: ISubTask): void {
    isSubtaskChecked ? this.checkedSubtasks.add(subTask) : this.checkedSubtasks.delete(subTask);
  }

  public initForm(): void {
    this.subTaskForm = this.formBuilder.group({
      subTasks: this.formBuilder.array([]),
    });
  }

  public subTasks(): FormArray {
    return this.subTaskForm.get('subTasks') as FormArray;
  }

  public newSubTask(): FormGroup {
    return this.formBuilder.group({
      code: ["", Validators.required],
      description: ["", Validators.required],
    })
  }

  public addSubTask(): void {
    this.subTasks().push(this.newSubTask());
  }

  public removeSubTask(index: number): void {
    this.subTasks().removeAt(index);
  }

  public clickOnSubmit(): void {
    this.showAddModal = true;
  }

  public clickOnDelete(): void {
    this.showDeleteModal = true;
  }

  public closeModal(): void {
    this.showAddModal = false;
    this.showDeleteModal = false;
  }

  public isBtnSubmitDisabled(): boolean {

    const canAddExistingSubtask = 
      this.checkedComponent.size > 0 && this.checkedSubtasks.size > 0;

    if(canAddExistingSubtask){
      let isAllUncommon:boolean = true;

      this.checkedSubtasks.forEach((subtask) => {
        if(subtask.type !== 'uncommon'){
          isAllUncommon = false;
        }
      })
      return !(canAddExistingSubtask && isAllUncommon && this.shouldShowSubtaskTable() && !this.task.finishedAt)
    }

    const canAddNewSubtask = 
      this.checkedComponent.size > 0 && this.subTaskForm.valid


    return !(
      canAddNewSubtask &&
      this.shouldShowSubtaskTable() &&
      !this.task.finishedAt
    )
  }

  public submit(option: boolean): void {
    this.veilService.openVeil();

    this.showAddModal = false;

    let hasNewSubtask = this.subTaskForm.valid;

    let subtasksArr = new Array<ISubTask>();

    if(hasNewSubtask){
      subtasksArr = [...this.subTaskForm.getRawValue().subTasks];
    }else{
      subtasksArr = [...this.checkedSubtasks];
    }

    subtasksArr.forEach((subTask: ISubTask) => {
  
      const body: ICreateSubTask = {
        code: subTask.code,
        description: subTask.description,
        componentsIds: Array.from(this.checkedComponent),
        scheduledTask: {
          id: this.task.id,
          parent: this.task.parent,
          createdAt: this.task.createdAt,
        }
      }

      this.componentSrv.createSubTask(body, this.facility.id, option).subscribe(
        (response: any) => {
          this.veilService.closeVeil();
          if (response.length > 0) {
            this.updateSubtaskTable$.next(true);
          }
        },
        (error: any) => {
          this.veilService.closeVeil();
        }
      )
    });
    //clear and reset
    this.subTaskForm.reset();
    this.subTasks().clear();
    this.addSubTask();
  }

  public isBtnDeleteDisabled(): boolean {
    const checkedComponent_Subtask = this.checkedComponent.size > 0 && this.checkedSubtasks.size > 0;
    const hasPermitions = this.shouldShowSubtaskTable();
    const isDisabled = !(checkedComponent_Subtask && hasPermitions && !this.task.finishedAt);
    return isDisabled;
  }

  public deleteSubtasks(option: boolean): void {
    this.showDeleteModal = false;
    this.veilService.openVeil();
    this.checkedSubtasks.forEach((subtask: ISubTask) => {

      const body: IDeleteSubTask = {
        code: subtask.code,
        componentsIds: Array.from(this.checkedComponent),
        scheduledTaskId: this.task.id,
      }

      this.componentSrv.deleteSubtasks(body, this.facility.id, option).subscribe(
        (response: any) => {
          this.veilService.closeVeil();
          if (response.length > 0) {
            this.updateSubtaskTable$.next(true);
          }
        },
        (error)=>{
          this.veilService.closeVeil();
        }
      )
    });

    this.checkedSubtasks.clear();
  }

  getFooterMessage(): string {
    const currentPage = this.componentsPage$.value;
    const limit = this.componentLimit;
    const totalItems = this.numTotalComponent;
  
    if (totalItems === 0) {
      return this.translate.instant('SCHEDULED_TASK_DETAIL.NO_COMPONENTS_TO_SHOW');
  
    } else {
      const startElement = (currentPage - 1) * limit + 1;
      let endElement = currentPage * limit;
      
      if (endElement > totalItems) {
        endElement = totalItems;
      }
  
      return this.translate.instant('SCHEDULED_TASK_DETAIL.GREATERTHAN_THEN_COMPONENTS', { startElement, endElement, totalComponents: totalItems});
    }
  }
  

  public changeSubtasksPage(event: number): void {
    this.subTasksPage = event;
    this.paginatedSubTasks = [...this.paginateSubtasks(event)];
  }

  public paginateSubtasks(pageNumber: number): Array<any> {
    const aux = [...this.subTasksByComponent.subtasks]
    return aux.slice((pageNumber - 1) * 10, pageNumber * 10);
  }
}
