<form class="ui form" [formGroup]="form">
  <div class="field" *ngIf="canRateIncidence() | async" 
    style="display: flex; justify-content: flex-start">
    <div class="ui primary button" [class.disabled]="!form.valid" (click)="rateIncidence()">{{ 'RATE' | translate }}</div>
  </div>
  <app-label-and-info
      [helpingText]="'HELP_REVIEW' | translate"
  ></app-label-and-info>
  <div class="field">
    <label>{{ 'SCORE' | translate }}</label>
    <div id="hello" fuiRating icon="star" color="yellow" maxRating="5" formControlName="score" [class.disabled]="!(canRateIncidence() | async)"></div>
  </div>
  <div *ngIf="(detailIncidence.incidence$ | async)?.reviewedAt" class="field">
    <label>{{ 'REVIEW_DATE' | translate }}</label>
    {{(detailIncidence.incidence$ | async)?.reviewedAt | date}}
  </div>
  <div class="field">
    <label>{{ 'COMMENT' | translate }}</label>
    <textarea name="reviewDescription" formControlName="description" [readOnly]="!(canRateIncidence() | async)"></textarea>
  </div>
</form>
