import {Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SessionService} from '../../../shared/services/session.service';
import {FacilitiesService} from '../../facilities.service';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {FacilityElementType} from '../../models/facility-element-type.interface';
import {pluck, skipWhile, switchMap, take, takeUntil} from 'rxjs/operators';
import {PaginatedResult} from '../../../shared/models/paginated-result.interface';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SortByData} from '../../../fomantic-ui/sortable-th/sortByData.interface';
import {saveAs} from 'file-saver';
import {ToastService} from '../../../shared/services/toast.service';
import { DateTime } from 'luxon';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-element-types-page',
  templateUrl: './element-types-page.component.html',
  styleUrls: ['./element-types-page.component.scss']
})
export class ElementTypesPageComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui grid container';

  types: FacilityElementType[];
  page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  elemsByPage: FormControl = new FormControl(null);
  elemsByPageOptions = [
    {value: 10, name: '10'},
    {value: 20, name: '20'},
    {value: 40, name: '40'}
  ];
  totalPages: number;
  totalTypes: number;

  sortedBy$: BehaviorSubject<SortByData> = new BehaviorSubject<SortByData>(null);

  filtersForm: FormGroup;
  filters$ = new BehaviorSubject({});

  @ViewChild('fileInput') fileInput: ElementRef;
  uploadingCsv = false;

  exportingCSV = false;

  destroy$: Subject<boolean> = new Subject<boolean>();
  uploadErrors: {row: number, message: string}[];
  showErrorsModal = false;

  constructor(
    private session: SessionService,
    private facilitySrv: FacilitiesService,
    private fb: FormBuilder,
    private toast: ToastService,
    private translate: TranslateService,
  ) {
    this.filtersForm = fb.group({
      name: null,
      modelNumber: null,
      company: null,
      replacementCost: null,
      expectedLife: null,
    });
  }

  ngOnInit(): void {
    combineLatest([
      this.session.activeFacility$.pipe(skipWhile(f => !f)),
      this.page$,
      this.elemsByPage.valueChanges.pipe(skipWhile(n => !n), pluck('value')),
      this.sortedBy$,
      this.filters$
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([facility, page, limit, sortData, filters]) => {
        let params = {
          facilityId: facility.id,
          page,
          limit,
          ...filters
        };
        if (sortData) {
          params = Object.assign(params, {sortBy: sortData.column, order: sortData.order});
        }

        return this.facilitySrv.fetchComponentTypes(params);
      })
    ).subscribe((response: PaginatedResult<FacilityElementType>) => {
      if (this.page$.value > response.meta.totalPages && response.meta.totalPages !== 0) {
        this.page$.next(response.meta.totalPages);
      } else {
        this.totalPages = response.meta.totalPages;
        this.totalTypes = response.meta.totalItems;
        this.types = response.items;
      }
    });

    this.elemsByPage.setValue(this.elemsByPageOptions[0]);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  exportCSV() {
    this.exportingCSV = true;
    this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.facilitySrv.downloadCSVComponentTypes({
          facilityId: facility.id,
          ...this.filters$.value
        });
      })
    ).subscribe(
      data => {
        const blob = new Blob([data], {type: 'text/csv'});
        const date = DateTime.local().toFormat("yyyy-MM-dd'T'HH-mm-ss");
        const fileName = `facility-component-types-${date}.csv`;
        saveAs(blob, fileName);
      },
      error => {},
      () => {
        this.exportingCSV = false;
      }
    );
  }

  handleKeyPress(event){
    if (event.keyCode === 13)
      this.applyFilters();
  }
  
  applyFilters() {
    const filters = this.filtersForm.value;
    Object.keys(filters).forEach(key => {
      if (!filters[key] || filters[key].trim() === '') {
        delete filters[key];
      }
    });

    this.filters$.next(filters);
  }

  clearFilters() {
    this.filtersForm.reset();
    this.filters$.next({});
  }

  uploadCSV() {
    const file = this.fileInput.nativeElement.files[0];
    this.uploadingCsv = true;
    this.session.activeFacility$.pipe(
      skipWhile(facility => !facility),
      take(1),
      switchMap(facility => {
        return this.facilitySrv.uploadComponentTypesCSV(file, {facilityId: facility.id});
      })
    ).subscribe(
      (result: any) => {
        if (result.result === 'error') {
          this.uploadErrors = result.errors;
          this.showErrorsModal = true;
        } else {
          this.toast.showToast({
            type: 'success',
            message: this.translate.instant('IMPORT_SUCCESSFUL')
          });
        }
      },
      error => {},
      () => {
        this.uploadingCsv = false;
        this.fileInput.nativeElement.value = null;
      }
    );
  }
}
