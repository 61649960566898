<div class="sidenav" id="sidenav" [@showMobileSideNav]="mobileSidenavState">
  <app-side-nav
    [class.collapsed]="sideNavCollapsed"
    (toggleButtonClicked)="toggleSideBar()"
    (closeButtonClicked)="closeSidenav()"
  ></app-side-nav>
</div>
<div class="content">
  <div class="sixteen wide computer column header-column">
    
      <div class="only-mobile left menu">
        <a class="item">
          <i class="ui bars icon" (click)="openSidenav()"></i>
        </a>
      </div>

      <div class="menu-absolute">
        <app-menu-help></app-menu-help>  
        <app-menu-new-incidence></app-menu-new-incidence>
        <app-menu-messages></app-menu-messages>
        <app-menu-profile></app-menu-profile>
      </div>
    
  </div>
  <div class="sixteen wide column">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="toast-container" [class.open]="toastOpened">
  <app-toast
    [type]="toastType"
    [header]="toastHeader"
    [message]="toastMessage"
  ></app-toast>
  <i *ngIf="toastType === 'error'" (click)="hideToast()" class="delete icon hide_toast"></i>
</div>

<div class="spinner-veil" *ngIf="this.veilService.isOpen$ | async">
  <div class="spinner-veil-container">
    <pui-spinner color="#ffffff" heightPx="60"></pui-spinner>
    <div class="color-white">{{loadingMessage | translate}}</div>
  </div>
</div>
