
import { 
    ReportAVGScoreByCompanyDTO, 
    ReportAVGScoreByCompanyReg, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";
import { TranslateService } from "@ngx-translate/core";

export class ProcessAvgScoreByCompany extends ProcessDataReport{
  translate: TranslateService;

    constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
        super(reports, reportGrossData);
    }
    
    getData(){
        const {labels: companyNameScored, dataA : avgScore, 
            dataA2 : avgScore2, } = this.getAVGScoreByCompanyDataSets(this.reportGrossData as ReportAVGScoreByCompanyDTO);
          const dataSetsAVGscoreByCompany = [{data: avgScore, label: this.translate.instant('REPORTS.AVG_SCORE_LABEL_1')}]
          if (avgScore2.length > 0) {
            dataSetsAVGscoreByCompany.push({data: avgScore, label: this.translate.instant('REPORTS.AVG_SCORE_LABEL_2')})
          }
          this.reports.avgScoreByCompany = {
            labels: companyNameScored,
            dataSets: dataSetsAVGscoreByCompany
          };
          this.reports.showIncidenceStatusFilter = false;
 
    }
    private getAVGScoreByCompanyDataSets(avgScoreByCompany: ReportAVGScoreByCompanyDTO) {
    
        let labels = [];
        const dataA = [];
        const dataA2 = [];
        avgScoreByCompany.value.forEach((reg: ReportAVGScoreByCompanyReg) => {
          labels.push(reg.companyName);
          dataA.push(reg.avgScore);
        });
        if (avgScoreByCompany.value2) {
          avgScoreByCompany.value2.forEach((reg: ReportAVGScoreByCompanyReg) => {
            labels.push(reg.companyName);
            dataA2.push(reg.avgScore);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataA2};
      }
}