<div class="ui mini five steps">

  <div class="completed step" [class.active]="incidence?.isCreated()">
    <i class="plus icon"></i>
    <div class="content">
      <div class="title">{{'INCIDENCE_STATES.CREATED' | translate }}</div>
      <div class="description">
        {{formatDate(incidence?.createdAt)}} <br>
        {{formatHour(incidence?.createdAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="incidence?.wasOpened()" [class.active]="incidence?.isOpen()">
    <i class="flag icon"></i>
    <div class="content">
      <div class="title">{{'INCIDENCE_STATES.OPENED' | translate }}</div>
      <div *ngIf="incidence?.wasOpened()" class="description">
        {{formatDate(incidence?.openedAt)}} <br>
        {{formatHour(incidence?.openedAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="incidence?.wasAssigned()" [class.active]="incidence?.isAssigned()">
    <i class="sign in icon"></i>
    <div class="content">
      <div class="title">{{'INCIDENCE_STATES.ASSIGNED' | translate }}</div>
      <div *ngIf="incidence?.wasAssigned()" class="description">
        {{formatDate(incidence?.assignedAt)}} <br>
        {{formatHour(incidence?.assignedAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="incidence?.wasFinished()" [class.active]="incidence?.isFinished()">
    <i class="tools icon"></i>
    <div class="content">
      <div class="title">{{'INCIDENCE_STATES.FINISHED' | translate }}</div>
      <div *ngIf="incidence?.wasFinished()" class="description">
        {{formatDate(incidence?.finishedAt)}} <br>
        {{formatHour(incidence?.finishedAt)}}
      </div>
    </div>
  </div>

  <div class="step" [class.completed]="incidence?.wasClosed()" [class.active]="incidence?.isClosed()">
    <i class="lock icon"></i>
    <div class="content">
      <div class="title">{{'INCIDENCE_STATES.CLOSED' | translate }}</div>
      <div *ngIf="incidence?.wasClosed()" class="description">
        {{formatDate(incidence?.closedAt)}} <br>
        {{formatHour(incidence?.closedAt)}}
      </div>
    </div>
  </div>
</div>
