<div class="loading_content" [class.active]="loading">
  <pui-spinner color="#ffffff" heightPx="60"></pui-spinner>
</div>

<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'MESSAGES.TITLE' | translate }}</h1>
</div>

<div class="five wide column">
  <div class="chat-selector">
    <div *ngFor="let chat of chats | async"
        class="chat-item"
        [class.active]="chat.id === selected"
        >
        <div 
        *ngIf="chat.id === selected && isMobile"
        class="go-back"
        (click)="goBackToChatSelector()">
          <i>{{ 'MESSAGES.BACK' | translate }}</i>
        </div>
        <div
        (click)="selectChat(chat)"
        >
          <div class="item-row">
            <div class="facility">{{ chat.facility?.name }}</div>
            <div class="type">{{incidenceType(chat.type?.name)}}</div>
            <div *ngIf="chat.unreadChats?.length > 0" class="circular">{{ chat.unreadChats?.length }}</div>  
          </div>
        
          <div class="item-row">
            <div class="topic">{{ chat.topic }}</div>
          </div>
          <div class="item-row">
            <div *ngIf="chat.unreadChats?.length > 0" class="unread" >
              <div class="lastMessage">{{ formatDate(chat.unreadChats[chat.unreadChats?.length - 1].createdAt) }}</div>
            </div>
            <div class="id">#{{ chat.id }}</div>
          </div>
        </div>
    </div>
  </div>
</div>

<div class="eleven wide column chat-messages"
[class.active]="selected">

  <app-chat
    *ngIf="messages && messages.length !== 0; else noMessages"
    [messages]="messages"
    (newMessage)="sendMessage($event)"
  ></app-chat>
</div>

<ng-template #noMessages>
  <div class="empty_screen select-message" *ngIf="!loading && !isMobile && messagesIncidences.length > 0">
    {{ 'MESSAGES.SELECT_CHAT' | translate }}
  </div>
  <div class="empty_screen no-message" *ngIf="!loading && messagesIncidences.length == 0">
    {{ 'MESSAGES.NO_MESSAGES' | translate }}
  </div>
</ng-template>
