import {Injectable, OnDestroy} from '@angular/core';
import {ROLES} from '../models/role.interface';
import {SessionService} from './session.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RolesService implements CanActivate, OnDestroy {

  private static readonly ACTIONS = {
    manageIncidences: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN, ROLES.CLIENT_USER],
    createUser: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN, ROLES.MAINTENANCE_ADMIN],
    editUser: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN, ROLES.MAINTENANCE_ADMIN],
    manageUserGroups: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.CLIENT_ADMIN, ROLES.MAINTENANCE_ADMIN],
    createCompany: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN],
    manageProblemTypes: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN],
    manageCriticalityLevels: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE],
    manageMenu: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER],
    manageCosts: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.MAINTENANCE_ADMIN],
    manageCostsFacility: [ROLES.SUPER_ADMIN, ROLES.OWNER],
    manageTime: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER],
    manageFacilityInfo: [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE],
  };

  userRoleSubs: Subscription;
  userRole: number;

  constructor(private session: SessionService, private router: Router) {
    this.userRoleSubs = this.session.userRole$.subscribe(role => this.userRole = role);
  }

  ngOnDestroy() {
    this.userRoleSubs.unsubscribe();
  }

  public can(action: string): boolean {
    return RolesService.ACTIONS[action]?.includes(this.userRole);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.session.userRole$.pipe(
      filter(role => !!role || role === 0),
      take(1),
      map(role => {
        if (role === ROLES.SUPER_ADMIN || (route.data.roles as ROLES[])?.includes(role)) {
          return true;
        } else {
          this.router.navigate(['/dashboard']);
          return false;
        }
      })
    );
  }
}
