import { Component, HostBinding, OnDestroy, OnInit } from "@angular/core";

import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { filter, switchMap, takeUntil } from "rxjs/operators";
import { Facility } from "../../../facilities/models/facility.interface";
import { CategoryInterface } from "../../../shared/models/category.interface";
import { CriticalityLevelInterface } from "../../models/criticalityLevel.interface";
import { CriticalityLevelsService } from "../../services/criticalityLevels.service";
import { RolesService } from "../../../shared/services/roles.service";
import { SessionService } from "../../../shared/services/session.service";
import { NEVER, Subject, of } from "rxjs";
import { ActionTypes } from "src/app/shared/models/role.interface";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-config-criticality-page",
  templateUrl: "./config-criticality-page.component.html",
  styleUrls: ["./config-criticality-page.component.scss"],
})
export class ConfigCriticalityPageComponent implements OnInit, OnDestroy {
  @HostBinding("class") hostClasses = "ui container grid";
  destroy$: Subject<boolean> = new Subject<boolean>();
  sections: { key: string; title: string }[];

  activeSection: { key: string; title: string };
  activeFacility: Facility;
  categories: CategoryInterface[];
  criticalityLevels: CriticalityLevelInterface[];
  levelForm: FormGroup;

  constructor(
    private sessionService: SessionService,
    private criticalityLevelSrv: CriticalityLevelsService,
    private router: Router,
    private rolesService: RolesService,
    private translate: TranslateService,
  ) {
    this.levelForm = new FormGroup(
      {
        name: new FormControl("", Validators.required),
        hours: new FormControl(null),
        minutes: new FormControl(null),
      },
      this.criticalityLevelValidator
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.configureSections();
    this.activeSection = this.sections[2];

    // Use switchMap to handle activeFacility change
    this.sessionService.activeFacility$
      .pipe(
        filter((facility) => !!facility),
        switchMap((facility) => {
          this.activeFacility = facility;

          // Check userRole
        if (!this.rolesService.can(ActionTypes.ManageCriticalityLevels)) {
          // Use navigateByUrl to ensure synchronous navigation
          this.router.navigateByUrl("/configuracion/notificaciones");
          return NEVER; // Stop further execution if role is not authorized
        }
        return of(facility);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        
        // Fetch criticality levels for the new facility
        this.criticalityLevelSrv.fetchAll(this.activeFacility.id)
          .subscribe((levels) => {
            this.criticalityLevels = levels;
          });
      });
  } 

  criticalityLevelValidator(
    form: AbstractControl
  ): { [key: string]: any } | null {
    if (form.value.hours || form.value.minutes) {
      return null;
    } else {
      return { criticalityLevelHoursOrMinutes: true };
    }
  }

  private configureSections() {
    this.sections = [
      { key: "notifications", title: this.translate.instant("SETTINGS.NOTIFICATIONS") },
      { key: "problemTypes", title: this.translate.instant("SETTINGS.PROBLEM_TYPES") },
      { key: "criticalityLevels", title: this.translate.instant("SETTINGS.CRITICALITY_LEVELS") },
      { key: "facilityInfo", title: this.translate.instant("SETTINGS.FACILITY_INFO") },
      { key: "general", title: this.translate.instant("SETTINGS.GENERAL") },
    ];
  }

  addNewCriticalityLevel() {
    let time = this.levelForm.value.hours ? this.levelForm.value.hours * 60 : 0;
    time = time + (this.levelForm.value.minutes || 0);

    this.criticalityLevelSrv
      .create(this.activeFacility.id, {
        name: this.levelForm.value.name,
        time,
      })
      .subscribe((response) => {
        this.criticalityLevels.push(response);
        this.levelForm.reset();
      });
  }

  deleteCriticalityLevel(id) {
    this.criticalityLevelSrv
      .delete(id, this.activeFacility.id)
      .subscribe((result) => {
        this.criticalityLevels.splice(
          this.criticalityLevels.findIndex((level) => level.id === id),
          1
        );
      });
  }
}
