import { DateTime } from 'luxon';
import { ScheduledTaskFilter, 
  ScheduledTaskFilterConditions, 
  ScheduledTaskFilterConditionsGroup, 
  SCHEDULED_TASKS_TYPES, 
  SCHEDULED_TASKS_STATUS, 
  ScheduledTask,
 } from '../models/scheduled-task.interface';
import { TranslateService } from '@ngx-translate/core';

export const FREQUENCY_UNIT_OPTIONS = [
  {value: 'days', name: 'DAYS'},
  {value: 'weeks', name: 'WEEKS'},
  {value: 'months', name: 'MONTHS'},
  {value: 'years', name: 'YEARS'}
];

export const SCHEDULED_TASKS_TYPES_OPTIONS = [
  { value: SCHEDULED_TASKS_TYPES.PREVENTIVE, name: 'PREVENTIVE' },
  { value: SCHEDULED_TASKS_TYPES.CONDUCTIVE, name: 'CONDUCTIVE' },
  { value: SCHEDULED_TASKS_TYPES.MANDATORY, name: 'MANDATORY' },
];

export const SCHEDULED_TASKS_STATUS_OPTIONS = [
  {value: SCHEDULED_TASKS_STATUS.FINISHED, name: 'FINISHED'},
  {value: SCHEDULED_TASKS_STATUS.FINISHED_WITH_ISSUES, name: 'FINISHED_WITH_ISSUES'},
  {value: SCHEDULED_TASKS_STATUS.NOT_STARTED, name: 'NOT_STARTED'},
]

export function getScheduledTypesOptions(translate: TranslateService){
  return SCHEDULED_TASKS_TYPES_OPTIONS.map(option => ({ value: option.value, name: translate.instant(`SCHEDULED_TASKS_TYPE.${option.name}`) }));
}
export function getFrequencyUnitOptions(translate: TranslateService){
  return FREQUENCY_UNIT_OPTIONS.map(option => ({ value: option.value, name: translate.instant(`FREQUENCY_UNIT.${option.name}`) }));
}
export function getScheduledTasksStatusOptions(translate: TranslateService){
  return SCHEDULED_TASKS_STATUS_OPTIONS.map(option => ({ value: option.value, name: translate.instant(`SCHEDULED_TASKS_STATUS.${option.name}`) }));
}

export function formatInfoFromForm(formValue: any): object {
  return {
    name: formValue.name,
    frequencyQuantity: formValue.frequencyQuantity,
    frequencyUnit: formValue.frequencyUnit.value,
    createdAt: formValue.createdAt,
    categoryId: formValue.category.id,
    companyId: formValue.company.id,
    assignedToEmail: formValue.assignedTo?.email,
    strictDates: formValue.strictDates,
    typeId: formValue.type?.value,
  };
}

export function formatFilterFromForm(formValue: any): ScheduledTaskFilter {
  const inclusiveFilters: ScheduledTaskFilterConditionsGroup = [];
  const exclusiveFilters: ScheduledTaskFilterConditionsGroup = [];

  formValue.inclusiveFilters.forEach(conditionsGroup => {
    const group = formatConditionsFromForm(conditionsGroup);
    if (group) {
      inclusiveFilters.push(group);
    }
  });

  formValue.exclusiveFilters.forEach(conditionsGroup => {
    const group = formatConditionsFromForm(conditionsGroup);
    if (group) {
      exclusiveFilters.push(group);
    }
  });

  if (inclusiveFilters.length || exclusiveFilters.length) {
    return {
      inclusiveFilters,
      exclusiveFilters
    };
  } else {
    return null;
  }
}

function formatConditionsFromForm(formValue: any[]): ScheduledTaskFilterConditions {
  const group = {};
  formValue.forEach(condition => {
    if (condition.filterParam?.value && condition.value) {
      group[condition.filterParam.value] = condition.value;
    }
  });

  return Object.keys(group).length ? group : null;
}

 

export function getTaskColor(task: ScheduledTask) {

  const eventColors = {
    completed: {primary: '#107A43', secondary: '#d7fadd'},
    finishedWithIssue:{ primary: '#c74f42', secondary:'#FEADBA'},
    overtime: {primary: '#f08907', secondary:'#fc523f'},
    overtimeButCanFinish: { primary: '#b3d4ff', secondary:'#7fb7ff'},
    default: {primary: '#1e90ff', secondary:'#1e90ff'}
  };

  let color : {primary: string, secondary: string};
          const today = DateTime.now().startOf('day');
          const firstDayOfLastMonth = today.minus({ months: 1 }).startOf('month');
          const createdAtDate = DateTime.fromISO(task.createdAt);

  if (task.statusId === SCHEDULED_TASKS_STATUS.FINISHED_WITH_ISSUES) {
    color = eventColors.finishedWithIssue;
  } else if (task.finishedAt) {
    color = eventColors.completed;
  } else if (createdAtDate < today 
    && createdAtDate >= firstDayOfLastMonth){
    color = eventColors.overtimeButCanFinish;
  } else if (createdAtDate < firstDayOfLastMonth){
    color = eventColors.overtime;
  } else {
    return eventColors.default;
  }

  return color;
}