<div class="sixteen wide column">
  <h1 class="ui dividing header">{{ 'SCHEDULED_TASK_DETAIL.NEW_TASK' | translate }}</h1>
</div>
<div class="four wide column">
  <div class="ui fluid vertical menu">
    <a class="item" [class.active]="activeSection === 'info'" (click)="activeSection = 'info'">{{ 'SCHEDULED_TASK_DETAIL.INFORMATION' | translate }}</a>
    <a class="item" [class.active]="activeSection === 'filter'" (click)="activeSection = 'filter'">{{ 'SCHEDULED_TASK_DETAIL.COMPONENTS' | translate }}</a>
  </div>
</div>

<div class="twelve wide column">
  <div class="ui grid">

    <!-- INFO FORM -->
    <div class="sixteen wide column" *ngIf="activeSection === 'info'">
      <app-scheduled-task-info-form
        [creatingTask]="true"
        [form]="infoForm"
        [categories]="categories"
        [companies]="companies"
        [users]="users"
      ></app-scheduled-task-info-form>
    </div>
  </div>

  <!-- FILTERS FORM -->

  <div class="sixteen wide column" *ngIf="activeSection === 'filter'">
    <div class="ui grid">

      <!-- Inclusive Filters -->
      <div class="sixteen wide column">
        <app-conditions-filter-group-form
          [conditionsFiltersArray]="filtersForm.get('inclusiveFilters')"
          [filterParams]="filterParams"
        ></app-conditions-filter-group-form>
      </div>

      <!-- Exclusive Filters -->
      <div class="sixteen wide column">
        <app-conditions-filter-group-form
          [conditionsFiltersArray]="filtersForm.get('exclusiveFilters')"
          [filterParams]="filterParams"
          [firstGroupTitle]="'SCHEDULED_TASK_DETAIL.EXCLUDE_COMPONENTS_WITH_ALL' | translate"
          [nextGroupsTitle]="'SCHEDULED_TASK_DETAIL.OR_COMPONENTS_WITH_ALL' | translate"
        ></app-conditions-filter-group-form>
      </div>

    </div>
  </div>

  <div class="sixteen wide column" style="display: flex; justify-content: flex-end">
    <div style="margin-top: 20px"
         class="ui primary button"
         [class.disabled]="!createRequestData"
         (click)="createScheduledTask()"
    >{{ 'SCHEDULED_TASK_DETAIL.CREATE' | translate }}
    </div>
  </div>

  <div class="sixteen wide column" *ngIf="activeSection === 'filter'">
    <div class="ui grid">

      <div class="sixteen wide column">
        <fui-pagination-menu
          *ngIf="filteredComponents && filteredComponents.length"
          [page]="componentsPage$ | async"
          [numOfPages]="componentsTotalPages"
          (pageSelected)="pageSelected($event)"
        ></fui-pagination-menu>
      </div>

      <div class="sixteen wide column">
        <app-filtered-components-table
          *ngIf="filteredComponents && filteredComponents.length"
          [components]="filteredComponents">
          <app-pagination-num-items-info
            slot="pagination"
            itemsName="{{ 'SCHEDULED_TASK_DETAIL.COMPONENTS' | translate }}"
            [page]="componentsPage$ | async"
            [numItemsPerPage]="numComponentsPerPage"
            [totalItems]="totalComponentsFiltered"
          ></app-pagination-num-items-info>
        </app-filtered-components-table>
      </div>

    </div>
  </div>
</div>
