import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

interface IQueryParams {
  taskId?: number;
  facilityId?: number;
  page?: number;
  limit?: number;
  search?: string;
  checked?: boolean | null;
}

@Injectable({
  providedIn: 'root'
})
export class ScheduledTasksPageViewerService {

  private readonly apiHost = environment.apiHost;

  constructor(
    private http: HttpClient,
  ) { }

  public fetchComponentsSubtask(queryParams: IQueryParams): Observable<any> {
    const { facilityId, taskId, page, limit, search } = queryParams;

    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('page', page.toString());
    params = params.append('limit', limit.toString());
    params = params.append('filter', search ? search.toString() : '');

    return this.http.get<any>(`${this.apiHost}/scheduled-tasks/${taskId}/components/subtasks?`,
      { params: params }
    )
  }

  public reviewSubtask(subtaskId: number, facilityId: number, checkedOption: boolean | null): Observable<any> {

    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('checkedOption', checkedOption === null ? 'null' : checkedOption.toString());

    return this.http.patch<any>(`${this.apiHost}/scheduled-tasks-components-subtask/${subtaskId}/finish?`,
      {},
      { params: params }
    )
  }

  public reviewedAllSubtask(componentId: number, facilityId: number, checkedOption: boolean | null): Observable<any> {
    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('checkedOption', checkedOption === null ? 'null' : checkedOption.toString());

    return this.http.patch<any>(`${this.apiHost}/scheduled-tasks/component/${componentId}/finish-all?`,
      {},
      { params: params }
    )
  }

  public reviewComponent(componentId: number, facilityId: number, checkedOption: boolean): Observable<any> {

    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('checkedOption', checkedOption.toString());

    return this.http.patch<any>(`${this.apiHost}/scheduled-tasks/component/${componentId}/finish?`,
      {},
      { params: params }
    )
  }

  public updateValue(componentSubtaskId: number, facilityId: number, value: number | null): Observable<any> {

    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('value', value? value.toString() : null);

    return this.http.patch<any>(`${this.apiHost}/scheduled-tasks-components-subtask/${componentSubtaskId}/?`,
      {},
      { params: params }
    )
  }

}