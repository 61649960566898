import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {DetailWorkOrderService} from './detail-work-order.service';
import {BehaviorSubject, Observable, Subscription, combineLatest} from 'rxjs';
import {WorkOrder} from '../../models/work-order.model';
import {ROLES} from '../../../shared/models/role.interface';
import {SessionService} from '../../../shared/services/session.service';
import {WorkOrderService} from '../../work-order.service';
import {ToastService} from '../../../shared/services/toast.service';
import {Facility} from '../../../facilities/models/facility.interface';
import { RolesService } from 'src/app/shared/services/roles.service';
import { filter, map, skipWhile } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-detail-work-order',
  templateUrl: './detail-work-order.component.html',
  styleUrls: ['./detail-work-order.component.scss'],
  providers: [DetailWorkOrderService]
})
export class DetailWorkOrderComponent implements OnInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui container grid';

  subscriptions: Subscription[];
  workOrder: WorkOrder;
  role: ROLES;
  activeFacility: Facility;
  workOrder$: BehaviorSubject<WorkOrder>;

  constructor(
    public detailWorkOrder: DetailWorkOrderService,
    private workOrderService: WorkOrderService,
    private session: SessionService,
    private toast: ToastService,
    public roles: RolesService,
    private translate: TranslateService,
  ) {
    this.workOrder$ = detailWorkOrder.workOrder$;
  }

  ngOnInit(): void {
    this.subscriptions = [
      this.detailWorkOrder.workOrder$.subscribe(order => this.workOrder = order),
      this.session.userRole$.subscribe(role => this.role = role),
      this.session.activeFacility$.subscribe(facility => this.activeFacility = facility),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subs => subs.unsubscribe());
  }

  canGoToIncidence(): boolean {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.role);
  }

  canStartWorkOrder(): boolean {
    return !this.workOrder.isWaiting() ? false : [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.role);
  }

  startWorkOrder(): void {
    this.workOrderService.start(this.workOrder.id, this.activeFacility.id)
      .subscribe(order => {
        this.detailWorkOrder.updateFormDataFormWorkOrder(order);

        this.toast.showToast({
          type: 'success',
          message: this.translate.instant('WORK_ORDER_DETAIL.WORK_ORDER_EMITED')
        });
      });
  }

  canCloseWaitingOrder(): boolean {
    return  [ROLES.SUPER_ADMIN, ROLES.OWNER].includes(this.role) &&
            (this.workOrder?.isBlocked() || this.workOrder?.isWaiting());
  }

  canFinishWorkOrder() {
    return (
      this.detailWorkOrder.workingTimeForm.value.workingTimeHours ||
      this.detailWorkOrder.workingTimeForm.value.workingTimeMinutes
      )
      && this.detailWorkOrder.validationEmailForm.value.validationEmail;
  }

  canCloseOrder(): Observable<boolean> {
    return combineLatest([
      this.session.userRole$.pipe(filter(role => role !== null && role !== undefined)),
      this.detailWorkOrder.workOrder$.pipe(skipWhile(order => !order))
    ]).pipe(
      map(([role, order]) => {
        if (order.isClosed()) {
          return false;
        }
        return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE, ROLES.MAINTENANCE_ADMIN].includes(role) && order.wasFinished();
      })
    );
  }
}
