import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaginatedResultEdit, ISchduledTask, ICreateSubTask, IDeleteSubTask } from '../models/edit-section-interface';
import { UtilsService } from 'src/app/shared/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class DetailPageEditSectionService {

  private readonly apiHost = environment.apiHost;
  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
  ) { }

  private getScheduledApi = (taskId: number) => {
    return `scheduled-tasks/${taskId}/components`
  }

  public fetchTaskComponents(taskId: number, facilityId: number, pageNum: number, limit: number, searchBody?: {}): Observable<any> {

    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('page', pageNum.toString());
    params = params.append('limit', limit.toString());

    if (searchBody) {
      return this.http.post<PaginatedResultEdit<ISchduledTask>>(`${this.apiHost}/${this.getScheduledApi(taskId)}?`,
        { ...searchBody },
        { params: params }
      )
    }
    return this.http.post<PaginatedResultEdit<ISchduledTask>>(`${this.apiHost}/${this.getScheduledApi(taskId)}?`,
      {},
      { params: params }
    )
  }

  public fetchSubTasks(taskId: number, componentIds: number[], facilityId: number): Observable<any> {

    let params =  {facilityId, componentIds};
    const filteredParams = this.utilsService.filterNonNullValues(params);
    const formattedParams = this.utilsService.parseParams(filteredParams);

    return this.http.get<any>(`${this.apiHost}/${this.getScheduledApi(taskId)}/common-uncommon-subtasks?${formattedParams.join('&')}`);
  }

  public createSubTask(body: ICreateSubTask, facilityId: number, next: boolean): Observable<any> {

    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('next', `${next}`);

    return this.http.post(`${this.apiHost}/scheduled-tasks-components-subtask?`,
      { ...body },
      { params: params }
    );
  }

  public deleteSubtasks(body: IDeleteSubTask, facilityId: number, next: boolean): Observable<any> {

    let params = new HttpParams();
    params = params.append('facilityId', facilityId.toString());
    params = params.append('next', `${next}`);

    return this.http.request(
      'DELETE',
      `${this.apiHost}/scheduled-tasks-components-subtask?`,
      { body: body, params: params }
    );

  }
}
