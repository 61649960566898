
import { 
    ReportHoursByMaintenanceCategoryDTO, 
    ReportHoursByMaintenanceCategoryReg, 
    ReportStatDTO,
 } from "src/app/reports/models/reports.inteface";
import { ReportsComponent } from '../reports.component';
import { ProcessDataReport } from "./processDataReport";

export class ProcessHoursByMaintenanceCategory extends ProcessDataReport{

  constructor(reports: ReportsComponent, reportGrossData: ReportStatDTO){
    super(reports, reportGrossData);
    }
    
    getData(){
        const {labels: categoryNameMANT, dataA : hoursMANT, 
            dataA2 : hoursMANT2, } = this.getHoursByMaintenanceCategoryDataSets(this.reportGrossData as ReportHoursByMaintenanceCategoryDTO);
          const dataSetsHoursMaintenanceCategory = [{data: hoursMANT, label: this.translate.instant("REPORTS.HOURS")}]
          if (hoursMANT2.length > 0) {
            dataSetsHoursMaintenanceCategory.push({data: hoursMANT, label: this.translate.instant("REPORTS.HOURS_LABEL_2")})
          }
          this.reports.hoursByMaintenanceCategory = {
            labels: categoryNameMANT,
            dataSets: dataSetsHoursMaintenanceCategory
          };
          this.reports.showIncidenceStatusFilter = false;
 
    }
    
    private getHoursByMaintenanceCategoryDataSets(HoursByMaintenanceCategory: ReportHoursByMaintenanceCategoryDTO) {
    
        let labels = [];
        const dataA = [];
        const dataB = [];
        const dataA2 = [];
        const dataB2 = [];
        HoursByMaintenanceCategory.value.forEach((reg: ReportHoursByMaintenanceCategoryReg) => {
          const categoryName = reg.categoryName;
          labels.push(categoryName);
          dataA.push(reg.hours);
        });
        if (HoursByMaintenanceCategory.value2) {
          HoursByMaintenanceCategory.value2.forEach((reg: ReportHoursByMaintenanceCategoryReg) => {
            const categoryName = reg.categoryName;
            labels.push(categoryName);
            dataA2.push(reg.hours);
          });
        }    
    
        labels = [...new Set(labels)];
    
        return {labels, dataA, dataB, dataA2, dataB2};
      }
}