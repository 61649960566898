// base-chart.component.ts
import { Component } from '@angular/core';

@Component({
  template: '' // Add your common template here if needed
})
export abstract class BaseChartComponent {
  abstract getTitle(): string;
  chartId: string;
  abstract getChartCanvas(): HTMLCanvasElement;
}
