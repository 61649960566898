import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CategoryInterface } from '../../../../shared/models/category.interface';
import { CompanyInterface } from '../../../../companies/models/company.interface';
import { UserInterface } from '../../../../users/models/user.interface';
import { ROLES } from '../../../../shared/models/role.interface';
import { ScheduledTask } from '../../../models/scheduled-task.interface';
import { take } from 'rxjs/operators';
import { SessionService } from 'src/app/shared/services/session.service';
import { IUserLogged } from 'src/app/shared/models/i-user-logged';
import { IReadonlyFields } from '../detail-page-edit-section/models/edit-section-interface';
import { ScheduledTaskDetailPageComponent } from '../scheduled-task-detail-page.component';

@Component({
  selector: 'app-detail-page-info-section',
  templateUrl: './detail-page-info-section.component.html',
  styleUrls: ['./detail-page-info-section.component.scss']
})
export class DetailPageInfoSectionComponent implements OnInit, OnChanges {

  @HostBinding('class') hostClass = 'sixteen wide column';

  @Input() infoForm: FormGroup;
  @Input() categories: CategoryInterface[];
  @Input() companies: CompanyInterface[];
  @Input() users: UserInterface[];
  @Input() validators: UserInterface[];
  @Input() userRole: ROLES;
  @Input() finishTaskForm: FormGroup;
  @Input() task: ScheduledTask;
  @Input() canEditTask: boolean;

  private loggedUser: IUserLogged;

  readonlyFields:IReadonlyFields;

  @Output() validateTask = new EventEmitter<null>();
  @Output() deleteTask = new EventEmitter<null>();
  @Output() editTask = new EventEmitter<null>();

  constructor(
    private session: SessionService,
    public taskDetail: ScheduledTaskDetailPageComponent,
  ) {

    this.session.loggedUser$
      .pipe(
        take(1),
      )
      .subscribe(
        (data: IUserLogged) => {
          this.loggedUser = { ...data };
        }
      )
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userRole) {
      this.readonlyFields = {
        name: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
        category: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
        frequencyQuantity: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
        frequencyUnit: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
        company: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
        assignedTo: [ROLES.MAINTENANCE_USER].includes(this.userRole),
        createdAt: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
        strictDates: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
        type: [ROLES.MAINTENANCE_ADMIN, ROLES.MAINTENANCE_USER].includes(this.userRole),
      };

    }
    if (this.isTaskFinished()) {
      Object.keys(this.readonlyFields).forEach(key => this.readonlyFields[key] = true);
    }
  }

  isTaskFinished(){
    const taskFinished = this.task != null? 
    this.task.finishedAt === null?
      false: true
    :false;

    return taskFinished
  }

  canValidateTask(){
    return this.canFinishTask() && 
      this.finishTaskForm.controls.hour.value &&
      this.finishTaskForm.controls.mins.value &&
      this.finishTaskForm.controls.email.value;
  }

  canFinishTask() {

    const isMaintenanceAdminFromCompany =
      this.userRole === ROLES.MAINTENANCE_ADMIN &&
      this.loggedUser.companyId === (this.task != null ? this.task.companyId:0);

    const isMaintenanceAssignedUserFromCompany =
      this.userRole === ROLES.MAINTENANCE_USER &&
      this.loggedUser.companyId === this.task.companyId &&
      this.loggedUser.id === this.task.assignedTo.id;

    const hasSuperPermissions = [
      ROLES.SUPER_ADMIN,
      ROLES.GLOBAL_SERVICE,
      ROLES.OWNER,
    ].includes(this.userRole);

    return (
      isMaintenanceAdminFromCompany ||
      isMaintenanceAssignedUserFromCompany ||
      hasSuperPermissions
    );

  }

  validateTaskClicked() {
    this.validateTask.emit();
  }

  clickOnDeleteTask() {
    this.deleteTask.emit();
  }

  clickOnEditTask() {
    this.editTask.emit();
  }

  shouldShowDeleteButton() {
    return [ROLES.SUPER_ADMIN, ROLES.OWNER, ROLES.GLOBAL_SERVICE].includes(this.userRole) && !this.isTaskFinished();
  }

  shouldShowSaveButton() {
    const hasRole = [
      ROLES.SUPER_ADMIN,
      ROLES.GLOBAL_SERVICE,
      ROLES.MAINTENANCE_ADMIN,
      ROLES.OWNER,
    ].includes(this.userRole);
    return hasRole &&  !this.isTaskFinished();
  }

  shouldShowFinishTaskForm() {
    return this.canFinishTask();
  }
  
}
