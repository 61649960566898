
<div class="fourteen wide column"></div>
  <div class="three wide column">
    <pui-dropdown 
      [options]="elemsPerPageOptions" 
      [formControl]="elemsPerPageInput"
    ></pui-dropdown>
  </div>
<div *ngIf="loadingTasks" class="sixteen wide column">
  <div class="ui segment" style="height: 400px">
    <div class="ui active loader"></div>
  </div>
</div>
<div class="sixteen wide column" *ngIf="!loadingTasks">
  <div class="table-container">
    <table class="ui small selectable table">
      <thead>
        <th>{{ 'SCHEDULED_TASKS_LIST.CODE' | translate }}</th>
        <th>{{ 'SCHEDULED_TASKS_LIST.NAME' | translate }}</th>
        <th>{{ 'SCHEDULED_TASKS_LIST.FREQUENCY' | translate }}</th>
        <th>{{ 'SCHEDULED_TASKS_LIST.COMPANY' | translate }}</th>
        <th>{{ 'SCHEDULED_TASKS_LIST.LAST_EXECUTION' | translate }}</th>
        <th>{{ 'SCHEDULED_TASKS_LIST.NEXT_EXECUTION' | translate }}</th>
      </thead>
      <tbody>
      <tr *ngFor="let task of tasks" (click)="selectedTask(task)">
        <td>{{task.parent.slice(0,8)}}</td>
        <td>{{task.name}}</td>
        <td>{{task.frequencyQuantity + ' ' + task.frequencyUnit}}</td>
        <td>{{task.companyName}}</td>
        <td class="selectable"
            (click)="selectedExecution($event, task.lastScheduledTask)"
        >{{task.lastScheduledTask | date:'yyyy-MM-dd'}}</td>
        <td class="selectable"
            (click)="selectedExecution($event, task.nextScheduledTask)"
        >{{task.nextScheduledTask | date:'yyyy-MM-dd'}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="table-foot">
  <tfoot>
    <tr>
      <th colspan="6">
        <fui-pagination-menu
          [page]="page$ | async"
          [numOfPages]="numOfPages"
          (pageSelected)="page$.next($event)"
        ></fui-pagination-menu>
      </th>
    </tr>
    </tfoot>
  </div>
</div>
