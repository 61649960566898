import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {SessionService} from '../../../shared/services/session.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {IncidencesService} from '../../incidences.service';
import {IUploadResponse} from '../../../shared/models/upload-response.interface';
import {FacilitiesService} from '../../../facilities/facilities.service';
import {Facility} from '../../../facilities/models/facility.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {
  FacilityComponentInterface,
  FacilityElementInterface,
  FacilitySpaceInterface
} from '../../../facilities/models/facility-element.interface';
import {debounceTime, delay, skipWhile, take, takeUntil} from 'rxjs/operators';
import {PuiStep} from '../../../../../projects/ng-proto-ui/src/lib/modules/stepper/models/PuiStep';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {AutodeskViewerComponent} from '../../../autodesk-forge/components/autodesk-viewer/autodesk-viewer.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-incidence',
  templateUrl: './add-incidence.component.html',
  styleUrls: ['./add-incidence.component.scss']
})
export class AddIncidenceComponent implements OnInit, AfterViewInit, OnDestroy {

  @HostBinding('class') hostClass = 'ui container grid';

  steps: PuiStep[];
  activeStep: PuiStep;
  @ViewChild('topicStep') topicStepTemplate: TemplateRef<any>;
  @ViewChild('descriptionStep') descriptionStateTemplate: TemplateRef<any>;
  @ViewChild('photosStep') photosStateTemplate: TemplateRef<any>;
  @ViewChild('componentsStep') componentsStateTemplate: TemplateRef<any>;

  topic: string;
  description: string;

  activeFacility: Facility;

  @ViewChild('fileInput') fileInput;

  uploadingImage = false;
  uploadedImages: IUploadResponse[] = [];

  @ViewChild('viewer') viewer: AutodeskViewerComponent;
  viewerLoaded$ = new BehaviorSubject<boolean>(false);
  selectedComponents: FacilityComponentInterface[] = [];

  activeElement: FacilityElementInterface;
  stepperMode: 'horizontal' | 'headerOnly';

  isMobile: boolean;
  hasViewer: boolean;

  destroy$ = new Subject<boolean>();
/*
  locationInfo: { latitude: number; longitude: number } | null = {
    latitude: 40.238258205130585,
    longitude: -3.992820331528895,
  };
  incidentFormVisible = false;
  qrCodeValue = 'initial_qr_code_value';
*/
  constructor(
    public sessionService: SessionService,
    private incidenceSrv: IncidencesService,
    private facilityService: FacilitiesService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private breakpoints$: BreakpointObserver,
    private translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.sessionService.activeFacility$
      .pipe(skipWhile(facility => !facility), takeUntil(this.destroy$))
      .subscribe(active => {
        this.handleActiveFacilityChange(active);
      });

    this.breakpoints$.observe(['(min-width: 900px)'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((state: BreakpointState) => {
        this.stepperMode = state.matches ? 'horizontal' : 'headerOnly';
        this.isMobile = !state.matches;
      });
  }
  private async translateSteps() {
    this.translate.get('ADD_INCIDENCE').subscribe(translations => {
      this.steps = [
        {
          title: translations.TOPIC_TITLE,
          description: translations.TOPIC_DESCRIPTION,
          templateRef: this.topicStepTemplate,
          completed: false,

        },
        {
          title: translations.DESCRIPTION_TITLE,
          description: translations.DESCRIPTION_DESCRIPTION,
          templateRef: this.descriptionStateTemplate,
          completed: false
        },
        {
          title: translations.PHOTOS_TITLE,
          description: translations.PHOTOS_DESCRIPTION,
          templateRef: this.photosStateTemplate,
          completed: false
        },
        {
          title: translations.COMPONENTS_TITLE,
          description: translations.COMPONENTS_DESCRIPTION,
          templateRef: this.componentsStateTemplate,
          completed: false
        },
      ];
      
      this.activeStep = this.steps[0];
      this.cd.detectChanges();
      }
    );
  }

  ngAfterViewInit() {
    this.translateSteps();
    this.checkParams();
    /*
    this.onScan();
    */
  }

  checkParams(){
    // Check for "componentGUID" parameter in the URL
    this.route.queryParams.subscribe(params => {
      const componentGUID = params['componentGUID'];

        if (componentGUID) {
          this.fetchAndAddComponent(componentGUID);
        }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addFiles() {
    this.uploadingImage = true;
    const file = this.fileInput.nativeElement.files[0];
    this.fileInput.nativeElement.value = '';

    this.incidenceSrv.uploadImage(file).subscribe(result => {
      this.uploadedImages.push(result);
      this.uploadingImage = false;
    });
  }

  deleteImage(image: IUploadResponse): void {
    const index = this.uploadedImages.indexOf(image);
    this.uploadedImages.splice(index, 1);
    this.uploadedImages = [...this.uploadedImages];
  }

  selectedComponentOnSelector(element: FacilityComponentInterface | FacilitySpaceInterface) {

    this.activeElement = element;

    if (this.viewer && element) {
      this.viewer.focusElements(element);
      this.viewer.resize();
    }
  }

  selectedSpaceOnSelector(space: FacilitySpaceInterface) {
    this.facilityService.fetchCubeForSpace(space.externalIdentifier, this.activeFacility.id)
      .subscribe(
        component => {
          if (this.hasViewer) this.viewer.focusElements(component);
          this.selectedComponentOnSelector(component);
        }
      );
  }

  selectedElementOnViewer(elementExternalId: string) {
    if (elementExternalId) {
      this.facilityService.fetchComponentByExternalIdentifier(elementExternalId, this.activeFacility.id)
        .subscribe(
          componentFetched => {
            this.activeElement = componentFetched;
          },
          error => {
            this.activeElement = null;
          }
        );
    } else {
      this.activeElement = null;
    }
    this.viewer.resize();
  }

  selectedComponentOnList(component: FacilityElementInterface) {
    this.activeElement = component;
    if (this.hasViewer){
      this.viewer.focusElements(component);
      this.viewer.resize();
    }
  }

  deselectComponent(component: FacilityComponentInterface) {
    this.incidenceSrv.deleteComponentFromNextIncidence(component);
    this.activeElement = null;
    this.focusSelectedComponents();
  }

  isValidIncidence() {
    return this.topic && this.selectedComponents.length > 0;
  }

  create() {
    this.incidenceSrv.create(this.activeFacility.id, {
      topic: this.topic,
      description: this.description,
      components: this.selectedComponents.map(component => component.id),
      photos: this.uploadedImages.map(image => image.url)
    }).subscribe(result => {
      this.incidenceSrv.clearComponentsFromNextIncidence();
      this.router.navigate(['/solicitudes'], {
        state: {
          successfulIncidenceCreated: true
        }
      });
    });
  }

  viewerLoaded() {
    this.viewerLoaded$.next(true);
  }

  focusSelectedComponents() {
    if (this.hasViewer) this.viewer.focusElements(this.selectedComponents);
  }

  nextStep() {
    const actualIndex = this.steps.indexOf(this.activeStep);
    this.activeStep = this.steps[actualIndex + 1];
  }

  prevStep() {
    const actualIndex = this.steps.indexOf(this.activeStep);
    this.activeStep = this.steps[actualIndex - 1];
  }

  addActiveElementToIncidence() {
    this.incidenceSrv.addComponentToNextIncidence(this.activeElement as FacilityComponentInterface);
  }

  closeElementInfo() {
    this.activeElement = null;
    this.viewer.resize();
  }

  private handleActiveFacilityChange(active: Facility): void {
    if (this.activeFacility && this.activeFacility.id !== active.id) {
      this.router.navigate(['..'], { relativeTo: this.route });
    }
  
    this.activeFacility = active;
    this.hasViewer = this.activeFacility.externalFacilityObject != null ? true : false;
    
    this.viewerLoaded$.pipe(
      skipWhile(loaded => !loaded),
      take(1)
    ).subscribe(() => {
      if (this.selectedComponents.length > 0) {
        const isolate: boolean = false;
        this.viewer.focusElements(this.selectedComponents, isolate);
      }
    });

    this.incidenceSrv.preselectedComponentsToIncidence$
      .pipe(debounceTime(900))
      .subscribe(components => this.selectedComponents = components);
  }

  private fetchAndAddComponent(componentGUID: string): void {
    if (!this.activeFacility) {
      console.info("Active facility is not available.");
      return;
    }

    this.facilityService.fetchComponentByExternalIdentifier(componentGUID, this.activeFacility.id)
    .pipe(delay(30))//to avoid rewrite instead of add component
    .subscribe(component => {
      this.incidenceSrv.addComponentToNextIncidence(component);
    });
  }

  /*
  onScan() {
    // Ask for geolocation permission
  if (navigator.geolocation) {
    // Logic to handle scanning the QR code
    // Update this.locationInfo and set this.incidentFormVisible based on the user's location
    // Use navigator.geolocation to get the user's geolocation
    // Display the incident form if the location is valid

    navigator.geolocation.getCurrentPosition(
      (position) => {
        // Compare user's geolocation with the location from the QR code
        const userLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        console.log("userLocation",userLocation);
        console.log("this.locationInfo",this.locationInfo);

        if (this.isLocationValid(userLocation, this.locationInfo)) {
          // Display the incident report form
          this.incidentFormVisible = true;
          console.log('Location is valid. Displaying incident report form for:', this.locationInfo);
        } else {
          alert('You are not in the specified location. Please try again.');
        }
      },
      (error) => {
        //if (error.code === error.PERMISSION_DENIED) {
        //  // Handle geolocation permission denied
        //  this.handleGeolocationPermissionDenied();
        //} else {
        //  console.error('Error getting geolocation:', error);
        //  alert('Error getting your location. Please try again.');
        //}
      }
    );
  }
  //else {
  //  // Geolocation is not supported by the browser
  //  alert('Geolocation is not supported by your browser.');
  //}
}
private handleGeolocationPermissionDenied() {
  const enableLocation = confirm('To use this feature, please enable location services. Do you want to enable them now?');
  if (enableLocation) {
    // Redirect the user to the location settings or guide them on how to enable location
    // Example: window.location.href = 'path/to/location/settings';
    alert('Please enable location services in your browser settings.');
  } else {
    // Handle the case where the user chooses not to enable location
    alert('Location services are required for this feature. Please enable them to proceed.');
  }
}

  private isLocationValid(userLocation, locationInfo) {
    // Check if locationInfo is not null before accessing its properties
  if (locationInfo && locationInfo.latitude != null && locationInfo.longitude != null) {
    // Logic to compare user's location with the QR code location
    const threshold = 0.01; // Adjust as needed. 0.01 degrees would be '1.11' kilometres
    return (
      Math.abs(userLocation.latitude - locationInfo.latitude) < threshold &&
      Math.abs(userLocation.longitude - locationInfo.longitude) < threshold
    );
  } else {
    // Handle the case where locationInfo is null or missing properties
    console.error('Invalid locationInfo:', locationInfo);
    return false; // or handle it according to your application logic
  }
  }
*/
}
