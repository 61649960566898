import {Directive, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {SortByData} from './sortByData.interface';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[fuiSortableTh]'
})
export class SortableThDirective {

  @Input() columnName: string;
  @Input() sortedBy: SortByData;
  @Output() sortedByChange: EventEmitter<SortByData> = new EventEmitter<SortByData>();

  @HostBinding('class.sorted') get isSorted() {
    return this.sortedBy?.column === this.columnName;
  }
  @HostBinding('class.ascending') get isSortedAscending() {
    return this.isSorted && this.sortedBy?.order === 'ASC';
  }
  @HostBinding('class.descending') get isSortedDescending() {
    return this.isSorted && this.sortedBy?.order === 'DES';
  }

  @HostListener('click') onClick() {
    if (!this.sortedBy || this.sortedBy?.column !== this.columnName) {
      this.sortedByChange.emit({column: this.columnName, order: 'ASC'});
    } else {
      if (this.sortedBy.order === 'ASC') {
        this.sortedByChange.emit({column: this.columnName, order: 'DES'});
      } else {
        this.sortedByChange.emit(null);
      }
    }
  }


  constructor() { }

}
