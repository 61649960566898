import {Component, Input, OnInit} from '@angular/core';
import {FacilityComponentInterface} from '../../../facilities/models/facility-element.interface';

@Component({
  selector: 'app-filtered-components-table',
  templateUrl: './filtered-components-table.component.html',
  styles: []
})
export class FilteredComponentsTableComponent implements OnInit {
  @Input() components: FacilityComponentInterface[];

  constructor() { }

  ngOnInit(): void {
  }

}
