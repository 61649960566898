import {Component, NgZone, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';
import { StorageService } from '../shared/services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { gapi } from 'gapi-script';
import { ToastService } from '../shared/services/toast.service';
const  YOUR_GOOGLE_CLIENT_ID = '528770176504-so79p5es0sc94dmp4se9vgaen8lngtre.apps.googleusercontent.com';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginError: boolean;

  loading: boolean;
  iconClass = 'eye slash';
  passwordVisible = false;
  errorMessage: string;
  private clientId = YOUR_GOOGLE_CLIENT_ID;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private storage: StorageService,
    private translate: TranslateService,
    private ngZone: NgZone,
    private toast: ToastService,
  ) {
    this.loginForm = fb.group({
      email: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // Set the default error message
    this.translate.get('LOGIN_ERROR_MESSAGE').subscribe((res: string) => {
      this.errorMessage = res;
    });

    this.loadGoogleSignIn();
  }

  async loginSubmit(value) {
    this.loading = true;

    const currentBlockedUsers = JSON.parse(this.storage.getItem('login_count'));
    const invalidTime = (currentBlockedUsers && currentBlockedUsers[value.email]) ? currentBlockedUsers[value.email]['date'] : null;
    if (invalidTime) {
      const time1min = 60000;
      const time6min = 60000; //360000
      const totalDelay = new Date().getTime() - new Date(invalidTime).getTime();
      const invalidTimeEnd = new Date(invalidTime).getTime() + time6min;
      
      if (totalDelay > time6min) {
        delete currentBlockedUsers[value.email];
        this.storage.setItem('login_count', JSON.stringify(currentBlockedUsers));

        this.setErrorMessage('LOGIN_ERROR_MESSAGE');
        this.loginError = true;
        this.tryLogin(value);
      } else {
        const minutesLeft = Math.ceil((invalidTimeEnd - new Date().getTime()) / time1min);
        this.setErrorMessage('LOGIN_BLOCKED_MESSAGE_3', { minutes: minutesLeft });
        this.loginError = true;
       
      }
      this.loading = false;
    } else {
      this.loginError = false;
      this.setErrorMessage('LOGIN_ERROR_MESSAGE');
      this.tryLogin(value);
    }
  }
  
  tryLogin(value) {
    this.authService.login(value.email, value.password).subscribe(
      () => {
      this.successfulLogin();
    },
    error => {
      if (error.error.code === "MustUseGoogleLogIn")
        this.setErrorMessage(error.error.message);
      else{
        let currentBlockedUsers = JSON.parse(this.storage.getItem('login_count'));
        let currentEmailUserCount = (currentBlockedUsers && currentBlockedUsers[value.email]) ? currentBlockedUsers[value.email]['count'] : null;
        if (!currentBlockedUsers) {
          currentBlockedUsers = {};
        }
        if (!currentBlockedUsers[value.email]) {
          currentBlockedUsers[value.email] = {};
        }
        let currentCount = currentEmailUserCount ? parseInt(currentEmailUserCount, 10) : null;
        if (!currentCount) {
          currentCount = 1;
        } else {
          currentCount += 1;
        }
    
        if (currentCount === 4) {
          // You have 2 attempts left before your account is locked for 6 minutes.
          this.setErrorMessage('LOGIN_BLOCKED_MESSAGE_1');
        } else if (currentCount === 5) {
          // You have 1 attempt left before your account is locked for 6 minutes.
          this.setErrorMessage('LOGIN_BLOCKED_MESSAGE_2');
        } else if (currentCount === 6) {
          this.setErrorMessage('LOGIN_BLOCKED_MESSAGE_3');
          currentBlockedUsers[value.email]['date'] = new Date();
        }
        currentBlockedUsers[value.email]['count'] = currentCount;
        this.storage.setItem('login_count', JSON.stringify(currentBlockedUsers));
      }
      this.loading = false;
      this.loginError = true;
    });
  }

  setErrorMessage(key: string, interpolateParams?: Object) {
    this.translate.get(key, interpolateParams).subscribe((res: string) => {
      this.errorMessage = res;
    });
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    this.iconClass = this.passwordVisible ? 'eye' : 'eye slash';
  }

  loadGoogleSignIn() {
    gapi.load('auth2', () => {
      gapi.auth2.init({
        client_id: this.clientId,
      });
    });
  }

  signIn() {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signIn().then(googleUser => {
      const id_token = googleUser.getAuthResponse().id_token;

      this.ngZone.run(() => {
        this.authService.googleLogin(id_token).subscribe(
          () => {
          
          this.successfulLogin();
          },
          error => {
            console.error('Authentication failed', error);
            this.loading = false;
            this.loginError = true;
          });
      });
    });
  }

  successfulLogin(){
    this.loading = false;
  
    // After successful login
    const redirectUrl = this.authService.getRedirectUrl() || 'dashboard';
    const queryParams = this.authService.getRedirectQueryParams();
    console.info("redirectUrl=========", redirectUrl);
    console.info("queryParams=========", queryParams);
    this.authService.clearRedirectUrl();

    // Navigate to the route path and add the query parameters
    this.router.navigate([redirectUrl], { queryParams: queryParams });
  }
}
