// languages.ts in ISO 639-1 codes

export const LANGUAGES: { [key: string]: string } = {
    Deutsch: "de",
    English: "en",
    Español: "es",
    Français: "fr",
    Italiano: "it",
    Português: "pt",
    
    //CHINESE_MANDARIN: "zh",
    //ARABIC: "ar",
    //RUSSIAN: "ru",
    //JAPANESE: "ja",
    //HINDI: "hi",
    //BENGALI: "bn",
    //URDU: "ur",
    //INDONESIAN: "id",
    //TURKISH: "tr",
    //VIETNAMESE: "vi",
    //KOREAN: "ko",
    //POLISH: "pl",
    //DUTCH: "nl",
    //THAI: "th"
  };
  